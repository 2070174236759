import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { getDetail } from './productsReportActions';
import ReportData from './reportData.jsx';

const useStyles = makeStyles(styles);

function ProductReport(props) {
  const classes = useStyles();
  const { content } = props.productsReport.listDetail;
  const { router } = props;

  const defaultColumnsMob = [{ title: 'Nome', field: 'user.name' }];

  const defaultColumns = [
    {
      title: 'Usuário',
      field: 'user.name',
      render: (rowData) => rowData.user?.name,
    },
    { title: 'Data da compra', field: 'createdAt', type: 'datetime' },
  ];

  const columns = isMobile ? defaultColumnsMob : defaultColumns;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getDetail(props.router.params.id);
  }, []);

  useEffect(() => {
    setData(props.productsReport.listDetail.content);
  }, [props.productsReport.listDetail.content]);

  function renderEmptyScreen() {
    return (
      <section id="productsReport">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não vendeu nenhum item desse produto!</h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.productsReport.loadingDetail) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section>
      <div>
        <Card style={{ position: 'relative' }} className="active-actions">
          <GridContainer>
            <GridItem
              xs={12}
              style={{
                marginBottom: 20,
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Typography variant="h5" style={{ fontFamily: 'Lato' }}>
                Vendas nos últimos 20 dias
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <ReportData data={props.productsReport.detail} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              style={{
                marginBottom: 20,
                marginLeft: 30,
                display: 'flex',
                justifyContent: 'start',
              }}>
              <Typography variant="span" style={{ fontFamily: 'Lato' }}>
                Produto: {props.productsReport.detail?.name}
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              style={{
                marginBottom: 20,
                marginLeft: 30,
                display: 'flex',
                justifyContent: 'start',
              }}>
              <Typography variant="span" style={{ fontFamily: 'Lato' }}>
                Atividade: {props.productsReport.detail?.action?.name}
              </Typography>
            </GridItem>
          </GridContainer>
        </Card>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">dashboard</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Estoque inicial (total)</p>
              <h3 className={classes.cardTitle}>{props.productsReport.detail?.stock}</h3>
            </CardHeader>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">accessibility</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Vendas</p>
              <h3 className={classes.cardTitle}>
                {props.productsReport.listDetail.content.length}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">dashboard</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Estoque restante</p>
              <h3 className={classes.cardTitle}>
                {props.productsReport.detail?.stock -
                  props.productsReport.listDetail.content.length}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link
            to={`/owner/acoes/cupons/${props.productsReport.detail?.action_id}/editar/?from=online`}>
            <Card
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="active-actions">
              <Typography color="primary" component="h4" className={classes.cardTitle}>
                Editar produto/ estoque
              </Typography>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table
                hasToolbar
                toolbarSearch
                title="Lista de Compras"
                columns={columns}
                rows={data}
                dataType={'sales'}
                router={router}
                onRowClick={(e, rowData) =>
                  props.router.push(`/owner/sales/${rowData.product_order_id}`)
                }
                mobileTable={isMobile}
                hasBorder={!isMobile}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ProductReport.propTypes = {
  auth: PropTypes.any,
  getDetail: PropTypes.func,
  productsReport: PropTypes.shape({
    detail: PropTypes.shape({
      action: PropTypes.shape({
        name: PropTypes.any,
      }),
      action_id: PropTypes.any,
      name: PropTypes.any,
      stock: PropTypes.any,
    }),
    listDetail: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loadingDetail: PropTypes.any,
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    productsReport: state.productsReport,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getDetail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductReport);
