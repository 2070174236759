import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Card,
  SvgIcon,
  Button,
} from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { String2Currency, changeDateToFormat } from 'helpers/dataTransformers';
import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { makeStyles, withStyles } from '@material-ui/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LockIcon from '@material-ui/icons/Lock';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RedeemIcon from '@material-ui/icons/Redeem';
import PropTypes from 'prop-types';
import { addHours, format, isValid, parseISO, subHours } from 'date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers';
import { appColors } from 'styles/colors';
import MomentUtils from '@date-io/moment';
import ptBR from 'date-fns/locale/pt-BR';
import dispatchSnackbar from './extractActions';

const CssDateField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
})(DatePicker);

const defaulColsMob = [
  {
    field: 'points',
    title: 'Valor (R$)',
  },
  {
    field: 'date',
    title: 'Data',
  },
];
const defaultColumns = [
  {
    field: 'date',
    title: 'Data',
  },
  {
    field: 'description',
    title: 'Descrição',
  },
  {
    field: 'amount',
    title: 'Valor (R$)',
  },
  {
    field: 'origin',
    title: 'Origem',
  },
  {
    field: 'destination',
    title: 'Destino',
  },
];

const columns = isMobile ? defaulColsMob : defaultColumns;

const useStyles = makeStyles((theme) => ({
  points: {
    marginTop: '1em',
    marginBottom: '2em',
  },
  value: {
    fontSize: '1.2em',
  },
  date: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '10',
  },
  card: {
    background: 'white',
    borderRadius: 8,
    border: '1px solid lightgray',
    padding: '1em',
    boxShadow: 'none',
  },
  cardHeader: {
    '*': {
      margin: '0',
    },
    justifyContent: 'space-between',
  },
  cardValue: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '58px',
    letterSpacing: '0.25px',
    color: '#000000',
    margin: '1em 0',
  },
}));

export function ManagerExtract(props) {
  const { establishments } = props.auth.user;
  const [loading, setLoading] = useState('idle');
  const [history, setHistory] = useState([]);
  const [points, setPoints] = useState({
    allocated_points: 0,
    available_points: 0,
    total_points: 0,
    distributed_points: 0,
  });
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(
    format(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd')
  );
  const classes = useStyles();

  const getExtract = useCallback(async () => {
    setLoading('pending');
    try {
      const response = await axios.get(
        `${BASE_API}/reports/extract/${establishments[0].id}?ends_at=${endDate}&starts_at=${startDate}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      );
      setPoints({
        allocated_points: response.data.allocated_points,
        available_points: response.data.available_points,
        total_points: response.data.total_points,
        distributed_points: response.data.distributed_points || 0,
      });

      if (response.data.history.length > 0) {
        const formatedResponse = response.data.history.map((i) => ({
          date: changeDateToFormat(i.date),
          amount: String2Currency(i.amount),
          description: i.description,
          origin: i.origin,
          destination: i.destination,
        }));
        setHistory(formatedResponse);
      }
      setLoading('resolved');
    } catch (error) {
      setHistory([]);
      props.dispatchSnackbar(
        'error',
        'Erro ao carregar extrato. Verifique os campos de data digitados.'
      );
      setLoading('rejected');
    }
  }, [establishments[0].id, endDate, startDate]);

  useEffect(() => {
    getExtract();
  }, []);

  function renderSelectExtractDate() {
    return (
      <Container className={classes.date}>
        <Grid container spacing={3} alignItems="center">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item>
              <CssDateField
                label="Data inicial"
                InputLabelProps={{
                  shrink: true,
                }}
                inputVariant="outlined"
                format="DD/MM/YYYY"
                ampm={false}
                autoOk
                onChange={(value) => setStartDate(format(new Date(value), 'yyyy-MM-dd'))}
                value={addHours(new Date(startDate), 3)}
              />
            </Grid>
            <Grid item>
              <CssDateField
                label="Data final"
                InputLabelProps={{
                  shrink: true,
                }}
                inputVariant="outlined"
                format="DD/MM/YYYY"
                ampm={false}
                onChange={(value) => setEndDate(format(new Date(value), 'yyyy-MM-dd'))}
                autoOk
                value={addHours(new Date(endDate), 3)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Button type="button" variant="contained" color="primary" onClick={getExtract}>
            FILTRAR
          </Button>
        </Grid>
      </Container>
    );
  }

  function CardItem({ title, value, icon }) {
    return (
      <Card variant="outline" className={classes.card}>
        <Grid container justifyContent="center">
          <Grid container className={classes.cardHeader}>
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
              <SvgIcon component={icon} color="primary" />
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.cardValue} variant="h4" align="center">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }

  CardItem.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.any,
    value: PropTypes.any,
  };

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <Container maxWidth="md" p={3}>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs>
              <Typography align="center">
                Nenhum extrato encontrado, selecione um maior espaço de tempo para a procura.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  }

  function renderPoints() {
    return (
      <Box maxWidth="lg" className={classes.points}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <CardItem
              title="Disponivel"
              value={String2Currency(points.available_points)}
              icon={AttachMoneyIcon}
            />
          </Grid>
          <Grid item xs={3}>
            <CardItem
              title="Alocado"
              value={String2Currency(points.allocated_points)}
              icon={LockIcon}
            />
          </Grid>
          <Grid item xs={3}>
            <CardItem
              title="Total"
              value={String2Currency(points.total_points)}
              icon={AccountBalanceWalletIcon}
            />
          </Grid>
          <Grid item xs={3}>
            <CardItem
              title="Distribuído"
              value={String2Currency(points.distributed_points)}
              icon={RedeemIcon}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (loading === 'idle') {
    return renderEmptyScreen();
  }

  if (loading === 'pending') {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <section id="manager-extract">
      <GeneralGrid container>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }} spacing={3}>
          <Grid item>{renderPoints()}</Grid>
          <Grid item>
            <Table
              searchPlaceholder="Pesquisar"
              title="Extrato"
              columns={columns}
              rows={history}
              mobileTable={isMobile}
              hasBorder={false}
              buttonCSV={history.length !== 0}
              csvData={history}
              customSearch={renderSelectExtractDate()}
              emptyMessage="Nenhum valor encontrado, selecione um período maior"
            />
          </Grid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ManagerExtract.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  dispatchSnackbar: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManagerExtract);
