const dispatchSnackbar = (variant, message) => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message,
        variant,
        open: true,
      },
    });
  };
};

export default dispatchSnackbar;
