import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddButton from 'common/components/buttons/addButton';
import Table from 'common/components/table/tableGeneral';
import { ColumnsBelowSearch } from 'common/components/table/tableComponents';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { getList, remove } from './downloadsActions';

export function Downloads(props) {
  const { router } = props;
  const { list, loading } = props.downloads;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const [columns] = useState([
    { field: 'name', title: 'Nome' },
    { field: 'description', title: 'Descrição' },
    {
      field: 'actions',
      headerCenter: true,
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(list.content);
  }, [list.content]);

  function onRowClick(e, rowData) {
    router.push(`/owner/content/downloads/${rowData.id}/editar`);
  }

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].stations[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você subiu nenhum arquivo para download</h2>
                <p>Suba um arquivo para que seus associados façam download.</p>
                <PrimaryButton onClick={() => props.router.push('/owner/content/downloads/novo')}>
                  Subir arquivo
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="downloads">
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.downloads.loading}
        general={props.general}
        title="Deletar arquivo para download"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover este arquivo?"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            hasToolbar
            toolbarSearch
            title="Downloads"
            columns={columns}
            data={data}
            router={router}
            onRowClick={onRowClick}
          />
        </GeneralGrid>
      </GeneralGrid>

      <AddButton onClick={() => props.router.push('/owner/content/downloads/novo')} />
    </section>
  );
}

Downloads.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  downloads: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  general: PropTypes.any,
  getList: PropTypes.func,
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    downloads: state.downloads,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
