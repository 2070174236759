import { faPeopleCarry } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleCard from 'common/components/cards/moduleCard/moduleCard';
import AlertDialogSlide from 'common/components/dialog/dialog';
import React, { Component } from 'react';

class ActionsOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  render() {
    return (
      <div>
        <AlertDialogSlide visible={this.state.visible} title="Criar Atividade">
          <ModuleCard
            cols="12 6 4 4"
            path={{
              pathname: '/owner/acoes/aquisicao-clientes/pesquisa/template',
              query: { type: 'contact' },
            }}
            text={
              'Uma forma barata e fácil de conhecer seus contatos não ativos é fazer uma pesquisa de opinião! Clique para começar'
            }
            bgc={this.renderBackground()}
            color="white"
            icon={
              <FontAwesomeIcon
                style={{ fontSize: 60, margin: 10 }}
                className="history-icon"
                icon={faPeopleCarry}
              />
            }
          />

          <ModuleCard
            cols="12 6 4 4"
            path={{
              pathname: '/owner/acoes/aquisicao-clientes/pesquisa/template',
              query: { type: 'user' },
            }}
            text={
              'Uma forma barata e fácil de conhecer seus Associados é fazer uma pesquisa de opinião! Você oferecerá uma recompensa em créditos para isso. Clique para começar'
            }
            bgc={this.renderBackground()}
            color="white"
            icon={
              <FontAwesomeIcon
                style={{ fontSize: 60, margin: 10 }}
                className="history-icon"
                icon={faPeopleCarry}
              />
            }
          />
          {/*   <ModuleCard
                        cols="12 6 4 4"
                        path="/owner/acoes/aquisicao-clientes/pesquisa"
                        text={"Panfletagem"}
                        bgc={this.renderBackground()}
                        color="white"
                        icon={<FontAwesomeIcon style={{ fontSize: 60, margin: 10 }} className="history-icon" icon={faBook} />}
                    />
                    <ModuleCard
                        cols="12 6 4 4"
                        path="/owner/acoes/aquisicao-clientes/pesquisa/form"
                        text={"Outra atividade para aquisição de clientes"}
                        bgc={this.renderBackground()}
                        color="white"
                        icon={<FontAwesomeIcon style={{ fontSize: 60, margin: 10 }} className="history-icon" icon={faPersonBooth} />}
                    /> */}
        </AlertDialogSlide>
      </div>
    );
  }
}

export default ActionsOptions;
