import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { EditorConvertToHTML } from 'common/components/fields/EditorConvertToHTML';
import { InputFile } from 'common/components/fields/InputFile';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Grid from 'common/components/layout/grid/grid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './newsActions';

let NewsForm = function (props) {
  const { news, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(news, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      station: props.auth.user.establishments[0].stations[0].id,
    };

    let firstTime = false;

    if (props.location.query.first_action) {
      firstTime = true;
    }

    if (!_.isUndefined(newValues.image.base64)) {
      newValues.base64 = imgToBase64(newValues.image);
      newValues.imageType = imgToImgType(newValues.image);
      delete newValues.image;
    }

    create_or_update(router, newValues, create, update, firstTime);
  }

  function onDropRejected(files, event) {
    props.fileRejected();
  }

  return props.news.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="news-form">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Formulário</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="Título"
                      name="title"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="Subtítulo"
                      name="subtitle"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} lg={6}>
                    <Field
                      component={EditorConvertToHTML}
                      name="body"
                      label={'Corpo da notícia'}
                      border={true}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputFile}
                      label={'Imagem .png, .jpg ou .gif'}
                      name="image"
                      validate={[FORM_RULES.required]}
                      onDropRejected={onDropRejected}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                  <PrimaryButton
                    type="submit"
                    disabled={props.news.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}>
                    {props.news.buttonLoading ? 'Enviando' : 'Enviar'}
                  </PrimaryButton>
                </Grid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

NewsForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  create: PropTypes.any,
  fileRejected: PropTypes.func,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_action: PropTypes.any,
    }),
  }),
  news: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

NewsForm = reduxForm({ form: 'newsForm' })(NewsForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
    formValues: getFormValues('newsForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsForm);
