import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL = `${BASE_API}/stations-permissions`;

const changeAccess = async (access, station_id, successCleanup, errorCleanup) => {
  const body = {
    classified_ads_access: access.classified_ads_access.value,
    feed_access: access.feed_access.value,
    events_access: access.events_access.value,
    files_access: access.files_access.value,
    my_account_access: access.my_account_access.value,
    checkout_access: access.checkout_access.value,
  };
  try {
    await axios.put(`${URL}/${station_id}`, body, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    });
    successCleanup();
    return;
  } catch (err) {
    errorCleanup();
    return err;
  }
};

export default changeAccess;
