import PropTypes from 'prop-types';
import Content from 'common/components/content/content';
import { ColorPickerInput } from 'common/components/fields/ColorPickerInput';
import { InputFile } from 'common/components/fields/InputFile';
import Grid from 'common/components/layout/grid/grid';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { getDetail, initializeForm, setDetail, updateIdentity } from './identityActions';

class Identity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    this.props.setDetail(this.props.auth.user.establishments[0].visual_identity_id);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSecondColorChange = this.handleSecondColorChange.bind(this);
  }

  handleColorChange(color) {
    this.props.change('mainColor', color);
  }

  handleSecondColorChange(color) {
    this.props.change('secondColor', color);
  }

  onSubmit = (values) => {
    const { router } = this.props;
    values.id = this.props.auth.user.establishments[0].visual_identity_id.id;
    values.base64 = values.cleanLogo.base64;
    const a = values.base64.indexOf('/');
    const b = values.base64.indexOf(';');
    values.imageType = values.base64.slice(a + 1, b);
    delete values.cleanLogo;
    this.props.updateIdentity(values, router);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Content>
        <Card>
          <CardTitle title="Minha Identidade Visual" />
          <CardText>
            <div className="container-fluid">
              <div className="row">
                <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
                  <Field
                    component={ColorPickerInput}
                    label={'Cor Principal'}
                    name="mainColor"
                    onColorChange={this.handleColorChange}
                    cols="12 12 6 6"
                    validate={[FORM_RULES.required]}
                  />
                  {/* <Field
									component={ColorPickerInput}
									label={"Cor secundária"}
									name="secondColor"
									onColorChange={this.handleSecondColorChange}
									cols="12 12 4 4"
									validate={[FORM_RULES.required]}
								/> */}
                  <Field
                    component={InputFile}
                    label={'Logo com fundo transparente'}
                    name="cleanLogo"
                    cols="12 12 6 6"
                    validate={[FORM_RULES.required]}
                  />
                  <Grid cols="12 12 12 12">
                    <center>
                      <button className="btn btn-primary" type="submit">
                        Salvar
                      </button>
                    </center>
                  </Grid>
                </Form>
              </div>
            </div>
          </CardText>
        </Card>
      </Content>
    );
  }
}

Identity.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  setDetail: PropTypes.func,
  updateIdentity: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('IdentityForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      updateIdentity,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'IdentityForm' })(Identity));
