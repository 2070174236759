import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Typography, Button, Box } from '@material-ui/core';

import AlertDialogSlide from 'common/components/dialog/dialog';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { PreviewVouchers } from './vouches-lote/modal-preview';
import { toastr } from 'react-redux-toastr';

export function VouchersEmLoteModal({ visible, onClose, atividade }) {
  const [quantidade, setQuantidade] = useState('');
  const [presential_course, setPresential_course] = useState();
  const [preview, setPreview] = useState(false);

  const handleLoadVoucherMetadata = async () => {
    const { id } = atividade;

    try {
      const response = await axios.get(`${BASE_API}/vouchers-metadata/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });
      const { data } = response;
      setPresential_course(data);
    } catch (e) {
      toastr.error('Erro ao recuperar metadados dos vouchers.');
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (atividade) {
      handleLoadVoucherMetadata();
    }
  }, [atividade, atividade]);

  return (
    <>
      <AlertDialogSlide title="Gerador de vouchers" visible={visible} onClose={onClose}>
        <Box minHeight={200} display="flex" style={{ gap: '12px' }} flexDirection="column">
          <Typography variant="h4">Qual a quantidade que você quer gerar?</Typography>
          <Typography variant="small">Quantidade máxima 100 vouchers?</Typography>
          <TextField
            onChange={(event) => setQuantidade(event.target.value)}
            InputProps={{ inputProps: { min: 0, max: 10 } }}
            fullWidth
            value={quantidade}
            type="number"
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={() => {
              onClose();
              setPreview(true);
            }}
            disabled={!presential_course || quantidade > 100}
            variant="contained"
            color="primary">
            Gerar
          </Button>
        </Box>
      </AlertDialogSlide>
      {preview && (
        <PreviewVouchers
          presential_course={presential_course}
          visible={preview}
          quantity={quantidade}
          onClose={() => setPreview(false)}
        />
      )}
    </>
  );
}
