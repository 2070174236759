import PropTypes from 'prop-types';
import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DollarSign from 'common/components/svg/dollarSign';

export default function ActivitiesCard(props) {
  const { image, text, callToAction, colors, hasImage, hasIcon } = props;

  return (
    <div>
      <div className="card" style={{ width: '100%', height: hasImage ? 400 : 150 }}>
        {hasImage ? (
          <div
            style={{
              position: 'relative',
              background: `url(${image}) center center / cover`,
              height: 200,
            }}>
            {hasIcon ? (
              <div
                className="station-font-bold d-flex justify-content-center align-items-center"
                style={{
                  position: 'absolute',
                  bottom: -25,
                  left: '1.25rem',
                  background: 'white',
                  fontSize: 'x-small',
                  borderRadius: '50%',
                  width: 50,
                  height: 50,
                  textAlign: 'center',
                  fill: colors.reward,
                }}>
                <DollarSign width={24} height={24} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="card-body" style={{ marginTop: 10 }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <p
              className="station-font-semibold"
              style={{
                color: colors.text,
                fontSize: '1rem',
                lineHeight: '1.875rem',
              }}>
              {text}
            </p>
            <div className="d-flex justify-content-between">
              <p
                className="pb-2 station-font-regular"
                style={{ color: colors.callToAction, fontSize: '0.875rem' }}>
                {callToAction}
              </p>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  color: colors.chevron,
                }}
                icon={faChevronRight}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ActivitiesCard.propTypes = {
  callToAction: PropTypes.any,
  colors: PropTypes.shape({
    callToAction: PropTypes.any,
    chevron: PropTypes.any,
    reward: PropTypes.any,
    text: PropTypes.any,
  }),
  hasIcon: PropTypes.any,
  hasImage: PropTypes.any,
  image: PropTypes.any,
  text: PropTypes.any,
};
