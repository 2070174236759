import { Box } from '@material-ui/core';
import { BASE_S3 } from 'config/consts';
import React, { useState } from 'react';
import { useCommands } from '@remirror/react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputEasyImageWithProcessing } from './InputEasyImageWithProcessing';

export function ImageToolbar({ imageRatio }) {
  const { insertImage } = useCommands();

  const [selectedImage, setSelectedImage] = useState({
    data: null,
    isModalOpen: false,
  });

  const { isModalOpen, data } = selectedImage;

  function handleClose() {
    setSelectedImage({ data: null, isModalOpen: false });
  }

  function handleOpen() {
    setSelectedImage({ data: null, isModalOpen: true });
  }

  function handleAddImage() {
    insertImage({
      src: `${BASE_S3}/${data.path}/${data.saved_name}`,
    });
    handleClose();
  }

  return (
    <Box mt={2}>
      <Button
        onClick={() => {
          handleOpen();
        }}
        type="button"
        color="primary"
        variant="outlined">
        Adicionar imagem
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Subir uma imagem</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clique na região abaixo ou arraste sua imagem
          </DialogContentText>
          <InputEasyImageWithProcessing
            input={{
              value: data,
              onChange: (value) => setSelectedImage({ ...selectedImage, data: value }),
            }}
            meta={{ touched: false, error: false }}
            aspect={imageRatio || 16 / 9}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddImage} color="primary" autoFocus disabled={!data}>
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
