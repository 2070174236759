import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/vector-stroke.svg';
import Button from 'common/components/buttons/button';
import { appColors } from '../../../../styles/colors';

export default function PlansOptionsCard({
  name,
  benefits = [],
  amount,
  onClick,
  is_highlighted,
  color,
  buttonLabel = 'Adquirir',
  buttonStyle,
  buttonLabelStyle,
  days,
  description,
  enterprisePlan = false,
  buttonDisabled = false,
  onDetailClick,
}) {
  const {
    v2: { mediumGray, black },
  } = appColors;

  const slicedBenefits = benefits ? benefits.slice(0, 5) : [];

  return (
    <>
      {is_highlighted && (
        <Box textAlign="center" style={{ marginTop: '-24px' }}>
          <span
            className="h5-bold"
            style={{
              backgroundColor: color,
              padding: '4px 32px',
              color: '#fff',
            }}>
            Mais popular
          </span>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={3}
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          boxShadow: ' 0px 2.6px 9px rgba(0, 0, 0, 0.15)',
          border: is_highlighted ? `2px solid ${color}` : '',
        }}
        height={800}
        maxWidth="100%">
        <Box height={200}>
          <p className="h2-bold text-center" style={{ color }}>
            {name}
          </p>

          <p
            className="h5 text-center mt-3"
            style={{
              color: black,
              maxHeight: 90,
              overflow: 'hidden',
            }}>
            {description}
          </p>
        </Box>

        <Box height={300} mt={5} overflow="hidden">
          {slicedBenefits &&
            slicedBenefits
              .filter((item) => item)
              .map((item, index) => (
                <p className="h5 text-left mt-3" key={index}>
                  <CheckIcon stroke={color} fill={color} style={{ marginRight: 8 }} />
                  {item.benefit}
                </p>
              ))}
          {benefits && benefits.length > 6 && (
            <p className="h5 text-left mt-3">
              <CheckIcon stroke={color} fill={color} style={{ marginRight: 8 }} />E muito mais!
            </p>
          )}
        </Box>

        <Box height={300} display="flex" flexDirection="column" alignItems="center">
          {amount ? (
            <p className="title text-center mt-5" style={{ color, flexWrap: 'false' }}>
              R$ {amount}
              {parseFloat(amount.replace(',', '.')) > 0 ? (
                <span className="h3 text-center" style={{ color: mediumGray }}>
                  /{days === 30 ? 'mês' : days === 183 ? 'semestre' : 'ano'}
                </span>
              ) : null}
            </p>
          ) : (
            <p className="title text-center mt-5" style={{ color }}>
              A negociar
            </p>
          )}
          <Button
            label={buttonLabel}
            onClick={onClick}
            buttonType="button"
            buttonStyle={{
              width: '100%',
              marginTop: 32,
              backgroundColor: color,
              ...buttonStyle,
            }}
            labelStyle={buttonLabelStyle}
            disabled={buttonDisabled}
          />
          {enterprisePlan ? null : (
            <Button
              label="Mais detalhes"
              onClick={onDetailClick}
              buttonType="button"
              buttonStyle={{
                width: '100%',
                marginTop: 8,
                backgroundColor: 'transparent',
              }}
              labelStyle={{ color, ...buttonLabelStyle }}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

PlansOptionsCard.propTypes = {
  amount: PropTypes.shape({
    replace: PropTypes.func,
  }),
  benefits: PropTypes.array,
  buttonDisabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonLabelStyle: PropTypes.any,
  buttonStyle: PropTypes.any,
  color: PropTypes.any,
  days: PropTypes.number,
  description: PropTypes.any,
  enterprisePlan: PropTypes.bool,
  is_highlighted: PropTypes.any,
  name: PropTypes.any,
  onClick: PropTypes.any,
  onDetailClick: PropTypes.any,
};
