import PropTypes from 'prop-types';
import React, { Component } from 'react';
import signup_img from 'assets/img/signup-img.svg';
import { InputCheckbox } from 'common/components/fields/InputCheckbox';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import { InputLabel2 } from 'common/components/fields/InputLabel2';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dialog } from 'primereact/dialog';

import mail_check from 'assets/img/mail-check.svg';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import { submitSurvey, getSurveyById } from './surveysActions';

class SurveysForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: true,
      visible: false,
    };
    this.props.getSurveyById(this.props.router.params.surveyId);
  }

  onSubmit = (values) => {
    const { router } = this.props;
    // adicionar estabelecimento
    values.author = 'contact';
    values.contact = router.params.contactId;
    values.establishment = router.params.establishmentId;
    values.research = router.params.surveyId;
    const arr = [];
    for (const prop in values) {
      if (prop.indexOf('contactSurveyAnswer') > -1) {
        if (!_.isArray(values[prop]) && prop.split('-').length < 3) {
          arr.push({
            answer: values[prop],
            question: prop.split('-')[1],
          });
        } else if (prop.split('-').length > 2) {
          arr.push({
            options: [values[prop]],
            question: prop.split('-')[1],
          });
        } else {
          arr.push({
            options: values[prop],
            question: prop.split('-')[1],
          });
        }
        delete values[`${prop}`];
      }
    }
    values.answers = arr;

    this.props.submitSurvey(values);
  };

  handleClick = () => {
    this.setState({ stepBool: false });
  };

  handleModal = () => {
    this.setState({ visible: true });
  };

  handleHide = () => {
    this.props.showModal();
  };

  handleGoBack = () => {
    this.setState({ stepBool: true });
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  renderStep1 = () => {
    return (
      <div>
        <div className="row justify-content-center">
          {!_.isUndefined(this.props.surveys.list.content[0]) ? (
            <div className="col-auto text-center signup-text my-4">
              {this.props.surveys.list.content[0].name}
            </div>
          ) : null}
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
              {!_.isUndefined(this.props.surveys.list.content[0])
                ? this.props.surveys.list.content[0].questions.forEach((item, index) => {
                    if (parseInt(item.type) === 3) {
                      return (
                        <div className="row justify-content-center my-n2">
                          <Field
                            component={InputLabel2}
                            type="text"
                            placeholder={`${item.question}`}
                            label={`${item.question}`}
                            name={`contactSurveyAnswer-${item.id}`}
                            cols="12 12 4 4"
                            validate={[FORM_RULES.required]}
                            style={{
                              border: '1px solid #FF007B',
                              fontFamily: 'Roboto',
                              fontSize: 16,
                              borderRadius: 0,
                              padding: '23px 10px',
                              fontWeight: 400,
                            }}
                            borderColor="#FF007B"
                          />
                        </div>
                      );
                    } else if (parseInt(item.type) === 2) {
                      return (
                        <div className="row justify-content-center my-n2">
                          <Field
                            component={InputCheckbox}
                            options={item.options.map((item) => {
                              return {
                                value: item.id,
                                label: item.label,
                              };
                            })}
                            cols="12 12 4 4"
                            name={`contactSurveyAnswer-${item.id}`}
                            label={`${item.question} (Selecione uma ou mais)`}
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 16,
                              borderRadius: 0,
                              padding: '23px 10px',
                              fontWeight: 400,
                            }}
                            borderColor="#FF007B"
                          />
                        </div>
                      );
                    } else if (parseInt(item.type) === 1) {
                      return (
                        <div className="row justify-content-center my-n2">
                          <Field
                            component={SelectLabel}
                            type="text"
                            placeholder={`${item.question}`}
                            label={`${item.question} (Selecione apenas uma)`}
                            name={`contactSurveyAnswer-${item.id}-1`}
                            cols="12 12 4 4"
                            validate={[FORM_RULES.required]}
                            options={item.options.map((item) => {
                              return {
                                value: item.id,
                                label: item.label,
                              };
                            })}
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 16,
                              borderRadius: 0,
                              padding: '23px 10px',
                              fontWeight: 400,
                            }}
                            borderColor="#FF007B"
                          />
                        </div>
                      );
                    }
                  })
                : null}

              <div className="row justify-content-center">
                <PrimaryButton
                  onClick={this.handleModal}
                  router={this.props.router}
                  type="submit"
                  color="primary">
                  Enviar
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.surveys.loading) {
      return (
        <div className="container-fluid" style={{ paddingBottom: 50 }}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid" style={{ paddingBottom: 50 }}>
          <div className="row justify-content-center">
            <div className="col-auto text-center signup-img">
              <img alt="" src={signup_img} />
            </div>
          </div>
          {this.state.stepBool ? this.renderStep1() : this.renderStep2()}
          <Dialog
            className="signup-modal"
            header="Confirmação"
            visible={this.props.surveys.surveySent}
            style={{ width: '80vw' }}
            modal={true}
            onHide={this.handleHide}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <img alt="" src={mail_check} />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      fontSize: 22,
                      color: '#2B2D6B',
                    }}>
                    {' '}
                    Sua pesquisa foi enviada com sucesso! Obrigado.
                  </span>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      );
    }
  }
}

SurveysForm.propTypes = {
  getSurveyById: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      contactId: PropTypes.any,
      establishmentId: PropTypes.any,
      surveyId: PropTypes.any,
    }),
  }),
  showModal: PropTypes.func,
  submitSurvey: PropTypes.func,
  surveys: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.any,
    }),
    loading: PropTypes.any,
    surveySent: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('surveysForm')(state),
    surveys: state.surveys,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSurveyById,
      submitSurvey,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'surveysForm' })(SurveysForm));
