import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  whiteCardHeader,
  grayColor,
} from 'material-dashboard-assets/jss/material-dashboard-react.js';

const cardIconStyle = {
  cardIcon: {
    borderRadius: '3px',
    backgroundColor: grayColor[0],
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginRight: '15px',
    float: 'left',
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  whiteCardHeader,
};

export default cardIconStyle;
