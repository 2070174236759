import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { BASE_API } from 'config/consts';
import Axios from 'axios';
import logo from 'assets/img/fan_logo.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QrReader from 'react-qr-reader-es6';
import MaterialButton from '@material-ui/core/Button';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import LoadingProgress from 'common/components/progress/loading';
import { Alert } from '@mui/material';

const SUCESSO = 'success';
const ERRO = 'error';
const validatorKey = 'code_validator';

const MENSAGEM = {
  [SUCESSO]: `Validado com sucesso. voucher`,
  [ERRO]: `Erro ao validar o voucher`,
};

let QRCodeReader = function (props) {
  const [loading, setLoading] = useState(false);
  const [scan, setScan] = useState(false);
  const [severity, setSeverity] = useState('');
  const [url, setUrl] = useState('no url');
  const [code, setCode] = useState(null);

  useEffect(() => {
    console.log(props.router?.params?.code_validator);
    if (props.router?.params?.code_validator) {
      localStorage.setItem(validatorKey, props.router?.params?.code_validator);
    } else {
      console.log('favor informar um code_validator na URL');
    }
  }, []);

  useEffect(() => {
    if (code) {
      const values = { code, code_validator: localStorage.getItem(validatorKey) };
      console.log('consultar: ', values);
      setLoading(true);
      Axios.put(`${BASE_API}/validate-code-with-no-auth/voucher`, values)
        .then((response) => {
          setScan(false);
          setLoading(false);
          setSeverity(SUCESSO);
          console.log(response);
        })
        .catch((err) => {
          console.log(err, err.response.data.error);
          if (err?.response?.data?.error) {
            MENSAGEM[ERRO] = err.response.data.error;
          }
          cancelarQrCodeReader(ERRO);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [code]);

  function handleScan(data) {
    if (data) {
      const arrData = data?.split('/');
      const code = arrData[arrData?.length - 1];
      setUrl(data);
      setCode(code);
    }
  }

  function handleError(err) {
    console.error(err);
  }

  function resetQrCodeReader() {
    setScan(true);
    setLoading(false);
    setUrl('no url');
    setCode(null);
    setSeverity('');
  }

  function cancelarQrCodeReader(severity = '') {
    setScan(false);
    setLoading(false);
    setUrl('no url');
    setCode(null);
    setSeverity(severity);
  }

  function renderValidatorTitleGrid() {
    return (
      <Grid container item justifyContent="center" mt={5}>
        <Typography
          style={{
            fontFamily: 'Roboto',
            marginTop: '10%',
            fontWeight: 300,
            fontSize: 20,
            marginBottom: '30%',
          }}>
          Validador QRCode
        </Typography>
      </Grid>
    );
  }

  function renderLogoGrid() {
    return (
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <img src={logo} alt="Rilato Logo" style={{ width: 80, height: 80 }} />
      </Grid>
    );
  }

  function renderAlertaAndButtonGrid() {
    return (
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        {severity && (
          <Alert variant="filled" severity={severity} style={{ marginBottom: 16, width: '100%' }}>
            {`${MENSAGEM[severity]} ${code || ''}`}
          </Alert>
        )}
        <MaterialButton
          variant="contained"
          size="large"
          style={{
            backgroundColor: '#1976d2',
            color: 'white',
            minWidth: '100%',
            minHeight: 60,
          }}
          onClick={resetQrCodeReader}
          type="button">
          Escanear
        </MaterialButton>
      </Grid>
    );
  }

  return (
    <Container maxWidth="xl" style={{ paddingBottom: '4rem', background: 'white', height: '90%' }}>
      <Box>
        {scan ? (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={3} xs={12} />
            <Grid md={6} item xs={12}>
              {loading ? (
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item style={{ marginTop: '45%', textAlign: 'center' }}>
                    <LoadingProgress />
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        marginTop: 12,
                        fontWeight: 100,
                        fontSize: 14,
                      }}>
                      Processando...
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                  <SecondaryButton
                    onClick={() => cancelarQrCodeReader()}
                    style={{ width: '100%', margin: '8px 0 0' }}
                    color="primary">
                    Cancelar
                  </SecondaryButton>
                </>
              )}
            </Grid>
            <Grid item md={3} xs={12} />
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={4} xs={12} />
            <Grid md={4} container item xs={12}>
              {renderLogoGrid()}
              {renderValidatorTitleGrid()}
              {renderAlertaAndButtonGrid()}
            </Grid>
            <Grid item md={4} xs={12} />
          </Grid>
        )}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    validations: state.validations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeReader);
