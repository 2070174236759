const INITIAL_STATE = {
  loading: false,
  list: [],
  total: 0,
  usersLoading: false,
  users: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OWNER_USERS_LOAD':
      return { usersLoading: action.payload };
    case 'OWNER_USERS_FETCHED':
      return { ...state, users: action.payload, usersLoading: false };
    default:
      return state;
  }
};
