import React from 'react';
import { Button, FontIcon } from 'react-md';
import { Link } from 'react-router';

class NotAuthorized extends React.Component {
  render() {
    return (
      <div className="container-login" style={{ overflow: 'none' }}>
        <h2
          style={{
            position: 'relative',
            top: '3em',
            textAlign: 'center',
            color: '#ffffff',
            margin: '0 auto',
            paddingTop: '40px',
            paddingBottom: '35px',
            background: 'rgb(255,255,255)',
            marginLeft: '30px',
            marginRight: '30px',
            borderRadius: '20px',
          }}>
          <FontIcon
            style={{
              color: '#0adf9f',
              fontSize: '3em',
            }}>
            cancel
          </FontIcon>
          <p
            style={{
              margin: 20,
            }}>
            Você não possui um estabelecimento vinculado ao seu usuário.
            <br /> <br /> Favor, falar com a equipe ou tentar novamente.
          </p>
        </h2>
        <Link to="/logout">
          <Button
            className="btn btn-lg btn-primary btn-block text-uppercase"
            flat
            style={{
              border: 'none',
              borderRadius: '50px',
              background: '#0adf9f',
              color: 'white',
              overflow: 'hidden',
              position: 'fixed',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, -50%)',
              height: 50,
              width: '80%',
            }}
            type="button">
            Tentar Novamente
          </Button>
        </Link>
      </div>
    );
  }
}

export default NotAuthorized;
