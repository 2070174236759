import {
  GET_FONT_BY_NAME,
  GET_FONT_BY_NAME_LOAD,
  GET_GOOGLE_FONTS,
  GET_GOOGLE_FONTS_LOAD,
} from './websiteActions';

const INITIAL_STATE = {
  loading: false,
  list: [],
  modal: false,
  website: {},
  buttonLoading: false,
  getGoogleFontsLoad: false,
  googleFontsData: [],
  getFontsByNameLoad: false,
  fontsByName: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case type.WEBSITE_LOAD:
      return {
        ...state,
        loading: payload,
      };
    case type.WEBSITE_FETCHED:
      return {
        ...state,
        list: payload.data,
        loading: false,
      };
    case type.WEBSITE_CREATED:
      return {
        ...state,
        modal: true,
        website: payload,
        loading: false,
      };
    case 'WEBSITE_MODAL':
      return {
        ...state,
        modal: payload,
      };
    case type.WEBSITE_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: payload,
      };
    case GET_GOOGLE_FONTS_LOAD:
      return {
        ...state,
        getGoogleFontsLoad: payload,
      };
    case GET_GOOGLE_FONTS:
      return {
        ...state,
        googleFontsData: payload,
      };
    case GET_FONT_BY_NAME_LOAD:
      return {
        ...state,
        getFontsByNameLoad: payload,
      };
    case GET_FONT_BY_NAME:
      return {
        ...state,
        fontsByName: payload,
      };
    default:
      return state;
  }
};
