const INITIAL_STATE = {
  loading: false,
  list: [],
  total: 0,
  points: 0,
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OWNER_POINTS_FETCHED':
      return { ...state, points: action.payload, loading: false };
    case 'OWNER_POINTS_LOAD':
      return { ...state, loading: action.payload };
    case 'OWNER_POINTS_BUTTON_LOAD':
      return {
        ...state,
        buttonLoading: action.payload,
      };

    default:
      return state;
  }
};
