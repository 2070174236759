import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 12px;
  background: white;
`;

export const Question = styled.div`
  width: 100%;
  height: 100px;
  background: #f7f7f7;
`;

export const AnswersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 12px;
`;
