import PropTypes from 'prop-types';
import { Box, Tooltip, Typography, ButtonGroup, Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isMobile } from 'react-device-detect';
import ProductDelivery from './productOrders/productDelivery';
import {
  getPagarmeData,
  getList,
  remove,
  getDigitalAccount,
  transferTotalBalance,
} from './salesActions';
import {
  cancelProductOrder,
  getOrdersWithId,
  getDeliverysWithId,
  updateObservationsAndDeliveryStatus,
  getDeliveryStatuses,
  refund,
} from './productOrders/productOrdersActions';
import ProductOrders from './productOrders/productOrders';
import DollarSign from '../../../../assets/titlesMobile/dollarSign.svg';
import {
  socket,
  connect as connectSocket,
  disconnect as disconnectSocket,
} from '../../../../services/socket.js';

const useStyles = makeStyles(styles);

const Sales = function (props) {
  const [tab, setTab] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
    props.getPagarmeData(props.auth.user.establishments[0].id, props.router);
    props.getDigitalAccount(props.auth.user.establishments[0].id, props.router);
  }, []);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].id);
    props.getPagarmeData(props.auth.user.establishments[0].id, props.router);
    props.getDigitalAccount(props.auth.user.establishments[0].id, props.router);

    disconnectSocket();
    // connectSocket({ establishment_id: props.auth.user.establishments[0].id });

    socket.on('refreshSales', () => {
      console.log('refreshSales');
      props.getList(props.auth.user.establishments[0].id);
    });

    socket.on('connect_error', (err) => {
      console.log('erro conexao websocket', err.message);
      setTimeout(() => connect({ establishment_id: props.auth.user.establishments[0].id }), 4000);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  function handleOpenModal() {
    setModal(true);
  }

  function handleCloseModal() {
    setModal(false);
  }

  function handleTransfer() {
    function cleanup() {
      props.getList(props.auth.user.establishments[0].stations[0].id);
      props.getPagarmeData(props.auth.user.establishments[0].id, props.router);
      props.getDigitalAccount(props.auth.user.establishments[0].id, props.router);
      handleCloseModal();
    }
    props.transferTotalBalance(props.auth.user.establishments[0].id, cleanup, props.router);
  }

  useEffect(() => {
    const samePurchaseArray = [];
    for (let i = 0; i < props.sales?.list?.content?.length; i += 1) {
      const item = props.sales?.list?.content[i];
      const index = samePurchaseArray.findIndex((secondItem) => {
        return secondItem.purchase_id === item.purchase_id;
      });
      if (index === -1) {
        samePurchaseArray.push({
          ...item,
        });
      } else {
        samePurchaseArray[index] = {
          ...samePurchaseArray[index],
          wallet_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].wallet_value) + parseFloat(item.wallet_value)
            ).toFixed(2)
          ),
          final_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].final_value) + parseFloat(item.final_value)
            ).toFixed(2)
          ),
        };
      }
    }
    console.log('sales: ', samePurchaseArray.length);
    setData(samePurchaseArray);
  }, [props.sales.list.content]);

  function renderNoAccountScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não possui uma conta bancária cadastrada para poder ver seu resumo de
                  vendas e/ou vender.
                </h2>
                <PrimaryButton onClick={() => props.router.push('/owner/profile')}>
                  Cadastre agora
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }
  if (props.sales.loading || props.sales.loadingPagarme || props.sales.digitalAccountLoading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (!props.auth.user.establishments[0].digital_account) {
    return renderNoAccountScreen();
  }
  return (
    <>
      <section id="sales">
        <div style={{ maxWidth: 1280 }}>
          <GridContainer>
            {isMobile ? (
              <div className="d-flex justify-content-start align-items-center container-fluid">
                <div style={{ width: 23, height: 23 }}>
                  <img src={DollarSign} alt="dollar Sign" style={{ width: 23, height: 'auto' }} />
                </div>
                <h4
                  style={{
                    fontSize: 20,
                    fontWeight: 400,
                    margin: 0,
                    marginLeft: 15,
                  }}>
                  Vendas
                </h4>
              </div>
            ) : null}
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">accessibility</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Vendas</p>
                  <h3 className={classes.cardTitle}>{data.length}</h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">dashboard</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Atividades online de vendas</p>
                  <h3 className={classes.cardTitle}> {props.sales.list.productActions?.count}</h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">attach_money</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Saldo disponível</p>
                  <h3 className={classes.cardTitle}>
                    R${' '}
                    {parseFloat(props.sales.list?.establishmentData?.avaliable_marketplace_points)
                      .toFixed(2)
                      .replace('.', ',')}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">money_off</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Saldo a ser liberado</p>
                  <h3 className={classes.cardTitle}>
                    R${' '}
                    {parseFloat(props.sales.list?.establishmentData?.unavaliable_marketplace_points)
                      .toFixed(2)
                      .replace('.', ',')}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
          <Box display="flex" alignItems="center" justifyContent="start">
            <Typography variant="h6">Gateway de pagamentos </Typography>
            <Tooltip
              title="Nossa plataforma utiliza a Pagar.me como adquirente, para que seus participantes consigam fazer compras com cartões de crédito, assim, você tem uma conta Pagar.me automaticamente."
              placement="top-start"
              style={{ marginLeft: 4 }}>
              <Icon color="default">help</Icon>
            </Tooltip>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenModal}
              style={{
                marginLeft: 4,
                textTransform: 'none',
              }}>
              Transferir saldo disponível
            </Button>
          </Box>

          <GridContainer style={{ marginTop: 20 }}>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">attach_money</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Saldo transferido</p>
                  <h3 className={classes.cardTitle}>
                    {props.sales.pagarme.transferred
                      ? `R$ ${parseFloat(props.sales.pagarme.transferred.amount / 100)
                          .toFixed(2)
                          .replace('.', ',')}`
                      : 'Falha ao obter dados'}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">attach_money</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Saldo disponível</p>
                  <h3 className={classes.cardTitle}>
                    {props.sales.pagarme?.available
                      ? `R$ ${parseFloat(props.sales.pagarme?.available.amount / 100)
                          .toFixed(2)
                          .replace('.', ',')}`
                      : 'Falha ao obter dados'}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">money_off</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Saldo a ser liberado</p>
                  <h3 className={classes.cardTitle}>
                    {props.sales.pagarme?.waiting_funds
                      ? `R$ ${parseFloat(props.sales.pagarme?.waiting_funds.amount / 100)
                          .toFixed(2)
                          .replace('.', ',')}`
                      : 'Falha ao obter dados'}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card style={{ position: 'relative' }} className="active-actions">
                <CardHeader color="primary" stats icon>
                  <CardIcon color="white">
                    <Icon color="primary">account_balance</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Adquirente</p>
                  <h5 className={classes.cardTitle}>Pagar.me</h5>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
          <Box display="flex" justifyContent="center">
            <ButtonGroup disableElevation variant="contained">
              <Button
                onClick={() => setTab(false)}
                color={!tab ? 'primary' : 'inherit'}
                style={{
                  textTransform: 'none',
                }}>
                Vendas Online
              </Button>
              <Button
                onClick={() => setTab(true)}
                color={tab ? 'primary' : 'inherit'}
                style={{
                  textTransform: 'none',
                }}>
                Vendas com pagamento na entrega (delivery)
              </Button>
            </ButtonGroup>
          </Box>
        </div>
        {!tab ? <ProductOrders {...props} /> : <ProductDelivery {...props} />}
      </section>
      {modal && (
        <Dialog
          open={modal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {'Deseja transferir saldo para conta bancária?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao confirmar, todo o saldo disponível será transferido para sua conta bancária
              cadastrada. Deseja prosseguir?
            </DialogContentText>
            <Typography variant="subtitle1" id="alert-dialog-description">
              Conta: {props.sales.digitalAccount.bank_account.conta}
              {props.sales.digitalAccount.bank_account.conta_dv
                ? `-${props.sales.digitalAccount.bank_account.conta_dv}`
                : ''}
            </Typography>
            <Typography variant="subtitle1" id="alert-dialog-description">
              Agência: {props.sales.digitalAccount.bank_account.agencia}
              {props.sales.digitalAccount.bank_account.agencia_dv
                ? `-${props.sales.digitalAccount.bank_account.agencia_dv}`
                : ''}
            </Typography>
            <Typography variant="subtitle1" id="alert-dialog-description">
              Código do banco: {props.sales.digitalAccount.bank_account.bank_code}
            </Typography>
            <Typography variant="subtitle1" id="alert-dialog-description">
              Responsável: {props.sales.digitalAccount.bank_account.legal_name}
            </Typography>
            <Typography>
              Quantia disponível:{' '}
              <Typography style={{ fontWeight: '700', display: 'inline' }}>
                R${' '}
                {parseFloat(props.sales.pagarme?.available.amount / 100)
                  .toFixed(2)
                  .replace('.', ',')}
              </Typography>
            </Typography>
            <Typography>
              Custo da transferência:{' '}
              <Typography style={{ fontWeight: '700', display: 'inline' }}>R$ 3,67</Typography>
            </Typography>
            <Typography>
              Quantia a ser transferida:{' '}
              <Typography style={{ fontWeight: '700', display: 'inline' }}>
                R${' '}
                {((parseInt(props.sales.pagarme?.available.amount) - 367) / 100)
                  .toFixed(2)
                  .replace('.', ',')}
              </Typography>
            </Typography>
            <DialogContentText id="alert-dialog-description" style={{ marginTop: 8 }}>
              Observação: é necessário um saldo de pelo menos R$ 5,00 para realizar a transferência.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={handleTransfer}
              color="primary"
              autoFocus
              disabled={props.sales.pagarme?.available.amount < 5 || props.sales.buttonLoading}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

Sales.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getDigitalAccount: PropTypes.func,
  getList: PropTypes.func,
  getPagarmeData: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  sales: PropTypes.shape({
    buttonLoading: PropTypes.any,
    digitalAccount: PropTypes.shape({
      bank_account: PropTypes.shape({
        agencia: PropTypes.any,
        agencia_dv: PropTypes.any,
        bank_code: PropTypes.any,
        conta: PropTypes.any,
        conta_dv: PropTypes.any,
        legal_name: PropTypes.any,
      }),
    }),
    digitalAccountLoading: PropTypes.any,
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.any,
      }),
      establishmentData: PropTypes.shape({
        avaliable_marketplace_points: PropTypes.any,
        unavaliable_marketplace_points: PropTypes.any,
      }),
      productActions: PropTypes.shape({
        count: PropTypes.any,
      }),
    }),
    loading: PropTypes.any,
    loadingPagarme: PropTypes.any,
    pagarme: PropTypes.shape({
      available: PropTypes.shape({
        amount: PropTypes.number,
      }),
      transferred: PropTypes.shape({
        amount: PropTypes.number,
      }),
      waiting_funds: PropTypes.shape({
        amount: PropTypes.number,
      }),
    }),
  }),
  transferTotalBalance: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sales: state.sales,
    productOrders: state.productOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      getPagarmeData,
      getDeliverysWithId,
      remove,
      getOrdersWithId,
      cancelProductOrder,
      updateObservationsAndDeliveryStatus,
      getDeliveryStatuses,
      getDigitalAccount,
      transferTotalBalance,
      refund,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
