import moment from 'moment';

export const Bool2Number = (data) => {
  if (data === 1) {
    return true;
  }
  return false;
};

export const ShowBoolean = (data, params) => {
  if (data === 1) {
    return Object.prototype.hasOwnProperty.call(params, 'true') ? params.true : 'Sim';
  }
  return Object.prototype.hasOwnProperty.call(params, 'true') ? params.false : 'Não';
};

export const String2Float = (data) => {
  return parseFloat(data);
};

export const changeDateToFormatWithDot = (date) => {
  const itemDate = date.split('.')[0];
  return moment(itemDate).format('DD/MM/YYYY HH:mm');
};

export const changeDateToFormat = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const String2Currency = (num) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(num);
};

export const handleWhiteSpaces = (value) => value && value.split(' ').join('').toLowerCase();

export const customCurrencyChanger = (currencyData, value) => {
  const { currency_multiplier, currency_name, currency_name_position } = currencyData;
  const customValue = value * currency_multiplier;

  if (currency_name_position === 'before_currency') {
    return `${currency_name} ${customValue.toFixed(2).replace('.', ',')}`;
  }
  // if currency_name_position === after_currency
  return `${customValue.toFixed(2).replace('.', ',')} ${currency_name}`;
};
