import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputFile } from 'common/components/fields/InputFile';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import UploadVideos from 'common/components/upload';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { isMobileOnly } from 'react-device-detect';
import { getList as getSectionsList } from '../websiteSections/websiteSectionsActions';
import { create, getDetail, initializeForm, setDetail, setLoading, update } from './aboutActions';

let AboutForm = function (props) {
  const { about, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    props.getSectionsList(props.auth.user.establishments[0].stations[0].id);
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(about, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      station: props.auth.user.establishments[0].stations[0].id,
    };
    if (values.image_or_video === 'image') {
      newValues.video_id = null;
    } else if (values.image_or_video === 'video') {
      newValues.image = null;
      newValues.video_id = values.videoIdField;
    }
    create_or_update(router, newValues, create, update);
  }
  const sectionsList = props.websiteSections.list.content
    .filter((item) => parseInt(item.type_id) !== 6)
    .map((item) => ({
      label: item.title ? item.title : `Sem título - Posição ${item.order}`,
      value: item.id,
    }));
  const linkTypes = [
    {
      label: 'Conteúdo Adicional',
      value: 'additional',
    },
    {
      label: 'Login',
      value: 'login',
    },
    {
      label: 'Cadastro',
      value: 'signup',
    },
    {
      label: 'Link externo',
      value: 'external',
    },
    {
      label: 'Sem link',
      value: 'nolink',
    },
  ];
  return props.about.loading || props.websiteSections.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="about-form" style={{ paddingBottom: isMobileOnly ? 100 : 0 }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Blocos</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={SelectLabel}
                      label="Seção"
                      name="section_id"
                      validate={[FORM_RULES.required]}
                      options={sectionsList}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Título (opcional)"
                      name="name"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Subtítulo (opcional)"
                      name="description"
                      rows={3}
                      multiline
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                {props.websiteSections?.list?.content?.filter(
                  (item) => parseInt(item.id) === parseInt(props.formValues?.section_id)
                )?.[0]?.type_id === 2 ||
                props.websiteSections?.list?.content?.filter(
                  (item) => parseInt(item.id) === parseInt(props.formValues?.section_id)
                )?.[0]?.type_id === 4 ? (
                  <>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12} md={4} lg={4}>
                        <Field
                          component={SelectLabel}
                          label="Imagem ou Video"
                          name="image_or_video"
                          options={[
                            {
                              label: 'Imagem',
                              value: 'image',
                            },
                            {
                              label: 'Video',
                              value: 'video',
                            },
                          ]}
                          validate={[FORM_RULES.required]}
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                    {props.formValues?.image_or_video === 'image' ? (
                      <GeneralGrid container spacing={3}>
                        <GeneralGrid item xs={12} md={4} lg={4}>
                          <Field
                            component={InputFile}
                            label={'Imagem .png, .jpg ou .gif'}
                            name="image"
                            validate={[FORM_RULES.required]}
                            margin="normal"
                          />
                        </GeneralGrid>
                      </GeneralGrid>
                    ) : props.formValues?.image_or_video === 'video' ? (
                      <GeneralGrid container spacing={3}>
                        <GeneralGrid item xs={12} md={4} lg={4}>
                          <Field
                            component={UploadVideos}
                            indexVideo={0}
                            multipleVideos={false}
                            formValues={props.formValues ? props.formValues : null}
                            name={`videoIdField`}
                          />
                        </GeneralGrid>
                      </GeneralGrid>
                    ) : null}
                  </>
                ) : (
                  <GeneralGrid container spacing={3}>
                    <GeneralGrid item xs={12} md={4} lg={4}>
                      <Field
                        component={InputFile}
                        label={'Imagem .png, .jpg ou .gif'}
                        name="image"
                        validate={[FORM_RULES.required]}
                        margin="normal"
                      />
                    </GeneralGrid>
                  </GeneralGrid>
                )}

                {props.formValues?.section_id &&
                props.websiteSections.list.content.filter(
                  (item) => parseInt(item.id) === parseInt(props.formValues.section_id)
                )[0].type_id === 2 ? (
                  <>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12} md={4} lg={4}>
                        <Field
                          component={SelectLabel}
                          label="Alinhamento"
                          name="image_left"
                          options={[
                            {
                              label: 'Imagem à esquerda e texto à direita',
                              value: true,
                            },
                            {
                              label: 'Imagem à direita e texto à esquerda',
                              value: false,
                            },
                          ]}
                          validate={[FORM_RULES.required]}
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                  </>
                ) : null}
                {!(props.formValues?.link_type === 'nolink') ? (
                  <GeneralGrid container spacing={3}>
                    <GeneralGrid item xs={12}>
                      <Field
                        component={InputLabelOutlined}
                        label="Botão"
                        name="button_label"
                        required
                        margin="normal"
                      />
                    </GeneralGrid>
                  </GeneralGrid>
                ) : null}

                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={SelectLabel}
                      label="Link"
                      name="link_type"
                      validate={[FORM_RULES.required]}
                      options={linkTypes}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                {props.formValues?.link_type === 'external' ? (
                  <GeneralGrid container spacing={3}>
                    <GeneralGrid item xs={12}>
                      <Field
                        component={InputLabelOutlined}
                        label="Link Externo"
                        name="external_link"
                        required
                        margin="normal"
                        validate={[FORM_RULES.required]}
                      />
                    </GeneralGrid>
                  </GeneralGrid>
                ) : null}
                {props.formValues?.link_type === 'additional' ? (
                  <>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12} md={4} lg={4}>
                        <Field
                          component={EditorConvertToHTMLActivity}
                          label="Texto"
                          name="html_description"
                          rows={3}
                          multiline
                          required
                          margin="normal"
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12} md={4} lg={4}>
                        <Field
                          component={InputFile}
                          label={'Imagem para área interna .png, .jpg ou .gif'}
                          name="internal_image"
                          margin="normal"
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                  </>
                ) : null}
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={6} md={4} lg={3} style={{ marginLeft: -8 }}>
                    <PrimaryButton
                      type="submit"
                      disabled={props.about.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}>
                      {props.about.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </GeneralGrid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

AboutForm.propTypes = {
  about: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  create: PropTypes.any,
  formValues: PropTypes.shape({
    image_or_video: PropTypes.string,
    link_type: PropTypes.string,
    section_id: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  getSectionsList: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
  websiteSections: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        filter: PropTypes.func,
      }),
    }),
    loading: PropTypes.any,
  }),
};

AboutForm = reduxForm({ form: 'aboutForm' })(AboutForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    about: state.about,
    formValues: getFormValues('aboutForm')(state),
    general: state.general,
    websiteSections: state.websiteSections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      getSectionsList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutForm);
