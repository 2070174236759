import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import { Box } from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import AddButton from 'common/components/buttons/addButton';
import Table from 'common/components/table/tableGeneral';
import { NameElement, ColumnsBelowSearch } from 'common/components/table/tableComponents';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { getList, remove } from './newsActions';

export function News(props) {
  const { router } = props;
  const { list, loading } = props.news;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const [columns] = useState([
    {
      field: 'title',
      title: 'Título',
      render: (rowData) => <NameElement name={rowData.title} />,
    },
    {
      field: 'subtitle',
      title: 'Subtítulo',
      render: (rowData) => <NameElement name={rowData.subtitle} />,
    },
    {
      field: 'actions',
      headerCenter: true,
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(props.news.list.content);
  }, [props.news.list.content]);

  function onRowClick(e, rowData) {
    router.push(`/owner/content/noticias/${rowData.id}/editar`);
  }

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].stations[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não fez nenhuma notícia</h2>
                <p>
                  Poste uma notícia na sua estação para que seus associados estejam sempre
                  atualizados.
                </p>
                <PrimaryButton onClick={() => props.router.push('/owner/content/noticias/novo')}>
                  Criar Notícia
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="news">
      <GenericConfirmationCard
        open={openConfirmation}
        loading={false}
        general={props.general}
        title="Deletar Notícia"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover esta notícia?"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            hasToolbar
            toolbarSearch
            title="Notícias"
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            dataType={'news'}
          />
        </GeneralGrid>
      </GeneralGrid>

      <AddButton onClick={() => props.router.push('/owner/content/noticias/novo')} />
    </section>
  );
}

News.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  general: PropTypes.any,
  getList: PropTypes.func,
  news: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
