import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Quiz from './quiz';
import QuizCreateForm from './create/quizCreateForm';
import ManageQuiz from './manage/manage';
import QuizEditForm from './edit/quizEditForm';

export default (
  <Route path="manage-quiz">
    <IndexRoute component={(props) => <Quiz />} />
    <Route path="create/:method" component={(props) => <QuizCreateForm {...props} />} />
    <Route path="create" component={(props) => <QuizCreateForm {...props} />} />
    <Route path=":id/editar" component={(props) => <QuizEditForm {...props} />} />
    <Route path="manage" component={(props) => <ManageQuiz {...props} />} />
  </Route>
);
