import axios from 'axios';
import { BASE_API } from 'config/consts';

export const VimeoApi = axios.create({
  baseURL: 'https://api.vimeo.com/videos/',
});

export const FanApi = axios.create({
  baseURL: BASE_API,
});
