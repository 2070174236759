import { makeStyles, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { appColors } from 'styles/colors';
import PropTypes from 'prop-types';

const useStylesSelectLabel = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
  inputLabel: {
    color: appColors.primary,
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fullWidth: {
    minWidth: '100%',
  },
}));
export function FilterSelect(props) {
  const {
    disabled,
    required,
    options,
    helperText,
    meta,
    input,
    loadingText,
    noOptionText,
    currentFontValue,
    label,
  } = props;
  const [value, setValue] = useState();
  const classes = useStylesSelectLabel();

  useEffect(() => {
    setValue(currentFontValue);
  }, [currentFontValue]);

  const handleChange = (_event, value) => {
    input?.onChange(value?.value);
    setValue(value?.value);
  };

  return (
    <div className={clsx(classes.root, classes.fullWidth)}>
      <FormControl
        aria-label="Tooltip do Formulário"
        variant="outlined"
        color="primary"
        className={classes.formControl}
        disabled={disabled}
        required={required}
        style={{ minWidth: '100%' }}>
        <Typography
          variant="caption"
          style={{
            color: '#838383',
            marginBottom: '.2rem',
            paddingLeft: '.2rem',
          }}>
          {label}
        </Typography>
        <Autocomplete
          id="font-select"
          loadingText={loadingText || 'Sem resultados'}
          noOptionsText={noOptionText || 'Sem resultados'}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.label === value}
          fullWidth
          loading
          clearOnEscape
          value={value}
          // onChange={(event, value) => {
          //   input?.onChange(value?.value);
          //   setValue(value?.value);
          // }}
          onChange={(event, value) => handleChange(event, value)}
          debug
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              id="font-family-selected"
              defaultValue={currentFontValue}
              variant="outlined"
              placeholder={currentFontValue}
              label={currentFontValue}
              {...params}
            />
          )}
        />
        {meta && meta.touched && meta.error ? (
          <FormHelperText>{(meta.touched && meta.error) || helperText}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

FilterSelect.propTypes = {
  currentFontValue: PropTypes.any,
  disabled: PropTypes.any,
  helperText: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.any,
  loadingText: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.any,
  }),
  noOptionText: PropTypes.string,
  options: PropTypes.any,
  required: PropTypes.any,
};
