import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import Button from '@material-ui/core/Button';
import 'react-image-crop/dist/ReactCrop.css';
import { BASE_S3 } from 'config/consts';
import Dropzone from 'react-dropzone';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { uploadImageFileToS3 } from 'modules/owner/pages/actions/actionsFile';
import Image from '@material-ui/icons/ImageOutlined';
import AlertDialogSlide from '../../dialog/dialog';

export default class InputEasyCutImage extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      src: null,
      crop: {
        unit: props?.unit || '%',
        width: 30,
        height: props.aspect === 'none' ? 30 : null,
        aspect: props.aspect === 'none' ? null : props.aspect,
      },
      imageLoading: false,
    };
  }

  onSelectFile = (files) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result, open: true }));
      reader.readAsDataURL(files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      const base64Image = canvas.toDataURL('image/jpeg', 0.92);
      const file = this.dataURLtoFile(base64Image, 'stationimage.jpeg');
      resolve({ base64: base64Image, file });
    });
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  uploadImage = async () => {
    // const url = await uploadImageToS3(this.state.croppedImgBase64);
    const data = await uploadImageFileToS3(this.state.file);
    this.props.input.onChange(data);
    this.setState({ imageLoading: false });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const { base64: croppedImgBase64, file } = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImgBase64, file });
    }
  }

  render() {
    function dropzoneProps() {
      return {
        accept: 'image/jpeg, image/png, image/gif',
        multiple: false,
        maxSize: 500000,
        onDropRejected: () =>
          meta.dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro: Imagem maior que 500kB',
              variant: 'error',
              open: true,
            },
          }),
      };
    }
    const { crop, croppedImgBase64, src } = this.state;
    const {
      meta: { touched, error },
      input: { value },
      label,
      meta,
    } = this.props;

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1500 + 1,
            color: '#fff',
          }}
          open={this.state.imageLoading}>
          <CircularProgress color="inherit" />{' '}
          <span style={{ marginLeft: 10 }}>Fazendo upload da imagem...</span>
        </Backdrop>
        <div className="App">
          <div>
            {/*
          <input type="file" accept="image/*" onChange={this.onSelectFile} /> */}
            <Box display="flex">
              <Dropzone onDropAccepted={this.onSelectFile} {...dropzoneProps()}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    display="flex"
                    flexGrow={1}
                    {...getRootProps()}
                    style={{
                      height: 'auto',
                      width: '100%',
                      borderWidth: 1,
                      borderColor: touched && error ? 'red' : 'gray',
                      borderStyle: 'dashed',
                      borderRadius: 5,
                      cursor: 'pointer',
                      backgroundColor: 'white',
                    }}>
                    {croppedImgBase64 ? (
                      <>
                        <input {...getInputProps()} />

                        <img src={croppedImgBase64} className="img-fluid" alt="Ícone do Troféu" />
                      </>
                    ) : value ? (
                      <>
                        <input {...getInputProps()} />
                        <div
                          style={{
                            position: 'relative',
                            textAlign: 'center',
                            color: 'white',
                            background: 'black',
                          }}>
                          <img
                            src={`${BASE_S3}/${value}`}
                            className="img-fluid"
                            alt="Ícone do Troféu"
                            style={{
                              width: '100%',
                              opacity: '0.7',
                            }}
                          />

                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <Image style={{ width: 50, color: 'white' }} />
                              <span style={{ fontFamily: 'Lato' }}>Trocar imagem</span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Box
                        display="flex"
                        flexGrow={1}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        p={3}>
                        <input {...getInputProps()} />
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            borderRadius: '50%',
                            backgroundColor: `white`,
                            height: 70,
                            width: 70,
                          }}>
                          <CloudUploadIcon
                            style={{
                              fontSize: 16,
                              color: touched && error ? 'red' : 'rgb(102, 102, 102)',
                            }}
                            className=""
                          />
                        </Box>

                        <Typography
                          variant="caption"
                          align="left"
                          style={{
                            textAlign: 'left',
                            color: touched && error ? 'red' : 'rgb(102, 102, 102)',
                          }}>
                          {label}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </div>
          {src && (
            <AlertDialogSlide
              visible={this.state.open}
              onClose={() => this.setState({ open: false })}
              title={`Edite e corte da forma que preferir${
                this.props.aspectLabel ? ` , na proporção ${this.props.aspectLabel}` : ''
              }`}
              maxWidth="sm">
              <>
                <div>
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      this.setState({ imageLoading: true, open: false }, () => {
                        this.uploadImage();
                      });
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: 'none',
                    }}>
                    Finalizar Edição
                  </Button>
                </div>
              </>
            </AlertDialogSlide>
          )}
        </div>
      </>
    );
  }
}

InputEasyCutImage.propTypes = {
  aspect: PropTypes.any,
  aspectLabel: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    dispatch: PropTypes.func,
    touched: PropTypes.func,
    error: PropTypes.func,
  }),
  unit: PropTypes.string,
};
