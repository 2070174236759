import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import MaterialGrid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { ColumnsBelowSearch, DateElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import { FORM_RULES } from 'helpers/validations';
import moment from 'moment';
import { finishTutorial } from 'pages/auth/authActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { isMobile } from 'react-device-detect';
import { cpfMask } from '../../../../helpers/masks';
import {
  dispatchErrorFalse,
  editPreRegister,
  getContactByEstablishmentId,
  getContactsByStationId,
  remove,
  setContactFormValues,
} from './contactsActions';

let Registereds = function (props) {
  useEffect(() => {
    props.getContactByEstablishmentId(props.auth.user.establishments[0].id);
  }, []);
  const { content } = props.contacts.list;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const defaultFilters = [
    {
      label: 'Filtrar entre as datas',
      field: 'betweenDates',
      component: 'datePicker',
      typeData: 'date',
      filterFunction: 3,
      startDate: new Date(),
      endDate: new Date(),
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const defaultColumnsMob = [
    {
      title: 'E-mail',
      field: 'email',
    },
    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  const defaultColumns = [
    {
      title: 'CPF',
      field: 'cpf',
    },
    {
      title: 'E-mail',
      field: 'email',
    },

    {
      title: 'Data',
      field: 'createdAt',
      // headerCenter: true,
      render: (rowData) => (
        <DateElement date={moment(rowData.createdAt).format('DD/MM/YYYY HH:mm')} />
      ),
    },
    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? defaultColumnsMob : defaultColumns;

  const [data, setData] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const filters = defaultFilters;

  // const [import, setImport] = useState('');
  useEffect(() => {
    setData(props.contacts.list.content);
  }, [props.contacts.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="contacts" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} justify="center" alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui contatos</h2>
                  <p className="">
                    Seus contatos são as pessoas que são ou não associados da sua empresa. Ao
                    importá-los, você poderá oferecer atividades promocionais para eles.
                  </p>
                  <PrimaryButton onClick={() => props.router.push('/owner/contacts/import')}>
                    Importar contatos
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Imagem de Tabela Vazia"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  const finishTutorial = () => {
    props.finishTutorial(props.auth.user.id, props.router, props.auth);
  };

  const handleDelete = async (id) => {
    await props.remove(id, props.auth.user.establishments[0].id);
    setOpenConfirmation(false);
  };

  function onRowClick(e, rowData) {
    setIsEditModalVisible(true);
    const values = {
      cpf: rowData.cpf,
      associated: rowData.associated === 'Sim',
      internal: rowData.internal === 'Sim',
      id: rowData.id,
    };
    props.setContactFormValues(values);
  }
  // passando como string, é true e false

  const onSubmit = (values) => {
    const values_prep = {
      cpf: values.cpf,
      associated: values.associated,
      internal: values.internal,
      plans: values.segment_plans,
      entities: values.entities,
    };

    return props.editPreRegister(values_prep, values.id);
  };

  if (props.contacts.loading) {
    return (
      <Box display="flex" justifyContent="center" height="100vh" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }

  const { first_time } = props.location.query;
  // const {handleSubmit} = props

  const { handleSubmit } = props;
  return (
    <>
      <section id="contacts">
        <GenericConfirmationCard
          cancelClose={() => setOpenConfirmation(false)}
          open={openConfirmation}
          loading={props.contacts.loading}
          general={props.general}
          title="Deletar Pré-Cadastro"
          confirmButtonColor={'primary'}
          text="Tem certeza que deseja remover esse pré-cadastro?"
          onClose={() => setOpenConfirmation(false)}
          onClick={() => {
            if (item) handleDelete(item);
          }}
        />
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
            <Table
              hasToolbar
              filters={filters}
              toolbarSearch
              defaultSelected={[]}
              title="Pré-Cadastros"
              columns={columns}
              rows={data}
              onRowClick={onRowClick}
              dataType={'cpf'}
              onChangeOption={() => {}}
              mobileTable={isMobile}
              hasBorder={!isMobile}
            />
          </GeneralGrid>
          <AlertDialogSlide visible={first_time} title="Finalize o tutorial">
            <MaterialGrid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center">
              <MaterialGrid item md={6} lg={6}>
                <img alt="" className="img-fluid" src={placeholder_illustration}></img>
              </MaterialGrid>
              <MaterialGrid item md={6} lg={6}>
                <Typography align="center" variant="subtitle1">
                  Finalize o tutorial e Comece a usar a plataforma!
                </Typography>
                <Box display="flex" justifyContent="center" mt={3}>
                  <PrimaryButton
                    fullWidth={false}
                    type="button"
                    disabled={props.auth.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}
                    onClick={() => finishTutorial()}>
                    {props.auth.buttonLoading ? 'Enviando' : 'Finalizar Tutorial'}
                  </PrimaryButton>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </AlertDialogSlide>
          <AlertDialogSlide
            visible={isEditModalVisible}
            title="Digite CPF do contato"
            height="95vh"
            maxWidth="sm"
            onClose={() => {
              setIsEditModalVisible(false);
            }}>
            <Form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="CPF"
                    name="cpf"
                    required
                    validate={[FORM_RULES.required]}
                    {...cpfMask}
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12}>
                  <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                    <Field component={InputSwitch} label={'É associado?'} name="associated" />
                  </Box>
                </MaterialGrid>
                <MaterialGrid item xs={12}>
                  <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                    <Field component={InputSwitch} label={'É empregado?'} name="internal" />
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
              <MaterialGrid container spacing={3} justify="center">
                <MaterialGrid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <PrimaryButton
                      type="submit"
                      disabled={props.contacts.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}
                      onClick={() => console.log(props.contacts)}>
                      {props.contacts.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
            </Form>
          </AlertDialogSlide>
        </GeneralGrid>
      </section>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Fab
          variant="extended"
          style={{ background: '#fff' }}
          onClick={() => props.router.push('/owner/contacts/import')}>
          <Add className={classes.extendedIcon} />
          Adicionar
        </Fab>
      </Box>
    </>
  );
};

Registereds.propTypes = {
  auth: PropTypes.shape({
    buttonLoading: PropTypes.any,
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  contacts: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  editPreRegister: PropTypes.func,
  finishTutorial: PropTypes.func,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getContactByEstablishmentId: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_time: PropTypes.any,
    }),
  }),
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  setContactFormValues: PropTypes.func,
};

Registereds = reduxForm({ form: 'editContactForm' })(Registereds);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contacts: state.contacts,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactByEstablishmentId,
      dispatchErrorFalse,
      finishTutorial,
      getContactsByStationId,
      setContactFormValues,
      editPreRegister,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Registereds);
