import React from 'react';
import { Route, IndexRoute } from 'react-router';
import CouponCodesForm from './couponCodesForm';
import Coupon from './index';

export default (
  <Route path="coupons">
    <IndexRoute component={(props) => <Coupon {...props} />} />
    <Route path="editar/:couponcode_id" component={(props) => <CouponCodesForm {...props} />} />
    <Route path="criar" component={(props) => <CouponCodesForm {...props} />} />
  </Route>
);
