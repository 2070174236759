import React from 'react';
import { Route, IndexRoute } from 'react-router';
import EditParticipant from './EditParticipant';
import Participants from './index';

export default (
  <Route path="participantes">
    <IndexRoute component={(props) => <Participants {...props} />} />
    <Route path=":id" component={(props) => <EditParticipant {...props} />} />
  </Route>
);
