/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import Grid from 'common/components/layout/grid/grid';
import _ from 'lodash';
import { Link } from 'react-router';
import './moduleCard.css';

const ModuleCard = (props) => {
  return (
    <Link to={props.path}>
      <Grid style={{ ...props.style, marginTop: 20 }} cols={props.cols}>
        <div
          className="info-box"
          style={{ background: props.bgc, color: props.color }}
          onClick={!_.isUndefined(props.onClick) ? props.onClick : null}>
          <span className="info-box-icon-full">
            {!_.isUndefined(props.icon)
              ? props.icon
              : !_.isUndefined(props.mdIcon)
              ? props.mdIcon
              : null}
          </span>
          <div className="info-box-content" style={{ marginLeft: 0, textAlign: 'center' }}>
            <span className="info-box-number font-dosis">{props.text}</span>
          </div>
        </div>
      </Grid>
    </Link>
  );
};

export default ModuleCard;

ModuleCard.propTypes = {
  bgc: PropTypes.any,
  color: PropTypes.any,
  cols: PropTypes.any,
  icon: PropTypes.any,
  mdIcon: PropTypes.any,
  onClick: PropTypes.any,
  path: PropTypes.any,
  style: PropTypes.any,
  text: PropTypes.any,
};
