// import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class OwnerUsers extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="modules">
        <div className="container-fluid">
          <Card>
            <CardTitle title="Para ativar seus usuários, basta pedir que coloquem esse código abaixo no momento do cadastro:" />
            <CardText>
              <center>
                <h2>{this.props.auth.user.establishments[0].activationString}</h2>
              </center>
              <h3>Ou que pedir para que escaneem o QR code abaixo:</h3>
              <center>
                <br />
                <QRCode
                  value={`http://localhost:3000/signup/${this.props.auth.user.establishments[0].activationString}/with-establisment`}
                  size={328}
                />
              </center>
            </CardText>
          </Card>
        </div>
      </section>
    );
  }
}

OwnerUsers.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerUsers);
