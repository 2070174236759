import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { BASE_S3 } from 'config/consts';
import moment from 'moment';
import React from 'react';
import LongMenu from '../../MoreVericMenu';
import './cardActionInfo.css';

export default function CardActionNew(props) {
  return (
    <GeneralGrid
      container
      justify="space-between"
      spacing={3}
      style={{
        backgroundColor: '#ffff',
        marginBottom: '2.5em',
        height: 190,
      }}>
      <GeneralGrid item sm={7} md={6} lg={8}>
        <GeneralGrid container spacing={3} style={{ height: '100%' }}>
          <GeneralGrid
            item
            sm={6}
            md={5}
            style={{
              height: 190,
              padding: 0,
              background: `url(${BASE_S3}/${props.data.image})`,
              backgroundSize: 'cover',
              backgroundPosition: `center`,
            }}
          />
          <GeneralGrid item sm={6} md={7} style={{ padding: '12px 12px 12px 35px' }}>
            <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
              <Box>
                <Typography variant="h6">{props.data.name}</Typography>
                <Typography style={{ color: '#959595' }} variant="subtitle1">
                  {props.summary}
                </Typography>
              </Box>
              <Box style={{ width: '100%', marginLeft: '-10px' }}>
                {props.hasResults ? (
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="space-between">
                      <Box style={{ width: 'fit-content' }}>
                        <Typography
                          variant="body1"
                          color="primary"
                          onClick={props.onClickResults}
                          className="links">
                          Resultados
                        </Typography>
                      </Box>
                      {props.data.type_id !== 2 && !props.reactivate ? (
                        <Box style={{ width: 'fit-content' }}>
                          <Typography
                            variant="body1"
                            color="primary"
                            onClick={props.onClickShare}
                            className="links">
                            Divulgar
                          </Typography>
                        </Box>
                      ) : null}
                      {props.reactivate ? (
                        <Typography
                          variant="body1"
                          color="primary"
                          onClick={props.onClickReactivate}
                          className="links">
                          Reativar
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="start">
                    <Box style={{ width: 'fit-content' }}>
                      <Typography
                        variant="body1"
                        color="primary"
                        onClick={props.onClick}
                        className="links">
                        Editar
                      </Typography>
                    </Box>
                    <Box style={{ width: 'fit-content' }}>
                      <Typography
                        variant="body1"
                        color="primary"
                        onClick={props.onClickDelete}
                        className="links">
                        Excluir
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid item sm={5} md={4} lg={3}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: '100%' }}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {props.edit && (
              <LongMenu
                hasEdit={!props.data.partner_id}
                onEdit={props.onClickEdit}
                onSuspend={props.onClickSuspend}
              />
            )}
            <p
              style={{
                marginBottom: 0,
                fontSize: 14,
                color: '#ffff',
                backgroundColor: props.condition[1],
                padding: '5px 10px',
              }}>
              {props.condition[0]}
            </p>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="center" alignItems="center">
              <SupervisorAccountIcon color="primary" fontSize="small" style={{ marginRight: 10 }} />
              <Typography component="span" variant="h5" color="primary">
                {`${' '}${props.data.userActions.length}`}
              </Typography>
              <Typography style={{ color: '#959595' }} component="span" variant="h5">
                {`/${props.data.limit}`}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" justifyContent="flex-end">
              <Typography style={{ color: '#707070' }} component="span" variant="subtitle1">
                início:{'  '}
                <Typography component="span" variant="subtitle1" color="primary">
                  {props.data.start !== 'Invalid date' && props.reactivate
                    ? `${props.data.start.slice(0, 10)} às ${props.data.start.slice(11, 16)}`
                    : props.data.start && props.data.start !== 'Invalid date'
                    ? `${moment(props.data.start).format('DD/MM/YYYY')} às ${moment(
                        props.data.start
                      ).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography style={{ color: '#707070' }} component="span" variant="subtitle1">
                fim:{'  '}
                <Typography component="span" variant="subtitle1" color="primary">
                  {props.data.end !== 'Invalid date' && props.reactivate
                    ? `${props.data.end.slice(0, 10)} às ${props.data.end.slice(11, 16)}`
                    : props.data.end && props.data.end !== 'Invalid date'
                    ? `${moment(props.data.end).format('DD/MM/YYYY')} às ${moment(
                        props.data.end
                      ).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </GeneralGrid>
    </GeneralGrid>
  );
}

CardActionNew.propTypes = {
  condition: PropTypes.any,
  data: PropTypes.shape({
    end: PropTypes.string,
    image: PropTypes.any,
    limit: PropTypes.any,
    name: PropTypes.any,
    partner_id: PropTypes.any,
    start: PropTypes.string,
    type_id: PropTypes.number,
    userActions: PropTypes.shape({
      length: PropTypes.any,
    }),
  }),
  edit: PropTypes.any,
  hasResults: PropTypes.any,
  onClick: PropTypes.any,
  onClickDelete: PropTypes.any,
  onClickEdit: PropTypes.any,
  onClickReactivate: PropTypes.any,
  onClickResults: PropTypes.any,
  onClickShare: PropTypes.any,
  onClickSuspend: PropTypes.any,
  reactivate: PropTypes.any,
  summary: PropTypes.any,
};
