/* eslint-disable consistent-return */
import axios from 'axios';
import { reset as resetForm, initialize, destroy } from 'redux-form';

import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import types from './types';

const URL = `${BASE_API}/cupoms/`;
const URL_COUPONS_TEMPLATES = `${BASE_API}/action-templates`;
const URL_ACTION = `${BASE_API}/actions`;

export const submitAction = (
  actionValues,
  router = undefined,
  create_or_update,
  id = undefined,
  publishAction
) => {
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    if (create_or_update === 'create') {
      axios
        .post(`${URL_ACTION}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        })
        .then((actionResponse) => {
          // console.log(actionResponse)
          dispatch([
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Operação realizada com sucesso',
                variant: 'success',
                open: true,
              },
            },
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
            destroyForm(),
          ]);
          router.push('/owner');
        })

        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else if (create_or_update === 'update') {
      axios
        .put(`${URL_ACTION}/${id}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((actionResponse) => {
          if (publishAction) {
            dispatch([publishAction()]); // dispatch só pra retornar na função
          } else {
            dispatch([
              { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
              destroyForm(),
            ]);
            if (router) {
              router.push('/owner/acoes/minhas-atividades');
            }
          }
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    }
  };
};

export const getListPage = (page) => {
  const request = axios.get(`${URL}?page=${page}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return (dispatch) => {
    dispatch([
      {
        type: types.COUPON_LOAD,
        payload: true,
      },
      {
        type: types.COUPONS_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const getListLimit = (number) => {
  const request = axios.get(`${URL}?limit=${number}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return (dispatch) => {
    dispatch([
      {
        type: types.COUPON_LOAD,
        payload: true,
      },
      {
        type: types.COUPONS_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  // console.log(id, "entrou");
  return (dispatch) => {
    // console.log(id, "dispatch", reject, resolve);
    dispatch({ type: types.COUPON_LOAD, payload: false });
    axios
      .delete(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.COUPON_LOAD,
            payload: false,
          },

          resolve(),
        ]);
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.COUPON_LOAD,
      payload: 'pending',
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const categories = response.data.category_actions.map((item) => ({
          id: item.category?.id,
          title: item.category?.name,
        }));
        const groupings = response.data.action_grouping?.map((item) => ({
          id: item.grouping?.id,
          title: item.grouping?.name,
        }));

        const products = response.data.products.map((item) => ({
          ...item,
          value: parseFloat(item.value),
          discount_value: parseFloat(item.discount_value),
        }));
        const questions = response.data.researches[0]?.questions?.map((question) => ({
          ...question,
          question: { id: question.id, text: question.question },
          type: question.type_id,
        }));
        const { target } = response.data;
        const initialValues = {
          ...response.data.cupons[0],
          ...response.data,
          categories_field: categories,
          groupings_field: groupings,
          from: target ? (target.age ? target.age.from : null) : null,
          to: target ? (target.age ? target.age.to : null) : null,
          states: target ? target.states : null,
          entities: target ? target.entities : null,
          associated: target ? target.associated : null,
          internal: target ? target.internal : null,
          segmentation_type: target ? (target.segment_users ? 'users' : 'public') : null,
          segment_users: target ? target.segment_users || [] : [],
          products,
          questions,
          has_product_order_parameter: !!(
            response.data.product_order_parameter_title ||
            response.data.product_order_parameter_subtitle
          ),
          has_giveaway: response.data.giveaway_number_rules.length > 0,
          giveaway_quantity:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].quantity
              : null,
          giveaway_id:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].giveaway_id
              : null,
          isThereTrophy: !!response.data.trophy_description,
        };
        if (parseFloat(initialValues.price) > 0 || parseFloat(initialValues.newPrice) > 0) {
          initialValues.discountPrice = initialValues.price - initialValues.newPrice;
          initialValues.prices = true;
        } else {
          initialValues.prices = false;
        }
        if (!initialValues.points || parseFloat(initialValues.points) === 0) {
          initialValues.isPaid = false;
        } else {
          initialValues.isPaid = true;
        }

        initialValues.expiration = new Date();
        dispatch([
          initializeForm(initialValues),
          {
            type: types.COUPON_DETAIL,
            payload: response.data[0],
          },
        ]);
      })
      .catch((e) => ({
        type: types.COUPON_LOAD,
        payload: 'rejected',
      }));
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    dispatch({
      type: types.COUPON_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: types.COUPON_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
      reset(),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('couponsForm', values);
};

export const reset = () => {
  return resetForm('couponsForm');
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

export const destroyForm = () => {
  return destroy('couponsForm');
};

export const getCupomTemplates = () => {
  return (dispatch) => {
    dispatch({ type: types.COUPON_TEMPLATES_LOAD, payload: true });
    axios
      .get(`${URL_COUPONS_TEMPLATES}/?where[type_action_id]=3&&where[type_action_id]=4`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.COUPON_TEMPLATES_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const dispatchFormReset = () => {
  return (dispatch) => {
    dispatch(destroy('couponsForm'));
  };
};

function submit(values, method, router = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';

  if (values.prizeOption === 'file') {
    values.fileName = values.rewardFile.name;
    values.fileType = values.rewardFile.type;

    return (dispatch) => {
      dispatch([
        { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
        { type: 'SUBMIT_PROGRESS', payload: 1 },
      ]);

      axios[method](`${URL}${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
        .then((response) => {
          /* dispatch([reset(), getList()]); */
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Operação realizada com sucesso',
                variant: 'success',
                open: true,
              },
            },
          ]);

          if (!_.isUndefined(router)) {
            if (firstTime) {
              router.push('/owner');
            } else {
              router.push('/owner/cupons');
            }
          }
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);

          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data.errors[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    };
  }
}

export const savePartialy = (values, router, setValue, value) => {
  const { id } = router.params;
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ACTION}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          getDetail(id),
          setValue(value + 1),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data.errors[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
