import _ from 'lodash';
import types from './types';

const INITIAL_STATE = {
  list: [],
  participantsList: [],
  detail: {},
  loading: true,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LIST_FETCHED:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case types.LIST_PARTICIPANTS_FETCHED:
      return {
        ...state,
        participantsList:
          _.isArray(action.payload) && action.payload.map((it) => ({ id: it.id, title: it.name })),
        loading: false,
      };
    case types.ITEM_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case types.LIST_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
