import axios from 'axios';
import { reset as resetForm, initialize } from 'redux-form';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import types from './types';

const URL = `${BASE_API}/owner-sections`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL}/?where[station_id]=${id}&[deletedAt]=null`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getListPage = (page) => {
  const request = axios.get(`${URL}?page=${page}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return (dispatch) => {
    dispatch([
      {
        type: types.LIST_LOAD,
        payload: true,
      },
      {
        type: types.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const getListLimit = (number) => {
  const request = axios.get(`${URL}?limit=${number}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return (dispatch) => {
    dispatch([
      {
        type: types.LIST_LOAD,
        payload: true,
      },
      {
        type: types.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const remove = (id, other = undefined) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.LIST_LOAD,
            payload: false,
          },

          // resolve(),
          getList(other),
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
        // reject();
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data,
        };
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: response.data,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: types.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('websiteSectionsForm', values);
};

export const reset = () => {
  return resetForm('websiteSectionsForm');
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

function submit(values, method, router = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: (progressEvent) =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
          },
        ]),
    })
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(router)) {
          if (firstTime) {
            router.push('/owner');
          } else {
            router.push('/owner/content/secoes');
          }
        }
      })
      .catch((e) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.messages) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.messages[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: false,
    });
  };
};
