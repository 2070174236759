import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getTransactionsByEstablishmentId from './buyActionsScripts';

export function StatementBuyPoints(props) {
  const { id } = props.auth.user.establishments[0];
  const { transactions, loading } = props.statementBuyActions;

  const defaultCols = [
    { field: 'createdAt', title: 'Data da Compra' },
    { field: 'points', title: 'Preço (R$)' },
    { field: 'action_template.name', title: 'Atividade' },
  ];
  const defaultColsMob = [
    { field: 'points', title: 'Preço (R$)' },
    { field: 'action_template.name', title: 'Atividade' },
  ];

  const columns = isMobile ? defaultColsMob : defaultCols;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getTransactionsByEstablishmentId(id);
  }, []);

  useEffect(() => {
    setData(props.statementBuyActions.transactions);
  }, [props.statementBuyActions.transactions]);

  function renderEmptyScreen() {
    console.log(props.statementBuyActions.transactions);
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não comprou créditos!</h2>
                <p>Créditos podem ser usados pelos seus associados para trocarem por benefícios.</p>

                <PrimaryButton onClick={() => props.router.push('/owner/points/buy')}>
                  Comprar créditos
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (transactions.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            searchPlaceholder={'Pesquisar'}
            hasToolbar={true}
            title="Extrato"
            columns={columns}
            rows={data}
            toolbarSearch
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

StatementBuyPoints.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getTransactionsByEstablishmentId: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  statementBuyActions: PropTypes.shape({
    loading: PropTypes.any,
    transactions: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    statementBuyActions: state.statementBuyActions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionsByEstablishmentId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementBuyPoints);
