import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Answers from 'common/components/quiz/Answers';
import Questions from 'common/components/quiz/Questions';
import ResultsLotties from 'common/components/ResultsLotties';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { getList as getCategoriesList } from 'modules/owner/pages/categories/categoriesActions';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';
import { Container } from './styles';
import { createResult } from '../researchPersonalityQuizActions';

const renderResults = (props) => {
  async function createResults() {
    const { data: id } = await createResult({
      research_id: props.formValues.researches[0].id,
    });
    props.fields.push(id);
  }
  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton noMargin onClick={() => createResults()}>
            Adicionar resultado
          </PrimaryButton>
        </Box>
      </li>
      {props.fields.map((result, index) => (
        <li key={index}>
          <ExpansionPanel
            key={index}
            label={`Resultado ${index + 1}`}
            footer={
              <Box px={3}>
                <SecondaryButton
                  noMargin
                  onClick={() => props.fields.remove(index)}
                  color="primary">
                  Excluir resultado
                </SecondaryButton>
              </Box>
            }
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#ffffff',
            }}>
            <ResultsLotties
              result={result}
              index={index}
              lottiesList={props.lottiesList}
              formValues={props.formValues}
            />
          </ExpansionPanel>
        </li>
      ))}
    </ul>
  );
};

const renderOptions = (props) => (
  <ul style={{ listStyleType: 'none' }}>
    <li>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>Adicionar opção</PrimaryButton>
      </Box>
    </li>
    {props.fields.map((option, index) => (
      <li key={index}>
        <GeneralGrid container style={{ marginBottom: 8 }}>
          <GeneralGrid item xs={6}>
            <Box display="flex" alignContent="center">
              <Field
                component={Answers}
                label={`Opção ${index + 1}`}
                name={`${option}.label`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              <FontAwesomeIcon
                style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                icon={faTrash}
                onClick={() => props.fields.remove(index)}
              />
            </Box>
          </GeneralGrid>
          <GeneralGrid item xs={6}>
            <Box display="flex" alignContent="center" justifyContent="center" height="100%">
              <Field
                component={SelectLabel}
                options={props.personalitiesList}
                label={`Esta é a resposta correta?`}
                name={`${option}.quiz_feedback_id`}
              />
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </li>
    ))}
  </ul>
);

const renderQuestions = (props) => {
  /* useEffect(() => {
    if (props.meta.submitFailed && props.meta.error) {
      props.parentProps.showToastr({
        message: props.meta.error,
        variant: 'error',
        open: true,
      });
    }
  }, [props.meta.submitFailed, props.meta.error]);
  console.log(props.parentProps); */

  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton onClick={() => props.fields.push()}>Adicionar pergunta</PrimaryButton>
        </Box>
      </li>
      {props.fields.map((question, index) => (
        <li key={index}>
          <Box>
            <SecondaryButton onClick={() => props.fields.remove(index)} color="primary">
              Excluir Pergunta
            </SecondaryButton>
          </Box>

          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12} sm={12}>
              <Field
                component={Questions}
                label={`Pergunta ${index + 1}`}
                name={`${question}.question`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              {props.meta.submitFailed && props.meta.error && (
                <Typography color="error" variant="caption">
                  {props.meta.error}
                </Typography>
              )}
            </GeneralGrid>

            <GeneralGrid item xs={12} sm={12}>
              <FieldArray
                name={`${question}.options`}
                component={renderOptions}
                personalitiesList={props.personalitiesList}
              />
            </GeneralGrid>
          </GeneralGrid>
        </li>
      ))}
    </ul>
  );
};

let ResearchPersonalityActionInteraction = function (props) {
  const onSubmit = (values) => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.results) {
      for (let i = 0; i < values.results.length; i++) {
        if (values.results[i].feedback_lottie_id === 'none') {
          values.results[i].feedback_lottie_id = null;
        }
      }
    }
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    props.onClickProgress(values);
  };

  const lottiesList = props.lotties.list.content.map((item) => {
    return {
      label: item.name,
      value: item.id,
      url: item.url,
    };
  });

  lottiesList.unshift({
    label: 'Nenhuma Animação',
    value: 'none',
    url: 'none',
  });

  function lottieChange(e) {
    if (props.formValues) {
      props.getLottiesListById(e.target.value);
    }
  }

  const personalitiesList = props.formValues.results
    ? props.formValues.results.map((result, index) => {
        return {
          value: result.id,
          label: `Resultado ${index + 1}`,
        };
      })
    : [];

  return (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          <Container>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12} sm={12}>
                <h4 style={{ fontWeight: 'bold' }}>Configure seus resultados:</h4>
              </GeneralGrid>
            </GeneralGrid>
            {_.isEmpty(props.formValues.questions) && (
              <p style={{ textAlign: 'left' }}>Ainda não há resultados. Adicione alguns!</p>
            )}
            <ExpansionList>
              <FieldArray
                name="results"
                component={renderResults}
                formValues={props.formValues}
                lottiesList={lottiesList}
                lottieChange={lottieChange}
                lotties={props.lotties}
              />
            </ExpansionList>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12} sm={12}>
                <h4 style={{ fontWeight: 'bold' }}>Configure suas perguntas:</h4>
              </GeneralGrid>
            </GeneralGrid>
            {_.isEmpty(props.formValues.questions) && (
              <p style={{ textAlign: 'center' }}>Ainda não há perguntas. Adicione algumas!</p>
            )}

            <FieldArray
              name="questions"
              component={renderQuestions}
              formValues={props.formValues}
              parentProps={props}
              personalitiesList={personalitiesList}
            />
          </Container>
          <GeneralGrid container spacing={3} style={{ marginBottom: 12 }}>
            {parseInt(props.formValues ? props.formValues.type_id : 0) !== 16 ? (
              <>
                <GeneralGrid item xs={12}>
                  <Field
                    component={InputSwitch}
                    label={'A atividade possui regulamento?'}
                    name="has_rules"
                    default={true}
                    disabled={props.disabled}
                  />
                </GeneralGrid>
                {props.formValues && props.formValues.has_rules && (
                  <GeneralGrid item xs={12}>
                    <Field
                      component={EditorConvertToHTMLActivity}
                      label={'Regulamento da atividade'}
                      name="rules"
                      border
                      rows={4}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>
                )}
              </>
            ) : null}
          </GeneralGrid>
          <GenericConfirmationCard
            open={props.openConfirmation}
            cancelClose={props.handleCancelClose}
            maxWidthFixed
            labelLeftButton={'Continuar aqui'}
            labelRightButton={props.buttonConfirmationLabel}
            general={props.general}
            confirmButtonColor={'primary'}
            title={props.confirmationTitle}
            text={props.confirmationSubtitle}
            onClose={() => props.setOpenConfirmation(false)}
            onClick={props.closeModal}
          />
        </div>
        <GeneralGrid
          container
          display="flex"
          justify="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            disabled={props.activeActions.buttonLoading}
            color="primary"
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}>
            Salvar e continuar
          </PrimaryButton>
        </GeneralGrid>
      </Form>
    </>
  );
};

ResearchPersonalityActionInteraction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    has_rules: PropTypes.any,
    questions: PropTypes.any,
    results: PropTypes.shape({
      map: PropTypes.func,
    }),
    type_id: PropTypes.any,
  }),
  general: PropTypes.any,
  getLottiesListById: PropTypes.func,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  lotties: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
  }),
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  setOpenConfirmation: PropTypes.func,
};

ResearchPersonalityActionInteraction = reduxForm({
  form: 'personalityQuizForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ResearchPersonalityActionInteraction);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    surveysRightAnswer: state.surveysRightAnswer,
    activeActions: state.activeActions,
    general: state.general,
    lotties: state.lotties,
    formValues: getFormValues('personalityQuizForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchPersonalityActionInteraction);
