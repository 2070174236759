import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputImageWithProcessing } from 'common/components/fields/InputImageWithProcessing';
import { ColorPickerInput } from 'common/components/fields/ColorPickerInput';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './websiteSectionsActions';

let WebsiteSectionsForm = function (props) {
  const { websiteSections, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(websiteSections, router, location, getDetail, setDetail);
    }
  }, []);

  function handleColorChange(color) {
    props.change('background_color', color);
  }
  function handleColorChange2(color) {
    props.change('title_color', color);
  }
  function handleColorChange3(color) {
    props.change('subtitle_color', color);
  }

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      station: props.auth.user.establishments[0].stations[0].id,
    };

    create_or_update(router, newValues, create, update);
  }

  const arrayTypes = [
    { label: 'Grid 2x3 (modelo original)', value: 1 },
    { label: 'Grid texto-imagem verticalizado', value: 2 },
    { label: 'Carousel', value: 3 },
    { label: 'Centralizado com imagem embaixo', value: 4 },
    { label: 'Atividades em Destaque', value: 5 },
    { label: 'Planos', value: 7 },
    { label: 'Formulário', value: 8 },
    { label: 'Hero', value: 9 },
  ];

  if (props.auth.user.establishments[0].stations[0].has_app) {
    arrayTypes.push({ label: 'Baixar o app', value: 6 });
  }

  return props.websiteSections.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="websiteSections-form" style={{ paddingBottom: isMobileOnly ? 100 : 0 }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Seções</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Titulo para o menu"
                      name="menu_title"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container justify="center" spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Título"
                      name="title"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid item xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Field
                      component={ColorPickerInput}
                      label={'Cor da fonte do título'}
                      name="title_color"
                      onColorChange={handleColorChange2}
                      cols="12 12 6 6"
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                </GeneralGrid>

                {props.formValues?.type_id && props.formValues?.type_id === 6 ? null : (
                  <>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Subtítulo"
                          name="subtitle"
                          rows={3}
                          multiline
                          margin="normal"
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12}>
                        <Box display="flex" justifyContent="flex-start">
                          <Field
                            component={ColorPickerInput}
                            label={'Cor da fonte do subtítulo'}
                            name="subtitle_color"
                            onColorChange={handleColorChange3}
                            cols="12 12 6 6"
                            validate={[FORM_RULES.required]}
                          />
                        </Box>
                      </GeneralGrid>
                    </GeneralGrid>
                  </>
                )}
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={SelectLabel}
                      label="Posição"
                      name="order"
                      options={[
                        { label: '0', value: 0 },
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                        { label: '5', value: 5 },
                        { label: '6', value: 6 },
                        { label: '7', value: 7 },
                        { label: '8', value: 8 },
                        { label: '9', value: 9 },
                        { label: '10', value: 10 },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={SelectLabel}
                      label="Tipo de seção"
                      name="type_id"
                      options={arrayTypes}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={SelectLabel}
                      label="Tipo de Fundo"
                      name="background_type"
                      options={[
                        { label: 'Imagem', value: 'image' },
                        { label: 'Cor', value: 'color' },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                {props.formValues?.background_type === 'image' ? (
                  <>
                    <GeneralGrid container spacing={3}>
                      <GeneralGrid item xs={12}>
                        <Field
                          component={InputImageWithProcessing}
                          label={'Imagem .png ou .jpg'}
                          cols="12 12 12 12"
                          name="background_image"
                          aspect={16 / 9}
                          aspectLabel={'16:9'}
                          validate={[FORM_RULES.required]}
                        />
                      </GeneralGrid>
                    </GeneralGrid>
                  </>
                ) : props.formValues?.background_type === 'color' ? (
                  <>
                    <GeneralGrid item xs={8}>
                      <Box display="flex" justifyContent="flex-start">
                        <Field
                          component={ColorPickerInput}
                          label={'Cor'}
                          name="background_color"
                          onColorChange={handleColorChange}
                          cols="12 12 6 6"
                          validate={[FORM_RULES.required]}
                        />
                      </Box>
                    </GeneralGrid>
                  </>
                ) : null}

                <GeneralGrid container>
                  <GeneralGrid item xs={8} md={4} lg={4} style={{ marginLeft: -8 }}>
                    <PrimaryButton
                      type="submit"
                      disabled={props.websiteSections.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}>
                      {props.websiteSections.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </GeneralGrid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

WebsiteSectionsForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  change: PropTypes.func,
  create: PropTypes.any,
  formValues: PropTypes.shape({
    background_type: PropTypes.string,
    type_id: PropTypes.number,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
  websiteSections: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
};

WebsiteSectionsForm = reduxForm({ form: 'websiteSectionsForm' })(WebsiteSectionsForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    websiteSections: state.websiteSections,
    formValues: getFormValues('websiteSectionsForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteSectionsForm);
