import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import ActionsTabs from 'common/components/navigation/actionsTab/activityTabs';
import AlertDialogSlide from 'common/components/dialog/dialogActivits';
import LoadingProgress from 'common/components/progress/loading';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import _ from 'lodash';
import { publishAction } from 'modules/owner/pages/actions/activeActions/activeActionsScripts';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import Informations from 'modules/owner/pages/actions/sections/information';
import Prize from 'modules/owner/pages/actions/sections/prize';
import Publish from 'modules/owner/pages/actions/sections/publish';
import { getList as getLottiesList } from '../../lotties/lottiesActions';
import {
  dispatchFormReset,
  getDetail,
  savePartialy,
  submitAction,
} from './researchRightAnswerActions';
import Interaction from './sections/interaction';

function Main(props) {
  const [value, setValue] = React.useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    props.getLottiesList();
  }, []);

  async function handleChange(values, router) {
    values.base64 = values.image.base64;
    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
    }

    values.body = {
      ...values,
      name: values.body_name,
      description: values.body_description,
    };

    values.type_action = values.type.id;
    delete values.body.image;
    delete values.body.base64;
    delete values.body.imageType;
    values.body = JSON.stringify(values.body);

    const selectedVideos = [];

    selectedVideos.push({
      video_id: values.videoIdField,
      name: '',
      description: '',
      required: false,
    });
    values.videos = [...selectedVideos];

    props.savePartialy(values, router, setValue, value);
  }

  function goBack() {
    setValue(value - 1);
  }
  function handleOpenConfirmation() {
    setOpenConfirmation(true);
  }
  function handleCancelClose() {
    setOpenConfirmation(false);
  }

  function handleNativeChange(e, newValue) {
    if (
      props.router.params.action === 'editar' ||
      props.router.params.action === 'proposta' ||
      props.router.params.action === 'rascunho'
    ) {
      setValue(newValue);
    } else {
      if (newValue < value) {
        setValue(newValue);
      }
    }
  }

  useEffect(() => {
    if (
      props.router.params.action === 'editar' ||
      props.router.params.action === 'proposta' ||
      props.router.params.action === 'rascunho'
    ) {
      props.getDetail(props.router.params.id);
    }
  }, []);

  const onSubmit = async (values) => {
    const { auth } = props;

    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
      values.base64 = imgToBase64(values.image);
    }

    const newValues = {
      ...values,
      partner: values.partner_id,
      limit: parseInt(values.limit),
      points: values.points,
      name: values.name,
      description: values.description,
      /* questions, */
      establishment: auth.user.establishments[0].id,
      station: auth.user.establishments[0].stations[0].id,
      type_action: values.type.id,
    };

    if (
      props.router.params.action === 'editar' ||
      props.router.params.action === 'proposta' ||
      props.router.params.action === 'rascunho'
    ) {
      newValues.research_id = props.surveysRightAnswer.detail.researches[0].id;
    }
    const target =
      values.segmentation_type === 'public'
        ? {
            age: {
              from: values.from,
              to: values.to,
            },
            states: values.states,
            associated: values.associated,
            internal: values.internal,
            plans: values.segment_plans,
            entities: values.entities,
          }
        : {
            segment_users: values.segment_users,
          };

    values.target = target;

    const create_or_update =
      props.router.params.action === 'editar' ||
      props.router.params.action === 'rascunho' ||
      props.router.params.action === 'proposta'
        ? 'update'
        : 'create';
    const id_action = props.router.params.id;

    let publish = null;
    if (props.router.params.action === 'rascunho') {
      publish = () => {
        return props.publishAction(
          props.router.params.id,
          props.router,
          'rightAnswerForm',
          'RIGHT_ANSWER_SURVEY_BUTTON_LOAD'
        );
      };
    }

    props.submitAction(values, props.router, create_or_update, id_action, publish);
  };

  function onDialogClose() {
    props.dispatchFormReset();
    const from = props.location.query.from ? props.location.query.from : 'online';
    props.router.push(
      props.router.params.action === 'editar'
        ? `/owner/acoes/minhas-atividades?from=${from}`
        : props.router.params.action === 'rascunho'
        ? '/owner/acoes/minhas-atividades?from=drafts'
        : props.router.params.action === 'proposta'
        ? '/owner/acoes/propostas'
        : '/owner'
    );
  }

  return (
    <AlertDialogSlide
      label={'Check resposta certa'}
      value={value}
      test={true}
      visible={true}
      height="95vh"
      form={props.formValues}
      onClose={handleOpenConfirmation}
      maxWidth={isMobile ? 'xl' : 'sm'}>
      {props.surveysRightAnswer.status === 'pending' ||
      props.surveysRightAnswer.status === 'idle' ? (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      ) : (
        <MaterialGrid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          sm={12}
          md={12}
          lg={12}>
          <ActionsTabs
            styles={{ marginBottom: 35, width: '100%' }}
            icons={[0, 2, 0, 0]}
            sectionTitles={[0, 2, 0, 0]}
            handleChange={handleNativeChange}
            onClickProgress={(values) => {
              if (values.submitFromButton) {
                return;
              }
              handleChange(values, props.router);
            }}
            goBack={goBack}
            activeTab={value}
            form="rightAnswerForm"
            tabs={[
              {
                content: (
                  <Informations
                    confirmationTitle={
                      props.router.params.action === 'editar'
                        ? 'Deseja sair da edição?'
                        : 'Deseja sair da edição?'
                    }
                    confirmationSubtitle={
                      props.router.params.action === 'editar'
                        ? 'Lembre-se, as informações que não foram salvas serão perdidas.'
                        : 'Lembre-se, as informações que não foram salvas serão perdidas.'
                    }
                    buttonConfirmationLabel={
                      props.router.params.action === 'editar'
                        ? 'Sim, sair da edição'
                        : 'Sim, sair da edição'
                    }
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    template={props.location.query.template}
                    type={props.location.query.type}
                    form="rightAnswerForm"
                    router={props.router}
                    onClickProgress={(values) => handleChange(values, props.router)}
                    disabled={props.router.params.action === 'editar'}
                  />
                ),
                icon: 'subject',
                label: 'Informações',
              },
              {
                content: (
                  <Interaction
                    confirmationTitle={
                      props.router.params.action === 'editar'
                        ? 'Deseja sair da edição?'
                        : 'Deseja sair da edição?'
                    }
                    confirmationSubtitle={
                      props.router.params.action === 'editar'
                        ? 'Lembre-se, as informações que não foram salvas serão perdidas.'
                        : 'Lembre-se, as informações que não foram salvas serão perdidas.'
                    }
                    buttonConfirmationLabel={
                      props.router.params.action === 'editar'
                        ? 'Sim, sair da edição'
                        : 'Sim, sair da edição'
                    }
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={(values) => handleChange(values, props.router)}
                    prevClick={goBack}
                    disabled={props.router.params.action === 'editar'}
                    router={props.router}
                  />
                ),
                icon: 'forum',
                label: 'Interações',
              },
              {
                content: (
                  <Prize
                    confirmationTitle={
                      props.router.params.action === 'editar'
                        ? 'Deseja sair da edição?'
                        : 'Deseja sair da edição?'
                    }
                    confirmationSubtitle={
                      props.router.params.action === 'editar'
                        ? 'Lembre-se, as informações que não foram salvas serão perdidas.'
                        : 'Lembre-se, as informações que não foram salvas serão perdidas.'
                    }
                    buttonConfirmationLabel={
                      props.router.params.action === 'editar'
                        ? 'Sim, sair da edição'
                        : 'Sim, sair da edição'
                    }
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={(values) => handleChange(values, props.router)}
                    prevClick={goBack}
                    router={props.router}
                    form="rightAnswerForm"
                    disabled={props.router.params.action === 'editar'}
                  />
                ),
                label: 'Recompensas',
                icon: 'loyalty',
              },
              {
                content: (
                  <Publish
                    confirmationTitle={
                      props.router.params.action === 'editar'
                        ? 'Deseja sair da edição?'
                        : 'Deseja sair da edição?'
                    }
                    confirmationSubtitle={
                      props.router.params.action === 'editar'
                        ? 'Lembre-se, as informações que não foram salvas serão perdidas.'
                        : 'Lembre-se, as informações que não foram salvas serão perdidas.'
                    }
                    buttonConfirmationLabel={
                      props.router.params.action === 'editar'
                        ? 'Sim, sair da edição'
                        : 'Sim, sair da edição'
                    }
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={(values) => onSubmit(values)}
                    prevClick={goBack}
                    form="rightAnswerForm"
                    disabled={props.router.params.action === 'editar'}
                    router={props.router}
                  />
                ),
                label: 'Publicação',
                icon: 'settings',
              },
            ]}></ActionsTabs>
        </MaterialGrid>
      )}
    </AlertDialogSlide>
  );
}

Main.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  dispatchFormReset: PropTypes.func,
  formValues: PropTypes.any,
  getDetail: PropTypes.func,
  getLottiesList: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.any,
      template: PropTypes.any,
      type: PropTypes.any,
    }),
  }),
  publishAction: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  savePartialy: PropTypes.func,
  submitAction: PropTypes.func,
  surveysRightAnswer: PropTypes.shape({
    detail: PropTypes.shape({
      researches: PropTypes.any,
    }),
    status: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contacts: state.contacts,
    surveysRightAnswer: state.surveysRightAnswer,
    formValues: getFormValues('rightAnswerForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitAction,
      getDetail,
      dispatchFormReset,
      getLottiesList,
      savePartialy,
      publishAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
