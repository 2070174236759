import React from 'react';
import { Route, IndexRoute } from 'react-router';
import share from 'modules/owner/pages/actions/sections/share/index';
import Main from './main';
import Results from './sections/results';

export default (
  <Route path="quiz-personalidade">
    <IndexRoute component={(props) => <Main {...props} />} />
    <Route path=":id/:action">
      <IndexRoute component={(props) => <Main {...props} />} />
    </Route>
    <Route path="resultados" component={Results} />
    <Route path="divulgar" component={share} />
  </Route>
);
