import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NewSelectMultiple from 'common/components/fields/NewSelectMultiple';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import Button from '@material-ui/core/Button';
import { Field, reduxForm, getFormValues, Form } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import CopyPasteIcon from '@material-ui/icons/FilterNone';
import CloseIcon from '@material-ui/icons/Close';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';
import { createCouponCode, getCouponCodeById, editCouponCode } from './couponCodesActions';
import { getStationActiveActions } from '../activeActions/activeActionsScripts';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

function CouponCodesForm(props) {
  const [couponCodes, setCouponCodes] = useState([]);
  const { couponcode_id } = props.router.params;
  const { id } = props.auth.user.establishments[0].stations[0];
  const onlyNumbers = (values) => values && values.replace(/[^\d]/g, '');
  const { detail } = props.couponCodes;
  const opcoesActions = props.activeActions.list.map((it) => ({
    id: it.id,
    title: it.name,
  }));

  useEffect(() => {
    if (_.isEmpty(props.activeActions.list)) {
      props.getStationActiveActions(id);
    }
  }, []);

  useEffect(() => {
    if (couponcode_id) {
      props.getCouponCodeById(couponcode_id);
    }
  }, [couponcode_id]);

  useEffect(() => {
    if (!_.isEmpty(detail?.coupon_codes)) {
      setCouponCodes(detail?.coupon_codes?.map((it) => ({ id: it.id, code: it.code })));
    }
  }, [detail]);

  const handleAddCouponCode = () => {
    const {
      formValues: { code },
    } = props;
    const codeCleaned = code?.toUpperCase()?.trim();
    if (!_.isEmpty(codeCleaned) && _.isEmpty(couponCodes.filter((it) => it.code === codeCleaned))) {
      setCouponCodes([...couponCodes, { id: `_${couponCodes.length + 1}`, code: codeCleaned }]);
    }
    props.change('code', null);
  };

  const handleCloseCouponCode = (code) => {
    setCouponCodes([...couponCodes.filter((it) => it.id !== code.id)]);
  };

  const handleClipboardClick = (code) => {
    navigator.clipboard.writeText(code);
    toastr.success('Copiado!', `Código ${code} copiado com sucesso!`);
  };

  function onSubmit(values) {
    const coupon_codes = couponCodes.map((it) => it.code);
    if (couponcode_id) {
      props.editCouponCode({ ...values, coupon_codes }, couponcode_id, props.router);
    } else {
      props.createCouponCode({ ...values, coupon_codes }, id, props.router);
    }
  }

  return (
    <Form
      role="form"
      onSubmit={props.handleSubmit(onSubmit)}
      noValidate
      style={{
        margin: isMobile ? '0px' : '50px auto',
      }}>
      <div style={{ marginLeft: isMobile ? 0 : 20, width: '80vw' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {couponcode_id ? 'Editar cupom' : 'Cadastrar cupom'}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ borderRight: '1px solid #C4C4C4' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="Título do cupom"
                  name="title"
                  validate={[FORM_RULES.required]}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  type="textarea"
                  multiline
                  rowsMax={6}
                  label={'Descrição'}
                  name="description"
                  rows={4}
                  placeholder="Breve descrição sobre o cupom"
                  cols="12 12 12 6"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label="Valor do desconto"
                  normalize={onlyNumbers}
                  name="percentage"
                  validate={[FORM_RULES.required, FORM_RULES.percentage]}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label="Quantia disponível"
                  name="amount"
                  normalize={onlyNumbers}
                  validate={[FORM_RULES.required]}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ borderRight: '1px solid #C4C4C4' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: -16 }}>
                <Field
                  component={NewSelectMultiple}
                  label={'Pesquisar'}
                  options={opcoesActions}
                  name="actions"
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={7}>
                <Field component={InputLabelOutlined} label="Cadastre o código aqui" name="code" />
              </Grid>
              <Grid item xs={5}>
                <Button onClick={handleAddCouponCode} color="primary">
                  criar código
                </Button>
              </Grid>
              <Grid item xs={12}>
                <List dense t component="nav">
                  {couponCodes.map((it) => (
                    <div key={it.id}>
                      <ListItem
                        button
                        key={it.id}
                        style={{ padding: 12 }}
                        onClick={() => handleClipboardClick(it)}>
                        <ListItemIcon>
                          <CopyPasteIcon style={{ color: '#5759FB' }} />
                        </ListItemIcon>
                        <ListItemText primary={it.code} style={{ color: '#043565' }} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={() => handleCloseCouponCode(it)}
                            aria-label="delete">
                            <CloseIcon style={{ color: '#C4C4C4' }} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <SecondaryButton onClick={() => props.router.push('/owner/coupons')} color="primary">
          Cancelar
        </SecondaryButton>
        <PrimaryButton type="submit" color="primary" disabled={props.couponCodes.buttonLoading}>
          {couponcode_id ? 'Editar cupom' : 'Cadastrar cupom'}
        </PrimaryButton>
      </Grid>
    </Form>
  );
}

CouponCodesForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  createCouponCode: PropTypes.func,
  editCouponCode: PropTypes.func,
  getCouponCodeById: PropTypes.func,
  couponCodes: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  getStationActiveActions: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      couponcode_id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    couponCodes: state.couponCodes,
    activeActions: state.activeActions,
    formValues: getFormValues('couponsForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCouponCode,
      getCouponCodeById,
      editCouponCode,
      getStationActiveActions,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'couponsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(CouponCodesForm)
);
