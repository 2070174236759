import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import MaterialGrid from '@material-ui/core/Grid';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import { Field, reduxForm, getFormValues, Form } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { createGiveaway, getGiveawayById, editGiveaway } from './giveawaysActions';

function GiveawaysForm(props) {
  const { giveaway_id } = props.router.params;
  const { id } = props.auth.user.establishments[0].stations[0];

  function onSubmit(values) {
    if (giveaway_id) {
      props.editGiveaway(values, giveaway_id, props.router);
    } else {
      props.createGiveaway(values, id, props.router);
    }
  }

  useEffect(() => {
    if (giveaway_id) {
      props.getGiveawayById(giveaway_id);
    }
  }, [giveaway_id]);

  return (
    <Form
      role="form"
      onSubmit={props.handleSubmit(onSubmit)}
      noValidate
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        margin: isMobile ? '0px' : '50px auto',
      }}>
      <div style={{ marginLeft: isMobile ? 0 : 20, width: '89vw' }}>
        <MaterialGrid container direction="column" justify="center" spacing={3}>
          <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
            {giveaway_id ? 'Editar sorteio' : 'Novo sorteio'}
          </Typography>
          <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Nome do sorteio"
              name="name"
              validate={[FORM_RULES.required]}
              required
            />
          </MaterialGrid>
          <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Descrição do sorteio"
              name="description"
              validate={[FORM_RULES.required]}
              required
            />
          </MaterialGrid>
          <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Inicio da participação"
              name="participation_start"
              type="datetime-local"
              validate={[FORM_RULES.required]}
              required
            />
          </MaterialGrid>
          <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Fim da participação"
              name="participation_end"
              type="datetime-local"
              validate={[FORM_RULES.required]}
              required
            />
          </MaterialGrid>
          <MaterialGrid item xs={12}>
            <Field
              component={InputLabelOutlined}
              label="Data de distribuição dos números da sorte"
              type="datetime-local"
              name="counting_date"
              validate={[FORM_RULES.required]}
              required
            />
          </MaterialGrid>
        </MaterialGrid>
      </div>
      <MaterialGrid container style={{ marginTop: 15, paddingLeft: 12 }}>
        <SecondaryButton onClick={() => props.router.push('/owner/giveaway')} color="primary">
          Cancelar
        </SecondaryButton>
        <PrimaryButton type="submit" color="primary" disabled={props.giveaways.buttonLoading}>
          {giveaway_id ? 'Editar' : 'Criar'}
        </PrimaryButton>
      </MaterialGrid>
    </Form>
  );
}

GiveawaysForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  createGiveaway: PropTypes.func,
  editGiveaway: PropTypes.func,
  getGiveawayById: PropTypes.func,
  giveaways: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      giveaway_id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    giveaways: state.giveaways,
    formValues: getFormValues('giveawayForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createGiveaway, getGiveawayById, editGiveaway }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'giveawayForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(GiveawaysForm)
);
