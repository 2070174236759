import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Plans from './plans';
import PlansEditPaymentForm from './plansEditPaymentForm';
import PlansForm from './plansForm';
import PlansPurchaseForm from './plansPurchaseForm';
import PlansUpgrade from './plansUpgrade';
import EnterprisePlanSupport from './enterprisePlanSupport';

export default (
  <Route path="planos">
    <IndexRoute component={(props) => <Plans {...props} />} />
    <Route path="novo" component={(props) => <PlansForm {...props} />} />
    <Route path=":id/editar" component={(props) => <PlansForm {...props} />} />
    <Route path="premium" component={(props) => <PlansUpgrade {...props} />} />
    <Route path=":id/:action" component={(props) => <PlansPurchaseForm {...props} />} />
    <Route path="editar-pagamento" component={(props) => <PlansEditPaymentForm {...props} />} />
    <Route
      path="suporte-plano-enterprise"
      component={(props) => <EnterprisePlanSupport {...props} />}
    />
  </Route>
);
