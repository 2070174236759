import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_CUSTOM_DOMAIN } from 'config/consts';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { FORM_RULES } from '../../../../../helpers/validations';
import { getStationData, updateSubdomain } from './subdomainActions';

let SubdomainForm = function (props) {
  useEffect(() => {
    props.getStationData(props.auth.user.establishments[0].stations[0]);
  }, []);

  function onSubmit(values) {
    const station = props.auth.user.establishments[0].stations[0];
    props.updateSubdomain(values, station, props.auth.user.id);
  }

  return props.auth.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="subdomain-form" style={{ paddingBottom: isMobileOnly ? 100 : 0 }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted pb-2">Domínio Personalizado</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <Typography>
                  No seu gerenciador de registros DNS, crie um registro do tipo A, de acordo com seu
                  subdomínio desejado e com o valor {BASE_CUSTOM_DOMAIN}
                </Typography>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="http://seusite.com.br/"
                      name="url"
                      required
                      margin="normal"
                      validate={[FORM_RULES.customDomain]}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={6} md={4} lg={3} style={{ marginLeft: -8 }}>
                    <PrimaryButton
                      type="submit"
                      disabled={props.subdomain.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}>
                      {props.subdomain.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </GeneralGrid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

SubdomainForm.propTypes = {
  auth: PropTypes.shape({
    loading: PropTypes.any,
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getStationData: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  subdomain: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  updateSubdomain: PropTypes.func,
};

SubdomainForm = reduxForm({ form: 'subdomainForm' })(SubdomainForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    subdomain: state.subdomain,
    formValues: getFormValues('subdomainForm')(state),
    general: state.general,
    websiteSections: state.websiteSections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationData,
      updateSubdomain,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubdomainForm);
