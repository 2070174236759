import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { connect } from 'react-redux';
import { COMPANY_COLORS } from '../../../../config/consts';

function GeneralTabs(props) {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: COMPANY_COLORS(props.colors).website.navbar.button.background,
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

  const StyledTab = withStyles((theme) => {
    return {
      root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
          opacity: 1,
        },
        outline: '0 !important',
        padding: '0 !important',
        display: 'flex',
        minWidth: '20px !important',
      },
    };
  })((props) => <Tab disableRipple {...props} />);

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
    },
  });

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const primary = COMPANY_COLORS(props.colors).website.navbar.button.background;
  const secundary = '#505050';

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid item container xs={12} className={classes.root}>
      {props.hiddenTabs ? (
        <Grid item container xs={12} alignItems="center" component={props.component}>
          {props.start}
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <AppBar className={classes.appBar} position="static">
            <StyledTabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              aria-label="Tabs"
              style={{
                marginBottom: '1rem',
              }}>
              <StyledTab
                style={{
                  color: value === 0 ? primary : secundary,
                  marginRight: '1rem',
                }}
                label="Geral"
                {...a11yProps(0)}
              />
              <StyledTab
                style={{ color: value === 1 ? primary : secundary }}
                label="Regulamento"
                {...a11yProps(1)}
              />
            </StyledTabs>
          </AppBar>
          <Grid item container xs={12} component="section">
            {value === 0 ? props.start : value === 1 ? props.rules : null}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

GeneralTabs.propTypes = {
  colors: PropTypes.any,
  component: PropTypes.any,
  hiddenTabs: PropTypes.any,
  rules: PropTypes.any,
  start: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
  };
};

export default connect(mapStateToProps)(GeneralTabs);
