import types from './types';

const INITIAL_STATE = {
  loading: false,
  list: [],
  loadingActionDetail: false,
  actionDetail: {},
  buttonLoading: false,
  loadingHistoryShare: false,
  stationUserActions: [],
  loadingStationUserActions: false,
  historyShare: [],
  segmentedShare: [],
  loadingPrivateActions: true,
  privateActions: [],
  loadingSegmentedShare: false,
  loadingActionResults: false,
  actionResults: {
    participations: [],
    multipleChoiceSelectOneQuestionsResults: [],
    multipleChoiceSelectManyQuestionsResults: [],
    multipleChoiceType4QuestionsResults: [],
    openQuestionsResults: [],
    quizResults: [],
  },
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ACTIVE_ACTIONS_LOAD':
      return { ...state, loading: action.payload };
    case 'ACTIVE_ACTIONS_FETCHED':
      return { ...state, list: action.payload, loading: false };
    case 'ACTION_SHARE_HISTORY_LOAD':
      return { ...state, loadingHistoryShare: action.payload };
    case 'ACTION_SHARE_HISTORY_FETCHED':
      return {
        ...state,
        historyShare: action.payload,
        loadingHistoryShare: false,
      };
    case 'ACTIVE_ACTIONS_TOTAL_LOAD':
      return { ...state, loadingActionResults: action.payload };
    case 'ACTIVE_ACTIONS_TOTAL_FETCHED':
      return {
        ...state,
        actionsTotal: action.payload,
        loadingActionResults: false,
      };
    case 'STATION_USER_ACTIONS_LOAD':
      return { ...state, loadingStationUserActions: action.payload };
    case 'STATION_USER_ACTIONS_FETCHED':
      return {
        ...state,
        stationUserActions: action.payload,
        loadingStationUserActions: false,
      };
    case 'ACTION_SHARE_SEGMENTED_LOAD':
      return { ...state, loadingSegmentedShare: action.payload };
    case 'ACTION_SHARE_SEGMENTED_FETCHED':
      return {
        ...state,
        segmentedShare: action.payload,
        loadingSegmentedShare: false,
      };
    case 'ACTION_RESULTS_LOAD':
      return { ...state, loadingActionResults: action.payload };
    case 'ACTION_RESULTS_FETCHED':
      return {
        ...state,
        actionResults: { ...state.actionResults, ...action.payload },
        actionDetail: action.payload.action,
        loadingActionResults: false,
      };
    case types.DETAIL_LOAD:
      return { ...state, loadingActionDetail: action.payload };
    case types.DETAIL_FETCHED:
      return {
        ...state,
        actionDetail: action.payload,
        loadingActionDetail: false,
      };
    case types.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };

    case 'FEATURED_PRIVATE_ACTIVITIES_LOAD':
      return {
        ...state,
        loadingPrivateActions: action.payload,
      };
    case 'FEATURED_PRIVATE_ACTIVITIES_FETCHED':
      return {
        ...state,
        privateActions: action.payload,
        loadingPrivateActions: false,
      };

    default:
      return state;
  }
};
