import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import PrimaryButton from 'common/components/buttons/primaryButton';
import Content from 'common/components/content/content';
import { InputSwitchGrey } from 'common/components/fields/InputSwitchGrey';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { cnpjMask } from 'helpers/masks';
import { validateCNPJ } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { Card } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Typography, Box } from '@material-ui/core';
import { appColors } from 'styles/colors';
import { getCitiesList, getStatesList } from '../addresses/addressesActions';
import { signupCompanyAndStation, changeField } from './createNewStationActions';
const lower = (value) => value && value.toLowerCase();

class createNewStationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogVisible: false,
    };
    props.getStatesList();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.formValues) {
      if (this.props.formValues.state && prevProps.formValues === undefined) {
        this.props.getCitiesList(
          this.props.addresses.statesList.find((item) => this.props.formValues.state === item.sigla)
            .id
        );
      } else if (
        this.props.formValues.state &&
        this.props.formValues.state !== prevProps.formValues.state
      ) {
        this.props.getCitiesList(
          this.props.addresses.statesList.find((item) => this.props.formValues.state === item.sigla)
            .id
        );
      }

      if (this.props.formValues.zip_code) {
        if (
          this.props.formValues.zip_code.length === 8 &&
          this.props.formValues.zip_code !== prevProps.formValues.zip_code
        ) {
          const response = await axios.get(
            `https://viacep.com.br/ws/${this.props.formValues.zip_code}/json/`
          );

          this.props.changeField('createNewStationForm', 'line_1', response.data.logradouro);
          console.log(response.data.uf);
          this.props.changeField(
            'createNewStationForm',
            'state',
            this.props.addresses.statesList.find((item) => response.data.uf === item.sigla).sigla
          );
        }
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ isDialogVisible: true });
  };

  handleClose = () => {
    this.setState({ isDialogVisible: false });
  };

  onSubmit = (values) => {
    // Validação de CNPJ

    const isCNPJValid = validateCNPJ(values.cnpj);

    if (!isCNPJValid) {
      this.setState({ isDialogVisible: true });
      return;
    }

    const newValues = {
      ...values,
      country: 'BR',
      email: this.props.auth.user.email,
      user_id: this.props.auth.user.id,
      owner: this.props.auth.user.id,
      type: 1,
    };

    const { router, auth } = this.props;
    values.type = 1;
    values.owner = this.props.auth.user.id;
    this.props.signupCompanyAndStation(newValues, router, auth);
  };

  render() {
    const { handleSubmit } = this.props;

    if (this.props.createNewStation.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    }
    return (
      <Content>
        <Card style={{ marginTop: 20 }}>
          <div className="container-fluid">
            <div className="col-auto modal-signup-text my-4">Cadastro comercial.</div>
            <form onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Nome fantasia"
                    name="name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Razão social resumida"
                    name="social_name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="CNPJ"
                    name="cnpj"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                    {...cnpjMask}
                  />
                </GeneralGrid>
              </GeneralGrid>

              <div className="col-auto modal-signup-text my-4">Informações básicas da estação</div>
              <GeneralGrid container spacing={3} alignItems="center">
                <GeneralGrid item xs={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Nome da sua Estação"
                    name="prettyName"
                    placeholder="Ex: ADVOCACIA SA"
                    validate={[FORM_RULES.required]}
                  />
                </GeneralGrid>
                <GeneralGrid item xs={3}>
                  <Field
                    component={InputLabelOutlined}
                    label="Subdomínio"
                    name="subdomain"
                    normalize={lower}
                    validate={[FORM_RULES.required, FORM_RULES.lowerCase]}
                  />
                </GeneralGrid>

                <GeneralGrid item xs={3}>
                  <Typography variant="body" style={{ color: appColors.primary }}>
                    .rilato.site
                  </Typography>
                </GeneralGrid>
              </GeneralGrid>
              <GeneralGrid item xs={5}>
                <Box display="flex" justifyContent="center" mb={3} mt={3}>
                  <Field
                    component={InputSwitchGrey}
                    label={'Estação será pública?'}
                    boxStyle={{
                      backgroundColor: '#f7f7f7',
                      padding: 16,
                      minWidth: 300,
                    }}
                    firstState="Sua estação será pública. Ou seja, o participante não terá que ser pré-credenciado para se cadastrar."
                    secondState="Selecione para configurar sua estação como pública. Em uma estação pública, qualquer pessoa pode se cadastrar."
                    disabled={this.props.disabled}
                    name="public"
                    // required
                    margin="normal"
                  />
                </Box>
              </GeneralGrid>

              <div className="row justify-content-start mb-3 mt-3">
                <div className="col">
                  <Box display="flex" justifyContent="center">
                    <PrimaryButton
                      type="submit"
                      disabled={this.props.createNewStation.buttonLoading}
                      progress={parseInt(this.props.general.submitProgress)}>
                      {this.props.createNewStation.buttonLoading ? 'Criando sua estação' : 'Enviar'}
                    </PrimaryButton>
                  </Box>
                </div>
              </div>
            </form>
          </div>
        </Card>
        <Dialog
          open={this.state.isDialogVisible}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">CNPJ inválido</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Verifique o CNPJ informado e tente novamente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    );
  }
}

createNewStationForm.propTypes = {
  addresses: PropTypes.shape({
    citiesList: PropTypes.shape({
      map: PropTypes.func,
    }),
    statesList: PropTypes.shape({
      find: PropTypes.func,
      map: PropTypes.func,
    }),
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  changeField: PropTypes.func,
  createNewStation: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    state: PropTypes.any,
    zip_code: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getCitiesList: PropTypes.func,
  getStatesList: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  signupCompanyAndStation: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    createNewStation: state.createNewStation,
    formValues: getFormValues('createNewStationForm')(state),
    addresses: state.addresses,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupCompanyAndStation,
      getCitiesList,
      getStatesList,
      changeField,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'createNewStationForm' })(createNewStationForm));
