import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CardMedia } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import img_actions from 'assets/img/galeria_de_atividades.png';
import { isMobile } from 'react-device-detect';
import { appColors } from '../../../styles/colors';
import { limitStringSize } from '../../../helpers/utils';

const styles = {
  card: {
    position: 'relative',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
    fontFamily: 'Roboto',
    fontWeight: '200',
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  media: {
    height: 190,
  },
  type: {
    fontFamily: 'Roboto',
    letterSpacing: 3,
    fontWeight: '400',
    fontSize: 11.5,
    textAlign: 'left',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 18,
    textAlign: 'left',
    cursor: 'pointer',
  },
  seeMore: {
    // float: 'right',
    color: appColors.primary,
  },
  edit: {
    float: 'left',
    color: 'gray',
  },
};

function SimpleCard(props) {
  const {
    classes,
    cardEmpty,
    router,
    styleCard,
    horizontalCard,
    image,
    onClick,
    name,
    description,
    doneAction,
    buttonLabel,
  } = props;

  if (cardEmpty) {
    return (
      <Card
        onClick={() => {
          router.push('/owner/acoes');
        }}
        elevation={0}
        style={{
          ...styleCard,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          width: horizontalCard ? 270 : 'default',
          height: horizontalCard ? 300 : 'default',
          border: '1px solid lightgray',
        }}
        className={classes.card}>
        <img src={img_actions} style={{ maxHeight: 130, width: 'auto' }} alt="Material Card" />
        <Typography className={classes.title} style={{ marginTop: 10 }}>
          Abrir loja de atividades
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      elevation={0}
      style={{
        ...styleCard,
        width: isMobile ? 178 : horizontalCard ? 270 : 265,
        height: isMobile ? 152 : horizontalCard ? 300 : 345,
        border: '1px solid lightgray',
      }}
      className={classes.card}
      onClick={isMobile ? onClick : null}>
      <CardMedia
        className={classes.media}
        style={{ height: isMobile ? 100 : horizontalCard ? 155.81 : 149.0625 }}
        image={image}
      />
      <CardContent
        style={{
          display: isMobile ? 'none' : undefined,
          paddingTop: 5,
        }}>
        <div style={{ marginBottom: 5, marginTop: 0 }}>
          <Typography className={classes.type} />
          <Typography onClick={onClick} className={classes.title}>
            {name}
          </Typography>
        </div>
        <Typography
          className={classes.pos}
          color="textSecondary"
          style={{ overflowWrap: 'break-word' }}>
          {limitStringSize(description, 65)}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 40,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: doneAction ? 'space-between' : 'flex-start',
          paddingLeft: 3,
        }}>
        <Button
          className={classes.seeMore}
          onClick={onClick}
          size="small"
          style={{
            textTransform: 'none',
            color: isMobile ? '#242424' : null,
            padding: '.8rem',
          }}>
          {isMobile ? name : buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
}

SimpleCard.defaultProps = {
  buttonLabel: 'Ver mais',
  horizontalCard: 'false',
  description: '',
};

SimpleCard.propTypes = {
  buttonLabel: PropTypes.string,
  cardEmpty: PropTypes.any,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  doneAction: PropTypes.any,
  horizontalCard: PropTypes.bool,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  styleCard: PropTypes.any,
};

export default withStyles(styles)(SimpleCard);
