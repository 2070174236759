import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';
import logo from 'assets/img/fan_logo.svg';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { MaterialCheckbox } from 'common/components/fields/MaterialCheckbox';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import { cpfMask, phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { appColors } from 'styles/colors';
import { handleWhiteSpaces } from '../../../helpers/dataTransformers';
import { showModal, signup, signupAdmin } from '../authActions';
import './signupStepForm.css';

class SignupStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: true,
      visible: false,
      checkbox: false,
    };
  }

  onSubmit(values) {
    this.props.signupAdmin(values, this.props.router);
  }

  handleClick() {
    this.setState({ stepBool: false });
  }

  /*  handleModal = () => {
        this.props.router.push("/confirmacao");
    }; */

  handleHide() {
    this.props.showModal();
  }

  handleGoBack() {
    this.setState({ stepBool: true });
  }

  onHide() {
    // console.log("onhide");
    this.setState({ visible: false });
  }

  handleChange(e) {
    this.setState({ checkbox: !this.state.checkbox });
  }

  renderStep1() {
    return (
      <Box mt={4}>
        <GeneralGrid container spacing={8} justify="center">
          <GeneralGrid item>
            <form onSubmit={this.props.handleSubmit((v) => this.onSubmit(v))} noValidate>
              <GeneralGrid container justify="center">
                <GeneralGrid item xs={8} md={4} lg={3}>
                  <Field
                    component={InputLabelOutlined}
                    helperText="Digite seu nome completo"
                    label="Nome"
                    name="name"
                    validate={[FORM_RULES.required]}
                  />
                </GeneralGrid>
              </GeneralGrid>
              <Box mt={3}>
                <GeneralGrid container justify="center">
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="E-mail"
                      name="email"
                      validate={[FORM_RULES.required, FORM_RULES.email]}
                      helperText="Digite seu melhor e-mail"
                      normalize={handleWhiteSpaces}
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </Box>
              <Box mt={3}>
                <GeneralGrid container justify="center">
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="CPF"
                      name="cpf"
                      validate={[FORM_RULES.required, FORM_RULES.cpf]}
                      normalize={handleWhiteSpaces}
                      {...cpfMask}
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </Box>
              <Box mt={3}>
                <GeneralGrid container justify="center">
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="Telefone"
                      name="tel"
                      validate={[FORM_RULES.required]}
                      helperText="Seu telefone"
                      normalize={handleWhiteSpaces}
                      {...phoneMask}
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </Box>
              <Box mt={3}>
                <GeneralGrid container spacing={0} justify="center">
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      type="password"
                      label="Senha"
                      name="password"
                      validate={[FORM_RULES.required]}
                      helperText="Digite uma senha segura"
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </Box>
              <Box mt={3}>
                <GeneralGrid container spacing={0} justify="center">
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      type="password"
                      label="Confirme sua senha"
                      name="password_confirmation"
                      validate={[FORM_RULES.required]}
                      helperText="Confirme sua senha"
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </Box>

              <Box display="flex" justifyContent="center" mt={3}>
                <Field
                  component={MaterialCheckbox}
                  name="checkbox"
                  label={
                    <Typography variant="body2" style={{ color: appColors.dark.level3 }}>
                      Li e aceito os{' '}
                      <a href="#/cadastrar" style={{ color: '#2D95EF' }}>
                        Termos de Uso
                      </a>{' '}
                      e{' '}
                      <a href="#/cadastrar" style={{ color: '#2D95EF' }}>
                        Política de Privacidade
                      </a>
                    </Typography>
                  }
                  type="checkbox"
                  validate={[FORM_RULES.requiredCheckbox]}
                />
              </Box>

              <GeneralGrid container spacing={0} justify="center">
                <GeneralGrid item xs={8} md={4} lg={3}>
                  <Box display="flex" justifyContent="center" mt={0}>
                    <PrimaryButton
                      style={{ padding: '12px 0' }}
                      fullWidth={true}
                      router={this.props.router}
                      type="submit"
                      onClick={this.handleModal}>
                      Inscrever
                    </PrimaryButton>
                  </Box>
                </GeneralGrid>
              </GeneralGrid>

              <Box display="flex" justifyContent="center" mt={3}>
                <Typography variant="body2" style={{ color: appColors.dark.level3 }}>
                  Já possui uma conta?{' '}
                  <a
                    href="#/login"
                    style={{
                      color: appColors.dark.level3,
                      textDecoration: 'underline',
                    }}>
                    Faça seu login!
                  </a>
                </Typography>
              </Box>
            </form>
          </GeneralGrid>
        </GeneralGrid>
      </Box>
    );
  }

  render() {
    return (
      <>
        <Container maxWidth="xl" style={{ paddingBottom: '4rem' }}>
          <GeneralGrid container spacing={8} justify="center" style={{ background: 'white' }}>
            <GeneralGrid item xs={8} md={4}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Link to="/owner">
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 8px',
                      justifyContent: 'center',
                    }}>
                    <img src={logo} alt="Rilato Logo" style={{ width: '100%', height: 'auto' }} />
                  </div>
                </Link>
              </Box>

              <Box display="flex" justifyContent="center" mt={5}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 32,
                  }}>
                  Crie sua conta
                </Typography>
              </Box>
              <Box display="flex" textAlign="center" justifyContent="center" mt={2}>
                <Typography variant="body1" style={{ color: appColors.dark.level3 }}>
                  Vamos começar? Cadastre-se para uma melhor experiência.
                </Typography>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          {this.renderStep1()}
          {/*
                    <CustomizedSnackbar /> */}
        </Container>
        <div
          style={{
            position: isMobile ? 'relative' : 'absolute',
            bottom: 0,
            background: '#FFFFFF',
            textAlign: 'center',
            width: '100%',
            padding: '1rem 0',
          }}>
          <Typography variant="body1" style={{ color: appColors.dark.level4 }}>
            2019 Todos os direitos reservados.{' '}
            <a href="#/termos" style={{ color: '#2D95EF', textDecoration: 'underline' }}>
              Preferências de cookies
            </a>
            ,{' '}
            <a href="#/termos" style={{ color: '#2D95EF', textDecoration: 'underline' }}>
              privacidade
            </a>{' '}
            e{' '}
            <a href="#/termos" style={{ color: '#2D95EF', textDecoration: 'underline' }}>
              termos de uso.
            </a>
          </Typography>
        </div>
        <CustomizedSnackbar />
      </>
    );
  }
}

SignupStepForm.propTypes = {
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  showModal: PropTypes.func,
  signupAdmin: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupStepForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      signupAdmin,
      showModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'signupStepForm' })(SignupStepForm));
