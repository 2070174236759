import PropTypes from 'prop-types';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import MoneySignIcon from '../../../../assets/icons/dollar-sign.svg';
import GiftIcon from '../../../../assets/icons/gift.svg';
import CalendarIcon from '../../../../assets/icons/calendar.svg';
import MoreHorizontalIcon from '../../../../assets/icons/more-horizontal.svg';
import { appColors } from '../../../../styles/colors';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-2.svg';

export default function PlansCard({
  amountLabel,
  frequencyLabel,
  trialLabel,
  handleActivation,
  isActive,
  planName,
  handleEdit,
  handleRemove,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  function handleMoreOptionsClick(event) {
    console.log('teste');
    setAnchorEl(event.currentTarget);
  }

  return (
    <Box
      pt={1}
      px={2}
      style={{
        backgroundColor: 'white',
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: appColors.v2.mediumGray,
        opacity: isActive ? 1 : 0.5,
      }}
      pb={3}>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <p className="h6-bold">{planName}</p>
        <Box>
          {/* <PurpleSwitch
            checked={isActive}
            onChange={handleActivation}
            name="checkedB"
            inputProps={{ 'aria-label': 'checkbox ativo/não tivo' }}
          /> */}
          {(handleEdit || handleRemove) && (
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMoreOptionsClick}>
              <img src={MoreHorizontalIcon} alt="Ícone de mais opções" />
            </Button>
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMoreOptionsClose}>
            {handleEdit && (
              <MenuItem onClick={handleEdit}>
                <EditIcon />{' '}
                <p className="h6" style={{ marginLeft: 16 }}>
                  Editar
                </p>
              </MenuItem>
            )}
            {handleRemove && (
              <MenuItem onClick={handleRemove}>
                <TrashIcon />{' '}
                <p className="h6" style={{ marginLeft: 16 }}>
                  Excluir
                </p>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: 16,
        }}>
        <img src={MoneySignIcon} alt="Ícone de dinheiro" />
        <p className="h7" style={{ marginLeft: 8 }}>
          Valor
        </p>
        <p className="h7" style={{ marginLeft: 8 }}>
          {amountLabel}
        </p>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: 16,
        }}>
        <img src={CalendarIcon} alt="Ícone de calendário" />
        <p className="h7" style={{ marginLeft: 8 }}>
          {frequencyLabel}
        </p>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: 16,
        }}>
        <img src={GiftIcon} alt="Ícone de presente" />
        <p className="h7" style={{ marginLeft: 8 }}>
          {trialLabel}
        </p>
      </Box>
    </Box>
  );
}

PlansCard.propTypes = {
  amountLabel: PropTypes.any,
  frequencyLabel: PropTypes.any,
  handleActivation: PropTypes.any,
  handleEdit: PropTypes.any,
  handleRemove: PropTypes.any,
  isActive: PropTypes.any,
  planName: PropTypes.any,
  trialLabel: PropTypes.any,
};
