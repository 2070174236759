import PropTypes from 'prop-types';
import GeneralTabs from 'common/components/navigation/actionsTab/tabs';
import { BASE_S3 } from 'config/consts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './activity.css';
import { Grid } from '@material-ui/core';
import ActivityCoupon from './activityCoupon/index';
import ActivityMemberGetMember from './activityMemberGetMember';
import ActivityPresentialCourse from './activityPresentialCourse';
import ActivityShare from './activityShare';
import ActivitySignUpAndEarn from './activitySignUpAndEarn';
import ActivitySurvey from './activitySurvey';
import ActivityAgreement from './activityAgreement';
import ActivityDelivery from './activityDelivery';
import ActivityRawContent from './activityRawContent';
import ActivityDonation from './activityDonation';
import ActivityRedirectInternal from './activityRedirectInternal';
import ActivityMemberGetPermission from './activityMemberGetPermission';
import { ActivityImageContainer, DomPurifyContentContainer } from './styles';
import GeneralTitle from '../../styles/styles';

class Activity extends Component {
  renderStart() {
    return (
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        xs={12}
        style={{
          backgroundColor: '',
        }}>
        <Grid item container alignItems="center" xs={12}>
          {this.props.formValues.type_id === 3 && (
            <ActivityCoupon formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 4 && (
            <ActivityPresentialCourse formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 2 && (
            <ActivitySignUpAndEarn formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 1 && (
            <ActivityMemberGetMember formValues={this.props.formValues} auth={this.props.auth} />
          )}

          {this.props.formValues.type_id === 19 && (
            <ActivityMemberGetPermission
              formValues={this.props.formValues}
              auth={this.props.auth}
            />
          )}
          {this.props.formValues.type_id === 5 && (
            <ActivityShare formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {(this.props.formValues.type_id === 7 ||
            this.props.formValues.type_id === 8 ||
            this.props.formValues.type_id === 9 ||
            this.props.formValues.type_id === 10 ||
            this.props.formValues.type_id === 11 ||
            this.props.formValues.type_id === 12 ||
            this.props.formValues.type_id === 6) && (
            <ActivitySurvey formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 14 && (
            <ActivityDelivery formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 15 && (
            <ActivityAgreement formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 16 && (
            <ActivityRawContent formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 17 && (
            <ActivityDonation formValues={this.props.formValues} auth={this.props.auth} />
          )}
          {this.props.formValues.type_id === 20 && (
            <ActivityRedirectInternal formValues={this.props.formValues} auth={this.props.auth} />
          )}
        </Grid>
      </Grid>
    );
  }

  renderRules() {
    return (
      <Grid item container alignItems="center" xs={12}>
        <DomPurifyContentContainer
          dangerouslySetInnerHTML={{ __html: this.props.formValues.rules }}
        />
      </Grid>
    );
  }

  render() {
    return (
      <Grid item container alignItems="center" justify="center" xs={12}>
        {/* IMAGE PREVIEW */}
        <Grid item container xs={11}>
          <ActivityImageContainer
            BASE_S3={BASE_S3}
            imagePath={
              typeof this.props.formValues?.image === 'string'
                ? this.props.formValues?.image?.indexOf('base64') > -1
                  ? this.props.formValues?.image
                  : `${BASE_S3}/${this.props.formValues?.image}`
                : `${BASE_S3}/${this.props.formValues?.image?.path}/${this.props.formValues?.image?.saved_name}`
            }
          />
        </Grid>
        {/* ACTIVITY PREVIEW TITLE */}
        <Grid item container xs={11}>
          <GeneralTitle isPreview>{this.props.formValues.name}</GeneralTitle>
        </Grid>
        {/* ACTIVITY PREVIEW CONTENT */}
        <Grid item container xs={11}>
          <GeneralTabs
            start={this.renderStart()}
            rules={this.renderRules()}
            hiddenTabs={!this.props.formValues.has_rules}
          />
        </Grid>
      </Grid>
    );
  }
}

Activity.propTypes = {
  auth: PropTypes.any.isRequired,
  formValues: PropTypes.shape({
    has_rules: PropTypes.any,
    image: PropTypes.shape({
      indexOf: PropTypes.func,
      path: PropTypes.any,
      saved_name: PropTypes.any,
    }),
    name: PropTypes.any,
    rules: PropTypes.any,
    type_id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Activity);
