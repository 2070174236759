import PropTypes from 'prop-types';
import React from 'react';

import Questions from '../../../quiz/QuestionsParticipant';
import Answers from '../../../quiz/AnswersParticipant';

import Styles from './styles';

export default function CheckListQuizParticipant({ question, options }) {
  return (
    <>
      {question && <Questions question={question} />}
      <Styles>
        {options &&
          options.map((item, index) => (
            <Answers
              key={index}
              background={item ? item.label.color : '#2D95EF'}
              textColor={item ? item.label.textColor : '#FFF'}
              text={item ? item.label.text : null}
              image={item ? item.label.base64 : null}
            />
          ))}
      </Styles>
    </>
  );
}

CheckListQuizParticipant.propTypes = {
  options: PropTypes.shape({
    map: PropTypes.func,
  }).isRequired,
  question: PropTypes.any.isRequired,
};
