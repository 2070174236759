import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  padding: 20px 25px;
  background: ${(props) =>
    !props.image ? (props.background ? props.background : '#333') : `url(${props.image})`};
  background-position: center;
  background-size: cover;
  opacity: ${(props) => (props.checked ? '0.7' : '1')};

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }

  p {
    cursor: pointer;
    color: ${(props) => props.textColor};
    text-align: center;
    line-height: 1.5;
    font-size: 24px;
    font-family: Roboto;
    margin: 0;
    width: 100%;
    height: fit-content;
    border: 0;
    resize: none;
    background: none;
    word-break: break-word;
  }
`;

export const TextImage = styled.input`
  color: ${(props) => props.textColor};
  width: 100%;
  height: 40px;
  font-size: 20px;
  text-align: center;
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 1px;
  transition: transform 0.2s;
`;

export const CheckBox = styled(CheckIcon)`
  position: absolute;
`;
