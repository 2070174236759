import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import types from './types';

const URL_COUPON_CODES = `${BASE_API}/coupons-list-table`;
const URL_COUPON_CODES_STATION = `${BASE_API}/coupons-station`;
const URL_COUPON = `${BASE_API}/coupons`;

export const getCouponsStationId = (station_id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_COUPON_CODES}/?where[active]=1&&where[station_id]=${station_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const getCouponCodeById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_COUPON}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const responseFormatted = {
          ...response.data,
          actions: response.data?.action_coupons?.map((it) => ({
            id: it.action.id,
            title: it.action.name,
          })),
          // decoracoes aqui...
        };
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: responseFormatted,
          },
          initializeForm(responseFormatted),
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

const initializeForm = (values) => {
  return initialize('couponsForm', values);
};

export const deleteCouponById = (couponcode_id, station_id, setVisible) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .delete(`${URL_COUPON}/${couponcode_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: types.BUTTON_LOAD, payload: false }, getCouponsStationId(station_id)]);
        setVisible({ visible: false });
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const createCouponCode = (values, station_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(`${URL_COUPON_CODES_STATION}/${station_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/coupons');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const editCouponCode = (values, couponcode_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .put(`${URL_COUPON}/${couponcode_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/coupons');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};
