import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { appColors } from 'styles/colors';

const CssTimeField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
})(TimePicker);

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function InputTimeOutlined(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.input.value || null);
  const classes = useStyles();

  useEffect(() => {
    if (props.input.value) {
      setSelectedDate(props.input.value);
    }
  }, [props.input.value]);

  function handleDateChange(date) {
    setSelectedDate(date);
    props.input.onChange(date);
  }

  const {
    meta: { touched, error },
    required,
    label,
    margin,
    helperText,
  } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.container}>
        <CssTimeField
          error={!!(touched && error)}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedDate}
          onChange={handleDateChange}
          inputVariant="outlined"
          disablePast
          format="HH:mm"
          ampm={false}
          autoOk
          disabled={props.disabled}
          required={required}
          helperText={(touched && error) || helperText}
          invalidDateMessage="Inválida"
          minDateMessage="Não é possível selecionar data antes de hoje"
          margin={margin}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

InputTimeOutlined.propTypes = {
  disabled: PropTypes.any,
  helperText: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  margin: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  required: PropTypes.any,
};
