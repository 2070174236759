import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Website from './website';
import WebsiteForm from './websiteFormCreate';
import AccessControl from './accessControl';

export default (
  <Route path="website">
    <IndexRoute component={(props) => <Website {...props} />} />
    <Route path="create" component={(props) => <WebsiteForm {...props} />} />
    <Route path="controle-de-acesso" component={(props) => <AccessControl {...props} />} />
  </Route>
);
