import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialGrid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';

import Cards from 'react-credit-cards';
import { MaterialCheckbox } from 'common/components/fields/MaterialCheckbox';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { FORM_RULES } from 'helpers/validations';
import 'react-credit-cards/es/styles-compiled.css';
import { phoneMask, dateMask } from 'helpers/masks';
import moment from 'moment';
import { cepMask, cpfMask, dateReducedMask } from '../../../../helpers/masks';
import { handleWhiteSpaces } from '../../../../helpers/dataTransformers';
import getEstablishmentPoints from '../points/pointsActions';
import { buyActionTemplate } from './actionsFile';

function BuyAction(props) {
  const theme = useTheme();
  const [focus, setFocus] = useState('');
  const [creditCardSwitch, setCreditCardSwitch] = useState(false);

  useEffect(() => {
    props.getEstablishmentPoints(props.auth.user.establishments[0].id);
  }, []);

  const { points, allocated_points } = props.points.points;
  const avaliable_points = (parseFloat(points) - parseFloat(allocated_points)).toFixed(2);

  function handleBuyAction() {
    const values = {
      action_template_id: parseInt(props.router.params.id),
      establishment_id: parseInt(props.auth.user.establishments[0].id),
      station_id: parseInt(props.auth.user.establishments[0].stations[0].id),
      type_action_id: parseInt(props.actions.templateDetail.type_action_id),
      method: 'points',
    };
    props.buyActionTemplate(values, props.setIsBuyActionModalVisible);
  }

  function handleInputFocus(e) {
    const fieldName = e.target.name.replace('payment.', '');
    if (fieldName === 'cvv') {
      setFocus('cvc');
      return;
    }
    setFocus(fieldName);
  }

  function onSubmit(values) {
    const newValues = {
      ...values,
      payment: {
        ...values.payment,
        birthday: moment(values.payment.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        save: false,
      },
      action_template_id: parseInt(props.router.params.id),
      establishment_id: parseInt(props.auth.user.establishments[0].id),
      method: 'credit_card',
    };
    console.log('envio: ', newValues);
    props.buyActionTemplate(newValues, props.setIsBuyActionModalVisible);
  }

  return (
    <MaterialGrid
      container
      spacing={3}
      style={{ background: theme.palette.background.main }}
      justify="center">
      <MaterialGrid
        item
        xs={11}
        style={{
          background: theme.palette.background.white,
          padding: theme.spacing(5),
          marginBottom: theme.spacing(5),
        }}>
        <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
          <MaterialGrid
            container
            spacing={3}
            justify="space-between"
            style={{ marginBottom: theme.spacing(3) }}
            alignItems="center">
            <MaterialGrid item xs={4}>
              <Typography
                style={{
                  color: theme.palette.text.main,
                  fontSize: '1.5rem',
                  fontWeight: 700,
                }}>
                Revise seu pedido
              </Typography>
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid container spacing={0} style={{ marginBottom: theme.spacing(1) }}>
            <MaterialGrid
              item
              xs={12}
              style={{ backgroundColor: '#707070', height: 10 }}></MaterialGrid>
          </MaterialGrid>
          <MaterialGrid
            container
            spacing={3}
            style={{
              marginTop: theme.spacing(5),
              marginBottom: theme.spacing(5),
            }}>
            <MaterialGrid item xs={12}>
              <Typography variant="body2" color="primary">
                Resumo do pedido
              </Typography>
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid container spacing={3} style={{ marginBottom: theme.spacing(3) }}>
            <MaterialGrid item xs={12}>
              <Typography
                style={{
                  color: theme.palette.text.main,
                  fontSize: '1.5rem',
                  fontWeight: 700,
                }}>
                {props.action.name}
              </Typography>
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid container spacing={3} style={{ marginBottom: theme.spacing(1) }}>
            <MaterialGrid item xs={12}>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12}>
                  <Typography variant="body2" color="primary">
                    Criado por: Fan.Projects
                  </Typography>
                </MaterialGrid>
              </MaterialGrid>
              <MaterialGrid container spacing={3} justify="space-between">
                <MaterialGrid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    py={3}
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: '1px 0px 0px 0px',
                      borderColor: 'lightgray',
                    }}>
                    <Typography variant="body2" style={{ color: theme.palette.text.main }}>
                      Valor desta
                    </Typography>
                    <Typography
                      variant="body2"
                      align="right"
                      style={{
                        color: theme.palette.text.main,
                        fontWeight: 700,
                      }}>
                      R$ {parseFloat(props.body.action_price).toFixed(2).replace('.', ',')}
                    </Typography>
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
              <MaterialGrid container spacing={3} justify="space-between">
                <MaterialGrid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    py={3}
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: '1px 0px 0px 0px',
                      borderColor: 'lightgray',
                    }}>
                    <Typography variant="body2" style={{ color: theme.palette.text.main }}>
                      Créditos disponíveis
                    </Typography>
                    <Typography
                      variant="body2"
                      align="right"
                      style={{
                        color: theme.palette.text.main,
                        fontWeight: 700,
                      }}>
                      R$ {parseFloat(avaliable_points).toFixed(2).replace('.', ',')}
                    </Typography>
                  </Box>
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>
            {parseFloat(props.body.action_price) !== 0 && (
              <MaterialGrid item xs={12}>
                <InputSwitchLarge
                  label={'Deseja pagar com cartão de crédito?'}
                  // required
                  margin="normal"
                  onChange={setCreditCardSwitch}
                  input={{
                    value: creditCardSwitch,
                    onChange: setCreditCardSwitch,
                  }}
                />
              </MaterialGrid>
            )}
          </MaterialGrid>

          {creditCardSwitch && (
            <>
              <Cards
                cvc={
                  (props.formValues && props.formValues.payment && props.formValues.payment.cvv) ||
                  ''
                }
                expiry={
                  (props.formValues &&
                    props.formValues.payment &&
                    props.formValues.payment.expiry) ||
                  ''
                }
                focused={focus}
                name={
                  (props.formValues && props.formValues.payment && props.formValues.payment.name) ||
                  ''
                }
                number={
                  (props.formValues &&
                    props.formValues.payment &&
                    props.formValues.payment.number) ||
                  ''
                }
                preview={true}
              />

              <MaterialGrid container spacing={3} style={{ padding: '0px 24px' }}>
                <MaterialGrid item md={12} lg={6} style={{ padding: '12px 6px 12px 0' }}>
                  <Box>
                    <Typography variant="h6" style={{ color: '#707070', marginBottom: 8 }}>
                      Dados do cartão
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Número do cartão"
                      type="tel"
                      name={`payment.number`}
                      normalize={handleWhiteSpaces}
                      inputProps={{
                        maxLength: 16,
                      }}
                      validate={[FORM_RULES.required, FORM_RULES.card_number]}
                      onFocus={handleInputFocus}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome escrito no cartão"
                      type="text"
                      name={`payment.name`}
                      validate={[FORM_RULES.required]}
                      onFocus={handleInputFocus}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="CPF do dono do cartão"
                      type="text"
                      name={`payment.cpf`}
                      validate={[FORM_RULES.required, FORM_RULES.cpf]}
                      onFocus={handleInputFocus}
                      {...cpfMask}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Data de Nascimento"
                      type="tel"
                      name="payment.birthday"
                      validate={[FORM_RULES.required]}
                      {...dateMask}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 12 }}>
                    <Box style={{ marginRight: 6, flex: 1 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="Expiração"
                        type="tel"
                        name={`payment.expiry`} /*
                    inputProps={{
                      maxLength: 4,
                    }} */
                        validate={[FORM_RULES.required, FORM_RULES.expiry, FORM_RULES.max(4)]}
                        onFocus={handleInputFocus}
                        {...dateReducedMask()}
                      />
                    </Box>
                    <Box style={{ marginLeft: 6, flex: 1 }}>
                      <Field
                        component={InputLabelOutlined}
                        label="CVC"
                        type="tel"
                        name={`payment.cvv`}
                        inputProps={{
                          maxLength: 3,
                        }}
                        validate={[FORM_RULES.required, FORM_RULES.cvc, FORM_RULES.max(3)]}
                        onFocus={handleInputFocus}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" style={{ marginTop: 12 }}>
                    <Field
                      component={MaterialCheckbox}
                      label="Deseja salvar os dados desse cartão para compras futuras?"
                      name={`payment.save`}
                    />
                  </Box>
                </MaterialGrid>
                <MaterialGrid item md={12} lg={6} style={{ padding: '12px 0px 12px 6px' }}>
                  <Box>
                    <Typography variant="h6" style={{ color: '#707070', marginBottom: 8 }}>
                      Endereço de cobrança
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Telefone"
                      type="tel"
                      inputlabelprops={{
                        shrink: true,
                      }}
                      name="payment.phone"
                      validate={[FORM_RULES.required]}
                      helperText="Digite seu Telefone"
                      {...phoneMask}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="CEP"
                      type="tel"
                      name={`payment.cep`}
                      validate={[FORM_RULES.required]}
                      {...cepMask}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Cidade"
                      type="text"
                      name={`payment.city`}
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Estado (UF)"
                      type="text"
                      name={`payment.state`}
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                  <Box style={{ marginTop: 12 }}>
                    <Field
                      component={InputLabelOutlined}
                      label="Logradouro"
                      type="text"
                      name={`payment.street`}
                      validate={[FORM_RULES.required]}
                    />
                  </Box>
                  <MaterialGrid style={{ marginTop: 12 }} container>
                    <MaterialGrid item xs={6}>
                      <Field
                        component={InputLabelOutlined}
                        label="Número"
                        type="tel"
                        name={`payment.street_number`}
                        validate={[FORM_RULES.required]}
                      />
                    </MaterialGrid>
                  </MaterialGrid>
                </MaterialGrid>
              </MaterialGrid>
            </>
          )}

          <MaterialGrid container spacing={3} justify="center">
            {!creditCardSwitch && (
              <MaterialGrid item xs={4}>
                <PrimaryButton
                  style={{ padding: '1rem 4rem' }}
                  onClick={() => handleBuyAction()}
                  type="button"
                  disabled={props.general.buttonLoading}
                  progress={props.general.submitProgress}>
                  {props.general.buttonLoading ? 'Contratando' : 'Contratar'}
                </PrimaryButton>
              </MaterialGrid>
            )}
            {creditCardSwitch && (
              <MaterialGrid item xs={6}>
                <PrimaryButton
                  style={{ padding: '1rem 4rem' }}
                  type="submit"
                  disabled={props.general.buttonLoading}>
                  Contratar com cartão
                </PrimaryButton>
              </MaterialGrid>
            )}
          </MaterialGrid>
        </Form>
      </MaterialGrid>
    </MaterialGrid>
  );
}

BuyAction.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.any,
  }),
  actions: PropTypes.shape({
    templateDetail: PropTypes.shape({
      type_action_id: PropTypes.any,
    }),
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  body: PropTypes.shape({
    action_price: PropTypes.any,
  }),
  buyActionTemplate: PropTypes.func,
  formValues: PropTypes.shape({
    payment: PropTypes.shape({
      cvv: PropTypes.any,
      expiry: PropTypes.any,
      name: PropTypes.any,
      number: PropTypes.any,
    }),
  }),
  general: PropTypes.shape({
    buttonLoading: PropTypes.any,
    submitProgress: PropTypes.any,
  }),
  getEstablishmentPoints: PropTypes.func,
  handleSubmit: PropTypes.func,
  points: PropTypes.shape({
    points: PropTypes.shape({
      points: PropTypes.any,
      allocated_points: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setIsBuyActionModalVisible: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    actions: state.actions,
    general: state.general,
    points: state.points,
    formValues: getFormValues('buyActionForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ buyActionTemplate, getEstablishmentPoints }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'buyActionForm', destroyOnUnmount: true })(BuyAction));
