import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize } from 'redux-form';
import types from './type';

const DELIVERY_ORDERS = `${BASE_API}/delivery-orders/?where[station_id]=`;
const CREATE_ORDER = `${BASE_API}/delivery-order`;
const VALIDATE_DELIVERY = `${BASE_API}/validate-delivery`;
const DELIVERY_ORDER = `${BASE_API}/delivery-orders/?where[id]=`;
const GET_GROCERY_LIST = `${BASE_API}/grocery-list`;

export const getOrdersWithId = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_ORDERS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_ORDERS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const createOrder = (values, router = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .post(`${CREATE_ORDER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        router.push('/owner/delivery');
        window.location.reload();
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const validateDelivery = (values, router = undefined, id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(`${VALIDATE_DELIVERY}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        router.push('/owner/delivery');
        window.location.reload();
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrderAndInitialize = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch(initialize('DeliveryReceiptForm', response.data[0]));
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrder = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_DETAIL_FETCHED,
            payload: response.data[0],
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getGroceryList = () => {
  return (dispatch) => {
    dispatch([
      {
        type: types.DELIVERY_GROCERY_LIST_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${GET_GROCERY_LIST}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.DELIVERY_GROCERY_LIST_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const initializeForm = (values) => {
  return (dispatch) => {
    dispatch(initialize('DeliveryForm', values));
  };
};
