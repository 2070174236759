import PropTypes from 'prop-types';
import React from 'react';

import Container from './styles';
import Questions from '../../../quiz/QuestionsParticipant';
import Answers from '../../../quiz/AnswersParticipant';

export default function QuizRightAnswer({ questions }) {
  return (
    <>
      {questions
        ? questions.map((item) => (
            <>
              {item ? (
                <>
                  <Questions question={item.question} />
                  <Container>
                    {item.options
                      ? item.options.map((Iitem, index) => (
                          <Answers
                            key={index}
                            background={
                              Iitem
                                ? Iitem.label.color
                                  ? Iitem.label.color
                                  : '#2D95EF'
                                : '#2D95EF'
                            }
                            textColor={
                              Iitem
                                ? Iitem.label.textColor
                                  ? Iitem.label.textColor
                                  : '#FFF'
                                : '#FFF'
                            }
                            text={Iitem ? (Iitem.label.text ? Iitem.label.text : null) : null}
                            image={Iitem ? (Iitem.label.base64 ? Iitem.label.base64 : null) : null}
                          />
                        ))
                      : null}
                  </Container>
                </>
              ) : null}
            </>
          ))
        : null}
    </>
  );
}

QuizRightAnswer.propTypes = {
  questions: PropTypes.shape({
    map: PropTypes.func,
  }).isRequired,
};
