import PropTypes from 'prop-types';
import Papa from 'papaparse';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { importParticipants } from './giveawaysActions';

function GiveawayImport({ auth, router, importParticipants }) {
  const { giveaway_id } = router.params;

  function handleFile(acceptedFiles) {
    const reader = new FileReader();
    acceptedFiles.forEach((file) => reader.readAsText(file, 'ISO-8859-1'));
    reader.onload = () => {
      console.log(reader.result, 'teste');
      Papa.parse(reader.result, {
        delimiter: ';',
        header: true,
        complete: (results) => {
          console.log(results);
          importParticipants(
            {
              participants: results.data,
              station_id: auth.user.establishments[0].station_id,
              giveaway_id,
            },
            router
          );
        },
      });
    };
  }

  return (
    <Dropzone onDrop={(acceptedFiles) => handleFile(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section style={{ margin: 30 }}>
          <div
            className="row align-items-center"
            {...getRootProps()}
            style={{
              width: '100%',
              height: 400,
              borderWidth: 2,
              borderColor: 'rgb(102, 102, 102)',
              borderStyle: 'dashed',
              borderRadius: 5,
              margin: 'auto',
              cursor: 'pointer',
            }}>
            <input {...getInputProps()} />
            <center className="justify-content-center">
              {
                'Arrasque aqui (ou clique) para subir sua planilha em .csv com apenas uma coluna com o nome "CPF" com os usuários que deverão ganhar números da sorte.'
              }
            </center>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

GiveawayImport.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  importParticipants: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      giveaway_id: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    giveaways: state.giveaways,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ importParticipants }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveawayImport);
