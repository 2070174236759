import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getTransactionsByEstablishmentId from './userEarnedPointsActions';

export function userEarnedPoints(props) {
  const { id } = props.auth.user.establishments[0];
  const { transactions, loading } = props.userEarnedPoints;

  const defaultCols = [
    { field: 'createdAt', title: 'Data da Compra' },
    { field: 'points', title: 'Créditos Comprados (R$)' },
  ];
  const defaultColsMob = [
    { field: 'points', title: 'Créditos Comprados (R$)' },
    { field: 'user.name', title: 'Usuário' },
  ];

  const columns = isMobile ? defaultColsMob : defaultCols;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getTransactionsByEstablishmentId(id);
  }, []);

  useEffect(() => {
    setData(props.userEarnedPoints.transactions);
  }, [props.userEarnedPoints.transactions]);

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Ninguém consumiu seus créditos até o momento!</h2>
                <p>Créditos podem ser usados pelos seus associados para trocarem por benefícios.</p>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (transactions.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            searchPlaceholder={'Pesquisar'}
            hasToolbar={true}
            title="Extrato"
            columns={columns}
            rows={data}
            toolbarSearch
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userEarnedPoints: state.userEarnedPoints,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionsByEstablishmentId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(userEarnedPoints);
