import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { limitStringSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddButton from 'common/components/buttons/addButton';
import Table from 'common/components/table/tableGeneral';
import { ColumnsBelowSearch } from 'common/components/table/tableComponents';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { getList, remove } from './aboutActions';

export function About(props) {
  const { router } = props;
  const { list, loading } = props.about;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);
  const MAX_LENGTH = 35;

  const defaultCols = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => (rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : ' '),
    },
    {
      field: 'description',
      title: 'Descrição',
      render: (rowData) =>
        rowData.description ? limitStringSize(rowData.description, MAX_LENGTH) : ' ',
    },
    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  const defaultColsMob = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => (rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : ' '),
    },

    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? defaultColsMob : defaultCols;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(list.content);
  }, [list.content]);

  function onRowClick(e, rowData) {
    router.push(`/owner/content/sobre/${rowData.id}/editar`);
  }

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].stations[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não editou a seção &quot;Sobre&quot; ainda
                </h2>
                <p>Adicione dados para aparecer no seu site!</p>
                <PrimaryButton onClick={() => props.router.push('/owner/content/sobre/novo')}>
                  Adicionar dados
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="about">
      <GenericConfirmationCard
        cancelClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
        loading={props.about.loading}
        general={props.general}
        title="Deletar Registro"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover esta linha?"
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            hasToolbar
            toolbarSearch
            title="Blocos"
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>

      <AddButton onClick={() => props.router.push('/owner/content/sobre/novo')} />
    </section>
  );
}

About.propTypes = {
  about: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  general: PropTypes.any,
  getList: PropTypes.func,
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    about: state.about,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
