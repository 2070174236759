import { getListTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  listDetail: {
    content: [],
  },
  loadingDetail: false,
  loading: false,
  detail: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PRODUCT_BY_OWNER_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.PRODUCT_BY_OWNER_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case types.PRODUCT_BY_OWNER_DETAIL_FETCHED:
      return {
        ...state,
        listDetail:
          getListTransform(action.payload.data?.single_product_orders) || INITIAL_STATE.listDetail,
        loadingDetail: false,
        detail: action.payload.data,
      };
    case types.PRODUCT_BY_OWNER_DETAIL_LOAD:
      return {
        ...state,
        loadingDetail: action.payload,
      };
    default:
      return state;
  }
};
