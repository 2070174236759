import PropTypes from 'prop-types';
import { Box, Fab, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { COMPANY_COLORS } from 'config/consts';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withStyles } from '@material-ui/styles';
import { appColors } from 'styles/colors';
import ActivityButton from '../../buttons/activityButton';
import CashbackCard from '../secudaryActivitiesCards/cashbackCard';
import PriceCard from '../secudaryActivitiesCards/priceCard';
import ShareCard from '../secudaryActivitiesCards/shareCard';
import './moduleCard.css';

const CssTextField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-multiline': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})(TextField);

const CallToActionCard = function (props) {
  if (props.formValues.type_id === 1) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          <CashbackCard
            type={props.formValues.type_id}
            cashback={props.formValues.points_inviter}
            invitedCashback={props.formValues.points_invited}
            secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
          />
        </Box>
        <div className="d-flex flex-column align-content-center">
          <p className="text-left" style={{ color: COMPANY_COLORS(props.colors).font.gray }}>
            Copie e compartilhe o link ou envie por e-mail abaixo:
          </p>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <textarea
              className="text-link"
              spellCheck="false"
              style={{
                resize: 'none',
              }}
              value="http://fanshortlink.io/asP89Ks"
            />
            <Fab
              style={{
                boxShadow: 'none',
                background: 'transparent',
                border: 'none',
              }}>
              <FileCopyIcon />
            </Fab>
          </Box>
          <Box
            style={{ marginTop: 20 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <CssTextField label="E-mail" variant="outlined" type="text" />
            <div style={{ marginTop: 20 }}>
              <ActivityButton color={COMPANY_COLORS(props.colors).primary} label="Enviar convite" />
            </div>
          </Box>
        </div>
      </>
    );
  }
  if (props.formValues.type_id === 2) {
    return null;
  }
  if (props.formValues.type_id === 3) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          {parseFloat(props.formValues.price).toFixed(2) > 0.0 ? (
            <PriceCard
              price={props.formValues.price}
              newPrice={props.formValues.newPrice}
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
              allowance={props.formValues.allowance}
            />
          ) : null}
          {props.formValues.points > 0 || props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? parseFloat(props.formValues.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={COMPANY_COLORS(props.colors).primary}
          label={props.formValues.click_me_label}
        />
      </>
    );
  }
  if (props.formValues.type_id === 4) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          {parseFloat(props.formValues.price).toFixed(2) > 0 ? (
            <PriceCard
              price={props.formValues.price}
              newPrice={props.formValues.discount_price}
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
              allowance={props.formValues.allowance}
            />
          ) : null}
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? parseFloat(props.formValues.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
            />
          ) : null}
        </Box>

        <ActivityButton
          color={COMPANY_COLORS(props.colors).primary}
          presential_course
          label={props.formValues.click_me_label}
        />
      </>
    );
  }
  if (props.formValues.type_id === 5) {
    return (
      <ShareCard
        cashback={
          parseFloat(props.formValues.points) +
          (props.formValues.additional_cashback
            ? parseFloat(props.formValues.additional_cashback)
            : 0.0)
        }
        secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
      />
    );
  }
  if (
    props.formValues.type_id === 7 ||
    props.formValues.type_id === 8 ||
    props.formValues.type_id === 6 ||
    props.formValues.type_id === 9 ||
    props.formValues.type_id === 10 ||
    props.formValues.type_id === 11 ||
    props.formValues.type_id === 12
  ) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? parseFloat(props.formValues.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={COMPANY_COLORS(props.colors).primary}
          label={props.formValues.click_me_label}
        />
      </>
    );
  }
  if (props.formValues.type_id === 14) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? parseFloat(props.formValues.additional_cashback)
                  : 0.0)
              }
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={COMPANY_COLORS(props.colors).primary}
          label={props.formValues.click_me_label}
        />
      </>
    );
  }
  if (props.formValues.type_id === 15) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}>
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_action_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback ? props.formValues.additional_cashback : 0.0)
              }
              secudaryColor={COMPANY_COLORS(props.colors).website.navbar.button.background}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={COMPANY_COLORS(props.colors).primary}
          agreement
          checkbox_text={props.formValues.checkbox}
          label={props.formValues.button}
        />
      </>
    );
  }
  if (props.formValues.type_id === 19) {
    return (
      <>
        {/*
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          <CashbackCard
            type={props.formValues.type_id}
            cashback={props.formValues.points_inviter}
            invitedCashback={props.formValues.points_invited}
            secudaryColor={
              COMPANY_COLORS(props.colors).website.navbar.button.background
            }
          />
        </Box> */}
        <div className="d-flex flex-column align-content-center">
          <Box
            style={{ marginTop: 20 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <CssTextField label="E-mail" variant="outlined" type="text" />
            <div style={{ marginTop: 20 }}>
              <ActivityButton color={COMPANY_COLORS(props.colors).primary} label="Enviar convite" />
            </div>
          </Box>
        </div>
      </>
    );
  }
  return null;
};

CallToActionCard.propTypes = {
  colors: PropTypes.any,
  formValues: PropTypes.shape({
    additional_cashback: PropTypes.any,
    allowance: PropTypes.any,
    button: PropTypes.any,
    checkbox: PropTypes.any,
    click_me_label: PropTypes.any,
    discount_price: PropTypes.any,
    isPaid: PropTypes.any,
    newPrice: PropTypes.any,
    points: PropTypes.number,
    points_invited: PropTypes.any,
    points_inviter: PropTypes.any,
    price: PropTypes.any,
    type_action_id: PropTypes.any,
    type_id: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  return {
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
  };
};

export default connect(mapStateToProps)(reduxForm({ form: 'emailForms' })(CallToActionCard));
