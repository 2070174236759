import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { appColors } from 'styles/colors';
import LinearDeterminate from '../progress/progress';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontFamily: 'roboto',
  },
  input: {
    display: 'none',
  },
  primary: {
    color: appColors.light.level1,
    backgroundColor: appColors.primary,
    '&:hover': {
      backgroundColor: appColors.primaryHover,
    },
  },
  disabledLabel: {
    color: appColors.light.level1,
  },
  loadingLabel: {
    display: 'block',
    color: appColors.success,
  },
  secondary: {
    color: 'white',
    backgroundColor: '#5759FB',
    '&:hover': {
      backgroundColor: '#474aab',
    },
  },
}));

export default function PrimaryButton(props) {
  const classes = useStyles();
  const { disabled, loading, loadingSize, progress } = props;

  if (progress) {
    return (
      <Button
        variant="contained"
        size="large"
        classes={{ label: classes.loadingLabel }}
        className={clsx(classes.button, classes.secondary)}
        {...props}>
        <LinearDeterminate progress={progress} style={{ display: 'block' }} />
        {props.children}
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      classes={{ label: disabled ? classes.disabledLabel : 'default' }}
      className={clsx(classes.button, classes.secondary)}
      {...props}>
      {loading ? <CircularProgress size={loadingSize} /> : props.children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.any,
  progress: PropTypes.any,
};
