import types from './types';

const INITIAL_STATE = {
  pagarmeLoading: false,
  pagarmeData: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PAGARME_DATA_FETCHED:
      return { ...state, pagarmeData: action.payload, pagarmeLoading: false };
    case types.PAGARME_DATA_LOAD:
      return { ...state, pagarmeLoading: action.payload };
    default:
      return state;
  }
};
