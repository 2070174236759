import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Entities from './entities';
import EntitiesForm from './entitiesForm';

export default (
  <Route path="entidades">
    <IndexRoute component={(props) => <Entities {...props} />} />
    <Route path="novo" component={(props) => <EntitiesForm {...props} />} />
    <Route path=":id/:action" component={(props) => <EntitiesForm {...props} />} />
  </Route>
);
