import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialGrid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from '../dialog/dialog';

const ITEM_HEIGHT = 48;

export default function LongMenu({ onEdit, onSuspend, hasEdit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    onEdit();
    setAnchorEl(null);
  };

  const handleSuspend = () => {
    setConfirmOpen(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applySuspend = () => {
    onSuspend();
  };

  const onCloseConfirm = () => {
    setConfirmOpen(false);
  };

  return (
    <div style={{ marginRight: 3 }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        {hasEdit && <MenuItem onClick={handleEdit}>Editar</MenuItem>}
        <MenuItem onClick={handleSuspend}>Suspender</MenuItem>
      </Menu>
      <AlertDialogSlide
        visible={confirmOpen}
        onClose={onCloseConfirm}
        title="Confirmação de suspensão"
        maxWidth="sm">
        <MaterialGrid container direction="column" spacing={3} justify="flex-start">
          <Typography variant="subtitle1" style={{ padding: 12 }}>
            Ao suspender uma atividade, ela irá sair do ar e será alocada em atividades finalizadas.
            Os créditos alocados nessa atividade serão retornados e você poderá reativá-la
            posteriormente se desejar.
          </Typography>
          <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 'bold' }}>
            Deseja continuar?
          </Typography>
          <MaterialGrid container justify="flex-start" style={{ padding: 12 }}>
            <SecondaryButton type="button" onClick={onCloseConfirm} color="primary">
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="button" color="primary" onClick={applySuspend}>
              Suspender
            </PrimaryButton>
          </MaterialGrid>
        </MaterialGrid>
      </AlertDialogSlide>
    </div>
  );
}

LongMenu.propTypes = {
  hasEdit: PropTypes.any.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSuspend: PropTypes.func.isRequired,
};
