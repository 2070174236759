import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { Container } from './styles';
import Wallet from '../../svg/wallet';

export default function CashbackCard(props) {
  return (
    <Container mgm={props.invitedCashback}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ color: '#707070', fontSize: 12 }}>Cashback</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            background: '#f7f7f7',
          }}>
          <Wallet color={props.secudaryColor} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        {props.invitedCashback ? (
          <span style={{ color: '#707070', fontSize: 12 }}>Filiado</span>
        ) : null}
        <strong style={{ fontSize: 24, color: props.secudaryColor }}>
          {String2Currency(props.cashback)}
        </strong>
        {props.invitedCashback ? (
          <>
            <span style={{ color: '#707070', fontSize: 12 }}>Convidado</span>
            <strong style={{ fontSize: 24, color: props.secudaryColor }}>
              {String2Currency(props.invitedCashback)}
            </strong>
          </>
        ) : null}
      </Box>
      <span style={{ fontSize: 16, color: '#707070' }}>
        {props.type === 1 // Member-get-member
          ? 'A pontuação será distribuída quando seu convidado se juntar à plataforma.'
          : props.type === 2 // Cadastre e ganhe
          ? 'Entre na plataforma, confirme o seu cadastro e receba os seus pontos'
          : props.type === 3 // Cupom
          ? 'O cashback será creditado após confirmação da empresa parceira.'
          : props.type === 4 // Atividade presencial
          ? 'O cashback será creditado após confirmação da empresa parceira.'
          : props.type === 5 // Compatilhe e ganhe
          ? 'O cashback será creditado após o acesso de algum convidado pelo o seu link.'
          : props.type === 6
          ? 'O cashback será creditado após o quiz ser respondido, caso você acerte pelo menos 50% das perguntas.'
          : props.type >= 7 && props.type <= 12 // Pesquisas
          ? 'O cashback será creditado após a pesquisa ser respondida.'
          : 'Este é o valor a ser será depositado na sua conta com o conclusão bem sucedida da atividade.'}
      </span>
    </Container>
  );
}

CashbackCard.propTypes = {
  cashback: PropTypes.any,
  invitedCashback: PropTypes.any,
  secudaryColor: PropTypes.any,
  type: PropTypes.number,
};
