import PropTypes from 'prop-types';
import Content from 'common/components/content/content';
import _ from 'lodash';
import React from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { postQuiz } from '../quizActions';
import QuizForm from './quizForm.jsx';

class QuizCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result: null,
    };

    // console.log(this.props.router.params)
  }

  onSubmit = (values) => {
    const { router } = this.props;
    const establishment = this.props.auth.user.establishments[0];
    const newValues = {
      ...values,
      establishment: establishment.id,
    };
    if (!_.isUndefined(this.props.router.params.method)) {
      this.props.postQuiz(newValues, router, this.props.router.params.method);
    } else {
      this.props.postQuiz(newValues, router, null);
    }
  };

  render() {
    return (
      <div>
        <Content>
          <Card>
            <CardTitle title="Criar Quiz" />
            <CardText>
              <div className="App">
                <QuizForm onSubmit={this.onSubmit} />
              </div>
            </CardText>
          </Card>
        </Content>
      </div>
    );
  }
}

QuizCreateForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  postQuiz: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      method: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues('quizCreateForm')(state),
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postQuiz,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'quizCreateForm' })(QuizCreateForm));
