const INITIAL_STATE = {
  snackbar: {
    message: '',
    open: false,
    variant: '',
  },
  submitProgress: false,
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SNACKBAR':
      return {
        ...state,
        snackbar: { ...action.payload },
      };
    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        snackbar: { ...state.snackbar, open: action.payload },
      };
    case 'SUBMIT_PROGRESS':
      return {
        ...state,
        submitProgress: action.payload,
      };
    case 'BUTTON_LOAD':
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
