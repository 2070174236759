import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { appColors } from 'styles/colors';

import { makeStyles, withStyles } from '@material-ui/core';

const CssDateTimeField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
})(DateTimePicker);

export const CssTextField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-multiline': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function InputLabelOutlined(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.input.value || null);
  const classes = useStyles();

  useEffect(() => {
    if (props.input.value) {
      setSelectedDate(props.input.value);
    }
  }, [props.input.value]);

  function handleDateChange(date) {
    setSelectedDate(date);
    props.input.onChange(date);
  }

  const {
    meta: { touched, error },
    input,
    className,
    required,
    label,
    type,
    multiline,
    rowsMax,
    rows,
    margin,
    helperText,
  } = props;
  if (type === 'datetime-local') {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          <CssDateTimeField
            error={!!(touched && error)}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedDate}
            onChange={handleDateChange}
            inputVariant="outlined"
            disablePast
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            autoOk
            disabled={props.disabled}
            required={required}
            helperText={(touched && error) || helperText}
            invalidDateMessage="Inválida"
            minDateMessage="Não é possível selecionar data antes de hoje"
            margin={margin}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <>
      <div className={classes.container}>
        <CssTextField
          label={label}
          className={clsx(className)}
          variant="outlined"
          onChange={props.onChange}
          disabled={props.disabled}
          error={!!(touched && error)}
          helperText={(touched && error) || helperText}
          type={type}
          multiline={multiline}
          inputProps={{ maxLength: props.maxLength || null }}
          InputLabelProps={props.inputlabelprops}
          rowsMax={rowsMax}
          rows={rows}
          {...input}
          style={{ ...props.style }}
          margin={margin}
        />
      </div>
      {props.maxLength ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <p style={{ fontSize: 15 }}>
            {props.input.value.length}/{props.maxLength}
          </p>
        </div>
      ) : null}
    </>
  );
}

InputLabelOutlined.propTypes = {
  className: PropTypes.any,
  disabled: PropTypes.any,
  helperText: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.shape({
      length: PropTypes.any,
    }),
  }),
  inputlabelprops: PropTypes.any,
  label: PropTypes.any,
  margin: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  maxLength: PropTypes.any,
  multiline: PropTypes.any,
  onChange: PropTypes.any,
  required: PropTypes.any,
  rows: PropTypes.any,
  rowsMax: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.string,
};
