import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LottieControl from 'common/components/lottie/lottie';
import { BASE_S3 } from 'config/consts';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { FontIcon } from 'react-md';
import { appColors } from 'styles/colors';
import PropTypes from 'prop-types';

export function InputFile(props) {
  const {
    input,
    label,
    meta: { touched, error },
  } = props;
  const [lottieFile, setLottie] = useState(undefined);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [imageRef, setImageRef] = useState({});
  const [croppedImgBase64] = useState(undefined);
  const theme = useTheme();

  function onDrop(imageFiles) {
    const {
      input: { onChange, onBlur },
    } = props;

    if (props.lottie) {
      const newReader = new FileReader();

      newReader.readAsBinaryString(imageFiles[0]);
      newReader.onload = function () {
        setLottie(JSON.parse(newReader.result));
      };
    }

    const reader = new FileReader();

    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function () {
      onBlur({ base64: reader.result, name: imageFiles[0] });
      onChange({
        base64: reader.result,
        imagem: imageFiles[0],
        preview: URL.createObjectURL(imageFiles[0]),
      });
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  function handleOnCropChange(crop) {
    setCrop(crop);
  }

  function handleImageLoaded(image) {
    setImageRef(image);
    return false;
  }

  function handleOnCropComplete(crop, pixelCrop) {
    makeClientCrop(crop);
  }

  function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      console.log(imageRef, crop, 'aa', imageRef.width, imageRef.height);
      console.log(imageRef.width, imageRef.height, imageRef.naturalWidth, imageRef.naturalHeight);
    }
  }

  const dropzoneProps = {
    accept: props.accept || 'image/jpeg, image/png, image/gif, image/svg, image/svg+xml',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 2000000,
    onDropRejected: () => {
      return props.meta.dispatch({
        type: 'SNACKBAR',
        payload: {
          message: 'Erro: Imagem maior que 2MB',
          variant: 'error',
          open: true,
        },
      });
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${touched && error && 'has-error'}`}
      sx={{
        minWidth: '100%',
      }}>
      {props.input.value ? (
        <Typography
          align="left"
          variant="caption"
          style={{
            color: touched && error ? appColors.error : '#000000',
            textAlign: 'left',
          }}>
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps} disabled={props.disabled}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
                height: 'auto',
                width: '100%',
                borderWidth: 1,
                borderColor: touched && error ? appColors.error : appColors.dark.level3,
                borderStyle: 'dashed',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: 'white',
              }}>
              {props.input.value ? (
                props.accept === 'application/json' || props.lottie ? (
                  <>
                    <input {...getInputProps()} />
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {lottieFile ? (
                        <LottieControl
                          width={100}
                          hasReplayButton={false}
                          height={100}
                          loop={false}
                          animation={lottieFile}
                        />
                      ) : props.input.value ? (
                        <LottieControl
                          width={100}
                          hasReplayButton={false}
                          height={100}
                          loop={false}
                          animation={props.input.value}
                        />
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <Box display="flex" style={{ justifyContent: 'center' }}>
                    <input {...getInputProps()} />
                    <ReactCrop
                      src={props.input.value.base64 || `${BASE_S3}/${props.input.value}`}
                      onChange={handleOnCropChange}
                      imageStyle={{
                        height: 'auto',
                        objectFit: 'cover',
                        margin: 0,
                      }}
                      crop={crop}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: props.maxWidthBool ? '50%' : '80%',
                        height: 'auto',
                      }}
                      onImageLoaded={handleImageLoaded}
                      onComplete={handleOnCropComplete}
                    />
                    {!props.disabled ? (
                      <FontIcon
                        onClick={() => {
                          props.input.onChange(null);
                        }}>
                        clear
                      </FontIcon>
                    ) : null}
                  </Box>
                )
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}>
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}>
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    align="left"
                    style={{
                      textAlign: 'left',
                      color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                    }}>
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography align="left" variant="caption" style={{ color: appColors.error }}>
            {error}
          </Typography>
        </Box>
      )}
      {croppedImgBase64 && <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImgBase64} />}
    </Box>
  );
}

InputFile.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.any,
  input: PropTypes.shape({
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.shape({
      base64: PropTypes.any,
    }),
  }),
  meta: PropTypes.shape({
    dispatch: PropTypes.func,
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  label: PropTypes.any,
  lottie: PropTypes.any,
  maxWidthBool: PropTypes.any,
};
