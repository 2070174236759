import { BASE_API } from 'config/consts';

export const dispatchSnackbar = (variant, message) => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message,
        variant,
        open: true,
      },
    });
  };
};

const URL_VOUCHERS = `${BASE_API}/subscriptions`;

export const getListByUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_VOUCHERS}/?where[user_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};
