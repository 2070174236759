import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputAnyFile } from 'common/components/fields/InputAnyFile';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Grid from 'common/components/layout/grid/grid';
import LoadingProgress from 'common/components/progress/loading';
import { setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  postFile,
  putFile,
  putFileDescription,
  setDetail,
  setLoading,
  update,
} from './downloadsActions';

let DownloadsForm = function (props) {
  const [file, setFile] = useState({ file: {}, show: false });
  const { downloads, router, location, getDetail, setDetail } = props;

  // Set loading false on create or set form values on update

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(downloads, router, location, getDetail, setDetail);
    }
  }, []);

  //

  function handleChange(file) {
    setFile({ show: true, file: file });
  }

  function onSubmit(values) {
    const newValues = {
      ...values,
      name: values.name,
      description: values.description,
      station_id: props.auth.user.establishments[0].stations[0].id,
    };

    delete newValues.file;

    // Atualização
    if (router.params.id) {
      if (file.path) {
        // Atualização inclui arquivo
        props.putFile(router.params.id, values.file, newValues, router);
      } else {
        // Atualização não inclui arquivo
        props.putFileDescription(router.params.id, newValues, router);
      }

      // Criação
    } else {
      props.postFile(values.file, props.router, newValues);
    }
  }

  return props.downloads.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="downloads-form">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Formulário</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome do arquivo"
                      name="name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Descrição'}
                      name="description"
                      rows={5}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={6} md={4} lg={3}>
                    <Field
                      component={InputAnyFile}
                      label="Arquivo"
                      placeholder="Clique ou arraste seu arquivo aqui"
                      name="file"
                      validate={[FORM_RULES.required]}
                      onChange={handleChange}
                    />
                  </GeneralGrid>
                </GeneralGrid>

                <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                  <PrimaryButton
                    type="submit"
                    disabled={props.downloads.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}>
                    {props.downloads.buttonLoading ? 'Enviando' : 'Enviar'}
                  </PrimaryButton>
                </Grid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

DownloadsForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  downloads: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  postFile: PropTypes.func,
  putFile: PropTypes.func,
  putFileDescription: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
};

DownloadsForm = reduxForm({ form: 'downloadsForm' })(DownloadsForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    downloads: state.downloads,
    formValues: getFormValues('downloadsForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      postFile,
      setLoading,
      putFileDescription,
      putFile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsForm);
