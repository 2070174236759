import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';
import { createAction } from '../../../../modules/owner/pages/actions/actionsFile';
import PrimaryButton from '../../buttons/primaryButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationCard(props) {
  function onClose() {
    props.onClose();
  }

  function createAction(action_template_id, station_id, partner_id, sponsored, router) {
    props.createAction(action_template_id, station_id, partner_id, sponsored, router);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '98%',
          }}>
          <IconButton style={{ paddingBottom: 10 }} onClick={() => onClose()}>
            <CloseIcon style={{ fontSize: 28 }} />
          </IconButton>
        </div>
        <DialogContent
          style={{
            dispĺay: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile ? '0px 30px 10px' : '0px 40px 20px',
          }}>
          <DialogTitle
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: isMobile ? 'center' : 'inherit',
              paddingTop: 0,
            }}>
            Criação de nova atividade
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
              maxWidth: props.maxWidthFixed ? 350 : null,
              fontFamily: 'roboto',
              color: 'black',
            }}>
            Deseja criar uma nova atividade?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '30px',
            flexDirection: isMobile ? 'column-reverse' : 'row',
          }}>
          <Button
            onClick={() => onClose()}
            color="primary"
            style={{
              textTransform: 'none',
              fontFamily: 'roboto',
            }}>
            Cancelar
          </Button>
          {/* <Button
            onClick={() =>
              createAction(
                props.item,
                props.station_id,
                props.partner_id,
                props.sponsored,
                props.router
              )
            }
            color="primary"
            style={{
              textTransform: 'none',
            }}
          >
            {props.loading ? 'Criando atividade...' : 'Confirmar'}
          </Button> */}
          {props.noRightButton ? null : (
            <PrimaryButton
              type="button"
              onClick={() =>
                createAction(
                  props.item,
                  props.station_id,
                  props.partner_id,
                  props.sponsored,
                  props.router
                )
              }>
              {props.loading ? 'Criando atividade' : 'Confirmar'}
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmationCard.propTypes = {
  createAction: PropTypes.func,
  item: PropTypes.any,
  loading: PropTypes.any,
  maxWidthFixed: PropTypes.any,
  noRightButton: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.any,
  partner_id: PropTypes.any,
  router: PropTypes.any,
  sponsored: PropTypes.any,
  station_id: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    loading: state.actions.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationCard);
