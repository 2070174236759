const INITIAL_STATE = {
  loading: false,
  list: {
    content: [],
    defaultValues: [],
  },
  total: 0,
  error: false,
  errors: [],
  contactDetails: {},
  loadingActivities: false,
  buttonLoading: false,
  earnPointsTransactions: [],
  loadingEarnPointsTransactions: false,
  supportMessages: [],
  loadingSupportMessages: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CONTACTS_FETCHED':
      return { ...state, list: { content: action.payload }, loading: false };
    case 'CONTACTS_LOAD':
      return { ...state, loading: action.payload };
    case 'CONTACTS_LIST_FETCHED':
      return { ...state, list: { defaultValues: action.payload }, loading: false };
    case 'CONTACTS_LIST_LOAD':
      return { ...state, loading: action.payload };
    case 'CONTACT_IMPORT_HAS_ERROR':
      return { ...state, error: action.payload };
    case 'CONTACT_IMPORT_ERRORS':
      return { ...state, errors: action.payload };
    case 'CONTACT_DETAIL_LOAD':
      return { ...state, loadingActivities: action.payload };
    case 'CONTACT_DETAIL_FETCHED':
      return {
        ...state,
        contactDetails: action.payload[0],
        loadingActivities: false,
      };
    case 'CONTACT_EARN_POINTS_TRANSACTIONS_LOAD':
      return { ...state, loadingEarnPointsTransactions: action.payload };
    case 'CONTACT_EARN_POINTS_TRANSACTIONS_FETCHED':
      return {
        ...state,
        earnPointsTransactions: action.payload,
        loadingEarnPointsTransactions: false,
      };
    case 'CONTACT_SUPPORT_MESSAGES_LOAD':
      return { ...state, loadingSupportMessages: action.payload };
    case 'CONTACT_SUPPORT_MESSAGES_FETCHED':
      return {
        ...state,
        supportMessages: action.payload,
        loadingSupportMessages: false,
      };
    case 'CONTACT_BUTTON_LOAD':
      return {
        ...state,
        buttonLoading: action.payload,
      };

    default:
      return state;
  }
};
