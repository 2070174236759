import types from './type';

const INITIAL_STATE = {
  loading: false,
  list: [],
  total: 0,
  campaignsLoading: false,
  campaigns: [],
  couponsLoading: false,
  coupons: [],
  basic: {},
  graphsLoading: false,
  dataLoading: true,
  data: {},
  onboarding: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START_ONBOARDING':
      return { ...state, onboarding: action.payload };
    case types.CAMPAIGNS_FETCHED:
      return { ...state, campaigns: action.payload, campaignsLoading: false };
    case types.CAMPAIGNS_LOAD:
      return { ...state, campaignsLoading: action.payload };
    case 'STATION_DATA_FETCHED':
      return { ...state, data: action.payload, dataLoading: false };
    case 'STATION_DATA_LOAD':
      return { ...state, dataLoading: action.payload };
    case types.CUPOM_OFFERS_ESTABLISHMENT_FETCHED:
      return { ...state, coupons: action.payload, couponsLoading: false };
    case types.CUPOM_OFFERS_ESTABLISHMENT_LOAD:
      return { ...state, couponsLoading: action.payload };

    case types.BASIC_GRAPHS_FETCHED:
      return {
        ...state,
        basic: action.payload || null,
        graphsLoading: false,
      };

    case types.BASIC_GRAPH_LOAD:
      return {
        ...state,
        graphsLoading: action.payload,
      };
    default:
      return state;
  }
};
