import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import GridMaterial from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputSwitchGrey } from 'common/components/fields/InputSwitchGrey';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { USER_TOKEN } from 'config/consts';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { validateToken } from 'pages/auth/authActions';
import React, { Component } from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { appColors } from 'styles/colors';
import { createWebsite } from './websiteActions';
import WebsiteSimulator from './websiteSimulator';

const lower = (value) => value && value.toLowerCase();

class WebsiteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.website.modal) {
      this.setState({ visible: true });
    }
  }

  openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  renderSimulator = () => {
    const { formValues } = this.props;
    return (
      <WebsiteSimulator formValues={!_.isUndefined(formValues) ? { ...formValues } : undefined} />
    );
  };

  onSubmit = async (values) => {
    const { id } = this.props.auth.user.establishments[0];
    const { router } = this.props;

    values.email = this.props.auth.user.email;
    values.establishment = id;

    const newValues = { ...values };
    await this.props.createWebsite(newValues, router);

    const { user } = this.props.auth;
    if (user) {
      await this.props.validateToken(JSON.parse(localStorage.getItem(USER_TOKEN)), user.id);
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <section className="modules mt-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-3 form-create">
              <div className="row justify-content-center">
                <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
                  <ExpansionList>
                    <GridMaterial container spacing={1} justify="flex-start" alignItems="center">
                      <ExpansionPanel
                        defaultExpanded
                        style={{
                          marginBottom: 10,
                          width: '100%',
                          borderRadius: 0,
                          boxShadow: 'none',
                        }}
                        label={`Principal`}
                        footer={<></>}>
                        <GridMaterial
                          container
                          spacing={1}
                          justify="flex-start"
                          alignItems="center">
                          <GridMaterial item xs={12}>
                            <Field
                              component={InputLabelOutlined}
                              label="Nome da sua Estação"
                              name="prettyName"
                              helperText="Defina o nome da sua estação"
                              placeholder="Ex: ADVOCACIA SA"
                              validate={[FORM_RULES.required]}
                            />
                          </GridMaterial>
                          <GridMaterial item xs={6}>
                            <Field
                              component={InputLabelOutlined}
                              label="Subdomínio"
                              name="subdomain"
                              normalize={lower}
                              validate={[FORM_RULES.required, FORM_RULES.lowerCase]}
                            />
                          </GridMaterial>

                          <GridMaterial item xs={6}>
                            <Typography variant="body" style={{ color: appColors.primary }}>
                              .rilato.site
                            </Typography>
                          </GridMaterial>
                        </GridMaterial>
                        <GridMaterial
                          container
                          spacing={1}
                          justify="flex-start"
                          alignItems="center">
                          <GeneralGrid item xs={12}>
                            <Box display="flex" justifyContent="center" mb={3}>
                              <Field
                                component={InputSwitchGrey}
                                label={'Estação será pública?'}
                                boxStyle={{
                                  backgroundColor: '#f7f7f7',
                                  padding: 16,
                                }}
                                firstState="Sua estação será pública."
                                secondState="Selecione para configurar sua estação como pública. Em uma estação pública, qualquer pessoa pode se cadastrar."
                                disabled={this.props.disabled}
                                name="public"
                                // required
                                margin="normal"
                              />
                            </Box>
                          </GeneralGrid>
                        </GridMaterial>
                      </ExpansionPanel>

                      <Box display="flex" justifyContent="center">
                        <PrimaryButton
                          type="submit"
                          disabled={this.props.website.buttonLoading}
                          progress={parseInt(this.props.general.submitProgress)}>
                          {this.props.website.buttonLoading ? 'Criando sua estação' : 'Enviar'}
                        </PrimaryButton>
                      </Box>
                    </GridMaterial>
                  </ExpansionList>
                </Form>
              </div>
            </div>
            <div className="col-9 simulator">{this.renderSimulator()}</div>
          </div>
        </div>

        <AlertDialogSlide
          visible={this.state.visible}
          title="Seu Website"
          onClose={() => {
            this.setState({ visible: false });
          }}>
          <GridMaterial container justify={'center'}>
            <Typography variant={'subtitle1'}>Pronto, sua estação está no ar!</Typography> <br></br>
            <Typography variant={'subtitle1'}>
              Agora, adicione seus membros na plataforma. Apenas eles poderão se cadastrar.
            </Typography>
            <br></br>
            <Typography variant="subtitle1">Obrigado</Typography>
          </GridMaterial>
          <GridMaterial container spacing={1} justify={'space-between'} alignItems="center">
            <GridMaterial item xs={3}>
              <SecondaryButton
                onClick={() => this.props.router.push('/owner')}
                router={this.props.router}
                type="submit"
                color="primary">
                Voltar para a tela inicial
              </SecondaryButton>
            </GridMaterial>
            <GridMaterial item xs={3}>
              <SecondaryButton
                onClick={() =>
                  window.open(
                    `http://${
                      this.props.formValues
                        ? this.props.formValues.name
                          ? this.props.formValues.name
                          : null
                        : null
                    }.rilato.${
                      process.env.REACT_APP_API_KEY === 'production' ||
                      process.env.REACT_APP_API_KEY === 'production-temp'
                        ? 'app'
                        : process.env.REACT_APP_API_KEY === 'test'
                        ? 'site'
                        : 'site'
                    }.s3-website-us-east-1.amazonaws.com`,
                    '_blank'
                  )
                }
                router={this.props.router}
                type="button"
                color="primary">
                Visitar sua estação
              </SecondaryButton>
            </GridMaterial>
            {this.props.location.query.first_station ? (
              <GridMaterial item xs={6}>
                <PrimaryButton
                  onClick={() =>
                    this.props.router.push('/owner/contacts/import?first_contacts=true')
                  }
                  router={this.props.router}
                  type="submit"
                  color="primary">
                  Prosseguir para a importação dos membros
                </PrimaryButton>
              </GridMaterial>
            ) : null}
          </GridMaterial>
        </AlertDialogSlide>
      </section>
    );
  }
}

WebsiteForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.any,
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  createWebsite: PropTypes.func,
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    name: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_station: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  validateToken: PropTypes.func,
  website: PropTypes.shape({
    buttonLoading: PropTypes.any,
    modal: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('websiteForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createWebsite, validateToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'websiteForm' })(WebsiteForm));
