import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import OnboardingCard from 'common/components/cards/onboardingCard/onboardingCard';
import LoadingProgress from 'common/components/progress/loading';
import { finishTutorial } from 'pages/auth/authActions';
import React, { Component } from 'react';
import { Avatar } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { bindActionCreators } from "redux";
import getUserSteps from './principalActions';

class Principal extends Component {
  constructor(props) {
    super(props);
    if (this.props.auth.user.briefingdDone) {
      this.props.router.push('/');
    }
    this.props.getUserSteps(this.props.auth.user.id);
  }

  renderPoints = () => {
    const { establishment } = this.props.auth.user;
    if (this.props.principal.loading) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    } else {
      return (
        <span className="font-dosis">
          <span style={{ fontWeight: '300' }}>Seja bem vindo, </span>
          <span style={{ fontWeight: 'bold' }}>{establishment.name}, você possui créditos.</span>
        </span>
      );
    }
  };

  renderNumber = (number, condition) => {
    if (condition) {
      return (
        <Avatar style={{ background: 'gray' }}>
          <span>{number}</span>
        </Avatar>
      );
    } else
      return (
        <Avatar style={{ background: '#FF007B' }}>
          <span>{number}</span>
        </Avatar>
      );
  };

  finishTutorial = () => {
    this.props.finishTutorial(this.props.auth.user.id, this.props.router, this.props.auth);
  };

  render() {
    const { establishment, website, contacts } = this.props.principal.obj;

    if (this.props.principal.loading) {
      return (
        <Box display="flex" justifyContent="center" height="100vh" mt={5}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <section style={{ paddingBottom: 50 }}>
        <MaterialGrid container spacing={3}>
          <MaterialGrid item xs={12} style={{ marginBottom: 40, marginTop: 40 }}>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 24,
                textAlign: 'center',
              }}>
              Bem vindo, {this.props.auth.user.name}
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid container spacing={2} direction="row" justify="center" alignItems="center">
          <MaterialGrid item>
            <OnboardingCard
              condition={establishment}
              title="Cadastre seu CNPJ para iniciar"
              text="Para iniciar a configuração do seu ambiente de gestão de sua estação de relacionamento você precisa do CNPJ da empresa que você é responsável."
              router={this.props.router}
              buttonLabel="Cadastrar Agora"
              onClickButton={() => this.props.router.push('/owner/signup-company')}
            />
          </MaterialGrid>
          <MaterialGrid item>
            <OnboardingCard
              condition={website || !establishment}
              title="Crie sua estação de relacionamento"
              text="A sua estação de relacionamento será o ponto de encontro digital de todos os participantes e prospects de sua empresa."
              router={this.props.router}
              buttonLabel="Criar estação"
              onClickButton={() =>
                this.props.router.push('/owner/website/create/?first_station=true')
              }
            />
          </MaterialGrid>
          <MaterialGrid item>
            <OnboardingCard
              condition={contacts || !website}
              title="Convide os primeiros participantes"
              text="Produza um arquivo no formato .csv (veja o tutorial) e faça o upload do seus primeiros participantes aqui"
              router={this.props.router}
              buttonLabel="Convidar"
              onClickButton={() =>
                this.props.router.push('/owner/contacts/import/?first_contacts=true')
              }
            />
          </MaterialGrid>
          {establishment && website && contacts ? (
            <MaterialGrid item>
              <OnboardingCard
                condition={false}
                title="Pronto"
                text="Finalize esse tutorial e vá para a dashboard"
                router={this.props.router}
                buttonLabel="Finalizar"
                onClickButton={() => this.finishTutorial()}
              />
            </MaterialGrid>
          ) : null}
        </MaterialGrid>
      </section>
    );
  }
}

Principal.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      briefingdDone: PropTypes.any,
      establishment: PropTypes.shape({
        name: PropTypes.any,
      }),
      id: PropTypes.any,
      name: PropTypes.any,
    }),
  }),
  finishTutorial: PropTypes.func,
  getUserSteps: PropTypes.func,
  principal: PropTypes.shape({
    loading: PropTypes.any,
    obj: PropTypes.shape({
      establishment: PropTypes.any,
      website: PropTypes.any,
      contacts: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    principal: state.principal,
    points: state.points,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserSteps, finishTutorial }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
