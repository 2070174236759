import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { appColors } from 'styles/colors';
import PropTypes from 'prop-types';

export function InputAnyFile(props) {
  const [files, setFiles] = useState(null);
  const theme = useTheme();

  function renderAccepted(acceptedFiles) {
    setFiles(acceptedFiles[0]);
    props.input.onChange(acceptedFiles[0]);
  }
  const {
    meta: { touched, error },
  } = props;
  return (
    <div>
      <Dropzone disabled={props.disabled} onDrop={(acceptedFiles) => renderAccepted(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div
            style={{
              height: 'auto',
              maxWidth: '100%',
              borderWidth: 2,
              borderColor: touched && error ? appColors.error : appColors.dark.level3,
              borderStyle: 'dashed',
              borderRadius: 5,
              margin: 'auto',
              cursor: 'pointer',
              backgroundColor: '#F7F7F7',
            }}
            {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="row h-100 justify-content-center">
              <div className="col-auto text-center align-self-center">
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}>
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}>
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    style={{
                      color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                    }}>
                    {files !== null ? files.name : props.placeholder}
                  </Typography>
                </Box>
              </div>
            </div>
          </div>
        )}
      </Dropzone>

      {touched && error && (
        <Typography variant="caption" style={{ color: appColors.error }}>
          {error}
        </Typography>
      )}
    </div>
  );
}

InputAnyFile.propTypes = {
  disabled: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  placeholder: PropTypes.any,
};
