import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PlansOptionsCard from 'common/components/cards/plansOptionsCard';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box } from '@material-ui/core';
import Button from 'common/components/buttons/button';
import { ReactComponent as CheckIcon } from 'assets/icons/vector-stroke.svg';
import { getList } from './plansActions';
import { appColors } from '../../../../styles/colors';
import { BASE_S3, COMPANY_ENTERPRISE_PLAN_BENEFITS } from '../../../../config/consts';

const {
  v2: { pink, blue, black, gray, background },
} = appColors;

const PlanDetail = ({ selectedPlan, onClick }) => {
  const { name, description, benefits, amount, days, image, color, discount } = selectedPlan;

  return (
    <section id="plans-detail" style={{ marginTop: 32 }}>
      <GeneralGrid container spacing={3} xs={12} justify="center">
        <GeneralGrid item xs={12} md={8}>
          <img
            src={`${BASE_S3}/${image}`}
            alt="Imagem do plano"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="h3-bold mt-4">{name}</p>
          <p className="h5 mt-4">{description}</p>
          <Box mt={4}>
            {benefits.map((item, index) => (
              <p className="h5 text-left mt-3" key={index}>
                <CheckIcon stroke={color} fill={color} style={{ marginRight: 8 }} />
                {item.benefit}
              </p>
            ))}
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ backgroundColor: background, padding: '32px 64px' }}
            mt={4}>
            <p className="h5-bold text-center">{name}</p>
            {amount ? (
              <p className="title text-center mt-2" style={{ color, flexWrap: 'false' }}>
                R$ {(parseFloat(amount) - parseFloat(discount)).toFixed(2).replace('.', ',')}
                <span className="h3 text-center" style={{ color: gray }}>
                  /{days === 30 ? 'mês' : days === 183 ? 'semestre' : 'ano'}
                </span>
              </p>
            ) : (
              <p className="title text-center mt-5" style={{ color }}>
                A negociar
              </p>
            )}
            <Button
              label={'Adquirir esse plano'}
              onClick={onClick}
              buttonStyle={{
                width: '45%',
                minWidth: '270px',
                marginTop: 32,
                backgroundColor: color,
              }}
              labelStyle={{ color: '#fff' }}
            />
          </Box>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

PlanDetail.propTypes = {
  onClick: PropTypes.any,
  selectedPlan: PropTypes.shape({
    amount: PropTypes.any,
    benefits: PropTypes.shape({
      map: PropTypes.func,
    }),
    color: PropTypes.any,
    days: PropTypes.number,
    description: PropTypes.any,
    discount: PropTypes.any,
    image: PropTypes.any,
    name: PropTypes.any,
  }),
};

function PlansUpgrade({ plans, router, auth, getList }) {
  const {
    user: {
      subscription: { plan },
      plans: plansOptions,
    },
  } = auth;

  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    return () => {
      setSelectedPlan(null);
    };
  }, []);

  if (selectedPlan) {
    return (
      <PlanDetail
        selectedPlan={selectedPlan}
        onClick={() =>
          plan
            ? router.push(`/owner/planos/${selectedPlan.id}/mudar`)
            : router.push(`/owner/planos/${selectedPlan.id}/comprar`)
        }
      />
    );
  }

  return (
    <section id="plans-options" style={{ marginTop: 64 }}>
      <GeneralGrid container spacing={3} justify="center">
        {plansOptions
          .filter((item) => !item.deletedAt)
          .sort(
            (a, b) =>
              parseFloat(a.amount) -
              parseFloat(a.discount) -
              (parseFloat(b.amount) - parseFloat(b.discount))
          )
          .map((item, index) => (
            <GeneralGrid item xs={12} md={4} key={item.id}>
              <PlansOptionsCard
                name={item.name}
                color={index === 0 ? blue : index === 1 ? pink : black}
                amount={(parseFloat(item.amount) - parseFloat(item.discount))
                  .toFixed(2)
                  .replace('.', ',')}
                benefits={item.benefits}
                description={item.description}
                onClick={() =>
                  plan
                    ? router.push(`/owner/planos/${item.id}/mudar`)
                    : router.push(`/owner/planos/${item.id}/comprar`)
                }
                buttonStyle={
                  plan ? (plan.id === item.external_id ? { backgroundColor: 'white' } : {}) : {}
                }
                buttonLabelStyle={
                  plan
                    ? plan.id === item.external_id
                      ? { color: 'gray', textAlign: 'center' }
                      : { textAlign: 'center' }
                    : { textAlign: 'center' }
                }
                buttonLabel={
                  plan
                    ? plan.id === item.external_id
                      ? 'Este é seu plano atual'
                      : parseFloat(item.amount) >= plan.amount
                      ? 'Fazer upgrade'
                      : 'Fazer downgrade'
                    : item.purchase_text
                }
                is_highlighted={item.is_highlighted}
                onDetailClick={() =>
                  setSelectedPlan({
                    ...item,
                    color: index === 0 ? blue : index === 1 ? pink : black,
                  })
                }
                days={item.days}
                buttonDisabled={plan ? plan.id === item.external_id : false}
              />
            </GeneralGrid>
          ))}
        <GeneralGrid container item xs={12} md={4}>
          <PlansOptionsCard
            name={'Enterprise'}
            enterprisePlan="true"
            color={black}
            description={
              'Sob medida para quem já lida com seus públicos de forma estruturada e quer transformar esse relacionamento em receita'
            }
            benefits={COMPANY_ENTERPRISE_PLAN_BENEFITS}
            onClick={() => router.push(`/owner/planos/suporte-plano-enterprise`)}
            buttonStyle={{ backgroundColor: 'black' }}
            buttonLabelStyle={{ textAlign: 'center' }}
            buttonLabel={'Entre em contato'}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

PlansUpgrade.propTypes = {
  auth: PropTypes.any,
  getList: PropTypes.any,
  plans: PropTypes.any,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansUpgrade);
