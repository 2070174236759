import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList } from './productsReportActions';

function ProductsReport(props) {
  const { auth } = props;
  const { content } = props.productsReport.list;
  const { router } = props;

  const defaultColumns = [
    {
      title: 'Produto',
      field: 'name',
    },
    {
      title: 'Atividade',
      field: 'action.name',
      render: (rowData) => rowData.action?.name,
    },
    {
      title: 'Estoque inicial',
      field: 'stock',
    },
    {
      title: 'Qtd. de Vendas',
      field: 'single_product_orders.length',
      render: (rowData) => rowData.single_product_orders?.length,
    },
    {
      title: 'Estoque disponível',
      field: 'stock',
      render: (rowData) => rowData.stock - rowData.single_product_orders?.length,
    },
    {
      title: 'Status',
      field: 'action.active',
      render: (rowData) =>
        !rowData.action.active ? 'Atividade fora do ar' : rowData.deletedAt ? 'Esgotado' : 'Online',
    },
  ];
  function onRowClick(e, rowdata) {
    props.router.push(`/owner/relatorio-produtos/${rowdata.id}`);
  }
  const columns = defaultColumns;

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(props.productsReport.list.content);
  }, [props.productsReport.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="productsReport">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui produtos</h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.productsReport.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table
                hasToolbar
                toolbarSearch
                title="Lista de Produtos"
                columns={columns}
                rows={data}
                onRowClick={onRowClick}
                router={router}
                mobileTable={isMobile}
                hasBorder={!isMobile}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ProductsReport.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getList: PropTypes.func,
  productsReport: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    productsReport: state.productsReport,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsReport);
