import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { phoneMask } from '../../../../helpers/masks';
import { fileRejected, getDetail, initializeForm, setDetail, setLoading } from './supportActions';

let SupportForm = function (props) {
  const { support, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(support, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {}

  return props.support.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="support-form">
      <div className="card">
        <div className="card-body">
          <p className=" h4 card-title text-muted">Formulário</p>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome"
                  name="name"
                  validate={[FORM_RULES.required]}
                  required
                  disabled
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'E-mail'}
                  name="email"
                  required
                  disabled
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Assunto'}
                  name="subject"
                  disabled
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Telefone'}
                  disabled
                  name="phone"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                  {...phoneMask}
                />
              </MaterialGrid>
              <MaterialGrid item xs={6}>
                <Field
                  component={InputLabelOutlined}
                  multiline
                  label={'Mensagem'}
                  name="message"
                  disabled
                  rows={5}
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
          </Form>
        </div>
      </div>
    </section>
  );
};

SupportForm.propTypes = {
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  support: PropTypes.shape({
    loading: PropTypes.any,
  }),
};

SupportForm = reduxForm({ form: 'supportForm' })(SupportForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    support: state.support,
    formValues: getFormValues('supportForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportForm);
