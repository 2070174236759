import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import types from './types';

const URL_RESEARCH = `${BASE_API}/research`;
const URL_ANSWER = `${BASE_API}/answer`;

export const getSurveyById = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.SURVEY_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_RESEARCH}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.SURVEYS_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const submitSurvey = (values, method = undefined, router = undefined) => {
  return (dispatch) => {
    dispatch({ type: types.SURVEY_LOAD, payload: true });
    axios
      .post(`${URL_ANSWER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.SURVEY_LOAD,
            payload: false,
          },
          {
            type: types.SURVEY_SENT,
            payload: true,
          },
        ]);

        /* dispatch([reset(), getList()]); */

        /*  if (!_.isUndefined(router)) {
                    router.push("/owner/");
                } */
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
