import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Journeys from '.';
import JourneysForm from './journeysForm';

export default (
  <Route path="journeys">
    <IndexRoute component={(props) => <Journeys {...props} />} />
    <Route path="journey/:id" component={(props) => <JourneysForm {...props} />} />
  </Route>
);
