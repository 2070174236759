import PropTypes from 'prop-types';
import { Box, Checkbox, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import logo from 'assets/img/fan_logo.svg';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import MaterialCard from 'common/components/cards/materialCard';
import DialogTemplates from 'common/components/dialog/dialogTemplates';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmationCard from 'common/components/cards/confirmationCard/confirmationCard';
import {
  getTemplates,
  openCupom,
  openMemberGetMember,
  openResearch,
  openShareContent,
  openSingupAndEarn,
  getTemplatesCategories,
  getActionsCategories,
} from './actionsFile';
import { getCupomTemplates } from './coupons/couponsActions';
import {
  dispatchFormReset,
  getMemberGetMemberTemplates,
} from './memberGetMember/memberGetMemberActions';
import { getResearchemplates } from './research/researchActions';
import { getSingupAndEarnTemplates } from './signupEarn/signupEarnActions';

class Actions extends Component {
  constructor(props) {
    super(props);
    this.props.getTemplates(this.props.auth.user.establishments[0].id);
    this.props.getTemplatesCategories();
    this.props.getActionsCategories();
    this.state = {
      section: 0,
      item: null,
      open: false,
      actionTypes: [],
      templatesCategories: [],
      filteredTemplatesList: [],
    };
  }

  // Filtra as atividades
  componentDidUpdate(props, state) {
    if (
      props.actions.templatesList.length !== this.props.actions.templatesList.length ||
      this.state.actionTypes.length !== state.actionTypes.length ||
      this.state.templatesCategories.length !== state.templatesCategories.length
    ) {
      const templatesList = this.props.actions.templatesList.filter(
        (item) =>
          item.stations[0] === 'all' ||
          item.stations.indexOf(this.props.auth.user.establishments[0].id) >= 0
      );
      const filteredTemplatesList = templatesList.filter(
        (item) =>
          this.state.actionTypes.indexOf(item.type_action_id) > -1 ||
          this.state.templatesCategories.indexOf(item.category) > -1
      );

      if (_.isEmpty(filteredTemplatesList)) {
        this.setState({
          filteredTemplatesList: templatesList,
        });
      } else {
        this.setState({ filteredTemplatesList });
      }
    }
  }

  componentDidMount() {
    const templatesList = this.props.actions.templatesList.filter(
      (item) =>
        item.stations[0] === 'all' ||
        item.stations.indexOf(this.props.auth.user.establishments[0].id) >= 0
    );
    const filteredTemplatesList = templatesList.filter(
      (item) => this.state.actionTypes.indexOf(item.type_action_id) > -1
    );
    if (_.isEmpty(filteredTemplatesList)) {
      this.setState({
        filteredTemplatesList: templatesList,
      });
    } else {
      this.setState({ filteredTemplatesList });
    }
  }

  handleChangeActionType = (category, e) => {
    let newTypesArray = [];
    if (e.target.checked) {
      newTypesArray = [...this.state.actionTypes, category.id];
    } else {
      newTypesArray = [...this.state.actionTypes].filter((item) => item !== category.id);
    }
    this.setState({
      actionTypes: newTypesArray,
      [category.name.split(' ').join('_')]: e.target.checked,
    });
  };

  handleChangeTemplateCategory = (category, e) => {
    let templatesCategories = [];
    if (e.target.checked) {
      templatesCategories = [...this.state.templatesCategories, category.id];
    } else {
      templatesCategories = [...this.state.templatesCategories].filter(
        (item) => item !== category.id
      );
    }
    this.setState({
      templatesCategories,
      [category.name.split(' ').join('_')]: e.target.checked,
    });
  };

  handleCreate = (item) => {
    this.setState({ open: true, item: item });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onDialogClose = () => {
    this.props.router.push('/owner/acoes/inicio');
  };

  renderEmptyScreen = () => {
    return (
      <section id="actionOffers" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Não temos novas atividades disponíveis no momento</h2>
                  <p className="">
                    Assim que novas atividades forem incluídas, lhe notificaremos. Enquanto isso
                    desfrute das suas atividades adquiridas.
                  </p>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  };

  renderTemplatesList = () => {
    const listTemplates = this.state.filteredTemplatesList.map((item, index) => {
      return (
        <MaterialGrid item key={index}>
          <MaterialCard
            onClick={
              item.has_bought
                ? () => this.handleCreate(item.id)
                : () => this.props.router.push(`/owner/acoes/${item.id}`)
            }
            name={item.name}
            has_bought={item.has_bought}
            image={`${BASE_S3}/${item.owner_image || item.image}`}
            description={item.summary}
            buttonLabel={item.has_bought ? 'Ativar' : 'Veja Mais'}
            type={item.type.name}
          />
        </MaterialGrid>
      );
    });

    return (
      <MaterialGrid
        container
        spacing={3}
        justify="flex-start"
        style={{ marginLeft: '0rem', background: '#f7f7f7' }}>
        {listTemplates.length > 0 ? listTemplates : this.renderEmptyScreen()}
      </MaterialGrid>
    );
  };

  render() {
    if (this.props.actions.templatesLoading) {
      return (
        <Box display="flex" justifyContent="center" height="100vh" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <>
        <DialogTemplates
          visible={true}
          height="95vh"
          active={this.handleActive}
          onClose={this.onDialogClose}
          maxWidth="lg"
          title="Escolha uma nova atividade">
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={3} style={{ backgroundColor: 'white' }}>
              <div
                style={{
                  width: 80,
                  height: 80,
                  backgroundColor: '#F7F7F7',
                  borderRadius: '50%',
                  display: 'flex',
                  justifySelf: 'center',
                  alignItems: 'center',
                  padding: '0 8px',
                  justifyContent: 'center',
                }}>
                <img src={logo} alt="Fermento Logo" style={{ width: '100%', height: 'auto' }} />
              </div>

              {this.props.actions.templatesCategories.length > 0 &&
                this.props.actions.templatesCategories.map((category, index) => (
                  <div key={index}>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 200,
                        fontSize: 14,
                        textAlign: 'left',
                        marginTop: 20,
                        color: '#2D95EF',
                      }}>
                      {category.name}
                    </Typography>
                    {category.categories.map((item, index) => (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: 0 }}
                        key={index}>
                        <Checkbox
                          checkboxclass="icheckbox_square-green"
                          checked={this.state[item.name.split(' ').join('_')] || false}
                          onChange={(e) => this.handleChangeTemplateCategory(item, e)}
                        />
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                ))}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 200,
                  fontSize: 14,
                  textAlign: 'left',
                  marginTop: 20,
                  color: '#2D95EF',
                }}>
                Interações
              </Typography>

              {this.props.actions.actionTypes.length > 0 &&
                this.props.actions.actionTypes.map((actionType, index) => (
                  <div key={index} className="d-flex align-items-center" style={{ marginLeft: 0 }}>
                    <Checkbox
                      checkboxclass="icheckbox_square-green"
                      checked={this.state[actionType.name.split(' ').join('_')] || false}
                      onChange={(e) => this.handleChangeActionType(actionType, e)}
                    />
                    <span>{actionType.name}</span>
                  </div>
                ))}
            </MaterialGrid>
            <MaterialGrid item xs={9} style={{ background: '#f7f7f7' }}>
              {this.renderTemplatesList()}
            </MaterialGrid>
          </MaterialGrid>
        </DialogTemplates>
        <ConfirmationCard
          onClose={this.handleClose}
          open={this.state.open}
          item={this.state.item}
          station_id={this.props.auth.user.establishments[0].stations[0].id}
          partner_id={null}
          sponsored={null}
          router={this.props.router}
        />
      </>
    );
  }
}

Actions.propTypes = {
  actions: PropTypes.shape({
    actionTypes: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    templatesCategories: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    templatesList: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.any,
    }),
    templatesLoading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getActionsCategories: PropTypes.func,
  getTemplates: PropTypes.func,
  getTemplatesCategories: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    contactsSurveys: state.contactsSurveys,
    signupAndEarn: state.signupAndEarn,
    memberGetMember: state.memberGetMember,
    actions: state.actions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCupomTemplates,
      openCupom,
      getResearchemplates,
      openResearch,
      getMemberGetMemberTemplates,
      getSingupAndEarnTemplates,
      openMemberGetMember,
      openSingupAndEarn,
      dispatchFormReset,
      openShareContent,
      getTemplates,
      getTemplatesCategories,
      getActionsCategories,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
