/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { BASE_S3 } from 'config/consts';
import React from 'react';

export default function FeaturedCard(props) {
  /* 820x312 */
  return (
    <div className="d-flex flex-column w-100">
      <article
        className="row py-4 justify-content-center pb-5"
        style={{
          background: 'transparent',
        }}>
        <div className="w-100" onClick={props.onClick}>
          <div className="container m-3">
            <div className="row">
              <>
                <div className="col-xs-12 col-sm-12 col-md-5 d-flex flex-column justify-content-center align-content-start">
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '48px',
                      lineHeight: '50px',
                      display: 'flex',
                      alignItems: 'flex-end',
                      color: '#282828',
                      maxWidth: '100%',
                      marginBottom: 25,
                    }}>
                    {props.text}
                  </span>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      lineHeight: '29px',
                      color: '#8A8A8A',
                      maxWidth: '80%',
                      marginBottom: 25,
                    }}>
                    {props.description}
                  </span>
                  {props.callToAction ? (
                    <button
                      type="button"
                      style={{
                        padding: 10,
                        borderRadius: 5,
                        background: '#595FF7',
                        color: 'white',
                        maxWidth: '33%',
                      }}>
                      {props.callToAction}
                    </button>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-1" />
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <img
                    src={`${BASE_S3}/${props.image}`}
                    style={{ maxWidth: '100%', aspectRatio: 1 }}
                    alt="FeaturedCard"
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </article>
      <div className="d-flex justify-content-center">
        {props.total.map((item, index) => (
          <div
            onClick={() => props.setActive(index)}
            style={{
              background: index === props.active ? 'gray' : 'lightgray',
              width: 8,
              height: 8,
              borderRadius: 4,
              margin: 3,
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

FeaturedCard.propTypes = {
  active: PropTypes.any,
  callToAction: PropTypes.any,
  description: PropTypes.any,
  image: PropTypes.any,
  onClick: PropTypes.any,
  setActive: PropTypes.func,
  text: PropTypes.any,
  total: PropTypes.shape({
    map: PropTypes.func,
  }),
};
