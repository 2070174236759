import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputFile } from 'common/components/fields/InputFile';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { validateDelivery, getOrderAndInitialize } from './deliveryOrdersActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let DeliveryReceiptForm = function (props) {
  useEffect(() => {
    props.getOrderAndInitialize(props.router.params.id);
  }, []);
  function onSubmit(values) {
    const newValues = {
      file_buy: {
        imageType: '',
        image: '',
      },
      file_sell: {
        imageType: '',
        image: '',
      },
    };

    newValues.file_buy.imageType = imgToImgType(values.file_buy);
    newValues.file_buy.image = imgToBase64(values.file_buy);
    newValues.file_sell.imageType = imgToImgType(values.file_sell);
    newValues.file_sell.image = imgToBase64(values.file_sell);

    props.validateDelivery(newValues, props.router, props.router.params.id);
  }

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}
        onClose={() => props.router.push('/owner/delivery')}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{'Comprovantes de compra'}</DialogTitle>
        <DialogContent>
          <GeneralGrid container style={{ display: 'flex', justifyContent: 'center' }} spacing={3}>
            <GeneralGrid item xs={10}>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      label={'Comprovante de pagamento do usuário'}
                      name={'file_sell'}
                      component={InputFile}
                      validate={[FORM_RULES.required]}
                      required
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      label={'Comprovante de pagamento do gestor'}
                      name={'file_buy'}
                      component={InputFile}
                      validate={[FORM_RULES.required]}
                      required
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <Box
                  style={{
                    widht: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <PrimaryButton
                    type="submit"
                    disabled={props.loading}
                    progress={parseInt(props.general.submitProgress)}>
                    {props.loading ? 'Enviando comprovantes...' : 'Enviar comprovante'}
                  </PrimaryButton>
                </Box>
              </Form>
            </GeneralGrid>
          </GeneralGrid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeliveryReceiptForm.propTypes = {
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getOrderAndInitialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  validateDelivery: PropTypes.func,
};

DeliveryReceiptForm = reduxForm({
  form: 'DeliveryReceiptForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(DeliveryReceiptForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
    loading: state.deliveryOrders.loading,
    formValues: getFormValues('DeliveryReceiptForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ validateDelivery, getOrderAndInitialize }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReceiptForm);
