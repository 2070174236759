import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import { reduxForm, Form, Field } from 'redux-form';
import MaterialGrid from '@material-ui/core/Grid';
import { FORM_RULES } from 'helpers/validations';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import csv from 'csv';
import { Box } from '@material-ui/core';
import OnboardingCard from 'common/components/cards/onboardingCard/onboardingCard';
import { USER_TOKEN } from 'config/consts';
import { validateToken } from 'pages/auth/authActions';
import Papa from 'papaparse';
import { cpfMask } from '../../../../helpers/masks';
import { postCsvFileToContacts, postContacts } from './contactsActions';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      import: '',
      contacts: [],
    };
  }

  handleFile = (acceptedFiles) => {
    const first_time = this.props.location.query.first_contacts;
    const reader = new FileReader();
    acceptedFiles.forEach((file) => reader.readAsText(file));
    reader.onload = () => {
      console.log(reader.result);
      Papa.parse(reader.result, {
        delimiter: ';',
        header: true,
        complete: (results) => {
          console.log(results);
          this.props.postCsvFileToContacts(
            results.data,
            this.props.auth.user.establishments[0].id,
            this.props.router,
            first_time
          );
        },
      });
    };
  };

  onSubmit = async (values) => {
    const { auth, router } = this.props;
    const newValues = {
      establishment: auth.user.establishments[0].id,
      contacts: [values],
    };
    const first_time = this.props.location.query.first_contacts;
    await this.props.postContacts(newValues, router, first_time);

    if (this.props.auth.user) {
      await this.props.validateToken(
        JSON.parse(localStorage.getItem(USER_TOKEN)),
        this.props.auth.user.id
      );
    }
  };

  render() {
    const { contacts } = this.props;

    const { handleSubmit } = this.props;
    if (contacts.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    }
    return (
      <section className="modules">
        <MaterialGrid container spacing={2} direction="row" justify="center" alignItems="center">
          <MaterialGrid item>
            <OnboardingCard
              condition={false}
              onClickButton={() => this.setState({ import: 'csv' })}
              title="Cadastre novos participantes"
              text="produza um arquivo no formato .csv (veja o tutorial) e faça upload dos seus participantes aqui."
              router={this.props.router}
              buttonLabel="Iniciar Inclusão"
            />
          </MaterialGrid>
          <MaterialGrid item>
            <OnboardingCard
              condition={false}
              onClickButton={() => this.setState({ import: 'manual' })}
              title="Inclua participantes manualmente"
              text="Utilize o formulário para importar um participante"
              router={this.props.router}
              buttonLabel="Iniciar Inclusão"
            />
          </MaterialGrid>
        </MaterialGrid>
        <AlertDialogSlide
          visible={this.state.import === 'csv'}
          title="Importar CSV"
          onClose={() => {
            this.setState({ import: '' });
          }}>
          <Dropzone onDrop={(acceptedFiles) => this.handleFile(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ margin: 30 }}>
                <div
                  className="row align-items-center"
                  {...getRootProps()}
                  style={{
                    width: '100%',
                    height: 400,
                    borderWidth: 2,
                    borderColor: 'rgb(102, 102, 102)',
                    borderStyle: 'dashed',
                    borderRadius: 5,
                    margin: 'auto',
                    cursor: 'pointer',
                  }}>
                  <input {...getInputProps()} />
                  <center className="justify-content-center">
                    {this.props.auth.user?.establishments?.[0].stations?.[0].public
                      ? `Arraste (ou clique aqui) seu Arquivo .csv com os as colunas
        "Nome", "Email", "CPF" (sem pontuação), "Data de Nascimento (Formato: dd/MM/yyyy, exemplo: 01/01/1990)", "UF", "Celular (sem pontuação)" e "Entidades" para que seus contatos
        sejam importados. Os nomes das colunas precisam ter os mesmos nomes acima sem as aspas e, caso um usuário pertença a mais de uma entidade, os nomes das entidades devem estar separados por vírgulas sem espaços após a vírgula, por exemplo: "Entidade 1,Entidade 2,Entidade 3". Os nomes das entidades também devem ser escritos exatamente como foram criados na área de Entidades.`
                      : `Arraste (ou clique aqui) seu Arquivo .csv com os as colunas
                    "CPF" e "Associado", "Empregado", para que seus contatos
                    sejam importados`}
                  </center>
                </div>
              </section>
            )}
          </Dropzone>
        </AlertDialogSlide>
        <AlertDialogSlide
          visible={this.state.import === 'manual'}
          title="Digite CPF do contato"
          height="95vh"
          maxWidth="sm"
          onClose={() => {
            this.setState({ import: '' });
          }}>
          <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="CPF"
                  name="cpf"
                  required
                  validate={[FORM_RULES.required]}
                  {...cpfMask}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                  <Field component={InputSwitch} label={'É associado?'} name="associated" />
                </Box>
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <Box display="flex" alignItems="center" style={{ height: '100%' }}>
                  <Field component={InputSwitch} label={'É empregado?'} name="internal" />
                </Box>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="center">
              <MaterialGrid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <PrimaryButton
                    type="submit"
                    disabled={this.props.contacts.buttonLoading}
                    progress={parseInt(this.props.general.submitProgress)}>
                    {this.props.contacts.buttonLoading ? 'Enviando' : 'Enviar'}
                  </PrimaryButton>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </Form>
        </AlertDialogSlide>
      </section>
    );
  }
}

ContactForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  contacts: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_contacts: PropTypes.any,
    }),
  }),
  postContacts: PropTypes.func,
  postCsvFileToContacts: PropTypes.func,
  router: PropTypes.any,
  validateToken: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contacts: state.contacts,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postCsvFileToContacts, postContacts, validateToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'ContactForm' })(ContactForm));
