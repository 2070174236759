import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize } from 'redux-form';
import types from './type';

const STATIONS_JOURNEYS = `${BASE_API}/journeys-station`;
const JOURNEYS = `${BASE_API}/journeys`;
const LEVELS = `${BASE_API}/level`;
const ACTIONS = `${BASE_API}/avaliable-actions`;

export const getStationJourneys = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${STATIONS_JOURNEYS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.JOURNEYS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.JOURNEYS_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${JOURNEYS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const { data } = response;

        const tempLevels = [];
        for (const level of data.levels) {
          const tempLevel = {
            id: level.id,
            journey_level_id: level.journey_level_id,
            actions: level.journey_actions.length === 0 ? [{ required: true }] : [],
          };
          for (const action of level.journey_actions) {
            tempLevel.actions.push({
              createdAt: action.createdAt,
              action_id: action.action_id,
              required: action.required,
            });
          }
          tempLevels.push(tempLevel);
        }

        const initialValues = {
          journey_id: data.id,
          journey_name: data.name,
          start: data.start,
          end: data.end,
          priority: data.priority,
          levels: tempLevels,
        };

        dispatch([
          {
            type: types.JOURNEYS_DETAIL_FETCHED,
            payload: response.data,
          },
          initializeForm(initialValues),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.JOURNEYS_DETAIL_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const initializeForm = (values) => {
  return initialize('journeysForm', values);
};

export const createJourney = (station_id, router) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .post(
        `${JOURNEYS}`,
        { station_id },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
        ]);
        const journeyID = response.data.createJourney.id;
        router.push(`/owner/journeys/journey/${journeyID}`);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const updateJourney = (id, values, router, redirect = true) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_BUTTON_LOADING,
        payload: true,
      },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    let proceed = true;

    for (const level of values.levels) {
      if (level) {
        if (level.actions.length === 0) {
          proceed = false;
        } else {
          for (const action of level.actions) {
            if (!action.action_id) {
              proceed = false;
            }
          }
        }
      } else {
        proceed = false;
      }
    }

    if (proceed) {
      const newValues = { ...values };
      if (newValues.levels.length > 0) {
        const { levels } = newValues;
        newValues.levels[0].journey_level_id = null;

        for (let i = 1; i < newValues.levels.length; i++) {
          if (levels[i - 1].id) {
            newValues.levels[i].journey_level_id = levels[i - 1].id;
          }
        }
      }

      axios
        .put(`${JOURNEYS}/${id}`, newValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        })
        .then(() => {
          dispatch([
            {
              type: types.JOURNEYS_BUTTON_LOADING,
              payload: false,
            },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          if (redirect) {
            router.push(`/owner/journeys`);
          }
        })
        .catch((e) => {
          dispatch([
            {
              type: types.JOURNEYS_BUTTON_LOADING,
              payload: false,
            },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            if (e.response.data[0]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `Erro: ${e.response.data[0]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else {
      dispatch([
        {
          type: 'SNACKBAR',
          payload: {
            message: `Erro: todos os níveis precisam ter atividades selecionadas`,
            variant: 'error',
            open: true,
          },
        },
        {
          type: types.JOURNEYS_BUTTON_LOADING,
          payload: false,
        },
        {
          type: 'SUBMIT_PROGRESS',
          payload: 0,
        },
      ]);
    }
  };
};

export const deleteLevel = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .delete(`${LEVELS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getAvailableActions = (id, journey_id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.ACTIONS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${ACTIONS}/?where[station_id]=${id}&&where[journey_id]=${journey_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.ACTIONS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.ACTIONS_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const deleteJourney = (journey_id, station_id, setOpen) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.JOURNEYS_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .delete(`${JOURNEYS}/${journey_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
          getStationJourneys(station_id),
          setOpen(false),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: types.JOURNEYS_BUTTON_LOADING,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
