import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { cnpjMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { requestPasswordResetLogged } from 'pages/auth/authActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import Button from 'common/components/buttons/button';
import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import AddressesForm from '../addresses/addressesForm';
import { initializeForm, setDetail, updateProfile, getPagarmeDetail } from './profileActions';
import GatewayForm from './gateway/gatewayForm';
import { appColors } from '../../../../styles/colors';
import { cancelSubscription } from '../plans/plansActions';
const maxL = (max) => (value) =>
  value && value.length > max
    ? `Aceitamos ${max} caracteres ou menos. Seu atual: ${value.length}`
    : undefined;
const maxLength = maxL(30);
const onlyLetters = (value) => (value && /[^a-zA-Z ]/i.test(value) ? 'Apenas letras' : undefined);

let ProfileForm = function (props) {
  const {
    auth: {
      user: { subscription },
    },
  } = props;

  const [visible, setVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState(false);

  useEffect(() => {
    props.getPagarmeDetail(props.auth.user.establishments[0].id);

    return () => {
      setIsCancelModalVisible(false);
    };
  }, []);

  useEffect(() => {
    const values = {
      name: props.auth.user.name,
      email: props.auth.user.email,
      cnpj: props.auth.user.establishments[0].cnpj,
      establishment_name: props.auth.user.establishments[0].name,
      social_name: props.auth.user.establishments[0].social_name,
      full_social_name: props.auth.user.establishments[0].full_social_name,
      municipal_tax_number: props.auth.user.establishments[0].municipal_tax_number,
    };

    props.setDetail(values);
  }, [props.auth]);

  function onSubmit(values) {
    const values_perfil = {
      user: {
        id: props.auth.user.id,
        name: values.name,
      },
      establishment: {
        id: props.auth.user.establishments[0].id,
        name: values.establishment_name,
        social_name: values.social_name,
        full_social_name: values.full_social_name,
        cnpj: values.cnpj,
        municipal_tax_number: values.municipal_tax_number,
      },
    };

    return props.updateProfile(values_perfil);
  }
  const buttonClick = () => {
    const values = {
      email: props.auth.user.email,
    };
    const { router } = props;
    props.requestPasswordResetLogged(values, router);
    setVisible(true);
  };

  const handleClickOpenCancelModal = () => {
    setIsCancelModalVisible(true);
  };

  const handleClickCloseCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  function handleCancelSubscription() {
    function cleanup() {
      handleClickCloseCancelModal();
    }
    props.cancelSubscription({ cancelReason }, cleanup);
  }

  return props.auth.tokenLoading ? (
    <Box display="flex" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="coupons-form">
      {subscription.plan ? (
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <div className="card">
              <div className="card-body">
                <p className="title">
                  Plano <span style={{ color: appColors.v2.blue }}>{subscription.plan.name}</span>
                </p>
                <p className="h4" style={{ marginTop: 64 }}>
                  Sua próxima cobrança será no dia{' '}
                  {format(parseISO(subscription.current_period_end), 'dd/MM/yyyy')}, no valor de R$
                  {subscription.plan.amount.toFixed(2).replace('.', ',')}.
                </p>
                <Box display="flex" justifyContent="space-between" mt={8} alignItems="center">
                  <Box>
                    <p className="h4" style={{ textTransform: 'capitalize' }}>
                      {subscription.card.brand} ****** ****
                      {subscription.card.last_digits}
                    </p>
                    <p className="h6">
                      Expira em {subscription.card.expiration_date[0]}
                      {subscription.card.expiration_date[1]}/{subscription.card.expiration_date[2]}
                      {subscription.card.expiration_date[3]}
                    </p>
                  </Box>

                  <Box display="flex">
                    <Button
                      onClick={() => props.router.push('/owner/planos/editar-pagamento')}
                      label="Editar informações de pagamento"
                      type="secondary"
                      buttonStyle={{ marginRight: 16, padding: '16px' }}
                      buttonType="button"
                    />
                    <Button
                      onClick={() => props.router.push(`/owner/planos/mudar`)}
                      label="Mudar de plano"
                      buttonType="button"
                      buttonStyle={{ marginRight: 16, padding: '16px' }}
                    />
                    <Button
                      onClick={handleClickOpenCancelModal}
                      label="Cancelar assinatura"
                      buttonType="button"
                      buttonStyle={{
                        backgroundColor: '#FF2F59',
                        padding: '16px',
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </div>
          </GeneralGrid>
        </GeneralGrid>
      ) : null}
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Perfil</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="E-mail"
                      name="email"
                      validate={[FORM_RULES.required]}
                      required
                      disabled
                      margin="normal"
                      normalize={handleWhiteSpaces}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome"
                      name="name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome Fantasia"
                      name="establishment_name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Razão social resumida (gateway de pagamentos)"
                      name="social_name"
                      margin="normal"
                      validate={[maxLength, FORM_RULES.required, onlyLetters]}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Razão social completa (para os Termos de Uso)"
                      name="full_social_name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="CNPJ"
                      name="cnpj"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      {...cnpjMask}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Inscrição municipal"
                      name="municipal_tax_number"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <div className="row justify-content-around mb-3 mt-3">
                  <PrimaryButton
                    type="button"
                    onClick={() => buttonClick()}
                    disabled={props.coupons.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}>
                    {props.coupons.buttonLoading ? 'Enviando' : 'Mudar minha senha'}
                  </PrimaryButton>
                  {visible ? <p>Você receberá um e-mail com as instruções. Obrigado!</p> : null}
                  <PrimaryButton router={props.router} type="submit" color="primary">
                    Enviar
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Endereço</p>
              <AddressesForm />
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div
            className="card"
            style={{
              marginBottom: isMobile ? '4rem' : null,
            }}>
            <div className="card-body">
              <p className=" h4 card-title text-muted">Informações para vendas</p>
              <GatewayForm />
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
      {subscription.plan && (
        <Dialog
          open={isCancelModalVisible}
          onClose={handleClickCloseCancelModal}
          aria-labelledby="Cancelamento de plano"
          aria-describedby="Formulário para preencher motivo de cancelamento">
          <DialogTitle
            id="Cancelamento de plano"
            style={{
              paddingTop: 24,
            }}>{`Cancelamento do plano ${subscription.plan.name}`}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Para melhorar nossos serviços, gostaríamos de saber o motivo para o cancelamento de
              sua assinatura. Por favor, selecione a opção adequada.
            </DialogContentText>
            <SelectLabel
              component={SelectLabel}
              options={[
                {
                  label: 'Prefiro não mencionar',
                  value: 'Prefiro não mencionar',
                },
                {
                  label: 'Não acho um bom custo-benefício',
                  value: 'Não acho um bom custo-benefício',
                },
                {
                  label: 'Não utilizo mais o produto',
                  value: 'Não utilizo mais o produto',
                },
                { label: 'Outro', value: 'Outro' },
              ]}
              onChange={(e) => console.log(e)}
              label="Escolha uma opção"
              input={{
                onChange: (e) => setCancelReason(e.target.value),
                value: cancelReason,
              }}
            />
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClickCloseCancelModal}
              type="secondary"
              label="Fechar"
              disabled={props.plans.subscriptionsLoading}
              progress={parseInt(props.general.submitProgress)}
            />
            <Button
              onClick={handleCancelSubscription}
              autoFocus
              label="Realizar cancelamento"
              buttonType="button"
              disabled={props.plans.subscriptionsLoading}
              progress={parseInt(props.general.submitProgress)}
            />
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
};

ProfileForm.propTypes = {
  auth: PropTypes.shape({
    tokenLoading: PropTypes.any,
    user: PropTypes.shape({
      email: PropTypes.any,
      establishments: PropTypes.any,
      id: PropTypes.any,
      name: PropTypes.any,
      subscription: PropTypes.any,
    }),
  }),
  cancelSubscription: PropTypes.func,
  coupons: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getPagarmeDetail: PropTypes.func,
  handleSubmit: PropTypes.func,
  plans: PropTypes.shape({
    subscriptionsLoading: PropTypes.any,
  }),
  requestPasswordResetLogged: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  setDetail: PropTypes.func,
  updateProfile: PropTypes.func,
};

ProfileForm = reduxForm({ form: 'ProfileForm' })(ProfileForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    formValues: getFormValues('ProfileForm')(state),
    general: state.general,
    plans: state.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDetail,
      initializeForm,
      requestPasswordResetLogged,
      getPagarmeDetail,
      updateProfile,
      cancelSubscription,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
