import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Giveaway from './index';
import GiveawaysForm from './giveawaysForm';
import GiveawayDrawForm from './giveawayDrawForm';
import GiveawayImport from './giveawayImport';

export default (
  <Route path="giveaway">
    <IndexRoute component={(props) => <Giveaway {...props} />} />
    <Route path="editar/:giveaway_id" component={(props) => <GiveawaysForm {...props} />} />
    <Route path="criar" component={(props) => <GiveawaysForm {...props} />} />
    <Route path="sortear/:giveaway_id" component={(props) => <GiveawayDrawForm {...props} />} />
    <Route path="importar/:giveaway_id" component={(props) => <GiveawayImport {...props} />} />
  </Route>
);
