const INITIAL_STATE = {
  classifiedList: [],
  classifiedLoading: false,
  item: {},
  loadingDetail: true,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@CLASSIFIED_ADS_LOAD':
      return { ...state, classifiedLoading: action.payload };
    case '@CLASSIFIED_ADS_FETCHED':
      return {
        ...state,
        classifiedLoading: false,
        classifiedList: action.payload,
      };
    case '@MODERATOR_DETAIL_LOAD':
      return { ...state, loadingDetail: action.payload };
    case '@MODERATOR_DETAIL_FETCHED':
      return { ...state, loadingDetail: false, item: action.payload };
    default:
      return state;
  }
};
