import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

export default function ProductOrders(props) {
  const { id } = props.auth.user.establishments[0].stations[0];
  const { router } = props;
  const { list, loading, deliveryStatusesList } = props.productOrders;

  const [columns, setColumns] = useState([]);
  const [csvData, setCSVData] = useState(null);

  function renderFullPrice(item) {
    return <div>{`R$ ${parseFloat(item).toFixed(2).toString().replace('.', ',')}`}</div>;
  }

  function handleOpenDetailModal(_, rowData) {
    router.push(`/owner/sales/${rowData.id}`);
  }

  useEffect(() => {
    props.getOrdersWithId(id);
    props.getDeliveryStatuses();
  }, []);

  useEffect(() => {
    const deliveryStatusLookup = {};
    for (let i = 0; i < deliveryStatusesList.length; i += 1) {
      deliveryStatusLookup[`${deliveryStatusesList[i].id}`] = deliveryStatusesList[i].status;
    }

    if (deliveryStatusesList.length > 0) {
      setColumns([
        {
          field: 'purchase_id',
          title: 'ID',
          editable: 'never',
        },
        {
          field: 'gateway_charge.external_id',
          title: 'ID Pagar.me',
          editable: 'never',
          render: (rowData) => rowData.gateway_charge?.external_id,
        },
        {
          field: 'action.name',
          title: 'Atividade',
          render: (rowData) => rowData.action?.name,
        },
        {
          field: 'createdAt',
          title: 'Data',
          editable: 'never',
        },
        {
          field: 'user.name',
          title: 'Nome',
          editable: 'never',
          render: (rowData) => rowData.user?.name,
        },
        {
          field: 'user.cpf',
          title: 'CPF',
          editable: 'never',
          render: (rowData) => rowData.user?.cpf,
        },
        {
          title: 'Valor Final',
          field: 'final_value',
          render: (rowData) => {
            return renderFullPrice(rowData.final_value);
          },
          editable: 'never',
        },
        {
          title: 'Valor pago da carteira',
          field: 'wallet_value',
          render: (rowData) => {
            return renderFullPrice(rowData.wallet_value);
          },
          editable: 'never',
          export: true,
        },
        {
          title: 'Valor líquido da carteira',
          render: (rowData) => {
            const futureTransaction = rowData.future_transactions?.find(
              (item) => !item.gateway_or_wallet
            );
            if (futureTransaction) {
              return renderFullPrice(futureTransaction.value);
            } else if (rowData.status === 'Pagamento recusado') {
              return 'R$ 0,00';
            }
            return 'Erro';
          },
          editable: 'never',
        },
        {
          title: 'Valor pago no cartão',
          field: 'total_credit_card_value',
          render: (rowData) => {
            const samePurchases = props.productOrders.list.filter(
              (item) => item.purchase_id === rowData.purchase_id
            );
            let totalWalletValue = 0;
            samePurchases.forEach((item) => {
              totalWalletValue += parseFloat(item.wallet_value);
            });
            if (totalWalletValue > 0) {
              return renderFullPrice(
                parseFloat(rowData.total_credit_card_value) *
                  (parseFloat(rowData.wallet_value) / totalWalletValue)
              );
            }
            return renderFullPrice(parseFloat(rowData.total_credit_card_value));
          },
          editable: 'never',
        },
        {
          title: 'Valor líquido do cartão',
          render: (rowData) => {
            const futureTransaction = rowData.future_transactions.find(
              (item) => item.gateway_or_wallet
            );
            if (futureTransaction) {
              return renderFullPrice(futureTransaction.value);
            } else if (rowData.status === 'Pagamento recusado') {
              return 'R$ 0,00';
            }
            return 'R$ 0,00';
          },
          editable: 'never',
        },
        {
          title: 'Status',
          field: 'status',
          editable: 'never',
        },
      ]);
    }
  }, [deliveryStatusesList.length]);

  useEffect(() => {
    const samePurchaseArray = [];
    const formattedCSVData = [
      [
        'ID',
        'ID Pagar.me',
        'Atividade',
        'Data',
        'Nome',
        'CPF',
        'Produtos',
        'Valor final',
        'Valor pago da carteira',
        'Valor líquido da carteira',
        'Valor pago no cartão',
        'Valor líquido do cartão/PIX',
        'Status',
        'Método de pagamento',
      ],
    ];
    for (let i = 0; i < props.productOrders.list.length; i += 1) {
      const item = props.productOrders.list[i];
      /* const index = samePurchaseArray.findIndex(secondItem => {
        return secondItem.purchase_id === item.purchase_id;
      }); */
      const futureTransaction = item.future_transactions.find(
        (secondItem) => !secondItem.gateway_or_wallet
      );
      const futureTransactionCreditCard = item.future_transactions.find(
        (secondItem) => secondItem.gateway_or_wallet
      );
      const samePurchases = props.productOrders.list.filter(
        (secondItem) => secondItem.purchase_id === item.purchase_id
      );
      let totalWalletValue = 0;
      samePurchases.forEach((secondItem) => {
        totalWalletValue += parseFloat(secondItem.wallet_value);
      });
      const notRepeatedProducts = [];
      item.single_product_orders.forEach((secondItem) => {
        const indexFound = notRepeatedProducts.findIndex(
          (thirdItem) => thirdItem.id === secondItem.id
        );
        if (indexFound >= 0) {
          notRepeatedProducts[indexFound] = {
            ...notRepeatedProducts[indexFound],
            quantity: notRepeatedProducts[indexFound].quantity + 1,
          };
        } else {
          notRepeatedProducts.push({
            name: secondItem.product.name,
            quantity: 1,
          });
        }
      });
      samePurchaseArray.push({
        ...item,
      });
      formattedCSVData.push([
        item.purchase_id,
        item.gateway_charge ? item.gateway_charge.external_id : '',
        item.action ? item.action.name : '',
        item.createdAt,
        item.user?.name,
        item.user?.cpf,
        notRepeatedProducts.map((secondItem) => `${secondItem.name} x${secondItem.quantity}; `),
        item.final_value.toString().replace('.', ','),
        item.wallet_value.toString().replace('.', ','),
        futureTransaction
          ? futureTransaction.value.toString().replace('.', ',')
          : item.status === 'Pagamento recusado' ||
            item.wallet_value === '0.00' ||
            (item.gateway_charge?.payment_method === 'pix' && item.status === 'Expirado')
          ? '0,00'
          : 'Erro',
        totalWalletValue > 0
          ? _.round(
              parseFloat(item.total_credit_card_value) *
                (parseFloat(item.wallet_value) / totalWalletValue),
              2
            )
              .toString()
              .replace('.', ',')
          : item.total_credit_card_value.toString().replace('.', ','),
        futureTransactionCreditCard
          ? futureTransactionCreditCard.value.toString().replace('.', ',')
          : item.status === 'Pagamento recusado' || item.total_credit_card_value === '0.00'
          ? '0,00'
          : 'Erro',
        item.status,
        item.gateway_charge
          ? item.gateway_charge.payment_method === 'pix'
            ? 'PIX'
            : 'Cartão de crédito'
          : parseFloat(item.total_credit_card_value) > 0
          ? 'Cartão de crédito'
          : 'Somente carteira',
      ]);
      /* samePurchaseArray[index] = {
          ...samePurchaseArray[index],
          wallet_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].wallet_value) +
              parseFloat(item.wallet_value)
            ).toFixed(2)
          ),
          final_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].final_value) +
              parseFloat(item.final_value)
            ).toFixed(2)
          ),
        };
        formattedCSVData.push([
          samePurchaseArray[index].purchase_id,
          samePurchaseArray[index].gateway_charge
            ? samePurchaseArray[index].gateway_charge.external_id
            : '',
          samePurchaseArray[index].action
            ? samePurchaseArray[index].action.name
            : '',
          samePurchaseArray[index].createdAt,
          samePurchaseArray[index].user.name,
          samePurchaseArray[index].user.cpf,
          parseFloat(
            (
              parseFloat(samePurchaseArray[index].wallet_value) +
              parseFloat(item.wallet_value)
            ).toFixed(2)
          ),
          futureTransaction
            ? futureTransaction.value
            : item.status === 'Pagamento recusado'
            ? 0
            : 'Erro',
          samePurchaseArray[index].total_credit_card_value,
          futureTransactionCreditCard
            ? futureTransactionCreditCard.value
            : item.status === 'Pagamento recusado'
            ? 0
            : 'Erro',
          parseFloat(
            (
              parseFloat(samePurchaseArray[index].final_value) +
              parseFloat(item.final_value)
            ).toFixed(2)
          ),
          item.status,
        ]); */
    }
    setCSVData(formattedCSVData);
  }, [props.productOrders.list]);

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não tem nenhum pedido</h2>
                <p>Os pedidos serão feitos pelos participantes e aparecerão aqui.</p>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading || !columns.find((item) => item.field === 'gateway_charge.external_id')) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3} mt={2}>
        <GeneralGrid item xs={12} style={{ marginTop: 20, width: isMobile ? '89vw' : undefined }}>
          <Table
            buttonCSV
            csvData={csvData}
            searchPlaceholder={'Procurar por nome de usuário'}
            hasToolbar={true}
            title="Vendas"
            columns={columns}
            dataType={'sales'}
            rows={props.productOrders.list}
            toolbarSearch
            onRowClick={handleOpenDetailModal}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ProductOrders.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getDeliveryStatuses: PropTypes.func,
  getOrdersWithId: PropTypes.func,
  productOrders: PropTypes.shape({
    deliveryStatusesList: PropTypes.shape({
      length: PropTypes.number,
    }),
    list: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
