import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Grid from 'common/components/layout/grid/grid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './benefitsActions';

let BenefitsForm = function (props) {
  const { benefits, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(benefits, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      station: props.auth.user.establishments[0].stations[0].id,
    };

    console.log(values);
    create_or_update(router, newValues, create, update);
  }

  return props.benefits.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="benefits-form">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Formulário</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={8} md={4} lg={3}>
                    <Field
                      component={InputLabelOutlined}
                      label="Título"
                      name="title"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Subtítulo'}
                      name="subtitle"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Benefício 1'}
                      name="b1"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Benefício 2'}
                      name="b2"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Benefício 3'}
                      name="b3"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Benefício 4'}
                      name="b4"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      multiline
                      label={'Link da mídia'}
                      name="media"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={10} md={6} lg={6}>
                    <Field
                      component={SelectLabel}
                      label={`Tipo da mídia`}
                      name={`type`}
                      margin="normal"
                      options={[
                        { value: 'image', label: 'Imagem' },
                        { value: 'video', label: 'Vídeo' },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </GeneralGrid>

                  <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                    <PrimaryButton
                      type="submit"
                      disabled={props.benefits.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}>
                      {props.benefits.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </Grid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

BenefitsForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  benefits: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  create: PropTypes.any,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

BenefitsForm = reduxForm({ form: 'benefitsForm' })(BenefitsForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    benefits: state.benefits,
    formValues: getFormValues('benefitsForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsForm);
