import React from 'react';
import { Box } from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';

const Loading = () => (
  <GeneralGrid container spacing={3}>
    <GeneralGrid item container xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center">
        <LoadingProgress />
      </Box>
    </GeneralGrid>
  </GeneralGrid>
);

export default Loading;
