import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const STATIONS_CLASSIFIED = `${BASE_API}/station-classified-ads`;
const CLASSIFIED_AD = `${BASE_API}/classified-ads`;

export const getStationClassifiedAds = (station_id) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@CLASSIFIED_ADS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${STATIONS_CLASSIFIED}/${station_id}/?moderator=true`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@CLASSIFIED_ADS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: '@CLASSIFIED_ADS_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const deactivateClassifiedAds = (id, active, station_id) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@CLASSIFIED_ADS_LOAD',
        payload: true,
      },
    ]);
    axios
      .put(
        `${CLASSIFIED_AD}/${id}`,
        { active },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(() => {
        dispatch([
          {
            type: '@CLASSIFIED_ADS_LOAD',
            payload: false,
          },
          getStationClassifiedAds(station_id),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: '@CLASSIFIED_ADS_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getClassifiedAdDetail = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: '@MODERATOR_DETAIL_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${CLASSIFIED_AD}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: '@MODERATOR_DETAIL_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: '@MODERATOR_DETAIL_LOAD',
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
