import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Content extends PureComponent {
  render() {
    return <section className="content">{this.props.children}</section>;
  }
}

Content.propTypes = {
  children: PropTypes.any,
};
