import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

export default function CustomizedPaper(props) {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5">{props.title}</Typography>
        <Typography variant="body1">{props.body}</Typography>
      </Paper>
    </div>
  );
}

CustomizedPaper.propTypes = {
  body: PropTypes.any,
  title: PropTypes.any,
};
