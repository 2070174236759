import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import types from './types';

const URL = `${BASE_API}/station-subscriptions`;
const URL_REWARD_PRESENTIAL_COURSE = `${BASE_API}/reward-presential-course`;
const URL_CANCEL_PRESENTIAL_COURSE_SUBSCRIPTION = `${BASE_API}/cancel-presential-course-subscription`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.SUBSCRIPTIONS_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getValidatedList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const modifiedResponse = response.data.filter(
          (item) => parseInt(item.validated) === 1 && !(item.cancelled || item.paid)
        );
        modifiedResponse.forEach((item) => {
          item.validated = parseInt(item.validated) === 0 ? 'Não' : 'Sim';
          item.createdAt = moment(item.createdAt).format('DD/MM/YYYY HH:mm');
        });

        dispatch([
          {
            type: types.SUBSCRIPTIONS_FETCHED,
            payload: { data: modifiedResponse },
          },
        ]);
      });
  };
};

export const rewardPresentialCourse = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: true });
    axios
      .put(
        `${URL_REWARD_PRESENTIAL_COURSE}/${data.id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: types.SUBSCRIPTIONS_REWARD_MODAL,
            payload: false,
          },
          { type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: false },
          getValidatedList(data.presential_course.partner_id),
        ]);
      });
  };
};

export const cancelPresentialCourseSubscription = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: true });
    axios
      .put(
        `${URL_CANCEL_PRESENTIAL_COURSE_SUBSCRIPTION}/${data.id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: types.SUBSCRIPTIONS_CANCEL_MODAL,
            payload: false,
          },
          { type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: false },
          getValidatedList(data.presential_course.partner_id),
        ]);
      })
      .catch((e) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: e.response.data.error,
              variant: 'error',
              open: true,
            },
          },
          { type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: false },
        ]);
      });
  };
};

export const setSubscriptionRewardLoading = (status) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_REWARD_LOAD, payload: status });
  };
};

export const setSubscriptionRewardModal = (status) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_REWARD_MODAL, payload: status });
  };
};

export const setSubscriptionCancelModal = (status) => {
  return (dispatch) => {
    dispatch({ type: types.SUBSCRIPTIONS_CANCEL_MODAL, payload: status });
  };
};
export const removeCode = (code, other = undefined) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Retornar uma Promise
      dispatch({ type: types.SUBSCRIPTIONS_LOAD, payload: false });
      axios
        .delete(`${URL}/${code}`, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((response) => {
          dispatch([
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Operação realizada com sucesso',
                variant: 'success',
                open: true,
              },
            },
            {
              type: types.SUBSCRIPTIONS_LOAD,
              payload: false,
            },
            getList(other),
          ]);
        })
        .catch((e) => {
          try {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.message}`,
                variant: 'error',
                open: true,
              },
            });
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    });
  };
};
