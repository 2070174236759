import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import { suspendActivity } from '../actions/activeActions/activeActionsScripts';
import { deleteLevel, getAvailableActions, getDetail, updateJourney } from './journeysActions';
import { Container, ContainerFields } from './styles';

let JourneysForm = function (props) {
  // Estados locais da aplicação
  const [actionsList, setActionsList] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [fieldInfo, setFieldInfo] = useState({});
  const [levelInfo, setLevelInfo] = useState({});
  const [levelOrActivity, setLevelOrActivity] = useState('');

  // Funções para deletar nível da jornada
  function handleDeleteLevel(level, array, index) {
    let activitySaved = false;

    for (const action of level.actions) {
      if (action.createdAt) {
        activitySaved = true;
      }
    }
    // Se a atividade já tiver salva no banco, ele tem que fazer o delete na api
    if (level.id || activitySaved) {
      // A modal é controlada por state para que não fique uma acima da outra
      setConfirmDialog(true);
      setLevelOrActivity('level');
      setLevelInfo({
        level,
        array,
        index,
      });
    }
    // Se não tiver salvo no banco ele vai apenas remover do redux
    else {
      array.remove(index);
    }
  }

  function archiveAndRemoveLevel(level, array, index) {
    setConfirmDialog(false);
    setLevelInfo({ removed: true });
    props.deleteLevel(level.id);
    array.remove(index);
    for (const action of level.actions) {
      props.suspendActivity(action.action_id);
    }
  }

  function justRemoveLevel(level, array, index) {
    setLevelInfo({});
    setConfirmDialog(false);
    props.deleteLevel(level.id);
    array.remove(index);
  }

  // Funções para deletar atividade de um nível da jornada
  function handleDeleteActivity(activity, array, index) {
    if (activity.createdAt) {
      setLevelOrActivity('activity');
      setConfirmDialog(true);
      setFieldInfo({
        id: activity.id,
        array,
        index,
      });
    } else {
      array.remove(index);
    }
  }

  function archiveAndRemove(id, array, index) {
    setFieldInfo({ removed: true });
    setConfirmDialog(false);
    array.remove(index);
    props.suspendActivity(id);
  }

  function justRemove(array, index) {
    setFieldInfo({});
    setConfirmDialog(false);
    array.remove(index);
  }

  // Funções de render de níveis e atividades respectivamente
  const renderLevels = (props) => {
    return (
      <Grid container spacing={3}>
        <Grid xs={12} style={{ padding: '0 12px' }}>
          <Box display="flex" justifyContent="flex-end">
            <PrimaryButton
              type="button"
              style={{ margin: '15px 0' }}
              onClick={() =>
                // Criar nova atividade com o required já marcado como true (toggle de atividade)
                props.fields.push({ actions: [{ required: true }] })
              }
              disabled={
                selectedActions.length === actionsList.length && selectedActions.length > 0
              }>
              + adicionar nível
            </PrimaryButton>
          </Box>
          <Container>
            {props.fields.map((level, index) => (
              <Grid
                key={index}
                item
                xs={12}
                direction="column"
                style={{
                  background: '#ffffff',
                  borderRadius: 12,
                  padding: 12,
                }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>{`Nível ${index + 1}`}</Typography>
                  <IconButton
                    style={{
                      float: 'right',
                      margin: '10px 0px',
                    }}
                    size="small"
                    aria-label="close"
                    onClick={() =>
                      handleDeleteLevel(
                        props.fields.get(index) ? props.fields.get(index) : null,
                        props.fields,
                        index
                      )
                    }>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <FieldArray
                  name={`${level}.actions`}
                  component={renderActions}
                  levelsField={props.fields}
                  levelIndex={index}
                  validate={[FORM_RULES.actions]}
                />
              </Grid>
            ))}
          </Container>
        </Grid>
      </Grid>
    );
  };

  const renderActions = ({ fields, levelsField, levelIndex, meta: { error } }) => {
    const MyFieldTip = React.forwardRef(function MyFieldTip(props, ref) {
      //  Distribua as propriedades para o elemento DOM subjacente.
      return (
        <div {...props} ref={ref}>
          <Field {...props} ref={ref} />
        </div>
      );
    });

    return (
      <Box display="flex" justifyContent="flex-start" flexDirection="column">
        {fields.map((actions, index) => (
          <Box display="flex" alignItems="center" key={index}>
            {levelIndex > 0 && fields.length > 1 && (
              // Mudar atividade para a o level anterior
              <ArrowBackIosIcon
                onClick={() => {
                  moveBackwards(index, levelIndex, fields, levelsField);
                }}
                style={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
            )}
            <Field
              name={`${actions}.action_id`}
              required
              label="Selecione uma atividade"
              component={SelectLabel}
              containerStyle={{ display: 'contents' }}
              options={actionsList}
            />
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="Seleção de obrigatoriedade. Selecione as atividades que serão obrigatórias para liberar o próximo nível">
              <MyFieldTip name={`${actions}.required`} component={InputSwitch} default={true} />
            </Tooltip>
            <IconButton
              style={{
                margin: '10px 0',
                height: 30,
                display: fields.length > 1 ? 'inline' : 'none',
              }}
              size="small"
              aria-label="close"
              onClick={() =>
                handleDeleteActivity(fields.get(index) ? fields.get(index) : null, fields, index)
              }>
              <CloseIcon />
            </IconButton>
            {levelIndex < levelsField.length - 1 && fields.length > 1 && (
              // Mudar a atividade para o próximo nível
              <ArrowForwardIosIcon
                onClick={() => {
                  moveForward(index, levelIndex, fields, levelsField);
                }}
                style={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
            )}
          </Box>
        ))}
        {error && <span style={{ color: 'red' }}>{error}</span>}
        <PrimaryButton
          type="button"
          style={{ margin: '10px 0' }}
          onClick={() => fields.push()}
          disabled={selectedActions.length === actionsList.length && selectedActions.length > 0}>
          + adicionar atividade
        </PrimaryButton>
      </Box>
    );
  };

  // Funções para alterar atividade para o próximo nível
  function moveForward(activityIndex, levelIndex, activitiesField, levelsField) {
    if (activitiesField.length > 1) {
      const activity = activitiesField.get(activityIndex);
      activitiesField.remove(activityIndex);
      levelsField.get(levelIndex + 1).actions.push(activity);
    }
  }

  // Funções para alterar atividade para o nível anterior
  function moveBackwards(activityIndex, levelIndex, activitiesField, levelsField) {
    if (activitiesField.length > 1) {
      const activity = activitiesField.get(activityIndex);
      activitiesField.remove(activityIndex);
      levelsField.get(levelIndex - 1).actions.push(activity);
    }
  }
  // Puxa os detalhes da jornada e as atividades que podem ser alocadas
  useEffect(() => {
    props.getAvailableActions(
      props.auth.user.establishments[0].stations[0].id,
      props.router.params.id
    );
    props.getDetail(props.router.params.id);
  }, []);

  // Reorganiza o array de atividades disponíveis para mostra apenas aquelas que ainda não foram selecionadas
  useEffect(() => {
    const activeActions = props.availableActions.map((item) => ({
      value: item.id,
      label: item.name,
      disabled: false,
    }));

    const journeyLevels = props.formValues ? props.formValues.levels : [];
    const selectedActions = [];

    if (journeyLevels.length > 0) {
      for (const level of journeyLevels) {
        if (level && level.actions && level.actions) {
          for (const action of level.actions) {
            if (action) {
              selectedActions.push(parseInt(action.action_id));
            }
          }
        }
      }
    }

    for (const action of activeActions) {
      if (selectedActions.indexOf(parseInt(action.value)) > -1) {
        action.disabled = true;
      }
    }

    setSelectedActions(selectedActions);
    setActionsList(activeActions);
  }, [props.availableActions, props.formValues && props.formValues.levels]);

  // Monitoramento de delete da atividade ou nível para renderizar atividades disponíveis corretas
  useEffect(() => {
    if (fieldInfo.removed || levelInfo.removed) {
      onSubmit(props.formValues, false);
      props.getAvailableActions(
        props.auth.user.establishments[0].stations[0].id,
        props.router.params.id
      );
    }
  }, [props.formValues]);

  useEffect(() => {
    props.getAvailableActions(
      props.auth.user.establishments[0].stations[0].id,
      props.router.params.id
    );
  }, [fieldInfo, levelInfo]);

  // Fecha a modal de edição da jornada
  const onDialogClose = () => {
    props.router.push('/owner/journeys');
  };

  // Fecha a modal de confirmação
  const onConfirmClose = () => {
    setConfirmDialog(false);
  };

  // Função de editar a jornada
  function onSubmit(values, redirect = true) {
    props.updateJourney(values.journey_id, values, props.router, redirect);
  }

  // Renderiza isso se não tiver jornadas ativas
  if (props.journeys.loadingDetail) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    );
  }

  // Retorno principal da função
  return (
    <>
      <AlertDialogSlide
        title="Jornada"
        visible={true}
        height="95vh"
        onClose={onDialogClose}
        maxWidth={'lg'}
        titleBackground="#f7f7f7"
        contentBackground="#f7f7f7">
        <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ContainerFields>
                <Field
                  name="journey_name"
                  component={InputLabelOutlined}
                  required
                  label={'Nome da jornada'}
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelOutlined}
                  label={'Data da publicação da jornada'}
                  name="start"
                  type="datetime-local"
                  required
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelOutlined}
                  name="end"
                  type="datetime-local"
                  required
                  label={'Data de finalização da jornada'}
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelOutlined}
                  name="priority"
                  type="number"
                  required
                  label={'Prioridade'}
                />
              </ContainerFields>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldArray name="levels" component={renderLevels} />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }} justify="flex-end">
                  <SecondaryButton
                    onClick={onDialogClose}
                    style={{ margin: '10px 10px 10px 0' }}
                    color="primary">
                    Voltar
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    style={{ margin: '10px 0' }}
                    disabled={props.journeys.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}>
                    Salvar
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
        <AlertDialogSlide
          title="Confirme a sua escolha"
          visible={confirmDialog}
          onClose={onConfirmClose}
          maxWidth={'sm'}
          titleBackground="#f7f7f7"
          contentBackground="#f7f7f7">
          <Typography>
            {`${
              levelOrActivity === 'activity'
                ? 'Essa atividade já esta atrelada a uma jornada, para retirá-la da jornada você pode escolher entre arquivá-la ou mantê-la online nas atividades. O que deseja fazer?'
                : 'Esse level esta atrelado a uma jornada, para remove-lo da jornada você pode escolher entre arquivar as atividades desse nível ou mantê-las online. O que deseja fazer?'
            }`}
          </Typography>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <SecondaryButton
                style={{ margin: '20px 15px 20px 0 ' }}
                type="button"
                onClick={onConfirmClose}>
                Cancelar
              </SecondaryButton>
              <SecondaryButton
                style={{ margin: '20px 15px 20px 0' }}
                type="button"
                onClick={() =>
                  levelOrActivity === 'activity'
                    ? justRemove(fieldInfo.array, fieldInfo.index)
                    : levelOrActivity === 'level'
                    ? justRemoveLevel(levelInfo.level, levelInfo.array, levelInfo.index)
                    : null
                }>
                Remover e manter online
              </SecondaryButton>
              <PrimaryButton
                style={{ margin: '20px 0' }}
                type="button"
                onClick={() =>
                  levelOrActivity === 'activity'
                    ? archiveAndRemove(fieldInfo.id, fieldInfo.array, fieldInfo.index)
                    : levelOrActivity === 'level'
                    ? archiveAndRemoveLevel(levelInfo.level, levelInfo.array, levelInfo.index)
                    : null
                }>
                Remover e arquivar
              </PrimaryButton>
            </Grid>
          </Grid>
        </AlertDialogSlide>
      </AlertDialogSlide>
    </>
  );
};

JourneysForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  availableActions: PropTypes.shape({
    map: PropTypes.func,
  }),
  deleteLevel: PropTypes.func,
  fields: PropTypes.shape({
    get: PropTypes.func,
    map: PropTypes.func,
    push: PropTypes.func,
  }),
  formValues: PropTypes.shape({
    levels: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getAvailableActions: PropTypes.func,
  getDetail: PropTypes.func,
  handleSubmit: PropTypes.func,
  journeys: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loadingDetail: PropTypes.any,
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  suspendActivity: PropTypes.func,
  updateJourney: PropTypes.func,
};

JourneysForm = reduxForm({
  form: 'journeysForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(JourneysForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    availableActions: state.journeys.actionsList,
    journeys: state.journeys,
    general: state.general,
    formValues: getFormValues('journeysForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAvailableActions,
      getDetail,
      updateJourney,
      deleteLevel,
      suspendActivity,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneysForm);
