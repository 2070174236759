import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  min-height: 89vh;
  background: #ffffff;
  footer {
    display: flex;
    justify-content: flex-end;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;

  section {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    span {
      font-size: 14px;
      line-height: 24px;
      color: #2d95ef;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 30px;
      color: #2d95ef;
    }
  }

  footer {
    display: flex;
    width: fit-content;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    background: #f7fafa;
    margin: 0;

    span {
      margin-top: 10px;
    }
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 50px 0;

  header {
    display: flex;
    justify-content: flex-end;

    p {
      text-align: right;
      flex: 0 0 20%;
      margin: 10px 0 0 0;
      font-size: 16px;

      & + p {
        padding-right: 35px;
      }
    }
  }
`;

export const InteractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex: 1;

  button {
    width: fit-content;
    color: white;
    font-size: 20px;
    padding: 10px 30px;
    margin-bottom: 20px;
    background: #2d95ef;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.5s;

    &:focus {
      outline: none;
    }

    &:hover {
      background: ${shade(0.2, '#2d95ef')};
    }
  }

  a {
    text-decoration: underline;
    color: #505050;
  }
`;

export const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 41px;

  letter-spacing: 0.25px;
`;

export const Subtitle = styled.p`
  /* Subtile 1 */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  /* Gray838383 */

  color: #838383;
`;

export const Titlecontainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
