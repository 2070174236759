import { InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect } from 'react';
import { appColors } from 'styles/colors';

import PropTypes from 'prop-types';

const useStylesSelectLabel = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
  inputLabel: {
    color: appColors.primary,
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fullWidth: {
    minWidth: '100%',
  },
}));

export function SelectMultiple(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [option, setOption] = React.useState([]);

  function handleChange(event) {
    const marked = event.target.value;
    if (option.length === 0) {
      setOption(marked);
    } else if (option.indexOf('all') > -1) {
      marked.splice(marked.indexOf('all'), 1);
      setOption(marked);
    } else if (option.indexOf('none') > -1) {
      marked.splice(marked.indexOf('none'), 1);
      setOption(marked);
    } else if (!(option.indexOf('all') > -1) || !(option.indexOf('none') > -1)) {
      if (marked.indexOf('all') > -1) {
        setOption(['all']);
      } else if (marked.indexOf('none') > -1) {
        setOption(['none']);
      } else {
        setOption(marked);
      }
    }
  }

  useEffect(() => {
    if (props.input.value.length > 0) {
      setOption(props.input.value);
    }
  }, [props.input.value]);

  const {
    meta: { touched, error },
  } = props;

  return (
    <div className={classes.root}>
      <FormControl
        aria-label="Tooltip do Formulário"
        variant="outlined"
        color="primary"
        className={classes.formControl}
        disabled={props.disabled}
        required={props.required}>
        <InputLabel ref={inputLabel} className={classes.inputLabel} htmlFor="age-customized-select">
          {props.label}
        </InputLabel>
        <Select
          {...props.input}
          margin={props.margin}
          error={!!(touched && error)}
          value={option}
          onChange={handleChange}
          multiple
          renderValue={(selected) => {
            const selectedLabelsList = props.options
              .filter((i) => selected.includes(i.value))
              .map((i) => i.label);
            return selectedLabelsList.join(', ');
          }}
          input={
            <OutlinedInput name={props.name} labelWidth={labelWidth} id="age-customized-select" />
          }>
          <MenuItem disabled value="">
            <em>Selecione uma ou mais opções. Clique fora para fechar.</em>
          </MenuItem>

          {props.options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Checkbox color="primary" checked={option.includes(item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {touched && error ? (
          <FormHelperText style={{ color: '#FF5A52' }}>{touched && error}</FormHelperText>
        ) : (
          <FormHelperText>Você pode selecionar mais de uma opção</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

SelectMultiple.propTypes = {
  disabled: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  label: PropTypes.any,
  margin: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  name: PropTypes.any,
  options: PropTypes.shape({
    filter: PropTypes.func,
    map: PropTypes.func,
  }),
  required: PropTypes.any,
};
