export default {
  PRODUCT_ORDERS_LOAD: 'PRODUCT_ORDERS_LOAD',
  PRODUCT_ORDERS_FETCHED: 'PRODUCT_ORDERS_FETCHED',
  PRODUCT_ORDERS_DETAIL: 'PRODUCT_ORDERS_DETAIL',
  PRODUCT_BUTTON_LOADING: 'PRODUCT_BUTTON_LOADING',
  PRODUCT_DETAIL_LOAD: 'PRODUCT_DETAIL_LOAD',
  PRODUCT_DETAIL_FETCHED: 'PRODUCT_DETAIL_FETCHED',
  PRODUCT_GROCERY_LIST_LOAD: 'PRODUCT_GROCERY_LIST_LOAD',
  PRODUCT_GROCERY_LIST_FETCHED: 'PRODUCT_GROCERY_LIST_FETCHED',
  DELIVERY_STATUSES_FETCHED: 'DELIVERY_STATUSES_FETCHED',
  DELIVERY_STATUSES_LOAD: 'DELIVERY_STATUSES_LOAD',
  PRODUCT_ORDERS_REFUND_LOAD: 'PRODUCT_ORDERS_REFUND_LOAD',
};
