import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Downloads from './downloads';
import DownloadsForm from './downloadsForm';

export default (
  <Route path="downloads">
    <IndexRoute component={(props) => <Downloads {...props} />} />
    <Route path="novo" component={(props) => <DownloadsForm {...props} />} />
    <Route path=":id/:action" component={(props) => <DownloadsForm {...props} />} />
  </Route>
);
