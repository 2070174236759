import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './donationsActions';

const useStyles = makeStyles(styles);

const defaultFilters = [
  {
    label: 'Filtrar entre as datas',
    field: 'betweenDates',
    component: 'datePicker',
    typeData: 'date',
    filterFunction: 3,
    startDate: new Date(),
    endDate: new Date(),
  },
];

const Donations = function (props) {
  const classes = useStyles();
  const { list, loading } = props.donations;

  const [columns] = useState([
    {
      title: 'Nome',
      field: 'user.name',
      render: (rowData) => rowData.user?.name,
    },
    { title: 'CPF', field: 'user.cpf', render: (rowData) => rowData.user?.cpf },
    {
      title: 'Atividade',
      field: 'action.name',
      render: (rowData) => rowData.action?.name,
    },
    {
      title: 'Valor',
      render: (rowData) => {
        return 'R$' + parseFloat(rowData.value).toFixed(2).replace('.', ',');
      },
    },
    {
      title: 'Data da doação',
      field: 'createdAt',
      type: 'datetime',
      render: (rowData) => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm'),
    },
  ]);
  const [data, setData] = useState([]);
  const filters = defaultFilters;

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(props.donations.list.content);
  }, [props.donations.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não possui doações!</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <>
      <section id="donations">
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">accessibility</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Doações</p>
                <h3 className={classes.cardTitle}>{props.donations.list.content.length}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">dashboard</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Atividades de doação</p>
                <h3 className={classes.cardTitle}> {props.donations.list.donationActions.count}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Saldo disponível</p>
                <h3 className={classes.cardTitle}>
                  R${' '}
                  {parseFloat(props.donations.list.establishmentData.avaliable_marketplace_points)
                    .toFixed(2)
                    .replace('.', ',')}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">money_off</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Saldo a ser liberado</p>
                <h3 className={classes.cardTitle}>
                  R${' '}
                  {parseFloat(props.donations.list.establishmentData.unavaliable_marketplace_points)
                    .toFixed(2)
                    .replace('.', ',')}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12} style={{ width: isMobile ? '89vw' : undefined }}>
            <Table
              filters={filters}
              searchPlaceholder={'Pesquisar por nome'}
              hasToolbar={true}
              title="Doações"
              dataType={'donations'}
              columns={columns}
              rows={data}
              toolbarSearch
              mobileTable={isMobile}
              hasBorder={!isMobile}
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    </>
  );
};

Donations.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  donations: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
      donationActions: PropTypes.shape({
        count: PropTypes.any,
      }),
      establishmentData: PropTypes.shape({
        avaliable_marketplace_points: PropTypes.any,
        unavaliable_marketplace_points: PropTypes.any,
      }),
    }),
    loading: PropTypes.any,
  }),
  getList: PropTypes.func,
  router: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    donations: state.donations,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Donations);
