import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { Typography, Box } from '@material-ui/core';
import CardActionInfo from 'common/components/cards/cardActionInfo/cardActionInfo';
import moment from 'moment';
import {
  getStationActiveActions,
  onClickActionResults,
  onClickActionShare,
  onClickAction,
  suspendActivity,
} from './activeActionsScripts';

function ActiveActions(props) {
  const { auth } = props;
  const content = props.activeActions.list;

  useEffect(() => {
    props.getStationActiveActions(auth.user.establishments[0].stations[0].id);
  }, []);

  function activityStatus(activity) {
    if (!activity.active) return ['OFFLINE', '#707070'];
    if (moment(new Date()).isBetween(activity.participationStart, activity.end))
      return ['ONLINE', '#169f53'];
    if (moment(new Date()).isBetween(activity.start, activity.participationStart))
      return ['VISÍVEL', '#de7b12'];

    return ['OFFLINE', '#707070'];
  }

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Atividades Aprovadas</h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton onClick={() => props.router.push('/owner/acoes')} color="primary">
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.activeActions.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item sm={12} style={{ padding: '0 2em' }}>
          <GeneralGrid
            container
            spacing={3}
            justify={'space-between'}
            style={{ padding: '2.6em 0 2.6em 0' }}>
            <GeneralGrid item sm={8} md={6} lg={4} style={{ padding: '12px 12px 12px 5px' }}>
              <Box height="100%" display="flex" justifyContent="flex-start" alignItems="center">
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                    textAlign: 'left',
                    textJustify: 'center',
                    backgroundColor: '#f7f7f7',
                  }}>
                  Estas são as atividades Aprovadas em sua estação.
                </Typography>
              </Box>
            </GeneralGrid>
            <GeneralGrid item sm={4} md={6} lg={8} style={{ padding: '12px 0 12px 12px' }}>
              <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
                <PrimaryButton
                  style={{
                    height: '4.3em',
                    width: '14em',
                    margin: '8px 0 8px 8px',
                  }}
                  onClick={() => props.router.push('/owner/acoes')}>
                  NOVA ATIVIDADE
                </PrimaryButton>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          {content.map((item, index) => (
            <CardActionInfo
              edit
              key={item.id}
              index={item.id}
              data={item}
              summary={item.summary}
              hasResults
              condition={activityStatus(item)}
              onClickEdit={() => props.onClickAction(item, props.router)}
              onClickResults={() => props.onClickActionResults(item, props.router, 'ativas')}
              onClickSuspend={() => props.suspendActivity(item.id, props.router)}
              onClickShare={() => props.onClickActionShare(item, props.router)}
            />
          ))}
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ActiveActions.propTypes = {
  activeActions: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getStationActiveActions: PropTypes.func,
  onClickAction: PropTypes.func,
  onClickActionResults: PropTypes.func,
  onClickActionShare: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  suspendActivity: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeActions: state.activeActions,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStationActiveActions,
      onClickActionResults,
      onClickActionShare,
      onClickAction,
      suspendActivity,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveActions);
