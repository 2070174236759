import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import CallToActionCard from '../cards/moduleCard/callToActionCard';
import { DomPurifyContentContainer } from './styles';

function ActivityMemberGetMember(props) {
  return (
    <Grid item container alignItems="center" xs={12}>
      {/* DOM PURIFY CONTENT */}
      <Grid item container alignItems="center" xs={12}>
        <DomPurifyContentContainer
          className={props.className}
          dangerouslySetInnerHTML={{
            __html: props.formValues.description,
          }}
        />
      </Grid>
      <Grid item container justify="center" xs={12}>
        <CallToActionCard formValues={props.formValues} />
      </Grid>
    </Grid>
  );
}

ActivityMemberGetMember.propTypes = {
  className: PropTypes.any,
  formValues: PropTypes.shape({
    description: PropTypes.any,
  }),
};
const StyledActivitySurvey = styled(ActivityMemberGetMember)`
  p {
    font-size: 16px;
  }
`;

export default StyledActivitySurvey;
