import { getListTransform, getDetailTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  count: 0,
  detail: {},
  status: 'idle',
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LIST_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data.content) || INITIAL_STATE.list,
        count: action.payload.data.count,
      };
    case types.ITEM_DETAIL:
      return {
        ...state,
        detail: getDetailTransform(action.payload) || INITIAL_STATE.detail,
      };
    case types.STATUS_UPDATE:
      return {
        ...state,
        status: action.payload || INITIAL_STATE.status,
      };
    case types.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
