import types from './type';

const INITIAL_STATE = {
  loading: false,
  list: [],
  loadingDetail: false,
  item: {},
  groceryList: [],
  loadingList: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DELIVERY_ORDERS_LOAD:
      return { ...state, loading: action.payload };
    case types.DELIVERY_ORDERS_FETCHED:
      return { ...state, loading: false, list: action.payload };
    case types.DELIVERY_BUTTON_LOADING:
      return { ...state, loading: action.payload };
    case types.DELIVERY_DETAIL_LOAD:
      return { ...state, loadingDetail: action.payload };
    case types.DELIVERY_DETAIL_FETCHED:
      return { ...state, loadingDetail: false, item: action.payload };
    case types.DELIVERY_GROCERY_LIST_LOAD:
      return { ...state, loadingList: action.payload };
    case types.DELIVERY_GROCERY_LIST_FETCHED:
      return { ...state, loadingList: false, groceryList: action.payload };
    default:
      return state;
  }
};
