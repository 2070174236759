import styled from 'styled-components';

const EmptyProducts = styled.div`
  height: 95.36px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  background: #e5e5e5;
  border-radius: 6.65327px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.277219px;
    color: #323232;
  }
`;

export default EmptyProducts;
