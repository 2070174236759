import styled from 'styled-components';
import { darken } from 'polished';

export const VideosContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const VideoButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  background: #fff;
  color: ${(props) => (props.disabled ? '#E0E0E0' : props.selected ? '#62B0F3' : '#000')};
  border-radius: 6px;
  font-family: Roboto;
  border: ${(props) =>
    props.disabled
      ? '1px solid #E0E0E0'
      : props.selected
      ? '1px solid #62B0F3'
      : '1px solid lightgray'};

  &:hover {
    background: ${darken(0.03, '#fff')};
  }
`;
