import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Calendar from 'common/components/calendar/calendar';
import { Box } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import AddButton from 'common/components/buttons/addButton';
import { getList, remove } from './schedulesActions';

export function Schedules(props) {
  const { schedules, router } = props;

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].stations[0].id);
  }

  return props.schedules.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={7}>
      <LoadingProgress />
    </Box>
  ) : (
    <>
      <Box justifyContent="center" mt={10}>
        <Calendar
          schedules={schedules}
          weekends={true}
          handleDelete={handleDelete}
          router={router}
        />
      </Box>
      <AddButton
        style={{ zIndex: 1000 }}
        onClick={() => props.router.push('/owner/calendar/novo')}
      />
    </>
  );
}

Schedules.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getList: PropTypes.func,
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  schedules: PropTypes.shape({
    loading: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    schedules: state.schedules,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
