/* eslint-disable no-useless-constructor */
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialGrid from '@material-ui/core/Grid';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import DefaultCard from '../cards/DefaultCard';
import Loading from '../progress/loading';
import GeneralTabs from '../navigation/actionsTab/tabsActivity';
import Interaction from '../interaction';
import Activities from '../activities/activity';

class DialogAction extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidUpdate(props) {
  //   // if (props.value !== this.props.value) {
  //   //   this.dialogContent.scrollIntoView({
  //   //     block: 'start',
  //   //   });
  //   // }
  // }

  handleClose = () => {
    this.props.onClose();
  };

  renderActivities() {
    return <Activities formValues={this.props.form} />;
  }

  renderInteraction() {
    return <Interaction formValues={this.props.form} />;
  }

  renderCard() {
    return (
      <>
        <DefaultCard
          actionName={this.props.form.name}
          imgCloudSource={this.props.form.image}
          labelClick={this.props.form.click_me_label || 'Participe'}
        />
      </>
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.visible}
          keepMounted
          PaperProps={{
            style: { minHeight: this.props.height || null },
          }}
          onClose={this.props.onClose}
          fullWidth
          fullScreen={isMobile}
          maxWidth="xl"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogContent fullWidth style={{ overflow: 'hidden', padding: 0 }}>
            <div
              style={{
                background: '#323232',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <span
                style={{
                  paddingLeft: 30,
                  paddingTop: isMobile ? 10 : 20,
                  paddingBottom: isMobile ? 10 : 20,
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '20px' : '32px',
                  lineHeight: isMobile ? '20px' : '38px',
                  letterSpacing: '0.25px',
                  color: '#FFFFFF',
                }}>
                Gerenciador de atividades
              </span>
              <Box>
                <IconButton
                  onClick={() => {
                    this.handleClose();
                  }}>
                  <CloseIcon
                    style={{
                      fontSize: 30,
                      color: '#FFFFFF',
                      marginRight: isMobile ? 0 : 20,
                    }}
                  />
                </IconButton>
              </Box>
            </div>
            <div
              ref={(node) => {
                this.dialogContent = node;
              }}>
              <DialogContent
                style={{
                  background: 'white',
                  padding: 0,
                  overflow: 'hidden',
                }}>
                <MaterialGrid container justify="space-between">
                  <MaterialGrid
                    style={{
                      padding: 0,
                    }}
                    item
                    xs={isMobile ? 12 : 5}>
                    {this.props.children}
                  </MaterialGrid>
                  {isMobile ? null : (
                    <MaterialGrid
                      item
                      xs={7}
                      style={{
                        borderLeft: '3px solid #f7f7f7',
                        backgroundColor: 'white',
                      }}>
                      {this.props.form ? (
                        <>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            style={{
                              padding: '25px',
                              paddingLeft: '20px',
                            }}>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '29px',
                                letterSpacing: '0.25px',
                                color: '#000000',
                                paddingLeft: 20,
                              }}>
                              Preview
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              width="100%"
                              style={{
                                paddingRight: 10,
                                height: 35,
                              }}>
                              {this.props.activeActions.buttonLoading ? (
                                <CircularProgress size={30} />
                              ) : null}
                            </Box>
                          </Box>
                          <GeneralTabs
                            formValues={this.props.form}
                            typeAction={this.props.form.type_action_id}
                            activity={this.renderActivities()}
                            card={this.renderCard()}
                            // step={1}
                            step={this.props.value}
                            interaction={this.renderInteraction()}
                          />
                        </>
                      ) : (
                        <Loading />
                      )}
                    </MaterialGrid>
                  )}
                </MaterialGrid>
              </DialogContent>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogAction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  children: PropTypes.any,
  form: PropTypes.shape({
    click_me_label: PropTypes.string,
    image: PropTypes.any,
    name: PropTypes.any,
    type_action_id: PropTypes.any,
  }),
  height: PropTypes.any,
  onClose: PropTypes.func,
  value: PropTypes.any,
  visible: PropTypes.any,
};
const mapStateToProps = (state, { form }) => {
  return {
    activeActions: state.activeActions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAction);
