import PropTypes from 'prop-types';
import React from 'react';

export default function SimpleButton(props) {
  const { background, text, border } = props;
  return (
    <button
      type="button"
      className={`py-2 px-4 station-font-medium ${props.className}`}
      style={{
        background,
        color: text,
        border,
        fontSize: '0.875rem',
        height: props.height,
        width: props.width,
      }}
      {...props}>
      {props.children}
    </button>
  );
}

SimpleButton.propTypes = {
  background: PropTypes.any,
  border: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
  height: PropTypes.any,
  text: PropTypes.any,
  width: PropTypes.any,
};
