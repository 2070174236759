const validate = function (cpf) {
  let sum = 0;
  let remainder;

  cpf = cpf.replace('.', '').replace('.', '').replace('-', '').trim();

  let allEqual = true;
  for (let i = 0; i < cpf.length - 1; i++) {
    if (cpf[i] !== cpf[i + 1]) allEqual = false;
  }
  if (allEqual) return false;

  for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export default validate;
