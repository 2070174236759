import { getListTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  listTemplates: {
    content: [],
  },
  listPartners: {
    content: [],
  },
  listSubscriptions: {
    content: [],
  },
  detail: {},
  loading: false,
  loadingTemplates: false,
  loadingPartners: false,
  loadingSubscriptions: false,
  buttonLoading: false,
  status: 'idle',
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PARTNERS_FETCHED':
      return {
        ...state,
        listPartners: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loadingPartners: false,
      };
    case 'PARTNERS_LOAD':
      return {
        ...state,
        loadingPartners: action.payload,
      };
    case 'SUBSCRIPTIONS_FETCHED':
      return {
        ...state,
        listSubscriptions: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loadingSubscriptions: false,
      };
    case 'SUBSCRIPTIONS_LOAD':
      return {
        ...state,
        loadingSubscriptions: action.payload,
      };
    case types.PRODUCT_DELIVERIES_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.PRODUCT_DELIVERIES_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
        status: 'resolved',
      };
    case types.PRODUCT_DELIVERIES_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case types.PRODUCT_DELIVERIES_TEMPLATES_FETCHED:
      return {
        ...state,
        listTemplates: getListTransform(action.payload.data) || INITIAL_STATE.listTemplates,
        loadingTemplates: false,
      };
    case types.PRODUCT_DELIVERIES_TEMPLATES_LOAD:
      return {
        ...state,
        loadingTemplates: action.payload,
      };
    case types.PRODUCT_DELIVERIES_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };

    default:
      return state;
  }
};
