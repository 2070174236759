import generalReducer from './generalReducer';
import actionOffersReducer from './pages/actions/actionOffers/actionOffersReducer';
import actionReducers from './pages/actions/actionReducers';
import actionsDraftsReducer from './pages/actions/actionsDrafts/actionsDraftsReducer';
import actionsHistoryReducer from './pages/actions/actionsHistory/actionsHistoryReducer';
import activeActionsReducer from './pages/actions/activeActions/activeActionsReducer';
import agreementReducer from './pages/actions/agreement/agreementReducer';
import couponsReducer from './pages/actions/coupons/couponsReducers';
import couponCodesReducer from './pages/actions/couponCodes/couponCodesReducer';
import giveawaysReducer from './pages/actions/giveaways/giveawaysReducer';
import memberGetMemberReducer from './pages/actions/memberGetMember/memberGetMemberReducer';
import presentialCourseReducers from './pages/actions/presentialCourse/presentialCoursesReducers';
import productDeliveryReducers from './pages/actions/productDelivery/productDeliveryReducers';
import rawContentReducer from './pages/actions/rawContent/rawContentReducer';
import redirectLinkReducer from './pages/actions/redirectLink/redirectLinkReducer';
import redirectLinkInternalReducer from './pages/actions/redirectLinkInternal/redirectLinkInternalReducer';
import contactsSurveysReducer from './pages/actions/research/researchReducers';
import surveysCheckListReducer from './pages/actions/researchCheckList/researchCheckListReducers';
import researchPersonalityQuizReducers from './pages/actions/researchPersonalityQuiz/researchPersonalityQuizReducers';
import researchPollQuizReducers from './pages/actions/researchPollQuiz/researchPollQuizReducers';
import surveysRightAnswerReducer from './pages/actions/researchRightAnswer/researchRightAnswerReducers';
import shareContentReducer from './pages/actions/shareContent/shareContentReducer';
import signupEarnReducer from './pages/actions/signupEarn/signupEarnReducer';
import addressesReducer from './pages/addresses/addressesReducer';
import categoriesReducer from './pages/categories/categoriesReducer';
import contactsReducers from './pages/contacts/contactsReducers';
import aboutReducer from './pages/content/about/aboutReducer';
import benefitsReducer from './pages/content/benefits/benefitsReducer';
import downloadsReducer from './pages/content/downloads/downloadsReducer';
import newsReducer from './pages/content/news/newsReducer';
import schedulesReducer from './pages/content/schedules/schedulesReducer';
import dashboardOwnerReducer from './pages/dashboard/dashboardOwnerReducer';
import deliveryOrdersReducer from './pages/deliveryOrders/deliveryOrdersReducer';
import lottiesReducers from './pages/lotties/lottiesReducers';
import ownerUsersReducers from './pages/ownerUsers/ownerUsersReducers';
import buyActionsReducers from './pages/points/pages/buyActions/buyActionsReducers';
import statementBuyPointsReducers from './pages/points/pages/statementBuyPoints/statementBuyPointsReducers';
import transferRequestsReducers from './pages/points/pages/transferRequests/transferRequestsReducers';
import userEarnedPointsReducers from './pages/points/pages/userEarnedPoints/userEarnedPointsReducers';
import pointsReducers from './pages/points/pointsReducers';
import principalReducer from './pages/principal/principalReducer';
import profileReducer from './pages/profile/profileReducer';
import questionsReducer from './pages/questions/questionsReducer';
import sectionsReducer from './pages/sections/sectionsReducer';
import signupCompanyReducer from './pages/signupCompany/signupCompanyReducer';
import websiteReducers from './pages/website/websiteReducers';
import surveysReducer from './surveys/surveysReducer';
import supportReducer from './pages/support/supportReducer';
import journeysReducer from './pages/journeys/journeysReducer';
import subscriptionsReducer from './pages/subscriptions/subscriptionsReducer';
import donationsReducer from './pages/donations/donationsReducer';
import validationsReducer from './pages/validations/validationsReducer';
import salesReducer from './pages/sales/salesReducer';
import productOrdersReducer from './pages/sales/productOrders/productOrdersReducer';
import validationsCupomReducer from './pages/validationsCupom/validationsCupomReducer';
import automationsReducer from './pages/automations/automationsReducer';
import welcomeMailReducer from './pages/automations/automationTypes/1_welcome_email/welcomeMailReducer';
import donationActionReducer from './pages/actions/donationAction/donationActionReducer';
import userAnswersReducer from './pages/actions/userAnswers/userAnswersReducer';
import actionOfferMessagesReducer from './pages/actions/actionOfferMessages/actionOfferMessagesReducer';
import moderatorReducer from './pages/moderator/moderatorReducer';
import ownersReducer from './pages/owners/ownersReducer';
import participantsReducer from './pages/participants/participantsReducer';
import createNewStationReducer from './pages/createNewStation/createNewStationReducer';
import websiteSectionsReducer from './pages/content/websiteSections/websiteSectionsReducer';
import plansReducer from './pages/plans/plansReducer';
import videoPlaylistReducer from './pages/actions/actionVideoPlaylist/videoPlaylistReducer';
import subdomainReducer from './pages/content/subdomain/subdomainReducer';
import entitiesReducer from './pages/actions/entities/entitiesReducer';
import validatorsReducer from './pages/actions/validators/validatorsReducer';
import productsReportReducer from './pages/productsReport/productsReportReducer';
import groupingsReducer from './pages/actions/groupings/groupingsReducer';

export default {
  addresses: addressesReducer,
  agreement: agreementReducer,
  about: aboutReducer,
  actions: actionReducers,
  actionOfferMessages: actionOfferMessagesReducer,
  activeActions: activeActionsReducer,
  automations: automationsReducer,
  actionsDrafts: actionsDraftsReducer,
  actionsHistory: actionsHistoryReducer,
  actionOffers: actionOffersReducer,
  benefits: benefitsReducer,
  categories: categoriesReducer,
  contacts: contactsReducers,
  coupons: couponsReducer,
  couponCodes: couponCodesReducer,
  groupings: groupingsReducer,
  contactsSurveys: contactsSurveysReducer,
  createNewStation: createNewStationReducer,
  dashboardOwner: dashboardOwnerReducer,
  deliveryOrders: deliveryOrdersReducer,
  donationAction: donationActionReducer,
  donations: donationsReducer,
  downloads: downloadsReducer,
  entities: entitiesReducer,
  general: generalReducer,
  giveaways: giveawaysReducer,
  journeys: journeysReducer,
  lotties: lottiesReducers,
  memberGetMember: memberGetMemberReducer,
  moderator: moderatorReducer,
  news: newsReducer,
  ownerUsers: ownerUsersReducers,
  owners: ownersReducer,
  participants: participantsReducer,
  plans: plansReducer,
  presentialCourses: presentialCourseReducers,
  points: pointsReducers,
  profile: profileReducer,
  productOrders: productOrdersReducer,
  productsReport: productsReportReducer,
  principal: principalReducer,
  productDelivery: productDeliveryReducers,
  questions: questionsReducer,
  rawContent: rawContentReducer,
  researchPollQuiz: researchPollQuizReducers,
  researchPersonalityQuiz: researchPersonalityQuizReducers,
  redirectLink: redirectLinkReducer,
  redirectLinkInternal: redirectLinkInternalReducer,
  shareContent: shareContentReducer,
  sections: sectionsReducer,
  statementBuyPoints: statementBuyPointsReducers,
  subdomain: subdomainReducer,
  surveys: surveysReducer,
  surveysCheckList: surveysCheckListReducer,
  surveysRightAnswer: surveysRightAnswerReducer,
  signupCompany: signupCompanyReducer,
  schedules: schedulesReducer,
  signupAndEarn: signupEarnReducer,
  statementBuyActions: buyActionsReducers,
  sales: salesReducer,
  support: supportReducer,
  subscriptions: subscriptionsReducer,
  transferRequests: transferRequestsReducers,
  userEarnedPoints: userEarnedPointsReducers,
  userAnswers: userAnswersReducer,
  validationsCupom: validationsCupomReducer,
  validations: validationsReducer,
  website: websiteReducers,
  websiteSections: websiteSectionsReducer,
  welcomeMail: welcomeMailReducer,
  validatorCode: validatorsReducer,
  videoPlaylist: videoPlaylistReducer,
};
