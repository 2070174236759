import axios from 'axios';
import { reset as resetForm, initialize } from 'redux-form';
import { BASE_API, USER_TOKEN } from 'config/consts';
import types from './types';

const URL = `${BASE_API}/automations-station`;
const URL_DETAIL = `${BASE_API}/automations`;
const URL_CHANGE_ACTIVE = `${BASE_API}/change-automation-activation`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const changeActive = (id, other) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: false });
    axios
      .put(
        `${URL_CHANGE_ACTIVE}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          getList(other),
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.LIST_LOAD,
            payload: false,
          },
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL_DETAIL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data,
        };
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: response.data,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: types.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('supportForm', values);
};

export const reset = () => {
  return resetForm('supportForm');
};

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: false,
    });
  };
};

export const fileRejected = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 2MB',
        variant: 'error',
        open: true,
      },
    });
  };
};
