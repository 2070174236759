const INITIAL_STATE = {
  loading: false,
  list: [],
  loadingPage: false,
  action: {},
  templatesCategories: [],
  actionTypes: [],
  templatesList: [],
  templatesLoading: true,
  templatesDetailLoading: true,
  templatesListBought: [],
  templatesListBoughtLoading: false,
  templateDetail: {},
  templateBody: {},
  boughtTemplate: {
    action_id: 0,
    type_action_id: 0,
  },
  buttonLoading: false,
  reviewModal: false,
  transactionMade: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_ACTIONS_LOAD':
      return { ...state, loading: action.payload };
    case 'USER_ACTIONS_FETCHED':
      return { ...state, list: action.payload, loading: false };
    case 'ACTION_PAGE_LOAD':
      return { ...state, loadingPage: action.payload };
    case 'ACTION_PAGE_FETCHED':
      return { ...state, action: action.payload, loadingPage: false };
    case 'TEMPLATES_FETCHED':
      return {
        ...state,
        templatesList: action.payload,
        templatesLoading: false,
      };
    case 'TEMPLATES_LOAD':
      return { ...state, templatesLoading: action.payload };
    case 'ACTIONS_CATEGORY_FETCHED':
      return {
        ...state,
        actionTypes: action.payload,
      };
    case 'TEMPLATES_CATEGORY_FETCHED':
      return {
        ...state,
        templatesLoading: false,
        templatesCategories: action.payload,
      };
    case 'TEMPLATE_DETAIL':
      return {
        ...state,
        templateDetail: action.payload,
        templateBody: JSON.parse(action.payload.body),
        templatesDetailLoading: false,
      };
    case 'BUTTON_LOAD':
      return {
        ...state,
        buttonLoading: action.payload,
        templatesLoading: false,
      };
    case 'BOUGHT_TEMPLATE':
      return { ...state, boughtTemplate: action.payload };
    case 'REVIEW_MODAL':
      return { ...state, reviewModal: action.payload };
    case 'TEMPLATES_BOUGHT_FETCHED':
      return {
        ...state,
        templatesListBought: action.payload,
        templatesBoughtLoading: false,
      };
    case 'TEMPLATES_BOUGHT_LOAD':
      return { ...state, templatesBoughtLoading: action.payload };
    case 'TRANSACTION_MADE':
      return { ...state, transactionMade: action.payload };
    default:
      return state;
  }
};
