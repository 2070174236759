import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import React from 'react';
import { appColors } from 'styles/colors';
import Grid from '../layout/grid/grid';
import PropTypes from 'prop-types';

export class DatePickerComponent extends React.Component {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const pt = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
      ],
      dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
      monthNames: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
    };
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">{this.props.label}</label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className="p-col-12 p-md-12">
            <Calendar
              inputStyle={{
                border: `1px solid ${appColors.field.primary} `,
                fontFamily: 'Roboto',
                fontSize: 16,
                borderRadius: 0,
                fontWeight: 400,
                padding: '7px 10px',
              }}
              value={this.state.date}
              locale={pt}
              showIcon
              onChange={(e) => {
                this.props.input.onChange(moment(e.value).format('YYYY-MM-DD HH:mm:ss'));
                this.setState({ date: e.value });
              }}
              disabledDates={this.props.disabledDates}
              touchUI
              disabledDays={[0]}
              disabled={this.props.readOnly}
              onSelect={this.props.onSelect}
              dateFormat="dd/mm/yy"
              readonlyInput
              monthNavigator
              yearNavigator
              yearRange={`${moment().format('YYYY')}:${moment().add(2, 'year').format('YYYY')}`}
            />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

DatePickerComponent.propTypes = {
  cols: PropTypes.any,
  disabledDates: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.any,
  meta: PropTypes.any,
  name: PropTypes.any,
  onSelect: PropTypes.any,
  readOnly: PropTypes.any,
};
