import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import LoadingProgress from 'common/components/progress/loading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrimaryButton from 'common/components/buttons/primaryButton';
import TextareaAutosize from 'react-autosize-textarea';
import { getOrder } from './deliveryOrdersActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeliveryDetail(props) {
  useEffect(() => {
    props.getOrder(props.router.params.id);
  }, []);
  if (props.deliveryOrders.loadingDetail) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  const list = props.deliveryOrders.item.grocery_list
    ? JSON.parse(props.deliveryOrders.item.grocery_list)
    : null;

  function renderList() {
    if (list) {
      const array = [];
      for (const i in list) {
        if (parseInt(list[`${i}`]) > 0) {
          array.push(
            <tr
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <td style={{ marginLeft: 30 }}>
                <span>{list[`${i}`]}</span>
              </td>
              <td>
                <Typography>{i.replace(/_/g, ' ')}</Typography>
              </td>
            </tr>
          );
        }
      }
      return (
        <>
          <table style={{ width: '100%' }}>
            <tr
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}>
              <th>Quantidade</th>
              <th style={{ marginRight: 10 }}>Produto</th>
            </tr>
            {array}
          </table>
          <Typography variant="subtitle2" style={{ marginBottom: 3, marginTop: 20 }}>
            Observação
          </Typography>
          <TextareaAutosize
            style={{
              outline: 'none',
              border: 'none',
              resize: 'none',
              width: '100%',
              height: '150px',
              fontFamily: 'Roboto',
            }}
            spellcheck="false"
            value={props.deliveryOrders.item.description}
          />
        </>
      );
    } else {
      return (
        <TextareaAutosize
          style={{
            outline: 'none',
            border: 'none',
            resize: 'none',
            width: '100%',
            height: '150px',
            fontFamily: 'Roboto',
          }}
          spellcheck="false"
          value={props.deliveryOrders.item.description}
        />
      );
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}
        onClose={() => props.router.push('/owner/delivery')}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <Box mt={4}>
            <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
              Pedido
            </Typography>
            {renderList()}
          </Box>

          <Box mt={4}>
            <Typography variant="subtitle2">Endereço</Typography>
            <Typography variant="body">{props.deliveryOrders.item.address}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle2">Referência</Typography>
            <Typography variant="body">{props.deliveryOrders.item.reference}</Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="subtitle2">Destinatário</Typography>
            <Typography variant="body">{props.deliveryOrders.item.name}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle2">Contato</Typography>
            <Typography variant="body">{props.deliveryOrders.item.phone}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle2">CPF</Typography>
            <Typography variant="body">{props.deliveryOrders.item.cpf}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle2">Estimativa de entrega</Typography>
            <Typography variant="body">
              {moment(props.deliveryOrders.item.delivery_date).format('DD/MM/YYYY')}
            </Typography>
          </Box>
          <Box mt={4} mb={4}>
            <Typography variant="subtitle2">Valor máximo da compra</Typography>
            <Typography variant="body">R$ {props.deliveryOrders.item.max_value}</Typography>
          </Box>
          <PrimaryButton onClick={() => window.print()}>Imprimir</PrimaryButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}

DeliveryDetail.propTypes = {
  deliveryOrders: PropTypes.shape({
    item: PropTypes.shape({
      address: PropTypes.any,
      cpf: PropTypes.any,
      delivery_date: PropTypes.any,
      description: PropTypes.any,
      grocery_list: PropTypes.any,
      max_value: PropTypes.any,
      name: PropTypes.any,
      phone: PropTypes.any,
      reference: PropTypes.any,
    }),
    loadingDetail: PropTypes.any,
  }),
  getOrder: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deliveryOrders: state.deliveryOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetail);
