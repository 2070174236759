import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import uploadReducer from 'common/components/upload/UploadFiles/uploadReducer';
import authReducer from '../pages/auth/authReducer';
import ownerReducers from '../modules/owner/ownerReducers';

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  toastr: toastrReducer,
  upload: uploadReducer,

  // Reducers from Owners module
  ...ownerReducers,
});

export default rootReducer;
