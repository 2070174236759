import React from 'react';
import { Route, IndexRoute } from 'react-router';
import DeliveryOrders from './deliveryOrders';
import DeliveryDetail from './deliveryDetail';
import DeliveryReceipt from './deliveryReceipt';

export default (
  <Route path="delivery">
    <IndexRoute component={(props) => <DeliveryOrders {...props} />} />
    <Route path="detalhe/:id" component={(props) => <DeliveryDetail {...props} />} />
    <Route path="receipt/:id" component={(props) => <DeliveryReceipt {...props} />} />
  </Route>
);
