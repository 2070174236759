import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Validations from './validationsCupom';
import ValidationsInfo from './validationsCupomInfo';

export default (
  <Route path="validacao-cupom">
    <IndexRoute component={(props) => <Validations {...props} />} />
    <Route path=":code" component={(props) => <ValidationsInfo {...props} />} />
  </Route>
);
