import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import React, { useState, useEffect } from 'react';
import { Field, reduxForm, getFormValues, Form } from 'redux-form';
import AlertDialogSlide from 'common/components/dialog/dialogActivits';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { connect } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { reactivateAction } from './actionsHistoryActions';

let ReactivateAction = function (props) {
  const [limit, setLimit] = useState(0);
  const [interactions, setInteractions] = useState(0);
  const [name, setName] = useState('');

  const onlyNumbers = (values) => values && values.replace(/[^\d]/g, '');

  useEffect(() => {
    if (props.actionData) {
      setLimit(props.actionData.limit);
      setInteractions(props.actionData?.userActions?.length || 0);
      setName(props.actionData.name);
    }
  }, [props.actionData]);

  function onSubmit(values) {
    props.reactivateAction(props.actionData.id, values, props.router, props.onClose);
  }

  return (
    <AlertDialogSlide
      test={true}
      height="95vh"
      form={props.actionData}
      maxWidth={'sm'}
      visible={props.visible}
      onClose={props.onClose}
      title={'Reativação de atividade'}>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            marginTop: '20px',
            overflowY: 'scroll',
            height: 'calc(100vh - 240px)',
            overflowX: 'hidden',
            paddingLeft: 15,
            marginRight: 22,
            marginLeft: 22,
            paddingTop: 10,
          }}>
          <MaterialGrid container direction="column" spacing={3} justify="flex-start">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
                Atividade:
              </Typography>
              <Typography variant="subtitle1">{`${name}`}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
                Número de participações:
              </Typography>
              <Typography variant="subtitle1">{`${interactions}`}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
                Limite de participações anterior:
              </Typography>
              <Typography variant="subtitle1">{`${limit}`}</Typography>
            </Box>
            <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
              Novas regras de publicação
            </Typography>
            <MaterialGrid item xs={10}>
              <Field
                component={InputLabelOutlined}
                label="Data de publicação da atividade"
                name="start"
                type="datetime-local"
                validate={[FORM_RULES.required]}
                required
              />
            </MaterialGrid>
            {/* <Typography variant="subtitle1" style={{ padding: 12 }}>
            Selecione a nova data de início da atividade:
          </Typography> */}
            <MaterialGrid item xs={10}>
              <Field
                component={InputLabelOutlined}
                label="Data de início da atividade"
                name="participationStart"
                type="datetime-local"
                validate={[FORM_RULES.required]}
                required
              />
            </MaterialGrid>
            {/* <Typography variant="subtitle1" style={{ padding: 12 }}>
            Selecione a nova data de expiração da atividade:
          </Typography> */}
            <MaterialGrid item xs={10}>
              <Field
                component={InputLabelOutlined}
                label="Data de expiração da atividade"
                name="end"
                type="datetime-local"
                validate={[FORM_RULES.required]}
                required
              />
            </MaterialGrid>
            <Typography variant="subtitle1" style={{ padding: 12, fontWeight: 600 }}>
              Digite o número de interações que deseja adicionar a atividade:
            </Typography>
            <MaterialGrid item xs={10}>
              <Field
                component={InputLabelOutlined}
                label="Quantidade adicional de participações"
                name="additional_limit"
                validate={[FORM_RULES.required]}
                normalize={onlyNumbers}
                required
              />
            </MaterialGrid>
          </MaterialGrid>
        </div>
        <MaterialGrid
          container
          display="flex"
          justify="flex-start"
          alignItems="center"
          style={{
            marginTop: '25px',
            overflowX: 'hidden',
          }}>
          <SecondaryButton
            onClick={props.onClose}
            style={{
              marginLeft: 34,
              width: '40%',
              padding: '10px 20px',
              textTransform: 'none',
            }}
            color="primary">
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            color="primary"
            disabled={props.disabled}
            style={{
              margin: 0,
              marginLeft: 10,
              width: '40%',
              padding: '10px 20px',
              textTransform: 'none',
            }}
            progress={parseInt(props.general.submitProgress)}>
            Reativar
          </PrimaryButton>
        </MaterialGrid>
      </Form>
    </AlertDialogSlide>
  );
};

ReactivateAction.propTypes = {
  actionData: PropTypes.shape({
    id: PropTypes.any,
    limit: PropTypes.any,
    name: PropTypes.any,
    userActions: PropTypes.shape({
      length: PropTypes.any,
    }),
  }),
  disabled: PropTypes.any,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  onClose: PropTypes.any,
  reactivateAction: PropTypes.func,
  router: PropTypes.any,
  visible: PropTypes.any,
};

ReactivateAction = reduxForm({
  form: 'reactivateForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ReactivateAction);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    disabled: state.actionsHistory.buttonLoading,
    general: state.general,
    formValues: getFormValues('reactivateForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ reactivateAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactivateAction);
