import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  button {
    padding: 5px 10px;
    background: #2d95ef;
    cursor: pointer;
    border-radius: 6px;
    margin-left: 30px;
    color: #fff;
  }
`;

export default Container;
