import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import PrimaryButton from '../../buttons/primaryButton';
import './style.css';
import { toastr } from 'react-redux-toastr';
import { sendPostVideo, dispatchLoading } from './uploadActions';

const InputVideoFile = function (props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loaded] = useState(0);

  function checkFileType(selectedFile) {
    const file = selectedFile;
    const fileType = file.path.slice(-4, file.path.length);
    const types = ['.mov', '.mp4', '.wmw'];
    if (types.every((type) => fileType !== type)) {
      console.log('Formato não suportado!');
      toastr.error('Erro!', 'Formato de vídeo não suportado.');
      return false;
    }
    return true;
  }

  function checkFileSize(selectedFile) {
    const file = selectedFile;
    const size = 200000000;
    if (file.size > size) {
      console.log('Escolha um arquivo de tamanho menor!');
      toastr.error('Erro!', 'Tamanho de vídeo não suportado!');
      return false;
    }
    return true;
  }

  function onDropHandler(acceptedFiles) {
    const file = acceptedFiles[0];

    if (checkFileSize(file) && checkFileType(file)) {
      setSelectedFile(acceptedFiles[0]);
    }
  }

  function onClickHandler() {
    if (selectedFile !== null) {
      props.dispatchLoading(true);
      props.sendPostVideo(
        selectedFile,
        setSelectedFile,
        props.establishment_id,
        props.handleChangeTab
      );
    }
  }

  return (
    <>
      <div
        className="container"
        style={{
          height: 300,
          maxWidth: '80%',
          borderWidth: 1,
          borderColor: selectedFile ? '#62B0F3' : 'lightgrey',
          borderStyle: 'dashed',
          borderRadius: 5,
          margin: 'auto',
          cursor: 'pointer',
          backgroundColor: '#F7F7F7',
          marginTop: 10,
        }}>
        <Dropzone multiple={false} onDrop={onDropHandler}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="d-flex flex-column justify-content-center "
              style={{ height: '100%', alignItems: 'center' }}>
              <input {...getInputProps()} />
              {selectedFile ? (
                <div style={{ textAlign: 'center' }}>
                  <p style={{ color: '#62B0F3' }}>Vídeo Selecionado!</p>
                  <p style={{ color: '#62B0F3' }}>{selectedFile.name}</p>
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <p>Clique aqui ou arraste um vídeo!</p>
                  <p style={{ color: 'grey' }}>
                    (Tamanho máximo: 200MB, Formatos permitidos: .mov, .mp4, .wmv)
                  </p>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      <div style={{ width: '80%', marginTop: 5, textAlign: 'center' }}>
        <p style={{ color: 'grey' }}>
          O seu vídeo pode demorar até 15 minutos para estar disponível depois de feito o upload.
        </p>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          marginTop: 10,
          width: '80%',
        }}>
        <PrimaryButton
          type="button"
          color="primary"
          disabled={props.loading}
          onClick={onClickHandler}
          progress={parseInt(loaded)}
          noMargin>
          {props.upload.uploadProgress}
          {props.loading ? ' Carregando...' : ' Upload'}
        </PrimaryButton>
      </div>
    </>
  );
};

InputVideoFile.propTypes = {
  dispatchLoading: PropTypes.func,
  establishment_id: PropTypes.any,
  handleChangeTab: PropTypes.any,
  loading: PropTypes.any,
  sendPostVideo: PropTypes.func,
  upload: PropTypes.shape({
    uploadProgress: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loading: state.upload.loading,
    upload: state.upload,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ dispatchLoading, sendPostVideo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InputVideoFile);
