import PropTypes from 'prop-types';
import React from 'react';
import { Question, TextImage } from './styles';

export default function Questions({ question }) {
  return (
    <>
      <Question background={question.color} textColor={question.textColor} image={question.base64}>
        {!question.base64 ? <p>{question.text}</p> : null}
      </Question>
      {question.base64 ? (
        <TextImage
          disabled
          textColor={question.textColor === '#FFF' ? '#000' : question.textColor}
          value={question.text}>
          <p>{question.text}</p>
        </TextImage>
      ) : null}
    </>
  );
}

Questions.propTypes = {
  question: PropTypes.shape({
    base64: PropTypes.any,
    color: PropTypes.any,
    text: PropTypes.any,
    textColor: PropTypes.string,
  }),
};
