import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { InputImageWithProcessing } from 'common/components/fields/InputImageWithProcessing';
import { InputSwitch } from 'common/components/fields/InputSwitch';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import LoadingProgress from 'common/components/progress/loading';
import { cepMask, currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, initialize, reduxForm } from 'redux-form';
import { getList as getCategoriesList } from '../../../categories/categoriesActions';

const changeFieldValue = (form, value) => {
  return (dispatch) => {
    dispatch(initialize(form, value));
  };
};

const renderproducts = (props) => {
  function array_move(fromIndex, toIndex) {
    const element = props.formValues?.products[fromIndex];
    props.fields.splice(fromIndex, 1);
    props.fields.splice(toIndex, 0, element);
  }
  function goUp(index) {
    return array_move(index, index - 1);
  }
  function goDown(index) {
    return array_move(index, index + 1);
  }
  return (
    <>
      {props.fields.map((product, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            borderTop: '2px solid #E5E5E5',
            paddingTop: 5,
          }}>
          <Box display="flex" flexDirection="column" style={{ marginRight: 20, zIndex: 100 }}>
            {index !== 0 && props.fields.length > 1 ? (
              <ArrowDropUp style={{ cursor: 'pointer' }} onClick={() => goUp(index)} />
            ) : null}
            {index !== props.fields.length - 1 && props.fields.length > 1 ? (
              <ArrowDropDown style={{ cursor: 'pointer' }} onClick={() => goDown(index)} />
            ) : null}
          </Box>
          <ExpansionPanel
            key={index}
            footer={null}
            label={props.formValues?.products[index]?.name || `Produto ${index + 1}`}
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#fff',
            }}>
            <MaterialGrid item xs={12}>
              <Box display="flex" justifyContent="space-between" style={{ marginBottom: 10 }}>
                <Typography
                  style={{
                    fontSize: 16,
                  }}>
                  Nome do produto
                </Typography>
                <Typography
                  style={{
                    cursor: 'pointer',
                    color: '#E34848',
                    fontSize: 16,
                  }}
                  onClick={() => props.fields.remove(index)}>
                  Excluir Produto
                </Typography>
              </Box>
              <Field
                component={InputLabelOutlined}
                label={`Nome do Produto ${index + 1}`}
                name={`${product}.name`}
                validate={[FORM_RULES.required]}
                required
                helperText="Indique o seu produto, marca e modelo. Evite usar condições de vendas."
                disabled={props.router.params.action === 'proposta'}
              />
            </MaterialGrid>
            <MaterialGrid container spacing={2} style={{ background: '#fff' }}>
              <Typography
                style={{
                  fontSize: 16,
                  display: 'block',
                  fontWeight: 500,
                  width: '100%',
                  margin: '10px 0',
                  paddingLeft: 10,
                }}>
                Imagem
              </Typography>

              <MaterialGrid item xs={12} style={{ maring: '15px 0' }}>
                <Field
                  squareOff
                  component={InputImageWithProcessing}
                  label={'Imagem .png ou .jpg'}
                  cols="12 12 12 12"
                  name={`${product}.image`}
                  aspect={16 / 9}
                  aspectLabel={'16:9'}
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputLabelOutlined}
                  multiline
                  label={`Descrição do produto ${index + 1}`}
                  name={`${product}.description`}
                  validate={[
                    FORM_RULES.required,
                    FORM_RULES.max(
                      1000,
                      props.formValues.products[index]
                        ? props.formValues.products[index].description
                          ? props.formValues.products[index].description.length
                          : 0
                        : 0
                    ),
                  ]}
                  required
                  rows={3}
                  disabled={props.router.params.action === 'proposta'}
                  helperText={
                    props.formValues.products[index] && props.formValues.products[index].description
                      ? `Quantidade de caracteres: ${props.formValues.products[index].description.length}/1000`
                      : 'Indique o seu produto, marca e modelo. Evite usar condições de vendas.'
                  }
                />
              </MaterialGrid>
              <Typography
                style={{
                  fontSize: 16,
                  display: 'block',
                  fontWeight: 500,
                  width: '100%',
                  margin: '10px 0',
                  paddingLeft: 10,
                }}>
                Preços e estoque
              </Typography>
              <MaterialGrid item xs={4}>
                <Field
                  component={InputLabelOutlined}
                  label={`Preço cheio`}
                  name={`${product}.value`}
                  validate={[FORM_RULES.required, FORM_RULES.minValueProduct]}
                  required
                  disabled={props.router.params.action === 'proposta'}
                  {...currencyMask}
                />
              </MaterialGrid>
              <MaterialGrid item xs={4}>
                <Field
                  component={InputLabelOutlined}
                  label={`Desconto`}
                  name={`${product}.discount_value`}
                  validate={[FORM_RULES.required]}
                  required
                  disabled={props.router.params.action === 'proposta'}
                  {...currencyMask}
                />
              </MaterialGrid>
              <MaterialGrid
                item
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0 8px 0 12px',
                }}>
                <Typography color="primary" style={{ fontSize: 12 }}>
                  Valor final
                </Typography>
                <Typography style={{ color: 'black', marginTop: 12 }}>
                  {props.formValues.products[index]
                    ? props.formValues.products[index].value === 0
                      ? 'Grátis'
                      : props.formValues.products[index].value &&
                        props.formValues.products[index].discount_value
                      ? props.formValues.products[index].value >=
                        props.formValues.products[index].discount_value
                        ? 'R$ ' +
                          (
                            parseFloat(props.formValues.products[index].value || 0) -
                            parseFloat(props.formValues.products[index].discount_value)
                          )
                            .toFixed(2)
                            .replace('.', ',')
                        : 'Desconto inválido'
                      : props.formValues.products[index].value
                      ? 'R$ ' +
                        parseFloat(props.formValues.products[index].value)
                          .toFixed(2)
                          .replace('.', ',')
                      : 'R$ 0,00'
                    : 'R$ 0,00'}
                </Typography>
              </MaterialGrid>
              <Typography
                style={{
                  fontSize: 16,
                  display: 'block',
                  fontWeight: 500,
                  width: '100%',
                  margin: '10px 0',
                  paddingLeft: 10,
                }}>
                Informações
              </Typography>
              <MaterialGrid item xs={6} sm={6}>
                <Field
                  component={InputLabelOutlined}
                  label={`Estoque total`}
                  name={`${product}.stock`}
                  validate={[FORM_RULES.required]}
                  required
                  type="number"
                  disabled={props.router.params.action === 'proposta'}
                />
              </MaterialGrid>
              <MaterialGrid item xs={6} sm={6}>
                <Field
                  component={InputLabelOutlined}
                  label={`Limite Por Usuário`}
                  name={`${product}.limit`}
                  validate={[FORM_RULES.required]}
                  required
                  type="number"
                  disabled={props.router.params.action === 'proposta'}
                />
              </MaterialGrid>
            </MaterialGrid>
          </ExpansionPanel>
        </Box>
      ))}
      <Box
        onClick={() => props.fields.push()}
        style={{
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 26,
        }}>
        <Typography
          style={{
            color: '#5759FB',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
          }}>
          + Adicionar Produto
        </Typography>

        {/*
        <PrimaryButton
          disabled={
            props.disabled ||
            props.router.params.action === 'proposta' ||
            props.router.params.action === 'editar'
          }
        >
        </PrimaryButton> */}
      </Box>
    </>
  );
};

const renderQuestions = (props) => {
  function array_move(fromIndex, toIndex) {
    const element = props.formValues?.questions[fromIndex];
    props.fields.splice(fromIndex, 1);
    props.fields.splice(toIndex, 0, element);
  }
  function goUp(index) {
    return array_move(index, index - 1);
  }
  function goDown(index) {
    return array_move(index, index + 1);
  }
  return (
    <>
      {props.fields.map((question, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            borderTop: '2px solid #E5E5E5',
            paddingTop: 5,
          }}>
          <Box display="flex" flexDirection="column" style={{ marginRight: 20, zIndex: 100 }}>
            {index !== 0 && props.fields.length > 1 ? (
              <ArrowDropUp style={{ cursor: 'pointer' }} onClick={() => goUp(index)} />
            ) : null}
            {index !== props.fields.length - 1 && props.fields.length > 1 ? (
              <ArrowDropDown style={{ cursor: 'pointer' }} onClick={() => goDown(index)} />
            ) : null}
          </Box>
          <ExpansionPanel
            key={index}
            footer={null}
            label={props.formValues?.questions[index]?.name || `Pergunta ${index + 1}`}
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#fff',
            }}>
            <MaterialGrid item xs={12}>
              <Box display="flex" justifyContent="space-between" style={{ marginBottom: 10 }}>
                <Typography
                  style={{
                    fontSize: 16,
                  }}>
                  Pergunta
                </Typography>
                <Typography
                  style={{
                    cursor: 'pointer',
                    color: '#E34848',
                    fontSize: 16,
                  }}
                  onClick={() => props.fields.remove(index)}>
                  Excluir Pergunta
                </Typography>
              </Box>
              <Field
                component={InputLabelOutlined}
                label={`Texto da pergunta ${index + 1}`}
                name={`${question}.question.text`}
                validate={[FORM_RULES.required]}
                required
                helperText={`Digite o texto da pergunta ${index + 1}`}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12} sm={12}>
              <Field
                component={SelectLabel}
                type="text"
                label={`Tipo da Pergunta ${index + 1}`}
                name={`${question}.type`}
                margin="normal"
                defaultValue={{
                  value: 3,
                  label: 'Questão aberta',
                }}
                options={[
                  {
                    value: 3,
                    label: 'Questão aberta',
                  },
                ]}
                validate={[FORM_RULES.required]}
                disabled={Boolean(props.formValues?.questions[index]?.type)}
              />
            </MaterialGrid>
          </ExpansionPanel>
        </Box>
      ))}
      <Box
        onClick={() => props.fields.push()}
        style={{
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 26,
        }}>
        <Typography
          style={{
            color: '#5759FB',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
          }}>
          + Adicionar Pergunta
        </Typography>
      </Box>
    </>
  );
};

const renderLocations = (props) => {
  return (
    <>
      {props.fields.map((location, index) => (
        <div
          key={index}
          style={{
            borderTop: '2px solid #E5E5E5',
          }}>
          <div
            key={index}
            style={{
              padding: 20,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#fff',
            }}>
            <span>Local {index + 1}</span>
            <MaterialGrid container spacing={3} style={{ backgroundColor: '#fff', marginTop: 10 }}>
              <MaterialGrid item xs={6} sm={6}>
                <Field
                  component={InputLabelOutlined}
                  label={`Nome do Local ${index + 1}`}
                  name={`${location}.name`}
                  validate={[FORM_RULES.required]}
                  required
                  disabled={
                    props.disabled ||
                    props.router.params.action === 'proposta' ||
                    props.router.params.action === 'editar'
                  }
                />
              </MaterialGrid>
              <MaterialGrid item xs={6} sm={6}>
                <Field
                  component={InputLabelOutlined}
                  label="Custo da entrega no local (R$)"
                  name={`${location}.price`}
                  type="tel"
                  disabled={
                    props.disabled ||
                    props.router.params.action === 'proposta' ||
                    props.router.params.action === 'editar'
                  }
                  validate={[FORM_RULES.required]}
                  required
                  {...currencyMask}
                />
              </MaterialGrid>

              <MaterialGrid item xs={12}>
                <Field
                  component={SelectLabel}
                  options={[
                    {
                      value: true,
                      label: 'Endereço do participante (entrega)',
                    },
                    { value: false, label: 'Meu endereço (retirada)' },
                  ]}
                  default
                  label="Tipo de local"
                  name={`${location}.delivery`}
                  disabled={props.disabled || props.router.params.action === 'proposta'}
                  validate={[FORM_RULES.required]}
                  required
                />
              </MaterialGrid>

              {props.formValues.delivery_locations[index] ? (
                !props.formValues.delivery_locations[index].delivery ? (
                  <>
                    <MaterialGrid item xs={12} sm={12}>
                      <Field
                        component={InputLabelOutlined}
                        label={`Logradouro do endereço de retirada`}
                        name={`${location}.street`}
                        validate={[FORM_RULES.required]}
                        required
                        disabled={props.disabled || props.router.params.action === 'proposta'}
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={12} sm={12}>
                      <Field
                        component={InputLabelOutlined}
                        label={`Número do endereço de retirada`}
                        name={`${location}.street_number`}
                        validate={[FORM_RULES.required]}
                        required
                        type={'number'}
                        disabled={props.disabled || props.router.params.action === 'proposta'}
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={12} sm={12}>
                      <Field
                        component={InputLabelOutlined}
                        label="CEP do endereço de retirada"
                        name={`${location}.zipcode`}
                        validate={[FORM_RULES.required]}
                        required
                        margin="normal"
                        {...cepMask}
                        disabled={props.disabled || props.router.params.action === 'proposta'}
                      />
                    </MaterialGrid>
                  </>
                ) : null
              ) : null}
            </MaterialGrid>
            <Box display="flex" justifyContent="start" width="100%">
              <SecondaryButton
                disabled={props.router.params.action === 'proposta'}
                onClick={() => props.fields.remove(index)}
                color="primary">
                Excluir Local
              </SecondaryButton>
            </Box>
          </div>
        </div>
      ))}
      {_.isEmpty(props.formValues.delivery_locations) && (
        <p style={{ textAlign: 'center', color: '#D0342C' }}>
          Adicione ao menos um endereço de entrega!
        </p>
      )}
      <Box
        onClick={() => props.fields.push()}
        style={{
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          borderBottom: '2px solid #E5E5E5',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 26,
        }}>
        <Typography
          style={{
            color: '#5759FB',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
          }}>
          + Adicionar local de entrega/retirada
        </Typography>
      </Box>
    </>
  );
};

const Interaction = function (props) {
  function onSubmit(values) {
    if (!values.has_product_order_parameter) {
      values.product_order_parameter_title = null;
      values.product_order_parameter_subtitle = null;
    }
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    props.onClickProgress(values);
  }

  function renderSteps() {
    return (
      <>
        {props.formValues && props.formValues.type_id === 3 && (
          <div className="mb-3">
            <Field
              component={SelectLabel}
              type="text"
              label="Opções de resgate"
              name="payment_option"
              margin="normal"
              options={[
                { value: 'credit_and_payment', label: 'Créditos e pagamento' },
                { value: 'credit_only', label: 'Somente créditos' },
              ]}
              validate={[FORM_RULES.required]}
            />
            <Field
              component={InputSwitch}
              name="allow_installments"
              label="Permite parcelamento?"
            />
            {props.formValues && props.formValues.allow_installments && (
              <Field
                style={{ marginTop: 12 }}
                component={InputLabelOutlined}
                label={`Quantidade de parcelas`}
                name={`max_installments`}
                validate={props.formValues.allow_installments ? [FORM_RULES.required] : null}
                required={props.formValues.allow_installments}
                type="number"
              />
            )}
            {props.formValues && props.formValues.allow_installments && (
              <Field
                style={{ marginTop: 12 }}
                component={InputLabelOutlined}
                label={`Número de parcelas isentas de juros`}
                name={`free_installments`}
                type="number"
              />
            )}
          </div>
        )}
        <div className="mb-3">
          <Field
            component={InputSwitch}
            name="only_one_product"
            label="Nessa atividade, o participante poderá comprar apenas um produto"
            disabled={
              props.router.params.action === 'proposta' || props.router.params.action === 'editar'
            }
          />
        </div>
        {props.formValues && _.isEmpty(props.formValues.products) && (
          <p style={{ textAlign: 'center', color: '#D0342C' }}>
            Ainda não há produtos. Adicione alguns!
          </p>
        )}
        <ExpansionList>
          <FieldArray name="products" component={renderproducts} {...props} />
        </ExpansionList>

        {props.formValues && _.isEmpty(props.formValues.questions) && (
          <p style={{ textAlign: 'center', color: '#D0342C' }}>
            Ainda não há perguntas. Adicione algumas!
          </p>
        )}
        <ExpansionList>
          <FieldArray name="questions" component={renderQuestions} {...props} />
        </ExpansionList>
        {props.formValues && !props.formValues.digital_product && (
          <>
            <ExpansionList>
              <FieldArray name="delivery_locations" component={renderLocations} {...props} />
            </ExpansionList>
          </>
        )}

        {props.formValues ? (
          props.formValues.has_product_order_parameter ? (
            <>
              <Field
                component={InputLabelOutlined}
                label="Título do Campo"
                name="product_order_parameter_title"
                validate={[FORM_RULES.required]}
                disabled={props.router.params.action === 'proposta'}
                required
                margin="normal"
              />
              <Field
                component={InputLabelOutlined}
                label="Subtítulo do Campo"
                name="product_order_parameter_subtitle"
                validate={[FORM_RULES.required]}
                disabled={props.router.params.action === 'proposta'}
                required
                margin="normal"
              />
            </>
          ) : null
        ) : null}

        {props.formValues && props.formValues.partner_id ? (
          <>
            <Typography style={{ margin: '15px 0' }} variant="subtitle2">
              Taxas
            </Typography>
            <Typography style={{ margin: '15px 0', fontSize: 12 }} variant="p">
              Quando o parceiro te enviar uma atividade de compra, ele pode oferecer uma porcentagem
              de suas vendas para você, por hospedar esses produtos na sua estação
            </Typography>
            <MaterialGrid container style={{ marginTop: 10 }}>
              <MaterialGrid xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {props.formValues ? (
                    props.formValues.comission_owner_percentage ? (
                      <Typography>
                        Você receberá{' '}
                        <strong>{parseFloat(props.formValues.comission_owner_percentage)}% </strong>
                        da venda desses produtos.
                      </Typography>
                    ) : null
                  ) : null}
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </>
        ) : null}
        <GenericConfirmationCard
          open={props.openConfirmation}
          maxWidthFixed
          cancelClose={props.handleCancelClose}
          labelLeftButton={'Continuar aqui'}
          labelRightButton={props.buttonConfirmationLabel}
          general={props.general}
          title={props.confirmationTitle}
          text={props.confirmationSubtitle}
          confirmButtonColor={'primary'}
          onClose={() => props.setOpenConfirmation(false)}
          onClick={props.closeModal}
        />
      </>
    );
  }

  return props.coupons.loading && props.formValues.delivery_locations === undefined ? (
    <div className="d-flex justify-content-center mt-5">
      <LoadingProgress />
    </div>
  ) : (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          {renderSteps()}
        </div>
        <MaterialGrid
          container
          display="flex"
          justify="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          {props.formValues.digital_product === true ? (
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={props.activeActions.buttonLoading || props.formValues.products.length === 0}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          ) : (
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={
                props.activeActions.buttonLoading ||
                props.formValues.products.length === 0 ||
                props.formValues.delivery_locations.length === 0
              }
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          )}
        </MaterialGrid>
      </Form>
    </>
  );
};

Interaction.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.any,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  coupons: PropTypes.shape({
    loading: PropTypes.any,
  }),
  formValues: PropTypes.shape({
    comission_owner_percentage: PropTypes.any,
    delivery_locations: PropTypes.shape({
      length: PropTypes.number,
    }),
    digital_product: PropTypes.any,
    has_product_order_parameter: PropTypes.any,
    partner_id: PropTypes.any,
    products: PropTypes.shape({
      length: PropTypes.number,
    }),
    type_id: PropTypes.number,
  }),
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setOpenConfirmation: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    categories: state.categories,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('couponsForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeFieldValue,
      getCategoriesList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'couponsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Interaction)
);
