import _ from 'lodash';
import validate from './cpf';

const valid = require('card-validator');

export const FORM_RULES = {
  required: (value) => {
    return !_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)
      ? undefined
      : 'Esse campo é obrigatório';
  },
  maxL: (max) => (value) =>
    value && value.length > max
      ? `Aceitamos ${max} caracteres ou menos. Seu atual: ${value.length}`
      : undefined,
  max: (max, current) => (value) =>
    value && value.length > max
      ? `Esse campo deve possuir no máximo ${max} caracteres. Atualmente: ${current}`
      : undefined,
  min: (min) => (value) =>
    value && value.length < min ? `Esse campo deve possuir no mínimo ${min} caracteres` : undefined,
  number: (value) =>
    value && Number.isNaN(Number(value)) ? 'Este campo só aceita números' : undefined,
  minValue: (min) => (value) =>
    value && value < min ? `O valor deve ser maior que ${_.round(min, 2)}` : undefined,
  maxValue: (max) => (value) =>
    value && value > max ? `O valor deve ser menor que ${_.round(max, 2)}` : undefined,
  positiveValue: (value) => (value && value < 0 ? 'Insira um número maior que zero!' : undefined),
  password: (value) =>
    value && !/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,20})/i.test(value)
      ? 'Senha precisar ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20.'
      : undefined,
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Email inválido'
      : undefined,
  tooOld: (value) => (value) => value && value > 65 ? 'You might be too old for this' : undefined,
  lowerCase: (value) =>
    value && /[^a-z]/i.test(value) ? 'Apenas letras minúsculas sem acento' : undefined,
  requiredCheckbox: (value) => {
    return value ? undefined : 'Esse campo é obrigatório';
  },
  actions: (value) => {
    let has_required = false;
    if (!value) {
      return undefined;
    }
    for (const item of value) {
      if (!item) {
        return undefined;
      }
      if (item.required) {
        has_required = true;
      }
    }
    return has_required
      ? undefined
      : 'É necessário que tenha ao menos 1 atividade obrigatória por nível';
  },
  url: (value) => {
    const expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    const t = value;

    if (t.match(regex)) {
      return undefined;
    }
    return 'Insira um URL válida';
  },
  minValueProduct: (value) => {
    const vFloat = parseFloat(value);
    if (vFloat < 1.5) {
      return 'Valor mínimo requerido de R$ 1,50';
    }
    return undefined;
  },
  cpf: (value) => {
    if (!validate(value)) {
      return 'CPF inválido';
    }
    return undefined;
  },
  partnersArray: (value) => {
    if (!value) {
      return 'Ao menos um sócio deve ser cadastrado';
    }
    return undefined;
  },
  card_number: (value) => {
    const numberValidation = valid.number(value);
    if (!numberValidation.isValid) {
      return 'Número inválido';
    }
    return undefined;
  },
  cvc: (value) => {
    const numberValidation = valid.cvv(value);
    if (!numberValidation.isValid) {
      return 'CVV inválido';
    }
    return undefined;
  },
  expiry: (value) => {
    const numberValidation = valid.expirationDate(value);
    if (!numberValidation.isValid) {
      return 'Data inválida';
    }
    return undefined;
  },
  cep: (value) => {
    if (value.length < 8) {
      return 'CEP inválido';
    }
    return undefined;
  },
  maxLength100: (value) =>
    value && value.length > 100
      ? `Aceitamos ${100} caracteres ou menos. Seu atual: ${value.length}`
      : undefined,
  maxLength30: (value) =>
    value && value.length > 30
      ? `Aceitamos ${30} caracteres ou menos. Seu atual: ${value.length}`
      : undefined,
  maxLength20: (value) =>
    value && value.length > 20
      ? `Aceitamos ${20} caracteres ou menos. Seu atual: ${value.length}`
      : undefined,
  customDomain: (value) => {
    const regex =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (value && regex.test(value)) {
      return undefined;
    }
    return 'Insira uma url válida, não se esqueça do http:// ou https://';
  },
  percentage: (value) =>
    value && (value < 0 || value > 100) ? 'Insira um número entre zero e 100.' : undefined,
};

export const MESSAGES = {
  required: 'Este campo é obrigatório',
  whitespace: 'Este campo não pode ser vazio',
  mail: 'Digite um email válido',
  number: 'Este campo deve conter apenas números',
  url: 'Digite uma url válida',
};

export const FORMRULES = {
  required: { required: true, message: MESSAGES.required },
  whitespace: { whitespace: true, message: MESSAGES.whitespace },
  number: { pattern: /\d+/, message: MESSAGES.number },
  mail: {
    pattern:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: MESSAGES.mail,
  },
  url: {
    pattern: /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/,
    message: MESSAGES.url,
  },
};
