import PropTypes from 'prop-types';
import { Box, Dialog, DialogTitle, FormControl, Select } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import PlansCard from '../../../../common/components/cards/plansCard';
import { FAN_STATION_ID } from '../../../../config/consts';
import { appColors } from '../../../../styles/colors';
import {
  deactivatePlan,
  getList,
  getPlansSubscriptions,
  getTransactions,
  remove,
  settleCharges,
} from './plansActions';
const {
  v2: { pink, darkBlue, blue },
} = appColors;

const paymentsColumns = [
  {
    field: 'jan',
    title: 'Janeiro',
    render: (rowData) => {
      const { jan } = rowData;
      return renderStatus(jan);
    },
  },
  {
    field: 'fev',
    title: 'Fevereiro',
    render: (rowData) => {
      const { fev } = rowData;
      return renderStatus(fev);
    },
  },
  {
    field: 'mar',
    title: 'Março',
    render: (rowData) => {
      const { mar } = rowData;
      return renderStatus(mar);
    },
  },
  {
    field: 'apr',
    title: 'Abril',
    render: (rowData) => {
      const { apr } = rowData;
      return renderStatus(apr);
    },
  },
  {
    field: 'may',
    title: 'Maio',
    render: (rowData) => {
      const { may } = rowData;
      return renderStatus(may);
    },
  },
  {
    field: 'june',
    title: 'Junho',
    render: (rowData) => {
      const { june } = rowData;
      return renderStatus(june);
    },
  },
  {
    field: 'july',
    title: 'Julho',
    render: (rowData) => {
      const { july } = rowData;
      return renderStatus(july);
    },
  },
  {
    field: 'aug',
    title: 'Agosto',
    render: (rowData) => {
      const { aug } = rowData;
      return renderStatus(aug);
    },
  },
  {
    field: 'sept',
    title: 'Setembro',
    render: (rowData) => {
      const { sept } = rowData;
      return renderStatus(sept);
    },
  },
  {
    field: 'oct',
    title: 'Outubro',
    render: (rowData) => {
      const { oct } = rowData;
      return renderStatus(oct);
    },
  },
  {
    field: 'nov',
    title: 'Novembro',
    render: (rowData) => {
      const { nov } = rowData;
      return renderStatus(nov);
    },
  },
  {
    field: 'dec',
    title: 'Dezembro',
    render: (rowData) => {
      const { dec } = rowData;
      return renderStatus(dec);
    },
  },
];

function renderStatus(field) {
  return <span style={{ color: field === 'Pago' ? darkBlue : pink }}>{field}</span>;
}

function Plans({
  plans,
  router,
  auth,
  getList,
  deactivatePlan,
  getPlansSubscriptions,
  getTransactions,
  settleCharges,
}) {
  const defaultFilters = [
    {
      label: 'Preço',
      field: 'currentSpendAction',
      component: 'slider',
      typeData: 'money',
      minValue: 0,
      maxValue: 200,
    },
    {
      label: 'Filtrar entre as datas',
      field: 'betweenDates',
      component: 'datePicker',
      typeData: 'date',
      filterFunction: 2,
      startDate: new Date(),
      endDate: new Date(),
    },
  ];
  const { loading, list, subscriptions, subscriptionsLoading } = plans;

  const filters = defaultFilters;
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);
  const [paymentsYear, setPaymentsYear] = useState('');
  const [paymentsData, setPaymentsData] = useState([
    {
      jan: 'Pendente',
      fev: 'Pendente',
      mar: 'Pendente',
      apr: 'Pendente',
      may: 'Pendente',
      june: 'Pendente',
      july: 'Pendente',
      aug: 'Pendente',
      sept: 'Pendente',
      oct: 'Pendente',
      nov: 'Pendente',
      dec: 'Pendente',
    },
  ]);

  function handleSeePayments(subscriptionID) {
    setSelectedSubscription(subscriptionID);
    setIsPaymentModalOpen(true);
  }

  function handleClosePaymentModal() {
    setIsPaymentModalOpen(false);
  }

  const [columns] = useState([
    {
      field: 'plan.name',
      title: 'Nome do plano',
      render: (rowData) => rowData.plan?.name,
    },
    {
      field: 'user.name',
      title: 'Usuário',
      render: (rowData) => rowData.user?.name || 'N/A',
    },
    {
      field: 'user.email',
      title: 'E-mail',
      render: (rowData) => rowData.user?.email || 'N/A',
    },
    {
      field: 'start_date',
      title: 'Data de início',
      render: (rowData) => <span>{format(parseISO(rowData.start_date), 'dd/MM/yyyy HH:mm')}</span>,
    },
    {
      title: 'Preço',
      field: 'plan.amount',
      render: (rowData) => `R$ ${rowData.plan.amount.replace('.', ',')}`,
    },
    {
      field: 'status',
      title: 'Status',
      render: (rowData) => (
        <button style={{ color: appColors.v2.blue }} onClick={() => handleSeePayments(rowData.id)}>
          Ver pagamentos
        </button>
      ),
    },
  ]);

  // Fetch general data
  useEffect(() => {
    getList(auth.user.establishments[0].stations[0].id);
    getPlansSubscriptions(auth.user.establishments[0].stations[0].id);
  }, []);

  // Fetch payments info data
  useEffect(() => {
    function callback(list) {
      setTransactions(list);
      setIsTransactionsLoading(false);
    }

    function errCallback() {
      setIsTransactionsLoading(false);
    }

    if (selectedSubscription) {
      setIsTransactionsLoading(true);
      getTransactions(selectedSubscription, callback, errCallback);
    }

    return () => {
      setTransactions([]);
    };
  }, [selectedSubscription]);

  // Compute payments table data
  useEffect(() => {
    if (transactions.length > 0) {
      const filtered = transactions.filter(
        (item) => getYear(parseISO(item.date_created)) === parseInt(paymentsYear)
      );
      const newPaymentsData = [
        {
          jan: 'Pendente',
          fev: 'Pendente',
          mar: 'Pendente',
          apr: 'Pendente',
          may: 'Pendente',
          june: 'Pendente',
          july: 'Pendente',
          aug: 'Pendente',
          sept: 'Pendente',
          oct: 'Pendente',
          nov: 'Pendente',
          dec: 'Pendente',
        },
      ];
      filtered.forEach((item) => {
        const month = getMonth(parseISO(item.date_created));
        switch (month) {
          case 0:
            newPaymentsData[0].jan = 'Pago';
            break;
          case 1:
            newPaymentsData[0].fev = 'Pago';
            break;
          case 2:
            newPaymentsData[0].mar = 'Pago';
            break;
          case 3:
            newPaymentsData[0].apr = 'Pago';
            break;
          case 4:
            newPaymentsData[0].may = 'Pago';
            break;
          case 5:
            newPaymentsData[0].june = 'Pago';
            break;
          case 6:
            newPaymentsData[0].july = 'Pago';
            break;
          case 7:
            newPaymentsData[0].aug = 'Pago';
            break;
          case 8:
            newPaymentsData[0].sept = 'Pago';
            break;
          case 9:
            newPaymentsData[0].oct = 'Pago';
            break;
          case 10:
            newPaymentsData[0].nov = 'Pago';
            break;
          case 11:
            newPaymentsData[0].dec = 'Pago';
            break;
        }
      });
      setPaymentsData(newPaymentsData);
    }

    return () => {};
  }, [paymentsYear, transactions.length]);

  useEffect(() => {
    if (!isPaymentModalOpen) {
      setTransactions([]);
    }
  }, [isPaymentModalOpen]);

  /* useEffect(() => {
    setData(props.categories.list.content);
  }, [props.categories.list.content]); */

  function handleSettleCharges() {
    function callback(list) {
      setTransactions(list);
      setIsTransactionsLoading(false);
    }

    function errCallback() {
      setIsTransactionsLoading(false);
    }

    function settleCallback() {
      setIsTransactionsLoading(true);
      getTransactions(selectedSubscription, callback, errCallback);
    }

    settleCharges(selectedSubscription, settleCallback);
  }

  function renderFrequencyLabel(frequency, amount) {
    if (parseFloat(amount) === 0) {
      return 'Não há periodicidade';
    }
    return frequency === 30
      ? 'Plano Mensal'
      : frequency === 365
      ? 'Plano Anual'
      : frequency === 183
      ? 'Plano Semestral'
      : 'Erro';
  }

  function renderAmountLabel(amount, discount) {
    return `R$ ${(parseFloat(amount) - parseFloat(discount)).toFixed(2).replace('.', ',')}`;
  }

  function renderFreeTrialDaysLabel(trialDays) {
    return trialDays > 0 ? `Tem teste grátis (${trialDays} dias)` : 'Não tem teste grátis';
  }

  function renderEmptyScreen(text, buttonText, routePath) {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">{text}</h2>
                <PrimaryButton onClick={() => router.push(routePath)}>{buttonText}</PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (
    !auth.user.subscription.plan &&
    auth.user.establishments[0].stations[0].id !== FAN_STATION_ID
  ) {
    return renderEmptyScreen(
      'Você ainda não contratou um plano',
      'Ver opções',
      '/owner/planos/premium'
    );
  }

  if (!auth.user.establishments[0].digital_account) {
    return renderEmptyScreen(
      'Você ainda não cadastrou uma conta corrente',
      'Cadastrar',
      '/owner/profile'
    );
  }

  if (list.content.length < 1) {
    return renderEmptyScreen(
      'Você ainda não criou nenhum plano',
      'Criar novo plano',
      '/owner/planos/novo'
    );
  }

  return (
    <section id="plans" style={{ marginTop: 64, position: 'relative' }}>
      <Fab
        variant="extended"
        style={{
          background: '#fff',
          position: 'absolute',
          right: 0,
          bottom: -52,
          zIndex: 1,
        }}
        onClick={() => router.push('/owner/planos/novo')}
        color="primary">
        <PlusIcon stroke={appColors.v2.blue} style={{ marginRight: 8 }} />
        <p className="h7" style={{ textTransform: 'initial' }}>
          Criar novo plano
        </p>
      </Fab>
      <GeneralGrid container spacing={3}>
        {list.content.map((item, index) => (
          <GeneralGrid item xs={4} key={index}>
            <PlansCard
              planName={item.name}
              amountLabel={renderAmountLabel(item.amount, item.discount)}
              isActive={!item.deletedAt}
              frequencyLabel={renderFrequencyLabel(item.days, item.amount)}
              trialLabel={renderFreeTrialDaysLabel(item.trial_days)}
              handleEdit={() => router.push(`/owner/planos/${item.id}/editar`)}
              handleRemove={() =>
                deactivatePlan(item.id, () => {
                  getList(auth.user.establishments[0].stations[0].id);
                })
              }
            />
          </GeneralGrid>
        ))}
        <GeneralGrid item xs={12}>
          {subscriptionsLoading ? (
            <Box display="flex" height="100vh" justifyContent="center" mt={7}>
              <LoadingProgress />
            </Box>
          ) : (
            <Table
              filters={filters}
              hasToolbar
              title="Planos e usuários"
              columns={columns}
              dataType={'planUser'}
              rows={subscriptions}
              toolbarSearch
              onChangeOption={() => {}}
              defaultSelected={[]}
            />
          )}
        </GeneralGrid>
      </GeneralGrid>
      <Dialog
        onClose={handleClosePaymentModal}
        aria-labelledby="payments-modal"
        open={isPaymentModalOpen}
        maxWidth="xl"
        fullWidth>
        {isTransactionsLoading ? (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <LoadingProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <DialogTitle id="payments-modal">Pagamentos</DialogTitle>
              <Box display="flex" alignItems="center" px={4}>
                <p className="h6" style={{ marginRight: 8 }}>
                  Ano base:
                </p>
                <FormControl variant="outlined" style={{ marginRight: 8 }}>
                  <Select
                    native
                    value={paymentsYear}
                    onChange={(e) => setPaymentsYear(e.target.value)}>
                    <option aria-label="Nenhum" value={''}>
                      Escolha uma opção
                    </option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </Select>
                </FormControl>
                <button
                  className="h6"
                  style={{
                    backgroundColor: blue,
                    padding: '14px 32px 14px 32px',
                    borderRadius: 6,
                    color: '#fff',
                  }}
                  onClick={handleSettleCharges}>
                  Dar baixa nas pendências
                </button>
              </Box>
            </Box>
            <Table
              title="Pagamentos"
              columns={paymentsColumns}
              data={paymentsData}
              dataType={'default'}
              onChangeOption={() => {}}
              defaultSelected={[]}
              hasBorder={false}
            />
          </>
        )}
      </Dialog>
    </section>
  );
}

Plans.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      subscription: PropTypes.shape({
        plan: PropTypes.any,
      }),
    }),
  }),
  deactivatePlan: PropTypes.func,
  getList: PropTypes.func,
  getPlansSubscriptions: PropTypes.func,
  getTransactions: PropTypes.func,
  plans: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func,
      }),
    }),
    loading: PropTypes.any,
    subscriptions: PropTypes.any,
    subscriptionsLoading: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  settleCharges: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
      deactivatePlan,
      getPlansSubscriptions,
      getTransactions,
      settleCharges,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
