import styled from 'styled-components';
import { darken } from 'polished';

const ColorPickPopOverButton = styled.button`
  background-color: #fffbfb;
  padding: 5px 8px;
  border-radius: 8px;
  min-width: 100px;
  transition: background-color 0.4s, box-shadow 0.4s;
  border-left: ${(props) => `10px solid ${props.currentColor}`};

  &:hover {
    background-color: ${darken(0.03, '#dddddd')};
    box-shadow: 5px 5px 5px lightgrey;
  }
`;

export default ColorPickPopOverButton;
