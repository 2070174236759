/* eslint-disable camelcase */
// import Ícones do FortAwesome
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
// Imports de Ícones do Material
import Contacts from '@material-ui/icons/Contacts';
import Grain from '@material-ui/icons/Grain';
import Home from '@material-ui/icons/Home';
import Layers from '@material-ui/icons/Layers';
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore';
import Loyalty from '@material-ui/icons/Loyalty';
import Score from '@material-ui/icons/Score';
import Subject from '@material-ui/icons/Subject';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ViewList from '@material-ui/icons/ViewList';
import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { USER_TOKEN } from '../config/consts';
import Owner from '../modules/owner/main/owner';
import NotAuthorized from '../pages/401/401';
import { validateToken } from '../pages/auth/authActions';
import Login from '../pages/auth/login/login';
import QrCodeValidator from '../pages/auth/qrCodeValidator/qrCodeValidator';

const engagementChildren = [
  /* {
            name: 'Inicial',
            action: '',
            icon: <ViewList style={{ fontSize: 24 }} />,
            link: '/owner/acoes/inicio',
            id: 0,
          }, */
  {
    name: 'Atividades',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/acoes/minhas-atividades',
    id: 1,
  },

  {
    name: 'Parcerias',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/acoes/propostas',
    id: 2,
  },
  {
    name: 'Jornadas',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/journeys',
    id: 3,
  },
  {
    name: 'Sorteios',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/giveaway',
    id: 4,
  },
  {
    name: 'Automações',
    action: '',
    icon: <LocalGroceryStore style={{ fontSize: 24 }} />,
    link: '/owner/automatizacoes',
    id: 5,
  },
  {
    name: 'Atendimento',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/suporte',
    id: 6,
  },
  {
    name: 'Cupons',
    action: '',
    icon: <ViewList style={{ fontSize: 24 }} />,
    link: '/owner/coupons',
    id: 6,
  },
];

const navItems = [
  {
    id: 1,
    name: 'Painel',
    link: '/owner',
    icon: <Home style={{ fontSize: 24 }} />,
    activeName: 'pnl',
  },
  {
    id: 2,
    name: 'Pedidos',
    action: '',
    icon: <Score style={{ fontSize: 24 }} />,
    activeName: 'ptp',
    link: '/owner/sales',
    children: [
      {
        name: 'Vouchers',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/inscricoes',
        id: 0,
      },
      {
        name: 'Vendas',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/sales',
        id: 1,
      },
      {
        name: 'Doações',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/donations',
        id: 2,
      },
      {
        name: 'Relatório de produtos',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/relatorio-produtos',
        id: 3,
      },
    ],
  },
  {
    id: 3,
    name: 'Engajamento',
    action: '',
    icon: <Layers style={{ fontSize: 24 }} />,
    activeName: 'trl',
    link: '/owner/acoes/inicio',
    children: isMobile
      ? engagementChildren.filter((item) => item.name !== 'Jornadas')
      : engagementChildren,
  } /*      {        id: 4,        name: 'Atendimento',        action: '',        icon: <ContactSupport style={{ fontSize: 24 }} />,        activeName: 'ptp',        link: '/owner/suporte',        children: [        ],      }, */,
  {
    id: 5,
    name: 'Usuários',
    action: '',
    icon: <Contacts style={{ fontSize: 24 }} />,
    activeName: 'ptp',
    link: '/owner/contacts',
    children: [
      {
        name: 'Cadastrados',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/contacts',
        id: 0,
      },
      {
        name: 'Pré-cadastros',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/contacts/credenciados',
        id: 2,
      },
      {
        name: 'Gestores',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/gestores',
        id: 3,
      },
      {
        name: 'Participantes',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/participantes',
      },
      {
        name: 'Validadores de Código',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/validadores',
        id: 4,
      },
    ],
  },
  {
    id: 6,
    name: 'Recompensas',
    action: '',
    icon: <Loyalty style={{ fontSize: 24 }} />,
    activeName: 'ptn',
    link: '/owner/points/buy',
    children: [
      {
        name: 'Solicitar crédito',
        action: '',
        icon: <LocalGroceryStore style={{ fontSize: 24 }} />,
        link: '/owner/points/buy',
        id: 0,
      },
      {
        name: 'Pedidos de transferência',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/transferencias',
        id: 1,
      },
      {
        name: 'Recargas de créditos',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/statement-buy-points',
        id: 2,
      },
      {
        name: 'Créditos premiados',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/user-earned-points',
        id: 3,
      },
      {
        name: 'Extrato de compra de atividades',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/points/buy-actions-transactions',
        id: 4,
      },
      {
        name: 'Meus créditos',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/points',
        id: 5,
      },
      {
        name: 'Extrato',
        action: '',
        icon: <Loyalty style={{ fontSize: 24 }} />,
        activeName: 'ptn',
        link: '/owner/points/extrato',
      },
    ],
  },
  /*  {
        id: 12,
        name: 'Inteligência',
        action: '',
        icon: <SyncAlt />,
        activeName: 'ptp',
        link: '/owner/automatizacoes',
        children: [],
      }, */
  /* {
        id: 6,
        name: 'Conteúdo',
        link: '/owner/content/noticias',
        icon: <Subject style={{ fontSize: 24 }} />,
        activeName: 'cnt',
        children: [
          {
            name: 'Notícias',
            action: '',
            icon: <LocalGroceryStore style={{ fontSize: 24 }} />,
            link: '/owner/content/noticias',
            id: 0,
          },
          {
            name: 'Agenda',
            action: '',
            icon: <Subject style={{ fontSize: 24 }} />,
            link: '/owner/content/calendar',
            id: 1,
          },
          {
            name: 'Downloads',
            action: '',
            icon: <Grain style={{ fontSize: 24 }} />,
            link: '/owner/content/downloads',
            id: 2,
          },

        ],
      }, */

  {
    id: 7,
    name: 'Configurações',
    link: '/owner/profile',
    icon: <AccountCircle style={{ fontSize: 24 }} />,
    activeName: 'pnl',
    children: [
      {
        name: 'Minha conta',
        action: '',
        icon: <Layers style={{ fontSize: 24 }} />,
        link: '/owner/profile',
        id: 0,
      },
      {
        name: 'Aparência',
        action: '',
        icon: <Layers style={{ fontSize: 24 }} />,
        link: '/owner/website',
        id: 1,
      },
      {
        name: 'Seções',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/content/secoes',
        id: 2,
      },
      {
        name: 'Blocos',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/content/sobre',
        id: 3,
      },
      {
        name: 'Controle de acesso',
        action: '',
        icon: <Layers style={{ fontSize: 24 }} />,
        link: '/owner/website/controle-de-acesso',
        id: 4,
      },
      {
        name: 'Minhas Categorias',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/minhas-categorias',
        id: 5,
      },
      {
        name: 'Calendário',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/content/calendar',
        id: 6,
      },
      {
        name: 'Domínio Personalizado',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/dominio-personalizado',
        id: 7,
      },
      {
        name: 'Entidades',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/entidades',
        id: 8,
      },
      {
        name: 'Agrupamentos',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/groupings',
        id: 9,
      },
    ],
  },
  {
    id: 8,
    name: 'Planos',
    link: '/owner/planos',
    activeName: 'plan',
  },
];

class AuthOrApp extends Component {
  constructor(props) {
    super(props);
    console.disableYellowBox = true;
    axios.defaults.headers.common.Accept = 'application/json';

    this.navBarItems = navItems;

    if (props.auth?.user) {
      if (props.auth.user.establishments[0]?.stations[0]?.classified_ads_access) {
        const { classified_ads_access } = props.auth.user.establishments[0]?.stations[0];

        if (classified_ads_access) {
          this.navBarItems[2].children.splice(6, 0, {
            id: 13,
            name: 'Moderação',
            action: '',
            icon: <SupervisorAccountIcon />,
            activeName: 'ptp',
            link: '/owner/moderator/classified-ads',
            children: [
              {
                name: 'Classificados',
                action: '',
                icon: <ViewList style={{ fontSize: 24 }} />,
                link: '/owner/moderator/classified-ads',
                id: 0,
              },
            ],
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth?.user?.establishments[0]?.stations[0]?.classified_ads_access) {
      if (
        prevProps.auth?.user?.establishments[0]?.stations[0].classified_ads_access !==
        this.props.auth?.user?.establishments[0]?.stations[0].classified_ads_access
      ) {
        if (this.props.auth?.user?.establishments[0]?.stations[0].classified_ads_access) {
          this.navBarItems[2].children.splice(7, 0, {
            id: 13,
            name: 'Moderação',
            action: '',
            icon: <SupervisorAccountIcon />,
            activeName: 'ptp',
            link: '/owner/moderator/classified-ads',
            children: [
              {
                name: 'Classificados',
                action: '',
                icon: <ViewList style={{ fontSize: 24 }} />,
                link: '/owner/moderator/classified-ads',
                id: 0,
              },
            ],
          });
          window.location.reload();
        } else {
          this.navBarItems.splice(6, 1);
          window.location.reload();
        }
      }
    }

    // Changes nav bar plan item label depending on user current subscription

    this.navBarItems[7] = {
      id: 9,
      name: this.props.auth.user?.subscription?.plan ? 'Upgrade de plano' : 'Seja Premium',
      link: '/owner/planos/premium',
      activeName: 'premium',
    };

    //
  }

  UNSAFE_componentWillMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(JSON.parse(localStorage.getItem(USER_TOKEN)), user.id);
    }
  }

  renderItems() {
    if (!this.props.auth.user.briefingDone) {
      return [];
    }
    return this.navBarItems;
  }

  render() {
    const { user, validToken } = this.props.auth;
    let prefix = this.props.router.location.pathname.split('/');
    if (prefix?.[1].indexOf('qr-code-validator') > -1) {
      return <QrCodeValidator>{this.props.children}</QrCodeValidator>;
    }
    if (!user && !validToken) {
      return <Login>{this.props.children}</Login>;
    }
    if (_.isNull(user.establishment_user)) {
      return <NotAuthorized />;
    }

    if (prefix.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      prefix = prefix[1];
    } else {
      // eslint-disable-next-line prefer-destructuring
      prefix = prefix[0];
    }
    if (prefix === '') {
      return this.props.children;
    }
    if (prefix === 'owner') {
      return (
        <Owner
          routes={this.props.routes}
          router={this.props.router}
          navBarItems={this.renderItems()}>
          {this.props.children}
        </Owner>
      );
    }
  }
}

AuthOrApp.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      briefingDone: PropTypes.any,
      establishment_user: PropTypes.any,
      establishments: PropTypes.any,
      id: PropTypes.any,
      subscription: PropTypes.shape({
        plan: PropTypes.any,
      }),
    }),
    validToken: PropTypes.any,
  }),
  children: PropTypes.any,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape({
        split: PropTypes.func,
      }),
    }),
  }),
  routes: PropTypes.any,
  validateToken: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  general: state.general,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ validateToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
