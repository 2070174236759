import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function GeneralGrid(props) {
  const classes = useStyles();
  return (
    <Grid className={clsx(classes.root, props.className)} {...props}>
      {props.children}
    </Grid>
  );
}

GeneralGrid.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
};
