import { InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/styles';
import React from 'react';
import { appColors } from 'styles/colors';

import PropTypes from 'prop-types';

const useStylesSelectLabel = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
  inputLabel: {
    color: appColors.primary,
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fullWidth: {
    minWidth: '100%',
  },
}));

export function SelectLabel(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const { meta, required, helperText, options } = props;

  /*  function handleChange(event) {
        setValue(event.target.value);
    } */
  const StyledMenuItem = styled(MenuItem)({
    display: (props) => (props.disabled ? 'none' : 'block'),
  });

  function renderOptions() {
    return options.map((option, index) => (
      <StyledMenuItem key={index} value={option.value} disabled={Boolean(option.disabled)}>
        {option.label}
      </StyledMenuItem>
    ));
  }
  return (
    <div className={classes.root} style={props.containerStyle}>
      <FormControl
        variant="outlined"
        color="primary"
        style={{ width: '100%' }}
        className={classes.formControl}
        error={!!(meta && meta.touched && meta.error)}
        required={required}>
        <InputLabel
          disabled={Boolean(props.disabled)}
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="select-label">
          {props.label}
        </InputLabel>
        <Select
          {...props.input}
          disabled={Boolean(props.disabled)}
          input={<OutlinedInput name={props.name} labelWidth={labelWidth} id="select-label" />}>
          {renderOptions()}
        </Select>
        {meta && meta.touched && meta.error ? (
          <FormHelperText>{(meta.touched && meta.error) || helperText}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

SelectLabel.propTypes = {
  containerStyle: PropTypes.any,
  disabled: PropTypes.any,
  helperText: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.any,
  }),
  name: PropTypes.any,
  options: PropTypes.shape({
    map: PropTypes.func,
  }),
  required: PropTypes.any,
};
