import { Typography } from '@material-ui/core';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';

export class EditorConvertToHTML extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.input.value && _.isUndefined(this.state.editorState)) {
      const editorState = this.initEditorState();
      this.setState({ editorState });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        this.props.input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }
    );
  };

  initEditorState() {
    const html = this.props.input.value;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  render() {
    const { editorState } = this.state;
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <>
        <Typography className="text-center">{this.props.label}</Typography>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorStyle={{
            border: this.props.border ? '1px solid #00000099' : 'none',
            borderRadius: 4,
          }}
          editorClassName="editor-test"
          onEditorStateChange={this.onEditorStateChange}
          readOnly={this.props.disabled}
          toolbar={{
            options: [
              'fontSize',
              'textAlign',
              'colorPicker',
              'image',
              'link',
              'embedded',
              'emoji',
              'remove',
              'history',
            ],
            embedded: {
              embedCallback: (embeddedLink) => {
                if (embeddedLink.indexOf('youtube') >= 0) {
                  embeddedLink = embeddedLink.replace('watch?v=', 'embed/');
                  embeddedLink = embeddedLink.replace('/watch/', '/embed/');
                  embeddedLink = embeddedLink.replace('youtu.be/', 'youtube.com/embed/');
                } else if (embeddedLink.indexOf('vimeo') >= 0) {
                  embeddedLink = embeddedLink.replace('//', '//player.');
                  embeddedLink = embeddedLink.replace('.com/', '.com/video/');
                }
                return embeddedLink;
              },
            },
          }}
        />
        {touched && error ? (
          <Typography variant="caption" style={{ color: '#FF5A52' }}>
            {error}
          </Typography>
        ) : null}
      </>
    );
  }
}

EditorConvertToHTML.propTypes = {
  border: PropTypes.any,
  disabled: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
};
