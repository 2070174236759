import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useRemirror, Remirror, EditorComponent, Toolbar, ThemeProvider } from '@remirror/react';
import { ExtensionPriority, prosemirrorNodeToHtml } from 'remirror';
import { ImageToolbar } from './ImageToolbar';
import { ComponentItem } from '@remirror/react';
import 'remirror/styles/all.css';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TableExtension,
  TrailingNodeExtension,
  ImageExtension,
} from 'remirror/extensions';

import PropTypes from 'prop-types';

const extensions = () => [
  new LinkExtension({ autoLink: true }),
  new BoldExtension(),
  new StrikeExtension(),
  new ItalicExtension(),
  new HeadingExtension(),
  new LinkExtension(),
  new BlockquoteExtension(),
  new BulletListExtension({ enableSpine: true }),
  new OrderedListExtension(),
  new ListItemExtension({
    priority: ExtensionPriority.High,
    enableCollapsible: true,
  }),
  new CodeExtension(),
  new TrailingNodeExtension(),
  new TableExtension(),
  new MarkdownExtension({ copyAsMarkdown: false }),
  /**
   * `HardBreakExtension` allows us to create a newline inside paragraphs.
   * e.g. in a list item
   */
  new HardBreakExtension(),
  new ImageExtension({ enableResizing: true }),
];

const toolbarItems = [
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Simple Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleBold',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleItalic',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleStrike',
        display: 'icon',
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'Heading Formatting',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 1 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 2 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 3 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 4 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 5 },
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleHeading',
        display: 'icon',
        attrs: { level: 6 },
      },
    ],
    separator: 'end',
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: 'History',
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'undo',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'redo',
        display: 'icon',
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: 'toggleColumns',
        display: 'icon',
        attrs: { count: 2 },
      },
    ],
    separator: 'none',
  },
];

export function EditorConvertToHTMLActivity({
  input: { onChange, value },
  label,
  disabled,
  meta: { touched, error },
  imageRatio,
}) {
  const { manager, state } = useRemirror({
    selection: 'end',
    stringHandler: 'html',
    extensions,
    content: `${value}`,
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography
          className="text-center"
          style={{
            color: disabled ? '#9E9E9E' : 'none',
            marginBottom: 10,
          }}>
          {label}
        </Typography>
      </Box>
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          onChange={(parameter) => {
            const htmlString = prosemirrorNodeToHtml(parameter.state.doc);
            onChange(htmlString);
          }}>
          <Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />
          <ImageToolbar imageRatio={imageRatio} />
          <EditorComponent />
        </Remirror>
      </ThemeProvider>
      {touched && error ? (
        <Typography variant="caption" style={{ color: '#FF5A52' }}>
          {error}
        </Typography>
      ) : null}
    </>
  );
}

EditorConvertToHTMLActivity.propTypes = {
  disabled: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
};
