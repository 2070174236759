import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field, getFormValues } from 'redux-form';
import { FORM_RULES } from 'helpers/validations';
import { bindActionCreators } from 'redux';
import { InputSwitchGrey } from 'common/components/fields/InputSwitchGrey';
import { ColorPickerInput } from 'common/components/fields/ColorPickerInput';
import { InputFile } from 'common/components/fields/InputFile';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FilterSelect } from 'common/components/fields/FilterSelect';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import _ from 'lodash';
import { ExpansionPanel, ExpansionList } from 'react-md';
import PrimaryButton from 'common/components/buttons/primaryButton';
import Typography from '@material-ui/core/Typography';
import GridMaterial from '@material-ui/core/Grid';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { Box, Grid } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { getList as getSectionsList } from '../../pages/content/websiteSections/websiteSectionsActions';
import { getStationActiveActions } from '../actions/activeActions/activeActionsScripts';
import { ColorOptionsContainer } from './styles';
import ColorPickerPopOver from './components/ColorPick/ColorPickerPopOver';
import AlertDialogSlide from '../../../../common/components/dialog/dialog';
import WebsiteSimulator from './websiteSimulatorEdit';
import {
  getDetail,
  editStation,
  getGoogleFonts as getGoogleFontsAction,
  getGoogleFontsByName as getGoogleFontsByNameAction,
} from './websiteActions';

const lower = (value) => value && value.toLowerCase();

const closeModal = () => {
  return (dispatch) => {
    dispatch({
      type: 'WEBSITE_MODAL',
      payload: false,
    });
  };
};

class WebsiteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.props.getSectionsList(props.auth.user.establishments[0].stations[0].id);
    this.props.getStationActiveActions(props.auth.user.establishments[0].stations[0].id);
    this.props.getDetail(this.props.auth.user.establishments[0].stations[0].id);
    this.props.getGoogleFontsAction();
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleColorChange2 = this.handleColorChange2.bind(this);
    this.handleColorChange3 = this.handleColorChange3.bind(this);
    this.handleColorChange4 = this.handleColorChange4.bind(this);
    this.handleColorChange5 = this.handleColorChange5.bind(this);
    this.handleColorChange6 = this.handleColorChange6.bind(this);
    this.handleColorChange7 = this.handleColorChange7.bind(this);
    this.handleColorChange8 = this.handleColorChange8.bind(this);
    this.handleColorChange9 = this.handleColorChange9.bind(this);
    this.handleColorChange10 = this.handleColorChange10.bind(this);
    this.handleColorChange11 = this.handleColorChange11.bind(this);
    this.handleColorChange12 = this.handleColorChange12.bind(this);
    this.handleColorChange13 = this.handleColorChange13.bind(this);
    this.handleColorChange14 = this.handleColorChange14.bind(this);
    this.handleColorChange15 = this.handleColorChange15.bind(this);
    this.handleColorChange16 = this.handleColorChange16.bind(this);
    this.handleColorChange17 = this.handleColorChange17.bind(this);
    this.handleColorChange18 = this.handleColorChange18.bind(this);
    this.handleColorChange19 = this.handleColorChange19.bind(this);
    this.handleColorChange20 = this.handleColorChange20.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.website.modal) {
      this.setState({ visible: true });
    }
  }

  handleColorChange(color) {
    this.props.change('general_color', color);
  }

  handleColorChange2(color) {
    this.props.change('body_light_color', color);
  }

  handleColorChange3(color) {
    this.props.change('body_dark_color', color);
  }

  handleColorChange4(color) {
    this.props.change('body_dark_font_color', color);
  }

  handleColorChange5(color) {
    this.props.change('body_button_background_color', color);
  }

  handleColorChange6(color) {
    this.props.change('body_button_font_color', color);
  }

  handleColorChange7(color) {
    this.props.change('body_button_border_color', color);
  }

  handleColorChange8(color) {
    this.props.change('signed_in_nav_text_color', color);
  }

  handleColorChange20(color) {
    this.props.change('signed_off_nav_text_color', color);
  }

  handleColorChange9(color) {
    this.props.change('navbar_button_background_color', color);
  }

  handleColorChange10(color) {
    this.props.change('navbar_button_font_color', color);
  }

  handleColorChange11(color) {
    this.props.change('navbar_button_border_color', color);
  }

  handleColorChange12(color) {
    this.props.change('footer_background_color', color);
  }

  handleColorChange13(color) {
    this.props.change('footer_font_color', color);
  }

  handleColorChange14(color) {
    this.props.change('body_link_color', color);
  }

  handleColorChange15(color) {
    this.props.change('navbar_background_color', color);
  }

  handleColorChange16(color) {
    this.props.change('navbar_button_color', color);
  }

  handleColorChange17(color) {
    this.props.change('navbar_title_color', color);
  }

  handleColorChange18(color) {
    this.props.change('signed_off_background_color', color);
  }

  handleColorChange19(color) {
    this.props.change('signed_in_background_color', color);
  }

  openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  renderSimulator = () => {
    const { formValues, websiteSections } = this.props;
    return (
      <WebsiteSimulator
        station={this.props.auth.user.establishments[0].stations[0]}
        websiteSections={websiteSections}
        formValues={!_.isUndefined(formValues) ? { ...formValues } : undefined}
        activeActions={this.props.activeActions}
      />
    );
  };

  onSubmit = async (values) => {
    if (!_.isUndefined(values.header_background_image.base64)) {
      values.header_background_image = {
        base64: imgToBase64(values.header_background_image),
        imageType: imgToImgType(values.header_background_image),
      };
    }
    if (
      values.header_background_image_1 &&
      !_.isUndefined(values.header_background_image_1.base64)
    ) {
      values.header_background_image_1 = {
        base64: imgToBase64(values.header_background_image_1),
        imageType: imgToImgType(values.header_background_image_1),
      };
    }
    if (
      values.header_background_image_2 &&
      !_.isUndefined(values.header_background_image_2.base64)
    ) {
      values.header_background_image_2 = {
        base64: imgToBase64(values.header_background_image_2),
        imageType: imgToImgType(values.header_background_image_2),
      };
    }
    if (
      values.header_background_image_3 &&
      !_.isUndefined(values.header_background_image_3.base64)
    ) {
      values.header_background_image_3 = {
        base64: imgToBase64(values.header_background_image_3),
        imageType: imgToImgType(values.header_background_image_3),
      };
    }
    if (
      values.header_background_image_4 &&
      !_.isUndefined(values.header_background_image_4.base64)
    ) {
      values.header_background_image_4 = {
        base64: imgToBase64(values.header_background_image_4),
        imageType: imgToImgType(values.header_background_image_4),
      };
    }
    if (!_.isUndefined(values.navbar_station_primary_logo.base64)) {
      values.navbar_station_primary_logo = {
        base64: imgToBase64(values.navbar_station_primary_logo),
        imageType: imgToImgType(values.navbar_station_primary_logo),
      };
    }
    if (!_.isUndefined(values.navbar_station_secondary_logo.base64)) {
      values.navbar_station_secondary_logo = {
        base64: imgToBase64(values.navbar_station_secondary_logo),
        imageType: imgToImgType(values.navbar_station_secondary_logo),
      };
    }
    if (!_.isUndefined(values.footer_company_logo.base64)) {
      values.footer_company_logo = {
        base64: imgToBase64(values.footer_company_logo),
        imageType: imgToImgType(values.footer_company_logo),
      };
    }
    if (!_.isUndefined(values.general_favicon_image.base64)) {
      values.general_favicon_image = {
        base64: imgToBase64(values.general_favicon_image),
        imageType: imgToImgType(values.general_favicon_image),
      };
    }
    if (!_.isUndefined(values.header_background_mobile_image.base64)) {
      values.header_background_mobile_image = {
        base64: imgToBase64(values.header_background_mobile_image),
        imageType: imgToImgType(values.header_background_mobile_image),
      };
    }
    if (
      values.header_background_mobile_image_1 &&
      !_.isUndefined(values.header_background_mobile_image_1.base64)
    ) {
      values.header_background_mobile_image_1 = {
        base64: imgToBase64(values.header_background_mobile_image_1),
        imageType: imgToImgType(values.header_background_mobile_image_1),
      };
    }
    if (
      values.header_background_mobile_image_2 &&
      !_.isUndefined(values.header_background_mobile_image_2.base64)
    ) {
      values.header_background_mobile_image_2 = {
        base64: imgToBase64(values.header_background_mobile_image_2),
        imageType: imgToImgType(values.header_background_mobile_image_2),
      };
    }
    if (
      values.header_background_mobile_image_3 &&
      !_.isUndefined(values.header_background_mobile_image_3.base64)
    ) {
      values.header_background_mobile_image_3 = {
        base64: imgToBase64(values.header_background_mobile_image_3),
        imageType: imgToImgType(values.header_background_mobile_image_3),
      };
    }
    if (
      values.header_background_mobile_image_4 &&
      !_.isUndefined(values.header_background_mobile_image_4.base64)
    ) {
      values.header_background_mobile_image_4 = {
        base64: imgToBase64(values.header_background_mobile_image_4),
        imageType: imgToImgType(values.header_background_mobile_image_4),
      };
    }
    if (!_.isUndefined(values.background_back_header.base64)) {
      values.background_back_header = {
        base64: imgToBase64(values.background_back_header),
        imageType: imgToImgType(values.background_back_header),
      };
    }
    if (!_.isUndefined(values.image_download_app.base64)) {
      values.image_download_app = {
        base64: imgToBase64(values.image_download_app),
        imageType: imgToImgType(values.image_download_app),
      };
    }
    if (!_.isUndefined(values.background_auth_station.base64)) {
      values.background_auth_station = {
        base64: imgToBase64(values.background_auth_station),
        imageType: imgToImgType(values.background_auth_station),
      };
    }
    // ANCHOR: DEIXAR O BACKGROUND EM BRANCO
    // if (!_.isUndefined(values.signed_in_background_image.base64)) {
    //   values['signed_in_background_image'] = {
    //     base64: imgToBase64(values.signed_in_background_image),
    //     imageType: imgToImgType(values.signed_in_background_image),
    //   };
    // }

    this.props.editStation(
      values,
      this.props.auth.user.establishments[0].stations[0].id,
      this.props.router
    );
    this.setState({ visible: true });
  };

  render() {
    if (this.props.website.loading) {
      return (
        <Box display="flex" justifyContent="center" height="100vh" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    const { handleSubmit } = this.props;
    return (
      <>
        <Grid
          item
          container
          xs={12}
          style={{
            backgroundColor: '',
          }}
          component="main">
          <Grid item container xs={12} component="section">
            <Form
              role="form"
              onSubmit={handleSubmit(this.onSubmit)}
              noValidate
              style={{
                display: 'flex',
                minWidth: '100%',
              }}>
              <ExpansionList style={{ minWidth: '100%' }}>
                <GridMaterial container spacing={1} justify="flex-start" alignItems="center">
                  <ExpansionPanel
                    defaultExpanded
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Identidade`}
                    footer={<></>}>
                    <GridMaterial container spacing={2} justify="flex-start" alignItems="center">
                      <GridMaterial item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Título do site"
                          name="prettyName"
                          placeholder="Ex: ADVOCACIA SA"
                          validate={[FORM_RULES.required]}
                          disabled
                        />
                      </GridMaterial>
                      <GridMaterial item xs={8}>
                        <Field
                          component={InputLabelOutlined}
                          label="Subdomínio"
                          name="name"
                          normalize={lower}
                          validate={[FORM_RULES.required, FORM_RULES.lowerCase]}
                          disabled
                        />
                      </GridMaterial>
                      <GridMaterial item xs={4}>
                        <Typography variant="body">.rilato.site</Typography>
                      </GridMaterial>
                      <Grid item container xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Informações de rodapé *"
                          name="address"
                          // helperText="Informações de rodapé"
                          placeholder="Informações de rodapé"
                          maxLength={100}
                          validate={[FORM_RULES.required]}
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="E-mail de contato"
                          name="contact_mail"
                          // helperText="E-mail de Contato"
                          placeholder="E-mail de contato"
                          maxLength={100}
                          validate={[FORM_RULES.required, FORM_RULES.email]}
                          // validate={[FORM_RULES.required]}
                        />
                      </Grid>
                      <GeneralGrid item xs={12}>
                        <Box display="flex" justifyContent="center" mb={3}>
                          <Field
                            component={InputSwitchGrey}
                            label={'Cadastro na plataforma'}
                            boxStyle={{
                              backgroundColor: '#f7f7f7',
                              padding: 16,
                              minWidth: '100%',
                            }}
                            firstState="Qualquer visitante poderá se cadastrar."
                            secondState="Se desativada, um visitante só poderá se cadastrar se estiver pré-cadastrado"
                            disabled={this.props.disabled}
                            name="public"
                            // required
                            margin="normal"
                          />
                        </Box>
                      </GeneralGrid>
                    </GridMaterial>
                  </ExpansionPanel>
                  <ExpansionPanel
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Moeda`}
                    footer={<></>}>
                    <GeneralGrid container justify="center">
                      <GridMaterial item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Nome ou símbolo *"
                          name="currency_name"
                          validate={[FORM_RULES.required]}
                        />
                      </GridMaterial>
                    </GeneralGrid>
                    <GeneralGrid container justify="center">
                      <GridMaterial item xs={12}>
                        <Field
                          component={SelectLabel}
                          options={[
                            {
                              label: 'Antes do valor (ex: F$ 10,00)',
                              value: 'before_currency',
                            },
                            {
                              label: 'Depois do valor (ex: 10,00 fan coins)',
                              value: 'after_currency',
                            },
                          ]}
                          name={`currency_name_position`}
                          label="Posição *"
                        />
                      </GridMaterial>
                    </GeneralGrid>
                  </ExpansionPanel>
                  <ExpansionPanel
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Marcas`}
                    footer={<></>}>
                    <Grid container spacing={2} xs={12}>
                      <Grid item container alignItems="center" justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Logo principal'}
                          cols="12 12 12 12"
                          name="navbar_station_primary_logo"
                          validate={[FORM_RULES.required]}
                          maxWidthBool={true}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Logo área logada'}
                          cols="12 12 12 12"
                          name="navbar_station_secondary_logo"
                          validate={[FORM_RULES.required]}
                          maxWidthBool={true}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Logo para rodapé'}
                          cols="12 12 12 12"
                          name="footer_company_logo"
                          validate={[FORM_RULES.required]}
                          maxWidthBool={true}
                        />
                      </Grid>
                      <Grid item container alignItems="center" justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Favicon'}
                          cols="12 12 12 12"
                          name="general_favicon_image"
                          validate={[FORM_RULES.required]}
                          maxWidthBool={true}
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanel>
                  <ExpansionPanel
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Cores`}
                    footer={<></>}>
                    <Grid container xs={12}>
                      <Grid item container alignItems="center" xs={12}>
                        <ColorOptionsContainer>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12} className={'color-select-label'}>
                              Texto
                            </Grid>
                            <Grid item container alignItems="center" justify="" xs={12}>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor para títulos',
                                      name: 'general_color',
                                      onColorChange: this.handleColorChange,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Títulos"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.general_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Texto/Parágrafos',
                                      name: 'body_dark_font_color',
                                      onColorChange: this.handleColorChange4,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Parágrafos"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.body_dark_font_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor para links',
                                      name: 'body_link_color',
                                      onColorChange: this.handleColorChange14,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Links"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.body_link_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ColorOptionsContainer>
                        <ColorOptionsContainer>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12} className={'color-select-label'}>
                              Navegação
                            </Grid>
                            <Grid item container alignItems="center" justify="" xs={12}>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor da fonte de navegação da área logada',
                                      name: 'signed_in_nav_text_color',
                                      onColorChange: this.handleColorChange8,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    labelDisabled={true}
                                    colorPickButtonLabel="Área logada"
                                    currentColor={this.props?.formValues?.signed_in_nav_text_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor da fonte de navegação da área não logada',
                                      name: 'signed_off_nav_text_color',
                                      onColorChange: this.handleColorChange20,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    labelDisabled={true}
                                    colorPickButtonLabel="Área não logada"
                                    currentColor={this.props?.formValues?.signed_off_nav_text_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor da fonte do rodapé',
                                      name: 'footer_font_color',
                                      onColorChange: this.handleColorChange13,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Rodapé"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.footer_font_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2} />
                            </Grid>
                          </Grid>
                        </ColorOptionsContainer>
                        <ColorOptionsContainer>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12} className={'color-select-label'}>
                              Botões
                            </Grid>
                            <Grid item container alignItems="center" justify="" xs={12}>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor do fundo do botão',
                                      name: 'body_button_background_color',
                                      onColorChange: this.handleColorChange5,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Fundo"
                                    labelDisabled={true}
                                    currentColor={
                                      this.props?.formValues?.body_button_background_color
                                    }
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor do texto do botão',
                                      name: 'body_button_font_color',
                                      onColorChange: this.handleColorChange6,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Fonte"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.body_button_font_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor da borda do botão',
                                      name: 'body_button_border_color',
                                      onColorChange: this.handleColorChange7,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Borda"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.body_button_border_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ColorOptionsContainer>
                        <ColorOptionsContainer>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12} className={'color-select-label'}>
                              Fundos (background)
                            </Grid>
                            <Grid item container alignItems="center" justify="" xs={12}>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor de fundo da área deslogada',
                                      name: 'signed_off_background_color',
                                      onColorChange: this.handleColorChange18,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Área pública"
                                    labelDisabled={true}
                                    currentColor={
                                      this.props?.formValues?.signed_off_background_color
                                    }
                                    // disabled={true}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor de fundo da área logada',
                                      name: 'signed_in_background_color',
                                      onColorChange: this.handleColorChange19,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Área logada"
                                    labelDisabled={true}
                                    currentColor={
                                      this.props?.formValues?.signed_in_background_color
                                    }
                                    // disabled={true}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                              <Grid item container justify="center" xs={2}>
                                <Grid item container justify="center" xs={12}>
                                  <ColorPickerPopOver
                                    fieldProps={{
                                      component: ColorPickerInput,
                                      label: 'Cor de fundo do rodapé',
                                      name: 'footer_background_color',
                                      onColorChange: this.handleColorChange12,
                                      cols: '12 12 6 6',
                                      validate: [FORM_RULES.required],
                                    }}
                                    colorPickButtonLabel="Rodapé"
                                    labelDisabled={true}
                                    currentColor={this.props?.formValues?.footer_background_color}
                                  />
                                </Grid>
                                <Grid item container justify="center" xs={12}></Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ColorOptionsContainer>
                      </Grid>
                    </Grid>
                  </ExpansionPanel>
                  <ExpansionPanel
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Fonte`}
                    footer={<></>}>
                    <Grid item container spacing={1} xs={12}>
                      <Grid item container xs={12} md={4}>
                        <Field
                          component={FilterSelect}
                          options={this.props.website.googleFontsData}
                          name={`text_font_family`}
                          label="Fonte de texto"
                          loadingText={'Sem resultados de busca'}
                          noOptionText={'Não encontrado'}
                          currentFontValue={this?.props?.formValues?.text_font_family}
                        />
                        {/* <Typography variant="caption" style={{ color: '#838383' }}>
                          Fonte atual: {this.props?.formValues?.text_font_family}
                        </Typography> */}
                      </Grid>
                      <Grid item container xs={12} md={4}>
                        <Field
                          component={FilterSelect}
                          options={this.props.website.googleFontsData}
                          name={`header_font_family`}
                          label="Fonte de títulos"
                          loadingText={'Sem resultados de busca'}
                          noOptionText={'Não encontrado'}
                          currentFontValue={this?.props?.formValues?.header_font_family}
                        />
                        {/* <Typography variant="caption" style={{ color: '#838383' }}>
                          Fonte atual: {this.props?.formValues?.header_font_family}
                        </Typography> */}
                      </Grid>
                      <Grid item container xs={12} md={4}>
                        <Field
                          component={FilterSelect}
                          options={this.props.website.googleFontsData}
                          name={`nav_font_family`}
                          label="Fonte de itens de navegação"
                          loadingText={'Sem resultados de busca'}
                          noOptionText={'Não encontrado'}
                          currentFontValue={this?.props?.formValues?.nav_font_family}
                        />
                        {/* <Typography variant="caption" style={{ color: '#838383' }}>
                          Fonte atual: {this.props?.formValues?.nav_font_family}
                        </Typography> */}
                      </Grid>
                    </Grid>
                  </ExpansionPanel>
                  <ExpansionPanel
                    style={{
                      marginBottom: 10,
                      width: '100%',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                    label={`Imagens`}
                    footer={<></>}>
                    <Grid container spacing={2} xs={12}>
                      <Grid item container justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Tela de login'}
                          cols="12 12 12 12"
                          name="background_auth_station"
                          validate={[FORM_RULES.required]}
                        />
                      </Grid>
                      <Grid item container justify="center" xs={12}>
                        <Field
                          component={InputFile}
                          label={'Background área logada'}
                          cols="12 12 12 12"
                          name="signed_in_background_image"
                          // validate={[FORM_RULES.required]}
                          // disabled
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanel>
                  <Box display="flex" justifyContent="center">
                    <PrimaryButton
                      type="submit"
                      disabled={this.props.website.buttonLoading}
                      progress={parseInt(this.props.general.submitProgress)}
                      style={{
                        padding: '16px 64px',
                        borderRadius: '6px',
                        textTransform: 'none',
                      }}>
                      {this.props.website.buttonLoading ? 'Editando sua estação' : 'Enviar'}
                    </PrimaryButton>
                  </Box>
                </GridMaterial>
              </ExpansionList>
            </Form>
          </Grid>
          {/* <Grid item container xs={9} component="section">
            {this.renderSimulator()}
          </Grid> */}
          <AlertDialogSlide
            visible={this.state.visible}
            title="Seu Website"
            onClose={() => {
              this.setState({ visible: false });
              this.props.closeModal();
            }}>
            <GridMaterial container justify={'center'}>
              <Typography variant={'subtitle1'}>Pronto, sua estação está no ar!</Typography>{' '}
              <br></br>
              <Typography variant={'subtitle1'}>
                Agora, adicione seus membros na plataforma. Apenas eles poderão se cadastrar.
              </Typography>
              <br></br>
              <Typography variant="subtitle1">Obrigado</Typography>
            </GridMaterial>
            <GridMaterial container spacing={1} justify={'space-between'} alignItems="center">
              <GridMaterial item xs={3}>
                <SecondaryButton
                  onClick={() => this.props.router.push('/owner')}
                  router={this.props.router}
                  type="submit"
                  color="primary">
                  Voltar para a tela inicial
                </SecondaryButton>
              </GridMaterial>
              <GridMaterial item xs={3}>
                <SecondaryButton
                  onClick={() =>
                    window.open(
                      `http://${
                        this.props.formValues
                          ? this.props.formValues.name
                            ? this.props.formValues.name
                            : null
                          : null
                      }.rilato.${
                        process.env.REACT_APP_API_KEY === 'production' ||
                        process.env.REACT_APP_API_KEY === 'production-temp'
                          ? 'site'
                          : process.env.REACT_APP_API_KEY === 'test'
                          ? 'site'
                          : 'site'
                      }`,
                      '_blank'
                    )
                  }
                  router={this.props.router}
                  type="button"
                  color="primary">
                  Visitar sua estação
                </SecondaryButton>
              </GridMaterial>
              {this.props.location.query.first_station ? (
                <GridMaterial item xs={6}>
                  <PrimaryButton
                    onClick={() =>
                      this.props.router.push('/owner/contacts/import?first_contacts=true')
                    }
                    router={this.props.router}
                    type="submit"
                    color="primary">
                    Prosseguir para a importação dos membros
                  </PrimaryButton>
                </GridMaterial>
              ) : null}
            </GridMaterial>
          </AlertDialogSlide>
        </Grid>
      </>
    );
  }
}

WebsiteForm.propTypes = {
  activeActions: PropTypes.any,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  change: PropTypes.func,
  closeModal: PropTypes.func,
  disabled: PropTypes.any,
  editStation: PropTypes.func,
  formValues: PropTypes.shape({
    body_button_background_color: PropTypes.any,
    body_button_border_color: PropTypes.any,
    body_button_font_color: PropTypes.any,
    body_dark_font_color: PropTypes.any,
    body_link_color: PropTypes.any,
    footer_background_color: PropTypes.any,
    footer_font_color: PropTypes.any,
    general_color: PropTypes.any,
    name: PropTypes.any,
    signed_in_background_color: PropTypes.any,
    signed_in_nav_text_color: PropTypes.any,
    signed_off_background_color: PropTypes.any,
    signed_off_nav_text_color: PropTypes.any,
    text_font_family: PropTypes.any,
    header_font_family: PropTypes.any,
    nav_font_family: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.func,
  getGoogleFontsAction: PropTypes.func,
  getSectionsList: PropTypes.func,
  getStationActiveActions: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_station: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  website: PropTypes.shape({
    buttonLoading: PropTypes.any,
    googleFontsData: PropTypes.any,
    loading: PropTypes.any,
    modal: PropTypes.any,
  }),
  websiteSections: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    formValues: getFormValues('websiteFormEdit')(state),
    general: state.general,
    websiteSections: state.websiteSections,
    activeActions: state.activeActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      editStation,
      closeModal,
      getSectionsList,
      getStationActiveActions,
      getGoogleFontsAction,
      getGoogleFontsByNameAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'websiteFormEdit' })(WebsiteForm));
