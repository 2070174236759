import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { appColors } from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${appColors.primary}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    bottom: '42%',
    right: '42%',
  },
}));

export default function CustomizedModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={props.handleClose}>
        <div className={classes.paper}>{props.children}</div>
      </Modal>
    </div>
  );
}

CustomizedModal.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};
