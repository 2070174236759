import PropTypes from 'prop-types';
import { Box, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { USER_TOKEN } from 'config/consts';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { validateToken } from '../../../../../pages/auth/authActions';
import changeAccess from './accessActions';

function AccessControl(props) {
  const {
    classified_ads_access,
    feed_access,
    events_access,
    files_access,
    my_account_access,
    checkout_access,
  } = props.auth.user.establishments[0].stations[0];

  useEffect(() => {
    console.log(checkout_access);
    setItemState({
      ...itemState,
      classified_ads_access: {
        ...itemState.classified_ads_access,
        value: classified_ads_access,
      },
      feed_access: {
        ...itemState.feed_access,
        value: feed_access,
      },
      events_access: {
        ...itemState.events_access,
        value: events_access,
      },
      files_access: {
        ...itemState.files_access,
        value: files_access,
      },
      my_account_access: {
        ...itemState.my_account_access,
        value: my_account_access,
      },
      checkout_access: {
        ...itemState.checkout_access,
        value: checkout_access,
      },
    });
  }, [
    classified_ads_access,
    feed_access,
    events_access,
    files_access,
    my_account_access,
    checkout_access,
  ]);

  const [loading, setLoading] = useState(false);

  const [itemState, setItemState] = useState({
    classified_ads_access: {
      value: classified_ads_access,
      isOpen: false,
      label: 'Classificados',
      name: 'classified_ads_access',
    },
    feed_access: {
      value: feed_access,
      isOpen: false,
      label: 'Mural',
      name: 'feed_access',
    },
    events_access: {
      value: events_access,
      isOpen: false,
      label: 'Eventos',
      name: 'events_access',
    },
    files_access: {
      value: files_access,
      isOpen: false,
      label: 'Arquivos',
      name: 'files_access',
    },
    my_account_access: {
      value: my_account_access,
      isOpen: false,
      label: 'Minha Conta',
      name: 'my_account_access',
    },
    checkout_access: {
      value: checkout_access,
      isOpen: false,
      label: 'Carrinho',
      name: 'checkout_access',
    },
  });

  function successCleanup(item) {
    setLoading(false);
    setItemState({
      ...itemState,
      [item?.name]: { ...itemState[item?.name], isOpen: false },
    });
    props.validateToken(`${JSON.parse(localStorage.getItem(USER_TOKEN))}`, props.auth.user.id);
  }

  function errorCleanup() {
    setLoading(false);
    props.validateToken(`${JSON.parse(localStorage.getItem(USER_TOKEN))}`, props.auth.user.id);
  }

  function renderItems() {
    return Object.values(itemState).map((item) => (
      <Box
        key={item.name}
        style={{
          background: 'white',
          width: '100%',
          padding: 20,
          marginTop: 20,
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box>{item.label}</Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Switch
              color="primary"
              checked={item.value}
              onChange={() => {
                setItemState((state) => ({
                  ...state,
                  [item.name]: {
                    ...state[item.name],
                    isOpen: true,
                  },
                }));
              }}
            />
          </Box>
        </Box>
      </Box>
    ));
  }

  const itemOnModal = useMemo(() => {
    return Object.values(itemState).find((item) => item.isOpen);
  }, [itemState]);

  return (
    <section id="access-control">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Box display="flex" flexDirection="column">
            {renderItems()}
          </Box>
        </GeneralGrid>
      </GeneralGrid>
      <AlertDialogSlide
        hiddenTitle
        maxWidth="sm"
        visible={itemOnModal?.isOpen}
        onClose={() =>
          setItemState({
            ...itemState,
            [item.name]: {
              ...itemState[item.name],
              isOpen: false,
            },
          })
        }>
        <DialogTitle id="alert-dialog-slide-title">{itemOnModal?.label}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer mudar o controle de acesso de {itemOnModal?.label}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setItemState({
                ...itemState,
                [itemOnModal?.name]: {
                  ...itemState[itemOnModal?.name],
                  isOpen: false,
                },
              })
            }
            color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setLoading(true);
              changeAccess(
                {
                  ...itemState,
                  [itemOnModal?.name]: {
                    ...itemState[itemOnModal?.name],
                    value: !itemOnModal?.value,
                  },
                },
                props.auth.user.establishments[0].stations[0].id,
                () => successCleanup(itemOnModal),
                errorCleanup
              );
            }}
            color="primary">
            {loading ? 'Ativando' : 'Confirmar'}
          </Button>
        </DialogActions>
      </AlertDialogSlide>
    </section>
  );
}

AccessControl.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  validateToken: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ validateToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);
