import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { String2Currency } from 'helpers/dataTransformers';
import moment from 'moment';
import types from './type';

const URL_STATEMENT_BUY_POINTS = `${BASE_API}/establishments/buy-action-transactions`;

const getTransactionsByEstablishmentId = (id) => (dispatch) => {
  dispatch([
    {
      type: types.BUY_POINTS_TRANSACTIONS_LOAD,
      payload: true,
    },
  ]);
  axios
    .get(`${URL_STATEMENT_BUY_POINTS}/${id}`, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    })
    .then((response) => {
      response.data.forEach((i) => {
        i.createdAt = moment(i.createdAt).format('DD/MM/YYYY HH:mm');
        i.points = String2Currency(i.points);
      });
      dispatch([
        {
          type: types.BUY_POINTS_TRANSACTIONS_FETCHED,
          payload: response.data,
        },
      ]);
    })
    .catch((e) => {
      try {
        if (e.response.data[0]) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data[0]}`,
              variant: 'error',
              open: true,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: 'SNACKBAR',
          payload: { message: 'Erro', variant: 'error', open: true },
        });
      }
    });
};

export default getTransactionsByEstablishmentId;
