import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL_ACTION_PAGE = `${BASE_API}/research-results-user`;

const getUserIndividualAnswers = (userId, actionId) => {
  return (dispatch) => {
    dispatch({ type: 'USER_INDIVIDUAL_ANSWERS_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/${actionId}/${userId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'USER_INDIVIDUAL_ANSWERS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export default getUserIndividualAnswers;
