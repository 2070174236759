import React from 'react';
import { Route, IndexRoute } from 'react-router';
import WebsiteSections from './websiteSections';
import WebsiteSectionsForm from './websiteSectionsForm';

export default (
  <Route path="secoes">
    <IndexRoute component={(props) => <WebsiteSections {...props} />} />
    <Route path="novo" component={(props) => <WebsiteSectionsForm {...props} />} />
    <Route path=":id/:action" component={(props) => <WebsiteSectionsForm {...props} />} />
  </Route>
);
