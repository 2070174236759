import React, { Component } from 'react';
import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './mail.css';

class Mail extends Component {
  render() {
    return (
      <section className="modules">
        <div className="container-fluid">
          <div>
            <h1>react-email-editor Demo</h1>
            <div>
              <button onClick={() => {}}>Export HTML</button>
            </div>
            <EmailEditor projectId={2262} ref={(editor) => (this.editor = editor)} />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Mail);
