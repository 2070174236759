import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from 'common/components/progress/loading';
import { loginToken } from '../authActions';

class LoginToken extends Component {
  componentDidMount() {
    const { router } = this.props;
    // console.log(router.params.hash, router.params.email)
    this.props.loginToken(router.params.token, this.props.router);
  }

  render() {
    return (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    );
  }
}

LoginToken.propTypes = {
  loginToken: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginToken);
