import React from 'react';
import { Route, IndexRoute } from 'react-router';
import GroupingsForm from './groupingsForm';
import Grouping from './index';

export default (
  <Route path="groupings">
    <IndexRoute component={(props) => <Grouping {...props} />} />
    <Route path="editar/:grouping_id" component={(props) => <GroupingsForm {...props} />} />
    <Route path="criar" component={(props) => <GroupingsForm {...props} />} />
  </Route>
);
