import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { MaterialCheckbox } from 'common/components/fields/MaterialCheckbox';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Answers from 'common/components/quiz/Answers';
import Question from 'common/components/quiz/Questions';
import _ from 'lodash';
import { getList as getCategoriesList } from 'modules/owner/pages/categories/categoriesActions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { isMobile } from 'react-device-detect';
import { Container } from './styles';

let RightAnswerQuiz = function (props) {
  const onSubmit = (values) => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    props.onClickProgress(values);
  };

  const renderOptions = (props) => (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton onClick={() => props.fields.push()}>Adicionar opção</PrimaryButton>
        </Box>
      </li>
      {props.fields.map((option, index) => (
        <li key={index}>
          <GeneralGrid container style={{ marginBottom: 8 }}>
            <GeneralGrid item xs={6}>
              <Box display="flex" alignContent="center">
                <Field
                  component={Answers}
                  label={`Opção ${index + 1}`}
                  name={`${option}.label`}
                  required
                  margin="normal"
                />
                <FontAwesomeIcon
                  style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                  icon={faTrash}
                  onClick={() => props.fields.remove(index)}
                />
              </Box>
            </GeneralGrid>
            <GeneralGrid item xs={6}>
              <Box display="flex" alignContent="center" justifyContent="center" height="100%">
                <Field
                  component={MaterialCheckbox}
                  label={`Esta é a resposta correta?`}
                  name={`${option}.correct`}
                />
              </Box>
            </GeneralGrid>
          </GeneralGrid>
        </li>
      ))}
    </ul>
  );

  const renderQuestions = (props) => {
    return (
      <ul style={{ listStyleType: 'none' }}>
        <li>
          <Box>
            <PrimaryButton onClick={() => props.fields.push()}>Adicionar pergunta</PrimaryButton>
          </Box>
        </li>
        {props.fields.map((question, index) => (
          <li key={index}>
            <Box>
              <SecondaryButton onClick={() => props.fields.remove(index)} color="primary">
                Excluir Pergunta
              </SecondaryButton>
            </Box>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12} sm={12}>
                <Field
                  component={Question}
                  label={`Pergunta ${index + 1}`}
                  name={`${question}.question`}
                  // validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
                {props.meta.submitFailed && props.meta.error && (
                  <Typography color="error" variant="caption">
                    {props.meta.error}
                  </Typography>
                )}
              </GeneralGrid>

              <GeneralGrid item xs={12} sm={12}>
                <FieldArray name={`${question}.options`} component={renderOptions} />
              </GeneralGrid>
            </GeneralGrid>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}>
          <Container>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12} sm={12}>
                <h4 style={{ fontWeight: 'bold' }}>Configure suas perguntas:</h4>
              </GeneralGrid>
            </GeneralGrid>
            {_.isEmpty(props.formValues.questions) && (
              <p style={{ textAlign: 'center' }}>Ainda não há perguntas. Adicione algumas!</p>
            )}

            <FieldArray
              name="questions"
              component={renderQuestions}
              formValues={props.formValues}
              // validate={[FORM_RULES.questions]}
              parentProps={props}
            />
          </Container>
          <GeneralGrid item xs={12} style={{ padding: 10, margin: '5px 0px 15px 0px' }}>
            <Field
              component={InputSwitchLarge}
              label={'Resposta errada conta como participação?'}
              name="error_count_as_participation"
              required
              margin="normal"
              disabled={props.router.params.action === 'proposta'}
            />
          </GeneralGrid>
          <GenericConfirmationCard
            open={props.openConfirmation}
            cancelClose={props.handleCancelClose}
            maxWidthFixed
            labelLeftButton={'Continuar aqui'}
            labelRightButton={props.buttonConfirmationLabel}
            general={props.general}
            confirmButtonColor={'primary'}
            title={props.confirmationTitle}
            text={props.confirmationSubtitle}
            onClose={() => props.setOpenConfirmation(false)}
            onClick={props.closeModal}
          />
        </div>
        <GeneralGrid
          container
          display="flex"
          justify="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}>
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}>
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            color="primary"
            disabled={props.activeActions.buttonLoading}
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}>
            Salvar e continuar
          </PrimaryButton>
        </GeneralGrid>
      </Form>
    </>
  );
};

RightAnswerQuiz.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.any,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
  }),
  formValues: PropTypes.shape({
    questions: PropTypes.any,
  }),
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.any,
    submitFailed: PropTypes.any,
  }),
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setOpenConfirmation: PropTypes.func,
};

RightAnswerQuiz = reduxForm({
  form: 'rightAnswerForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(RightAnswerQuiz);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('rightAnswerForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RightAnswerQuiz);
