import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Settings } from 'react-feather';
import { isMobile } from 'react-device-detect';
import CardIcon from '../../../../assets/editActions/cardIcon.svg';
import AlignLeft from '../../../../assets/editActions/alignLeft.svg';
import MedalIcon from '../../../../assets/editActions/medal.svg';
import ProductIcon from '../../../../assets/editActions/product.svg';
import TicketIcon from '../../../../assets/editActions/ticket.svg';
import ClickIcon from '../../../../assets/editActions/click.svg';
import CheckIcon from '../../../../assets/editActions/check.svg';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    marginLeft: -14,
    marginRight: -14,
  },
  AppBar: {
    background: 'white',
    boxShadow: 'none',
    marginLeft: -14,
    marginRight: -14,
  },
  tab: {
    color: 'black',
  },
  active: {
    backgroundColor: 'white',
  },
}));

const ActionsTabsForm = (props) => {
  const classes = useStyles();

  const [clickedFromArrow, setClickedFromArrow] = React.useState(false);

  function submit(values) {
    if (!clickedFromArrow) return;
    if (props.activeTab === 1) {
      if (values.results) {
        for (let i = 0; i < values.results.length; i++) {
          if (values.results[i].feedback_lottie_id === 'none') {
            values.results[i].feedback_lottie_id = null;
          }
        }
      }
    } else if (props.activeTab === 2) {
      if (!values.isPaid) {
        values.points = 0.0;
      }
      if (values.feedback_lottie_id === 'none') {
        values.feedback_lottie_id = null;
      }

      if (values.type_action_id === 1 || values.type_action_id === 19) {
        if (!values.isInviterPaid) {
          values.points_inviter = 0.0;
        }
        if (!values.isInvitedPaid) {
          values.points_invited = 0.0;
        }
      }
    }

    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = false;
    props.onClickProgress(values);
    setClickedFromArrow(false);
  }
  return (
    <form style={{ width: '100%' }} onSubmit={props.handleSubmit(submit)}>
      <Box
        style={{
          ...props.styles,
          padding: isMobile ? '15px' : '25px',
          borderBottom: '3px solid #F4F4F4',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%">
        {props.activeTab === 0 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              {props.icons && props.icons[0] === 0 ? (
                <div style={{ width: 23, height: 23 }}>
                  <img src={CardIcon} alt="card icon" style={{ width: '100%', height: 'auto' }} />
                </div>
              ) : props.icons && props.icons[0] === 1 ? (
                <div style={{ width: 23, height: 23 }}>
                  <img
                    src={AlignLeft}
                    alt="align left icon"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              ) : (
                <Settings style={{ fontSize: 23 }} />
              )}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '18px' : '24px',
                  lineHeight: isMobile ? '22px' : '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 15,
                }}>
                {props.sectionTitles && props.sectionTitles[0] === 0
                  ? 'Apresentação'
                  : props.sectionTitles && props.sectionTitles[0] === 1
                  ? 'Conteúdo'
                  : 'Apresentação'}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center">
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}>
                1 de {`${props.tabs.length}`}
              </Typography>

              <button type="button">
                <ChevronLeft style={{ fontSize: 23, color: 'gray' }} />
              </button>
              <button type="submit" onClick={() => setClickedFromArrow(true)}>
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : props.activeTab === 1 && props.tabs.length === 4 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              {props.icons && props.icons[1] === 0 ? (
                <div style={{ width: 23, height: 23 }}>
                  <img src={ProductIcon} alt="product" style={{ width: '100%', height: 'auto' }} />
                </div>
              ) : props.icons && props.icons[1] === 1 ? (
                <div style={{ width: 23, height: 23 }}>
                  <img src={TicketIcon} alt="ticket" style={{ width: '100%', height: 'auto' }} />
                </div>
              ) : (
                <div style={{ width: 23, height: 23 }}>
                  <img
                    src={ClickIcon}
                    alt="interaction"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              )}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 15,
                }}>
                {props.sectionTitles && props.sectionTitles[1] === 0
                  ? 'Produto'
                  : props.sectionTitles && props.sectionTitles[1] === 1
                  ? 'Interação'
                  : 'Interação'}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center">
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}>
                2 de {`${props.tabs.length}`}
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>
              <button type="submit" onClick={() => setClickedFromArrow(true)}>
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : (props.activeTab === 2 && props.tabs.length === 4) ||
          (props.activeTab === 1 && props.tabs.length === 3) ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              {props.icons && props.icons[2] === 0 ? (
                <div style={{ width: 23, height: 23 }}>
                  <img src={MedalIcon} alt="prize" style={{ width: '100%', height: 'auto' }} />
                </div>
              ) : (
                <div style={{ width: 23, height: 23 }}>
                  <img src={CheckIcon} alt="check" style={{ width: '100%', height: 'auto' }} />
                </div>
              )}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 15,
                }}>
                {props.sectionTitles && props.sectionTitles[2] === 0 ? 'Feedback' : 'Recompensas'}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center">
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}>
                {props.tabs.length === 4 ? 3 : 2} de {`${props.tabs.length}`}
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>
              <button type="submit" onClick={() => setClickedFromArrow(true)}>
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : (props.activeTab === 3 && props.tabs.length === 4) ||
          (props.activeTab === 2 && props.tabs.length === 3) ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              {props.icons && props.icons[3] === 0 ? (
                <Settings size={22} />
              ) : (
                <Settings size={22} />
              )}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 15,
                }}>
                {props.sectionTitles && props.sectionTitles[3] === 0 ? 'Publicação' : 'Publicação'}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center">
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}>
                {props.tabs.length === 4 ? 4 : 3} de {`${props.tabs.length}`}
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>

              <button type="button">
                <ChevronRight style={{ fontSize: 23, color: 'gray' }} />
              </button>
            </Box>
          </Box>
        ) : null}
      </Box>

      <div className={classes.root}>
        <div
          style={{
            backgroundColor: 'white',
            minHeight: '100vh',
            padding: '0px 20px',
          }}>
          {props.tabs.map(
            (item, index) =>
              props.activeTab === index && <TabContainer key={item.id}>{item.content}</TabContainer>
          )}
        </div>
      </div>
    </form>
  );
};

ActionsTabsForm.propTypes = {
  activeTab: PropTypes.number,
  goBack: PropTypes.any,
  handleSubmit: PropTypes.func,
  icons: PropTypes.any,
  onClickProgress: PropTypes.func,
  sectionTitles: PropTypes.any,
  styles: PropTypes.any,
  tabs: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
};

const ActionsTabs = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ActionsTabsForm);

const mapStateToProps = (state, { form }) => {
  return {
    formValues: getFormValues(form)(state),
    form,
  };
};

export default connect(mapStateToProps)(ActionsTabs);
