import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GeneralFeedback from './feedback/generalFeedback';
import NewQuizesFeedback from './feedback/newQuizesFeedback';
import CupomFeedback from './feedback/cupomFeedback';
import PresentialCourseFeedback from './feedback/presentialCourseFeedback';
import ResearchInteraction from './interaction/researchInteraction';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    backgroundColor: '#ffffff',
  },
  appBar: {
    margin: '0px 0 47px 0',
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid lightgray',
  },
}));

function ActivityTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    setValue(0);
  }, [props.step]);
  return (
    <>
      {(props.step === 2 && props.formValues.type_id === 16) || props.step !== 2 ? (
        props.step === 1 && (props.formValues.type_id === 8 || props.formValues.type_id === 6) ? ( // se for pesquisa a interação é diferente
          <div className={classes.root}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}>
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 220px)',
                  overflowX: 'hidden',
                  paddingRight: 15,
                }}>
                <ResearchInteraction {...props} />
              </TabPanel>
            </SwipeableViews>
            {/* </div>
        ) : props.step === 1 && props.formValues.type_id === 18 ? (
          //interacao especial para atividade de vídeo
          <div className={classes.root}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 220px)',
                  overflowX: 'hidden',
                  paddingRight: 15,
                }}
              >
                <VideoPlaylistInteraction {...props} />
              </TabPanel>
            </SwipeableViews> */}
          </div>
        ) : (
          // se for qualquer outra, interação é igual
          <div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example">
                <Tab label="Atividade" {...a11yProps(0)} />
                <Tab label="Card" {...a11yProps(1)} />
                {(props.formValues.type_id === 9 ||
                  props.formValues.type_id === 10 ||
                  props.formValues.type_id === 11 ||
                  props.formValues.type_id === 12) && <Tab label="Interação" {...a11yProps(2)} />}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}>
              <TabPanel
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 320px)',
                  overflowX: 'hidden',
                  paddingRight: 15,
                }}
                value={value}
                index={0}
                dir={theme.direction}>
                {props.activity}
              </TabPanel>
              <TabPanel
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 320px)',
                  overflowX: 'hidden',
                }}
                value={value}
                index={1}
                dir={theme.direction}>
                {props.card}
              </TabPanel>
              {props.formValues.type_id === 9 ||
              props.formValues.type_id === 10 ||
              props.formValues.type_id === 11 ||
              props.formValues.type_id === 12 ? (
                <TabPanel
                  value={value}
                  index={2}
                  dir={theme.direction}
                  style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 320px)',
                    overflowX: 'hidden',
                    paddingRight: 15,
                  }}>
                  {props.interaction}
                </TabPanel>
              ) : (
                <div />
              )}
            </SwipeableViews>
          </div>
        )
      ) : props.step === 2 && // Se for a etapa de recompensa
        props.formValues.type_id !== 1 && // Se não for MGM
        props.formValues.type_id !== 2 && // Se não for Cadastre e ganhe
        props.formValues.type_id !== 3 && // Se não for Compre e ganhe *
        props.formValues.type_id !== 4 && // Se não for Compre e ganhe *
        props.formValues.type_id !== 9 && // Se não for ChecklistQuiz *
        props.formValues.type_id !== 10 && // Se não for Pesquisa de opnião *
        props.formValues.type_id !== 11 && // Se não for Quiz resposta certa *
        props.formValues.type_id !== 12 && // Se não for Personalidade *
        props.formValues.type_id !== 13 && // Se não for Redirecionar link
        props.formValues.type_id !== 16 ? ( // Se não for Post sinmples
        <GeneralFeedback {...props} />
      ) : props.formValues.type_id === 3 ? ( // Cupom
        <CupomFeedback {...props} />
      ) : props.formValues.type_id === 4 ? ( // Atividade presencial
        <PresentialCourseFeedback {...props} />
      ) : props.formValues.type_id === 9 ||
        props.formValues.type_id === 10 ||
        props.formValues.type_id === 11 ||
        props.formValues.type_id === 12 ? (
        <NewQuizesFeedback {...props} />
      ) : props.formValues.type_id === 1 ? (
        <div className={classes.root}>
          <AppBar className={classes.appBar} position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example">
              <Tab label="Atividade" {...a11yProps(0)} />
              <Tab label="Card" {...a11yProps(1)} />
              {(props.formValues.type_id === 9 ||
                props.formValues.type_id === 10 ||
                props.formValues.type_id === 11 ||
                props.formValues.type_id === 12) && <Tab label="Interação" {...a11yProps(2)} />}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel
              style={{
                overflowY: 'scroll',
                height: 'calc(100vh - 320px)',
                overflowX: 'hidden',
                paddingRight: 15,
              }}
              value={value}
              index={0}
              dir={theme.direction}>
              {props.activity}
            </TabPanel>
            <TabPanel
              style={{
                overflowY: 'scroll',
                height: 'calc(100vh - 320px)',
                overflowX: 'hidden',
              }}
              value={value}
              index={1}
              dir={theme.direction}>
              {props.card}
            </TabPanel>
          </SwipeableViews>
        </div>
      ) : null}
    </>
  );
}

ActivityTabs.propTypes = {
  activity: PropTypes.any,
  card: PropTypes.any,
  formValues: PropTypes.shape({
    type_id: PropTypes.number,
  }),
  interaction: PropTypes.any,
  step: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    lotties: state.lotties,
    auth: state.auth,
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTabs);
