import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  box-shadow: 5px 5px 20px lightgrey;
  padding: 24px;
  margin: 0 16px 16px 16px;
  min-height: 240px;
  min-width: 350px;
  max-width: ${(props) => (props.mgm ? '500px' : '350px')};
  border-radius: 6px;
`;

export const ContainerShare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  box-shadow: 5px 5px 20px lightgrey;
  padding: 24px;
  margin: 0 16px 16px 16px;
  min-height: 240px;
  min-width: 380px;
  max-width: 600px;
  border-radius: 6px;
`;
