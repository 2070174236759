import styled from 'styled-components';

export const ColorOptionsContainer = styled.div`
  background-color: #f7f7f7;
  padding: 16px;
  min-width: 100%;
  margin: 0.3rem 0;
`;

export const ColorPickerButtonLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`;
