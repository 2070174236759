import { Button, Dialog, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { isMobile } from 'react-device-detect';
import EnhancedTable from 'common/components/table/table-material';
import moment from 'moment';
import VoucherModal from '../VoucherModal';

function UserDetails({ open, data, onClose, actionType }) {
  const [show, setShow] = useState(false);
  const [displayData, setDisplayData] = useState();

  const columns = [
    { field: 'presential_course_id', title: 'ID da atividade' },
    {
      field: 'createdAt',
      title: 'Data de participação',
      render: (rowData) => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: '',
      title: 'Visualizar ação',
      render: (rowData) => selectButton(actionType, rowData),
    },
  ];

  function selectButton(type, rowData) {
    switch (type) {
      case 4:
        return (
          <Button
            variant="contained"
            onClick={() => {
              setDisplayData(rowData);
              setShow(true);
            }}>
            Ver voucher
          </Button>
        );

      default:
        return '';
    }
  }

  const { email, name, quantity, presential_course_subscriptions } = data;

  if (show) {
    switch (actionType) {
      case 4:
        return <VoucherModal item={displayData} open onClose={() => setShow(false)} />;
    }
  }

  return (
    <AlertDialogSlide
      visible={open}
      onClose={onClose}
      maxWidth={isMobile ? 'xl' : 'sm'}
      title={<Typography variant="h6">Detalhes de {name}</Typography>}>
      <Typography>Email: {email}</Typography>
      <Typography>Visualizações: {quantity}</Typography>
      {presential_course_subscriptions ? (
        <EnhancedTable
          rows={presential_course_subscriptions}
          columns={columns}
          hasToolBar={false}
        />
      ) : null}
    </AlertDialogSlide>
  );
}

UserDetails.propTypes = {
  /** Estado do modal */
  open: PropTypes.func,
  /** Função disparada ao fechar o modal */
  handleClose: PropTypes.func,

  /** Data vinda do actionResults.usersReached */
  data: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    quantity: PropTypes.number,
    user_actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        user_id: PropTypes.number,
        action_id: PropTypes.number,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  /** Tipo de ação no qual o modal está sendo utilizado
   * @todo Adicionar todos os tipos de ações
   */
  actionType: PropTypes.number,
};

export default UserDetails;
