import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { isMobile } from 'react-device-detect';
import { appColors } from '../../../styles/colors';

const useStyles = makeStyles((theme) => {
  return {
    fab: {
      margin: theme.spacing(1),
      position: 'absolute',
      bottom: isMobile ? 120 : theme.spacing(2),
      right: theme.spacing(2),
      background: appColors.primary,
      color: appColors.light.level1,
      '&:hover': {
        background: appColors.primaryHover,
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

export default function AddButton(props) {
  const classes = useStyles();
  return (
    <div>
      <Fab aria-label="add" className={classes.fab} {...props}>
        <AddIcon />
      </Fab>
    </div>
  );
}
