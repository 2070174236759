import React from 'react';
import Grid from '../layout/grid/grid';
import PropTypes from 'prop-types';

export class InputLabelNatural extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className="md-text-field-container md-full-width md-text-field-container--input md-cell md-cell--bottom">
            <input
              {...this.props.input}
              id={this.props.id}
              className={this.props.className}
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly}
              type={this.props.type}
              name={this.props.name}
            />
            <hr className="md-divider md-divider--text-field md-divider--expand-from-center" />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

InputLabelNatural.propTypes = {
  className: PropTypes.any,
  cols: PropTypes.any,
  id: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.any,
  readOnly: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.any,
};
