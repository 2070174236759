import { Popover, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import ColorPickPopOverButton from './styles';

function ColorPickerPopOver(props) {
  const {
    fieldProps: { label, component, name, onColorChange, cols, validate },
    colorPickButtonLabel,
    currentColor,
    disabled,
    labelDisabled,
  } = props;
  const [anchorElPopOver, setAnchorElPopOver] = useState(null);
  const [openPopOver, setOpenPopOver] = useState(null);
  const id = openPopOver ? 'color-pick-popover' : undefined;

  function handleOpenPopOverClick(event) {
    event.preventDefault();
    setOpenPopOver(true);
    setAnchorElPopOver(event.currentTarget);
  }

  function handleClosePopOver() {
    setOpenPopOver(null);
    setAnchorElPopOver(null);
  }

  return (
    <>
      <ColorPickPopOverButton
        aria-describedby={id}
        onClick={disabled ? (e) => e.preventDefault() : (event) => handleOpenPopOverClick(event)}
        currentColor={currentColor}>
        {colorPickButtonLabel}
      </ColorPickPopOverButton>
      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorElPopOver}
        onClose={() => handleClosePopOver()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          // marginBottom: 0,
        }}>
        <Grid
          container
          xs={12}
          justifyContent="flex-end"
          style={{
            backgroundColor: currentColor,
          }}>
          <CloseIcon
            onClick={() => handleClosePopOver()}
            style={{
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            onHover={{
              backgroundColor: 'red',
            }}
            fontSize="medium"
          />
        </Grid>
        <Field
          component={component}
          label={label}
          name={name}
          onColorChange={onColorChange}
          cols={cols}
          validate={validate}
          labelDisabled={labelDisabled}
        />
      </Popover>
    </>
  );
}

ColorPickerPopOver.propTypes = {
  actualColor: PropTypes.string.isRequired,
  colorPickButtonLabel: PropTypes.string.isRequired,
  currentColor: PropTypes.any,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.object.isRequired,
  labelDisabled: PropTypes.any,
};

export default ColorPickerPopOver;
