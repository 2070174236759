import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getUserIndividualAnswers from './userAnswersActions';

function ActionOffers(props) {
  useEffect(() => {
    if (props.userId && props.actionId) {
      props.getUserIndividualAnswers(props.userId, props.actionId);
    }
  }, []);

  if (props.userAnswers.loading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    );
  }
  return (
    <section>
      {props.userAnswers.list.map((item, index) => (
        <Box style={{ margin: 20 }} key={index}>
          <Typography style={{ marginBottom: 20 }} variant="h6">
            {item.question}
          </Typography>
          {item.type_id === 1 ? (
            <>
              <Table6
                columns={[{ field: 'question_option.label', title: 'Resposta' }]}
                data={item.Answers}
              />
            </>
          ) : item.type_id === 2 ? (
            <>
              <Table6
                columns={[{ field: 'question_option.label', title: 'Respostas' }]}
                data={item.Answers}
              />
            </>
          ) : item.type_id === 3 ? (
            <>
              <Table6 columns={[{ field: 'answer', title: 'Respostas' }]} data={item.Answers} />
            </>
          ) : item.type_id === 4 ? (
            <>
              <Table6
                columns={[
                  {
                    field: 'question_option.label',
                    title: 'Resposta',
                    render: (rowData) =>
                      rowData.answer ? `(Outros) ${rowData.answer}` : rowData.question_option.label,
                  },
                ]}
                data={item.Answers}
              />
            </>
          ) : item.type_id === 5 ? (
            <>
              <Table6
                columns={[
                  {
                    field: 'question_option.label',
                    title: 'Respostas',
                    render: (rowData) =>
                      rowData.answer ? `(Outros) ${rowData.answer}` : rowData.question_option.label,
                  },
                ]}
                data={item.Answers}
              />
            </>
          ) : (
            <>
              <Table6
                columns={[
                  {
                    field: 'question_option.label',
                    title: 'Respostas',
                    render: (rowData) =>
                      rowData.answer ? `(Outros) ${rowData.answer}` : rowData.question_option.label,
                  },
                ]}
                data={item.Answers}
              />
            </>
          )}
        </Box>
      ))}
    </section>
  );
}

ActionOffers.propTypes = {
  actionId: PropTypes.any,
  getUserIndividualAnswers: PropTypes.func,
  userAnswers: PropTypes.shape({
    list: PropTypes.shape({
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  userId: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    userAnswers: state.userAnswers,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserIndividualAnswers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionOffers);
