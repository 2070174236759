import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import MaterialGrid from '@material-ui/core/Grid';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import { Field, reduxForm, getFormValues, Form, FieldArray } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { connect } from 'react-redux';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { BRB_FLA_STATION_ID } from 'config/consts';
import { getGiveawayWinners, initializeDrawForm, drawWinners } from './giveawaysActions';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import Loading from '../../participants/components/Loading';
import LoadingProgress from 'common/components/progress/loading';

const POSITIONS_LIST = [
  {
    value: 1,
    label: 'Unidades',
  },
  {
    value: 2,
    label: 'Dezenas',
  },
  {
    value: 3,
    label: 'Centenas',
  },
  {
    value: 4,
    label: 'Milhares',
  },
  {
    value: 5,
    label: 'Dezenaz de milhares',
  },
];

const SERIES_NUMBER_LENGTH = [
  {
    value: 1,
    label: '1 dígito',
  },
  {
    value: 2,
    label: '2 dígitos',
  },
  {
    value: 3,
    label: '3 dígitos',
  },
  {
    value: 4,
    label: '4 dígitos',
  },
];

const WINNABLE_TIMES_OPTIONS = [
  {
    value: 1,
    label: '1 vez',
  },
  {
    value: 2,
    label: '2 vezes',
  },
  {
    value: 3,
    label: '3 vezes',
  },
  {
    value: 4,
    label: '4 vezes',
  },
  {
    value: 0,
    label: 'Ilimitado',
  },
];

function calculateNumberLabel(number) {
  let stringNumber = number.toString();

  if (10 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (100 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (1000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (10000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (100000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  if (1000000 / number > 1) {
    stringNumber = ['0', ...stringNumber];
  }
  return stringNumber;
}

const renderTickets = (props) => {
  return (
    <>
      <Box style={{ padding: 12 }}>
        <PrimaryButton
          style={{ margin: 0 }}
          onClick={() => props.fields.length < 5 && props.fields.push()}>
          Adicionar bilhete
        </PrimaryButton>
      </Box>
      {props.fields.map((item, index) => (
        <MaterialGrid key={index} item xs={12} style={{ display: 'flex' }} alignItems="center">
          <Field
            component={InputLabelOutlined}
            name={item}
            style={{ width: '100%' }}
            label={`${index + 1}º bilhete`}
            validate={[FORM_RULES.required]}
            required
          />
          {props.fields.length > 1 && (
            <FontAwesomeIcon
              style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
              icon={faTrash}
              onClick={() => props.fields.remove(index)}
            />
          )}
        </MaterialGrid>
      ))}
    </>
  );
};

function GiveawayDrawForm(props) {
  const { giveaway_id } = props.router.params;

  function onSubmit(values) {
    console.log(values);
    console.log(props.auth.user.establishments[0].stations[0].id, BRB_FLA_STATION_ID);
    if (props.auth.user.establishments[0].stations[0].id === BRB_FLA_STATION_ID) {
      return props.drawWinners(values, giveaway_id);
    }
    props.getGiveawayWinners(values, giveaway_id);
  }

  useEffect(() => {
    props.initializeDrawForm();
  }, []);

  return (
    <Form
      role="form"
      onSubmit={props.handleSubmit(onSubmit)}
      noValidate
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        margin: '50px auto',
      }}>
      <div style={{ marginLeft: 20, width: '90vw' }}>
        <MaterialGrid container spacing={3}>
          <MaterialGrid item xs={4}>
            <MaterialGrid container direction="column" justify="center" spacing={3}>
              <Typography variant="h5" style={{ padding: 12, fontWeight: 600 }}>
                Sortear
              </Typography>
              <FieldArray name="tickets" component={renderTickets} />
              {props.auth.user.establishments[0].stations[0].id === BRB_FLA_STATION_ID && (
                <MaterialGrid
                  item
                  xs={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                  alignItems="center">
                  <Field
                    component={InputLabelOutlined}
                    name={'draw_quantity'}
                    style={{ width: '100%' }}
                    label={`Quantidade de prêmios`}
                    validate={[FORM_RULES.required]}
                    required
                  />
                  <Field
                    component={SelectLabel}
                    name={'series_number_position'}
                    label="Posição do número da série"
                    options={POSITIONS_LIST}
                    validate={[FORM_RULES.required]}
                    containerStyle={{ width: '100%' }}
                  />
                  <Field
                    component={SelectLabel}
                    name={'sortable_element_position'}
                    label="Posição do elemento sorteável"
                    options={POSITIONS_LIST}
                    validate={[FORM_RULES.required]}
                    containerStyle={{ width: '100%' }}
                  />
                  <Field
                    component={SelectLabel}
                    name={'series_number_length'}
                    label={`Tamanho do número de série`}
                    validate={[FORM_RULES.required]}
                    required
                    options={SERIES_NUMBER_LENGTH}
                    containerStyle={{ width: '100%' }}
                  />
                  <Field
                    component={SelectLabel}
                    name={'winnable_times'}
                    label={`Quantas vezes um participante pode ganhar?`}
                    validate={[FORM_RULES.required]}
                    required
                    options={WINNABLE_TIMES_OPTIONS}
                    containerStyle={{ width: '100%' }}
                  />
                </MaterialGrid>
              )}
            </MaterialGrid>
            <MaterialGrid container style={{ marginTop: 24 }}>
              <SecondaryButton
                onClick={() => props.router.push('/owner/giveaway')}
                color="primary"
                style={{ margin: '0 10px 0 0' }}>
                Voltar
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                color="primary"
                style={{ margin: 0 }}
                disabled={props.giveaways.buttonLoading}>
                Apurar
              </PrimaryButton>
              {props.giveaways.buttonLoading && (
                <Box display="flex" alignItems={'center'} ml={1}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid item xs={8}>
            {props.giveaways.winners.length > 0 && (
              <>
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  Resultado
                </Typography>
                {props.giveaways.winners.map((item, index) => (
                  <Fragment key={item.number}>
                    <Typography variant="h6" style={{ marginTop: 10 }}>{`${
                      index + 1
                    }º ganhador`}</Typography>
                    <Box display="flex" style={{ marginTop: 10 }}>
                      <Typography style={{ fontWeight: 600, marginRight: 5 }}>Nome:</Typography>
                      <Typography>{item.name}</Typography>
                    </Box>
                    <Box display="flex" style={{ marginTop: 10 }}>
                      <Typography style={{ fontWeight: 600, marginRight: 5 }}>Número:</Typography>
                      <Typography>{calculateNumberLabel(item.number)}</Typography>
                    </Box>
                  </Fragment>
                ))}
              </>
            )}
          </MaterialGrid>
        </MaterialGrid>
      </div>
    </Form>
  );
}

GiveawayDrawForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  drawWinners: PropTypes.func,
  getGiveawayWinners: PropTypes.func,
  giveaways: PropTypes.shape({
    buttonLoading: PropTypes.any,
    winners: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
  }),
  handleSubmit: PropTypes.func,
  initializeDrawForm: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      giveaway_id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    giveaways: state.giveaways,
    formValues: getFormValues('giveawayDrawForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getGiveawayWinners, initializeDrawForm, drawWinners }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'giveawayDrawForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(GiveawayDrawForm)
);
