import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FORM_RULES } from 'helpers/validations';
import { currencyMask } from 'helpers/masks';
import { roundMoney } from 'helpers/utils';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import TableGeneral from 'common/components/table/tableGeneral';
import LoadingProgress from 'common/components/progress/loading';
import AlertDialogSlide from 'common/components/dialog/dialog';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import {
  getProductOrderDetail,
  refund,
  updateObservationsAndDeliveryStatus,
  getDeliveryStatuses,
  cancelProductOrder,
} from './productOrdersActions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  defaultSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bold: {
    fontWeight: '700',
  },
  italic: {
    fontStyle: 'italic',
  },
}));

function ProductOrdersDetail({
  router,
  productOrders: { detail, loading, refundLoading, deliveryStatusesList, buttonLoading },
  getProductOrderDetail,
  refund,
  auth,
  updateObservationsAndDeliveryStatus,
  getDeliveryStatuses,
  cancelProductOrder,
}) {
  const classes = useStyles();
  const { id } = router.params;

  const [isRefundSettingsDialogVisible, setIsRefundSettingsDialogVisible] = useState(false);
  const [isRefundConfirmationDialogVisible, setIsRefundConfirmationDialogVisible] = useState(false);
  const [partialRefundValue, setPartialRefundValue] = useState('');
  const [isPartialRefundValueValid, setIsPartialRefundValueValid] = useState(false);
  const [isPartialRefundValueStatusVisible, setIsPartialRefundValueStatusVisible] = useState(false);
  const [refundData, setRefundData] = useState(null);
  const [total_final_value, settotal_final_value] = useState(0);
  const [refunded_percentage, setrefunded_percentage] = useState(0);
  const [refundedValue, setRefundedValue] = useState(0);
  const [avaliableRefundValue, setAvaliableRefundValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [idCancel, setIdCancel] = useState(undefined);
  const observations = null;
  const [deliveryStatusId, setDeliveryStatusId] = useState('');
  const [deliveryStatusLookup, setDeliveryStatusLookup] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductOrderDetail(id);
    getDeliveryStatuses();
  }, []);

  useEffect(() => {
    const deliveryStatusLookup = deliveryStatusesList.map((item) => ({
      value: item.id,
      label: item.status,
    }));
    /* for (let i = 0; i < deliveryStatusesList.length; i += 1) {
      deliveryStatusLookup[`${deliveryStatusesList[i].id}`] =
        deliveryStatusesList[i].status;
    } */
    setDeliveryStatusLookup(deliveryStatusLookup);
  }, [deliveryStatusesList]);

  useEffect(() => {
    if (detail) {
      setDeliveryStatusId(detail.delivery_status_id);
      const { total_final_value: total, refunded_percentage: percentage } = detail;
      settotal_final_value(total);
      setrefunded_percentage(percentage);
      setRefundedValue((total * (percentage / 100)).toFixed(2));
      setAvaliableRefundValue(total - ((total * percentage) / 100).toFixed(2));
    }
  }, [detail]);

  useEffect(() => {
    if (
      !partialRefundValue ||
      parseFloat(partialRefundValue) <= 0 ||
      parseFloat(partialRefundValue) >= avaliableRefundValue
    ) {
      setIsPartialRefundValueValid(false);
    } else setIsPartialRefundValueValid(true);
  }, [partialRefundValue]);

  useEffect(() => {
    if (detail) {
      const notRepeatedProducts = [];
      detail.single_product_orders.forEach((item) => {
        const indexFound = notRepeatedProducts.findIndex((secondItem) => secondItem.id === item.id);
        if (indexFound >= 0) {
          notRepeatedProducts[indexFound] = {
            ...notRepeatedProducts[indexFound],
            quantity: notRepeatedProducts[indexFound].quantity + 1,
          };
        } else {
          notRepeatedProducts.push({
            id: item.product_id,
            name: item.product.name,
            quantity: 1,
            amount: roundMoney(item.product.value - item.product.discount_value),
          });
        }
      });
      setProducts(notRepeatedProducts);
    }

    return () => {
      setProducts([]);
    };
  }, [detail]);

  function treatInputValue(value) {
    let formatedValue = value.substring(3);
    while (true) {
      if (formatedValue.includes('.')) {
        formatedValue = formatedValue.replace('.', '');
      } else break;
    }
    return formatedValue.replace(',', '.');
  }

  function getErrorMessage() {
    if (!partialRefundValue) return 'Digite um valor a ser estornado';
    if (parseFloat(partialRefundValue) <= 0) return 'Digite um valor positivo!';
    if (
      parseFloat(partialRefundValue) === total_final_value ||
      parseFloat(partialRefundValue) === avaliableRefundValue
    )
      return 'Realize um estorno total';
    if (parseFloat(partialRefundValue) > avaliableRefundValue)
      return 'Valor maior do que o valor disponível para estorno!';
    if (parseFloat(partialRefundValue) > total_final_value)
      return 'Valor maior do que o valor total da venda, impossível estornar!';
    return 'Valor inválido!';
  }

  function clearRefundData() {
    setRefundData(null);
    setPartialRefundValue('');
  }

  function handleCloseDialog(dialogToClose = 'confirmation') {
    if (dialogToClose === 'confirmation') {
      setIsRefundConfirmationDialogVisible(false);
    }
    if (dialogToClose === 'settings') {
      setIsRefundSettingsDialogVisible(false);
    }
    setIsPartialRefundValueStatusVisible(false);
    clearRefundData();
  }

  function openConfirmationDialog() {
    setIsRefundConfirmationDialogVisible(true);
    setIsRefundSettingsDialogVisible(false);
  }

  function handleRefund() {
    function cleanup() {
      handleCloseDialog();
      getProductOrderDetail(id);
    }
    refund(refundData, cleanup);
  }

  function handleUpdate() {
    updateObservationsAndDeliveryStatus(id, {
      observations,
      delivery_status_id: deliveryStatusId,
    });
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (!detail) {
    return (
      <Box display="flex" height="100vh" justifyContent="center">
        <Typography>O registro não foi encontrado.</Typography>
      </Box>
    );
  }

  const walletFutureTransaction = detail.future_transactions.find(
    (item) => !item.gateway_or_wallet
  );

  const gatewayFutureTransaction = detail.future_transactions.find(
    (item) => item.gateway_or_wallet
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                ID: <Typography component="span">#{detail.purchase_id}</Typography>
              </Typography>
            </Grid>
            {detail.gateway_charge && (
              <Grid item xs={12}>
                <Typography className={classes.bold}>
                  ID Pagar.me:{' '}
                  <Typography component="span">#{detail.gateway_charge.external_id}</Typography>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Data:{' '}
                <Typography component="span">
                  {detail ? format(parseISO(detail.createdAt), 'dd/MM/yyyy HH:mm') : 'Erro'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Valor total da compra:{' '}
                <Typography component="span">{roundMoney(total_final_value, true)}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Status do pedido:{' '}
                <Typography component="span">
                  {detail.status}
                  {detail.status === 'Parcialmente estornada' &&
                    ` (${roundMoney(refundedValue, true)})`}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Nome: <Typography component="span">{detail.user.name}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                CPF: <Typography component="span">{detail.user.cpf || ''}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Endereço: <Typography component="span">{detail.address}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Complemento: <Typography component="span">{detail.reference}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Local: <Typography component="span">{detail.user.name}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Atividade: <Typography component="span">{detail.action.name}</Typography>
              </Typography>
            </Grid>
            {detail.action.partner && (
              <Grid item xs={12}>
                <Typography className={classes.bold}>
                  Parceiro: <Typography component="span">{detail.action.partner.name}</Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => router.push(`/owner/validacao-cupom/${detail.code}`)}>
                Validar QR Code
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={`${classes.formControl} ${classes.defaultSpacing}`}>
                <InputLabel htmlFor="outlined-age-native-simple">Status do delivery</InputLabel>
                <Select
                  native
                  value={deliveryStatusId}
                  onChange={(event) => {
                    setDeliveryStatusId(event.target.value);
                  }}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}>
                  {deliveryStatusLookup.map((item, index) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdate}
                className={classes.defaultSpacing}>
                Atualizar status
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setIdCancel(id);
                  setOpen(true);
                }}
                className={classes.defaultSpacing}
                disabled={detail.status === 'Cancelado'}>
                Cancelar pedido
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={(event) => {
                  setRefundData(detail);
                  setIsRefundSettingsDialogVisible(true);
                  event.stopPropagation();
                }}
                disabled={refunded_percentage === 100}
                className={classes.defaultSpacing}>
                Estornar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <TableGeneral
              hasToolbar={true}
              searchPlaceholder={'Pesquisar por nome'}
              toolbarSearch
              title={'Produtos'}
              dataType={'productOrders'}
              columns={[
                {
                  field: 'name',
                  title: 'Nome',
                  editable: 'never',
                },
                {
                  field: 'quantity',
                  title: 'Quantidade',
                  editable: 'never',
                },
                {
                  field: 'amount',
                  title: 'Valor (R$)',
                  editable: 'never',
                  render: (rowData) => roundMoney(rowData.amount, true),
                },
              ]}
              data={products}
            />
          </Box>
        </Grid>
        <Grid container spacing={1} className={classes.defaultSpacing}>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Soma dos produtos:{' '}
              <Typography component="span">
                {roundMoney(detail.product_items_sum_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Soma dos descontos:{' '}
              <Typography component="span">
                {roundMoney(detail.additional_discount_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Entrega:{' '}
              <Typography component="span">
                {roundMoney(detail.delivery_tax_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor final:{' '}
              <Typography component="span">{roundMoney(detail.final_value, true)}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor pago da carteira:{' '}
              <Typography component="span">{roundMoney(detail.wallet_value, true)}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor líquido recebido da carteira:{' '}
              <Typography component="span">
                {roundMoney(walletFutureTransaction?.value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor pago do cartão/PIX:{' '}
              <Typography component="span">{roundMoney(detail.credit_card_value, true)}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor líquido recebido do cartão/PIX:{' '}
              <Typography component="span">
                {roundMoney(gatewayFutureTransaction?.value, true)}*
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.italic}>
              *Cobramos uma taxa de R$ 1,50 de antifraude (para compras no cartão de crédito) por
              transação. Esta taxa é dividida igualmente entre todos os parceiros caso o cliente
              tenha comprado de mais de 1 parceiro em uma única compra:{' '}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isRefundSettingsDialogVisible && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={() => handleCloseDialog('settings')}
          aria-labelledby="alert-dialog-title"
          open={isRefundSettingsDialogVisible}>
          <DialogTitle id="alert-dialog-title">
            {`Deseja estornar a transação de id: #${refundData.purchase_id}?`}
          </DialogTitle>
          <DialogContent
            style={{
              height: 100,
            }}>
            <Field
              component={InputLabelOutlined}
              label={'Valor do estorno parcial:'}
              name="partialRefundInput"
              type="text"
              required
              validate={[
                FORM_RULES.required,
                FORM_RULES.number,
                FORM_RULES.positiveValue,
                FORM_RULES.maxValue(avaliableRefundValue),
              ]}
              {...currencyMask}
              onChange={async (e) => {
                await setTimeout(() => true, 100);
                const value = e.target.value.includes('R$ ')
                  ? treatInputValue(e.target.value)
                  : roundMoney(`0.0${e.target.value}`);
                setPartialRefundValue(value);
                if (!isPartialRefundValueStatusVisible) setIsPartialRefundValueStatusVisible(true);
              }}
              error={isPartialRefundValueStatusVisible && !isPartialRefundValueValid}
              helperText={
                isPartialRefundValueStatusVisible && !isPartialRefundValueValid && getErrorMessage()
              }
            />
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={() => handleCloseDialog('settings')} color="primary">
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setRefundData((prevRefundData) => ({
                  purchaseId: prevRefundData.purchase_id,
                  value: roundMoney(partialRefundValue),
                  isPartial: true,
                }));
                openConfirmationDialog();
              }}
              disabled={!isPartialRefundValueValid}
              color="primary">
              Estorno parcial
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setRefundData((prevRefundData) => {
                  const totalValue = prevRefundData.total_final_value;
                  const refundedPercentage = prevRefundData.refunded_percentage;
                  const refValue = totalValue * (refundedPercentage / 100);
                  return {
                    purchaseId: prevRefundData.purchase_id,
                    value: totalValue - refValue,
                    isPartial: false,
                  };
                });
                openConfirmationDialog();
              }}
              color="primary">
              Estorno total
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
      {isRefundConfirmationDialogVisible && refundData && (
        <Dialog
          maxWidth="xl"
          onClose={() => handleCloseDialog('confirmation')}
          aria-labelledby="alert-dialog-title"
          open={isRefundConfirmationDialogVisible}>
          <DialogTitle id="alert-dialog-title">{`Tem certeza?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* Ao confirmar, todo o cashback recebido pelo usuário será estornado
              e ele receberá a pontuação utilizada para a compra, que é retirada
              do seu saldo a ser liberado. Deseja prosseguir? */}
              {`Id da compra: #${refundData.purchaseId}`}
              <br />
              {refundData.value === 0 && `Compra já estornada!`}
              {`Valor estornado: ${roundMoney(refundData.value, 'string')}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={() => handleCloseDialog('confirmation')} color="secondary">
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={handleRefund}
              color="primary"
              disabled={refundLoading || refundData.value === 0}>
              Confirmar
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
      {open ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Você tem certeza que deseja cancelar esse pedido?"
          onClose={() => setOpen(false)}
          visible={open}>
          <div>
            <PrimaryButton
              noMargin
              onClick={() => {
                setOpen(false);
                cancelProductOrder(idCancel, auth.user.establishments[0].id);
              }}
              disabled={buttonLoading}>
              Sim, cancelar o pedido.
            </PrimaryButton>
            <SecondaryButton onClick={() => setOpen(false)}>Sair</SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
    </Container>
  );
}

ProductOrdersDetail.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  cancelProductOrder: PropTypes.func,
  getDeliveryStatuses: PropTypes.func,
  getProductOrderDetail: PropTypes.func,
  refund: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  updateObservationsAndDeliveryStatus: PropTypes.func,
  productOrders: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    productOrders: state.productOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductOrderDetail,
      updateObservationsAndDeliveryStatus,
      refund,
      getDeliveryStatuses,
      cancelProductOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrdersDetail);
