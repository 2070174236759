import { BASE_S3 } from 'config/consts';

export const NewActivity = (action, station, establishment, alternativeSummary) => {
  console.log(action, station);
  let parsedJson = {};
  let imageAddr = {};
  const { jsonData } = station;
  parsedJson = JSON.parse(jsonData);
  imageAddr = parsedJson.objectNewItemsBody.navbar_station_secondary_logo;
  const generalColor = parsedJson.objectNewItemsBody.general_color;

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
  <html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=Edge">
        <!--<![endif]-->
        <!--[if (gte mso 9)|(IE)]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
        <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
      body {width: 400px;margin: 0 auto;}
      table {border-collapse: collapse;}
      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
      img {-ms-interpolation-mode: bicubic;}
    </style>
  <![endif]-->
        <style type="text/css">
      body, p, div {
        font-family: lato,helvetica,sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #1188E6;
        text-decoration: none;
      }
      p { margin: 0; padding: 0; }
      table.wrapper {
        width:100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      @media screen and (max-width:480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
          text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 100% !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .social-icon-column {
          display: inline-block !important;
        }
      }
    </style>
        <!--user entered Head Start--><!--End Head user entered-->
      </head>
      <body>
        <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size:14px; font-family:lato,helvetica,sans-serif; color:#000000; background-color:#FFFFFF;">
          <div class="webkit">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
              <tr>
                <td valign="top" bgcolor="#FFFFFF" width="100%">
                  <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td width="100%">
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr>
                            <td style="display: flex; flex-direction: column; align-items: center">
                              <!--[if mso]>
      <center>
      <table><tr><td width="400">
    <![endif]-->
    <div style="width:100%; background-color: ${generalColor}; height: 12px; margin-bottom: 16px">
    
    </div>
                                      <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:400px;" align="center">
                                        <tr>
                                          <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
      <tr>
        <td role="module-content">
          <p></p>
        </td>
      </tr>
    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6f4adc84-9d12-400a-9286-85da66e92c0b">
      <tbody>
        <tr>
          <td style="font-size:6px; line-height:10px; padding:0px 0px 10px 0px;" valign="top" align="left">
            <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Lato, lato, sans-serif; font-size:16px; max-width:30% !important; width:30%; height:auto !important; margin-bottom: 12px" width="120" alt="" data-proportionally-constrained="true" data-responsive="true" src="${BASE_S3}/${imageAddr}">
          </td>
        </tr>
      </tbody>
    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6c18bf2e-4964-41eb-abe5-676834ea10d8">
      <tbody>
        <tr>
          <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
            <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; object-fit: "contain" width="auto" alt="" data-proportionally-constrained="false" data-responsive="false" src="${BASE_S3}/${
    action.image
  }" height="auto">
          </td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="5bc11197-98cb-4c0f-a092-cec245ed5762" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:40px 10px 0px 10px; line-height:22px; text-align:inherit; color: #505050; font-weight: 600" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 24px; font-family: lato, tahoma, geneva, sans-serif">${
            action.name
          }</span></div><div></div></div></td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="48ffeb35-1a4a-4da4-bfc8-da52cea94df5" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 10px 40px 10px; line-height:22px; text-align:inherit; color: #7a7a7a;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: lato, sans-serif; text-align: center">${
            alternativeSummary || action.summary
          }</div><div></div></div></td>
        </tr>
      </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed;" width="100%" data-muid="4361bbc7-8519-46ab-a877-b8ac190b1054">
        <tbody>
          <tr>
            <td align="center" bgcolor="" class="outer-td" style="padding:0px 0px 0px 0px;">
              <table border="0" cellpadding="0" cellspacing="0" class="wrapper-mobile" style="text-align:center;">
                <tbody>
                  <tr>
                  <td align="center" bgcolor="#ff5a52" class="inner-td" style="border-radius:6px; font-size:16px; text-align:center; background-color:inherit;">
                    <a href="" style="background-color:#ff5a52; border:1px solid #ff5a52; border-color:#ff5a52; border-radius:4px; border-width:1px; color:#ffffff; display:inline-block; font-size:14px; font-weight:normal; letter-spacing:1px; line-height:normal; padding:10px 40px 10px 40px; text-align:center; text-decoration:none; border-style:solid;" target="_blank">SAIBA MAIS</a>
                  </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="0e28bdeb-1310-42ec-97c7-edb1fe901ba7" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center; color: #7a7a7a;">Ou clique neste link:</div><div></div></div></td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="bc9bd696-be7e-43fd-959a-75c1f987e3f8">
      <tbody>
        <tr>
        <tr>
        <td height="100%" valign="top" role="module-content" style="text-align: center; padding-bottom: 40px;"><a href="${
          station.url
        }/#/homepage/atividades/${
    action.id
  }" style="color: #7a7a7a; font-family: lato, sans-serif; text-decoration: underline">
        ${station.url}/#/homepage/atividades/${action.id}
</a></td>
        </tr>
      </tbody>
    </table>
                       
                      </td>
                    </tr>
                  </table>
                  <div style="width:100%; background-color: #f7f7f7;">
                  <div style="width:400px; margin: 0px auto">
                  <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="7edbd4ba-e2f9-4cfa-93fa-680e29122405">
                  <tbody>
                    <tr>
                      <td style="font-size:6px; line-height:10px; padding:32px 0px 0px 0px; background-color: #f7f7f7;" valign="top" align="center">
                        <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Lato, lato, sans-serif; font-size:16px; max-width:30% !important; width:30%; height:auto !important;" width="120" alt="" data-proportionally-constrained="true" data-responsive="true" src="${BASE_S3}/${imageAddr}">
                      </td>
                    </tr>
                  </tbody>
                </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="5b9a4ee9-bd20-467d-ae98-d961402b66d2" data-mc-module-version="2019-10-22">
                  <tbody>
                    <tr>
                      <td style="padding:18px 10px 32px 10px; line-height:22px; text-align:center; color: #7a7a7a; background-color: #f7f7f7" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: inherit">${
                        station.name
                      } - ${
    establishment.social_name
  }. Não quer mais receber nossos emails? <a href="${
    station.url
  }/#/email/desinscricao" style="color: #7a7a7a; text-decoration: underline">Clique aqui</a>.</div><div></div></div></td>
                    </tr>
                  </tbody>
                  <!--[if mso]>
                  </td>
                </tr>
              </table>
            </center>
            <![endif]-->
                  </td>
                  </tr>
                </table>
                </div>

</div>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </body>
    </html>`;
};

export const WelcomeMailTemplate = (user, station, featured_image, title, body, establishment) => {
  let parsedJson = {};
  let imageAddr = {};
  const { jsonData } = station;
  parsedJson = JSON.parse(jsonData);
  imageAddr = parsedJson.objectNewItemsBody.navbar_station_secondary_logo;
  const generalColor = parsedJson.objectNewItemsBody.general_color;

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
  <html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=Edge">
        <!--<![endif]-->
        <!--[if (gte mso 9)|(IE)]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
        <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
      body {width: 400px;margin: 0 auto;}
      table {border-collapse: collapse;}
      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
      img {-ms-interpolation-mode: bicubic;}
    </style>
  <![endif]-->
        <style type="text/css">
      body, p, div {
        font-family: lato,helvetica,sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #1188E6;
        text-decoration: none;
      }
      p { margin: 0; padding: 0; }
      table.wrapper {
        width:100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      @media screen and (max-width:480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
          text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 100% !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .social-icon-column {
          display: inline-block !important;
        }
      }
    </style>
        <!--user entered Head Start--><!--End Head user entered-->
      </head>
      <body>
        <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size:14px; font-family:lato,helvetica,sans-serif; color:#000000; background-color:#FFFFFF;">
          <div class="webkit">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
              <tr>
                <td valign="top" bgcolor="#FFFFFF" width="100%">
                  <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td width="100%">
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr>
                            <td style="display: flex; flex-direction: column; align-items: center">
                              <!--[if mso]>
      <center>
      <table><tr><td width="400">
    <![endif]-->
    <div style="width:100%; background-color: ${generalColor}; height: 12px; margin-bottom: 16px">
    
    </div>
                                      <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:400px;" align="center">
                                        <tr>
                                          <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
      <tr>
        <td role="module-content">
          <p></p>
        </td>
      </tr>
    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6f4adc84-9d12-400a-9286-85da66e92c0b">
      <tbody>
        <tr>
          <td style="font-size:6px; line-height:10px; padding:0px 0px 10px 0px;" valign="top" align="left">
            <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Lato, lato, sans-serif; font-size:16px; max-width:30% !important; width:30%; height:auto !important; margin-bottom: 12px" width="120" alt="" data-proportionally-constrained="true" data-responsive="true" src="${BASE_S3}/${imageAddr}">
          </td>
        </tr>
      </tbody>
    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="6c18bf2e-4964-41eb-abe5-676834ea10d8">
      <tbody>
        <tr>
          <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
            <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; object-fit: cover" width="400" alt="" data-proportionally-constrained="false" data-responsive="false" src="${BASE_S3}/${featured_image}" height="400">
          </td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="5bc11197-98cb-4c0f-a092-cec245ed5762" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:40px 10px 0px 10px; line-height:22px; text-align:inherit; color: #505050; font-weight: 600" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 24px; font-family: lato, tahoma, geneva, sans-serif">${title}</span></div><div></div></div></td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="48ffeb35-1a4a-4da4-bfc8-da52cea94df5" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 10px 40px 10px; line-height:22px; text-align:inherit; color: #7a7a7a;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: lato, sans-serif; text-align: center">${body}</div><div></div></div></td>
        </tr>
      </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed;" width="100%" data-muid="4361bbc7-8519-46ab-a877-b8ac190b1054">
        <tbody>
          <tr>
            <td align="center" bgcolor="" class="outer-td" style="padding:0px 0px 0px 0px;">
              <table border="0" cellpadding="0" cellspacing="0" class="wrapper-mobile" style="text-align:center;">
                <tbody>
                  <tr>
                  <td align="center" bgcolor="#ff5a52" class="inner-td" style="border-radius:6px; font-size:16px; text-align:center; background-color:inherit;">
                    <a href="" style="background-color:#ff5a52; border:1px solid #ff5a52; border-color:#ff5a52; border-radius:4px; border-width:1px; color:#ffffff; display:inline-block; font-size:14px; font-weight:normal; letter-spacing:1px; line-height:normal; padding:10px 40px 10px 40px; text-align:center; text-decoration:none; border-style:solid;" target="_blank">SAIBA MAIS</a>
                  </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="0e28bdeb-1310-42ec-97c7-edb1fe901ba7" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center; color: #7a7a7a;">Ou clique neste link:</div><div></div></div></td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="bc9bd696-be7e-43fd-959a-75c1f987e3f8">
      <tbody>
        <tr>
        <tr>
        <td height="100%" valign="top" role="module-content" style="text-align: center; padding-bottom: 40px;"><a href="${station.url}/#" style="color: #7a7a7a; font-family: lato, sans-serif; text-decoration: underline">
        ${station.url}/#
</a></td>
        </tr>
      </tbody>
    </table>
                       
                      </td>
                    </tr>
                  </table>
                  <div style="width:100%; background-color: #f7f7f7;">
                  <div style="width:400px; margin: 0px auto">
                  <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="7edbd4ba-e2f9-4cfa-93fa-680e29122405">
                  <tbody>
                    <tr>
                      <td style="font-size:6px; line-height:10px; padding:32px 0px 0px 0px; background-color: #f7f7f7;" valign="top" align="center">
                        <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Lato, lato, sans-serif; font-size:16px; max-width:30% !important; width:30%; height:auto !important;" width="120" alt="" data-proportionally-constrained="true" data-responsive="true" src="${BASE_S3}/${imageAddr}">
                      </td>
                    </tr>
                  </tbody>
                </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="5b9a4ee9-bd20-467d-ae98-d961402b66d2" data-mc-module-version="2019-10-22">
                  <tbody>
                    <tr>
                      <td style="padding:18px 10px 32px 10px; line-height:22px; text-align:center; color: #7a7a7a; background-color: #f7f7f7" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: inherit">${station.name} é um programa de relacionamento da ${establishment.social_name}. Não quer mais receber nossos emails? <a href="${station.url}/#/email/desinscricao" style="color: #7a7a7a; text-decoration: underline">Clique aqui</a>.</div><div></div></div></td>
                    </tr>
                  </tbody>
                  <!--[if mso]>
                  </td>
                </tr>
              </table>
            </center>
            <![endif]-->
                  </td>
                  </tr>
                </table>
                </div>

</div>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </body>
    </html>`;
};
