import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LottieControl from 'common/components/lottie/lottie';
import LoadingProgress from 'common/components/progress/loading';
import { USER_TOKEN } from 'config/consts';
import { api } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import React, { useState } from 'react';
import { Field } from 'redux-form';

// import { Container } from './styles';

export default function ResultsLotties({ formValues, result, lottiesList }) {
  const [lottieJSON, setLottieJSON] = useState(null);
  const [loading, setLoading] = useState(false);

  async function getLottie(id) {
    setLoading(true);
    const response = await api.get(`/lottie-files/${id}`, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    });
    setLottieJSON(JSON.parse(response.data.json));
    setLoading(false);
  }

  function lottiesChange(id) {
    if (formValues) {
      if (id !== 'none') {
        getLottie(id);
      }
    }
  }

  return (
    <div>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} sm={12}>
          <Field
            component={EditorConvertToHTMLActivity}
            label="Feedback da pesquisa"
            name={`${result}.feedback`}
            required
            border
            validate={[FORM_RULES.required]}
          />
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3} justifyContent="center">
        <GeneralGrid item xs={12} sm={12}>
          <Field
            component={SelectLabel}
            options={lottiesList}
            required
            onChange={(e) => lottiesChange(e.target.value)}
            name={`${result}.feedback_lottie_id`}
            label="Escolha uma animação para feedback"
          />
        </GeneralGrid>
        <GeneralGrid container spacing={3} pt={3} justify="center">
          <GeneralGrid item xs={6} style={{ margin: '15px 0' }}>
            {formValues ? (
              formValues.results[0] ? (
                formValues.results[0].feedback_lottie_id !== 'none' ? (
                  loading ? (
                    <Box display="flex" justifyContent="center">
                      <LoadingProgress />
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center" width="100%">
                      <LottieControl
                        width={100}
                        height={100}
                        loop={false}
                        animation={lottieJSON}
                        hasReplayButton
                      />
                    </Box>
                  )
                ) : null
              ) : null
            ) : null}
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </div>
  );
}

ResultsLotties.propTypes = {
  formValues: PropTypes.shape({
    results: PropTypes.any,
  }).isRequired,
  lottiesList: PropTypes.any.isRequired,
  result: PropTypes.any.isRequired,
};
