import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { USER_TOKEN } from 'config/consts';
import YouTube from 'react-youtube';
import LoadingProgress from 'common/components/progress/loading';
import { Grid } from '@material-ui/core';
import { FanApi } from '../upload/api';
import { DomPurifyContentContainer } from './styles';

function ActivityRawContent(props) {
  const [videosRequest, setVideosRequest] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getVideosID() {
      const response = await FanApi.get(`/videos/${props.auth.user.establishments[0].id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });
      setVideosRequest(response.data);
      setLoading(false);
    }
    getVideosID();
  }, [props.auth.user.establishments]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    );
  }
  const videos = videosRequest.filter((item) => {
    if (props.formValues?.videos?.length > 0) {
      return parseInt(item.id) === parseInt(props.formValues?.videos[0]);
    }

    return false;
  });

  return (
    <>
      <Grid item container alignItems="center" xs={12}>
        {/* DOM PURIFY CONTENT */}
        <Grid item container alignItems="center" xs={12}>
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{
              __html: props.formValues.description,
            }}
          />
        </Grid>
        {/* VIDEO PLAYER */}
        <Grid item container alignItems="center" xs={12}>
          {videos.length > 0 ? (
            <div className="row justify-content-center mt-2 mb-5">
              <div className="col-10">
                {videos[0].custom ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <YouTube videoId={videos[0] ? videos[0].url.split('=')[1] : null} />
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe
                      src={`https://player.vimeo.com/video/${
                        videos[0] ? videos[0].url.split('/')[2] : null
                      }`}
                      style={{ width: '100%', height: '400px' }}
                      frameBorder="0"
                      title="vimeo video"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowFullScreen
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

ActivityRawContent.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  formValues: PropTypes.shape({
    description: PropTypes.any,
    videos: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
};
const StyledActivitySurvey = styled(ActivityRawContent)`
  p {
    font-size: 16px;
  }
`;

export default StyledActivitySurvey;
