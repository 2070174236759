import PropTypes from 'prop-types';
import ResponsiveDrawer from 'common/components/navigation/responsiveDrawer';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import _ from 'lodash';
import React, { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import OwnerNavigation from '../../../common/components/navigation/ownerNavigation/ownerNavigation';
import { changeEstablishment, signupCompanyOnly } from '../../../pages/auth/authActions.js';
import { dispatchStartOnboarding } from '../pages/dashboard/dashboardOwnerActions';
import { getListById as getSectionsListById } from '../pages/sections/sectionsActions';
import { getWebsites } from '../pages/website/websiteActions';
import './owner.css';

const INITIAL_STATE = {
  step: 0,
  visibleOnboarding: false,
  steps: [
    {
      placement: 'right',
      disableBeacon: true,
      target: '.active-actions',
      content: 'Essa é a quantidade de atividades que estão no ar!',
    },
    {
      disableBeacon: true,
      target: '.points-display',
      content: 'O seu caixa é uma parte importante da sua estação, cuide bem dele.',
    },
    {
      disableBeacon: true,
      target: '.partner-offers',
      content: 'Parceiros farão propostas para você!',
    },
    {
      disableBeacon: true,
      target: '.users-by-station',
      content: 'Seus participantes são o seu negócio, aqui você verá quantos você já conquistou.',
    },
    {
      disableBeacon: true,
      target: '.active-actions-detail',
      content: 'Para ficar fácil, mostraremos suas últimas atividades aqui!',
    },
    {
      disableBeacon: true,
      target: '.users-by-station-detail',
      content: 'Seus últimos participantes são de fácil acesso!',
    },
  ],
};
class Owner extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, visible: !!_.isEmpty(props.auth.user.establishments) };

    if (!_.isEmpty(props.auth.user.establishments)) {
      props.getWebsites(props.auth.user.establishments[0].id);
    }
  }

  renderNav = () => {
    return (
      <span className="font-dosis">
        <span style={{ fontWeight: 'bold', color: '#2b2d6b' }}>{this.props.auth.user.name}</span>
      </span>
    );
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  onSubmit = (values) => {
    const { router, auth } = this.props;
    values.type = 1;
    values.owner = this.props.auth.user.id;
    this.props.signupCompanyOnly(values, router, auth);
  };

  render() {
    const currentRoute = this.props.routes[this.props.routes.length - 2]?.path;
    if (isMobileOnly) {
      return (
        <ResponsiveDrawer
          router={this.props.router}
          routes={this.props.routes}
          navItems={this.props.navBarItems}
          user={this.props.auth.user}
          changeEstablishment={this.props.changeEstablishment}>
          {this.props.children}
        </ResponsiveDrawer>
      );
    }
    return (
      <>
        <OwnerNavigation
          router={this.props.router}
          navBarItems={this.props.navBarItems}
          changeEstablishment={this.props.changeEstablishment}
          establishments={this.props.establishments}
        />
        <div
          style={{
            background: '#f7f7f7',
            width: '100%',
            minHeight: currentRoute === 'website' ? 0 : '110vh',
          }}>
          <div className="container" style={{ maxWidth: 1180 }}>
            <div className="col-12">{this.props.children}</div>
          </div>

          <CustomizedSnackbar />
        </div>
      </>
    );
  }
}

Owner.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
      name: PropTypes.any,
    }),
  }),
  changeEstablishment: PropTypes.any,
  children: PropTypes.any,
  establishments: PropTypes.any,
  getWebsites: PropTypes.func,
  navBarItems: PropTypes.any,
  router: PropTypes.any,
  routes: PropTypes.shape({
    length: PropTypes.number,
  }),
  signupCompanyOnly: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    points: state.points,
    establishments: state.establishments,
    sections: state.sections,
    principal: state.principal,
    website: state.website,
    general: state.general,
    dashboardOwner: state.dashboardOwner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupCompanyOnly,
      getSectionsListById,
      getWebsites,
      dispatchStartOnboarding,
      changeEstablishment,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'ownerForm' })(Owner));
