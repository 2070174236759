import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { cnpjMask } from '../../../../../helpers/masks';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './entitiesActions';

const EntitiesForm = function (props) {
  const { entities, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(entities, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      establishmentId: props.auth.user.establishments?.[0]?.id,
    };

    create_or_update(router, newValues, create, update);
  }

  const arrayTypes = [
    { label: 'Grid 2x3 (modelo original)', value: 1 },
    { label: 'Grid texto-imagem verticalizado', value: 2 },
    { label: 'Carousel', value: 3 },
    { label: 'Centralizado com imagem embaixo', value: 4 },
    { label: 'Atividades em Destaque', value: 5 },
    { label: 'Planos', value: 7 },
  ];

  if (props.auth.user.establishments[0].stations[0].has_app) {
    arrayTypes.push({ label: 'Baixar o app', value: 6 });
  }

  return props.entities.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="entities-form" style={{ paddingBottom: isMobileOnly ? 100 : 0 }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Entidades</p>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome"
                      name="name"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="CNPJ"
                    name="cnpj"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                    {...cnpjMask}
                  />
                </GeneralGrid>

                <GeneralGrid container>
                  <GeneralGrid item xs={8} md={4} lg={4} style={{ marginLeft: -8 }}>
                    <PrimaryButton
                      type="submit"
                      disabled={props.entities.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}>
                      {props.entities.buttonLoading ? 'Enviando' : 'Enviar'}
                    </PrimaryButton>
                  </GeneralGrid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

EntitiesForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  change: PropTypes.func,
  create: PropTypes.any,
  entities: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    entities: state.entities,
    formValues: getFormValues('entitiesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'entitiesForm' })(EntitiesForm));
