import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { limitStringSize } from 'helpers/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteGiveawayById, getGiveawaysStationId } from './giveawaysActions';

const MAX_LENGTH = 30;

function Giveaway(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [confirmBox, setConfirmBox] = useState({ visible: false });
  const columns = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) =>
        rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : 'Sem título',
    },
    {
      title: 'Descrição',
      field: 'description',
      render: (rowData) =>
        rowData.description ? limitStringSize(rowData.description, MAX_LENGTH) : 'Sem título',
    },
    { title: 'Início', field: 'participation_start' },
    { title: 'Fim', field: 'participation_end' },
    {
      title: 'Data de distribuição',
      render: (rowData) => <span>{moment(rowData.counting_date).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Editar',
      render: (rowData) => (
        <Box onClick={() => props.router.push(`/owner/giveaway/editar/${rowData.id}`)}>
          <span style={{ color: '#2D95EF', cursor: 'pointer' }}>Editar</span>
        </Box>
      ),
    },
    {
      title: 'Deletar',
      render: (rowData) => (
        <Box
          onClick={() =>
            setConfirmBox((value) => ({
              ...value,
              visible: true,
              giveaway_id: rowData.id,
            }))
          }>
          <DeleteOutlineIcon style={{ color: '#E34848', cursor: 'pointer' }} />
        </Box>
      ),
    },
    {
      title: 'Sortear',
      render: (rowData) =>
        moment(rowData.counting_date).isBefore(new Date()) ? (
          <Box onClick={() => props.router.push(`/owner/giveaway/sortear/${rowData.id}`)}>
            <span style={{ color: '#2D95EF', cursor: 'pointer' }}>Sortear</span>
          </Box>
        ) : (
          <Box>
            {console.log(rowData.counting_date)}
            <span>{`A partir do dia ${moment(rowData.counting_date).format('DD/MM/YYYY')} `}</span>
          </Box>
        ),
    },
  ];

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Sorteios na plataforma</h2>
                  <p className="">
                    Cada sorteio pode ser atrelado a uma ou várias atividades com intuito de
                    incentivar a sua participação na mesma.
                  </p>
                  <PrimaryButton
                    onClick={() => props.router.push('/owner/giveaway/criar')}
                    color="primary">
                    Novo sorteio
                  </PrimaryButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </Grid>
        </Grid>
      </section>
    );
  }

  useEffect(() => {
    props.getGiveawaysStationId(props.auth.user.establishments[0].stations[0].id);
  }, []);

  if (props.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (props.giveaways.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ width: isMobile ? '89vw' : undefined }}>
          <Table
            hasToolbar
            toolbarSearch
            searchPlaceholder={'Pesquisar por nome'}
            title="Sorteios"
            columns={columns}
            rows={props.giveaways}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </Grid>
      </Grid>
      <AlertDialogSlide
        maxWidth="sm"
        title="Tem certeza?"
        onClose={() => setConfirmBox((value) => ({ ...value, visible: false }))}
        visible={confirmBox.visible}>
        <div>
          <PrimaryButton
            noMargin
            type="button"
            onClick={() =>
              props.deleteGiveawayById(
                confirmBox.giveaway_id,
                props.auth.user.establishments[0].stations[0].id,
                setConfirmBox
              )
            }
            disabled={props.giveaways.buttonLoading}>
            Sim, quero deletar
          </PrimaryButton>

          <SecondaryButton onClick={() => setConfirmBox((value) => ({ ...value, visible: false }))}>
            Cancelar
          </SecondaryButton>
        </div>
      </AlertDialogSlide>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Fab
          variant="extended"
          style={{ background: '#fff', marginRight: 16 }}
          onClick={() => props.router.push('/owner/giveaway/importar')}>
          <Add className={classes.extendedIcon} />
          Importar contatos
        </Fab>
        <Fab
          variant="extended"
          style={{ background: '#fff' }}
          onClick={() => props.router.push('/owner/giveaway/criar')}>
          <Add className={classes.extendedIcon} />
          Novo Sorteio
        </Fab>
      </Box>
    </>
  );
}

Giveaway.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteGiveawayById: PropTypes.func,
  getGiveawaysStationId: PropTypes.func,
  giveaways: PropTypes.shape({
    buttonLoading: PropTypes.any,
    length: PropTypes.number,
  }),
  loading: PropTypes.any,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    giveaways: state.giveaways.list,
    loading: state.giveaways.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGiveawaysStationId,
      deleteGiveawayById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Giveaway);
