import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardFooter from 'material-dashboard-components/Card/CardFooter.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import { getActionResults } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { ColumnsBelowSearch, NumberGenericElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import UserAnswers from '../../userAnswers/userAnswers';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualAnswer: false,
      userIndividualAnswerId: null,
    };
    this.props.getActionResults(this.props.location.query.action);
  }

  onDialogClose = () => {
    if (this.props.location.query.from === 'inicio') {
      this.props.router.push('/owner/acoes/inicio');
    } else {
      this.props.router.push(
        `/owner/acoes/minhas-atividades/?to=${this.props.location.query.from}`
      );
    }
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Resultados"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}>
        <section id="statement-buy-points" style={{ height: '100vh' }}>
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <h2 className="signup-text">Ninguém participou dessa atividade até o momento</h2>
                  <p>Aqui você poderá distribuir os prêmios</p>
                </GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img src={placeholder_illustration} className="img-fluid" alt="Ilustração" />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  render() {
    const { classes } = this.props;
    const { actionResults, loadingActionResults, actionDetail } = this.props.activeActions;
    const {
      impressions,
      reach,
      participations,
      cashback,
      cashback_left,
      quizResults,
      usersReached,
      totalAnswers,
    } = actionResults;
    if (loadingActionResults) {
      return (
        <AlertDialogSlide
          title="Resultados"
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    if (this.state.individualAnswer) {
      return (
        <AlertDialogSlide
          title={
            <span
              style={{
                color: '#2D95EF',
                cursor: 'pointer',
                fontWeight: '400',
                fontSize: 15,
              }}
              onClick={() =>
                this.setState({
                  individualAnswer: false,
                })
              }>
              Voltar
            </span>
          }
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <UserAnswers
            userId={this.state.userIndividualAnswerId}
            actionId={this.props.location.query.action}
            {...this.props}
          />
        </AlertDialogSlide>
      );
    }

    return (
      <AlertDialogSlide
        isFullScreen={isMobile}
        title={`${actionDetail.name} (${moment(actionDetail.start).format('DD/MM')} - ${
          moment(actionDetail.end).isAfter(new Date())
            ? 'Hoje'
            : moment(actionDetail.end).format('DD/MM')
        }) `}
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'md'}>
        <Typography variant="h6">Resultados gerais</Typography>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">remove_red_eye</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Impressões</p>
                <h3 className={classes.cardTitle}>{impressions}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de visualizações que sua atividade teve, independente se for do mesmo usuário ou de usuários diferentes."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">people_alt</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Alcance</p>
                <h3 className={classes.cardTitle}>{reach}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participantes que viram sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">call_made</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Participações</p>
                <h3 className={classes.cardTitle}>{participations.length}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participações efetivas na sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">bar_chart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Conversão</p>
                <h3 className={classes.cardTitle}>
                  {parseFloat((participations.length / reach) * 100).toFixed(1)}%
                </h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a porcentagem de pessoas que participaram dessa atividade em relação a todas que foram alcançadas"
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Gasto atual</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é quantidade de dinheiro que já foi paga em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Cashback restante</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback_left).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é a quantidade de dinheiro que foi reservada para ação mas ainda não foi distribuida em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Table
              hasToolBar={false}
              defaultSelected={''}
              title="Extrato"
              columns={[
                { field: 'name', title: 'Nome' },
                {
                  field: 'quantity',
                  title: 'Visualizações',
                  render: (rowData) => (
                    <NumberGenericElement category="visualizations" value={rowData.quantity} />
                  ),
                },
                {
                  title: 'Participação',
                  render: (rowData) =>
                    rowData.user_actions.length > 0 ? 'Visualizou e participou' : 'Visualizou',
                },
                {
                  title: 'Email',
                  field: 'email',
                },
                {
                  title: 'Search',
                  field: 'search',
                  render: (rowData) => (
                    <ColumnsBelowSearch
                      buttons={{
                        namedButtons: [
                          {
                            name:
                              rowData.user_actions.length > 0
                                ? 'Ver respostas'
                                : 'Ainda não participou',
                            onClick:
                              rowData.user_actions.length > 0
                                ? () => {
                                    this.setState({
                                      userIndividualAnswerId: rowData.id,
                                      individualAnswer: true,
                                    });
                                  }
                                : () => {},
                            disabled: !(rowData.user_actions.length > 0),
                          },
                        ],
                        iconButtons: [],
                      }}
                    />
                  ),
                },
              ]}
              rows={usersReached}
              onChangeOption={() => {}}
              selectOptions={[]}
            />
          </GeneralGrid>
        </GeneralGrid>
        <Box mt={3}>
          <Typography variant="h6">Resultados da pesquisa</Typography>
        </Box>
        {participations.length === 0 ? (
          <Box mt={3}>
            <Typography variant="body1">Ninguém respondeu a pesquisa ainda</Typography>
          </Box>
        ) : (
          <GeneralGrid container spacing={3}>
            {quizResults.map((item, index) => (
              <GeneralGrid item xs={12} key={index}>
                <Box mt={3}>
                  <Typography variant="body1">
                    {item.question} (múltiplas opções podem ser escolhidas)
                  </Typography>
                </Box>
                <ResponsiveContainer width={'100%'} height={400}>
                  <BarChart data={item.options} layout="horizontal">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis allowDecimals={false} />
                    <RechartsTooltip formatter={(value, name) => [value, 'Total']} />
                    <Legend formatter={(value, entry) => ['Quantidade de respostas']} />
                    <ReferenceLine
                      y={totalAnswers}
                      stroke="red"
                      strokeDasharray="0 0"
                      alwaysShow
                      isFront={true}
                      label={{
                        value: 'Total de respostas',
                        angle: 0,
                        position: 'top',
                      }}
                    />
                    <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </GeneralGrid>
            ))}
          </GeneralGrid>
        )}
      </AlertDialogSlide>
    );
  }
}

Results.propTypes = {
  activeActions: PropTypes.shape({
    actionResults: PropTypes.shape({
      cashback: PropTypes.any,
      cashback_left: PropTypes.any,
      impressions: PropTypes.any,
      participations: PropTypes.shape({
        length: PropTypes.number,
      }),
      quizResults: PropTypes.shape({
        map: PropTypes.func,
      }),
      reach: PropTypes.any,
      totalAnswers: PropTypes.any,
      usersReached: PropTypes.any,
    }),
    loadingActionResults: PropTypes.any,
    actionDetail: PropTypes.shape({
      end: PropTypes.any,
      name: PropTypes.any,
      start: PropTypes.any,
    }),
  }),
  classes: PropTypes.shape({
    cardCategory: PropTypes.any,
    cardTitle: PropTypes.any,
    stats: PropTypes.any,
  }),
  getActionResults: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.any,
      from: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionResults,
    },
    dispatch
  );
};

const StyledResults = withStyles(styles)(Results);

export default connect(mapStateToProps, mapDispatchToProps)(StyledResults);
