import React from 'react';
import Grid from '../layout/grid/grid';
import PropTypes from 'prop-types';

export class InputCheckbox extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
      options,
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          {options.map((item, index) => {
            return (
              <div className="form-check form-control" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.value}
                  id={`checkbox-${item.label}`}
                />

                {touched && error ? (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}>
                    <i className="fa fa-times-circle-o" />
                    &nbsp;
                    {item.label}
                  </label>
                ) : (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}>
                    {item.label}
                  </label>
                )}
              </div>
            );
          })}
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

InputCheckbox.propTypes = {
  cols: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  name: PropTypes.any,
  options: PropTypes.shape({
    map: PropTypes.func,
  }),
  style: PropTypes.any,
};
