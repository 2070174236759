/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from 'assets/img/fan_logo.svg';
import activity1 from 'assets/imgs/activity1.jpg';
import activity2 from 'assets/imgs/activity2.jpg';
import activity3 from 'assets/imgs/activity3.jpg';
import benefits_img from 'assets/imgs/benefits_img.png';
import whatis1 from 'assets/imgs/whatis1.png';
import whatis3 from 'assets/imgs/whatis3.png';
import SimpleButton from 'common/components/buttons/simpleButton';
import AboutCard from 'common/components/cards/aboutCard';
import ActivitiesCard from 'common/components/cards/activitiesCard';
import React from 'react';
import { isMobile } from 'react-device-detect';
import ItemsCarousel from 'react-items-carousel';
import './website.css';

const COMPANY_STATION_CARDS = [
  {
    image: activity1,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Participe',
    points: '20',
  },
  {
    image: activity2,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Responda',
    points: '50',
  },
  {
    image: activity3,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Enviar',
    points: '10',
  },
  {
    image: activity1,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Convide',
    points: '500',
  },
  {
    image: activity2,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Ganhe',
    points: '100',
  },
];
const COMPANY_WHAT_IS_CARDS = [
  {
    image: whatis1,

    title: 'Cultura',
    text: 'Texto sobre cultura Texto sobre cultura Texto sobre cultura Texto sobre cultura Texto sobre cultura',
  },
  {
    image: 'https://via.placeholder.com/377x288',
    title: 'Educação',
    text: 'Texto sobre educaçãoTexto sobre educaçãoTexto sobre educaçãoTexto sobre educaçãoTexto sobre educação',
  },
  {
    image: whatis3,
    title: 'Esporte e lazer',
    text: 'Texto sobre esporte e lazer',
  },
];
const COMPANY_STATION_BENEFITS = {
  image: benefits_img,
  title: 'Título - Benefícios',
  text: 'Texto sobre os benefícios do programa',
  items: ['Benefício 1', 'Benefício 2', 'Benefício 3', 'Benefício 4'],
};

export class WebsiteSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItemIndex: 0, chevronWidth: 30 };
    this.chevronWidth = 30;
  }

  setActiveItemIndex = (index) => {
    this.setState({ activeItemIndex: index });
  };

  render() {
    const { formValues } = this.props;
    const general_color = formValues ? formValues.general_color : null;
    const general_favicon_image = formValues ? formValues.general_favicon_image : null;
    const header_background_image = formValues ? formValues.header_background_image : null;
    const header_background_image_1 = formValues ? formValues.header_background_image_1 : null;
    const header_background_image_2 = formValues ? formValues.header_background_image_2 : null;
    const header_background_image_3 = formValues ? formValues.header_background_image_3 : null;
    const header_background_image_4 = formValues ? formValues.header_background_image_4 : null;
    const background_back_header = formValues ? formValues.background_back_header : null;
    const image_download_app = formValues ? formValues.image_download_app : null;
    const navbar_station_primary_logo = formValues ? formValues.navbar_station_primary_logo : null;
    const address_local = formValues ? formValues.address : null;
    const contact_mail = formValues ? formValues.contact_mail : null;
    const phoneNumber = formValues ? formValues.phone : null;
    const navbar_station_secondary_logo = formValues
      ? formValues.navbar_station_secondary_logo
      : null;
    const navbar_font_color = formValues ? formValues.navbar_font_color : null;
    const navbar_button_background_color = formValues
      ? formValues.navbar_button_background_color
      : null;
    const navbar_button_font_color = formValues ? formValues.navbar_button_font_color : null;
    const navbar_button_border_color = formValues ? formValues.navbar_button_border_color : null;
    const footer_font_color = formValues ? formValues.footer_font_color : null;
    const footer_background_color = formValues ? formValues.footer_background_color : null;
    const footer_company_logo = formValues ? formValues.footer_company_logo : null;
    const body_dark_color = formValues ? formValues.body_dark_color : null;
    const body_light_color = formValues ? formValues.body_light_color : null;
    const body_dark_font_color = formValues ? formValues.body_dark_font_color : null;
    const body_link_color = formValues ? formValues.body_link_color : null;
    const body_button_font_color = formValues ? formValues.body_button_font_color : null;
    const body_button_background_color = formValues
      ? formValues.body_button_background_color
      : null;
    const body_button_border_color = formValues ? formValues.body_button_border_color : null;
    const first_section_title = formValues ? formValues.first_section_title : null;
    const first_section_subtitle = formValues ? formValues.first_section_subtitle : null;
    const first_section_text = formValues ? formValues.first_section_text : null;
    const third_section_title = formValues ? formValues.third_section_title : null;
    const third_section_subtitle = formValues ? formValues.third_section_subtitle : null;
    const third_section_text = formValues ? formValues.third_section_text : null;

    return (
      <div className="container-fluid station-container">
        <div className="row">
          <div className="col-12">
            {/* Início do código da main */}
            <article id="main">
              <div
                className="row justify-content-center"
                style={{
                  background: `url(${
                    background_back_header
                      ? background_back_header.base64
                      : 'https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
                  })`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}>
                <div className="col-12">
                  <nav className="row justify-content-center">
                    <div className="col-10">
                      <div className="row px-5">
                        <div className="col-4 col-sm-2 my-3 pl-2">
                          <div className="d-flex h-100 justify-content-start align-items-center">
                            <a href="/">
                              <img
                                src={`${
                                  navbar_station_primary_logo
                                    ? navbar_station_primary_logo.base64
                                    : logo
                                }`}
                                alt="LOGO_ESTAÇÃO"
                                style={{ maxHeight: 60 }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-8 col-sm-10">
                          <div className="d-flex h-100 justify-content-end align-items-center">
                            <a
                              href="#oque"
                              className="mx-md-2 mx-lg-3"
                              style={{ color: navbar_font_color || '#ffffff' }}>
                              {first_section_title || 'O que é'}
                            </a>
                            <a
                              href="#beneficios"
                              className="mx-md-2 mx-lg-3"
                              style={{ color: navbar_font_color || '#ffffff' }}>
                              Baixe o App
                            </a>
                            <a
                              href="#contato"
                              className="mx-md-2 mx-lg-3"
                              style={{
                                color: navbar_font_color || '#ffffff',
                                paddingRight: '1rem',
                              }}>
                              Contato
                            </a>
                            <SimpleButton
                              background={navbar_button_background_color || '#ffffff'}
                              text={navbar_button_font_color || '#333333'}
                              border={`1px solid ${navbar_button_border_color || '#ffffff'}`}>
                              Entrar
                            </SimpleButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>

                  {/* <article className='row justify-content-center' style={{ height: '90%' }}>
                <div className='col-11'>
                  <div className='row justify-content-end align-items-center h-100'>
                    <div className='col-5'>
                      <div className='d-inline-flex flex-column'>
                        <h4 className='station-font-bold py-2' style={{ color: 'white', fontSize: '2.125rem' }}>
                          Seja muito bem-vindo!
                        </h4>
                        <p className='station-font-medium py-2' style={{ color: 'white', fontSize: '1.5rem' }}>
                          Participe mais e aproveite as vantagens de fazer parte deste programa.
                        </p>
                        <div className='row py-2'>
                          <div className='col-6'>
                            <FullButton
                              background={'#ffffff'}
                              text={'#000000'}
                              border={`1px solid ${'#ffffff'}`}
                              fontSize='0.875rem'
                              classNameProp='station-font-bold py-3 px-4'
                            >
                              Inscreva-se
                            </FullButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article> */}
                </div>
                <div className="col-12" style={{ cursor: 'pointer', padding: isMobile ? 0 : 15 }}>
                  <div
                    style={{
                      background: `url(${
                        header_background_image
                          ? header_background_image.base64
                          : 'https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
                      })`,
                      backgroundSize: isMobile ? 'cover' : 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: isMobile ? 736 : 500,
                      padding: isMobile ? -15 : 0,
                      backgroundPosition: isMobile ? 'center' : `center`,
                    }}></div>
                </div>
              </div>
            </article>
            {/* Início do código da main */}

            {/* Início do código do sobre */}
            {/* <article id='oque'>
          <article className='row py-4 justify-content-center' style={{ background: body_light_color || '#ffffff' }}>
            <div className='col-12 col-md-10 col-xl-8'>
              <div className='row justify-content-center mt-5 mt-md-3'>
                <div className='col-auto'>
                  <h1
                    className='station-font-bold'
                    style={{
                      fontSize: '0.675rem',
                      letterSpacing: '1.2px',
                      textTransform: 'uppercase',
                      color: body_dark_font_color || '#333333'
                    }}
                  >
                    O que é
                  </h1>
                </div>
              </div>
              <div className='row justify-content-center'>
                <div className='col-auto mb-3 mb-md-4'>
                  <h1 style={{ fontSize: '2.125rem', color: body_dark_font_color || '#333333' }}>
                    Participar vale a pena
                  </h1>
                </div>
              </div>
              <div className='row justify-content-center'>
                {COMPANY_WHAT_IS_CARDS.map((item, index) => {
                  return (
                    <div className='col-sm-12 col-md-4 mb-4 mb-md-0'>
                      <a href='#/login'>
                        <AboutCard
                          image={item.image}
                          title={item.title}
                          text={item.text}
                          colors={{
                            title: body_dark_font_color || '#333333',
                            text: body_dark_font_color || '#333333'
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </article>
        </article> */}
            <article id="oque">
              <article
                className="row py-4 justify-content-center"
                style={{ background: body_light_color || '#ffffff' }}>
                <div className="col-12 col-md-10 col-xl-9">
                  <div className="row justify-content-center mt-5 mt-md-3">
                    <div className="col-auto">
                      <h1
                        className="station-font-bold"
                        style={{
                          fontSize: '0.675rem',
                          letterSpacing: '1.2px',
                          textTransform: 'uppercase',
                          color: body_dark_font_color || '#333333',
                        }}>
                        {first_section_title || 'O que é'}
                      </h1>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto mb-3 mb-md-4">
                      <h1
                        style={{
                          color: body_dark_font_color || '#333333',
                        }}>
                        {first_section_subtitle || 'Participar vale a pena'}
                      </h1>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto mb-3 mb-md-5">
                      <h5
                        style={{
                          fontSize: isMobile ? '1rem' : '1.0rem',
                          textAlign: isMobile ? 'center' : 'center',
                          color: '#666',
                          overflowWrap: 'break-word',
                        }}>
                        {first_section_text ||
                          'O programa de relacionamento, onde tudo o que você pensa e faz é reconhecido e recompensado. Entre, aproveite as atividades propostas e descubra como é bom fazer parte do Sindicado Mais Moderno do Brasil.'}
                      </h5>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {COMPANY_WHAT_IS_CARDS.map((item, index) => {
                      return (
                        <div className="col-sm-12 col-md-4 mb-4 mb-md-0" key={index}>
                          <a href="#/login">
                            <AboutCard
                              image={item.image}
                              title={item.title}
                              text={item.text}
                              colors={{
                                title: body_dark_font_color || '#333333',
                                text: body_dark_font_color || '#333333',
                              }}
                            />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </article>
            </article>

            {/* Início dos benefícios */}
            <article id="beneficios">
              <a
                href="https://play.google.com/store/apps/details?id=com.rilato.programamais"
                target="_blank">
                <div
                  className="row justify-content-center"
                  style={{
                    position: 'relative',
                  }}>
                  {/* <div
            className='col-12 d-flex align-self-end'
            style={{
              backgroundColor: '#F58A75',
              backgroundSize: isMobile ? 'container' : 'cover',
              minHeight: 600,
              position: 'relative',
              backgroundImage: `url(${downloadApp})`
            }}
          > */}
                  <div
                    className="col-12 website-header-background"
                    style={{
                      background: `url(${
                        image_download_app
                          ? image_download_app.base64
                          : 'https://images.unsplash.com/photo-1541345023926-55d6e0853f4b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80'
                      })`,
                      height: 900,
                    }}></div>
                </div>
              </a>
            </article>
            {/* Início das atividades */}
            <article id="atividades">
              <div
                className="row py-4 justify-content-center "
                style={{ background: body_light_color || '#ffffff' }}>
                <div className="col-12 col-md-10 col-xl-8">
                  <div className="row justify-content-center mt-5 mt-md-3">
                    <div className="col-auto">
                      <h1
                        className="station-font-bold"
                        style={{
                          fontSize: '0.675rem',
                          letterSpacing: '1.2px',
                          textTransform: 'uppercase',
                          color: body_dark_font_color || '#333333',
                        }}>
                        {third_section_title || 'ATIVIDADES'}
                      </h1>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto mb-4 mb-md-4">
                      <h1
                        style={{
                          fontSize: isMobile ? '1.5rem' : '2.125rem',
                          textAlign: isMobile ? 'center' : '',
                          color: body_dark_font_color || '#333333',
                        }}
                        className="mb-0">
                        {third_section_subtitle || 'PARTICIPE E GANHE SEMPRE!'}
                      </h1>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto mb-5 mb-md-5">
                      <h5
                        style={{
                          fontSize: isMobile ? '1rem' : '1.0rem',
                          textAlign: isMobile ? 'center' : 'center',
                          color: '#666',
                          overflowWrap: 'break-word',
                        }}>
                        {third_section_text ||
                          'Além de reconhecimento e recompensa, as atividades são pensadas para oferecer benefícios relevantes para você e sua família. Mantenha-se conectado, que nós temos sempre uma novidade para propor.'}
                      </h5>
                    </div>
                  </div>
                  {/* <div className='row justify-content-between mb-4 align-items-center'>
                    <div className=' col-md-8 col-lg-8 col-xl-9'>
                      <h6
                        className='station-font-medium mb-4 mb-md-0'
                        style={{ color: body_dark_font_color || '#333333', fontSize: '1.25rem', lineHeight: '2rem' }}
                      >
                        As atividades proporcionam a melhor maneira de interagir para ganhar dinheiro!
                      </h6>
                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-3 text-center'>
                      <FullButton
                        background={body_button_background_color || '#0284fe'}
                        text={body_button_font_color || '#ffffff'}
                        border={`1px solid ${body_button_border_color || '#0284fe'}`}
                        classNameProp='station-font-medium py-2 px-4'
                      >
                        Todas Atividades
                      </FullButton>
                    </div> </div> */}

                  <div className="row justify-content-center" style={{ minHeight: 400 }}>
                    <div className="col-12">
                      <div style={{ padding: `0 ${this.chevronWidth}px` }}>
                        <ItemsCarousel
                          requestToChangeActive={this.setActiveItemIndex}
                          activeItemIndex={this.state.activeItemIndex}
                          numberOfCards={isMobile ? 1 : 2}
                          gutter={20}
                          leftChevron={<FontAwesomeIcon icon={faChevronLeft} />}
                          rightChevron={<FontAwesomeIcon icon={faChevronRight} />}
                          outsideChevron
                          chevronWidth={this.state.chevronWidth}>
                          {COMPANY_STATION_CARDS.map((item, index) => (
                            <div style={{ cursor: 'pointer', height: 400 }} key={index}>
                              <ActivitiesCard
                                image={item.image}
                                title={item.title}
                                text={item.text}
                                callToAction={item.callToAction}
                                reward={`R$ ${parseFloat(item.points)
                                  .toFixed(2)
                                  .replace('.', ',')}`}
                                colors={{
                                  reward: '#666666',
                                  title: '#999999',
                                  text: body_dark_font_color || '#333333',
                                  callToAction: body_link_color || '#006ce6',
                                  chevron: '#cccccc',
                                }}
                                hasIcon
                                hasImage
                              />
                            </div>
                          ))}
                        </ItemsCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            {/* Início do footer */}
            <article id="contato">
              <div
                className="row py-3 py-md-5 px-3 px-md-5 justify-content-center"
                style={{
                  background: footer_background_color || 'rgb(82, 82, 82)',
                }}>
                <div className="col-12 col-md-10">
                  <div className="row justify-content-between">
                    <div className="col-8 col-sm-4 col-md-3 col-lg-2 align-self-center mb-3 mb-sm-0">
                      <img
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          maxHeight: 100,
                        }}
                        src={footer_company_logo ? footer_company_logo.base64 : logo}
                        alt="LOGO-EMPRESA"
                      />
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className={`d-flex flex-column`}>
                        {/* <h4 style={{ color: COMPANY_COLORS.font.light }} className='pb-3 station-font-black'>
                    Contato
                  </h4> */}
                        <div className="d-flex">
                          {/* <img src={address} style={{ width: 16, height: 24 }} alt='ICONE-ENDERECO' />{' '} */}
                          <p
                            style={{
                              color: footer_font_color || '#ffffff',
                              overflowWrap: 'break-word',
                              overflow: 'auto',
                            }}
                            className="station-footer-info ml-3">
                            {address_local || 'Endereço'}
                          </p>
                        </div>
                        <div className="d-flex">
                          {/* <img src={phone} style={{ width: 22, height: 24 }} alt='ICONE-TELEFONE' />{' '} */}
                          <p
                            style={{
                              color: footer_font_color || '#ffffff',
                              overflowWrap: 'break-word',
                              overflow: 'auto',
                            }}
                            className="ml-3">
                            {phoneNumber || 'Telefone'}
                          </p>
                        </div>
                        <div className="d-flex">
                          {/* <img src={mail} style={{ width: 24, height: 18 }} alt='ICONE-EMAIL' />{' '} */}
                          <p
                            style={{
                              color: footer_font_color || '#ffffff',
                              overflowWrap: 'break-word',
                              overflow: 'auto',
                            }}
                            className="ml-3">
                            {contact_mail || 'E-mail para contato'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            {/* Início do copyright */}
            <article id="copyright">
              <div className="row justify-content-center" style={{ background: '#fffffff' }}>
                <div className="col-10 d-flex justify-content-center">
                  <p className="py-2 my-0" style={{ color: '#333333' }}>
                    Todos os direitos reservados. 2019
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    );
  }
}

WebsiteSimulator.propTypes = {
  formValues: PropTypes.shape({
    address: PropTypes.any,
    background_back_header: PropTypes.any,
    body_button_background_color: PropTypes.any,
    body_button_border_color: PropTypes.any,
    body_button_font_color: PropTypes.any,
    body_dark_color: PropTypes.any,
    body_dark_font_color: PropTypes.any,
    body_light_color: PropTypes.any,
    body_link_color: PropTypes.any,
    contact_mail: PropTypes.any,
    first_section_subtitle: PropTypes.any,
    first_section_text: PropTypes.any,
    first_section_title: PropTypes.any,
    footer_background_color: PropTypes.any,
    footer_company_logo: PropTypes.any,
    footer_font_color: PropTypes.any,
    general_color: PropTypes.any,
    general_favicon_image: PropTypes.any,
    header_background_image: PropTypes.any,
    header_background_image_1: PropTypes.any,
    header_background_image_2: PropTypes.any,
    header_background_image_3: PropTypes.any,
    header_background_image_4: PropTypes.any,
    image_download_app: PropTypes.any,
    navbar_button_background_color: PropTypes.any,
    navbar_button_border_color: PropTypes.any,
    navbar_button_font_color: PropTypes.any,
    navbar_font_color: PropTypes.any,
    navbar_station_primary_logo: PropTypes.any,
    navbar_station_secondary_logo: PropTypes.any,
    phone: PropTypes.any,
    third_section_subtitle: PropTypes.any,
    third_section_text: PropTypes.any,
    third_section_title: PropTypes.any,
  }),
};
export default WebsiteSimulator;
