import PropTypes from 'prop-types';
import React, { Component } from 'react';
import logo_horizontal_svg from 'assets/img/logo_horizontal_svg.svg';
import magnifier from 'assets/img/lupa-positive.svg';
import NavBarItem from './navBarItem';
import './navBarSite.css';

class NavBarSite extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-center">
            <div className="navbar-logo my-4">
              <span>
                <img alt="" src={logo_horizontal_svg} />
              </span>
            </div>
          </div>
          <div className="col-auto text-center navbar-item">
            <NavBarItem
              font={{
                family: 'Roboto',
                weight: 700,
                size: 12,
                color: '#2B2F69',
                letterSpacing: '0.2em',
              }}
              type="text"
              value="SOBRE"
            />
          </div>
          <div className="col-auto text-center navbar-item">
            <NavBarItem
              font={{
                family: 'Roboto',
                weight: 700,
                size: 12,
                color: '#2B2F69',
                letterSpacing: '0.2em',
              }}
              type="text"
              value="PRODUTOS"
            />
          </div>
          <div className="col-auto text-center navbar-item">
            <NavBarItem
              type="dropdown"
              font={{
                family: 'Roboto',
                weight: 700,
                size: 12,
                color: '#2B2F69',
                letterSpacing: '0.2em',
              }}
              value="PLANOS"
            />
          </div>
          <div className="col text-right">
            <img alt="" src={magnifier} className="py-4 mr-4" />
            <a href="#/owner">
              <div className="header-button py-2 px-5">TESTE AGORA</div>
            </a>
          </div>
        </div>
        <div className="row">{this.props.children}</div>
      </div>
    );
  }
}

NavBarSite.propTypes = {
  children: PropTypes.any,
};

export default NavBarSite;
