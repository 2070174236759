import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardTitle, CardText } from 'react-md';
import { bindActionCreators } from 'redux';
import Content from 'common/components/content/content';
import Table from 'common/components/table/table';
import { remove, getUsersByEstablishmentId } from './manageActions';

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.props.getUsersByEstablishmentId(this.props.auth.user.establishments[0].id);
  }

  render() {
    const { router } = this.props;
    const { users } = this.props.ownerUsers;
    return (
      <Content>
        <Card>
          <CardTitle title="Usuários" />
          <CardText>
            <Table
              path={'/owner/owner-users'}
              router={router}
              values={users}
              columns={[
                { field: 'id', header: 'Id' },
                { field: 'name', header: 'Nome' },
                { field: 'email', header: 'Descrição' },
                { field: 'createdAt', header: 'Data de entrada' },
              ]}
              remove={this.props.remove}
              permissions={[]}
            />
          </CardText>
        </Card>
      </Content>
    );
  }
}

ManageUsers.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getUsersByEstablishmentId: PropTypes.func,
  ownerUsers: PropTypes.shape({
    users: PropTypes.any,
  }),
  remove: PropTypes.any,
  router: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ownerUsers: state.ownerUsers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUsersByEstablishmentId,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
