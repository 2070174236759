import PropTypes from 'prop-types';
import React from 'react';
import iphone8Mockup from 'assets/img/iphone8_mockup.png';
import logo from 'assets/img/fan_logo.svg';

// import { Container } from './styles';

function PushSimulator({ title, body }) {
  return (
    <div
      style={{
        position: 'relative',
        backgroundImage: `url(${iphone8Mockup})`,
        width: 319,
        height: 640,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      <div
        style={{
          position: 'absolute',
          top: '35%',
          left: '10%',
          width: 250,
          borderRadius: 10,
          boxShadow: '5px',
          backgroundColor: '#00000066',
          padding: 8,
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="" style={{ height: 20, width: 20 }} />
          <p
            style={{
              fontWeight: 400,
              color: '#ffffff66',
              textTransform: 'uppercase',
              margin: '2px 0px 2px 8px',
              fontSize: 12,
            }}>
            APP
          </p>
        </div>
        <p
          style={{
            fontWeight: 700,
            color: '#fff',
            margin: '0px 0px',
            overflowX: 'hidden',
          }}>
          {title}
        </p>
        <p
          style={{
            fontWeight: 400,
            color: '#fff',
            margin: '0px 0px',
            lineHeight: 1,
            overflowWrap: 'break-word',
          }}>
          {body}
        </p>
      </div>
    </div>
  );
}

PushSimulator.propTypes = {
  body: PropTypes.any,
  title: PropTypes.any,
};

export default PushSimulator;
