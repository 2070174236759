import { BASE_API, USER_TOKEN } from 'config/consts';
import axios from 'axios';
import _ from 'lodash';

const URL_BUY_POINTS = `${BASE_API}/transfer-request`;

const buyPoints = (values, id, router = undefined, firstTime) => {
  return (dispatch) => {
    dispatch([
      { type: 'OWNER_POINTS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    const body = {
      points: values.points,
      establishment_id: values.establishment_id,
      user_id: id,
      type_id: 1,
      status_id: 1,
    };
    axios
      .post(URL_BUY_POINTS, body, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: `Agora é só aguardar e checar os e-mails. Vamos te notificar`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(router)) {
          if (firstTime) {
            router.push('/owner/points/?first_action=true');
          } else {
            router.push('/owner/points/transferencias');
          }
        }
      })
      .catch((e) => {
        dispatch([
          { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export default buyPoints;
