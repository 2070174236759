import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize } from 'redux-form';
import types from './types';

const URL_GROUPING = `${BASE_API}/groupings`;
const URL_GROUPINGS = `${BASE_API}/groupings-list-table`;
const URL_GROUPINGS_STATION = `${BASE_API}/groupings-station`;
const URL_GROUPINGS_PARTICIPANTS = `${BASE_API}/groupings-participants-list`;

export const getGroupingsStationId = (station_id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_GROUPINGS}/?where[active]=1&&where[station_id]=${station_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const getGroupingsParticipantsStationId = (station_id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_PARTICIPANTS_FETCHED, payload: true });
    axios
      .get(
        `${URL_GROUPINGS_PARTICIPANTS}/?where[station_id]=${station_id}&where[active]=1&where[type_id]=2`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: types.LIST_PARTICIPANTS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const getGroupingById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_GROUPING}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const responseFormatted = {
          ...response.data,
          participants: response.data?.grouping_user?.map((it) => ({
            id: it.user.id,
            title: it.user.name,
          })),
          actions: response.data?.action_grouping?.map((it) => ({
            id: it.action.id,
            title: it.action.name,
          })),
        };
        // console.log(response.data, responseFormatted);
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: responseFormatted,
          },
          initializeForm(responseFormatted),
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

const initializeForm = (values) => {
  return initialize('groupingsForm', values);
};

export const deleteGroupingById = (groupingcode_id, station_id, setVisible) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .delete(`${URL_GROUPING}/${groupingcode_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: types.BUTTON_LOAD, payload: false }, getGroupingsStationId(station_id)]);
        setVisible({ visible: false });
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const createGrouping = (values, station_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(`${URL_GROUPINGS_STATION}/${station_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/groupings');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const editGrouping = (values, groupingcode_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .put(`${URL_GROUPING}/${groupingcode_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/groupings');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};
