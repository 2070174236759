import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { change, initialize } from 'redux-form';
import _ from 'lodash';
import types from './types';

const URL_STATES = `https://servicodados.ibge.gov.br/api/v1/localidades/estados`;
const URL_CITIES_BY_STATE = `https://servicodados.ibge.gov.br/api/v1/localidades/estados`;
const URL_ADDRESS = `${BASE_API}/addresses-establishment`;
const URL_ADDRESS_POST = `${BASE_API}/addresses`;

export const getStatesList = () => {
  return (dispatch) => {
    dispatch([
      {
        type: types.LIST_LOAD,
        payload: true,
      },
    ]);
    axios.get(`${URL_STATES}`).then((response) => {
      dispatch([
        {
          type: 'STATES_LIST_FETCHED',
          payload: response.data,
        },
      ]);
    });
  };
};

export const getCitiesList = (stateID) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.LIST_LOAD,
        payload: true,
      },
    ]);
    axios.get(`${URL_CITIES_BY_STATE}/${stateID}/municipios`).then((response) => {
      dispatch([
        {
          type: 'CITIES_LIST_FETCHED',
          payload: response.data,
        },
      ]);
    });
  };
};

export function update(values, id) {
  return (dispatch) => {
    dispatch([
      { type: types.LIST_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ADDRESS}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.LIST_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: types.LIST_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export function create(values) {
  return (dispatch) => {
    dispatch([
      { type: types.LIST_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_ADDRESS_POST}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.LIST_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: types.LIST_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const changeField = (form, field, value) => {
  return change(form, field, value);
};

export const initializeForm = (values) => {
  return initialize('AddressesForm', values);
};

export const getAddressByEstablishment = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.LIST_LOAD,
        payload: true,
      },
    ]);
    axios.get(`${URL_STATES}`).then((statesResponse) => {
      dispatch([
        {
          type: 'STATES_LIST_FETCHED',
          payload: statesResponse.data,
        },
      ]);
      axios
        .get(`${URL_ADDRESS}/${id}`, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((response) => {
          if (!_.isEmpty(response.data)) {
            const stateID = statesResponse.data.find(
              (item) => response.data.state === item.sigla
            ).id;
            axios.get(`${URL_CITIES_BY_STATE}/${stateID}/municipios`).then((citiesResponse) => {
              dispatch([
                {
                  type: 'CITIES_LIST_FETCHED',
                  payload: citiesResponse.data,
                },
              ]);
              const initialValues = {
                ...response.data,
              };
              dispatch([
                {
                  type: types.ITEM_DETAIL,
                  payload: response.data,
                },
                initializeForm(initialValues),
              ]);
            });
          }
        });
    });
  };
};
