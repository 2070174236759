import PropTypes from 'prop-types';
import { Box, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeActive, getList } from './automationsActions';

export function Automations(props) {
  const { list, loading } = props.automations;
  function sendToEdit(type, id) {
    if (type === 1) {
      props.router.push(`/owner/automatizacoes/boas-vindas/${id}/editar`);
    }
  }
  const [activate, setActivate] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  function handleActivate() {
    return (
      <div>
        <Dialog
          open={activate}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setActivate(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">{'Ativar automatização'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Tem certeza que quer ativar essa automatização?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setActivate(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={async () => {
                props.changeActive(id, props.auth.user.establishments[0].stations[0].id);
                setActivate(false);
              }}
              color="primary">
              {loading ? 'Ativando' : 'Confirmar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function handleDeactivate() {
    return (
      <div>
        <Dialog
          open={deactivate}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDeactivate(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title">{'Destivar automatização'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Tem certeza que quer desativar essa automatização?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeactivate(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={async () => {
                props.changeActive(id, props.auth.user.establishments[0].stations[0].id);
                setDeactivate(false);
              }}
              color="primary">
              {loading ? 'Ativando' : 'Confirmar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não possui automatizações</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  function renderLines() {
    return (
      <Box display="flex" flexDirection="column">
        {props.automations.list.content.map((item, index) => (
          <Box
            key={index}
            style={{
              background: 'white',
              width: '100%',
              padding: 20,
              marginTop: 20,
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Box>{item.type.name}</Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <a
                  style={{ cursor: 'pointer', color: '#2D95EF' }}
                  onClick={() => sendToEdit(item.type.id, item.id)}>
                  Personalizar
                </a>
              </Box>
              <Box>
                {item.active ? (
                  <Switch
                    color="primary"
                    required
                    checked={true}
                    onChange={() => {
                      setId(item.id);
                      setDeactivate(true);
                    }}
                    value="switch"
                  />
                ) : (
                  <Switch
                    color="primary"
                    required
                    checked={false}
                    onChange={() => {
                      setId(item.id);
                      setActivate(true);
                    }}
                    value="switch"
                  />
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
  return (
    <section id="automations">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          {renderLines()}
        </GeneralGrid>
      </GeneralGrid>
      {handleActivate()}
      {handleDeactivate()}
    </section>
  );
}

Automations.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  automations: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func,
      }),
    }),
    loading: PropTypes.any,
  }),
  changeActive: PropTypes.func,
  getList: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    automations: state.automations,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      changeActive,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Automations);
