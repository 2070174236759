/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import activity1 from 'assets/imgs/activity1.jpg';
import activity2 from 'assets/imgs/activity2.jpg';
import activity3 from 'assets/imgs/activity3.jpg';
import Spinner from 'common/components/spinner/spinner';
import whatis1 from 'assets/imgs/whatis1.png';
import whatis3 from 'assets/imgs/whatis3.png';
import SimpleButton from 'common/components/buttons/simpleButton';
import AboutCard from 'common/components/cards/aboutCard';
import { BASE_S3 } from 'config/consts';
import React from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import ItemsCarousel from 'react-items-carousel';
import DefaultCard from 'common/components/cards/DefaultCard';
import './website.css';
const chevronWidth = 30;
const COMPANY_STATION_CARDS = [
  {
    image: activity1,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Participe',
    points: '20',
  },
  {
    image: activity2,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Responda',
    points: '50',
  },
  {
    image: activity3,
    title: 'Título',
    text: 'Texto sobre o card',
    callToAction: 'Enviar',
    points: '10',
  },
];
const COMPANY_WHAT_IS_CARDS = [
  {
    image: whatis1,

    title: 'Cultura',
    text: 'Texto sobre cultura Texto sobre cultura Texto sobre cultura Texto sobre cultura Texto sobre cultura',
  },
  {
    image: 'https://via.placeholder.com/377x288',
    title: 'Educação',
    text: 'Texto sobre educaçãoTexto sobre educaçãoTexto sobre educaçãoTexto sobre educaçãoTexto sobre educação',
  },
  {
    image: whatis3,
    title: 'Esporte e lazer',
    text: 'Texto sobre esporte e lazer',
  },
];

export class WebsiteSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItemIndex: 0, chevronWidth: 30 };
    const { formValues } = this.props;
  }

  setActiveItemIndex = (index) => {
    this.setState({ activeItemIndex: index });
  };

  renderBenefits = (item, index) => {
    const { formValues } = this.props;
    const general_color = formValues?.general_color;
    const general_favicon_image = formValues?.general_favicon_image;
    const header_background_image = formValues?.header_background_image;
    const header_background_image_1 = formValues?.header_background_image_1;
    const header_background_image_2 = formValues?.header_background_image_2;
    const header_background_image_3 = formValues?.header_background_image_3;
    const header_background_image_4 = formValues?.header_background_image_4;
    const background_back_header = formValues?.background_back_header;
    const navbar_station_primary_logo = formValues?.navbar_station_primary_logo;
    const address_local = formValues?.address;
    const image_download_app = formValues?.image_download_app;
    const contact_mail = formValues?.contact_mail;
    const phoneNumber = formValues?.phone;
    const navbar_station_secondary_logo = formValues?.navbar_station_secondary_logo;
    const navbar_font_color = formValues?.navbar_font_color;
    const navbar_button_background_color = formValues?.navbar_button_background_color;
    const navbar_button_font_color = formValues?.navbar_button_font_color;
    const navbar_button_border_color = formValues?.navbar_button_border_color;
    const footer_font_color = formValues?.footer_font_color;
    const footer_background_color = formValues?.footer_background_color;
    const footer_company_logo = formValues?.footer_company_logo;
    const body_dark_color = formValues?.body_dark_color;
    const body_light_color = formValues?.body_light_color;
    const body_dark_font_color = formValues?.body_dark_font_color;
    const body_link_color = formValues?.body_link_color;
    const body_button_font_color = formValues?.body_button_font_color;
    const body_button_background_color = formValues?.body_button_background_color;
    const body_button_border_color = formValues?.body_button_border_color;
    const first_section_title = formValues?.first_section_title;
    const first_section_subtitle = formValues?.first_section_subtitle;
    const first_section_text = formValues?.first_section_text;
    const third_section_title = formValues?.third_section_title;
    const third_section_subtitle = formValues?.third_section_subtitle;
    const third_section_text = formValues?.third_section_text;
    return (
      <div
        key={index}
        className="row py-3 justify-content-center"
        style={{
          backgroundColor: body_light_color,
        }}>
        <div
          className="col-12 col-md-10 col-xl-8"
          style={{
            display: 'flex',
            height: isMobileOnly ? 658 : 500,
            width: !isMobileOnly ? 1190 : 540,
            justifyContent: !isMobileOnly ? 'flex-end' : 'center',
            alignItems: 'center',
            backgroundImage: `url(${BASE_S3}/${image_download_app})`,
            backgroundRepeat: 'no repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            alignSelf: 'center',
          }}>
          <div
            style={{
              width: !isMobileOnly ? '50%' : '90%',
              height: 300,
              maxWidth: 600,
              justifyContent: 'center',
              alignItems: isMobileOnly ? 'flex-start' : 'flex-start',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <h1
              style={{
                width: isMobileOnly ? '85%' : '65%',
                fontSize: isMobileOnly ? '2.125rem' : '2.125rem',
                textAlign: 'left',
                color: footer_font_color,
                fontWeight: 'bold',
                marginLeft: 10,
              }}
              className="my-3">
              {item?.title || 'Baixe nosso App!'}
            </h1>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 'auto',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <a
                href={
                  isMobileOnly
                    ? this.props.station.ios
                    : this.props.station.ios.replace('itms-apps://', 'https://')
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: isMobileOnly ? '50%' : 'auto',
                  // width: '50%',
                  objectFit: 'contain',
                  height: 65,
                }}>
                <img
                  src={
                    'https://i0.wp.com/loja.tools4life.com.br/wp-content/uploads/2019/04/app-store-badge.png?ssl=1'
                  }
                  alt="Badge App Store"
                  style={{
                    width: isMobileOnly ? '100%' : 'auto',
                    // width: '100%',
                    objectFit: 'contain',
                    height: '100%',
                  }}
                />
              </a>
              <a
                href={
                  isMobileOnly
                    ? this.props.station.android
                    : this.props.station.android.replace(
                        'market://',
                        'https://play.google.com/store/apps/'
                      )
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: isMobileOnly ? '50%' : 'auto',

                  objectFit: 'contain',
                  height: 82,
                }}>
                <img
                  src={`https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png`}
                  alt="Badge Google Play"
                  style={{
                    width: isMobileOnly ? '100%' : 'auto',
                    objectFit: 'contain',
                    height: '100%',
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderActivities = (item, index) => {
    const { formValues } = this.props;
    const body_dark_font_color = formValues?.body_dark_font_color;

    return (
      <div
        className="row py-3  justify-content-center "
        style={{
          background:
            item.background_type === 'image'
              ? `url(${BASE_S3}/${item.background_image})`
              : item.background_type === 'color'
              ? item.background_color
              : '#fff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `center`,
        }}>
        <div className="col-12 col-md-10 col-xl-9 pt-5">
          <div
            className="my-3"
            style={{
              margin: '0 auto',
              width: isMobileOnly ? '85%' : '50%',
            }}>
            <div className="row justify-content-center">
              <div className="col-auto mb-4 mb-md-4">
                <h1
                  style={{
                    fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                    textAlign: isMobileOnly ? 'center' : 'center',
                    color: body_dark_font_color,
                  }}
                  className="mb-0">
                  {item.title || 'Subtítulo'}
                </h1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto mb-4 mb-md-4">
                <h5
                  style={{
                    fontSize: isMobileOnly ? '1rem' : '1.0rem',
                    textAlign: isMobileOnly ? 'center' : 'center',
                    color: '#666',
                  }}>
                  {item.subtitle || 'Texto'}
                </h5>
              </div>
            </div>
          </div>

          <div className="row" style={{ minHeight: 400 }}>
            <div className="col-12">
              <div style={{ padding: `0 ${chevronWidth}px` }}>
                <ItemsCarousel
                  requestToChangeActive={this.setActiveItemIndex}
                  activeItemIndex={this.state.activeItemIndex}
                  numberOfCards={isMobileOnly ? 1 : isTablet ? 2 : 3}
                  gutter={20}
                  leftChevron={<FontAwesomeIcon icon={faChevronLeft} />}
                  rightChevron={<FontAwesomeIcon icon={faChevronRight} />}
                  outsideChevron
                  chevronWidth={chevronWidth}>
                  {!this.props.activeActions.loading ? (
                    this.props.activeActions.list
                      .filter((item) => item.featuredPublic)
                      .map((item, index) => {
                        return (
                          <div style={{ cursor: 'pointer', height: 400 }} key={index.toString()}>
                            <DefaultCard
                              imgSource={`${BASE_S3}/${item.image}`}
                              actionName={item.name}
                              labelClick={`> ${item.click_me_label}` || '> Participe'}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <div className="d-flex">
                      <Spinner />
                    </div>
                  )}
                </ItemsCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMain = () => {
    const { formValues } = this.props;
    const background_back_header = formValues?.background_back_header;
    const navbar_station_primary_logo = formValues?.navbar_station_primary_logo;
    const navbar_font_color = formValues?.navbar_font_color;
    const navbar_button_background_color = formValues?.navbar_button_background_color;
    const navbar_button_font_color = formValues?.navbar_button_font_color;
    const navbar_button_border_color = formValues?.navbar_button_border_color;

    return (
      <div className="col">
        {isMobileOnly ? (
          <div className="col-12" style={{ cursor: 'pointer' }}>
            <NavBarBootstrap has_App={this.props.station.has_app} color={`transparent`} />
          </div>
        ) : (
          // <div className="col-12" style={{ cursor: 'pointer' }}>
          <nav className="row justify-content-center" style={{ cursor: 'pointer', paddingTop: 24 }}>
            <div className="col-12">
              <div className={`d-flex justify-content-between`}>
                <div className={`d-flex justify-content-start`}>
                  <a href="/" style={{ marginLeft: 24 }}>
                    <img
                      src={
                        navbar_station_primary_logo?.base64 ||
                        `${BASE_S3}/${navbar_station_primary_logo}`
                      }
                      alt="LOGO_ESTAÇÃO"
                      style={{ maxHeight: 60 }}
                    />
                  </a>
                  <div style={{ marginLeft: 48 }}>
                    <div className="d-flex h-100 justify-content-end align-items-center">
                      {this.props.websiteSections.list.content.length < 5 ? (
                        this.props.websiteSections.list.content.map((item, index) => (
                          <a
                            key={index}
                            href={`#secao${item.id}`}
                            style={{
                              color: navbar_font_color,
                              fontSize: isTablet ? 14 : 16,
                              marginLeft: 24,
                            }}>
                            {item.menu_title}
                          </a>
                        ))
                      ) : (
                        <>
                          <button
                            className={`btn btn-secondary dropdown-toggle`}
                            type="button"
                            data-toggle="dropdown"
                            id={'dropdownMenu'}
                            aria-haspopup="true"
                            style={{
                              background: 'transparent',
                              color: navbar_font_color,
                              border: 'none',
                              fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontSize: '16px',
                              paddingLeft: 0,
                              paddingRight: 16,
                            }}>
                            <span style={{ marginLeft: 10 }}>Seções</span>
                          </button>

                          <div className="dropdown-menu" aria-labelledby={'dropdownMenu'}>
                            {this.props.websiteSections.list.content.map((item, index) => (
                              <a
                                key={index}
                                className="dropdown-item"
                                type="button"
                                style={{
                                  marginBottom: 10,
                                }}
                                href={`#secao${item.id}`}>
                                {item.menu_title}
                              </a>
                            ))}
                          </div>
                        </>
                      )}
                      {this.props.station.plans && this.props.station.plans.length > 0 ? (
                        <a
                          href="#plans"
                          style={{
                            color: navbar_font_color,
                            paddingRight: '1rem',
                            fontSize: isTablet ? 14 : 16,
                            marginLeft: 24,
                          }}>
                          Planos
                        </a>
                      ) : null}
                      <a
                        href="#suporte"
                        style={{
                          color: navbar_font_color,
                          paddingRight: '1rem',
                          fontSize: isTablet ? 14 : 16,
                          marginLeft: 24,
                        }}>
                        Fale Conosco
                      </a>
                    </div>
                  </div>
                </div>
                <div style={{ marginRight: 24 }}>
                  <div className="d-flex h-100 justify-content-end align-items-center">
                    <a
                      href="#"
                      style={{
                        color: navbar_font_color,
                        fontSize: isTablet ? 14 : 16,
                        marginRight: 24,
                      }}>
                      Entrar
                    </a>
                    <SimpleButton
                      background={navbar_button_background_color}
                      text={navbar_button_font_color}
                      border={`1px solid ${navbar_button_border_color}`}
                      height={48}
                      width={168}>
                      Cadastre-se
                    </SimpleButton>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        )}
      </div>
    );
  };

  render() {
    const { formValues } = this.props;
    const general_color = formValues?.general_color;
    const general_favicon_image = formValues?.general_favicon_image;
    const header_background_image = formValues?.header_background_image;
    const header_background_image_1 = formValues?.header_background_image_1;
    const header_background_image_2 = formValues?.header_background_image_2;
    const header_background_image_3 = formValues?.header_background_image_3;
    const header_background_image_4 = formValues?.header_background_image_4;
    const background_back_header = formValues?.background_back_header;
    const navbar_station_primary_logo = formValues?.navbar_station_primary_logo;
    const address_local = formValues?.address;
    const image_download_app = formValues?.image_download_app;
    const contact_mail = formValues?.contact_mail;
    const phoneNumber = formValues?.phone;
    const navbar_station_secondary_logo = formValues?.navbar_station_secondary_logo;
    const navbar_font_color = formValues?.navbar_font_color;
    const navbar_button_background_color = formValues?.navbar_button_background_color;
    const navbar_button_font_color = formValues?.navbar_button_font_color;
    const navbar_button_border_color = formValues?.navbar_button_border_color;
    const footer_font_color = formValues?.footer_font_color;
    const footer_background_color = formValues?.footer_background_color;
    const footer_company_logo = formValues?.footer_company_logo;
    const body_dark_color = formValues?.body_dark_color;
    const body_light_color = formValues?.body_light_color;
    const body_dark_font_color = formValues?.body_dark_font_color;
    const body_link_color = formValues?.body_link_color;
    const body_button_font_color = formValues?.body_button_font_color;
    const body_button_background_color = formValues?.body_button_background_color;
    const body_button_border_color = formValues?.body_button_border_color;
    const first_section_title = formValues?.first_section_title;
    const first_section_subtitle = formValues?.first_section_subtitle;
    const first_section_text = formValues?.first_section_text;
    const third_section_title = formValues?.third_section_title;
    const third_section_subtitle = formValues?.third_section_subtitle;
    const third_section_text = formValues?.third_section_text;

    return (
      <div className="container-fluid station-container">
        {/* Início do código da main */}
        {this.props.websiteSections.list.content.map((item, index) => {
          if (item.type_id === 1) {
            return (
              <section
                key={index}
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}>
                {index === 0 ? this.renderMain(item) : null}
                <article
                  key={index.toString()}
                  className="row justify-content-center pt-5"
                  style={{ marginTop: index === 0 ? 32 : 0 }}>
                  <div className="col-12 col-md-10 col-xl-9 ">
                    <div
                      className="my-3"
                      style={{
                        margin: '0 auto',
                        width: isMobileOnly ? '85%' : '50%',
                      }}>
                      <div className="row justify-content-center">
                        <div className="col-auto mb-3 mb-md-3">
                          <h1
                            style={{
                              fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                              textAlign: isMobileOnly ? 'center' : 'center',
                              color: navbar_font_color,
                            }}>
                            {item.title || 'Subtítulo'}
                          </h1>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-auto mb-3 mb-md-5">
                          <h5
                            style={{
                              fontSize: isMobileOnly ? '1rem' : '1.0rem',
                              textAlign: isMobileOnly ? 'center' : 'center',
                              color: navbar_font_color,
                            }}>
                            {item.subtitle || 'Texto'}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {item.section_items.map((sectionItem, index) => (
                        <div className="col-sm-12 col-md-4 mb-4 mb-md-0" key={index.toString()}>
                          <a
                            href={
                              sectionItem.link_type === 'nolink'
                                ? '#'
                                : sectionItem.link_type === 'login'
                                ? '#/login'
                                : sectionItem.link_type === 'signup'
                                ? '#/cadastrar'
                                : sectionItem.link_type === 'additional'
                                ? `#/sobre/${sectionItem.id}`
                                : '#'
                            }>
                            <AboutCard
                              image={sectionItem.image}
                              title={sectionItem.name}
                              text={sectionItem.description}
                              colors={{
                                title: body_dark_font_color || '#333333',
                                text: body_dark_font_color || '#333333',
                              }}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </article>
              </section>
            );
          } else if (item.type_id === 2) {
            if (isMobileOnly) {
              return (
                <section
                  key={item.id}
                  id={`secao${item.id}`}
                  style={{
                    background:
                      item.background_type === 'image'
                        ? `url(${BASE_S3}/${item.background_image})`
                        : item.background_type === 'color'
                        ? item.background_color
                        : '#fff',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `center`,
                  }}>
                  {index === 0 ? this.renderMain(item) : null}
                  {item.title && item.subtitle ? (
                    <article
                      className="row justify-content-center pt-5"
                      style={{ marginTop: index === 0 ? 32 : 0 }}>
                      <div className="col-12 col-md-10 col-xl-9 ">
                        <div
                          className="my-3"
                          style={{
                            margin: '0 auto',
                            width: isMobileOnly ? '85%' : '50%',
                          }}>
                          <div className="row justify-content-center">
                            <div className="col-auto mb-3 mb-md-3">
                              <h1
                                style={{
                                  fontSize: '2.125rem',
                                  textAlign: 'center',
                                  color: index === 0 ? navbar_font_color : '#000',
                                }}>
                                {item.title || 'Subtítulo'}
                              </h1>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-auto mb-3 mb-md-5">
                              <h5
                                style={{
                                  fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                  textAlign: 'center',
                                  color: index === 0 ? navbar_font_color : '#666',
                                }}>
                                {item.subtitle || 'Texto'}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  ) : null}
                  {item.section_items.map((sectionItem, index) => (
                    <div
                      key={index}
                      className="container"
                      style={{
                        marginTop: index === 0 ? (item.title && item.subtitle ? 0 : 32) : 0,
                      }}>
                      <div className="row">
                        <>
                          <div className="col-xs-12 col-sm-12 col-md-2"></div>

                          <div className="col-xs-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-content-center align-items-center">
                            <span
                              style={{
                                fontFamily: 'Lato',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: index !== 0 ? '28px' : '48px',
                                lineHeight: index !== 0 ? '32px' : '50px',
                                display: 'flex',
                                alignItems: 'flex-end',

                                color: index === 0 ? navbar_font_color : '#000',
                                maxWidth: '100%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}>
                              {sectionItem.name}
                            </span>
                            <span
                              style={{
                                fontFamily: 'Lato',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '18px',
                                lineHeight: '29px',
                                color: index === 0 ? navbar_font_color : '#666',
                                maxWidth: '80%',
                                marginBottom: 25,
                                textAlign: 'center',
                              }}>
                              {sectionItem.description}
                            </span>

                            {sectionItem.button_label && sectionItem.link_type !== 'nolink' ? (
                              <button
                                style={{
                                  padding: 10,
                                  borderRadius: 5,
                                  background: navbar_button_background_color,
                                  color: navbar_button_font_color,
                                  maxWidth: isMobileOnly ? '50%' : '33%',
                                  height: 48,
                                }}
                                onClick={() => {
                                  return sectionItem.link_type === 'nolink'
                                    ? null
                                    : sectionItem.link_type === 'login'
                                    ? this.props.router.push('/login')
                                    : sectionItem.link_type === 'signup'
                                    ? this.props.router.push('/cadastrar')
                                    : sectionItem.link_type === 'additional'
                                    ? this.props.router.push(`/sobre/${sectionItem.id}`)
                                    : null;
                                }}>
                                {sectionItem.button_label}
                              </button>
                            ) : null}
                            <img
                              src={BASE_S3 + '/' + sectionItem.image}
                              style={{
                                maxWidth: '100%',
                                aspectRatio: 1,
                                marginTop: 25,
                              }}
                            />
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-2"></div>
                        </>
                      </div>
                    </div>
                  ))}
                </section>
              );
            }
            return (
              <section
                key={item.id}
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}>
                {index === 0 ? this.renderMain(item) : null}
                {item.title && item.subtitle ? (
                  <article
                    className="row justify-content-center pt-5"
                    style={{ marginTop: index === 0 ? 32 : 0 }}>
                    <div className="col-12 col-md-10 col-xl-9 ">
                      <div
                        className="my-3"
                        style={{
                          margin: '0 auto',
                          width: isMobileOnly ? '85%' : '50%',
                        }}>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-3">
                            <h1
                              style={{
                                fontSize: '2.125rem',

                                textAlign: isMobileOnly ? 'center' : 'center',
                                color: navbar_font_color,
                              }}>
                              {item.title || 'Subtítulo'}
                            </h1>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-5">
                            <h5
                              style={{
                                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                textAlign: isMobileOnly ? 'center' : 'center',
                                color: navbar_font_color,
                              }}>
                              {item.subtitle || 'Texto'}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ) : null}
                {item.section_items.map((sectionItem, indexItem) => (
                  <article
                    key={indexItem.toString()}
                    className="row pt-4 justify-content-center"
                    style={{
                      marginTop: index === 0 ? (item.title && item.subtitle ? 0 : 32) : 0,
                    }}>
                    <a
                      className="w-100 d-flex justify-content-center mx-1"
                      href={
                        sectionItem.link_type === 'nolink'
                          ? ''
                          : sectionItem.link_type === 'login'
                          ? '#/login'
                          : sectionItem.link_type === 'signup'
                          ? '#/cadastrar'
                          : sectionItem.link_type === 'additional'
                          ? `#/sobre/${sectionItem.id}`
                          : null
                      }>
                      <div className="col-12 px-4">
                        <div className="row">
                          {sectionItem.image_left ? (
                            <>
                              <div className="col-xs-12 col-sm-12 col-md-6">
                                <img
                                  src={BASE_S3 + '/' + sectionItem.image}
                                  style={{ maxWidth: '100%', aspectRatio: 1 }}
                                />
                              </div>

                              <div className="col-xs-12 col-sm-12 col-md-6  d-flex flex-column justify-content-center align-content-start">
                                <span
                                  style={{
                                    fontFamily: 'Lato',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: index !== 0 ? '28px' : '48px',
                                    lineHeight: index !== 0 ? '32px' : '50px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    color: index === 0 ? navbar_font_color : '#000',
                                    maxWidth: '100%',
                                    marginBottom: 25,
                                  }}>
                                  {sectionItem.name}
                                </span>
                                <span
                                  style={{
                                    fontFamily: 'Lato',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '18px',
                                    lineHeight: '29px',
                                    color: index === 0 ? navbar_font_color : '#666',
                                    maxWidth: '80%',
                                    marginBottom: 25,
                                  }}>
                                  {sectionItem.description}
                                </span>
                                {sectionItem.button_label && sectionItem.link_type !== 'nolink' ? (
                                  <button
                                    style={{
                                      padding: 10,
                                      borderRadius: 5,
                                      background: navbar_button_background_color,
                                      color: navbar_button_font_color,
                                      maxWidth: isMobileOnly ? '50%' : '33%',
                                      height: 48,
                                    }}>
                                    {sectionItem.button_label}
                                  </button>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-center align-content-start">
                                <span
                                  style={{
                                    fontFamily: 'Lato',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: index !== 0 ? '28px' : '48px',
                                    lineHeight: index !== 0 ? '32px' : '50px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    color: index === 0 ? navbar_font_color : '#000',
                                    maxWidth: '100%',
                                    marginBottom: 25,
                                  }}>
                                  {sectionItem.name}
                                </span>
                                <span
                                  style={{
                                    fontFamily: 'Lato',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '18px',
                                    lineHeight: '29px',
                                    color: index === 0 ? navbar_font_color : '#666',
                                    maxWidth: '80%',
                                    marginBottom: 25,
                                  }}>
                                  {sectionItem.description}
                                </span>
                                {sectionItem.button_label && sectionItem.link_type !== 'nolink' ? (
                                  <button
                                    style={{
                                      padding: 10,
                                      borderRadius: 5,
                                      background: navbar_button_background_color,
                                      color: navbar_button_font_color,
                                      maxWidth: isMobileOnly ? '50%' : '33%',
                                      height: 48,
                                    }}>
                                    {sectionItem.button_label}
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-6">
                                <img
                                  src={BASE_S3 + '/' + sectionItem.image}
                                  style={{ maxWidth: '100%', aspectRatio: 1 }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </a>
                  </article>
                ))}
              </section>
            );
          } else if (item.type_id === 3) {
            return (
              <section
                key={item.id}
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}>
                {index === 0 ? this.renderMain(item) : null}
                <div
                  className="row py-3  justify-content-center"
                  key={index.toString()}
                  style={{ marginTop: index === 0 ? 32 : 0 }}>
                  <div className="col-12 col-md-10 col-xl-9">
                    {item.title && item.subtitle ? (
                      <div
                        className="my-3"
                        style={{
                          margin: '0 auto',
                          width: isMobileOnly ? '85%' : '50%',
                        }}>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-4 mb-md-4">
                            <h1
                              style={{
                                fontSize: isMobileOnly ? '1.5rem' : '2.125rem',
                                textAlign: isMobileOnly ? 'center' : 'center',

                                color: index === 0 ? navbar_font_color : '#000',
                              }}
                              className="mb-0">
                              {item.title || 'Subtítulo'}
                            </h1>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-4 mb-md-4">
                            <h5
                              style={{
                                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                textAlign: isMobileOnly ? 'center' : 'center',

                                color: index === 0 ? navbar_font_color : '#666',
                              }}>
                              {item.subtitle || 'Texto'}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row" style={{ minHeight: 400 }}>
                      <div className="col-12">
                        <div
                          style={{
                            padding: `0 ${chevronWidth}px`,
                          }}>
                          <ItemsCarousel
                            requestToChangeActive={this.setActiveItemIndex}
                            activeItemIndex={this.state.activeItemIndex}
                            numberOfCards={isMobileOnly ? 1 : isTablet ? 2 : 3}
                            gutter={20}
                            leftChevron={<FontAwesomeIcon icon={faChevronLeft} />}
                            rightChevron={<FontAwesomeIcon icon={faChevronRight} />}
                            outsideChevron
                            chevronWidth={chevronWidth}>
                            {item.section_items.map((sectionItem, index) => (
                              <div className="col-sm-12" key={index.toString()}>
                                <a
                                  href={
                                    sectionItem.link_type === 'nolink'
                                      ? ''
                                      : sectionItem.link_type === 'login'
                                      ? '#/login'
                                      : sectionItem.link_type === 'signup'
                                      ? '#/cadastrar'
                                      : sectionItem.link_type === 'additional'
                                      ? `#/sobre/${sectionItem.id}`
                                      : null
                                  }>
                                  <AboutCard
                                    image={sectionItem.image}
                                    title={sectionItem.name}
                                    text={sectionItem.description}
                                    colors={{
                                      title: body_dark_font_color || '#333333',
                                      text: body_dark_font_color || '#333333',
                                    }}
                                  />
                                </a>
                              </div>
                            ))}
                          </ItemsCarousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          } else if (item.type_id === 4) {
            return (
              <section
                key={item.id}
                id={`secao${item.id}`}
                style={{
                  background:
                    item.background_type === 'image'
                      ? `url(${BASE_S3}/${item.background_image})`
                      : item.background_type === 'color'
                      ? item.background_color
                      : '#fff',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `center`,
                }}>
                {index === 0 ? this.renderMain(item) : null}
                {item.title && item.subtitle ? (
                  <article
                    className="row justify-content-center pt-5"
                    style={{ marginTop: index === 0 ? 32 : 0 }}>
                    <div className="col-12 col-md-10 col-xl-9 ">
                      <div
                        className="my-3"
                        style={{
                          margin: '0 auto',
                          width: isMobileOnly ? '85%' : '50%',
                        }}>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-3">
                            <h1
                              style={{
                                fontSize: '2.125rem',
                                textAlign: 'center',
                                color: index === 0 ? navbar_font_color : '#000',
                              }}>
                              {item.title || 'Subtítulo'}
                            </h1>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-auto mb-3 mb-md-5">
                            <h5
                              style={{
                                fontSize: isMobileOnly ? '1rem' : '1.0rem',
                                textAlign: 'center',
                                color: index === 0 ? navbar_font_color : '#666',
                              }}>
                              {item.subtitle || 'Texto'}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ) : null}
                {item.section_items.map((sectionItem, index) => (
                  <div
                    key={index}
                    className="container"
                    style={{
                      marginTop: index === 0 ? (item.title && item.subtitle ? 0 : 32) : 0,
                    }}>
                    <div className="row">
                      <>
                        <div className="col-xs-12 col-sm-12 col-md-2"></div>

                        <div className="col-xs-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-content-center align-items-center">
                          <span
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: index !== 0 ? '28px' : '48px',
                              lineHeight: index !== 0 ? '32px' : '50px',
                              display: 'flex',
                              alignItems: 'flex-end',

                              color: index === 0 ? navbar_font_color : '#000',
                              maxWidth: '100%',
                              marginBottom: 25,
                              textAlign: 'center',
                            }}>
                            {sectionItem.name}
                          </span>
                          <span
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '18px',
                              lineHeight: '29px',
                              color: index === 0 ? navbar_font_color : '#666',
                              maxWidth: '80%',
                              marginBottom: 25,
                              textAlign: 'center',
                            }}>
                            {sectionItem.description}
                          </span>

                          {sectionItem.button_label && sectionItem.link_type !== 'nolink' ? (
                            <button
                              style={{
                                padding: 10,
                                borderRadius: 5,
                                background: navbar_button_background_color,
                                color: navbar_button_font_color,
                                maxWidth: isMobileOnly ? '50%' : '33%',
                                height: 48,
                              }}
                              onClick={() => {
                                return sectionItem.link_type === 'nolink'
                                  ? null
                                  : sectionItem.link_type === 'login'
                                  ? this.props.router.push('/login')
                                  : sectionItem.link_type === 'signup'
                                  ? this.props.router.push('/cadastrar')
                                  : sectionItem.link_type === 'additional'
                                  ? this.props.router.push(`/sobre/${sectionItem.id}`)
                                  : null;
                              }}>
                              {sectionItem.button_label}
                            </button>
                          ) : null}
                          <img
                            src={BASE_S3 + '/' + sectionItem.image}
                            style={{
                              maxWidth: '100%',
                              aspectRatio: 1,
                              marginTop: 25,
                            }}
                          />
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-2"></div>
                      </>
                    </div>
                  </div>
                ))}
              </section>
            );
          } else if (item.type_id === 5) {
            return (
              <article key={item.id} id={`secao${item.id}`}>
                {this.renderActivities(item, index)}
              </article>
            );
          } else if (item.type_id === 6) {
            return (
              <article key={item.id} id={`secao${item.id}`}>
                {this.props.station.has_app ? this.renderBenefits() : null}
              </article>
            );
          } else return null;
        })}
        {/* Início do footer */}
        <div
          className="row py-3 py-md-5 px-3 px-md-5 justify-content-center"
          style={{
            background: footer_background_color,
          }}>
          <div className="col-12 col-md-10">
            <div
              className={`row ${
                isMobileOnly ? 'justify-content-center' : 'justify-content-between'
              }`}>
              <div
                className="d-flex col-6 col-sm-4 col-md-2 col-lg-2 align-self-center mb-3 mt-1 mb-sm-0"
                style={{
                  textAlign: isMobileOnly ? 'center' : 'initial',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                }}>
                <img
                  style={{
                    maxWidth: '90%',
                    height: 'auto',
                    maxHeight: 100,
                  }}
                  src={footer_company_logo?.base64 || `${BASE_S3}/${footer_company_logo}`}
                  alt="LOGO-EMPRESA"
                />
              </div>

              <div className={`col-12 ${isTablet ? 'col-md-8' : ''} col-sm-8`}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: !isMobileOnly ? 'space-between' : 'center',
                    flexWrap: 'wrap',
                  }}>
                  <div className={`d-flex flex-column ${isMobileOnly ? 'align-items-center' : ''}`}>
                    <div
                      className={`d-flex ${
                        !isMobileOnly ? '' : 'justify-content-center'
                      }  align-items-center`}>
                      <span
                        style={{
                          cursor: 'pointer',
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className={`station-footer-info ${!isMobileOnly ? 'ml-3 mb-3' : 'mb-3'} `}>
                        Política de privacidade
                      </span>
                    </div>
                    <div className="d-flex">
                      <span
                        style={{
                          cursor: 'pointer',
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className={`station-footer-info ${!isMobileOnly ? 'ml-3 mb-3' : 'mb-3'} `}>
                        Termos de uso
                      </span>
                    </div>

                    <div className="d-flex">
                      <p
                        style={{
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className="ml-3">
                        {this.props.station
                          ? this.props.station.establishment
                            ? `Cnpj: ${this.props.station.establishment.cnpj}`
                            : null
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className={`d-flex flex-column ${isMobileOnly ? 'align-items-center' : ''}`}>
                    <div
                      className={`d-flex ${
                        !isMobileOnly ? '' : 'justify-content-center'
                      }  align-items-center`}>
                      <span
                        style={{
                          cursor: 'pointer',
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className={`station-footer-info ${!isMobileOnly ? 'ml-3 mb-3' : 'mb-3'} `}>
                        Fale Conosco
                      </span>
                    </div>
                    <div className="d-flex">
                      <p
                        style={{
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className="station-footer-info ml-3">
                        {address_local}
                      </p>
                    </div>

                    <div className="d-flex">
                      <p
                        style={{
                          color: footer_font_color,
                          overflowWrap: 'break-word',
                          overflow: 'auto',
                        }}
                        className="ml-3">
                        {contact_mail}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WebsiteSimulator.propTypes = {
  activeActions: PropTypes.shape({
    list: PropTypes.shape({
      filter: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  formValues: PropTypes.shape({
    address: PropTypes.any,
    background_back_header: PropTypes.any,
    body_button_background_color: PropTypes.any,
    body_button_border_color: PropTypes.any,
    body_button_font_color: PropTypes.any,
    body_dark_color: PropTypes.any,
    body_dark_font_color: PropTypes.string,
    body_light_color: PropTypes.any,
    body_link_color: PropTypes.any,
    contact_mail: PropTypes.any,
    first_section_subtitle: PropTypes.any,
    first_section_text: PropTypes.any,
    first_section_title: PropTypes.any,
    footer_background_color: PropTypes.any,
    footer_company_logo: PropTypes.shape({
      base64: PropTypes.any,
    }),
    footer_font_color: PropTypes.any,
    general_color: PropTypes.any,
    general_favicon_image: PropTypes.any,
    header_background_image: PropTypes.any,
    header_background_image_1: PropTypes.any,
    header_background_image_2: PropTypes.any,
    header_background_image_3: PropTypes.any,
    header_background_image_4: PropTypes.any,
    image_download_app: PropTypes.any,
    navbar_button_background_color: PropTypes.any,
    navbar_button_border_color: PropTypes.any,
    navbar_button_font_color: PropTypes.any,
    navbar_font_color: PropTypes.any,
    navbar_station_primary_logo: PropTypes.shape({
      base64: PropTypes.any,
    }),
    navbar_station_secondary_logo: PropTypes.any,
    phone: PropTypes.any,
    third_section_subtitle: PropTypes.any,
    third_section_text: PropTypes.any,
    third_section_title: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  station: PropTypes.shape({
    android: PropTypes.shape({
      replace: PropTypes.func,
    }),
    establishment: PropTypes.shape({
      cnpj: PropTypes.any,
    }),
    has_app: PropTypes.any,
    ios: PropTypes.shape({
      replace: PropTypes.func,
    }),
    plans: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  websiteSections: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func,
      }),
    }),
  }),
};
export default WebsiteSimulator;
