/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import { BASE_S3 } from 'config/consts';
import { limitStringSize } from 'helpers/utils';

const DefaultCard = (props) => {
  const { onClick, imgCloudSource = undefined, actionName, labelClick, key } = props;
  let { imgSource } = props;

  const cloudTo = () => {
    imgSource = `${BASE_S3}/${imgCloudSource}`;
  };

  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
        }}
        onClick={onClick}
        key={key}>
        {imgCloudSource ? (
          <>
            {cloudTo()}
            <img
              alt={key}
              className="img-fluid"
              style={{
                borderRadius: 6,
                marginBottom: 16,
                maxWidth: 267.02,
                maxHeight: 150.31,
                width: 'auto',
                height: 'auto',
                display: 'block',
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              src={imgSource}
            />
          </>
        ) : (
          <img
            alt={key}
            className="img-fluid"
            style={{
              borderRadius: 6,
              marginBottom: 16,
              maxWidth: 267.02,
              maxHeight: 150.31,
              width: 'auto',
              height: 'auto',
              display: 'block',
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
            src={imgSource}
          />
        )}

        <span
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            maxWidth: 267.02,
            lineHeight: '24px',
            height: 48,
            letterSpacing: '0.25px',
            marginBottom: 8,
            color: '#242424',
          }}>
          {limitStringSize(actionName, 62)}
        </span>
        <span
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.25px',
            color: '#6200EE',
            maxWidth: 267.02,
          }}>
          {limitStringSize(labelClick, 35)}
        </span>
      </div>
    </>
  );
};

export default DefaultCard;

DefaultCard.propTypes = {
  actionName: PropTypes.any,
  imgCloudSource: PropTypes.any,
  imgSource: PropTypes.any,
  key: PropTypes.any,
  labelClick: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};
