import PropTypes from 'prop-types';
import moment from 'moment';
import React, { PureComponent } from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from 'recharts';

export default class ReportData extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';

  render() {
    const lastDays = [];
    for (let i = 0; i < 20; i++) {
      lastDays.push({
        date: moment().subtract(i, 'd').format('MM/DD'),
      });
    }

    for (const i in lastDays) {
      const sellsInDate = this.props.data.single_product_orders.filter((item) => {
        return moment(item.createdAt, 'DD/MM/YYYY HH:mm').format('MM/DD') === lastDays[i].date;
      });
      lastDays[i].index = i;
      lastDays[i].Vendas = sellsInDate.length;
    }
    lastDays.reverse();
    console.log(lastDays);
    return (
      <>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={lastDays}
            margin={{
              top: 10,
              right: 25,
              left: -20,
              bottom: 0,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis interval={1} />
            <Tooltip />
            <Legend />
            <Line dataKey="Vendas" fill="#5759FB" />
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  }
}

ReportData.propTypes = {
  data: PropTypes.shape({
    single_product_orders: PropTypes.shape({
      filter: PropTypes.func,
    }),
  }),
};
