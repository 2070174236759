import axios from 'axios';
import { reset as resetForm, initialize, destroy } from 'redux-form';
import { BASE_API, USER_TOKEN } from 'config/consts';
import types from './types';

const URL_ACTION = `${BASE_API}/actions`;
const URL_PARTNERS = `${BASE_API}/establishments`;

export const submitAction = (
  actionValues,
  router = undefined,
  create_or_update,
  id = undefined,
  publishAction
) => {
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    if (create_or_update === 'create') {
      axios
        .post(`${URL_ACTION}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        })
        .then((actionResponse) => {
          // console.log(actionResponse)
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            destroyForm(),
          ]);
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else if (create_or_update === 'update') {
      axios
        .put(`${URL_ACTION}/${id}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((actionResponse) => {
          if (publishAction) {
            dispatch([publishAction()]); // dispatch só pra retornar na função
          } else {
            dispatch([
              { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
              destroyForm(),
            ]);
            if (router) {
              router.push('/owner/acoes/minhas-atividades');
            }
          }
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    }
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.REDIRECT_LINK_INTERNAL_LOAD,
      payload: 'pending',
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const categories = response.data.category_actions.map((item) => ({
          id: item.category?.id,
          title: item.category?.name,
        }));
        const groupings = response.data.action_grouping?.map((item) => ({
          id: item.grouping?.id,
          title: item.grouping?.name,
        }));
        const { target } = response.data;
        const initialValues = {
          ...response.data,
          ...response.data.redirect_links[0],
          categories_field: categories,
          groupings_field: groupings,
          videoIdField:
            response.data.action_videos.length > 0 ? response.data.action_videos[0].video_id : null,
          from: target.age ? target.age.from : null,
          to: target.age ? target.age.to : null,
          states: target.states,
          entities: target.entities,
          associated: target.associated,
          rules: response.data.rules,
          internal: target.internal,
          segmentation_type: target.segment_users ? 'users' : 'public',
          segment_users: target.segment_users || [],
          has_giveaway: response.data.giveaway_number_rules.length > 0,
          giveaway_quantity:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].quantity
              : null,
          giveaway_id:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].giveaway_id
              : null,
          isThereTrophy: !!response.data.trophy_description,
        };
        dispatch([
          initializeForm(initialValues),
          {
            type: types.REDIRECT_LINK_INTERNAL_DETAIL,
            payload: response.data[0],
          },
        ]);
      })
      .catch((e) => {
        dispatch({
          type: types.REDIRECT_LINK_INTERNAL_LOAD,
          payload: 'rejected',
        });
      });
  };
};

export const getPartnersList = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'PARTNERS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_PARTNERS}/?where[type_id]=4`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'PARTNERS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const initializeForm = (values) => {
  return initialize('redirectLinkInternalForm', values);
};

export const reset = () => {
  return resetForm('redirectLinkInternalForm');
};

export const dispatchFormReset = () => {
  return (dispatch) => {
    dispatch(destroy('redirectLinkInternalForm'));
  };
};

export const destroyForm = () => {
  return destroy('redirectLinkInternalForm');
};

export const savePartialy = (values, router, setValue, value) => {
  const { id } = router.params;
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ACTION}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          setValue(value + 1),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
