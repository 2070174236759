import styled from 'styled-components';

const ErrorMessage = styled.strong`
  position: absolute;
  color: red;
  font-size: 16px;
  width: fit-content;
  font-family: Roboto;
  ${(props) => (props.error ? null : 'display:none')}
`;

export default ErrorMessage;
