import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './categoriesActions';

let CategoriesForm = function (props) {
  const { categories, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(categories, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;
    const newValues = {
      ...values,
      station_id: props.auth.user.establishments[0].stations[0].id,
    };
    const firstTime = false;

    create_or_update(router, newValues, create, update, firstTime);
  }

  return props.categories.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="categories-form">
      <div className="card">
        <div className="card-body">
          <p className=" h4 card-title text-muted">Formulário</p>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center">
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome"
                  name="name"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Digite um número de 0-1000 para priorizar."
                  name="priority"
                  required
                  margin="normal"
                />
              </MaterialGrid>
              {/*  <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Ícone"
                  name="icon"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>

              <MaterialGrid item xs={12} md={6} lg={4}>
                Para ver opções de ícone, entre no site:
                <a href="https://material.io/resources/icons/" target="_blank">
                  {' '}
                  https://material.io/resources/icons/
                </a>
              </MaterialGrid>
              {props.formValues?.icon ? (
                <MaterialGrid item xs={12} md={6} lg={4}>
                  <Box>
                    <Typography>Prévia do ícone:</Typography>
                    <FontIcon style={{ fontSize: 40 }}>
                      {props.formValues?.icon}
                    </FontIcon>
                  </Box>
                </MaterialGrid>
              ) : null} */}
              <PrimaryButton
                type="submit"
                disabled={props.categories.buttonLoading}
                progress={parseInt(props.general.submitProgress)}>
                {props.categories.buttonLoading ? 'Enviando' : 'Enviar'}
              </PrimaryButton>
            </MaterialGrid>
          </Form>
        </div>
      </div>
    </section>
  );
};

CategoriesForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  categories: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  create: PropTypes.any,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

CategoriesForm = reduxForm({ form: 'categoriesForm' })(CategoriesForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    categories: state.categories,
    formValues: getFormValues('categoriesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesForm);
