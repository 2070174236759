import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import AlertDialogSlide from 'common/components/dialog/dialog';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { BASE_API, BASE_S3, USER_KEY, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'hidden',
    pageBreakAfter: 'always',
    border: '1px dashed grey',
    marginBottom: '16px',
    paddingBottom: '16px',
  },
  card_head: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
  },
  logo: {
    maxWidth: '300px',
    width: '300px',
    height: 'auto',
    margin: theme.spacing(3),
  },
  text: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
  },
}));

export function PreviewVouchers({ visible, onClose, presential_course, quantity }) {
  const [coders, setCoders] = useState([]);
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  const {
    action: {
      station: { jsonData = {} },
    },
  } = presential_course;

  const componyInfo = JSON.parse(jsonData);

  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleLoad = async () => {
    const { id: userId } = user;
    const { id } = presential_course;
    const values = {
      quantity,
      presential_course: id,
      user_id: userId,
    };

    try {
      const response = await axios.post(`${BASE_API}/presential-course-vouchers`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });

      const { data } = response;
      setCoders(data);
    } catch (e) {
      toastr.error('Erro ao gerar os vouchers em lote.');
      console.log('error => ', e);
    }
  };

  function generateUrlQrcode(item, code) {
    if (item.action.station.id && item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
    if (item.action.station.id && !item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://app.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-gestor.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
  }

  console.log('componyInfo', componyInfo);
  console.log('presential_course', presential_course);
  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <AlertDialogSlide title="Vouchers" visible={visible} onClose={onClose}>
      <>
        <Box marginBottom={2} display="flex" flexDirection="row" justifyContent="center">
          <Button fullWidth variant="contained" color="primary" onClick={handlePrint}>
            Imprimir
          </Button>
        </Box>
        <Box display={'flex'} justifyContent="center" flexDirection={'column'} ref={ref}>
          {coders.map((it) => (
            <Box className={classes.card} key={it}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                spacing={4}>
                <Grid container className={classes.card_head}>
                  <Grid item>
                    <img
                      className={classes.logo}
                      src={`${BASE_S3}/${componyInfo.objectNewItemsBody.navbar_station_secondary_logo}`}
                      alt="LOGO-EMPRESA"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" textAlign="center" className={classes.title}>
                      {presential_course.action.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs="8" align="center">
                  <Typography
                    className={classes.text}
                    variant="body1"
                    textAlign="center">{`Voucher gerado em: ${moment(
                    presential_course.createdAt
                  ).format('LLLL')}`}</Typography>
                </Grid>
                <Grid item>
                  <QRCode value={generateUrlQrcode(presential_course, it)} />
                </Grid>
                <Grid item>
                  <Typography className={classes.text} variant="body1" textAlign="center">
                    {it}
                  </Typography>
                </Grid>
                {presential_course.rules ? (
                  <Grid item>
                    <Typography variant="body1" textAlign="center">
                      {presential_course.rules}
                    </Typography>
                  </Grid>
                ) : null}

                <Grid item align="center">
                  <Typography className={classes.text} textAlign="center">
                    Este voucher deverá ser utilizado até:
                  </Typography>
                  <Typography className={classes.text} textAlign="center">
                    {moment(presential_course.date).format('LLLL')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </>
    </AlertDialogSlide>
  );
}
