import _ from 'lodash';

export const setFormValue = (item, router, location, getDetail, setDetail, options = undefined) => {
  if (!_.isUndefined(router.params.id)) {
    const values = item.list.content.filter((item) => item.id === parseInt(router.params.id));
    if (values.length === 1) {
      setDetail(values[0]);
    } else {
      getDetail(router.params.id, options);
    }
  } else if (!_.isUndefined(location.state)) {
    setDetail(location.state);
  }
};

export const create_or_update = (router, values, create, update, firstTime) => {
  if (_.isUndefined(router.params.id)) {
    create(values, router, firstTime);
  } else {
    values.id = router.params.id;
    update(values, router);
  }
};
