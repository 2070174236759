import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NavBarSite from 'common/components/navigation/navBarSite';
import signup_img from 'assets/img/signup-img.svg';
import { InputLabel2 } from 'common/components/fields/InputLabel2';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dialog } from 'primereact/dialog';

import mail_check from 'assets/img/mail-check.svg';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { submitSurvey, getSurveyById } from './surveysActions';

class SurveysFormStd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBool: true,
      visible: false,
    };
    // console.log(this.props.router);

    this.props.getSurveyById(this.props.router.params.surveyId);
  }

  onSubmit = (values) => {
    const { router } = this.props;
    // adicionar estabelecimento
    values.author = 'contact';
    values.contact = router.params.contactId;
    values.establishment = router.params.establishmentId;
    values.research = router.params.surveyId;
    const arr = [];
    for (const prop in values) {
      if (prop.indexOf('contactSurveyAnswer') > -1) {
        arr.push({
          answer: values[prop],
          question: prop.split('-')[1],
        });
        delete values[`${prop}`];
      }
    }
    values.answers = arr;
    // console.log(values);
    this.props.submitSurvey(values);
  };

  handleClick = () => {
    this.setState({ stepBool: false });
  };

  handleModal = () => {
    this.setState({ visible: true });
  };

  handleHide = () => {
    this.props.showModal();
  };

  handleGoBack = () => {
    this.setState({ stepBool: true });
  };

  onHide = () => {
    // console.log("onhide");
    this.setState({ visible: false });
  };

  renderStep1 = () => {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-auto text-center signup-text my-4">
            Deu certo! A seguir encontra-se a pesquisa que será enviada.
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
              {!_.isUndefined(this.props.surveys.list.content[0])
                ? this.props.surveys.list.content[0].questions.map((item, index) => {
                    return (
                      <div className="row justify-content-center my-n2" key={index}>
                        <Field
                          component={InputLabel2}
                          type="text"
                          placeholder={`${item.question}`}
                          label={`${item.question}`}
                          name={`contactSurveyAnswer-${item.id}`}
                          cols="12 12 4 4"
                          validate={[FORM_RULES.required]}
                          style={{
                            border: '1px solid #FF007B',
                            fontFamily: 'Roboto',
                            fontSize: 16,
                            borderRadius: 0,
                            padding: '23px 10px',
                            fontWeight: 400,
                          }}
                          borderColor="#FF007B"
                          disabled
                        />
                      </div>
                    );
                  })
                : null}

              <div className="row justify-content-center">
                <PrimaryButton
                  disabled
                  onClick={this.handleModal}
                  router={this.props.router}
                  type="submit"
                  color="primary">
                  Enviar
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <NavBarSite />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto text-center signup-img">
            <img alt="" src={signup_img} />
          </div>
        </div>
        {this.state.stepBool ? this.renderStep1() : this.renderStep2()}
        <Dialog
          className="signup-modal"
          header="Confirmação de e-mail"
          visible={this.props.auth.modal}
          style={{ width: '80vw' }}
          modal={true}
          onHide={this.handleHide}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <img alt="" src={mail_check} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <span
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 600,
                    fontSize: 22,
                    color: '#2B2D6B',
                  }}>
                  {' '}
                  Acesse sua caixa de e-mail e clique no link de confirmação.
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <span>
                  Se necessário, verifique sua pasta de SPAM e adicione o nosso endereço como
                  confiável.
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

SurveysFormStd.propTypes = {
  auth: PropTypes.shape({
    modal: PropTypes.any,
  }),
  getSurveyById: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      contactId: PropTypes.any,
      establishmentId: PropTypes.any,
      surveyId: PropTypes.any,
    }),
  }),
  showModal: PropTypes.func,
  submitSurvey: PropTypes.func,
  surveys: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('surveysForm')(state),
    surveys: state.surveys,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSurveyById,
      submitSurvey,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'surveysForm' })(SurveysFormStd));
