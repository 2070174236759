import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AddButton from 'common/components/buttons/addButton';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { ColumnsBelowSearch } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { limitStringSize } from 'helpers/utils';
import { isMobile } from 'react-device-detect';
import { getList, remove } from './entitiesActions';

export function Entities(props) {
  const { router } = props;
  const { list, loading } = props.entities;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const MAX_LENGTH = 35;
  const defaultColumnsMobile = [
    {
      title: 'Nome da Empresa',
      field: 'name',
      render: (rowData) => (rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : 'Sem nome'),
    },
    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  const defaultColumns = [
    {
      title: 'Nome da Empresa',
      field: 'name',
      render: (rowData) => (rowData.name ? limitStringSize(rowData.name, MAX_LENGTH) : 'Sem nome'),
    },

    {
      field: 'actions',
      title: '',

      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? defaultColumnsMobile : defaultColumns;
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(list.content);
  }, [list.content]);

  function onRowClick(e, rowData) {
    router.push(`/owner/entidades/${rowData.id}/editar`);
  }

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não adicionou entidades ligadas ao seu CNPJ
                </h2>
                <p>Adicione dados para aparecer no seu site!</p>
                <PrimaryButton onClick={() => props.router.push('/owner/entidades/novo')}>
                  Adicionar dados
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="entities">
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.entities.loading}
        general={props.general}
        title="Deletar Entidade"
        confirmButtonColor={'primary'}
        text="Tem certeza? Você deletará a Entidade e os blocos referentes a ela"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            hasToolbar
            toolbarSearch
            title="Entidades"
            columns={columns}
            rows={data}
            onRowClick={onRowClick}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
        <Box style={{ marginTop: 20 }}>
          <AddButton onClick={() => props.router.push('/owner/entidades/novo')} />
        </Box>
      </GeneralGrid>
    </section>
  );
}

Entities.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  entities: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  general: PropTypes.any,
  getList: PropTypes.func,
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    entities: state.entities,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Entities);
