export const BASE_API =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api'
    : 'http://localhost:8080/api';

export const BASE_AUTH =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk/auth'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api/auth'
    : 'http://localhost:8080/api/auth';

export const BASE_CUSTOM_DOMAIN =
  process.env.REACT_APP_API_KEY === 'production'
    ? '54.232.228.80'
    : process.env.REACT_APP_API_KEY === 'test'
    ? '54.232.44.199'
    : '54.232.44.199';

export const STATION_SUBDOMAIN =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'rilato.site'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'rilato.test'
    : 'rilato.site';

export const BASE_S3 = 'https://s3.amazonaws.com/rilato.app.files';

export const USER_KEY = '_fermentoLabUser';
export const USER_TOKEN = '_fermentoLabUserToken';

export const FAN_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 13
    : process.env.REACT_APP_API_KEY === 'test'
    ? 1
    : 1;

export const COMPANY_ENTERPRISE_PLAN_BENEFITS = [
  {
    benefit: 'Grande número de participantes',
  },
  {
    benefit: 'Integrações de sistema',
  },
  {
    benefit: 'Mais armazenamento',
  },
  {
    benefit: 'Suporte 24/7',
  },
  {
    benefit: 'Especialistas de gestão',
  },
  {
    benefit: 'Criação de campanhas',
  },
];

export const COMPANY_COLORS = (colors) => {
  return {
    primary: colors.general_color,
    light: '#f7f7f7',
    // dark: configConsts.COMPANY_DARK_COLOR,
    gray: '#525252',
    secondary: '#525252',
    footer: '#525252',
    font: {
      gray: '#525252',
      // light: configConsts.COMPANY_LIGHT_FONT_COLOR,
      // dark: configConsts.COMPANY_DARK_FONT_COLOR
    },
    error: '#FF5A52',
    success: '#52C22C',
    activityCard: {
      muted: '#999999',
      text: colors.body_dark_font_color,
      callToAction: colors.body_link_color || `#006ce6`,
      reward: '#666666',
      chevron: '#cccccc',
    },
    aboutCard: {
      title: colors.body_dark_font_color,
      text: colors.body_dark_font_color,
    },
    website: {
      header: {
        image: colors.header_background_image,
        mobile_image: colors.header_background_mobile_image,
        background_back_header: colors.background_back_header,
        background_auth_station: colors.background_auth_station,
      },
      body: {
        title: colors.body_dark_font_color,
        text: colors.body_dark_font_color,
        button: {
          background: colors.body_button_background_color || `#0284fe`,
          text: colors.body_button_font_color,
          border: colors.body_button_border_color,
        },
        firstBackground: colors.body_light_color,
        secondBackground: colors.body_dark_color,
        thirdBackground: colors.body_light_color || `#f1f2f4`,
      },

      footer: {
        background: colors.footer_background_color || `#f69376`,
        text: colors.footer_font_color,
        company_logo: colors.footer_company_logo,
      },
      copyright: {
        background: '#FFFFFF',
        text: '#666666',
      },
      navbar: {
        burger: '#333333',
        text: colors.navbar_font_color,
        button: {
          background: colors.navbar_button_background_color,
          text: colors.navbar_button_font_color,
          border: colors.navbar_button_border_color,
        },
        station_primary_logo: colors.navbar_station_primary_logo,
        station_secondary_logo: colors.navbar_station_secondary_logo,
      },
    },
    homepage: {
      navbar: {
        background: colors.navbar_background_color || 'black',
        button: colors.navbar_button_color || 'white',
        title: colors.navbar_title_color || 'white',
      },
      sidebar: {
        detail_color: colors.body_link_color,
        logo: colors.navbar_station_secondary_logo,
      },
    },
    station: {
      sidebar: {
        icon: {
          active: '#666666',
          default: colors.general_color,
        },
        text: {
          active: '#333333',
          default: colors.general_color,
        },
      },
      topbar: {
        background: colors.general_color,
        text: colors.body_light_color,
      },
    },
    buttons: {
      primaryButton: {
        background: colors.navbar_button_background_color,
        text: colors.navbar_button_font_color,
        border: colors.navbar_button_border_color,
      },
      secondaryButton: {
        background: colors.body_button_background_color || `#0284fe`,
        text: colors.body_button_font_color || `#ffffff`,
        border: colors.body_button_border_color,
      },
    },
    link: colors.body_link_color || `#006ce6`,
    form: {
      general: '#999999',
      active: '#666666',
      hover: '#666666',
    },
    general: {
      favicon: colors.general_favicon_image,
      general_color: colors.general_color,
      primaryButton: {
        background: colors.navbar_button_background_color,
        text: colors.navbar_button_font_color,
        border: colors.navbar_button_border_color,
      },
      secondaryButton: {
        background: colors.body_button_background_color || `#0284fe`,
        text: colors.body_button_font_color || `#ffffff`,
        border: colors.body_button_border_color,
      },
      link: colors.body_link_color || `#006ce6`,
      text: colors.body_dark_font_color || `#333333`,
      form: {
        general: '#999999',
        active: '#666666',
        hover: '#666666',
      },
    },
  };
};

export const BRB_FLA_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 165
    : process.env.REACT_APP_API_KEY === 'test'
    ? 1
    : 1;
