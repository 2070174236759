import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import Button from 'common/components/buttons/button';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { InputEasyImageWithProcessing } from '../../../../common/components/fields/InputEasyImageWithProcessing';
import { InputSwitch } from '../../../../common/components/fields/InputSwitch';
import { InputSwitchGrey } from '../../../../common/components/fields/InputSwitchGrey';
import { SelectLabel } from '../../../../common/components/fields/SelectLabel';
import { currencyMask } from '../../../../helpers/masks';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './plansActions';

const renderBenefits = ({ fields, meta: { error, submitFailed } }) => (
  <Box display="flex" flexDirection="column">
    <Box display="flex" alignItems="center">
      <p className="h6 mr-3">Coloque características do plano</p>{' '}
      <Button label={'Adicionar benefício'} buttonType={'button'} onClick={() => fields.push({})} />
    </Box>

    <MaterialGrid container spacing={3} style={{ marginBottom: 32 }}>
      {fields.map((benefit, index) => (
        <MaterialGrid item xs={4} key={index}>
          <Box display="flex" alignItems="center">
            <label onClick={() => fields.remove(index)} style={{ marginRight: 8 }}>
              <RemoveCircleOutlinedIcon />
            </label>
            <Field
              component={InputLabelOutlined}
              label="Benefício"
              name={`${benefit}.value`}
              required
              margin="normal"
              validate={[FORM_RULES.required, FORM_RULES.maxLength30]}
            />
          </Box>
        </MaterialGrid>
      ))}
    </MaterialGrid>
  </Box>
);

let PlansForm = function ({
  plans,
  router,
  location,
  getDetail,
  setDetail,
  auth,
  setLoading,
  handleSubmit,
  create,
  update,
  general,
  formValues,
}) {
  const { list, loading } = plans;

  useEffect(() => {
    if (!router.params.id) {
      setLoading(false);
    } else {
      setFormValue(plans, router, location, getDetail, setDetail);
    }
  }, [router.params.id]);

  function onSubmit(values) {
    const newValues = {
      ...values,
      establishment_id: auth.user.establishments[0].id,
      station_id: auth.user.establishments[0].stations[0].id,
      amount: values.isFree ? 0 : values.amount,
    };

    create_or_update(router, newValues, create, update);
  }

  return loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="plans-form" style={{ marginTop: 64 }}>
      <div className="card">
        <div className="card-body" style={{ padding: '32px 64px' }}>
          <Form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box display="flex" alignItems="center" mb={3}>
              <p className="h4-bold" style={{ marginRight: 24 }}>
                Criando um novo plano
              </p>
              <Field component={InputSwitch} name="is_highlighted" />
              <p className="h5" style={{ marginLeft: 4 }}>
                Destacar este plano
              </p>
            </Box>

            <MaterialGrid container spacing={3} style={{ marginBottom: 32 }}>
              <MaterialGrid item xs>
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome do plano"
                      name="name"
                      validate={[FORM_RULES.required, FORM_RULES.maxLength20]}
                      required
                      margin="normal"
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Descrição"
                      name="description"
                      required
                      margin="normal"
                      multiline
                      rows={2}
                      validate={[FORM_RULES.required, FORM_RULES.maxLength100]}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Texto do botão para compra (Ex.: Adquirir, Comprar)"
                      name="purchase_text"
                      required
                      validate={[FORM_RULES.required, FORM_RULES.maxLength20]}
                    />
                  </MaterialGrid>
                </MaterialGrid>
              </MaterialGrid>
              {!router.params.id && (
                <MaterialGrid item xs>
                  <MaterialGrid container spacing={3}>
                    <MaterialGrid item xs={12}>
                      <Field
                        component={InputSwitchGrey}
                        label={'O plano será gratuito?'}
                        name="isFree"
                        boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                        firstState="Este plano será gratuito"
                        secondState="Este plano será pago"
                      />
                    </MaterialGrid>
                    {!formValues?.isFree ? (
                      <>
                        <MaterialGrid item xs={12}>
                          <Field
                            component={InputLabelOutlined}
                            label="Preço"
                            name="amount"
                            validate={[FORM_RULES.required]}
                            required
                            margin="normal"
                            {...currencyMask}
                          />
                        </MaterialGrid>
                        <MaterialGrid item xs={12}>
                          <Field
                            component={InputLabelOutlined}
                            label="Desconto"
                            name="discount"
                            validate={[FORM_RULES.required]}
                            required
                            margin="normal"
                            {...currencyMask}
                          />
                        </MaterialGrid>

                        <MaterialGrid item xs={12}>
                          <Field
                            component={SelectLabel}
                            options={[
                              {
                                value: 'Mensal',
                                label: 'Mensal',
                              },
                              {
                                value: 'Anual',
                                label: 'Anual',
                              },
                            ]}
                            name={`days`}
                            label="Escolha o tipo de plano (anual ou mensal)"
                            validate={[FORM_RULES.required]}
                          />
                        </MaterialGrid>
                        <MaterialGrid item xs={12}>
                          <Field
                            component={SelectLabel}
                            options={[
                              {
                                value: '0',
                                label: 'Nenhum',
                              },
                              {
                                value: '7',
                                label: '7 dias',
                              },
                              {
                                value: '15',
                                label: '15 dias',
                              },
                              {
                                value: '30',
                                label: '30 dias',
                              },
                            ]}
                            name="trial_days"
                            label="Quantos dias de teste grátis"
                            validate={[FORM_RULES.required]}
                          />
                        </MaterialGrid>
                      </>
                    ) : null}
                  </MaterialGrid>
                </MaterialGrid>
              )}
            </MaterialGrid>

            <FieldArray name="benefits" component={renderBenefits} />
            <p className="h6" style={{ marginBottom: 16 }}>
              Imagem de capa do plano
            </p>
            <MaterialGrid container spacing={3} style={{ marginBottom: 32 }}>
              <MaterialGrid item xs>
                <Field
                  component={InputEasyImageWithProcessing}
                  label={'Arraste uma imagem 16:9 formato .jpeg, .png'}
                  cols="12 12 12 12"
                  name="image"
                  aspect={16 / 9}
                  aspectLabel={'16:9'}
                />
              </MaterialGrid>
            </MaterialGrid>
            {list.content.length < 1 ? (
              <Box display="flex" alignItems="center" mb={3}>
                <Field component={InputSwitch} name="create_section" />
                <p className="h5" style={{ marginLeft: 4 }}>
                  Criar seção na área pública para mostrar os planos
                </p>
              </Box>
            ) : null}
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                onClick={() => router.push('/owner/planos')}
                label="Cancelar"
                type="secondary"
                buttonStyle={{ marginRight: 16 }}
                disabled={plans.buttonLoading}
                buttonType="button"
              />
              <Button
                label={router.params.id ? 'Atualizar' : 'Criar'}
                disabled={plans.buttonLoading}
                progress={parseInt(general.submitProgress)}
                buttonType={'submit'}
              />
            </Box>
          </Form>
        </div>
      </div>
    </section>
  );
};

PlansForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  create: PropTypes.any,
  formValues: PropTypes.shape({
    isFree: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  plans: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

PlansForm = reduxForm({ form: 'plansForm' })(PlansForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
    formValues: getFormValues('plansForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansForm);
