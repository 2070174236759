import React from 'react';
import { Route, IndexRoute } from 'react-router';
import About from './about';
import AboutForm from './aboutForm';

export default (
  <Route path="sobre">
    <IndexRoute component={(props) => <About {...props} />} />
    <Route path="novo" component={(props) => <AboutForm {...props} />} />
    <Route path=":id/:action" component={(props) => <AboutForm {...props} />} />
  </Route>
);
