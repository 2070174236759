import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputFile } from 'common/components/fields/InputFile';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputTimeOutlined } from 'common/components/fields/InputTimeOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
// import { bindActionCreators } from "redux";
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './schedulesActions';

/* import { getDetail, setDetail, initializeForm } from "./scheduleActions"; */

let SchedulesForm = function (props) {
  const { schedules, router, location, getDetail, setDetail } = props;
  const { handleSubmit } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(schedules, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { router, create, update } = props;

    const newValues = {
      ...values,
      station_id: props.auth.user.establishments[0].stations[0].id,
    };

    delete newValues[('title', 'contractDescription')];

    // O dia da programação é considerado o dia de início da programação
    /* newValues.day = moment(newValues.start).format("YYYY-MM-DD");
        newValues.start = moment(newValues.start).format("HH:mm");
        newValues.end = moment(newValues.end).format("HH:mm"); */

    if (!_.isUndefined(newValues.image.base64)) {
      newValues.base64 = imgToBase64(newValues.image);
      newValues.imageType = imgToImgType(newValues.image);
      delete newValues.image;
    }

    create_or_update(router, newValues, create, update);
  }

  return props.schedules.loading ? (
    <Box display="flex" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="schedules">
      <Box mt={7}>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <div className="card">
              <div className="card-body">
                <p className=" h4 card-title text-muted">Formulário</p>
                <Form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <GeneralGrid container spacing={3}>
                    <GeneralGrid item xs={12} md={6}>
                      <GeneralGrid container spacing={3}>
                        <GeneralGrid item xs={12}>
                          <Field
                            component={InputLabelOutlined}
                            placeholder="Nome da programação"
                            label={'Nome'}
                            name="name"
                            cols="12 12 6 6"
                            validate={[FORM_RULES.required]}
                            required
                          />
                        </GeneralGrid>
                        {router.params.action === 'editar' ? (
                          <>
                            <GeneralGrid item xs={12}>
                              <Field
                                component={InputTimeOutlined}
                                label={'Início'}
                                name="start"
                                cols="12 12 6 6"
                                validate={[FORM_RULES.required]}
                                required
                              />
                            </GeneralGrid>
                            <GeneralGrid item xs={12}>
                              <Field
                                component={InputTimeOutlined}
                                label={'Fim'}
                                name="end"
                                cols="12 12 6 6"
                                validate={[FORM_RULES.required]}
                                required
                              />
                            </GeneralGrid>
                          </>
                        ) : (
                          <>
                            <GeneralGrid item xs={12}>
                              <Field
                                component={InputLabelOutlined}
                                type="datetime-local"
                                label={'Início'}
                                name="start"
                                cols="12 12 6 6"
                                validate={[FORM_RULES.required]}
                                required
                              />
                            </GeneralGrid>
                            <GeneralGrid item xs={12}>
                              <Field
                                component={InputLabelOutlined}
                                type="datetime-local"
                                label={'Fim'}
                                name="end"
                                cols="12 12 6 6"
                                validate={[FORM_RULES.required]}
                                required
                              />
                            </GeneralGrid>
                          </>
                        )}
                      </GeneralGrid>
                    </GeneralGrid>
                    <GeneralGrid item xs={12} md={6}>
                      <GeneralGrid container spacing={3}>
                        <GeneralGrid item xs={12}>
                          <Field
                            component={InputLabelOutlined}
                            type="textarea"
                            multiline
                            rowsMax={6}
                            label={'Descrição'}
                            name="description"
                            rows={6}
                            placeholder="Breve descrição da programação"
                            cols="12 12 12 6"
                            required
                          />
                        </GeneralGrid>
                        <GeneralGrid item xs={12}>
                          <Field
                            component={InputFile}
                            label={'Imagem .png, .jpg ou .gif'}
                            name="image"
                            validate={[FORM_RULES.required]}
                            margin="normal"
                          />
                        </GeneralGrid>
                      </GeneralGrid>
                    </GeneralGrid>
                    <div className="col-12 mt-3" style={{ textAlign: 'center' }}>
                      <PrimaryButton type="submit" disabled={props.schedules.buttonLoading}>
                        {props.schedules.buttonLoading ? 'Enviando' : 'Enviar'}
                      </PrimaryButton>
                    </div>
                  </GeneralGrid>
                </Form>
              </div>
            </div>
          </GeneralGrid>
        </GeneralGrid>
      </Box>
    </section>
  );
};

SchedulesForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  create: PropTypes.any,
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.any,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.any,
    }),
  }),
  schedules: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
};

SchedulesForm = reduxForm({ form: 'schedulesForm' })(SchedulesForm);

const mapStateToProps = (state) => {
  return {
    schedules: state.schedules,
    auth: state.auth,
    formValues: getFormValues('schedulesForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesForm);
