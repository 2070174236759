import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { appColors } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  primary: {
    color: appColors.light.level1,
    backgroundColor: appColors.error,
    '&:hover': {
      backgroundColor: appColors.errorHover,
    },
  },
  disabledLabel: {
    color: appColors.light.level1,
  },
  loadingLabel: {
    display: 'block',
    color: appColors.success,
  },
}));

export default function DangerButton(props) {
  const classes = useStyles();
  const { disabled } = props;

  return (
    <Button
      variant="contained"
      classes={{ label: disabled ? classes.disabledLabel : 'default' }}
      className={clsx(classes.button, classes.primary)}
      {...props}>
      {props.children}
    </Button>
  );
}

DangerButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.any,
};
