import PropTypes from 'prop-types';
import { Component } from 'react';

export default class IF extends Component {
  render() {
    if (this.props.test) {
      return this.props.children;
    } else {
      return false;
    }
  }
}

IF.propTypes = {
  test: PropTypes.any,
  children: PropTypes.any,
};
