import { getListTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  detail: {},
  loading: true,
  loadingTemplates: false,
  buttonLoading: false,
  participant: {},
  cupom: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VALIDATIONS_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.VALIDATION_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case types.VALIDATION_LOAD:
      return {
        ...state,
        loading: action.payload,
      };

    case types.VALIDATION_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    case 'PARTICIPANT_DETAIL':
      return {
        ...state,
        participant: action.payload,
        loading: false,
      };

    case 'VALIDATION_CUPOM_DETAIL':
      return {
        ...state,
        cupom: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
