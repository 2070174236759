import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStationActionOfferMessages, onClickAction, remove } from './actionOfferMessagesActions';

function ActionOfferMessages(props) {
  const content = props.actionOfferMessages.list;

  useEffect(() => {
    console.log('entrou aqui');
    props.getStationActionOfferMessages(props.router.params.id);
  }, []);

  function onDialogClose() {
    if (props.location.query.from === 'arquivadas') {
      props.router.push('/owner/acoes/propostas');
    } else {
      props.router.push('/owner/acoes/propostas');
    }
  }

  function renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Mensagens da proposta"
        visible={true}
        height="95vh"
        onClose={onDialogClose}
        maxWidth={isMobile ? 'xl' : 'md'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography>Nenhuma mensagem aqui por enquanto</Typography>
        </div>
      </AlertDialogSlide>
    );
  }

  if (props.actionOfferMessages.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <AlertDialogSlide
      title="Mensagens da proposta"
      visible={true}
      height="95vh"
      onClose={onDialogClose}
      maxWidth={isMobile ? 'xl' : 'md'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        {props.actionOfferMessages.list.map((message, index) => {
          console.log(moment(message.createdAt).format('DD/MM'));
          return (
            <div
              key={index}
              style={{
                maxWidth: 300,
                alignSelf:
                  message.from_establishment_id === props.auth.user.establishments[0].id
                    ? 'flex-end'
                    : 'flex-start',
              }}>
              <Typography
                style={{
                  textAlign:
                    message.from_establishment_id === props.auth.user.establishments[0].id
                      ? 'right'
                      : 'left',
                  margin: 10,
                }}
                variant="subtitle2">
                {message.from_establishment_id === props.auth.user.establishments[0].id
                  ? 'Você'
                  : message.from_establishment.name}
              </Typography>
              <MessageBox
                position={
                  message.from_establishment_id === props.auth.user.establishments[0].id
                    ? 'right'
                    : 'left'
                }
                type={'text'}
                dateString={moment(message.createdAt).format('DD/MM')}
                text={message.message}
                data={{
                  uri: 'https://facebook.github.io/react/img/logo.svg',
                  status: {
                    click: false,
                    loading: 0,
                  },
                }}
              />
            </div>
          );
        })}
      </div>
    </AlertDialogSlide>
  );
}

ActionOfferMessages.propTypes = {
  actionOfferMessages: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getStationActionOfferMessages: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    actionOfferMessages: state.actionOfferMessages,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getStationActionOfferMessages, remove, onClickAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionOfferMessages);
