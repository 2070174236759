import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialGrid from '@material-ui/core/Grid';
import OnboardingCard from 'common/components/cards/onboardingCard/onboardingCard';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { Box } from '@material-ui/core';
import { reduxForm, Form, Field } from 'redux-form';
import { FORM_RULES } from 'helpers/validations';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { inviteOwner } from './contactsActions';

class InviteOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  onSubmit = (values) => {
    const { user } = this.props.auth;
    const newValues = {
      ...values,
      establishment_id: user.establishments[0].id,
      user_id: user.id,
      type_id: user.type_id,
    };
    this.props.inviteOwner(newValues);
  };

  render() {
    return (
      <Box marginTop="5.3rem">
        <MaterialGrid container spacing={3}>
          <MaterialGrid item xs={12}>
            <OnboardingCard
              condition={false}
              onClickButton={() => this.setState({ isModalOpen: true })}
              title="Convide novos gestores"
              text="Coloque o e-mail da pessoa que deseja convidar para ser um(a) novo(a) gestor(a) do seu estabelecimento."
              router={this.props.router}
              buttonLabel="Iniciar Inclusão"
            />
          </MaterialGrid>
        </MaterialGrid>
        <AlertDialogSlide
          visible={this.state.isModalOpen}
          title="Convidar novo gestor"
          onClose={() => {
            this.setState({ isModalOpen: false });
          }}
          maxWidth="sm">
          <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="E-mail"
                  name="email"
                  required
                  validate={[FORM_RULES.required, FORM_RULES.email]}
                />
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={0}>
              <MaterialGrid item xs={12}>
                <Box display="flex" justifyContent="flex-end" pt={3} pb={2}>
                  <PrimaryButton
                    type="submit"
                    disabled={this.props.general.buttonLoading}
                    progress={parseInt(this.props.general.submitProgress)}>
                    {this.props.general.buttonLoading ? 'Enviando' : 'Enviar'}
                  </PrimaryButton>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </Form>
        </AlertDialogSlide>
      </Box>
    );
  }
}

InviteOwner.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
      type_id: PropTypes.any,
    }),
  }),
  general: PropTypes.shape({
    buttonLoading: PropTypes.any,
    submitProgress: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  inviteOwner: PropTypes.func,
  router: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ inviteOwner }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'InviteOwnerForm' })(InviteOwner));
