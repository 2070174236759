import PropTypes from 'prop-types';
import { Box, Icon, Tooltip, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { InputImageWithProcessing } from 'common/components/fields/InputImageWithProcessing';
import { EditorConvertToHTMLActivity } from 'common/components/fields/EditorConvertToHTMLActivity';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { InputSwitchLarge } from 'common/components/fields/InputSwitchLarge';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import LottieControl from 'common/components/lottie/lottie';
import LoadingProgress from 'common/components/progress/loading';
import Results from 'common/components/quiz/Results';
import { currencyMask, thousandSeparatorMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { requiredFieldError } from 'modules/owner/pages/actions/actionsFile';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormMeta, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { isMobile } from 'react-device-detect';
import getEstablishmentPoints from '../../points/pointsActions';
import {
  getList as getLottiesList,
  getListById as getLottiesListById,
} from '../../lotties/lottiesActions';
import { getGiveawaysStationId } from '../giveaways/giveawaysActions';

class Prize extends Component {
  constructor(props) {
    super(props);

    this.state = { submitted: false, poppedUp: false };
    this.props.getLottiesList();
    this.props.getGiveawaysStationId(this.props.auth.user.establishments[0].stations[0].id);
    this.props.getEstablishmentPoints(this.props.auth.user.establishments[0].id);
  }

  onSubmit = (values) => {
    if (!values.isInvitedPaid) {
      values.points_invited = 0;
    }
    if (!values.isInviterPaid) {
      values.points_inviter = 0;
    }
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.feedback_lottie_id === 'none') {
      values.feedback_lottie_id = null;
    }
    if (values.categories_field) {
      values.categories = values.categories_field.map((item) => item.id);
    }
    if (values.groupings_field) {
      values.groupings = values.groupings_field.map((item) => item.id);
    }
    values.submitFromButton = true;
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    if (!values.isInvitedPaid) {
      values.points_invited = 0;
    }
    if (!values.isInviterPaid) {
      values.points_inviter = 0;
    }
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.feedback_lottie_id === 'none') {
      values.feedback_lottie_id = null;
    }
    values.categories = values.categories_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  componentDidMount() {
    if (this.props.formValues) {
      if (this.props.formValues.feedback_lottie_id) {
        this.props.getLottiesListById(this.props.formValues.feedback_lottie_id);
      }
    }
  }

  componentDidUpdate(props) {
    if (props.formValues && this.props.formValues) {
      if (props.formValues.feedback_lottie_id !== this.props.formValues.feedback_lottie_id) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(this.props.formValues.feedback_lottie_id);
        }
      }
    } else {
      if (this.props.formValues) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(this.props.formValues.feedback_lottie_id);
        }
      }
    }

    if (!_.isEmpty(this.props.submitErrors) && this.state.submitted && !this.state.poppedUp) {
      this.props.requiredFieldError();
      this.setState({ poppedUp: true });
      this.setState({ submitted: false });
    }
    if (this.props.submitErrors !== props.submitErrors && this.state.poppedUp) {
      this.setState({ poppedUp: false });
    }
  }

  renderPoints = () => {
    const { points, allocated_points } = this.props.points.points;
    const pointsLabeled = `R$ ${parseFloat(points).toFixed(2).replace('.', ',')}`;
    const allocatedPointsLabeled = `R$ ${parseFloat(allocated_points)
      .toFixed(2)
      .replace('.', ',')}`;
    const currentAllocated = this.props.formValues?.type_id === 1;
    const currentAllocatedLabeled = currentAllocated
      ? `R$ ${parseFloat(currentAllocated).toFixed(2).replace('.', ',')}`
      : null;
    return (
      <>
        <MaterialGrid item xs={12}>
          <Typography variant="p" color="primary" style={{ fontSize: 12 }}>
            Você possui {pointsLabeled} no total e {allocatedPointsLabeled} alocados em atividades.
          </Typography>
        </MaterialGrid>
        {points - allocated_points < currentAllocated ? (
          <MaterialGrid item xs={12}>
            <Typography variant="p" color="error" style={{ fontSize: 12 }}>
              Faltam{' '}
              {parseFloat(currentAllocated - (points - allocated_points))
                .toFixed(2)
                .replace('.', ',')}{' '}
              na sua carteira para conseguir publicar essa atividade com esse cashback/limite.
            </Typography>
          </MaterialGrid>
        ) : (
          <>
            {currentAllocatedLabeled ? (
              <MaterialGrid item xs={12}>
                <Typography variant="p" color="primary" style={{ fontSize: 12 }}>
                  {' '}
                  {currentAllocatedLabeled} serão alocados nessa atividade
                </Typography>
              </MaterialGrid>
            ) : null}
          </>
        )}
      </>
    );
  };

  render() {
    const lottiesList = this.props.lotties.list.content.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
        url: item.url,
      };
    });

    lottiesList.unshift({
      label: 'Nenhuma Animação',
      value: 'none',
      url: 'none',
    });

    const giveawaysList = this.props.giveaways.list.map((item, index) => ({
      value: item.id,
      label: item.name,
    }));

    if (this.props.giveaways.loading) {
      return (
        <Box display="flex" justifyContent="center">
          <LoadingProgress />
        </Box>
      );
    }

    return (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 15,
            }}>
            {(this.props.formValues?.type_id <= 8 ||
              this.props.formValues?.type_id === 10 ||
              this.props.formValues?.type_id > 12) &&
            this.props.formValues?.type_id !== 4 &&
            this.props.formValues?.type_id !== 15 &&
            this.props.formValues?.type_id !== 16 &&
            this.props.formValues?.type_id !== 1 &&
            this.props.formValues?.type_id !== 19 ? (
              <>
                <MaterialGrid item xs={12}>
                  <Field
                    component={EditorConvertToHTMLActivity}
                    label={'Mensagem ao concluir atividade'}
                    name="feedback_message"
                    disabled={this.props.disabled}
                    required
                    border
                    validate={[FORM_RULES.required]}
                  />
                </MaterialGrid>
                <MaterialGrid
                  item
                  xs={12}
                  style={{
                    minHeight: 100,
                    marginTop: 20,
                    marginBottom: 20,
                  }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <MaterialGrid item xs={6}>
                      <Field
                        component={SelectLabel}
                        options={lottiesList}
                        name="feedback_lottie_id"
                        label="Escolha uma animação para feedback"
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={6}>
                      <div style={{ height: 70 }}>
                        {this.props.formValues ? (
                          this.props.formValues.feedback_lottie_id !== 'none' ? (
                            this.props.lotties.loading ? (
                              <Box display="flex" justifyContent="center">
                                <LoadingProgress />
                              </Box>
                            ) : (
                              <Box display="flex" justifyContent="center">
                                <LottieControl
                                  width={100}
                                  height={100}
                                  loop={false}
                                  animation={this.props.lotties.detail.json}
                                  hasReplayButton
                                />
                              </Box>
                            )
                          ) : null
                        ) : null}
                      </div>
                    </MaterialGrid>
                  </Box>
                </MaterialGrid>
              </>
            ) : this.props.formValues?.type_id === 12 ||
              this.props.formValues?.type_id === 4 ||
              this.props.formValues?.type_id === 15 ||
              this.props.formValues?.type_id === 16 ||
              this.props.formValues?.type_id === 1 ||
              this.props.formValues?.type_id === 19 ? null : (
              <Field component={Results} name="results" router={this.props.router} />
            )}
            {this.props.formValues?.type_id === 1 || this.props.formValues?.type_id === 19 ? (
              // <MaterialGrid container spacing={3}>
              <>
                <div style={{ marginTop: 20 }}>
                  <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                    Limite de participação
                  </Typography>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Total de convidados que ganharão recompensa"
                      name="limit"
                      validate={[FORM_RULES.required]}
                      disabled={this.props.disabled}
                      required
                      margin="normal"
                      {...thousandSeparatorMask}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Total de convidados por usuário que podem ganhar recompensa"
                      name="limitPerUser"
                      disabled={this.props.disabled}
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      {...thousandSeparatorMask}
                    />
                  </MaterialGrid>
                </div>
              </>
            ) : this.props.formValues?.type_id === 2 || this.props.formValues?.type_id === 16 ? (
              <>
                <div style={{ marginTop: 20 }}>
                  <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                    Limite de participação
                  </Typography>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Limite de participações na atividade"
                      name="limit"
                      validate={[FORM_RULES.required]}
                      //disabled={this.props.disabled}
                      required
                      margin="normal"
                      {...thousandSeparatorMask}
                    />
                  </MaterialGrid>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginTop: 20 }}>
                  <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
                    Limite de participação
                  </Typography>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Limite de participações na atividade"
                      name="limit"
                      validate={[FORM_RULES.required]}
                      //disabled={this.props.disabled}
                      required
                      margin="normal"
                      {...thousandSeparatorMask}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Limite de participações por usuário"
                      name="limitPerUser"
                      validate={[FORM_RULES.required]}
                      //disabled={this.props.disabled}
                      required
                      margin="normal"
                      {...thousandSeparatorMask}
                    />
                  </MaterialGrid>
                </div>
              </>
            )}

            {this.props.formValues?.type_id === 1 || this.props.formValues?.type_id === 19 ? (
              <>
                <MaterialGrid container spacing={1}>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputSwitchLarge}
                      label={'Quem convida ganha recompensa?'}
                      disabled={this.props.disabled}
                      name="isInviterPaid"
                    />
                  </MaterialGrid>
                  {this.props.formValues.isInviterPaid ? (
                    <MaterialGrid item xs={12}>
                      <Field
                        component={InputLabelOutlined}
                        label={'De quanto será a recompensa de quem convida?'}
                        disabled={this.props.disabled}
                        name="points_inviter"
                        type="tel"
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                      />
                    </MaterialGrid>
                  ) : null}
                </MaterialGrid>
                <MaterialGrid container spacing={1}>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputSwitchLarge}
                      label={'O convidado ganhará recompensa?'}
                      disabled={this.props.disabled}
                      name="isInvitedPaid"
                    />
                  </MaterialGrid>
                  {this.props.formValues.isInvitedPaid ? (
                    <MaterialGrid item xs={12}>
                      <Field
                        component={InputLabelOutlined}
                        label={'De quanto será a recompensa do convidado?'}
                        name="points_invited"
                        type="tel"
                        disabled={this.props.disabled}
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                      />
                    </MaterialGrid>
                  ) : null}
                </MaterialGrid>
              </>
            ) : (
              <>
                <>
                  <MaterialGrid item xs={12} sm={12}>
                    <Field
                      component={InputSwitchLarge}
                      label="O participante ganhará uma recompensa?"
                      disabled={this.props.disabled}
                      name="isPaid"
                    />
                  </MaterialGrid>
                  {this.props.formValues && this.props.formValues.isPaid ? (
                    <MaterialGrid container spacing={3}>
                      <MaterialGrid item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Quanto vai ganhar quem participar? (R$)"
                          name="points"
                          disabled={this.props.disabled}
                          type="tel"
                          validate={[FORM_RULES.required]}
                          required
                          margin="normal"
                          {...currencyMask}
                        />
                      </MaterialGrid>
                      {this.renderPoints()}
                    </MaterialGrid>
                  ) : null}
                </>
              </>
            )}

            {parseInt(this.props.formValues ? this.props.formValues?.type_id : 0) !== 16 ? (
              // <MaterialGrid container xs={12} spacing={3}>
              <>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputSwitchLarge}
                    label={'Regulamento'}
                    name="has_rules"
                    default={true}
                    disabled={this.props.disabled}
                  />
                </MaterialGrid>
                {this.props.formValues && this.props.formValues.has_rules && (
                  <MaterialGrid item xs={12}>
                    <Field
                      component={EditorConvertToHTMLActivity}
                      label={''}
                      name="rules"
                      border
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                )}
              </>
            ) : // </MaterialGrid>
            null}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Receber troféu ao concluir'}
                disabled={this.props.disabled}
                name="isThereTrophy"
                // required
                margin="normal"
              />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.isThereTrophy ? (
                <>
                  <MaterialGrid item xs={12}>
                    <Typography
                      style={{ marginBottom: 5, marginTop: 16 }}
                      variant="subtitle2"
                      align="left">
                      Ícone do Troféu
                    </Typography>
                    <Field
                      component={InputImageWithProcessing}
                      label={'Imagem .png ou .jpg'}
                      cols="12 12 12 12"
                      name="trophy_image"
                      aspect={1 / 1}
                      aspectLabel={'1:1'}
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                    <Typography
                      style={{ marginBottom: 5, display: 'inline' }}
                      variant="subtitle2"
                      align="left">
                      Mensagem de exibição do troféu
                    </Typography>
                    <div style={{ cursor: 'pointer', display: 'inline' }}>
                      <Tooltip
                        style={{ marginLeft: 10 }}
                        title="É a mensagem que aparecerá para o usuário ao passar o mouse no ícone do troféu."
                        placement="top-start">
                        <Icon color="default">help</Icon>
                      </Tooltip>
                    </div>
                    <Field
                      component={InputLabelOutlined}
                      label="Texto do troféu"
                      name="trophy_description"
                      margin="normal"
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </>
              ) : null
            ) : null}
            {this.props.giveaways.list.length > 0 ? (
              <>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputSwitchLarge}
                    disabled={this.props.disabled || this.props.router.params.action === 'proposta'}
                    label={'A atividade recompensará com Números da Sorte?'}
                    name="has_giveaway"
                  />
                </MaterialGrid>
                {this.props.formValues ? (
                  this.props.formValues.has_giveaway &&
                  (parseInt(this.props.formValues?.type_id) === 1 ||
                    parseInt(this.props.formValues?.type_id) === 19) ? (
                    <>
                      <MaterialGrid item xs={12}>
                        <Field
                          component={SelectLabel}
                          type="text"
                          label={`Promoção`}
                          name={`giveaway_id`}
                          disabled={
                            this.props.disabled || this.props.router.params.action === 'proposta'
                          }
                          margin="normal"
                          options={giveawaysList}
                          validate={[FORM_RULES.required]}
                        />
                      </MaterialGrid>
                      <MaterialGrid item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          type="number"
                          label={`Quantidade de números que o participante que convida ganhará`}
                          name={`giveaway_quantity`}
                          disabled={
                            this.props.disabled || this.props.router.params.action === 'proposta'
                          }
                          margin="normal"
                          validate={[FORM_RULES.required]}
                        />
                      </MaterialGrid>
                      <MaterialGrid item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          type="number"
                          label={`Quantidade de números que o participante convidado ganhará`}
                          name={`giveaway_quantity_invited`}
                          disabled={
                            this.props.disabled || this.props.router.params.action === 'proposta'
                          }
                          margin="normal"
                          validate={[FORM_RULES.required]}
                        />
                      </MaterialGrid>
                    </>
                  ) : null
                ) : null}
                {this.props.formValues ? (
                  this.props.formValues.has_giveaway &&
                  parseInt(this.props.formValues?.type_id) !== 1 &&
                  parseInt(this.props.formValues?.type_id) !== 19 ? (
                    <MaterialGrid item xs={12}>
                      <Field
                        component={SelectLabel}
                        type="text"
                        label={`Promoção`}
                        name={`giveaway_id`}
                        disabled={
                          this.props.disabled || this.props.router.params.action === 'proposta'
                        }
                        margin="normal"
                        options={giveawaysList}
                        validate={[FORM_RULES.required]}
                      />
                      <MaterialGrid item xs={12}>
                        <Field
                          component={InputLabelOutlined}
                          label="Quantos números da sorte vai ganhar quem participar"
                          name="giveaway_numbers"
                          type="tel"
                          validate={[FORM_RULES.required]}
                          required
                          margin="normal"
                        />
                      </MaterialGrid>
                    </MaterialGrid>
                  ) : null
                ) : null}
              </>
            ) : null}

            {this.props.router.params.action === 'proposta' ? (
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputSwitchLarge}
                  label="A atividade possuirá cashback adicional?"
                  name="hasCashBack"
                />
              </MaterialGrid>
            ) : null}
            {this.props.formValues && this.props.formValues.hasCashBack ? (
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputLabelOutlined}
                  label="Qual o valor de cashback? (R$)"
                  name="additional_cashback"
                  type="tel"
                  disabled={this.props.disabled}
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                  {...currencyMask}
                />
              </MaterialGrid>
            ) : null}

            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              title={this.props.confirmationTitle}
              text={this.props.confirmationSubtitle}
              general={this.props.general}
              maxWidthFixed
              confirmButtonColor={'primary'}
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
            }}>
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              onClick={() => this.setState({ submitted: true })}
              disabled={this.props.activeActions.buttonLoading}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Prize.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  disabled: PropTypes.any,
  formValues: PropTypes.shape({
    feedback_lottie_id: PropTypes.string,
    hasCashBack: PropTypes.any,
    has_giveaway: PropTypes.any,
    has_rules: PropTypes.any,
    isInvitedPaid: PropTypes.any,
    isInviterPaid: PropTypes.any,
    isPaid: PropTypes.any,
    isThereTrophy: PropTypes.any,
    limit: PropTypes.number,
    points: PropTypes.number,
    points_invited: PropTypes.number,
    points_inviter: PropTypes.number,
    type_id: PropTypes.number,
  }),
  general: PropTypes.any,
  getEstablishmentPoints: PropTypes.func,
  getGiveawaysStationId: PropTypes.func,
  getLottiesList: PropTypes.func,
  getLottiesListById: PropTypes.func,
  giveaways: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  lotties: PropTypes.shape({
    detail: PropTypes.shape({
      json: PropTypes.any,
    }),
    list: PropTypes.shape({
      content: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    loading: PropTypes.any,
  }),
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  points: PropTypes.shape({
    points: PropTypes.shape({
      points: PropTypes.any,
      allocated_points: PropTypes.any,
    }),
  }),
  prevClick: PropTypes.any,
  requiredFieldError: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
    }),
  }),
  setOpenConfirmation: PropTypes.func,
  submitErrors: PropTypes.any,
};

const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    lotties: state.lotties,
    general: state.general,
    giveaways: state.giveaways,
    formValues: getFormValues(form)(state),
    submitErrors: getFormSyncErrors(form)(state),
    metaForm: getFormMeta(form)(state),
    form: form,
    points: state.points,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLottiesListById,
      getLottiesList,
      getGiveawaysStationId,
      requiredFieldError,
      getEstablishmentPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Prize)
);
