import { getListTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  listTemplates: {
    content: [],
  },
  detail: {},
  loading: false,
  loadingTemplates: false,
  buttonLoading: false,
  status: 'idle',
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REDIRECT_LINK_INTERNAL_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.REDIRECT_LINK_INTERNAL_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
        status: 'resolved',
      };
    case types.REDIRECT_LINK_INTERNAL_LOAD:
      return {
        ...state,
        loading: action.payload,
        status: action.payload,
      };
    case types.REDIRECT_LINK_INTERNAL_TEMPLATES_FETCHED:
      return {
        ...state,
        listTemplates: getListTransform(action.payload.data) || INITIAL_STATE.listTemplates,
        loadingTemplates: false,
      };
    case types.REDIRECT_LINK_INTERNAL_TEMPLATES_LOAD:
      return {
        ...state,
        loadingTemplates: action.payload,
      };
    case types.REDIRECT_LINK_INTERNAL_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };

    default:
      return state;
  }
};
