import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Owners from './owners';

export default (
  <Route path="gestores">
    <IndexRoute component={(props) => <Owners {...props} />} />
    {/* <Route path="novo" component={(props) => <OwnersForm {...props} />} />
    <Route path=":id/:action" component={(props) => <OwnersForm {...props} />} /> */}
  </Route>
);
