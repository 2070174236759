import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import CardActionInfo from 'common/components/cards/cardActionInfo/cardActionInfo';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  getStationActionsDrafts,
  onClickDraftAction,
  deleteActionDraft,
} from './actionsDraftsScripts';

function ActiveActions(props) {
  const { auth } = props;
  const content = props.actionsDrafts.list;

  useEffect(() => {
    props.getStationActionsDrafts(auth.user.establishments[0].stations[0].id);
  }, []);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [item, setItem] = React.useState(undefined);

  function activityStatus(activity) {
    if (!activity.active) return ['OFFLINE', '#707070'];
    if (moment(new Date()).isBetween(activity.participationStart, activity.end))
      return ['ONLINE', '#169f53'];
    if (moment(new Date()).isBetween(activity.start, activity.participationStart))
      return ['VISÍVEL', '#de7b12'];

    return ['OFFLINE', '#707070'];
  }

  function renderEmptyScreen() {
    return (
      <section id="activeActions" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você não possui atividades em construção.</h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton onClick={() => props.router.push('/owner/acoes')} color="primary">
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionsDrafts.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section>
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.actionsDrafts.buttonLoading}
        // state={props.actionsDrafts}
        general={props.general}
        title="Deletar rascunho"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja deletar esse rascunho? Essa ação não poderá ser desfeita"
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) props.deleteActionDraft(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item sm={12} style={{ padding: '0 2em' }}>
          <GeneralGrid
            container
            spacing={3}
            justify={'space-between'}
            style={{ padding: '2.6em 0 2.6em 0' }}>
            <GeneralGrid item sm={8} md={6} lg={4} style={{ padding: '12px 12px 12px 5px' }}>
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                    textAlign: 'left',
                    textJustify: 'center',
                    backgroundColor: '#f7f7f7',
                  }}>
                  Estas são as atividades em construção.
                </Typography>
              </Box>
            </GeneralGrid>
            <GeneralGrid
              item
              sm={4}
              md={6}
              lg={8}
              style={{ padding: '12px 0 12px 12px' }}></GeneralGrid>
          </GeneralGrid>
          {content.map((item, index) => (
            <CardActionInfo
              key={index}
              index={item.id}
              summary={item.summary}
              data={item}
              condition={activityStatus(item)}
              onClickDelete={() => {
                setOpenConfirmation(true);
                setItem(item.id);
              }}
              onClick={() => props.onClickDraftAction(item, props.router)}
            />
          ))}
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

ActiveActions.propTypes = {
  actionsDrafts: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteActionDraft: PropTypes.func,
  general: PropTypes.any,
  getStationActionsDrafts: PropTypes.func,
  onClickDraftAction: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    actionsDrafts: state.actionsDrafts,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getStationActionsDrafts, onClickDraftAction, deleteActionDraft },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveActions);
