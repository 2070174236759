import React from 'react';
import { Box } from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';

const EmptyScreen = ({ page }) => {
  const messagesByPage = {
    editParticipant: 'Participante não encontrado',
  };

  const message = messagesByPage[page] || 'Algo deu errado!';

  return (
    <GeneralGrid container justifyContent="center">
      <Box display="flex" justifyContent="center">
        <h2 className="signup-text">{message}</h2>
      </Box>
    </GeneralGrid>
  );
};

export default EmptyScreen;
