import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../authActions';

class Logout extends Component {
  UNSAFE_componentWillMount() {
    this.props.logout();

    window.location.href =
      process.env.REACT_APP_API_KEY === 'production' ||
      process.env.REACT_APP_API_KEY === 'production-temp'
        ? 'https://rilato.com.br'
        : process.env.REACT_APP_API_KEY === 'test'
        ? 'https://sandbox-gestor.rilato.com.br'
        : 'http://localhost:3000';
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
