import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'common/components/content/content';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import Table from 'common/components/table/table';
import { getQuizzes, remove } from './manageActions';

class ManageQuiz extends Component {
  constructor(props) {
    super(props);

    this.props.getQuizzes(this.props.auth.user.establishments[0].id);
  }

  render() {
    const { router } = this.props;
    const { list } = this.props.quizzes;
    return (
      <Content>
        <Card>
          <CardTitle title="Quiz" />
          <Link to="/owner/manage-quiz/create">
            <center>
              <button type="button" className="btn btn-primary" style={{ marginBottom: 20 }}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar um novo Quiz
              </button>
            </center>
          </Link>
          <CardText>
            <Table
              path={'/owner/manage-quiz'}
              router={router}
              values={list}
              columns={[
                { field: 'id', header: 'Id' },
                { field: 'name', header: 'Nome' },
                { field: 'description', header: 'Descrição' },
                { field: 'points', header: 'Créditos' },
                { field: 'expiration', header: 'Expiração' },
                { field: 'limit', header: 'Limite' },
              ]}
              remove={this.props.remove}
              permissions={['can_delete', 'can_edit']}
            />
          </CardText>
        </Card>
      </Content>
    );
  }
}

ManageQuiz.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getQuizzes: PropTypes.func,
  quizzes: PropTypes.shape({
    list: PropTypes.any,
  }),
  remove: PropTypes.any,
  router: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getQuizzes,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageQuiz);
