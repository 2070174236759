import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { cpfMask, currencyMask, phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import GroceryList from 'common/components/GroceryList';
import { createOrder, initializeForm, getGroceryList } from './deliveryOrdersActions';

let DeliveryForm = function (props) {
  const { auth, createOrder, initializeForm, getGroceryList, groceryList } = props;
  const users = props.contacts.map((item) => {
    return { label: item.name, value: item.id };
  });

  function autoComplete(e) {
    const values = {};
    for (const item of props.contacts) {
      if (item.id === e.target.value) {
        values.name = item.name;
        values.cpf = item.cpf;
        values.phone = item.tel;
      }
    }
    initializeForm(values);
  }

  useEffect(() => {
    getGroceryList();
  }, []);

  function onSubmit(values) {
    const newValues = { ...values };

    newValues.station_id = auth.user.establishments[0].stations[0].id;

    createOrder(newValues, props.router);
  }
  return (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Usuário'}
              name={'user_id'}
              options={users}
              component={SelectLabel}
              required
              onChange={autoComplete}
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'CPF'}
              name={'cpf'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
              {...cpfMask}
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Nome Completo'}
              name={'name'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Telefone'}
              name={'phone'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
              {...phoneMask}
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Endereço'}
              name={'address'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Complemento/Referência'}
              name={'reference'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Valor máximo a ser gasto na compra'}
              name={'max_value'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
              {...currencyMask}
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field name={'grocery_list'} items={groceryList} component={GroceryList} />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Field
              label={'Observação'}
              name={'description'}
              component={InputLabelOutlined}
              validate={[FORM_RULES.required]}
              required
              multiline
              rowsMax={5}
              rows={3}
            />
          </GeneralGrid>
        </GeneralGrid>
        <Box style={{ widht: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <PrimaryButton
            type="submit"
            disabled={props.loading}
            progress={parseInt(props.general.submitProgress)}>
            {props.loading ? 'Criando pedido...' : 'Criar pedido'}
          </PrimaryButton>
        </Box>
      </Form>
    </>
  );
};

DeliveryForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  contacts: PropTypes.shape({
    map: PropTypes.func,
  }),
  createOrder: PropTypes.func,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getGroceryList: PropTypes.func,
  groceryList: PropTypes.any,
  handleSubmit: PropTypes.func,
  initializeForm: PropTypes.func,
  loading: PropTypes.any,
  router: PropTypes.any,
};

DeliveryForm = reduxForm({
  form: 'DeliveryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(DeliveryForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
    contacts: state.contacts.list.content,
    loading: state.deliveryOrders.loading,
    groceryList: state.deliveryOrders.groceryList,
    formValues: getFormValues('DeliveryForm')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createOrder, initializeForm, getGroceryList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryForm);
