import React from 'react';
import Grid from '../layout/grid/grid';
import PropTypes from 'prop-types';

export class InputLabel2 extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
      cols,
      id,
      label,
      placeholder,
      type,
      name,
      style,
      className,
    } = this.props;
    return (
      <Grid cols={cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <label htmlFor={id}>{label}</label>
          <input
            className={`form-control ${className} ${touched && error && 'is-invalid'}`}
            aria-describedby="emailHelp"
            {...this.props.input}
            id={this.props.id}
            placeholder={placeholder}
            readOnly={this.props.readOnly}
            type={type}
            name={name}
            style={style}
          />
          {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
      </Grid>
    );
  }
}

InputLabel2.propTypes = {
  className: PropTypes.any,
  cols: PropTypes.any,
  id: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.any,
  readOnly: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.any,
};
