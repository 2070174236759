import PropTypes from 'prop-types';
import { InputFile } from 'common/components/fields/InputFile';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import { InputLabelNatural } from 'common/components/fields/InputLabelNatural';
import { InputAuth } from 'common/components/fields/InputAuth';
import Grid from 'common/components/layout/grid/grid';
import { cnpjMask, cpfMask, phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import LoadingProgress from 'common/components/progress/loading';
import { codeValidate, signup, signupCompany } from '../authActions';
import './signup.css';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      percent: 0,
    };
    if (!_.isUndefined(this.props.router.params.activationString)) {
      this.validate();
    }
  }

  async validate() {
    const token = !_.isUndefined(this.props.router.params.activationString) ? 'activate' : null;
    const value = !_.isUndefined(this.props.router.params.activationString)
      ? this.props.router.params.activationString
      : this.props.formValues.activation_code;
    await this.props.codeValidate(value, token);
    this.setState({ step: 2, percent: 25 });
  }

  renderOptions(establishmentsList, establishmentTypesList) {
    if (!_.isUndefined(this.props.formValues)) {
      /// //console.log(this.props.formValues.hasCompany)
      if (parseInt(this.props.formValues.hasCompany) === 1) {
        return (
          <div>
            <Field
              component={InputLabelNatural}
              className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
              id="floating-center-title"
              type="text"
              name="activation_code"
              icon="envelope"
              label="Qual é o código de ativação da sua empresa?"
              style={{
                display: parseInt(this.state.step) === 1 ? 'block' : 'none',
              }}
              cols="12 12 12 12"
              validate={
                _.isUndefined(this.props.router.params.activationString)
                  ? [FORM_RULES.required]
                  : null
              }
            />
            <Grid
              cols={`12 12 12 12`}
              style={{
                display: parseInt(this.state.step) === 1 ? 'block' : 'none',
              }}>
              <button
                type="button"
                onClick={() => this.validate()}
                className="btn btn-lg btn-block button-login">
                Validar código
              </button>
            </Grid>
            {!_.isNull(this.props.auth.establishmentActivation) ? (
              <div
                style={{
                  display: parseInt(this.state.step) === 2 ? 'block' : 'none',
                }}>
                <p>Parabéns. Você vai ser cadastrado na empresa</p>
                <h3>{this.props.auth.establishmentActivation.name}</h3>
              </div>
            ) : null}
          </div>
        );
      } else if (parseInt(this.props.formValues.hasCompany) === 2) {
        return (
          <div>
            <Field
              component={InputFile}
              multiple={false}
              name="image"
              label="Por favor, faça upload da logo da sua empresa"
              style={{
                display: parseInt(this.state.step) === 1 ? 'block' : 'none',
              }}
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
            />
            <Field
              component={SelectLabel}
              className="md-cell md-cell--bottom"
              id="floating-center-title"
              label={'Como você classificaria sua instituição?'}
              style={{
                display: parseInt(this.state.step) === 2 ? 'block' : 'none',
              }}
              options={establishmentTypesList}
              name="establishmentType"
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
            />

            <Field
              component={InputLabelNatural}
              className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
              id="floating-center-title"
              type="text"
              name="establishmentName"
              icon="envelope"
              label="Qual é o nome da sua empresa?"
              style={{
                display: parseInt(this.state.step) === 2 ? 'block' : 'none',
              }}
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
            />
            <Field
              component={InputLabelNatural}
              className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
              id="floating-center-title"
              type="text"
              name="establishmentDescription"
              icon="envelope"
              label="Descreva aqui o que vocês fazem"
              style={{
                display: parseInt(this.state.step) === 2 ? 'block' : 'none',
              }}
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
            />
            <Field
              component={InputLabelNatural}
              className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
              id="floating-center-title"
              type="tel"
              name="cnpj"
              icon="envelope"
              label="CNPJ da sua empresa"
              style={{
                display: parseInt(this.state.step) === 2 ? 'block' : 'none',
              }}
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
              {...cnpjMask}
            />
          </div>
        );
      }
    }
  }

  onSubmit(values) {
    // console.log(values);
    if (values.password === values.password_confirmation) {
      const { signup, signupCompany, router } = this.props;
      /// //////console.log(values);
      if (!_.isUndefined(this.props.formValues)) {
        if (parseInt(this.props.formValues.hasCompany) === 1) {
          values.establishmentId = this.props.auth.establishmentActivation.id;
          signup(values, router);
        } else if (parseInt(this.props.formValues.hasCompany) === 2) {
          signupCompany(values, router);
        }
      }
    } else {
      toastr.error(
        'A senha não bate com a confirmação, por favor, confira se colocou corretamente.'
      );
    }
  }

  validateNext() {
    let condition = '';

    if (!_.isUndefined(this.props.formValues)) {
      if (this.state.step < 5) {
        if (parseInt(this.props.formValues.hasCompany) !== 1) {
          condition = 'block';
        } else {
          if (parseInt(this.state.step) === 1) {
            condition = 'none';
          }
        }
      } else {
        condition = 'none';
      }
    }
    return {
      display: condition,
    };
  }

  render() {
    const { establishmentTypes, establishments } = this.props;

    const establishmentTypesList = establishmentTypes.list.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    const establishmentsList = establishments.list.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    const { handleSubmit } = this.props;

    return (
      <div>
        <div className="container-login">
          <center style={{ maxWidth: '30rem', margin: '0 auto', paddingTop: '5rem' }}>
            <ProgressBar filledBackground="#0adf9f" percent={this.state.percent}>
              <Step>
                {({ accomplished, index }) => (
                  <div>
                    <div
                      onClick={() => this.setState({ step: 1, percent: 0 })}
                      className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
                      {index + 1}
                    </div>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    onClick={() => this.setState({ step: 2, percent: 25 })}
                    className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    onClick={() => this.setState({ step: 3, percent: 50 })}
                    className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    onClick={() => this.setState({ step: 4, percent: 75 })}
                    className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    onClick={() => this.setState({ step: 5, percent: 100 })}
                    className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
            </ProgressBar>
          </center>
          <div className="signup">
            <form onSubmit={handleSubmit((v) => this.onSubmit(v))} noValidate>
              <Field
                component={SelectLabel}
                className="md-cell md-cell--bottom"
                id="floating-center-title"
                label={'Você possui uma empresa ou instituição?'}
                style={{
                  display: parseInt(this.state.step) === 1 ? 'block' : 'none',
                }}
                options={[
                  { value: 1, label: 'Trabalho em uma empresa já cadastrada' },
                  { value: 2, label: 'Quero filiar a minha empresa' },
                ]}
                name="hasCompany"
                cols="12 12 12 12"
                validate={[FORM_RULES.required]}
              />
              {this.renderOptions(establishmentsList, establishmentTypesList)}
              <Field
                component={InputLabelNatural}
                className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
                id="floating-center-title"
                type="text"
                name="name"
                icon="envelope"
                label="Seu Nome"
                style={{
                  display: parseInt(this.state.step) === 3 ? 'block' : 'none',
                }}
                cols="12 12 12 12"
                validate={[FORM_RULES.required]}
              />
              <Field
                component={InputAuth}
                className="md-cell md-cell--bottom"
                id="floating-center-title"
                type="email"
                name="email"
                icon="envelope"
                label="Email"
                style={{
                  display: parseInt(this.state.step) === 3 ? 'block' : 'none',
                }}
                cols="12 12 12 12"
                validate={[FORM_RULES.required, FORM_RULES.email]}
              />
              <Field
                component={InputLabelNatural}
                className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
                id="floating-center-title"
                type="tel"
                name="tel"
                icon="envelope"
                label="Celular"
                style={{
                  display: parseInt(this.state.step) === 4 ? 'block' : 'none',
                }}
                cols="12 12 12 12"
                validate={[FORM_RULES.required]}
                {...phoneMask}
              />
              <Field
                component={InputLabelNatural}
                className="md-cell md-cell--bottom md-text-field md-text-field--floating-margin md-full-width md-text"
                id="floating-center-title"
                type="tel"
                name="cpf"
                icon="envelope"
                label="CPF"
                style={{
                  display: parseInt(this.state.step) === 4 ? 'block' : 'none',
                }}
                cols="12 12 12 12"
                validate={[FORM_RULES.required]}
                {...cpfMask}
              />
              <div
                style={{
                  display: this.props.auth.activationBegan ? 'none' : 'block',
                }}>
                <Field
                  className="md-cell md-cell--bottom"
                  id="floating-password"
                  component={InputAuth}
                  type="password"
                  name="password"
                  icon="key"
                  label="Senha"
                  style={{
                    display: parseInt(this.state.step) === 5 ? 'block' : 'none',
                  }}
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  className="md-cell md-cell--bottom"
                  id="floating-password"
                  component={InputAuth}
                  type="password"
                  name="password_confirmation"
                  icon="key"
                  label="Confirmar Senha"
                  style={{
                    display: parseInt(this.state.step) === 5 ? 'block' : 'none',
                  }}
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Grid cols="12 12 6 6" style={{ display: this.state.step > 1 ? 'block' : 'none' }}>
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({
                        step: this.state.step - 1,
                        percent: this.state.percent - 25,
                      });
                    }}
                    className="btn btn-lg btn-block button-login">
                    Voltar
                  </button>
                </Grid>
                <div
                  className="social-auth-links text-center"
                  style={{
                    display: parseInt(this.state.step) === 5 ? 'block' : 'none',
                  }}>
                  <Grid cols="12 12 6 6">
                    <button type="submit" className="btn btn-lg btn-block button-login">
                      {this.props.auth.loadingRegister ? (
                        <div className="d-flex justify-content-center mt-5">
                          <LoadingProgress />
                        </div>
                      ) : (
                        'Começar'
                      )}
                    </button>
                  </Grid>
                </div>
                <Grid
                  cols={`12 12 ${this.state.step === 1 ? '12 12' : '6 6'}`}
                  style={this.validateNext()}>
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({
                        step: this.state.step + 1,
                        percent: this.state.percent + 25,
                      });
                    }}
                    className="btn btn-lg btn-block button-login">
                    Próximo
                  </button>
                </Grid>
              </div>
              <div
                style={{
                  display: this.props.auth.activationBegan ? 'block' : 'none',
                }}>
                Enviamos a confirmação no seu e-mail!
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  auth: PropTypes.shape({
    activationBegan: PropTypes.any,
    establishmentActivation: PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.any,
    }),
    loadingRegister: PropTypes.any,
  }),
  codeValidate: PropTypes.func,
  establishmentTypes: PropTypes.shape({
    list: PropTypes.shape({
      map: PropTypes.func,
    }),
  }),
  establishments: PropTypes.shape({
    list: PropTypes.shape({
      map: PropTypes.func,
    }),
  }),
  formValues: PropTypes.shape({
    activation_code: PropTypes.any,
    hasCompany: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      activationString: PropTypes.any,
    }),
  }),
  signup: PropTypes.func,
  signupCompany: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('authForm')(state),
    establishments: state.establishments,
    establishmentTypes: state.establishmentTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      signupCompany,
      codeValidate,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'authForm' })(Signup));
