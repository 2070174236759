import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import profile_avatar from 'assets/img/profile_avatar.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialCard from 'common/components/cards/materialCard';
import LoadingProgress from 'common/components/progress/loading';
import {
  getCupomInfo,
  getParticipantInfo,
  validateCupom,
  validateParticipant,
} from './validationsActions';
import { BASE_S3 } from '../../../../config/consts';

function ValidationsInfo(props) {
  useEffect(() => {
    props.getParticipantInfo(props.router.params.code, props.router);
  }, []);

  function validate() {
    props.validateParticipant(props.validations.participant.id, props.router);
  }

  if (props.validations.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <Box mt={10}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3} justify="center">
            <GeneralGrid item xs={10} md={4}>
              <Box display="flex" justifyContent="center">
                <MaterialCard
                  name={props.validations.participant.user.name}
                  image={`${BASE_S3}/${props.validations.participant.user.image}` || profile_avatar}
                  description={props.validations.participant.user.email}
                  type={'Nome:'}
                  noButton={true}
                />
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3} justify="center">
            <GeneralGrid item xs={12} md={4}>
              <Box display="flex" justifyContent="center">
                <PrimaryButton type="button" onClick={validate} color="primary">
                  Validar
                </PrimaryButton>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </Box>
  );
}

ValidationsInfo.propTypes = {
  getParticipantInfo: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.any,
    }),
  }),
  validateParticipant: PropTypes.func,
  validations: PropTypes.shape({
    loading: PropTypes.any,
    participant: PropTypes.shape({
      id: PropTypes.any,
      user: PropTypes.shape({
        email: PropTypes.any,
        image: PropTypes.any,
        name: PropTypes.any,
      }),
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    validations: state.validations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getParticipantInfo,
      validateParticipant,
      validateCupom,
      getCupomInfo,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationsInfo);
