import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { Add } from '@material-ui/icons';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { ActionNameElement, ColumnsBelowSearch } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { getList, remove } from './ownersActions';

export function Owners(props) {
  const { list, loading } = props.owners;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const defaultColsMob = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => (
        <ActionNameElement singleColum={false} actionName={rowData.user ? rowData.user.name : ''} />
      ),
    },
    {
      field: 'actions',
      headerCenter: true,
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  const defaultCols = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => <ActionNameElement actionName={rowData.user ? rowData.user.name : ''} />,
    },
    {
      field: 'user.email',
      title: 'E-mail',
      render: (rowData) => rowData.user?.email,
    },
    {
      field: 'actions',
      headerCenter: true,
      title: '',
      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? defaultColsMob : defaultCols;

  const [data, setData] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    // setData(props.owners.list.content.filter((item)=> item.user!==null));
    setData(props.owners.list.content);
  }, [props.owners.list.content]);

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não fez nenhuma notícia</h2>
                <p>
                  Poste uma notícia na sua estação para que seus associados estejam sempre
                  atualizados.
                </p>
                <PrimaryButton onClick={() => props.router.push('/owner/content/noticias/novo')}>
                  Criar Notícia
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="owners">
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.owners.loading}
        // s/ state={props.contacts}
        general={props.general}
        title="Remover Gestor"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover gestor?"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            hasToolbar
            toolbarSearch
            defaultSelected={[]}
            title="Gestores"
            columns={columns}
            rows={data}
            dataType={'owners'}
            onChangeOption={() => {}}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>

      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Fab
          variant="extended"
          style={{ background: '#fff' }}
          onClick={() => props.router.push('/owner/contacts/gestor')}>
          <Add className={classes.extendedIcon} />
          Adicionar
        </Fab>
      </Box>
    </section>
  );
}

Owners.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  general: PropTypes.any,
  getList: PropTypes.func,
  owners: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    owners: state.owners,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Owners);
