import PropTypes from 'prop-types';
import { Dialog, Slide } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Dropzone from 'react-dropzone';
import { ColorPicker, IconBackground, Question, TextImage, TextColorPicker } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Questions({ input: { onBlur, onChange, value } }) {
  const [image, setImage] = useState(undefined);
  const [background, setBackground] = useState('#2D95EF');
  const [textColor, setTextColor] = useState('#fff');
  const [open, setOpen] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [counter, setCounter] = useState(0);
  const [selectedDate, setSelectedDate] = useState(value.text || null);

  function handleDateChange(e) {
    setSelectedDate(e.target.value);
  }

  useEffect(() => {
    setSelectedDate(value.text);
  }, [value.text]);

  function onClose() {
    setOpen(false);
    setOpenText(false);
  }

  function handleOpen() {
    setOpen(true);
    setCounter(counter + 1);
  }

  function handleOpenText() {
    setCounter(counter + 1);
    setOpenText(true);
  }

  function handleChangeComplete(color) {
    setBackground(color.hex);
  }

  function handleChangeCompleteText(color) {
    setTextColor(color.hex);
  }
  const dropzoneProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 1200000,
  };

  useEffect(() => {
    if (value.base64) {
      setImage(value.base64);
      setTextColor(value.textColor);
    } else if (value.color) {
      setBackground(value.color);
      setTextColor(value.textColor);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      setImage(undefined);
      onChange({
        ...value,
        base64: undefined,
        color: background,
      });
    }
  }, [background]);

  useEffect(() => {
    if (counter > 0) {
      onChange({
        ...value,
        textColor,
      });
    }
  }, [textColor]);

  function onDrop(imageFiles) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function () {
      onBlur({ base64: reader.result });
      onChange({
        ...value,
        color: undefined,
        base64: reader.result,
      });
      setImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  return (
    <>
      <Question background={background} textColor={textColor} image={image}>
        {!image ? (
          <textarea
            spellCheck="false"
            rows="2"
            cols="50"
            maxLength="300"
            name="text"
            value={selectedDate}
            onChange={(e) => handleDateChange(e)}
            onBlur={(e) => onChange({ ...value, text: e.target.value })}
          />
        ) : null}

        <TextColorPicker onClick={handleOpenText} />
        <ColorPicker onClick={handleOpen} />

        <Dropzone {...dropzoneProps}>
          {({ getRootProps, getInputProps }) => (
            <IconBackground {...getRootProps()}>
              <label htmlFor="image">
                <ImageIcon style={{ color: 'white' }} />
                <input {...getInputProps()} />
              </label>
            </IconBackground>
          )}
        </Dropzone>
      </Question>
      {image ? (
        <TextImage
          maxlength="300"
          textColor={textColor === '#FFF' ? '#000' : textColor}
          value={selectedDate}
          onChange={(e) => handleDateChange(e)}
          onBlur={(e) => onChange({ ...value, text: e.target.value })}
        />
      ) : null}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <SketchPicker color={background} onChangeComplete={handleChangeComplete} />
      </Dialog>
      <Dialog
        open={openText}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <SketchPicker color={textColor} onChangeComplete={handleChangeCompleteText} />
      </Dialog>
    </>
  );
}

Questions.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
  }),
};
