import types from './type';

const INITIAL_STATE = {
  buttonLoading: false,
  loading: false,
  actionsList: [],
  actionsLoading: false,
  list: [],
  loadingDetail: true,
  item: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.JOURNEYS_LOAD:
      return { ...state, loading: action.payload };
    case types.JOURNEYS_FETCHED:
      return { ...state, loading: false, list: action.payload };
    case types.ACTIONS_LOAD:
      return { ...state, actionsLoading: action.payload };
    case types.ACTIONS_FETCHED:
      return { ...state, actionsLoading: false, actionsList: action.payload };
    case types.JOURNEYS_BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case types.JOURNEYS_DETAIL_LOAD:
      return { ...state, loadingDetail: action.payload };
    case types.JOURNEYS_DETAIL_FETCHED:
      return { ...state, loadingDetail: false, item: action.payload };
    default:
      return state;
  }
};
