import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import MovieIcon from '@material-ui/icons/Movie';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import React from 'react';
import './cardJourneyInfo.css';

export default function CardJourneyInfo(props) {
  return (
    <GeneralGrid
      container
      justify="space-between"
      spacing={3}
      style={{
        backgroundColor: '#ffff',
        marginBottom: '2.5em',
        height: 190,
      }}>
      <GeneralGrid item sm={7} md={6} lg={8}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
          style={{ paddingLeft: 10, paddingTop: 5 }}>
          <Box>
            <Typography variant="h6">{props.name}</Typography>
            <Typography style={{ color: '#959595' }} variant="subtitle1">
              {props.description}
            </Typography>
          </Box>
          <Box style={{ width: '100%', marginLeft: '-10px' }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" justifyContent="space-between">
                <Box style={{ width: 'fit-content' }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    onClick={props.onClickEdit}
                    className="links">
                    Editar
                  </Typography>
                </Box>
                <Box style={{ width: 'fit-content' }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    onClick={props.onClickDelete}
                    className="links">
                    Excluir
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </GeneralGrid>
      <GeneralGrid item sm={5} md={4} lg={3}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          style={{ height: '100%' }}>
          {/* <Box display="flex" justifyContent="flex-end" alignItems="center">
            <p
              style={{
                marginBottom: 0,
                fontSize: 14,
                color: '#ffff',
                backgroundColor: props.condition ? '#ffff' : '#ffff',
                padding: '5px 10px',
              }}
            >
              {props.condition ? 'ONLINE' : 'OFFLINE'}
            </p>
          </Box> */}
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="span"
                variant="subtitle1"
                style={{ color: '#707070', marginRight: 10 }}>
                {`${props.item.action_participations_length} participações`}
              </Typography>
              <SupervisorAccountIcon color="primary" fontSize="small" />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="span"
                variant="subtitle1"
                style={{ color: '#707070', marginRight: 10 }}>
                {`${props.item.actions_length} atividades`}
              </Typography>
              <MovieIcon color="primary" fontSize="small" />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="span"
                variant="subtitle1"
                style={{ color: '#707070', marginRight: 10 }}>
                {`${props.item.levels_length} níveis`}
              </Typography>
              <LayersIcon color="primary" fontSize="small" />
            </Box>
          </Box>

          {/* <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" justifyContent="flex-end">
              <Typography
                style={{ color: '#707070' }}
                component="span"
                variant="subtitle1"
              >
                início:{'  '}
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="primary"
                >
                  {props.data.start !== 'Invalid date' && props.reactivate
                    ? `${props.data.start.slice(
                        0,
                        10
                      )} às ${props.data.start.slice(11, 16)}`
                    : props.data.start && props.data.start !== 'Invalid date'
                    ? `${moment(props.data.start).format(
                        'DD/MM/YYYY'
                      )} às ${moment(props.data.start).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography
                style={{ color: '#707070' }}
                component="span"
                variant="subtitle1"
              >
                fim:{'  '}
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="primary"
                >
                  {props.data.end !== 'Invalid date' && props.reactivate
                    ? `${props.data.end.slice(0, 10)} às ${props.data.end.slice(
                        11,
                        16
                      )}`
                    : props.data.end && props.data.end !== 'Invalid date'
                    ? `${moment(props.data.end).format(
                        'DD/MM/YYYY'
                      )} às ${moment(props.data.end).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
          </Box> */}
        </Box>
      </GeneralGrid>
    </GeneralGrid>
  );
}

CardJourneyInfo.propTypes = {
  description: PropTypes.any,
  item: PropTypes.shape({
    action_participations_length: PropTypes.any,
    actions_length: PropTypes.any,
    levels_length: PropTypes.any,
  }),
  name: PropTypes.any,
  onClickDelete: PropTypes.any,
  onClickEdit: PropTypes.any,
};
