import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';
import logo from 'assets/img/fan_logo.svg';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { appColors } from 'styles/colors';
import { SelectLabel } from 'common/components/fields/SelectLabel';
import CustomizedPaper from 'common/components/surfaces/CustomizedPaper';

let Terms = (props) => {
  return (
    <>
      <Container maxWidth="xl" style={{ paddingBottom: '4rem' }}>
        {/* <div className="row">
                    <div className="col-12">
                        <NavBarSite />
                    </div>
                </div> */}
        <Box mt={4}>
          <GeneralGrid container spacing={8} justify="center" style={{ background: 'white' }}>
            <GeneralGrid item xs={12}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Link to="/owner">
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 8px',
                      justifyContent: 'center',
                    }}>
                    <img src={logo} alt="Rilato Logo" style={{ width: '100%', height: 'auto' }} />
                  </div>
                </Link>
              </Box>

              <Box display="flex" justifyContent="center" mt={5}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 32,
                  }}>
                  Termos
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <form onSubmit={props.handleSubmit((v) => console.log(v))} noValidate>
                  <Field
                    component={SelectLabel}
                    cols="12 12 12 12"
                    options={[
                      { label: 'Termos de Uso', value: 1 },
                      { label: 'Política de Privacidade', value: 2 },
                      { label: 'Cookies', value: 3 },
                    ]}
                    name="select"
                    label="Selecione"
                  />
                </form>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3} justify="center">
            <GeneralGrid item xs={12}>
              <Box mt={5}>
                {props.selectedValue === '1' ? (
                  <CustomizedPaper title="Termos de Uso" body="Texto dos Termos de Uso" />
                ) : props.selectedValue === '2' ? (
                  <CustomizedPaper
                    title="Política de Privacidade"
                    body="Texto da Política de Privacidade"
                  />
                ) : props.selectedValue === '3' ? (
                  <CustomizedPaper
                    title="Preferência de Cookies"
                    body="Preferências de Cookies do Usuário"
                  />
                ) : (
                  <Typography align="center" variant="body1">
                    Selecione uma das opções acima
                  </Typography>
                )}
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography variant="subtitle1" style={{ color: appColors.dark.level3 }}>
              Voltar ao{' '}
              <a href="#/login" style={{ color: '#2D95EF', textDecoration: 'underline' }}>
                login
              </a>
            </Typography>
          </Box>
        </Box>
        <CustomizedSnackbar />
      </Container>
    </>
  );
};

Terms.propTypes = {
  handleSubmit: PropTypes.func,
  selectedValue: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    general: state.general,
    formValues: getFormValues('termsForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const selector = formValueSelector('termsForm');

Terms = connect((state) => {
  const selectedValue = selector(state, 'select');
  return { selectedValue };
})(Terms);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'termsForm' })(Terms));
