import PropTypes from 'prop-types';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import OnboardingCard from 'common/components/cards/onboardingCard/onboardingCard';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask } from 'helpers/masks';
import { String2Currency } from 'helpers/dataTransformers';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import getEstablishmentPoints from '../../pointsActions';
import buyPoints from './buyPointsActions';

class BuyPoints extends Component {
  constructor(props) {
    super(props);
    if (this.props.location.query.first_action) {
      this.props.getEstablishmentPoints(this.props.auth.user.establishments[0].id);
    }

    this.state = {
      valueToPay: null,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.points.points > 0) {
      if (props.location.query.first_action) {
        this.props.router.push('/owner/acoes/?first_action=true');
      }
    }
  }

  onSubmit = (values) => {
    const { id, establishments } = this.props.auth.user;
    const { router } = this.props;
    let first_Action = false;
    if (this.props.location.query.first_action) {
      first_Action = true;
    }
    values.establishment_id = establishments[0].id;
    this.props.buyPoints(values, id, router, first_Action);
  };

  render() {
    const { handleSubmit } = this.props;
    if (this.props.points.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    }
    return (
      <section className="modules">
        <div className="container-fluid">
          {this.props.location.query.first_action ? (
            <p>
              Para fazer sua primeira atividade, você deve solicitar créditos, é bem fácil. Preencha
              a quantidade de créditos abaixo e continue
            </p>
          ) : null}
          <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center">
              <MaterialGrid item>
                <OnboardingCard
                  condition={false}
                  title="Transferência bancária"
                  height={600}
                  width={290}
                  text={
                    <div style={{ textAlign: 'left' }}>
                      <span>Banco: 756 - BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB</span>
                      <br />
                      <span>CNPJ: 35.622.254/0001-26</span>
                      <br />
                      <span>Ag: 4259</span>
                      <br />
                      <span>CC: 3250-6</span>
                      <br />
                      <span>Nome: RILATO S/A</span>
                    </div>
                  }
                  router={this.props.router}
                  hideButton
                  buttonLabel="Contato"
                  onClickButton={() =>
                    this.props.router.push('/owner/website/create/?first_station=true')
                  }
                />
              </MaterialGrid>
              <MaterialGrid item>
                <OnboardingCard
                  condition={false}
                  marginText={false}
                  title="Começar"
                  height={600}
                  width={290}
                  text={
                    'Digite abaixo o valor que irá transferir. Efetue a transferência bancária utilizando os dados ao lado. Quando verificarmos a transferência, liberaremos o crédito para uso em suas atividades.'
                  }
                  alternativeButton={
                    <PrimaryButton
                      type="submit"
                      disabled={this.props.points.buttonLoading}
                      progress={parseInt(this.props.general.submitProgress)}>
                      {this.props.points.buttonLoading ? 'Processando pedido' : 'Fazer pedido'}
                    </PrimaryButton>
                  }
                  aditionalContent={
                    <Field
                      component={InputLabelOutlined}
                      type="tel"
                      label={'Quantidade em BRL'}
                      name="points"
                      required
                      onChange={() => console.log('ok')}
                      validate={[FORM_RULES.required]}
                      margin="normal"
                      {...currencyMask}
                    />
                  }
                  aditionalContent2={
                    <>
                      <span>Você tera que depositar o valor de:</span>
                      <strong>
                        {this.props.formValues
                          ? String2Currency(this.props.formValues.points / (1 - 0.08))
                          : 'R$ 0,00'}
                      </strong>
                    </>
                  }
                  router={this.props.router}
                  buttonLabel="Cadastrar Agora"
                />
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3}></MaterialGrid>
          </Form>
        </div>
      </section>
    );
  }
}

BuyPoints.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
      id: PropTypes.any,
    }),
  }),
  buyPoints: PropTypes.func,
  formValues: PropTypes.shape({
    points: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getEstablishmentPoints: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_action: PropTypes.any,
    }),
  }),
  points: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
    points: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    points: state.points,
    general: state.general,
    formValues: getFormValues('buyPoints')(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ buyPoints, getEstablishmentPoints }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'buyPoints' })(BuyPoints));
