import PropTypes from 'prop-types';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useMemo, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import {
  Container,
  FinalPrice,
  InfoContainer,
  ItemImage,
  Prices,
  Quantity,
  SeeMore,
  Title,
} from './styles';

const ItemCard = function ({ data, colors }) {
  const [items, setItems] = useState(1);

  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const availableStock = useMemo(() => {
    return data.stock;
  }, [data.stock]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  const finalPrice = useMemo(() => {
    return ((data.value - data.discount_value) * items).toFixed(2).replace('.', ',');
  }, [data.value, data.discount_value, items]);

  const onAdd = useCallback(() => {
    if (items < data.limit && items < availableStock) {
      setItems((item) => item + 1);
    }
  }, [availableStock, data.limit, items]);

  const onSubtract = useCallback(() => {
    if (items > 0) {
      setItems((item) => item - 1);
    }
  }, [items]);
  if (!colors) {
    return null;
  }
  return (
    <>
      <Container>
        <ItemImage image={data.image} />
        <InfoContainer>
          <Title>{data.name}</Title>
          <SeeMore colors={colors}>Ver mais</SeeMore>
          <section>
            <Prices colors={colors}>
              <span>{discountPrice}</span>
              {data.discount_value > 0 && <span>{price}</span>}
            </Prices>
            <Quantity colors={colors}>
              <FiMinus onClick={() => onSubtract()} size={14} />
              <p>{items}</p>
              <FiPlus onClick={() => onAdd()} size={14} />
            </Quantity>
            <FinalPrice colors={colors}>
              R$ <span>{finalPrice}</span>
            </FinalPrice>
          </section>
        </InfoContainer>
      </Container>
    </>
  );
};

ItemCard.propTypes = {
  colors: PropTypes.any,
  data: PropTypes.shape({
    discount_value: PropTypes.number,
    image: PropTypes.any,
    limit: PropTypes.any,
    name: PropTypes.any,
    stock: PropTypes.any,
    value: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
  };
};

export default connect(mapStateToProps)(ItemCard);
