import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Container, FinalPrice, ItemImage, Prices, Quantity, Title, InfoContainer } from './styles';

const ItemCard = function ({ data }) {
  return (
    <Container>
      <ItemImage image={data.image} />
      <InfoContainer>
        <Title>{data.name}</Title>
        <FiMoreHorizontal />
        <section>
          <Prices>
            <span>{parseFloat(data.value).toFixed(2).replace('.', ',')}</span>
          </Prices>
          <Quantity>
            <p>1</p>
          </Quantity>
          <FinalPrice>
            R${' '}
            <span>
              {(parseFloat(data.value) - parseFloat(data.discount_value))
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </FinalPrice>
        </section>
      </InfoContainer>
    </Container>
  );
};

ItemCard.propTypes = {
  data: PropTypes.shape({
    discount_value: PropTypes.any,
    image: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('shoppingForm')(state),
  };
};

export default connect(mapStateToProps)(ItemCard);
