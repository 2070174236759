import _ from 'lodash';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

export class CaptchaInput extends React.Component {
  onChange = (value) => {
    if (!_.isNull(value)) {
      this.props.input.onChange(value);
    } else {
      this.props.input.onChange(undefined);
    }
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`form-group ${touched && error && 'has-error'}`}>
        <ReCAPTCHA
          sitekey="6LfLGHwkAAAAAJr7DSFDGvnOe2aE3rqz_e1v19RU"
          onChange={this.onChange}
          hl="pt-BR"
        />
        {touched && error && (
          <span style={{ fontSize: 13, color: 'red' }} className="help-block">
            {error}
          </span>
        )}
      </div>
    );
  }
}

CaptchaInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
};
