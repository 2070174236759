import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { BASE_S3 } from 'config/consts';
import moment from 'moment';
import QRCode from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: isMobile ? '100vh' : '95vh',
  },
  card: {
    padding: '16px 24px',
    color: theme.palette.text.secondary,
  },
  card_head: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    margin: theme.spacing(3),
  },
  text: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: '1.5rem',
    color: theme.palette.text.secondary,
  },
}));

const VoucherModal = (props) => {
  const [print, setPrint] = useState(false);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  const classes = useStyles();

  const { open = false, setOpen, item, onClose } = props;

  console.log(item);
  function url(item, code) {
    if (item.action.station_id && item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
    if (item.action.station_id && !item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://app.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-gestor.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
  }
  return (
    <Dialog
      open={open}
      handleClose={() => (onClose ? onClose() : setOpen(false))}
      PaperProps={{ classes: { root: classes.paper } }}
      fullScreen={print}
      maxWidth={'md'}>
      <Box className={classes.card} ref={ref}>
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => (onClose ? onClose() : setOpen(false))}>
          <CloseIcon />
        </IconButton>
        <Grid container alignItems="center" justifyContent="center" direction="column" spacing={4}>
          <Grid container className={classes.card_head}>
            <Grid item>
              <img
                className={classes.logo}
                src={`${BASE_S3}/${
                  JSON.parse(item.presential_course.action.station.jsonData).objectNewItemsBody
                    .navbar_station_secondary_logo
                }`}
                alt="LOGO-EMPRESA"
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" textAlign="center" className={classes.title}>
                {item.presential_course.action.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="8" align="center">
            <Typography
              className={classes.text}
              variant="body1"
              textAlign="center">{`Voucher gerado em: ${moment(item.createdAt).format(
              'LLLL'
            )}`}</Typography>
          </Grid>
          <Grid item>{/* <QRCode value={url(item.presential_course, item.code)} /> */}</Grid>
          <Grid item>
            <Typography className={classes.text} variant="body1" textAlign="center">
              {item.code}
            </Typography>
          </Grid>
          {item.presential_course.rules ? (
            <Grid item>
              <Typography variant="body1" textAlign="center">
                {item.presential_course.rules}
              </Typography>
            </Grid>
          ) : null}

          <Grid item align="center">
            <Typography className={classes.text} textAlign="center">
              Este voucher deverá ser utilizado até:
            </Typography>
            <Typography className={classes.text} textAlign="center">
              {moment(item.presential_course.date).format('LLLL')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <Button onClick={handlePrint}>{isMobile ? 'Salvar' : 'Imprimir'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoucherModal;
