import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CopyPasteIcon from '@material-ui/icons/FilterNone';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { limitStringSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { deleteCouponById, getCouponsStationId } from './couponCodesActions';

const MAX_LENGTH = 30;

function Coupon(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [confirmBox, setConfirmBox] = useState({ visible: false });
  const columns = [
    {
      title: 'Título do cupom',
      field: 'name',
      render: (rowData) =>
        rowData.title ? limitStringSize(rowData.title, MAX_LENGTH) : 'Sem título',
    },
    {
      title: 'Desconto',
      field: 'percentage',
      render: (rowData) => (rowData.percentage ? `${rowData.percentage}%` : '0%'),
    },
    {
      title: 'Código do cupom',
      field: 'coupon_codes',
      render: (rowData) =>
        _.chain(rowData.coupon_codes)
          .map((item, i) => (
            <Typography display="inline" key={i} style={{ marginRight: 16 }}>
              {item.code}
            </Typography>
          ))
          .value(),
    },
    {
      title: '',
      sorting: false,
      field: 'copy',
      render: (rowData) => (
        <Box
          onClick={() =>
            navigator.clipboard.writeText(
              _.chain(rowData.coupon_codes)
                .map((item) => item.code)
                .join('\n')
                .value()
            ) && toastr.success('Copiado!', 'Cupom copiado com sucesso!')
          }>
          <CopyPasteIcon style={{ color: '#5759FB', cursor: 'pointer' }} />
        </Box>
      ),
    },
    {
      title: '',
      sorting: false,
      field: 'edit',
      render: (rowData) => (
        <Box onClick={() => props.router.push(`/owner/coupons/editar/${rowData.id}`)}>
          <EditIcon style={{ color: '#043565;', cursor: 'pointer' }} />
        </Box>
      ),
    },
  ];

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Cupom na plataforma</h2>
                  <p className="">
                    Cada cupon pode ser atrelado a uma ou várias atividades com intuito de
                    incentivar a sua participação na mesma.
                  </p>
                  <PrimaryButton
                    onClick={() => props.router.push('/owner/coupons/criar')}
                    color="primary">
                    Novo cupom
                  </PrimaryButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </Grid>
        </Grid>
      </section>
    );
  }

  useEffect(() => {
    props.getCouponsStationId(props.auth.user.establishments[0].stations[0].id);
  }, []);

  if (props.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (props.couponCodes.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ width: isMobile ? '89vw' : undefined }}>
          <Table
            hasToolbar
            toolbarSearch
            searchPlaceholder={'Pesquisar'}
            title="Cupons"
            columns={columns}
            rows={props.couponCodes.map((it) => ({ ...it, name: it.title }))}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </Grid>
      </Grid>
      <AlertDialogSlide
        maxWidth="sm"
        title="Tem certeza?"
        onClose={() => setConfirmBox((value) => ({ ...value, visible: false }))}
        visible={confirmBox.visible}>
        <div>
          <PrimaryButton
            noMargin
            type="button"
            onClick={() =>
              props.deleteCouponById(
                confirmBox.couponcode_id,
                props.auth.user.establishments[0].stations[0].id,
                setConfirmBox
              )
            }
            disabled={props.couponCodes.buttonLoading}>
            Sim, quero deletar
          </PrimaryButton>

          <SecondaryButton onClick={() => setConfirmBox((value) => ({ ...value, visible: false }))}>
            Cancelar
          </SecondaryButton>
        </div>
      </AlertDialogSlide>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <Fab
          variant="extended"
          style={{ background: '#fff' }}
          onClick={() => props.router.push('/owner/coupons/criar')}>
          <AddIcon className={classes.extendedIcon} />
          Criar cupom
        </Fab>
      </Box>
    </>
  );
}

Coupon.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deleteCouponById: PropTypes.func,
  getCouponsStationId: PropTypes.func,
  couponCodes: PropTypes.shape({
    buttonLoading: PropTypes.any,
    length: PropTypes.number,
  }),
  loading: PropTypes.any,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    couponCodes: state.couponCodes.list,
    loading: state.couponCodes.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCouponsStationId,
      deleteCouponById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
