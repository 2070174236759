import PropTypes from 'prop-types';
import React from 'react';
import { BASE_S3 } from 'config/consts';

export default function AboutCard(props) {
  const { image, title, text, colors } = props;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <img
        src={`${BASE_S3}/${image}`}
        className="card-img-top"
        alt="Imagem do card"
        style={{ maxWidth: '100%', height: 'auto' }}
      />

      <div className="card-body" style={{}}>
        <p
          className="station-font-semibold text-center py-3 m-0"
          style={{
            fontSize: '1rem',
            color: colors.title,
          }}>
          {title || 'Tipo indefinido'}
        </p>
        <p
          className="station-font-medium text-center pb-3 m-0"
          style={{
            color: colors.text,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
          }}>
          {text}
        </p>
      </div>
    </div>
  );
}

AboutCard.propTypes = {
  colors: PropTypes.shape({
    text: PropTypes.any,
    title: PropTypes.any,
  }),
  image: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.string,
};
