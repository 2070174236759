import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export const ContainerFields = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
`;
