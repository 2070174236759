import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Title extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <p
            className="text-left"
            style={{
              fontSize: 20,
              fontWeight: 300,
              color: 'rgb(119, 119, 119)',
              lineHeight: 1.588,
              fontFamily: 'Roboto',
            }}>
            {this.props.title}
          </p>
        </div>
      </div>
    );
  }
}

Title.propTypes = {
  title: PropTypes.any,
};

export default Title;
