import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NewSelectMultiple from 'common/components/fields/NewSelectMultiple';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { FORM_RULES } from 'helpers/validations';
import { Field, reduxForm, getFormValues, Form } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { InputFile } from 'common/components/fields/InputFile';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import {
  createGrouping,
  getGroupingById,
  editGrouping,
  getGroupingsParticipantsStationId,
} from './groupingsActions';
import { getStationActiveActions } from '../activeActions/activeActionsScripts';
import _ from 'lodash';

function GroupingsForm(props) {
  const { grouping_id } = props.router.params;
  const { id } = props.auth.user.establishments[0].stations[0];
  const opcoesActions = props.activeActions.list.map((it) => ({
    id: it.id,
    title: it.name,
  }));

  useEffect(() => {
    if (_.isEmpty(props.activeActions.list)) {
      props.getStationActiveActions(id);
    }
    if (_.isEmpty(props.participants)) {
      props.getGroupingsParticipantsStationId(id);
    }
  }, []);

  useEffect(() => {
    if (grouping_id) {
      props.getGroupingById(grouping_id);
    }
  }, [grouping_id]);

  function onSubmit(values) {
    if (!_.isUndefined(values.logo_image?.base64)) {
      values.logo_image = {
        base64: imgToBase64(values.logo_image),
        imageType: imgToImgType(values.logo_image),
      };
    }
    const actions = values.actions || [];
    if (grouping_id) {
      props.editGrouping({ ...values, actions }, grouping_id, props.router);
    } else {
      props.createGrouping({ ...values, actions }, id, props.router);
    }
  }

  return (
    <Form
      role="form"
      onSubmit={props.handleSubmit(onSubmit)}
      noValidate
      style={{
        margin: isMobile ? '0px' : '50px auto',
      }}>
      <div style={{ marginLeft: isMobile ? 0 : 20, width: '80vw' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {grouping_id ? 'Editar agrupamento' : 'Novo agrupamento'}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ borderRight: '1px solid #C4C4C4' }}>
            <Field
              component={InputFile}
              label={'Logo'}
              cols="12 12 12 12"
              name="logo_image"
              /*validate={[FORM_RULES.required]}*/
              maxWidthBool={true}
            />
          </Grid>
          <Grid item xs={4} style={{ borderRight: '1px solid #C4C4C4' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome do agrupamento"
                  name="name"
                  validate={[FORM_RULES.required]}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  type="textarea"
                  multiline
                  rowsMax={6}
                  label={'Descrição'}
                  name="description"
                  rows={4}
                  placeholder="Breve descrição sobre o agrupamento"
                  cols="12 12 12 6"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: -16 }}>
                <Field
                  component={NewSelectMultiple}
                  label={'Selecione os usuários'}
                  options={props.participants}
                  name="participants"
                  multiple
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: -16 }}>
                <Field
                  component={NewSelectMultiple}
                  label={'Selecione as atividade'}
                  options={opcoesActions}
                  name="actions"
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: 32 }}>
        <SecondaryButton onClick={() => props.router.push('/owner/groupings')} color="primary">
          Cancelar
        </SecondaryButton>
        <PrimaryButton type="submit" color="primary" disabled={props.groupings.buttonLoading}>
          {grouping_id ? 'Editar agrupamento' : 'Cadastrar agrupamento'}
        </PrimaryButton>
      </Grid>
    </Form>
  );
}

GroupingsForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  createGrouping: PropTypes.func,
  editGrouping: PropTypes.func,
  getGroupingById: PropTypes.func,
  groupings: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  handleSubmit: PropTypes.func,
  getStationActiveActions: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      grouping_id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    groupings: state.groupings,
    activeActions: state.activeActions,
    participants: state.groupings.participantsList,
    formValues: getFormValues('groupingForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createGrouping,
      getGroupingById,
      editGrouping,
      getStationActiveActions,
      getGroupingsParticipantsStationId,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'groupingsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(GroupingsForm)
);
