import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Avatar } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { BASE_S3, USER_TOKEN } from 'config/consts';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import DownArrow from '../svg/downArrow';
import UpArrow from '../svg/upArrow';
import MenuButton from './menuButton';
import './persistentDrawer.css';
import CloseButton from './closeButton';
import OwnerIcon from './ownerIcon';
import ExploreIcon from './explore';

const URL_FAN =
  process.env.REACT_APP_API_KEY === 'production'
    ? `https://rilato.com.br`
    : process.env.REACT_APP_API_KEY === 'production-temp'
    ? `https://rilato.com.br`
    : process.env.REACT_APP_API_KEY === 'test'
    ? `http://fan.fantest.site`
    : 'http://localhost:3000';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: '100%',
  },
  content: {
    height: '100vh',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  bottomNavigation: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    letterSpacing: '0.14',
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const route = window.location.hash.split('#')[1];

  function handleFirstStateActive() {
    for (const i in props.navItems) {
      if (props.navItems[i].route === route) {
        return { id: props.navItems[i].id, children: false };
      } else if (props.navItems[i].children) {
        for (const item in props.navItems[i].children) {
          if (props.navItems[i].children[item].route === route) {
            return { id: props.navItems[i].id, children: true };
          }
        }
      } else {
        return { id: 0, children: false };
      }
    }
  }
  const [openCollapse, setOpenCollapse] = React.useState({
    id: 0,
    open: false,
  });

  const handleCollapse = (item) => {
    if (item.children) {
      if (item.id !== openCollapse.id && openCollapse.open) {
        setOpenCollapse({ id: item.id, open: true });
      } else {
        setOpenCollapse({ id: item.id, open: !openCollapse.open });
      }
    } else {
      props.router.push(item.link);
      handleDrawerToggle();
    }
  };

  const handleChildrenClick = (children) => {
    setActive({ id: children.id, children: true });
    props.router.push(children.link);
    handleDrawerToggle();
  };

  // Escolha de empresa da plataforma
  function handleEstablishmentChange(establishmentId) {
    const newEstablishmentsList = [...props.user.establishments];
    for (let index = 0; index < props.user.establishments.length; index++) {
      if (props.user.establishments[index].id === establishmentId) {
        [newEstablishmentsList[0], newEstablishmentsList[index]] = [
          newEstablishmentsList[index],
          newEstablishmentsList[0],
        ];
      }
    }
    return props.changeEstablishment(newEstablishmentsList);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [active, setActive] = React.useState(handleFirstStateActive());

  const handleActive = (index, item) => {
    setActive({ id: item.id, children: false });
  };

  const drawer = (
    <div>
      <Toolbar style={{ height: '70px' }}>
        <div className="container-fluid" style={{ background: 'inherit' }}>
          <div className="row justify-content-between align-items-center">
            <div className="col-8" style={{ paddingLeft: 0 }}>
              <div className="d-flex align-items-center">
                <img
                  src={`${BASE_S3}/${props.user.fanData.objectNewItemsBody.navbar_station_secondary_logo}`}
                  style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                  className="img-fluid"
                  alt="station_img"
                  onClick={() => props.router.push('/owner')}
                />
              </div>
            </div>
            <div
              onClick={handleDrawerToggle}
              className="col-4  d-flex align-items-center justify-content-end">
              <CloseButton></CloseButton>
            </div>
          </div>
        </div>
      </Toolbar>
      <div
        className="d-flex justify-content-start w-100 align-items-center pt-4 pb-4"
        onClick={() => {
          props.router.push('/owner/profile');
          handleDrawerToggle();
        }}>
        <div className="pl-3">
          <Avatar
            alt="Avatar Perfil"
            src={
              props.user.image
                ? `${BASE_S3}/${props.user.image}`
                : 'https://www.searchpng.com/wp-content/uploads/2019/02/Deafult-Profile-Pitcher.png'
            }
            style={{ cursor: 'pointer', marginLeft: 6 }}
            aria-controls="customized-menu"
            aria-haspopup="true"
          />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start p-2">
          <span
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '160%',
              textAlign: 'center',
              letterSpacing: '0.25',
              color: '#282828',
            }}>
            {props.user.name}
          </span>
          <span
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              textAlign: 'center',
              letterSpacing: '0.140385px',
              color: '#5759FB',
            }}>
            {props.title}
          </span>
        </div>
      </div>
      <List style={{ padding: 0 }}>
        {props.navItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              className={classes.listRoot}
              onClick={() => handleCollapse(item)}
              style={{
                textDecoration: 'none',
                backgroundColor:
                  active.id === item.id && !active.children && !item.children
                    ? '#f1f2f4'
                    : '#ffffff',
                cursor: 'pointer',
              }}>
              <div
                className="container-fluid"
                style={{
                  backgroundColor:
                    active.id === item.id && !active.children && !item.children
                      ? '#f1f2f4'
                      : '#ffffff',
                }}>
                <div
                  key={index}
                  onClick={() => handleActive(`${item.id}`, item)}
                  className={clsx(
                    classes.listItemRoot,
                    active.id === item.id && !active.children && !item.children
                      ? classes.navItemActive
                      : undefined,
                    'row py-3 no-gutters'
                  )}>
                  <div
                    className="col-auto pr-3"
                    style={{
                      fill:
                        active.id === item.id && !active.children && !item.children
                          ? '#5759FB'
                          : '#282828',
                      marginTop: '-3px',
                    }}>
                    {item.iconType === 'materialIcon' ? (
                      <div
                        className=""
                        style={{
                          color: 'black',
                        }}>
                        {item.icon}
                      </div>
                    ) : (
                      <div className="">{item.icon}</div>
                    )}
                  </div>
                  <div className="col ">
                    <p
                      className={clsx(
                        active.id === item.id && !active.children && !item.children
                          ? classes.navItemActive
                          : undefined,
                        'station-font-medium mb-0'
                      )}
                      style={{
                        color: 'black',
                        fontSize: '0.875rem',
                      }}>
                      {item.name}
                    </p>
                  </div>
                  <div className="col-auto ">
                    {item.children ? (
                      openCollapse.id === item.id && openCollapse.open ? (
                        <UpArrow color="#666" />
                      ) : (
                        <DownArrow color="#666" />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </ListItem>

            {/* Collapse children render */}
            {item.children ? (
              <Collapse
                in={openCollapse.open && openCollapse.id === item.id}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((childrenItem, childrenIndex) => {
                    return (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => handleChildrenClick(childrenItem)}
                        style={{
                          backgroundColor:
                            active.id === childrenItem.id && active.children
                              ? '#f1f2f4'
                              : '#ffffff',
                        }}
                        key={childrenIndex}>
                        <div
                          className="col-auto pr-3"
                          style={{
                            fill:
                              active.id === childrenItem.id && active.children
                                ? '#5759FB'
                                : '#282828',
                            marginTop: '-3px',
                          }}>
                          <div className="align-self-center">{childrenItem.icon}</div>
                        </div>
                        <ListItemText
                          secondary={childrenItem.name}
                          secondaryTypographyProps={{
                            style: { fontSize: '0.875rem' },
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            ) : null}
            <Divider light={true} style={{ color: '#F7F7F7;' }} />
          </React.Fragment>
        ))}
      </List>
      <div className="d-flex justify-content-center w-100 py-4">
        <span
          onClick={() => props.router.push('/logout')}
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            letterSpacing: '0.25',
            color: '#5759FB',
          }}>
          Sair da conta
        </span>
      </div>
    </div>
  );

  const currentRoute = props.routes[props.routes.length - 2]?.path;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={2}
        position="fixed"
        className={classes.appBar}
        style={{
          backgroundColor: 'white',
        }}>
        <Toolbar style={{ height: '70px' }}>
          <div className="container-fluid" style={{ background: 'inherit' }}>
            <div className="row justify-content-between align-items-center">
              <div className="col-4" style={{ padding: 0 }}>
                <div className="d-flex justify-content-start align-items-center">
                  <img
                    src={`${BASE_S3}/${props.user.fanData.objectNewItemsBody.navbar_station_secondary_logo}`}
                    style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                    className="img-fluid"
                    alt="station_img"
                    onClick={() => props.router.push('/owner')}
                  />
                </div>
              </div>
              <div
                className="col-6 d-flex align-items-center justify-content-start"
                style={{ padding: 0 }}>
                <button
                  className={`btn btn-secondary dropdown-toggle`}
                  type="button"
                  data-toggle="dropdown"
                  id={'dropdownMenuStation'}
                  aria-haspopup="true"
                  style={{
                    background: 'transparent',
                    color: '#000',
                    border: 'none',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    marginRight: 20,
                  }}>
                  {!_.isEmpty(props.user.establishments)
                    ? props.user.establishments[0]?.stations[0]?.name
                    : 'Não há empresa'}
                </button>
                <div className="dropdown-menu" aria-labelledby={'dropdownMenuStation'}>
                  {props.user.establishments.map((item, index) => (
                    <button
                      key={index}
                      className="dropdown-item"
                      type="button"
                      style={{
                        marginBottom: 10,
                      }}
                      onClick={() => handleEstablishmentChange(item.id)}>
                      {item.stations[0]?.name}
                    </button>
                  ))}
                  <button
                    className="dropdown-item"
                    type="button"
                    style={{
                      marginBottom: 10,
                    }}
                    onClick={() => props.router.push('/owner/create-new-station')}>
                    + Adicionar nova estação
                  </button>
                </div>
              </div>
              <div
                className="col-2 d-flex align-items-center justify-content-end"
                style={{ padding: 0 }}>
                <IconButton
                  color={'inherit'}
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}>
                  <MenuButton style={{ color: 'black' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: 0,
            left: 0,
            background: 'white',
            height: 64,
            width: '100%',
            borderTop: '1px solid #DFDFDF',
            zIndex: 3,
          }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onClick={() => {
              window.open(
                `${URL_FAN}/#/login-token/${
                  JSON.parse(localStorage.getItem(USER_TOKEN)).split(' ')[1]
                }`
              );
            }}
            className="w-25 d-flex flex-column  justify-content-center align-content-center align-items-center">
            <ExploreIcon fill="#282828" />

            <span className={classes.bottomNavigation}>Descobrir</span>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onClick={() => props.router.push('/owner')}
            className="w-25 d-flex flex-column  justify-content-center align-content-center align-items-center">
            <OwnerIcon fill={currentRoute !== 'profile' ? '#5759FB' : '#282828'} />
            <span
              style={{
                color: currentRoute !== 'profile' ? '#5759FB' : '#282828',
              }}
              className={classes.bottomNavigation}>
              Gerenciar
            </span>
          </div>
        </div>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  changeEstablishment: PropTypes.func,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  container: PropTypes.any,
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  router: PropTypes.objectOf(PropTypes.func).isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.any,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResponsiveDrawer;
