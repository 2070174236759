import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IOSSwitch } from './IOSSwitch';

export function InputSwitchLarge(props) {
  const [state, setState] = useState(false);

  const { input, label, disabled } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(false);
      input.onChange(false);
    } else {
      setState(input.value);
    }
  }, [input]);

  return (
    <Box
      style={{ ...props.boxStyle, marginTop: 10 }}
      display="flex"
      justifyContent="space-between"
      alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography style={{ fontSize: 16, fontWeight: '400' }} variant="subtitle2">
          {label}
        </Typography>
        <Typography variant="caption" style={{ color: `${state ? '#2D95EF' : '#52C22C'}` }}>
          {state ? props.firstState : props.secondState}
        </Typography>
      </Box>

      <IOSSwitch disabled={disabled} color checked={state} onChange={handleChange} value="switch" />
    </Box>
  );
}

InputSwitchLarge.propTypes = {
  boxStyle: PropTypes.any,
  disabled: PropTypes.any,
  firstState: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.any,
  secondState: PropTypes.any,
};
