import { Box, Switch, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function InputSwitch(props) {
  const [state, setState] = useState(props.default || false);

  const { input, label, disabled } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(props.default || false);
      input.onChange(props.default || false);
    } else {
      setState(input.value);
    }
  }, [input, props.default]);

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Switch
        disabled={disabled}
        color="primary"
        required
        checked={state}
        onChange={handleChange}
        value="switch"
      />
      <Typography>{label}</Typography>
    </Box>
  );
}

InputSwitch.propTypes = {
  default: PropTypes.bool,
  disabled: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.any,
};
