import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress, Dialog, Slide } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { BASE_S3 } from 'config/consts';
import { uploadImageFileToS3 } from 'modules/owner/pages/actions/actionsFile';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Dropzone from 'react-dropzone';
import { ColorPicker } from '../Questions/styles';
import { IconBackground, Option, TextColorPicker, TextImage } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Questions({ input: { onBlur, onChange, value } }) {
  const [image, setImage] = useState(undefined);
  const [background, setBackground] = useState('#2D95EF');
  const [textColor, setTextColor] = useState('#fff');
  const [open, setOpen] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [counter, setCounter] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value.text || null);

  function handleDateChange(e) {
    setSelectedDate(e.target.value);
  }

  useEffect(() => {
    setSelectedDate(value.text);
  }, [value.text]);

  function onClose() {
    setOpen(false);
    setOpenText(false);
  }

  function handleOpen() {
    setOpen(true);
    setCounter(counter + 1);
  }

  function handleOpenText() {
    setOpenText(true);
    setCounter(counter + 1);
  }

  function handleChangeComplete(color) {
    setBackground(color.hex);
  }

  function handleChangeCompleteText(color) {
    setTextColor(color.hex);
  }
  const dropzoneProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 1200000,
  };

  useEffect(() => {
    if (value.base64) {
      setImage(value.base64);
      setTextColor(value.textColor);
    } else if (value.color) {
      setBackground(value.color);
      setTextColor(value.textColor);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      setImage(undefined);
      onChange({
        ...value,
        image: undefined,
        color: background,
        base64: undefined,
      });
    }
  }, [background]);

  useEffect(() => {
    if (counter > 0) {
      onChange({
        ...value,
        textColor,
      });
    }
  }, [textColor]);

  async function onDrop(imageFiles) {
    setImageLoading(true);
    const data = await uploadImageFileToS3(imageFiles[0]);
    setImageLoading(false);
    onBlur({ image: data });
    onChange({
      ...value,
      color: undefined,
      image_id: data.id,
      image: data,
      base64: `${BASE_S3}/${data.path}/${data.saved_name}`,
    });
    setImage(`${BASE_S3}/${data.path}/${data.saved_name}`);
  }

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={imageLoading}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Fazendo upload da imagem...</span>
      </Backdrop>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <Box>
          <Option background={background} textColor={textColor} image={image}>
            {!image ? (
              <textarea
                spellCheck="false"
                rows="2"
                cols="50"
                maxLength="70"
                name="text"
                value={selectedDate}
                onChange={(e) => handleDateChange(e)}
                onBlur={(e) => onChange({ ...value, text: e.target.value })}
              />
            ) : null}

            <TextColorPicker onClick={handleOpenText} />
            <ColorPicker onClick={handleOpen} />

            <Dropzone {...dropzoneProps}>
              {({ getRootProps, getInputProps }) => (
                <IconBackground {...getRootProps()}>
                  <label htmlFor="image">
                    <ImageIcon style={{ color: 'white' }} />
                    <input {...getInputProps()} />
                  </label>
                </IconBackground>
              )}
            </Dropzone>
          </Option>
        </Box>
        <Box>
          {image ? (
            <TextImage
              textColor={textColor === '#FFF' ? '#000' : textColor}
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              onBlur={(e) => onChange({ ...value, text: e.target.value })}
            />
          ) : null}
        </Box>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <SketchPicker color={background} onChangeComplete={handleChangeComplete} />
      </Dialog>
      <Dialog
        open={openText}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <SketchPicker color={textColor} onChangeComplete={handleChangeCompleteText} />
      </Dialog>
    </>
  );
}

Questions.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
  }),
};
