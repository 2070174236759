import PropTypes from 'prop-types';
import React from 'react';
import FullButton from 'common/components/buttons/fullButton';
import { CaptchaInput } from 'common/components/fields/CaptchaInput';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FORM_RULES } from 'helpers/validations';
import { Field, getFormValues, reduxForm } from 'redux-form';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { submitSupport } from './plansActions';

function EnterprisePlanSupport(props) {
  const enterprise_station_id =
    process.env.REACT_APP_API_KEY === 'production' ||
    process.env.REACT_APP_API_KEY === 'production-temp'
      ? props.auth.user.station_id || 13
      : 1;

  function onSubmit(values) {
    values.station_id = enterprise_station_id;
    values.subject = 'Consulta por plano enterprise';
    values.user_id = props.auth.user.id;
    values.name = props.auth.user?.name;
    values.phone = props.auth.user?.tel;
    values.email = props.auth.user.email;
    props.submitSupport(values, props.router);
  }

  return (
    <GeneralGrid container spacing={3} style={{ justifyContent: 'center' }}>
      <GeneralGrid item xs={6}>
        <div className="card">
          <div className="row justify-content-center mt-3">
            <div className="col-auto mb-3 mb-md-3">
              <h1
                style={{
                  fontSize: '2.125rem',
                  textAlign: '',
                  //   color: body.text,
                }}>
                {'Plano Enterprise'}
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto mb-3 mb-md-5">
              <h5
                style={{
                  fontSize: '1.0rem',
                  textAlign: 'center',
                  color: '#666',
                }}>
                {'Responderemos o mais rápido possível.'}
              </h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <form
              onSubmit={props.handleSubmit((v) => onSubmit(v))}
              noValidate
              style={{ width: 450 }}>
              <div>
                <div className="row justify-content-center my-1">
                  <div className="col-12">
                    <Field
                      component={InputLabelOutlined}
                      label="Conte-nos um pouco sobre sua empresa e necessidade"
                      inputlabelprops={{
                        shrink: true,
                      }}
                      multiline
                      rows={5}
                      name="message"
                      validate={[FORM_RULES.required]}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: 15,
                      alignItems: 'center',
                    }}>
                    <Field
                      component={CaptchaInput}
                      inputlabelprops={{
                        shrink: true,
                      }}
                      name="captcha"
                      validate={[FORM_RULES.required]}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mb-4">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <FullButton
                      background={'#5759FB'}
                      text={'white'}
                      fontSize="0.875rem"
                      classNameProp="station-font-medium py-3 px-4"
                      type="submit">
                      Enviar
                    </FullButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </GeneralGrid>
    </GeneralGrid>
  );
}

EnterprisePlanSupport.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.any,
      id: PropTypes.any,
      name: PropTypes.any,
      station_id: PropTypes.number,
      tel: PropTypes.any,
    }),
  }),
  handleSubmit: PropTypes.func,
  router: PropTypes.any,
  submitSupport: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('supportForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ submitSupport }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'supportForm' })(EnterprisePlanSupport));
