import React from 'react';
import { Route, IndexRoute } from 'react-router';
import researchRoutes from 'modules/owner/pages/actions/research/researchRoutes';
import Actions from './actions';
import ActionsOptions from './actionsOptions';
import ActionDetails from './actionDetails';
import activeActionsRoutes from './activeActions/activeActionsRoutes';
import presentialCourseRoutes from './presentialCourse/presentialCoursesRoutes';
import signupEarnRoutes from './signupEarn/signupEarnRoutes';
import memberGetMemberRoutes from './memberGetMember/memberGetMemberRoutes';
import researchCheckListRoutes from './researchCheckList/researchCheckListRoutes';
import researchRightAnswerRoutes from './researchRightAnswer/researchRightAnswerRoutes';
import researchPersonalityQuizRoutes from './researchPersonalityQuiz/researchPersonalityQuizRoutes';
import actionOffersRoutes from './actionOffers/actionOffersRoutes';
import couponsRoutes from './coupons/couponsRoutes';
import shareContentRoutes from './shareContent/shareContentRoutes';
import actionsDrafts from './actionsDrafts/actionsDraftsRoutes';
import ActionDetail from './actionDetail';
import researchPollQuizRoutes from './researchPollQuiz/researchPollQuizRoutes';
import redirectLinkRoutes from './redirectLink/redirectLinkRoutes';
import redirectLinkInternalRoutes from './redirectLinkInternal/redirectLinkInternalRoutes';
import productDeliveryRoutes from './productDelivery/productDeliveryRoutes';
import agreementRoutes from './agreement/agreementRoutes';
import rawContentRoutes from './rawContent/rawContentRoutes';
import donationActionRoutes from './donationAction/donationActionRoutes';
import actionsMenuRoutes from './actionsMenu/actionsMenuRoutes';
import generalActionRoutes from './actionsGeneral/actionsGeneralRoutes';
import videoPlaylistRoutes from './actionVideoPlaylist/videoPlaylistRoutes';
import memberGetPermissionRoutes from './memberGetPermission/memberGetPermissionRoutes';

export default (
  <Route path="acoes">
    <IndexRoute component={(props) => <Actions {...props} />} />
    {actionsMenuRoutes}
    {generalActionRoutes}
    {activeActionsRoutes}
    {agreementRoutes}
    {actionsDrafts}
    {presentialCourseRoutes}
    {researchCheckListRoutes}
    {researchRightAnswerRoutes}
    {researchPollQuizRoutes}
    {researchPersonalityQuizRoutes}
    {signupEarnRoutes}
    {memberGetMemberRoutes}
    {actionOffersRoutes}
    {couponsRoutes}
    {shareContentRoutes}
    {productDeliveryRoutes}
    {redirectLinkRoutes}
    {redirectLinkInternalRoutes}
    {rawContentRoutes}
    {videoPlaylistRoutes}
    {donationActionRoutes}
    {memberGetPermissionRoutes}
    <Route path=":id">
      <IndexRoute component={(props) => <ActionDetail {...props} />} />
    </Route>
    <Route path="acao/:id">
      <IndexRoute component={(props) => <ActionDetails {...props} />} />
    </Route>
    <Route path="aquisicao-clientes">
      <IndexRoute component={(props) => <ActionsOptions {...props} />} />
      {researchRoutes}
    </Route>
  </Route>
);
