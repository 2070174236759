import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, reduxForm } from 'redux-form';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { isMobile } from 'react-device-detect';

class ContactsSurveysTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      openConfirmation: false,
    };
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  onSubmit = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props.onClickProgress(values);
  };

  onSubmitSec = (values) => {
    values.categories = values.categories_field.map((item) => item.id);
    values.groupings = values.groupings_field.map((item) => item.id);
    values.submitFromButton = true;
    this.props
      .onClickProgress(values)
      .then(() => {
        this.props.closeModal();
      })
      .catch(() => {});
  };

  render() {
    return (
      <>
        <Form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          <div
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 355px)',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 15,
            }}>
            <MaterialGrid container direction="row" justify="center">
              <Typography variant="subtitle1" align="center">
                Nesta atividade, a interação do usuário é convidar/ dar permissão para novos
                usuários na sua plataforma.
              </Typography>
            </MaterialGrid>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              cancelClose={this.props.handleCancelClose}
              maxWidthFixed
              labelLeftButton={'Continuar aqui'}
              labelRightButton={this.props.buttonConfirmationLabel}
              general={this.props.general}
              confirmButtonColor={'primary'}
              title={this.props.confirmationTitle}
              text={this.props.confirmationSubtitle}
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={() => this.props.router.push('/owner/acoes/minhas-atividades')}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: isMobile ? 0 : 29,
            }}>
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              disabled={this.props.activeActions.buttonLoading}
              color="primary"
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: isMobile ? '12px 20px' : '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}>
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

ContactsSurveysTest.propTypes = {
  activeActions: PropTypes.shape({
    buttonLoading: PropTypes.any,
  }),
  buttonConfirmationLabel: PropTypes.any,
  closeModal: PropTypes.func,
  confirmationSubtitle: PropTypes.any,
  confirmationTitle: PropTypes.any,
  general: PropTypes.any,
  handleCancelClose: PropTypes.any,
  handleSubmit: PropTypes.func,
  onClickProgress: PropTypes.func,
  openConfirmation: PropTypes.any,
  prevClick: PropTypes.any,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  setOpenConfirmation: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'mgpForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ContactsSurveysTest)
);
