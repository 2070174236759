import PropTypes from 'prop-types';
import { Box, IconButton, useTheme } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { limitStringSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { finishSupport, getList, remove } from './supportActions';

export function Support(props) {
  const defaultFilters = [
    {
      label: 'Filtrar entre as datas',
      field: 'betweenDates',
      component: 'datePicker',
      typeData: 'date',
      filterFunction: 3,
      startDate: new Date(),
      endDate: new Date(),
    },
  ];

  const { router } = props;
  const theme = useTheme();
  const { list, loading } = props.support;
  const filters = defaultFilters;

  const MAX_LENGTH = 35;

  const [columns] = useState([
    { field: 'name', title: 'Nome' },
    {
      field: 'subject',
      title: 'Assunto',
      render: (rowData) => (rowData.subject ? limitStringSize(rowData.subject, MAX_LENGTH) : ''),
    },
    { field: 'email', title: 'E-mail' },
    {
      title: 'Finalizado',
      render: (rowData) => (rowData.solved ? 'Sim' : 'Não'),
    },
    {
      title: 'Ver Detalhes',
      render: (rowData) => {
        return (
          <div>
            <IconButton
              style={{ color: theme.palette.success.main }}
              aria-label="Aceitar"
              onClick={() => console.log(rowData.id)}>
              <FontIcon
                onClick={() => props.router.push(`/owner/suporte/${rowData.id}/detalhes`)}
                style={{ cursor: 'pointer' }}>
                search
              </FontIcon>
            </IconButton>
          </div>
        );
      },
    },
    {
      title: 'Finalizar',
      render: (rowData) => {
        if (rowData.solved) {
          return <span>Finalizado</span>;
        }
        return (
          <div>
            <IconButton
              style={{ color: theme.palette.success.main }}
              aria-label="Aceitar"
              onClick={() =>
                props.finishSupport(rowData.id, props.auth.user.establishments[0].stations[0].id)
              }>
              <Check />
            </IconButton>
          </div>
        );
      },
    },
    { field: 'createdAt', title: 'Data de criação' },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(props.support.list.content);
  }, [props.support.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não possui chamados de participantes</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="support">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            filters={filters}
            hasToolbar
            toolbarSearch
            title="Chamados"
            columns={columns}
            rows={data}
            router={router}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

Support.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  finishSupport: PropTypes.func,
  getList: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  support: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    support: state.support,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
      finishSupport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
