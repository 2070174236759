import React from 'react';
import { Route } from 'react-router';
import DownloadsRoutes from './downloads/downloadsRoutes';
import NewsRoutes from './news/newsRoutes';
import SchedulesRoutes from './schedules/schedulesRoutes';
import BenefitsRoutes from './benefits/benefitsRoutes';
import AboutRoutes from './about/aboutRoutes';
import WebsiteSectionsRouter from './websiteSections/websiteSectionsRouter';

export default (
  <Route path="content">
    {NewsRoutes}
    {DownloadsRoutes}
    {SchedulesRoutes}
    {BenefitsRoutes}
    {AboutRoutes}
    {WebsiteSectionsRouter}
  </Route>
);
