import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { getActionDetail } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShareTest from './shareTest';
import ShareToParticipants from './shareToParticipants';
import ShareHistory from './shareHistory';
import SharePushNotification from './sharePushNotification';

const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const useStyles = {
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.any,
  value: PropTypes.any,
};

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.props.getActionDetail(this.props.location.query.action);
  }

  onDialogClose = () => {
    this.props.router.push('/owner/acoes/minhas-atividades');
  };

  handleNativeChange = (e, newValue) => {
    this.setState({ value: newValue });
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Divulgação"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}>
        <section id="statement-buy-points" style={{ height: '100vh' }}>
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}></GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img alt="" src={placeholder_illustration} className="img-fluid" />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }

  renderBackground = () => {
    return '#3f51b5';
  };

  render() {
    const { actionDetail, loadingActionDetail } = this.props.activeActions;
    if (loadingActionDetail) {
      return (
        <AlertDialogSlide
          title="Divulgação"
          visible={true}
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'lg'}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    return (
      <AlertDialogSlide
        title="Divulgação"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'lg'}>
        <AppBar
          style={{
            background: '#fff',
            boxShadow: 'none',
            border: 'none',
            marginBottom: 20,
          }}
          position="static">
          <AntTabs value={this.state.value} onChange={this.handleNativeChange}>
            <Tab style={{ color: '#000000' }} label="E-mail de teste" />
            <Tab style={{ color: '#000000' }} label="Envio para participantes" />
            <Tab style={{ color: '#000000' }} label="Resultados" />

            {this.props.auth.user.establishments[0].stations[0].has_app ? (
              <Tab style={{ color: '#000000' }} label="Notificação Push" />
            ) : null}
          </AntTabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <ShareTest action={actionDetail} />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <ShareToParticipants action={actionDetail} />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <ShareHistory action={actionDetail} />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {this.props.auth.user.establishments[0].stations[0].has_app ? (
            <SharePushNotification action={actionDetail} />
          ) : null}
        </TabPanel>
      </AlertDialogSlide>
    );
  }
}

Share.propTypes = {
  activeActions: PropTypes.shape({
    actionDetail: PropTypes.any,
    loadingActionDetail: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getActionDetail: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDetail,
    },
    dispatch
  );
};

const StyledShare = withStyles(useStyles)(Share);
export default connect(mapStateToProps, mapDispatchToProps)(StyledShare);
