import { Icon, Switch, Tooltip, Typography, Grid as MuiGrid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function InputSwitchGrey(props) {
  const [state, setState] = useState(false);

  const { input, label, disabled, tooltipMessage } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(false);
      input.onChange(false);
    } else {
      setState(input.value);
    }
  }, [input]);

  return (
    <>
      <MuiGrid
        container
        style={{
          ...props.boxStyle,
        }}>
        <MuiGrid
          item
          container
          alignItems="center"
          justify="space-between"
          direction="row"
          wrap="nowrap"
          xs={12}>
          <Typography variant="subtitle2">{label}</Typography>
          <Switch
            disabled={disabled}
            color="primary"
            required
            checked={state}
            onChange={handleChange}
            value="switch"
          />
        </MuiGrid>
        <MuiGrid item container alignItems="center" xs={12}>
          <Typography
            variant="caption"
            // style={{ color: `${state ? '#2D95EF' : '#52C22C'}` }}
            style={{ color: '#838383' }}>
            {state ? props.firstState : props.secondState}
          </Typography>
          {tooltipMessage ? (
            <Tooltip style={{ marginLeft: 10 }} title={tooltipMessage} placement="top-start">
              <Icon color="default">help</Icon>
            </Tooltip>
          ) : null}
        </MuiGrid>
      </MuiGrid>
    </>
  );
}

InputSwitchGrey.propTypes = {
  boxStyle: PropTypes.any,
  disabled: PropTypes.any,
  firstState: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.any,
  secondState: PropTypes.any,
  tooltipMessage: PropTypes.any,
};
