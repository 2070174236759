import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';

const URL_QUIZZES = `${BASE_API}/quizzes/establishment`;

export const getQuizzes = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'QUIZZES_LOAD',
        payload: true,
      },
    ]);

    axios
      .get(`${URL_QUIZZES}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'QUIZZES_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const remove = (id, router = undefined) => {
  return (dispatch) => {
    axios
      .delete(`${BASE_API}/quiz/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        toastr.success('Quiz deletado com sucesso');
        if (!_.isUndefined(router)) {
          router.push('/owner/manage-quiz/manage');
        }
        dispatch([getQuizzes()]);
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: { message: 'Erro', variant: 'error', open: true },
        });
      });
  };
};

export const initializeForm = (values) => {
  return initialize('quizForm', values);
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${BASE_API}/quiz/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data[0],
          questions: JSON.parse(response.data[0].questions),
        };
        dispatch([initializeForm(initialValues)]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
      questions: JSON.parse(values.questions),
    };
    dispatch([initializeForm(initialValues)]);
  };
};
