import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../common/components/table/table-material';
import { dispatchSnackbar, getListByUserId } from './VoucherTableActions';
import LoadingProgress from 'common/components/progress/loading';
import VoucherModal from '../../VoucherModal';

const useStyles = makeStyles((theme) => ({
  search: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: '4px',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
}));

function VoucherTable({
  auth,
  activeActions: {
    actionResults: { action },
  },
  snackbar,
}) {
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState('idle');
  const [open, setOpen] = useState(false);
  const [voucher, setVoucher] = useState(undefined);
  const [openPrint, setOpenPrint] = useState(false);
  const [generatingUser, setGeneratingUser] = useState('');

  const classes = useStyles();

  const fetchData = async () => {
    try {
      setLoading('pending');
      const response = await axios.get(`${BASE_API}/users`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        params: {
          name,
          station_id: auth.user.establishments[0].stations[0].id,
          page,
          limit,
        },
      });
      const data = await response.data.content;
      data.forEach((i) => {
        if (i.cpf) i.cpf = i.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      });
      setData(data);
      setCount(response.data.count);
      setLoading('resolved');
    } catch (error) {
      setLoading('rejected');
    }
  };

  const onClick = () => {
    if (name) {
      fetchData();
    } else {
      setData([]);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const voucherGenerator = async (print = false) => {
    try {
      await axios
        .post(
          `${BASE_API}/subscribe-to-presential-course`,
          {
            should_contact: true,
            user_id: generatingUser.id,
            presential_course: action.presential_courses[0].id,
          },
          {
            headers: {
              Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
            },
          }
        )
        .then((response) => {
          snackbar('success', 'Voucher gerado com sucesso.');
          console.log(response);
          if (print) {
            setVoucher(response.data);
            setOpenPrint(true);
          }
        });
    } catch (error) {
      snackbar('error', `ERROR: ${error.message}`);
    }
    setOpen(false);
  };

  const handleDialogOpen = (rowData) => {
    setGeneratingUser(rowData);
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const UserTable = () => (
    <Paper>
      <Table
        hasToolBar={false}
        defaultSelected=""
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'CPF',
            field: 'cpf',
          },
          {
            title: '',
            field: '',
            render: (rowData) => (
              <Button variant="contained" color="default" onClick={() => handleDialogOpen(rowData)}>
                Gerar voucher
              </Button>
            ),
          },
        ]}
        rows={data}
        setAPIPage={setPage}
        setAPILimit={setLimit}
        countFromAPI={count}
        loading={loading}
      />
    </Paper>
  );

  return (
    <section>
      <Grid container spacing={1} alignItems="center" maxWidth={isMobile ? 'xl' : 'md'}>
        <Grid item>
          <Typography variant="h6">Geração de voucher</Typography>
        </Grid>
        <Grid container alignItems="center" className={classes.search}>
          <Grid item>
            <TextField
              id="outlined-basic"
              label="Pesquisar por Nome"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={onClick} color="primary">
              Pesquisar
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.table}>
          {UserTable(data)}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {`Gerar voucher para ${generatingUser.name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => voucherGenerator(true)} color="primary">
            Gerar impressão
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Não
          </Button>
          <Button onClick={() => voucherGenerator()} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      {voucher ? <VoucherModal open={openPrint} setOpen={setOpenPrint} item={voucher} /> : null}
    </section>
  );
}

VoucherTable.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  activeActions: PropTypes.shape({
    actionResults: PropTypes.any,
  }),
  dispatchSnackbar: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      snackbar: dispatchSnackbar,
      getListByUserId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTable);
