import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import types from './types';

const URL = `${BASE_API}/products-by-owner/station`;
const URL_DETAIL = `${BASE_API}/product-details`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.PRODUCT_BY_OWNER_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        response.data.forEach((item) => {
          item.validated = parseInt(item.validated) === 0 ? 'Não' : 'Sim';
        });
        dispatch([
          {
            type: types.PRODUCT_BY_OWNER_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: types.PRODUCT_BY_OWNER_DETAIL_LOAD, payload: true });
    axios
      .get(`${URL_DETAIL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.PRODUCT_BY_OWNER_DETAIL_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};
