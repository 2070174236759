import React from 'react';
import { hashHistory, IndexRoute, Route, Router } from 'react-router';
import actionOfferMessagesRoutes from '../modules/owner/pages/actions/actionOfferMessages/actionOfferMessagesRoutes';
import actionsMenu from '../modules/owner/pages/actions/actionsMenu/actionsMenu';
import ActionsRoutes from '../modules/owner/pages/actions/actionsRoutes';
import entitiesRouter from '../modules/owner/pages/actions/entities/entitiesRouter';
import groupingsRoutes from '../modules/owner/pages/actions/groupings/groupingsRoutes';
import giveawayRoutes from '../modules/owner/pages/actions/giveaways/giveawaysRoutes';
import couponCodesRoutes from '../modules/owner/pages/actions/couponCodes/couponCodesRoutes';
import automationsRoutes from '../modules/owner/pages/automations/automationsRoutes';
import categoriesRoutes from '../modules/owner/pages/categories/categoriesRoutes';
import ContactsRoutes from '../modules/owner/pages/contacts/contactsRoutes';
import ContentRoutes from '../modules/owner/pages/content/contentRoutes';
import SchedulesRoutes from '../modules/owner/pages/content/schedules/schedulesRoutes';
import subdomainRoutes from '../modules/owner/pages/content/subdomain/subdomainRoutes';
import createNewStationRoutes from '../modules/owner/pages/createNewStation/createNewStationRoutes';
import DeliveryOrdersRoutes from '../modules/owner/pages/deliveryOrders/deliveryOrdersRoutes';
import donationsRoutes from '../modules/owner/pages/donations/donationsRoutes';
import IdentityRouter from '../modules/owner/pages/identity/identityRouter';
import JourneysRoutes from '../modules/owner/pages/journeys/journeysRoutes';
import MailRoutes from '../modules/owner/pages/mail/mailRoutes';
import moderatorRoutes from '../modules/owner/pages/moderator/moderatorRoutes';
import ownersRoutes from '../modules/owner/pages/owners/ownersRoutes';
import OwnerUsersRoutes from '../modules/owner/pages/ownerUsers/ownerUsersRoutes';
import plansRoutes from '../modules/owner/pages/plans/plansRoutes';
import PointsRoutes from '../modules/owner/pages/points/pointsRoutes';
import PrincipalRoutes from '../modules/owner/pages/principal/principalRoutes';
import ProfileRoutes from '../modules/owner/pages/profile/profileRoutes';
import QuizRoutes from '../modules/owner/pages/quiz/quizRoutes';
import salesRoutes from '../modules/owner/pages/sales/salesRoutes';
import SignupCompanyRoutes from '../modules/owner/pages/signupCompany/signupCompanyRoutes';
import subscriptionsRoutes from '../modules/owner/pages/subscriptions/subscriptionsRoutes';
import supportRoutes from '../modules/owner/pages/support/supportRoutes';
import validationsRoutes from '../modules/owner/pages/validations/validationsRoutes';
import validationsCupomRoutes from '../modules/owner/pages/validationsCupom/validationsCupomRoutes';
import WebsiteRoutes from '../modules/owner/pages/website/websiteRoutes';
import SurveysForm from '../modules/owner/surveys/surveysForm';
import SurveysFormStd from '../modules/owner/surveys/surveysFormStd';
import ForgotPassword from '../pages/auth/forgotPassword/forgotPassword';
import NewPassword from '../pages/auth/forgotPassword/newPassword';
import Login from '../pages/auth/login/login';
import loginToken from '../pages/auth/loginToken/loginToken';
import Logout from '../pages/auth/logout/logout';
import EmailConfirmation from '../pages/auth/signup/emailConfirmation';
import Signup from '../pages/auth/signup/signup';
import SignupStepForm from '../pages/auth/signup/signupStepForm';
import NotAutorized from '../pages/error';
import Home from '../pages/home/home';
import Terms from '../pages/terms/terms';
import authOrApp from './authOrApp';
import qrCodeValidator from '../pages/auth/qrCodeValidator/qrCodeValidator';
import qrcodeReader from 'pages/auth/qrcodeReader/qrcodeReader';
import validatorsRouter from '../modules/owner/pages/actions/validators/validatorsRouter';
import productsReportRoutes from '../modules/owner/pages/productsReport/productsReportRoutes';
import participantsRoutes from '../modules/owner/pages/participants/participantsRoutes';

const Routes = () => {
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)} history={hashHistory}>
      <Route path="/login" component={Login} />
      <Route path="/qr-code-validator/:type/:code" component={qrCodeValidator} />
      <Route path="/qr-code-validator/:type" component={qrCodeValidator} />
      <Route path="/leitor-qrcode/:code_validator" component={qrcodeReader} />
      <Route path="/login-token/:token" component={loginToken} />
      <Route path="/cadastrar" component={SignupStepForm} />
      <Route path="/cadastrar/:code_mgm" component={SignupStepForm} />
      <Route path="/confirmacao" component={EmailConfirmation} />
      <Route path="/esqueci-minha-senha" component={ForgotPassword} />
      <Route path="/redefinir-senha/:userHash/:userEmail" component={NewPassword} />
      <Route path="/ativar-lead/:activationString" component={Signup} />
      <Route path="/not-autorized" component={NotAutorized} />
      <Route path="/logout" component={Logout} />
      <Route path="/termos" component={Terms} />
      <Route path="/research">
        <IndexRoute component={(props) => <SurveysForm {...props} />} />
        <Route
          path=":establishmentId/:contactId/:surveyId"
          component={(props) => <SurveysForm {...props} />}
        />
      </Route>
      <Route path="/research-test">
        <IndexRoute component={(props) => <SurveysForm {...props} />} />
        <Route path=":surveyId" component={(props) => <SurveysFormStd {...props} />} />
      </Route>
      <Route path="/" component={authOrApp}>
        <IndexRoute component={Home} />
        <Route path="/owner">
          <IndexRoute component={actionsMenu} />
          {entitiesRouter}
          {groupingsRoutes}
          {QuizRoutes}
          {OwnerUsersRoutes}
          {PointsRoutes}
          {MailRoutes}
          {JourneysRoutes}
          {ContactsRoutes}
          {IdentityRouter}
          {WebsiteRoutes}
          {ActionsRoutes}
          {giveawayRoutes}
          {couponCodesRoutes}
          {ContentRoutes}
          {PrincipalRoutes}
          {ProfileRoutes}
          {subscriptionsRoutes}
          {SignupCompanyRoutes}
          {supportRoutes}
          {SchedulesRoutes}
          {DeliveryOrdersRoutes}
          {donationsRoutes}
          {validationsRoutes}
          {salesRoutes}
          {validationsCupomRoutes}
          {automationsRoutes}
          {moderatorRoutes}
          {actionOfferMessagesRoutes}
          {ownersRoutes}
          {createNewStationRoutes}
          {productsReportRoutes}
          {categoriesRoutes}
          {plansRoutes}
          {subdomainRoutes}
          {validatorsRouter}
          {participantsRoutes}
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
