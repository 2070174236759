import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  return (
    <div>
      <Dialog
        {...props}
        open={props.visible}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            minHeight: props.height || null,
            minWidth: props.width || null,
          },
        }}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={props.maxWidth || 'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}

AlertDialogSlide.propTypes = {
  children: PropTypes.any,
  height: PropTypes.any,
  maxWidth: PropTypes.string,
  onClose: PropTypes.any,
  visible: PropTypes.any,
  width: PropTypes.any,
};

export default AlertDialogSlide;
