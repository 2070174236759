import axios from 'axios';
import { BASE_API } from 'config/consts';
import { change } from 'redux-form';
import types from './types';

const URL_WEBSITE = `${BASE_API}/generateWebsite`;

export const changeField = (form, field, value) => {
  return change(form, field, value);
};

export const signupCompanyAndStation = (values, router, auth) => {
  return (dispatch) => {
    dispatch({
      type: types.BUTTON_LOAD,
      payload: true,
    });
    axios
      .post(
        `${URL_WEBSITE}`,
        {
          ...values,
        },
        {
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        }
      )
      .then((responseStation) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Sucesso! Aproveite.',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message:
                'Operação realizada com sucesso, escolha sua nova estação na navegação lateral',
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'WEBSITE_CREATED',
            payload: responseStation.data,
          },
        ]);
        if (router) {
          router.push('/owner');
        }
      })
      .catch((e) => {
        dispatch([
          {
            type: types.BUTTON_LOAD,
            payload: false,
          },
          { type: 'SUBMIT_PROGRESS', payload: 0 },
        ]);
        try {
          if (e.response.data) {
            dispatch([
              {
                type: 'SNACKBAR',
                payload: {
                  message: `Erro: ${e.response.data.messages[0]}`,
                  variant: 'error',
                  open: true,
                },
              },
            ]);
          }
        } catch (error) {
          dispatch([
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Erro',
                variant: 'error',
                open: true,
              },
            },
          ]);
        }
      });
  };
};
