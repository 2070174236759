import types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  winners: [],
  loading: true,
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LIST_FETCHED:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case types.ITEM_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      };
    case types.WINNER_FETCHED:
      return {
        ...state,
        winners: action.payload.winners,
        loading: false,
      };
    case types.LIST_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case types.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
