import React from 'react';
import { connect } from 'react-redux';
import ParticipantsTable from './components/ParticipantsTable';
import EmptyScreen from './components/EmptyScreen';
import statuses from './status';

const Participants = (props) => {
  const { status } = props.participants;

  if (status === statuses.REJECTED) return <EmptyScreen />;
  return <ParticipantsTable {...props} />;
};

const mapStateToProps = (state) => {
  return {
    participants: state.participants,
  };
};

export default connect(mapStateToProps)(Participants);
