import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import types from './types';

const URL_QUIZ = `${BASE_API}/quiz`;

export const postQuiz = (values, router = undefined, method) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.QUIZ_LOAD,
        payload: true,
      },
    ]);

    const postValues = {
      questions: JSON.stringify(values.questions),
      establishment: values.establishment,
      expiration: values.expiration,
      limit: values.limit,
      points: values.points,
      name: values.name,
      description: values.description,
    };

    axios
      .post(`${URL_QUIZ}`, postValues, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        if (!_.isUndefined(router)) {
          if (!_.isNull(method)) {
            router.push(`/owner/${method}`);
          } else {
            router.push('/owner/manage-quiz');
          }
        }
      });
  };
};
export const putQuiz = (values, router = undefined) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.QUIZ_LOAD,
        payload: true,
      },
    ]);

    const postValues = {
      questions: JSON.stringify(values.questions),
      establishment: values.establishment,
      expiration: values.expiration,
      limit: values.limit,
      points: values.points,
      name: values.name,
      description: values.description,
    };

    axios
      .put(`${URL_QUIZ}/${router.params.id}`, postValues, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        // console.log(response)
        if (!_.isUndefined(router)) {
          router.push('/owner/manage-quiz/manage');
        }
      });
  };
};
