import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import types from './types';

const URL_GIVEAWAYS_STATION = `${BASE_API}/giveaways-station`;
const URL_GIVEAWAY = `${BASE_API}/giveaway`;
const URL_DRAW_GIVEAWAY = `${BASE_API}/draw-giveaway`;
const URL_GIVEAWAYS = `${BASE_API}/giveaways`;

export const getGiveawaysStationId = (station_id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_GIVEAWAYS_STATION}/${station_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const responseFormatted = response.data.map((item) => ({
          ...item,
          participation_start: moment(item.participation_start).format('DD/MM/YYYY hh:mm'),
          participation_end: moment(item.participation_end).format('DD/MM/YYYY hh:mm'),
        }));
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: responseFormatted,
          },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

const initializeForm = (values) => {
  return initialize('giveawayForm', values);
};

export const getGiveawayById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL_GIVEAWAY}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const responseFormatted = {
          ...response.data,
          participation_start: moment(response.data.participation_start).format('DD/MM/YYYY hh:mm'),
          participation_end: moment(response.data.participation_end).format('DD/MM/YYYY hh:mm'),
          counting_date: moment(response.data.counting_date).format('DD/MM/YYYY HH:mm'),
        };
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: responseFormatted,
          },
          initializeForm(response.data),
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.LIST_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const deleteGiveawayById = (giveaway_id, station_id, setVisible) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .delete(`${URL_GIVEAWAY}/${giveaway_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([{ type: types.BUTTON_LOAD, payload: false }, getGiveawaysStationId(station_id)]);
        setVisible({ visible: false });
      })
      .catch((err) => {
        toastr.error('Erro', 'Ainda existem atividades atreladas a este sorteio');
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const createGiveaway = (values, station_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(`${URL_GIVEAWAYS_STATION}/${station_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/giveaway');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const editGiveaway = (values, giveaway_id, router) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .put(`${URL_GIVEAWAY}/${giveaway_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        router.push('/owner/giveaway');
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const initializeDrawForm = () => {
  return (dispatch) => {
    dispatch(initialize('giveawayDrawForm', { tickets: [''] }));
  };
};

export const getGiveawayWinners = (values, giveaway_id) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(`${URL_DRAW_GIVEAWAY}/${giveaway_id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        console.log('response ', response.data);
        dispatch([
          { type: types.WINNER_FETCHED, payload: response.data },
          { type: types.BUTTON_LOAD, payload: false },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const drawWinners = (values, giveaway_id) => {
  return (dispatch) => {
    dispatch({ type: types.BUTTON_LOAD, payload: true });
    axios
      .post(`${URL_GIVEAWAYS}/${giveaway_id}/draw`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: types.WINNER_FETCHED, payload: response.data },
          { type: types.BUTTON_LOAD, payload: false },
        ]);
      })
      .catch((err) => {
        dispatch({ type: types.BUTTON_LOAD, payload: false });
        console.log(err);
      });
  };
};

export const importParticipants = (data, router = undefined) => {
  return (dispatch) => {
    dispatch({
      type: types.BUTTON_LOAD,
      payload: true,
    });
    axios
      .post(`${URL_GIVEAWAYS}/${data.giveaway_id}/import`, data, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {})
      .catch((e) => {})
      .finally(() => {
        dispatch({
          type: types.BUTTON_LOAD,
          payload: false,
        });
      });
  };
};
