import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';
import { Box, Typography } from '@material-ui/core';

export default class LottieControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    if (!this.props.animation) {
      return null;
    }
    const defaultOptions = {
      loop: this.props.loop,
      autoplay: true,
      animationData: this.props.animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={this.props.height || 400}
          width={this.props.width || 400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        {this.props.hasReplayButton && this.props.animation ? (
          <Box justifyContent="center" display="flex">
            <Typography
              onClick={() =>
                this.setState({ isStopped: true }, (prevProps) =>
                  this.setState({ isStopped: false })
                )
              }
              style={{ cursor: 'pointer', marginBottom: 5 }}
              color="primary">
              {' '}
              Repetir animação
            </Typography>
          </Box>
        ) : null}
      </div>
    );
  }
}

LottieControl.propTypes = {
  animation: PropTypes.any,
  hasReplayButton: PropTypes.any,
  height: PropTypes.number,
  loop: PropTypes.any,
  width: PropTypes.number,
};
