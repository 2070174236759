import { getListTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  loading: false,
  subscriptionRewardLoading: false,
  isSubscriptionRewardModalOpen: false,
  isSubscriptionCancelModalOpen: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SUBSCRIPTIONS_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.SUBSCRIPTIONS_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SUBSCRIPTIONS_REWARD_LOAD:
      return {
        ...state,
        subscriptionRewardLoading: action.payload,
      };
    case types.SUBSCRIPTIONS_REWARD_MODAL:
      return {
        ...state,
        isSubscriptionRewardModalOpen: action.payload,
      };
    case types.SUBSCRIPTIONS_CANCEL_MODAL:
      return {
        ...state,
        isSubscriptionCancelModalOpen: action.payload,
      };
    default:
      return state;
  }
};
