import { FormControlLabel, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import { appColors } from 'styles/colors';
import PropTypes from 'prop-types';

export function MaterialCheckbox({ input, label, disabled, meta: { touched, error } }) {
  // É necessário configurar valor para falso no uso caso usuário não mexa no checkbox
  // Exemplo de uso: <Field component={MaterialCheckbox} label={"Quer esta opção?"} name="checkbox" />
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(input.value);
  }, [input.value]);

  return (
    <div>
      <FormControlLabel
        control={<Checkbox color="primary" {...input} disabled={disabled} />}
        label={label}
        checked={state}
      />
      {touched && error ? (
        <Typography
          variant="caption"
          style={{
            color: appColors.error,
            display: 'block',
            marginTop: '-18px',
            marginLeft: 48,
          }}>
          {error}
        </Typography>
      ) : null}
    </div>
  );
}

MaterialCheckbox.propTypes = {
  disabled: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  type: PropTypes.any,
};
