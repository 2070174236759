import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Table from 'common/components/table/table-material';
import { ActionNameElement, ColumnsBelowSearch } from 'common/components/table/tableComponents';
import { appColors } from 'styles/colors';
import { getList } from '../participantsActions';
import { debounce, throttle } from 'lodash';

const ParticipantsTable = (props) => {
  const {
    status,
    list: { content: data },
    count,
  } = props.participants;

  const [isMounted, setIsMounted] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  const [shownPerPage, setShownPerPage] = useState(5);
  const [nameSearched, setNameSearched] = useState('');

  const fetchData = () => {
    props.getList({
      stationId: props.auth.user.establishments[0].stations[0].id,
      tablePage,
      shownPerPage,
      nameSearched,
    });
  };

  const fetchBySearch = useCallback(
    debounce((name) => {
      props.getList({
        stationId: props.auth.user.establishments[0].stations[0].id,
        tablePage,
        shownPerPage,
        nameSearched: name,
      });
    }, 1000),
    []
  );

  // const fetchData = () => {
  //   props.getList({
  //     stationId: props.auth.user.establishments[0].stations[0].id,
  //     tablePage,
  //     shownPerPage,
  //     nameSearched,
  //   });
  // };

  useEffect(() => {
    if (!isMounted) {
      fetchData();
      setIsMounted(true);
    }
  }, [isMounted]);

  useEffect(() => {
    fetchData();
  }, [tablePage, shownPerPage]);

  useEffect(() => {
    fetchBySearch(nameSearched);
  }, [nameSearched]);

  const defaultColumns = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => {
        return <ActionNameElement actionName={rowData.name ?? ''} />;
      },
    },
    {
      field: 'cpf',
      title: 'CPF',
      render: (rowData) => <ActionNameElement actionName={rowData.cpf ?? ''} />,
    },
    {
      field: 'tel',
      title: 'Telefone',
      render: (rowData) => <ActionNameElement actionName={rowData.tel ?? ''} />,
    },
    {
      field: 'birthday',
      title: 'Data de nascimento',
      render: (rowData) => (
        <ActionNameElement
          actionName={rowData.birthday ? moment(rowData.birthday).format('DD/MM/YYYY') : ''}
        />
      ),
    },
    {
      field: 'email',
      title: 'E-mail',
      render: (rowData) => <ActionNameElement actionName={rowData.email ?? ''} />,
    },
    {
      field: 'hash',
      title: 'Código',
      render: (rowData) => <ActionNameElement actionName={rowData.hash ?? ''} />,
    },
    {
      field: 'address',
      title: 'Estado',
      render: (rowData) => <ActionNameElement actionName={rowData.addresses?.[0]?.state || ''} />,
    },
    {
      field: 'actions',
      headerCenter: true,
      title: 'Editar',
      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'edit',
                  onClick: () => {
                    props.router.push(`/owner/participantes/${rowData.id}`);
                  },
                  color: '#5759FB',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const mobileColumns = [
    {
      field: 'name',
      title: 'Nome',
      render: (rowData) => {
        return <ActionNameElement actionName={rowData.name ?? ''} />;
      },
    },
    {
      field: 'email',
      title: 'E-mail',
      render: (rowData) => <ActionNameElement actionName={rowData.email ?? ''} />,
    },
    {
      field: 'actions',
      headerCenter: true,
      title: 'Editar',
      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'edit',
                  onClick: () => {
                    props.router.push(`/owner/participantes/${rowData.id}`);
                  },
                  color: appColors.primary,
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? mobileColumns : defaultColumns;

  return (
    <GeneralGrid container spacing={3}>
      <GeneralGrid item container xs={12} justifyContent="center">
        <GeneralGrid container item xs={12} style={{ width: isMobile ? '85vw' : undefined }}>
          <GeneralGrid item alignItems="center">
            <Table
              toolbarSearch
              disableSearchIconClick
              searchBarOnChange={setNameSearched}
              searchPlaceholder="Pesquisar nome, email ou cpf"
              title="Participantes"
              columns={columns}
              rows={data}
              dataType="participants"
              setAPIPage={setTablePage}
              setAPILimit={setShownPerPage}
              countFromAPI={count}
              loading={status}
              mobileTable={isMobile}
              hasBorder={!isMobile}
            />
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </GeneralGrid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    participants: state.participants,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsTable);
