import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize } from 'redux-form';
import types from './types';

const URL_WEBSITE = `${BASE_API}/generateWebsite`;
const URL_EDIT_WEBSITE = `${BASE_API}/updateWebsite`;
const URL_WEBSITES = `${BASE_API}/landing-pages`;
const URL_STATION_DATA = `${BASE_API}/stations`;
const URL_FONTS_DATA = `${BASE_API}/fonts`;

export const GET_GOOGLE_FONTS_LOAD = 'GET_GOOGLE_FONTS_LOAD';
export const GET_GOOGLE_FONTS = 'GET_GOOGLE_FONTS';
export const GET_FONT_BY_NAME_LOAD = 'GET_FONT_BY_NAME_LOAD';
export const GET_FONT_BY_NAME = 'GET_FONT_BY_NAME';

export const createWebsite = (values, router = undefined) => {
  // Standard values for color fields
  values.general_color = values.general_color ? values.general_color : '#000000';
  values.body_light_color = values.body_light_color ? values.body_light_color : '#000000';
  values.body_dark_color = values.body_dark_color ? values.body_dark_color : '#000000';
  values.body_dark_font_color = values.body_dark_font_color
    ? values.body_dark_font_color
    : '#000000';
  values.body_button_background_color = values.body_button_background_color
    ? values.body_button_background_color
    : '#000000';
  values.body_button_font_color = values.body_button_font_color
    ? values.body_button_font_color
    : '#000000';
  values.body_button_border_color = values.body_button_border_color
    ? values.body_button_border_color
    : '#000000';
  values.navbar_font_color = values.navbar_font_color ? values.navbar_font_color : '#000000';
  values.navbar_button_background_color = values.navbar_button_background_color
    ? values.navbar_button_background_color
    : '#000000';
  values.navbar_button_font_color = values.navbar_button_font_color
    ? values.navbar_button_font_color
    : '#000000';
  values.navbar_button_border_color = values.navbar_button_border_color
    ? values.navbar_button_border_color
    : '#000000';
  values.footer_background_color = values.footer_background_color
    ? values.footer_background_color
    : '#000000';
  values.footer_font_color = values.footer_font_color ? values.footer_font_color : '#000000';
  values.body_link_color = values.body_link_color ? values.body_link_color : '#000000';
  values.navbar_background_color = values.navbar_background_color
    ? values.navbar_background_color
    : '#000000';
  values.navbar_button_color = values.navbar_button_color ? values.navbar_button_color : '#000000';
  values.navbar_title_color = values.navbar_title_color ? values.navbar_title_color : '#000000';

  return (dispatch) => {
    dispatch([
      {
        type: types.WEBSITE_BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);

    axios
      .post(`${URL_WEBSITE}`, values, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: types.WEBSITE_BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.WEBSITE_CREATED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            dispatch([
              {
                type: 'SNACKBAR',
                payload: {
                  message: `Erro: ${e.response.data.messages[0]}`,
                  variant: 'error',
                  open: true,
                },
              },
              {
                type: types.WEBSITE_BUTTON_LOAD,
                payload: false,
              },
            ]);
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro',
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};

export const editStation = (values, id, router = undefined) => {
  const newValues = {
    ...values,
    contact_phone: values.phone,
  };
  return (dispatch) => {
    dispatch([
      {
        type: types.WEBSITE_BUTTON_LOAD,
        payload: true,
      },
      {
        type: 'SUBMIT_PROGRESS',
        payload: 1,
      },
    ]);

    axios
      .put(`${URL_EDIT_WEBSITE}/${id}`, newValues, {
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          {
            type: types.WEBSITE_BUTTON_LOAD,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.WEBSITE_CREATED,
            payload: response.data,
          },
        ]);
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            dispatch([
              {
                type: 'SNACKBAR',
                payload: {
                  message: `Erro: ${e.response.data.messages[0]}`,
                  variant: 'error',
                  open: true,
                },
              },
              {
                type: types.WEBSITE_BUTTON_LOAD,
                payload: false,
              },
            ]);
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro',
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};

export const getWebsites = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.WEBSITE_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_WEBSITES}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.WEBSITE_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: types.WEBSITE_LOAD,
        payload: true,
      },
    ]);
    axios.get(`${URL_STATION_DATA}/${id}`).then((response) => {
      let initialValues = JSON.parse(response.data.jsonData);
      /* const initialValues = {
                    ...response.data,
                    prettyName: response.data.name,
                    name: response.data.domain,
                    phone: response.data.contact_phone
                } */
      initialValues = {
        url: response.data.url,
        ...initialValues,
        ...initialValues.objectNewItemsBody,
        public: response.data.public,
      };
      initialValues.prettyName = response.data.name;
      initialValues.name = response.data.domain;
      initialValues.currency_name = response.data.currency_name;
      initialValues.currency_name_position = response.data.currency_name_position;
      dispatch([
        {
          type: types.WEBSITE_LOAD,
          payload: false,
        },
        initializeForm(initialValues),
      ]);
    });
  };
};

export const getGoogleFonts = () => {
  return (dispatch) => {
    dispatch([
      {
        type: GET_GOOGLE_FONTS_LOAD,
        payload: true,
      },
    ]);

    axios
      .get(URL_FONTS_DATA, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(async (response) => {
        const googleFontsData = await response.data.content.map((font) => {
          return {
            label: font,
            value: font,
          };
        });
        dispatch([
          {
            type: GET_GOOGLE_FONTS,
            payload: googleFontsData,
          },
          {
            type: GET_GOOGLE_FONTS_LOAD,
            payload: false,
          },
        ]);
      });
  };
};

export const getGoogleFontsByName = (fontSearch) => {
  return (dispatch) => {
    dispatch([
      {
        type: GET_FONT_BY_NAME_LOAD,
        payload: true,
      },
    ]);

    axios
      .get(`${BASE_API}/fonts?search=${fontSearch}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        console.log('responsebyname');
        console.log(response);
        dispatch([
          {
            type: GET_FONT_BY_NAME,
            payload: response.data,
          },
          {
            type: GET_FONT_BY_NAME_LOAD,
            payload: false,
          },
        ]);
      });
    // .catch((err) => console.log(err));
  };
};

export const initializeForm = (values) => {
  return initialize('websiteFormEdit', values);
};
