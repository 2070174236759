import PropTypes from 'prop-types';
import React from 'react';
import CheckListQuiz from '../activities/Quizes/CheckListQuiz';
import QuizRightAnswer from '../activities/Quizes/QuizRightAnswer';

import Container from './styles';

export default function Interaction(props) {
  return (
    <Container>
      {props.formValues.type_id === 9 || props.formValues.type_id === 10 ? (
        <CheckListQuiz
          question={props.formValues.questions[0].question}
          options={props.formValues.questions[0].options}
        />
      ) : props.formValues.type_id === 11 || props.formValues.type_id === 12 ? (
        <QuizRightAnswer questions={props.formValues.questions} />
      ) : null}
    </Container>
  );
}

Interaction.propTypes = {
  formValues: PropTypes.shape({
    questions: PropTypes.any,
    type_id: PropTypes.number,
  }),
};
