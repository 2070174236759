/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import { getContactsByStationId } from '../contacts/contactsActions';
import DeliveryForm from './deliveryForm';
import { getOrdersWithId } from './deliveryOrdersActions';

export function DeliveryOrders(props) {
  const { id } = props.auth.user.establishments[0].stations[0];
  const { router } = props;
  const { list, loading } = props.deliveryOrders;
  const [visible, setVisible] = useState(false);

  const [columns] = useState([
    { field: 'name', title: 'Nome' },
    {
      field: 'delivery_date',
      title: 'Estimativa de entrega',
      render: (rowData) => moment(rowData.delivery_date).format('DD/MM/YYYY'),
    },
    {
      field: 'createdAt',
      title: 'Data do pedido',
      render: (rowData) => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Whatsapp',
      field: 'validated',
      render: (rowData) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <a target="_blank" href={`https://wa.me/55${rowData.phone}`}>
              Contatar
            </a>
          </Box>
        );
      },
    },
    {
      title: 'Detalhes do Pedido',
      render: (rowData) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <FontIcon
              onClick={() => props.router.push(`/owner/delivery/detalhe/${rowData.id}`)}
              style={{ cursor: 'pointer' }}>
              search
            </FontIcon>
          </Box>
        );
      },
    },
    {
      field: 'delivered',
      title: 'Entregue',
      render: (rowData) => (rowData.delivered ? 'Sim' : 'Não'),
    },
    {
      title: 'Anexar comprovantes',
      render: (rowData) => {
        return (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <span
              onClick={() => props.router.push(`/owner/delivery/receipt/${rowData.id}`)}
              style={{ color: '#2d95ef', cursor: 'pointer' }}>
              Anexar
            </span>
          </Box>
        );
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.auth.user.establishments[0].stations[0].id === 1) {
      props.getOrdersWithId(id);
      props.getContactsByStationId(id);
    } else {
      props.router.push('/owner');
    }
  }, []);

  useEffect(() => {
    setData(props.deliveryOrders.list);
  }, [props.deliveryOrders.list]);

  function handleChange(e) {
    setData(tableSearch(e, props.deliveryOrders.list));
    setValue(e.target.value);
  }
  function handleClose() {
    setVisible(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não tem nenhum pedido</h2>
                <p>Os pedidos serão feitos pelos participantes e aparecerão aqui.</p>

                <PrimaryButton onClick={() => handleOpen()}>Criar pedido manualmente</PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>

          <AlertDialogSlide
            visible={visible}
            onClose={handleClose}
            max-width={'md'}
            title="Criação de pedido">
            <GeneralGrid
              container
              style={{ display: 'flex', justifyContent: 'center' }}
              spacing={3}>
              <GeneralGrid item xs={10}>
                <DeliveryForm router={props.router} />
              </GeneralGrid>
            </GeneralGrid>
          </AlertDialogSlide>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3} justify="space-between" alignContent="space-between">
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}>
                Pedidos
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {list.length} {list.length > 1 ? 'pedidos' : 'pedido'}
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6 title="Pedidos" columns={columns} data={data} router={router} />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 'fit-content',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleOpen}>
                <Typography
                  style={{
                    width: 'fit-content',
                    marginRight: 10,
                    color: '#2d95ef',
                  }}>
                  Criar pedido
                </Typography>
                <AddCircleIcon style={{ marginRight: 10, fontSize: 42, color: '#2d95ef' }} />
              </Box>
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
      <AlertDialogSlide
        visible={visible}
        onClose={handleClose}
        max-width={'md'}
        title="Criação de pedido">
        <GeneralGrid container style={{ display: 'flex', justifyContent: 'center' }} spacing={3}>
          <GeneralGrid item xs={10}>
            <DeliveryForm router={props.router} />
          </GeneralGrid>
        </GeneralGrid>
      </AlertDialogSlide>
    </section>
  );
}

DeliveryOrders.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deliveryOrders: PropTypes.shape({
    list: PropTypes.shape({
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  getContactsByStationId: PropTypes.func,
  getOrdersWithId: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deliveryOrders: state.deliveryOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrdersWithId,
      getContactsByStationId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOrders);
