import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import CardJourneyInfo from 'common/components/cards/cardJourneyInfo';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createJourney, deleteJourney, getStationJourneys } from './journeysActions';

function Journeys(props) {
  const { auth } = props;
  const content = props.journeys.list;
  const [open, setOpen] = useState(false);
  const [idCancel, setIdCancel] = useState(undefined);

  useEffect(() => {
    props.getStationJourneys(auth.user.establishments[0].stations[0].id);
  }, []);

  function renderEmptyScreen() {
    return (
      <section id="activeActions">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Jornadas</h2>
                  <p className="">
                    Jornadas são conjuntos de atividades encadeadas que só podem ser feitas quando o
                    nível anterior for concluído
                  </p>

                  <PrimaryButton
                    onClick={() =>
                      props.createJourney(auth.user.establishments[0].stations[0].id, props.router)
                    }
                    color="primary">
                    Criar Jornada
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.journeys.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item sm={12} style={{ padding: '0 2em' }}>
          <GeneralGrid
            container
            spacing={3}
            justify={'space-between'}
            style={{ padding: '2.6em 0 2.6em 0' }}>
            <GeneralGrid item sm={8} md={6} lg={4} style={{ padding: '12px 12px 12px 5px' }}>
              <Box height="100%" display="flex" justifyContent="flex-start" alignItems="center">
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                    textAlign: 'left',
                    textJustify: 'center',
                    backgroundColor: '#f7f7f7',
                  }}>
                  Estas são as jornadas online em sua estação.
                </Typography>
              </Box>
            </GeneralGrid>
            <GeneralGrid item sm={4} md={6} lg={8} style={{ padding: '12px 0 12px 12px' }}>
              <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
                <PrimaryButton
                  style={{
                    height: '4.3em',
                    width: '14em',
                    margin: '8px 0 8px 8px',
                  }}
                  onClick={() =>
                    props.createJourney(auth.user.establishments[0].stations[0].id, props.router)
                  }>
                  NOVA JORNADA
                </PrimaryButton>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          {content.map((item, index) => (
            <CardJourneyInfo
              key={index}
              name={item.name}
              onClickDelete={() => {
                setIdCancel(item.id);
                setOpen(true);
              }}
              item={item}
              description={item.description}
              condition={moment(new Date()).isBetween(item.start, item.end)}
              onClickEdit={() => props.router.push(`/owner/journeys/journey/${item.id}`)}
            />
          ))}
        </GeneralGrid>
      </GeneralGrid>
      {open ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={() => setOpen(false)}
          visible={open}>
          <div>
            <Typography>
              {!(content.filter((item) => item.id === idCancel)[0].levels.length < 1)
                ? 'Para deletar, primeiramente remova as atividades e níveis da jornada.'
                : null}
            </Typography>
            {content.filter((item) => item.id === idCancel)[0].levels.length < 1 ? (
              <PrimaryButton
                noMargin
                type="button"
                onClick={() =>
                  props.deleteJourney(
                    idCancel,
                    props.auth.user.establishments[0].stations[0].id,
                    setOpen
                  )
                }
                disabled={props.journeys.buttonLoading}>
                Sim, quero deletar
              </PrimaryButton>
            ) : (
              <PrimaryButton
                noMargin
                onClick={() => props.router.push(`/owner/journeys/journey/${idCancel}`)}
                type="button"
                disabled={props.journeys.buttonLoading}>
                Ir para a jornada
              </PrimaryButton>
            )}

            <SecondaryButton onClick={() => setOpen(false)}>Cancelar</SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
    </section>
  );
}

Journeys.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  createJourney: PropTypes.func,
  deleteJourney: PropTypes.func,
  getStationJourneys: PropTypes.func,
  journeys: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
      map: PropTypes.func,
    }),
    loading: PropTypes.any,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    journeys: state.journeys,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getStationJourneys, createJourney, deleteJourney }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Journeys);
