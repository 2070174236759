import AppBar from '@material-ui/core/AppBar';
import MaterialGrid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Account from '@material-ui/icons/AccountBox';
import clsx from 'clsx';
import LoadingProgress from 'common/components/progress/loading';
import {
  ButtonIconElement,
  ConditionalElement,
  DateElement,
  NameElement,
} from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appColors } from 'styles/colors';
import { finishSupport } from '../../support/supportActions';
import {
  getContactById,
  getEarnPointsTransactions,
  getUserSupportMessages,
} from '../contactsActions';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  AppBar: {
    background: 'white',
    boxShadow: 'none',
  },
  tab: {
    color: 'black',
  },
  active: {
    backgroundColor: appColors.background.level2,
  },
}));

const AntTabs = withStyles({
  indicator: {
    backgroundColor: appColors.primary,
    transform: 'translateY(-45px)',
  },
})(Tabs);

function ContactModal(props) {
  useEffect(() => {
    props.getContactById(props.contact.id);
    props.getEarnPointsTransactions(props.contact.id);
    props.getUserSupportMessages(props.contact.id);
  }, []);

  const classes = useStyles();
  const [list, setList] = React.useState([]);
  const [listEarnPoints, setListEarnPoints] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [messages, setMessages] = React.useState([]);
  const [columns] = React.useState([
    {
      title: 'Atividade',
      field: 'name',
      render: (rowData) => <NameElement name={rowData.name} />,
    },
    {
      title: 'Data',
      field: 'createdAt',
      render: (rowData) => <DateElement start date={rowData.createdAt} />,
    },
    {
      title: 'Resultado',
      render: (rowData) => {
        if (rowData.isActivity) {
          return (
            <div>
              <span
                style={{ color: '#2D95EF', cursor: 'pointer' }}
                onClick={() =>
                  rowData.active
                    ? props.router.push(
                        `/owner/acoes/cupons/resultados/?action=${rowData.id}&from=contacts`
                      )
                    : props.router.push(
                        `/owner/acoes/cupons/resultados/?action=${rowData.id}&from=contacts`
                      )
                }>
                Resultado
              </span>
            </div>
          );
        }
      },
    },
  ]);

  useEffect(() => {
    if (!_.isNull(props.contacts.contactDetails) && !_.isUndefined(props.contacts.contactDetails)) {
      if (props.contacts.contactDetails.activities) {
        console.log('aqui');
        setList(props.contacts.contactDetails.activities);
      }
    } else {
      setList([]);
    }
  }, [props.contacts.contactDetails]);

  useEffect(() => {
    if (props.contacts.earnPointsTransactions) {
      setListEarnPoints(props.contacts.earnPointsTransactions);
    }
  }, [props.contacts.earnPointsTransactions]);

  useEffect(() => {
    if (props.contacts.supportMessages) {
      setMessages(props.contacts.supportMessages);
    }
  }, [props.contacts.supportMessages]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const { contact } = props;
  console.log(contact);
  return (
    <MaterialGrid container spacing={3}>
      <MaterialGrid item md={3} sm={6} xs={6} lg={3} style={{ marginBottom: 20 }}>
        <center>
          <div
            className="d-inline-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: 10, minWidth: 0 }}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                borderRadius: '50%',
                height: 170,
                width: 170,
                background: contact.image ? `url(${BASE_S3}/${contact.image})` : '#ababab',
                backgroundSize: 'cover',
                backgroundPosition: `center`,
              }}>
              {!contact.image && (
                <Account
                  style={{
                    fontSize: 90,
                    color: 'white',
                  }}
                />
              )}
            </div>
          </div>
        </center>
      </MaterialGrid>
      <MaterialGrid item md={4} sm={6} xs={12} lg={4}>
        <span style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 24 }}>{contact.name}</span>
        <p
          style={{
            fontFamily: 'Roboto',
            fontWeight: 200,
            fontSize: 12,
            marginTop: 20,
            color: '#2D95EF',
          }}>
          {contact.email}
        </p>
        <p
          style={{
            fontFamily: 'Roboto',
            fontWeight: 200,
            fontSize: 12,
            marginTop: 0,
            color: 'lightblack',
          }}>
          Adicionado como Participante em {moment(contact.createdAt).format('DD/MM/YYYY')}
        </p>
      </MaterialGrid>
      <MaterialGrid sm={12} md={12} lg={12}>
        <div className={classes.root}>
          <AppBar position="static" className={classes.AppBar}>
            <AntTabs value={value} onChange={handleChange}>
              <Tab
                className={clsx(classes.tab, value === 0 && classes.active)}
                label="Atividades"
              />
              <Tab
                className={clsx(classes.tab, value === 1 && classes.active)}
                label="Créditos Conquistados"
              />

              <Tab className={clsx(classes.tab, value === 2 && classes.active)} label="Chamados" />
            </AntTabs>
          </AppBar>
          <div style={{ backgroundColor: appColors.background.level2 }}>
            {value === 0 && (
              <TabContainer>
                {props.contacts.loadingActivities ? (
                  <div className="d-flex justify-content-center mt-5">
                    <LoadingProgress />
                  </div>
                ) : list.length > 0 ? (
                  <MaterialGrid item md={12} sm={12} xs={12} lg={12}>
                    <Table
                      onChangeOption={() => {}}
                      defaultSelected={[]}
                      columns={columns}
                      rows={list}
                    />
                  </MaterialGrid>
                ) : (
                  <MaterialGrid item md={12} sm={12} xs={12} lg={12}>
                    <center
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 200,
                        fontSize: 24,
                      }}>
                      Não existem atividades até o momento
                    </center>
                  </MaterialGrid>
                )}
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                {props.contacts.loadingEarnPointsTransactions ? (
                  <div className="d-flex justify-content-center mt-5">
                    <LoadingProgress />
                  </div>
                ) : list.length > 0 ? (
                  <MaterialGrid item md={12} sm={12} xs={12} lg={12}>
                    <Table
                      columns={[
                        {
                          title: 'Atividade',
                          field: 'action.name',
                          render: (rowData) => (
                            <NameElement name={rowData.action ? rowData.action.name : ''} />
                          ),
                        },
                        {
                          title: 'Créditos',
                          field: 'points',
                        },
                        {
                          title: 'Data',
                          field: 'createdAtDate',
                        },
                      ]}
                      rows={listEarnPoints}
                      onChangeOption={() => {}}
                      defaultSelected={[]}
                    />
                  </MaterialGrid>
                ) : (
                  <MaterialGrid item md={12} sm={12} xs={12} lg={12}>
                    <center
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 200,
                        fontSize: 24,
                      }}>
                      Não existem atividades até o momento
                    </center>
                  </MaterialGrid>
                )}
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                {props.contacts.loadingSupportMessages ? (
                  <div className="d-flex justify-content-center mt-5">
                    <LoadingProgress />
                  </div>
                ) : (
                  <MaterialGrid item md={12} sm={12} xs={12} lg={12}>
                    <Table
                      columns={[
                        { field: 'name', title: 'Nome' },
                        { field: 'subject', title: 'Assunto' },
                        { field: 'email', title: 'E-mail' },
                        {
                          title: 'Finalizado',
                          render: (rowData) => <ConditionalElement condition={rowData.solved} />,
                          // headerCenter:true,
                        },
                        {
                          title: 'Ver Detalhes',
                          // headerCenter:true,
                          render: (rowData) => {
                            return (
                              <ButtonIconElement
                                type={0}
                                justifyContent={'flex-start'}
                                onClick={() => {
                                  props.router.push(`/owner/suporte/${rowData.id}/detalhes`);
                                }}
                              />
                            );
                          },
                        },
                        {
                          title: 'Finalizar',
                          // headerCenter:true,
                          render: (rowData) => {
                            if (rowData.solved) {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                  }}>
                                  <span>Finalizado</span>
                                </div>
                              );
                            }
                            return (
                              <ButtonIconElement
                                onClick={() =>
                                  props.finishSupport(
                                    rowData.id,
                                    props.auth.user.establishments[0].stations[0].id,
                                    props.closeModal
                                  )
                                }
                                type={1}
                                justifyContent={'flex-start'}
                              />
                            );
                          },
                        },
                        { field: 'createdAt', title: 'Data de criação' },
                      ]}
                      rows={messages}
                      onChangeOption={() => {}}
                      defaultSelected={[]}
                    />
                  </MaterialGrid>
                )}
              </TabContainer>
            )}
          </div>
        </div>
      </MaterialGrid>
    </MaterialGrid>
  );
}

ContactModal.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  closeModal: PropTypes.any,
  contact: PropTypes.shape({
    createdAt: PropTypes.any,
    email: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.any,
    name: PropTypes.any,
  }),
  contacts: PropTypes.shape({
    contactDetails: PropTypes.shape({
      activities: PropTypes.any,
    }),
    earnPointsTransactions: PropTypes.any,
    loadingActivities: PropTypes.any,
    loadingEarnPointsTransactions: PropTypes.any,
    loadingSupportMessages: PropTypes.any,
    supportMessages: PropTypes.any,
  }),
  finishSupport: PropTypes.func,
  getContactById: PropTypes.func,
  getEarnPointsTransactions: PropTypes.func,
  getUserSupportMessages: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contacts: state.contacts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactById,
      getEarnPointsTransactions,
      getUserSupportMessages,
      finishSupport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
