import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Benefits from './benefits';
import BenefitsForm from './benefitsForm';

export default (
  <Route path="beneficios">
    <IndexRoute component={(props) => <Benefits {...props} />} />
    <Route path="novo" component={(props) => <BenefitsForm {...props} />} />
    <Route path=":id/:action" component={(props) => <BenefitsForm {...props} />} />
  </Route>
);
