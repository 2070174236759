import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Automations from './automations';
import welcomeMailRoutes from './automationTypes/1_welcome_email/welcomeMailRoutes';

export default (
  <Route path="automatizacoes">
    <IndexRoute component={(props) => <Automations {...props} />} />
    {welcomeMailRoutes}
  </Route>
);
