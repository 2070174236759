import axios from 'axios';
import { reset as resetForm, initialize } from 'redux-form';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import types from './types';

const URL = `${BASE_API}/subscription-plans`;
const URL_SUBSCRIPTIONS = `${BASE_API}/subscriptions`;
const URL_PLANS_SUBSCRIPTIONS = `${BASE_API}/plans-subscriptions`;
const URL_SUPPORT = `${BASE_API}/enviar-suporte`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: true });
    axios
      .get(`${URL}?station_id=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getPlansSubscriptions = (id) => {
  return (dispatch) => {
    dispatch({ type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: true });
    axios
      .get(`${URL_PLANS_SUBSCRIPTIONS}/?[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'PLANS_SUBSCRIPTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getTransactions = (id, callback, errCallback) => {
  return (dispatch) => {
    axios
      .get(`${URL_SUBSCRIPTIONS}/${id}/transactions`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch((err) => {
        if (errCallback) errCallback();
        console.log(err);
      });
  };
};

export const settleCharges = (id, callback, errCallback) => {
  return (dispatch) => {
    dispatch({ type: 'SUBSCRIPTION_TRANSACTIONS_FETCHED', payload: true });
    axios
      .put(`${URL_SUBSCRIPTIONS}/${id}/settle-charges`, null, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: 'Operação realizada com sucesso',
            variant: 'success',
            open: true,
          },
        });
        if (callback) callback();
      })
      .catch((err) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${err.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
        if (errCallback) errCallback();
      });
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  return (dispatch) => {
    dispatch({ type: types.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: types.LIST_LOAD,
            payload: false,
          },

          resolve(),
          getList(other),
        ]);
      })
      .catch((e) => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
        reject();
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const initialValues = {
          ...response.data,
          benefits: response.data.benefits.map((item) => ({
            value: item.benefit,
          })),
        };
        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: response,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    const initialValues = {
      ...values,
      benefits: values.benefits.map((item) => ({
        value: item.benefit,
      })),
    };
    dispatch([
      {
        type: types.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = (values) => {
  return initialize('plansForm', values);
};

export const reset = () => {
  return resetForm('plansForm');
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

function submit(values, method, router = undefined) {
  const id = values.id ? `${values.id}/` : '';
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: (progressEvent) =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
          },
        ]),
    })
      .then((response) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
        if (!_.isUndefined(router)) {
          router.push('/owner/planos');
        }
      })
      .catch((e) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.messages) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.messages[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const purchasePlan = (values, method, id) => {
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](
      `${method === 'post' ? URL_SUBSCRIPTIONS : `${URL_SUBSCRIPTIONS}${id ? `/${id}` : ''}`}`,
      values,
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      }
    )
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: 'USER_UPDATE_SUBSCRIPTION', payload: response.data },
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: 'PLANS_SUBSCRIPTIONS_SHOW_CONFIRMATION',
            payload: true,
          },
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.messages) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.messages[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: false,
    });
  };
};

export const fileRejected = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 2MB',
        variant: 'error',
        open: true,
      },
    });
  };
};

export const deactivatePlan = (id, cleanup) => {
  return (dispatch) => {
    dispatch([
      { type: types.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          cleanup(),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          if (e.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          } else {
            for (const i in e.response.data) {
              for (const j in e.response.data[i]) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: {
                    message: `${i}: ${e.response.data.errors[i][j]}`,
                    variant: 'error',
                    open: true,
                  },
                });
              }
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const submitSupport = (values, router = undefined) => {
  return (dispatch) => {
    dispatch([
      { type: 'SUBMIT_PROGRESS', payload: 1 },
      { type: 'ABOUT_BUTTON_LOAD', payload: true },
    ]);

    axios
      .post(`${URL_SUPPORT}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          { type: 'ABOUT_BUTTON_LOAD', payload: false },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(router)) {
          router.push('/');
        }
      })
      .catch((e) => {
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          { type: 'ABOUT_BUTTON_LOAD', payload: false },
        ]);

        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `${e.response.data.error}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const showConfirmation = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'PLANS_SUBSCRIPTIONS_SHOW_CONFIRMATION',
      payload: value,
    });
  };
};

export const cancelSubscription = (values, cleanup) => {
  return (dispatch) => {
    dispatch([
      { type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(`${URL_SUBSCRIPTIONS}/cancel`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'USER_UPDATE_SUBSCRIPTION', payload: {} },
          { type: 'PLANS_SUBSCRIPTIONS_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          cleanup(),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: types.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.messages[0]}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
