import React from 'react';
import { IndexRoute, Route } from 'react-router';
import ProductsReport from './productsReport';
import ProductReport from './productReport';

export default (
  <Route path="relatorio-produtos">
    <IndexRoute component={(props) => <ProductsReport {...props} />} />
    <Route path=":id">
      <IndexRoute component={(props) => <ProductReport {...props} />} />
    </Route>
  </Route>
);
