export default {
  LOADING_LOGIN: 'LOADING_LOGIN',
  LOADING_REGISTER: 'LOADING_REGISTER',
  USER_REGISTRED: 'USER_REGISTRED',
  USER_ACTIVED: 'USER_ACTIVED',
  TOKEN_VALIDATED: 'TOKEN_VALIDATED',
  USER_FETCHED: 'USER_FETCHED',
  USER_RECOVERED: 'USER_RECOVERED',
  PERMISSIONS_FETCHED: 'PERMISSIONS_FETCHED',
  PERMISSIONS_DELETED: 'PERMISSIONS_DELETED',
  USER_CHANGED: 'USER_CHANGED',
  ESTABLISHMENTS_LOAD: 'ESTABLISHMENTS_LOAD',
  ESTABLISHMENTS_FETCHED: 'ESTABLISHMENTS_FETCHED',
};
