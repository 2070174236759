import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
  componentDidMount() {
    if (this.props.auth.user.active === 0) {
      this.props.router.push('/confimation');
    } else {
      if (this.props.auth.user.type_id === 1) {
        this.props.router.push('/owner');
      } else if (this.props.auth.user.type_id === 5) {
        this.props.router.push('/owner/delivery');
      }
    }
  }

  render() {
    return <div></div>;
  }
}

Home.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      active: PropTypes.number,
      type_id: PropTypes.number,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Home);
