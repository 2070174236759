import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import './tableGeneral.css';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TuneIcon from '@material-ui/icons/Tune';

import Chip from '@material-ui/core/Chip';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { CSVLink } from 'react-csv';
import { GetApp } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { searchTableValue, formatReal } from '../../../helpers/utils.js';
import { SearchBar, MenuFiltersOptions } from './tableComponents';

const themeSec = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        width: 95,
        height: 60,
        borderRadius: 10,
        border: '1px solid #DFDFDF',
        padding: 10,
        margin: 0,
      },
      outlined: {
        height: 60,
        // borderRadius: 6,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 6,
        border: '1px solid #DFDFDF',
        padding: 0,
        marginRight: 5,
        marginLeft: 15,
        transform: `rotate(90deg)`,
      },
    },
  },
});

const themeSecMobile = createTheme({
  overrides: {
    MuiSelect: {
      input: {
        border: '0px',
      },
      root: {
        width: 135,
        height: 60,
        borderRadius: 10,
        padding: 10,
        margin: 0,
        border: '0px',
        fontWeight: 'bold',
      },
    },

    MuiIconButton: {
      root: {
        borderRadius: 6,
        padding: 0,
        marginRight: 5,
        border: '1px solid #DFDFDF',
        transform: `rotate(90deg)`,
        // color: 'black',
      },
    },
  },
});

export default function TableGeneral(props) {
  const {
    title,
    subtitle,
    toolbarSearch = false,
    compressedTable,
    data: defaultData,
    columns,
    dataType,
    hasToolbar,
    filters,
    searchPlaceholder,
    add,
    edit,
    remove,
    csvData,
    buttonCSV,
    hasBorder = true,
    mobileTable,
    mobileDropdown,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = React.useRef();
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    const filteredByName = searchTableValue(searchValue, defaultData, dataType);
    const filteredBySelectors = filterTableData(appliedFilters, filteredByName);
    setFilteredData(filteredBySelectors);
  }, [searchValue, appliedFilters]);

  const filterTableData = (appliedFilters, backupData) => {
    let currentData = [];
    let filtered = [];
    if (appliedFilters.length > 0) {
      currentData = backupData;
      filtered = currentData.filter((item, index) => {
        const array = [];
        for (let i = 0; i < appliedFilters.length; i++) {
          if (appliedFilters[i].field === 'betweenDates') {
            if (appliedFilters[i].filterFunction === 0) {
              if (
                moment(item.participationStart).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item.participationEnd).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 1) {
              if (
                moment(item.action.participationStart).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item.action.participationEnd).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 2) {
              if (
                moment(item.start_date).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 3) {
              if (
                moment(item.createdAt).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            }
          } else {
            if (
              appliedFilters[i].value[0] <= item[appliedFilters[i].field] &&
              item[appliedFilters[i].field] <= appliedFilters[i].value[1]
            ) {
              array.push(true);
            }
          }
        }
        if (array.length === appliedFilters.length) return true;
        return false;
      });
    } else {
      filtered = backupData;
    }

    return filtered;
  };

  const handleClick = () => {
    setAnchorEl(true);
  };

  const [newColumns2] = React.useState(setInitialNewColumns(props));
  // eslint-disable-next-line no-unused-vars
  const [columnsWithStyle, setColumnsWithStyle] = React.useState(setInitialColumnsStyle(props));
  const [optionSelected, setOptionSelected] = React.useState(
    props.defaultSelected ? props.defaultSelected : ''
  );

  const handleSelectChange = (event) => {
    setOptionSelected(event.target.value);
  };

  const handleDelete = (chipField) => {
    setAppliedFilters((chips) => chips.filter((chip) => chip.field !== chipField));
  };

  useEffect(() => {
    if (props.onChangeOption) props.onChangeOption(optionSelected);
  }, [optionSelected]);

  useEffect(() => {
    setFilteredData(defaultData);
  }, [defaultData]);

  function setInitialNewColumns(props) {
    const index = props.columns.findIndex((item) => item.field === 'base64');
    const itemFound = props.columns.find((item) => item.field === 'base64');
    if (index >= 0) {
      const columnsWithImg = [...columns];

      columnsWithImg[index] = {
        ...itemFound,
        render: (rowData) => (
          <img
            src={rowData.base64}
            style={{ width: 50, borderRadius: '50%' }}
            alt="Imagem da Tabela"
          />
        ),
      };
      return columnsWithImg;
    } else {
      return props.columns;
    }
  }

  function setInitialColumnsStyle(props) {
    const array = newColumns2.map((item) => {
      if (item.field === 'actions') {
        return {
          ...item,
          title: '',
          headerStyle: {
            textAlign: item.headerCenter ? 'center' : 'left',
            fontWeight: 500,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            paddingLeft: 0,
            padding: 5,
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            border: 0,
            padding: 0,
            height: 50,
          },
          disableClick: true,
        };
      } else {
        return {
          ...item,
          headerStyle: {
            textAlign: item.headerCenter ? 'center' : 'left',
            fontWeight: 500,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            padding: 5,
            paddingLeft: 10,
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            border: 0,
            padding: 5,
            paddingLeft: 10,
            height: 50,
          },
          disableClick: !props.onRowClick,
        };
      }
    });
    return array;
  }

  function Toolbar({ showSearchBar, setShowSearchBar, ...props }) {
    return (
      <div>
        <div
          className="rowToolbar"
          style={{
            marginBottom: subtitle || compressedTable || mobileTable ? 0 : 'inherent',
          }}>
          <div
            style={{
              width: mobileTable ? '70%' : '40%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            {mobileTable && mobileDropdown ? null : (
              <p
                style={{
                  fontWeight: mobileTable ? 700 : 500,
                  fontSize: mobileTable ? 16 : 24,
                  padding: 0,
                  margin: 0,
                  marginLeft: 5,
                  marginRight: 20,
                }}>
                {title || ''}
              </p>
            )}
            {props.selectOptions ? (
              props.selectOptions.length > 0 ? (
                <ThemeProvider theme={mobileTable && mobileDropdown ? themeSecMobile : themeSec}>
                  <Select
                    disableUnderline={mobileDropdown && mobileTable}
                    variant={mobileDropdown && mobileTable ? 'standard' : 'outlined'}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={optionSelected}
                    onChange={handleSelectChange}>
                    {props.selectOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ThemeProvider>
              ) : null
            ) : null}
          </div>

          <div
            style={{
              width: mobileTable ? '30%' : '60%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: mobileTable ? 'flex-end' : 'flex-end',
              alignItems: 'center',
              flexWrap: mobileTable ? 'nowrap' : 'wrap',
            }}>
            {appliedFilters && appliedFilters.length > 0 && !mobileDropdown
              ? appliedFilters.map((item, index) => {
                  return (
                    <div key={index} style={{ marginRight: 5 }}>
                      <Chip
                        size={'small'}
                        icon={
                          item.typeData === 'quantity' ? (
                            <PersonOutlineIcon />
                          ) : item.typeData === 'money' ? (
                            <MonetizationOnIcon />
                          ) : item.typeData === 'date' ? (
                            <CalendarTodayIcon />
                          ) : null
                        }
                        label={
                          item.typeData === 'quantity'
                            ? `${item.value[0]} até ${item.value[1]}`
                            : item.typeData === 'money'
                            ? `${formatReal(item.value[0])} até ${formatReal(item.value[1])}`
                            : item.typeData === 'date'
                            ? `${moment(item.startDate).format('DD/MM/YYYY')} até ${moment(
                                item.endDate
                              ).format('DD/MM/YYYY')}`
                            : null
                        }
                        onDelete={() => handleDelete(item.field)}
                        color="primary"
                      />
                    </div>
                  );
                })
              : null}
            {buttonCSV && csvData ? (
              <Box display="flex" justifyContent="flex-end" alignItems="center" height={'100%'}>
                <CSVLink data={csvData} filename="fan-projects-vendas.csv">
                  <GetApp
                    style={{
                      fontSize: 25,
                      marginRight: 10,
                    }}
                  />
                </CSVLink>
              </Box>
            ) : null}
            {toolbarSearch ? (
              <SearchBar
                setShowSearchBar={setShowSearchBar}
                showSearchBar={showSearchBar}
                mobileTable={mobileTable}
                placeholder={searchPlaceholder || 'Pesquisar'}
                small={props.smallSearch}
                setSearchValue={setSearchValue}
                defaultValue={searchValue}
              />
            ) : null}
            {filters && filters.length > 0 ? (
              <ThemeProvider theme={mobileDropdown && mobileTable ? themeSecMobile : themeSec}>
                <IconButton ref={buttonRef} disableFocusRipple disableRipple onClick={handleClick}>
                  <TuneIcon />
                </IconButton>
                <MenuFiltersOptions
                  filterOptions={filters}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                  open={Boolean(anchorEl)}
                  buttonRef={() => buttonRef.current}
                  setAnchorEl={setAnchorEl}
                />
              </ThemeProvider>
            ) : null}
          </div>
        </div>
        {subtitle ? (
          <div className="rowToolbar" style={{ marginBottom: 0, height: 20 }}>
            <div
              style={{
                width: '35%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  padding: 0,
                  margin: 0,
                  marginLeft: 5,
                  marginRight: 20,
                }}>
                {subtitle}
              </p>
            </div>
          </div>
        ) : mobileTable && showSearchBar ? (
          <div
            className="rowToolbar"
            style={{
              marginBottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <SearchBar
              placeholder={searchPlaceholder || 'Pesquisar'}
              mobileWidth={true}
              setSearchValue={setSearchValue}
              defaultValue={searchValue}
            />
          </div>
        ) : null}

        {appliedFilters && appliedFilters.length > 0 && mobileDropdown ? (
          <div
            className="rowToolbar"
            style={{
              marginBottom: 0,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            {appliedFilters.map((item, index) => {
              return (
                <div key={index} style={{ marginRight: 5 }}>
                  <Chip
                    size={'small'}
                    icon={
                      item.typeData === 'quantity' ? (
                        <PersonOutlineIcon />
                      ) : item.typeData === 'money' ? (
                        <MonetizationOnIcon />
                      ) : item.typeData === 'date' ? (
                        <CalendarTodayIcon />
                      ) : null
                    }
                    label={
                      item.typeData === 'quantity'
                        ? `${item.value[0]} até ${item.value[1]}`
                        : item.typeData === 'money'
                        ? `${formatReal(item.value[0])} até ${formatReal(item.value[1])}`
                        : item.typeData === 'date'
                        ? `${moment(item.startDate).format('DD/MM/YYYY')} até ${moment(
                            item.endDate
                          ).format('DD/MM/YYYY')}`
                        : null
                    }
                    onDelete={() => handleDelete(item.field)}
                    color="primary"
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  Toolbar.propTypes = {
    showSearchBar: PropTypes.any,
    setShowSearchBar: PropTypes.any,
  };

  return (
    <>
      <MaterialTable
        components={{
          Toolbar: (propsSec) => {
            return hasToolbar ? (
              <Toolbar
                setShowSearchBar={setShowSearchBar}
                showSearchBar={showSearchBar}
                {...props}
              />
            ) : null;
          },
        }}
        style={{
          boxShadow: 'none',
          borderRadius: 5,
          padding: mobileTable ? 10 : 25,
          border: hasBorder ? '1px solid #c4c4c4' : '',
          width: '100%',
        }}
        // Necessário para realizar tradução do placeholder. Retirar deleteTooltip bugará.
        localization={{
          grouping: {
            groupedBy: 'Agrupar por:',
            placeholder: 'Clique e arraste até aqui os títulos das colunas para agrupar',
          },
          toolbar: {
            searchPlaceholder: 'Procurar',
          },
          body: {
            editRow: {
              saveTooltip: 'Salvar',
              cancelTooltip: 'Cancelar',
              deleteText: props.deleteText || 'Tem certeza que deseja apagar esta linha?',
            },
            addTooltip: 'Adicionar',
            deleteTooltip: 'Deletar',
            editTooltip: 'Editar',
            emptyDataSourceMessage: 'Não há dados para visualização',
          },
          header: { actions: 'Ações' },
          pagination: {
            labelRowsPerPage: 'Linhas por página',
            labelRowsSelect: 'linhas',
          },
        }}
        editable={{
          ...(add
            ? {
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const { data } = this.state;
                        data.push(newData);
                        this.setState({ data }, () => resolve());
                      }
                      resolve();
                    }, 1000);
                  }),
              }
            : {}),
          ...(edit
            ? {
                onRowUpdate: (oldData) =>
                  new Promise((resolve, reject) => {
                    props.handleUpdate(oldData, resolve, reject);
                  }),
              }
            : {}),
          ...(remove
            ? {
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    props.handleDelete(oldData.id, resolve, reject);
                  }),
              }
            : {}),
        }}
        title={title}
        columns={[...columnsWithStyle]}
        onRowClick={props.onRowClick}
        data={filteredData}
        options={{
          header: !mobileTable,
          actionsCellStyle: {
            whiteSpace: 'nowrap',
            border: 0,
            padding: 5,
            paddingLeft: 10,
            height: 50,
          },
          headerStyle: {
            textAlign: 'left',
            fontWeight: 500,
            fontSize: 14,
            color: 'black',
            whiteSpace: 'nowrap',
            paddingLeft: 0,
            padding: 5,
            backgroundColor: 'white',
          },
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
}

TableGeneral.propTypes = {
  add: PropTypes.any,
  buttonCSV: PropTypes.any,
  columns: PropTypes.shape({
    find: PropTypes.func,
    findIndex: PropTypes.func,
  }),
  compressedTable: PropTypes.any,
  csvData: PropTypes.any,
  data: PropTypes.any,
  dataType: PropTypes.any,
  defaultSelected: PropTypes.any,
  deleteText: PropTypes.string,
  edit: PropTypes.any,
  filters: PropTypes.shape({
    length: PropTypes.number,
  }),
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  hasBorder: PropTypes.bool,
  hasToolbar: PropTypes.any,
  mobileDropdown: PropTypes.any,
  mobileTable: PropTypes.any,
  onChangeOption: PropTypes.func,
  onRowClick: PropTypes.any,
  remove: PropTypes.any,
  searchPlaceholder: PropTypes.string,
  selectOptions: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  smallSearch: PropTypes.any,
  subtitle: PropTypes.any,
  title: PropTypes.string,
  toolbarSearch: PropTypes.bool,
};
