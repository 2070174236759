export default {
  LIST_FETCHED: 'SALES_FETCHED',
  LIST_LOAD: 'SALES_LOAD',
  PAGARME_FETCHED: 'PAGARME_FETCHED',
  PAGARME_LOAD: 'PAGARME_LOAD',
  ITEM_DETAIL: 'SALES_DETAIL',
  BUTTON_LOAD: 'SALES_BUTTON_LOAD',
  DIGITAL_ACCOUNT_LOADING: 'DIGITAL_ACCOUNT_LOADING',
  DIGITAL_ACCOUNT_FETCHED: 'DIGITAL_ACCOUNT_FETCHED',
};
