import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { USER_TOKEN } from 'config/consts';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { toastr } from 'react-redux-toastr';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import fallback from './fallback.png';

import Radio from '@material-ui/core/Radio';
import ConfirmationCard from '../ConfirmationCard/confirmationCard';
import { FanApi } from '../api';

import ErrorMessage from './styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      },

      // Name of the rule
    },
  },
});

export default function VideoCard({
  name,
  url,
  id,
  onChange,
  custom,
  checkVideos,
  setTab,
  tab,
  valueSelected,
  setValueSelected,
  indexVideo,
}) {
  const [error, setError] = useState('');
  const [visible, setVisible] = useState(false);

  // const accessToken = 'b581876458f6814924e8460acb042025';

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (checkVideos && checkVideos.action_videos.length > 0) {
  //       if (checkVideos.action_videos[indexVideo]?.video.id === id) {
  //         onChange(id);
  //         setSelected(true);
  //       }
  //     }
  //   }, 300);
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 2000);
  }, [error]);

  async function deleteVideo(id) {
    try {
      await FanApi.delete(`/videos/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });
      if (tab === 0) {
        setTab(1);
      } else {
        setTab(0);
      }
      setVisible(false);
    } catch (e) {
      try {
        if (e.response.data.error) {
          toastr.error('Erro', e.response.data.error);
        }
      } catch (error) {
        toastr.error('Erro', 'Erro interno no servidor');
      }
      setVisible(false);
    }
  }

  function handleDelete(id) {
    deleteVideo(id);
  }
  function openDialog() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  function handleClick(event) {
    if (event.target.value === valueSelected) {
      setValueSelected(null);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginLeft: 25,
        width: 240,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <ReactPlayer url={url} height={120} width={240} controls light={custom ? true : fallback} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          overflow: 'hidden',
        }}>
        <ThemeProvider theme={theme}>
          <FormControlLabel
            checked={valueSelected === id.toString()}
            value={id.toString()}
            control={<Radio disableRipple color="primary" onClick={handleClick} />}
            label={name}
          />
        </ThemeProvider>

        <div>
          <IconButton
            color="#A3A6A9"
            aria-label="upload picture"
            component="span"
            onClick={openDialog}
            disableFocusRipple
            disableRipple
            style={{ margin: 0, padding: 0 }}>
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <ConfirmationCard
        visible={visible}
        onClose={onClose}
        confirmDelete={() => handleDelete(id)}
      />
      <ErrorMessage error={error}>Limite de 1 vídeo</ErrorMessage>
    </div>
  );
}

VideoCard.propTypes = {
  checkVideos: PropTypes.any,
  custom: PropTypes.any,
  id: PropTypes.shape({
    toString: PropTypes.func,
  }),
  indexVideo: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.any,
  setTab: PropTypes.func,
  setValueSelected: PropTypes.func,
  tab: PropTypes.number,
  url: PropTypes.shape({
    split: PropTypes.func,
  }),
  valueSelected: PropTypes.func,
};
