import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Coupons from './subscriptions';
import SubscriptionsValidated from './subscriptionsValidated';

export default (
  <Route path="inscricoes">
    <IndexRoute component={(props) => <Coupons {...props} />} />
    <Route path="validados" component={(props) => <SubscriptionsValidated {...props} />} />
  </Route>
);
