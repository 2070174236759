import TextField from '@material-ui/core/TextField';
import React from 'react';
import Grid from '../layout/grid/grid';
import IF from '../operador/if';
import PropTypes from 'prop-types';

export class InputAuth extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <IF test={!this.props.hide}>
        <Grid cols={this.props.cols} style={this.props.style}>
          <div className={`form-group ${touched && error && 'has-error'}`}>
            <TextField
              {...this.props.input}
              id={this.props.id}
              label={this.props.label}
              type={this.props.type}
              lineDirection="center"
              name={this.props.name}
              floating
              className={this.props.className}
            />
            {touched && error && <span className="help-block">{error}</span>}
          </div>
        </Grid>
      </IF>
    );
  }
}

InputAuth.propTypes = {
  className: PropTypes.any,
  cols: PropTypes.any,
  hide: PropTypes.any,
  id: PropTypes.any,
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
  name: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.any,
};
