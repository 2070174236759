import PropTypes from 'prop-types';
import ptBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import { appColors } from '../../../styles/colors';
import DangerButton from '../buttons/dangerButton';
import PrimaryButton from '../buttons/primaryButton';
import SecondaryButton from '../buttons/secondaryButton';
import CustomizedModal from '../modal/CustomizedModal';
import './calendar.css';

export default class Calendar extends Component {
  constructor() {
    super();
    this.state = {
      selectedEvent: '',
      selectedDescription: '',
      selectedTitle: '',
      open: false,
    };
  }

  handleDateClick = (event) => {
    this.setState({
      open: true,
      selectedEvent: event.event._def.publicId,
      selectedDescription: event.event.extendedProps.description,
      selectedTitle: event.event.title,
    });
  };

  onHide = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.props.handleDelete(this.state.selectedEvent);
  };

  handleEdit = () => {
    this.props.router.push(
      `${this.props.router.location.pathname}/${this.state.selectedEvent}/editar`
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { weekends, schedules } = this.props;
    const eventList = schedules.list.content.map((event) => {
      return {
        id: event.id,
        start: `${moment(event.day.description).format('YYYY-MM-DD')}T${event.start}Z`,
        end: `${moment(event.day.description).format('YYYY-MM-DD')}T${event.end}Z`,
        title: event.name,
        description: event.description,
      };
    });

    const footer = (
      <div>
        <SecondaryButton type="button" onClick={this.onHide}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton type="button" onClick={this.handleEdit}>
          Editar
        </PrimaryButton>
        <DangerButton onClick={this.handleDelete} type="button">
          Excluir
        </DangerButton>
      </div>
    );
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 py-2">
            <FullCalendar
              defaultView="dayGridMonth"
              header={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              plugins={[dayGridPlugin, timeGridPlugin]}
              selectable
              events={eventList}
              weekends={weekends}
              eventColor={appColors.darkBlue}
              displayEventTime
              displayEventEnd
              eventTimeFormat={{ hour: '2-digit', hour12: false }}
              timeZone="UTC"
              locale={ptBr}
              eventClick={this.handleDateClick}
              slotDuration="00:15:00"

              /* columnHeaderText={function(date) {
                                if (date.getDay() === 5) {
                                    return "Friday!";
                                } else {
                                    return "LLL";
                                }
                            }} */
            />

            <CustomizedModal open={this.state.open}>
              <Typography variant="h6">O que deseja realizar com o evento selecionado?</Typography>
              <Typography variant="body1" style={{ fontWeight: 700, display: 'inline' }}>
                Título:{' '}
              </Typography>{' '}
              <Typography variant="body2">{this.state.selectedTitle}</Typography>
              <br />
              <Typography variant="body1" style={{ fontWeight: 700, display: 'inline' }}>
                Descrição:{' '}
              </Typography>{' '}
              <Typography variant="body2">{this.state.selectedDescription}</Typography>
              {footer}
            </CustomizedModal>
          </div>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  handleDelete: PropTypes.func,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  schedules: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
  }),
  weekends: PropTypes.any,
};
