import React, { Component } from 'react';
import PropTypes from 'prop-types';

class navBarItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { props } = this;

    if (props.type === 'text') {
      return (
        <div className="py-4 mx-2" style={{ display: 'inline-block' }}>
          <a href="#/cadastrar" style={{ textDecoration: 'none' }}>
            <span
              style={{
                fontFamily: `${props.font.family}`,
                fontWeight: `${props.font.weight}`,
                fontSize: props.font.size,
                color: `${props.font.color}`,
                letterSpacing: `${props.font.letterSpacing}`,
              }}>
              {props.value}
            </span>
          </a>
        </div>
      );
    } else if (props.type === 'dropdown') {
      return (
        <div className="dropdown py-4 mx-2" style={{ display: 'inline-block' }}>
          <a
            className="dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <span
              style={{
                fontFamily: `${props.font.family}`,
                fontWeight: `${props.font.weight}`,
                fontSize: props.font.size,
                color: `${props.font.color}`,
                letterSpacing: `${props.font.letterSpacing}`,
              }}>
              {props.value}
            </span>
          </a>
          <div
            className="dropdown-menu"
            style={{ minWidth: '7rem' }}
            aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" href="#">
              Opção 1
            </a>
            <a className="dropdown-item" href="#">
              Opção 2
            </a>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

navBarItem.propTypes = {
  type: PropTypes.any,
  font: PropTypes.any,
  value: PropTypes.any,
};

export default navBarItem;
