import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import {
  ActionNameElement,
  ButtonElement,
  ColumnsBelowSearch,
  DateElement,
} from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { getStationActionOffers, onClickAction, remove } from './actionOffersActions';

const dispatchErrorMessage = () => {
  return (dispatch) => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: `Você já aceitou essa proposta.`,
        variant: 'error',
        open: true,
      },
    });
  };
};

const filters = [
  {
    label: 'Filtrar entre as datas:',
    field: 'betweenDates',
    component: 'datePicker',
    typeData: 'date',
    filterFunction: 1,
    startDate: new Date(),
    endDate: new Date(),
  },
];

let ActionOffers = function (props) {
  const { auth } = props;
  const content = props.actionOffers.list;
  const [idCancel, setIdCancel] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('waiting');
  const [data, setData] = useState([]);

  const getActionsStatus = (action) => {
    if (!action.action.station_id && !action.refused) {
      return 'waiting';
    } else if (action.refused) {
      return 'refused';
    } else {
      return 'approved';
    }
  };

  const setFilteredActions = (option, defaultData) => {
    const filtered = defaultData.filter((action) => getActionsStatus(action) === option);
    return setData(filtered);
  };

  useEffect(() => {
    props.getStationActionOffers(auth.user.establishments[0].stations[0].id);
  }, [selectedOption]);

  useEffect(() => {
    setFilteredActions(selectedOption, props.actionOffers.list);
  }, [props.actionOffers.list]);

  function onRowClick(e, rowData) {
    if (!rowData.action.station_id) {
      props.onClickAction(rowData, props.router);
    }
  }

  const defaultColumnsMobile = [
    {
      title: 'Nome do parceiro',
      field: 'partnerName',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
        border: '1px solid black',
      },
      render: (rowData) => (
        <ActionNameElement
          singleColum={false}
          actionName={rowData.partner.name}
          image={rowData['']}
        />
      ),
    },
    {
      field: 'actions',
      headerStyle: {
        fontWeight: 500,
        fontSize: 13,
        color: 'blue',
        whiteSpace: 'nowrap',
      },
      cellStyle: {
        whiteSpace: 'nowrap',
        border: 0,
        padding: 0,
      },
      sorting: false,
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={
              selectedOption === 'waiting'
                ? {
                    namedButtons: [],
                    iconButtons: [],
                  }
                : selectedOption === 'refused'
                ? {
                    namedButtons: [],
                    iconButtons: [],
                  }
                : selectedOption === 'approved'
                ? {
                    namedButtons: [],
                    iconButtons: [],
                  }
                : null
            }
          />
        );
      },
    },
  ];

  const defaultColumns = [
    {
      title: 'Nome do parceiro',
      field: 'partnerName',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
        border: '1px solid black',
      },
      render: (rowData) => (
        <ActionNameElement actionName={rowData.partner.name} image={rowData['']} />
      ),
    },
    {
      title: 'Nome da atividade',
      field: 'actionName',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
        border: '1px solid black',
      },
      render: (rowData) => (
        <ActionNameElement actionName={rowData.action.name} image={rowData['']} />
      ),
    },
    {
      title: 'Início da participação',
      field: 'participationStart',
      render: (rowData) => <DateElement date={rowData.participationStartDate} />,
    },
    {
      title: 'Fim da participação',
      field: 'participationEnd',
      render: (rowData) => <DateElement date={rowData.participationEndDate} />,
    },
    {
      title: 'Mais Detalhes',
      field: 'moreDetails',
      render: (rowData) => {
        if (selectedOption === 'waiting') {
          return (
            <ButtonElement
              name="Ver Atividade"
              onClick={() => {
                onRowClick({}, rowData);
              }}
            />
          );
        } else {
          return <ButtonElement disabled center name="--" onClick={() => {}} />;
        }
      },
    },
    {
      title: 'Comunicar parceiro',
      field: 'lala',
      render: (rowData) => (
        <ButtonElement
          background="transparent"
          name="Mensagens"
          onClick={() => {
            props.router.push(`/owner/mensagens-proposta/${rowData.id}`);
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerStyle: {
        fontWeight: 500,
        fontSize: 13,
        color: 'blue',
        whiteSpace: 'nowrap',
      },
      cellStyle: {
        whiteSpace: 'nowrap',
        border: 0,
        padding: 0,
      },
      sorting: false,
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={
              selectedOption === 'waiting'
                ? {
                    namedButtons: [
                      {
                        name: 'Negar proposta',
                        onClick: () => {
                          if (!rowData.action.station_id) {
                            setIdCancel(rowData.id);
                            setOpen(true);
                          } else {
                            props.dispatchErrorMessage();
                          }
                        },
                        color: '#E34848',
                        background: 'transparent',
                      },
                    ],
                    iconButtons: [],
                  }
                : selectedOption === 'refused'
                ? {
                    namedButtons: [
                      {
                        name: 'PROPOSTA NEGADA',
                        onClick: () => {},
                        color: '#7D7D7D',
                        background: 'transparent',
                      },
                    ],
                    iconButtons: [],
                  }
                : selectedOption === 'approved'
                ? {
                    namedButtons: [
                      {
                        name: 'PROPOSTA ACEITA',
                        onClick: () => {},
                        color: '#7D7D7D',
                        background: 'transparent',
                      },
                    ],
                    iconButtons: [],
                  }
                : null
            }
          />
        );
      },
    },
  ];
  const [columns, setColumns] = useState(isMobile ? defaultColumnsMobile : defaultColumns);
  useEffect(() => {
    setColumns(isMobile ? defaultColumnsMobile : defaultColumns);
  }, [selectedOption]);

  const options = [
    { label: 'Esperando aprovação', value: 'waiting' },
    { label: 'Aprovadas', value: 'approved' },
    { label: 'Recusadas', value: 'refused' },
  ];

  function loadData(option) {
    setSelectedOption(option);
  }

  function onSubmit(values) {
    props.remove(idCancel, props.auth.user.establishments[0].stations[0].id, values);
  }

  function renderEmptyScreen() {
    return (
      <section id="actionOffers" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui Propostas de parceiros</h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton onClick={() => props.router.push('/owner/acoes')} color="primary">
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img src={placeholder_illustration} className="img-fluid" alt="Ilustração Cupons" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionOffers.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section>
      <GeneralGrid item xs={12}>
        <Table
          hasToolbar
          defaultSelected={selectedOption}
          toolbarSearch
          filters={filters}
          title="Atividades de parceiros"
          searchPlaceholder={'Pesquisar por nome'}
          columns={columns}
          rows={data}
          dataType={'partners'}
          onChangeOption={loadData}
          selectOptions={options}
          mobileTable={isMobile}
          mobileDropdown
          hasBorder={!isMobile}
        />
      </GeneralGrid>
      {open ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={() => setOpen(false)}
          visible={open}>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <div>
              <Typography>
                Se confirmar, você negará a proposta desse parceiro e não poderá mais ativar, a não
                ser que ele reenvie.
              </Typography>{' '}
              <Typography>
                Se desejar, pode enviar uma mensagem, notificando o parceiro do motivo de recusa.
                Assim, ele poderá fazer as alterações necessárias para reenviar para a sua
                aprovação.
              </Typography>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label="Mensagem para o gestor"
                  name="message"
                  required
                  multiline
                  rowsMax={2}
                  margin="normal"
                />
              </MaterialGrid>
              <PrimaryButton noMargin type="submit" disabled={props.actionOffers.buttonLoading}>
                Sim, quero negar
              </PrimaryButton>
              <SecondaryButton onClick={() => setOpen(false)}>Cancelar</SecondaryButton>
            </div>
          </Form>
        </AlertDialogSlide>
      ) : null}
    </section>
  );
};

ActionOffers.propTypes = {
  actionOffers: PropTypes.shape({
    buttonLoading: PropTypes.any,
    list: PropTypes.shape({
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
  }),
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  dispatchErrorMessage: PropTypes.func,
  getStationActionOffers: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClickAction: PropTypes.func,
  remove: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

ActionOffers = reduxForm({
  form: 'actionOffersForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ActionOffers);

const mapStateToProps = (state) => {
  return {
    actionOffers: state.actionOffers,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getStationActionOffers, remove, onClickAction, dispatchErrorMessage },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionOffers);
