import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import { BASE_S3 } from 'config/consts';
import moment from 'moment';
import Grid from 'common/components/layout/grid/grid';
import { Card, CardText } from 'react-md';
import Title from 'common/components/titles/title';
import Table6 from 'common/components/table/table6';
import LoadingProgress from 'common/components/progress/loading';
import { Box } from '@material-ui/core';
import { getUserActions, getActionById } from './actionsFile';

class Actions extends Component {
  constructor(props) {
    super(props);
    this.props.getUserActions(this.props.router.params.id);
    this.props.getActionById(this.props.router.params.id);

    this.state = {
      columns: [
        { title: 'Nome', field: 'user.name' },
        { title: 'E-mail', field: 'user.email' },
        { title: 'CPF', field: 'user.cpf' },
        { title: 'Data e Horário', field: 'createdAtDate' },
      ],
    };
  }

  renderEmptyPage = () => {
    return (
      <div style={{ margin: 20 }}>
        <div className="row align-items-center ">
          <Grid cols="12 12 6 6">
            <div className="row">
              <Grid cols="12 12 12 12">
                <div className="pl-4">
                  <h2 className="signup-text">Nenhum usuário realizou essa atividade ainda</h2>
                  <p className="">
                    Os membros que houverem realizado essa atividade aparecerão aqui, em uma tabela,
                    para que você confia com facilidade.
                  </p>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid cols="12 12 6 6">
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </Grid>
        </div>
      </div>
    );
  };

  render() {
    const { action, list, loading, loadingPage } = this.props.actions;
    const { columns } = this.state;
    const { router } = this.props;
    if (loading) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    if (loadingPage) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" mt={7}>
          <LoadingProgress />
        </Box>
      );
    } else {
      return (
        <div className="container-fluid">
          <Card
            style={{
              margin: 20,
              boxShadow: 'none',
              border: '1px solid lightgrey',
              borderRadius: 6,
            }}>
            <CardText>
              <div className="row">
                <Grid cols="12 12 6 6">
                  <center>
                    <p>Nome da Atividade: {action.name}</p>
                    <p>Descrição: {action.description}</p>
                    <p>Data de Criação: {moment(action.createdAt).format('DD/MM/YYYY HH:mm')}</p>
                  </center>
                </Grid>
                <Grid cols="12 12 6 6">
                  <center>
                    <img
                      alt=""
                      src={`${BASE_S3}/${action.image}`}
                      className="img-fluid"
                      style={{
                        maxHeight: '10em',
                      }}
                    />
                  </center>
                </Grid>
              </div>
            </CardText>
          </Card>
          <div className="my-5">
            {list.length > 0 ? (
              <div style={{ margin: 20 }}>
                <Title title="Membros que já realizaram essa atividade" />
                <Table6
                  localization={{
                    localization: {
                      toolbar: { searchPlaceholder: 'Procurar' },
                    },
                  }}
                  title="Lista"
                  columns={columns}
                  data={list}
                  router={router}
                />
              </div>
            ) : (
              this.renderEmptyPage()
            )}
          </div>
        </div>
      );
    }
  }
}

Actions.propTypes = {
  actions: PropTypes.shape({
    action: PropTypes.shape({
      createdAt: PropTypes.any,
      description: PropTypes.any,
      image: PropTypes.any,
      name: PropTypes.any,
    }),
    list: PropTypes.shape({
      length: PropTypes.number,
    }),
    loading: PropTypes.any,
    loadingPage: PropTypes.any,
  }),
  getActionById: PropTypes.func,
  getUserActions: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    actions: state.actions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserActions, getActionById }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
