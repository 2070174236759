import PropTypes from 'prop-types';
import React from 'react';

export default function FullButton(props) {
  const { background, text, border, fontSize, classNameProp } = props;
  return (
    <button
      type="button"
      className={`${classNameProp}`}
      style={{
        background,
        color: text,
        border,
        fontSize,
        width: '100%',
        borderRadius: 4,
        height: props.activityPreview ? 52 : null,
      }}
      {...props}>
      {props.children}
    </button>
  );
}

FullButton.propTypes = {
  activityPreview: PropTypes.any,
  background: PropTypes.any,
  border: PropTypes.any,
  children: PropTypes.any,
  classNameProp: PropTypes.any,
  fontSize: PropTypes.any,
  text: PropTypes.any,
};
