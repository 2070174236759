import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import React from 'react';
import { appColors } from '../../../styles/colors';
import LinearDeterminate from '../progress/progress';

const {
  v2: { blue, white, mediumGray },
} = appColors;

export default function Button({
  onClick,
  label,
  buttonStyle,
  labelStyle,
  type = 'primary',
  disabled,
  progress,
  buttonType,
}) {
  if (progress) {
    return (
      <MaterialButton
        onClick={onClick}
        style={{
          padding: '16px 64px',
          backgroundColor: mediumGray,
          border: white,
          borderRadius: 6,
          display: 'block',
        }}
        disabled={Boolean(disabled)}
        type={buttonType}>
        <LinearDeterminate progress={progress} style={{ display: 'block' }} />
        <p
          className="h6"
          style={{
            color: white,
            textTransform: 'initial',
            ...labelStyle,
          }}>
          {label}
        </p>
      </MaterialButton>
    );
  }
  return (
    <MaterialButton
      onClick={onClick}
      style={{
        textTransform: 'none',
        padding: '16px 64px',
        backgroundColor: disabled ? mediumGray : type === 'primary' ? blue : white,
        border: disabled ? 'white' : type === 'primary' ? '' : `1px solid ${blue}`,
        borderRadius: 6,
        ...buttonStyle,
      }}
      type={buttonType}
      disabled={Boolean(disabled)}>
      <p
        className="h6"
        style={{
          color: disabled ? 'white' : type === 'primary' ? white : blue,
          textTransform: 'none',
          ...labelStyle,
        }}>
        {label}
      </p>
    </MaterialButton>
  );
}

Button.propTypes = {
  buttonStyle: PropTypes.any,
  buttonType: PropTypes.any,
  disabled: PropTypes.any,
  label: PropTypes.any,
  labelStyle: PropTypes.any,
  onClick: PropTypes.any,
  progress: PropTypes.any,
  type: PropTypes.string,
};
