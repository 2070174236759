import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import {
  ActionNameElement,
  ColumnsBelowSearch,
  ConditionalElement,
  DateElement,
} from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import moment from 'moment';
import { finishTutorial } from 'pages/auth/authActions';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  dispatchErrorFalse,
  getContactByEstablishmentId,
  getContactsByStationId,
  removeAccess,
} from './contactsActions';
import ContactModal from './modal/main';

function Contacts(props) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (props.auth.user.establishments) {
      if (props.auth.user.establishments.length > 0) {
        if (props.auth.user.establishments[0].stations) {
          if (props.auth.user.establishments[0].stations.length > 0) {
            props.getContactsByStationId(props.auth.user.establishments[0].stations[0].id);
          }
        }
      }
    }
  }, []);

  const { content } = props.contacts.list;

  const defaultColumnsMob = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) => <ActionNameElement actionName={rowData.name} />,
    },
    {
      title: 'Status',
      field: 'rowData.active',
      render: (rowData) =>
        rowData.deletedAt ? 'Suspenso' : rowData.active ? 'E-mail Ativo' : 'Inativo',
    },
    {
      field: 'actions',
      title: '',
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const defaultColumns = [
    {
      title: 'Nome',
      field: 'name',
      render: (rowData) => <ActionNameElement actionName={rowData.name} />,
    },
    {
      title: 'E-mail',
      field: 'email',
    },

    {
      title: 'Data',
      field: 'createdAt',
      // headerCenter: true,
      render: (rowData) => (
        <DateElement date={moment(rowData.createdAt).format('DD/MM/YYYY HH:mm')} />
      ),
    },
    {
      title: 'Status',
      field: 'active',
      render: (rowData) =>
        rowData.deletedAt ? 'Suspenso' : rowData.active ? 'E-mail Ativo' : 'Inativo',
    },
    {
      title: 'Telefone',
      field: 'tel',
      render: (rowData) => (
        <ConditionalElement
          center
          condition={rowData.tel}
          labelTrue={rowData.tel}
          labelFalse={'N/A'}
        />
      ),
    },
    {
      field: 'actions',
      title: '',
      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const columns = isMobile ? defaultColumnsMob : defaultColumns;

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [contact, setContact] = useState({});
  useEffect(() => {
    setData(props.contacts.list.content.slice(0).reverse());
  }, [props.contacts.list.content]);

  useEffect(() => {
    if (props.contacts.error === true) {
      setVisible(true);
    }
  }, [props]);

  function handleDelete(id, resolve, reject) {
    props.removeAccess(
      id,
      resolve,
      reject,
      props.auth.user.establishments[0].stations[0].id,
      setOpenConfirmation
    );
  }

  function renderEmptyScreen() {
    return (
      <section id="contacts" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} justify="center" alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui contatos</h2>
                  <p className="">
                    Seus contatos são as pessoas que são ou não associados da sua empresa. Ao
                    importá-los, você poderá oferecer atividades promocionais para eles.
                  </p>
                  <PrimaryButton onClick={() => props.router.push('/owner/contacts/import')}>
                    Importar contatos
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Imagem de Tabela Vazia"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }
  function renderErrors() {
    return props.contacts.errors.map((item, index) => <p key={index}>{item.email}</p>);
  }

  const finishTutorial = () => {
    props.finishTutorial(props.auth.user.id, props.router, props.auth);
  };

  function onRowClick(e, rowData) {
    setVisibleModal(true);
    setContact(rowData);
  }

  if (props.contacts.loading) {
    return (
      <Box display="flex" justifyContent="center" height="100vh" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }

  const { first_time } = props.location.query;
  return (
    <section id="contacts">
      <GenericConfirmationCard
        cancelClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
        // state={props.contacts}
        loading={props.contacts.loading}
        general={props.general}
        title="Deletar Cadastro"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja remover esse cadastro?"
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            hasToolbar
            title="Cadastrados"
            columns={columns}
            rows={data}
            toolbarSearch
            onRowClick={onRowClick}
            dataType={'duplicateSearch'}
            onChangeOption={() => {}}
            defaultSelected={[]}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
        <AlertDialogSlide
          visible={visible}
          title="Erro, esses e-mails abaixo já estão cadastrados como contatos:"
          onClose={() => {
            setVisible(false);
            props.dispatchErrorFalse();
          }}>
          {renderErrors()}
        </AlertDialogSlide>
        {visibleModal ? (
          <AlertDialogSlide
            visible={visibleModal}
            title=""
            onClose={() => {
              setVisibleModal(false);
            }}
            maxWidth="lg">
            <ContactModal
              router={props.router}
              closeModal={() => setVisibleModal(false)}
              contact={contact}
            />
          </AlertDialogSlide>
        ) : null}
        <AlertDialogSlide visible={first_time} title="Finalize o tutorial">
          <MaterialGrid container spacing={2} direction="row" justify="center" alignItems="center">
            <MaterialGrid item md={6} lg={6}>
              <img alt="" className="img-fluid" src={placeholder_illustration}></img>
            </MaterialGrid>
            <MaterialGrid item md={6} lg={6}>
              <Typography align="center" variant="subtitle1">
                Finalize o tutorial e Comece a usar a plataforma!
              </Typography>
              <Box display="flex" justifyContent="center" mt={3}>
                <PrimaryButton
                  fullWidth={false}
                  type="button"
                  disabled={props.auth.buttonLoading}
                  progress={parseInt(props.general.submitProgress)}
                  onClick={() => finishTutorial()}>
                  {props.auth.buttonLoading ? 'Enviando' : 'Finalizar Tutorial'}
                </PrimaryButton>
              </Box>
            </MaterialGrid>
          </MaterialGrid>
        </AlertDialogSlide>
      </GeneralGrid>
    </section>
  );
}

Contacts.propTypes = {
  auth: PropTypes.shape({
    buttonLoading: PropTypes.any,
    user: PropTypes.shape({
      establishments: PropTypes.shape({
        length: PropTypes.number,
      }),
      id: PropTypes.any,
    }),
  }),
  contacts: PropTypes.shape({
    error: PropTypes.bool,
    errors: PropTypes.shape({
      map: PropTypes.func,
    }),
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
        slice: PropTypes.func,
      }),
    }),
    loading: PropTypes.any,
  }),
  dispatchErrorFalse: PropTypes.func,
  finishTutorial: PropTypes.func,
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getContactsByStationId: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_time: PropTypes.any,
    }),
  }),
  removeAccess: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    contacts: state.contacts,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactByEstablishmentId,
      dispatchErrorFalse,
      finishTutorial,
      getContactsByStationId,
      removeAccess,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
