import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { BASE_S3 } from 'config/consts';
import { uploadImageToS3 } from 'modules/owner/pages/actions/actionsFile';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { appColors } from 'styles/colors';
import PropTypes from 'prop-types';

export function InputFileWithUpload({
  input: { onBlur, onChange, value },
  meta: { touched, error },
  label,
  meta,
  input,
  squareOff,
}) {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  function onDrop(imageFiles) {
    setImageLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = async function (e) {
      const img = new Image();
      img.src = e.target.result;
      img.onload = async function () {
        console.log(img.width, img.height, '1');
        const { width, height } = img;
        if (width !== height && !squareOff) {
          meta.dispatch({
            type: 'SNACKBAR',
            payload: {
              message: 'Erro: Use uma imagem de proporção 1x1, ou seja, quadrada.',
              variant: 'error',
              open: true,
            },
          });
        } else {
          const url = await uploadImageToS3(reader.result);
          onBlur({ base64: url });
          onChange(url);
        }
        setImageLoading(false);
      };
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }

  function dropzoneProps() {
    return {
      accept: 'image/jpeg, image/png, image/gif, image/jpg, image/svg, image/svg+xml',
      multiple: false,
      onDropAccepted: onDrop,
      maxSize: 500000,
      onDropRejected: () =>
        meta.dispatch({
          type: 'SNACKBAR',
          payload: {
            message: 'Erro: Imagem maior que 500kB',
            variant: 'error',
            open: true,
          },
        }),
    };
  }

  return (
    <Box display="flex" flexDirection="column" className={`${touched && error && 'has-error'}`}>
      {value ? (
        <Typography
          align="left"
          variant="caption"
          style={{
            color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
            textAlign: 'left',
          }}>
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps()}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                height: 'auto',
                width: '100%',
                borderWidth: 1,
                borderColor: touched && error ? appColors.error : appColors.dark.level3,
                borderStyle: 'dashed',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: 'white',
              }}>
              {value ? (
                <>
                  <input {...getInputProps()} />
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img src={`${BASE_S3}/${value}`} className="img-fluid" alt="Ícone do Troféu" />
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}>
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}>
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    align="left"
                    style={{
                      textAlign: 'left',
                      color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
                    }}>
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography align="left" variant="caption" style={{ color: appColors.error }}>
            {error}
          </Typography>
        </Box>
      )}
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={imageLoading}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Fazendo upload da imagem...</span>
      </Backdrop>
    </Box>
  );
}

InputFileWithUpload.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.shape({
    dispatch: PropTypes.func,
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  squareOff: PropTypes.any,
};
