import axios from 'axios';
import { initialize } from 'redux-form';
import moment from 'moment';
import { BASE_API, USER_TOKEN } from 'config/consts';
import types from './types';
import statuses from './status';

const USERS_URL = `${BASE_API}/users`;
const PARTICIPANTS_URL = `${BASE_API}/participants`;

export const setStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: types.STATUS_UPDATE,
      payload: status,
    });
  };
};

export const getList = (filters) => {
  const { stationId, shownPerPage, tablePage, nameSearched } = filters;
  return async (dispatch) => {
    try {
      dispatch([
        {
          type: types.STATUS_UPDATE,
          payload: statuses.PENDING,
        },
      ]);
      const response = await axios.get(`${USERS_URL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        params: {
          name: nameSearched,
          station_id: stationId,
          page: tablePage,
          limit: shownPerPage,
          cpf: nameSearched,
          email: nameSearched,
        },
      });
      dispatch([
        {
          type: types.LIST_FETCHED,
          payload: response,
        },
        {
          type: types.STATUS_UPDATE,
          payload: statuses.RESOLVED,
        },
      ]);
    } catch (error) {
      dispatch([
        {
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${
              error.response?.data?.message ||
              error.response?.data?.messages[0] ||
              error.message ||
              ''
            }`,
            variant: 'error',
            open: true,
          },
        },
        {
          type: types.STATUS_UPDATE,
          payload: statuses.REJECTED,
        },
      ]);
    }
  };
};

export const initializeForm = (values) => {
  return initialize('editParticipantForm', values);
};

export const getUser = (userId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.STATUS_UPDATE,
        payload: statuses.PENDING,
      });
      const { data } = await axios.get(`${PARTICIPANTS_URL}/${userId}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });
      const userData = {
        id: data.id,
        name: data.name,
        cpf: data.cpf,
        email: data.email,
        tel: data.tel,
        birthday: moment(data.birthday).format('DD/MM/YYYY'),
        stationHasIntegration: data.station.integration_url ? true : false,
      };
      dispatch([
        {
          type: types.ITEM_DETAIL,
          payload: userData,
        },
        initializeForm({ ...userData }),
        {
          type: types.STATUS_UPDATE,
          payload: statuses.RESOLVED,
        },
      ]);
    } catch (error) {
      dispatch([
        {
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${error.response?.data?.messages[0] || error.message || ''}`,
            variant: 'error',
            open: true,
          },
        },
        {
          type: types.STATUS_UPDATE,
          payload: statuses.REJECTED,
        },
      ]);
    }
  };
};

export const updateUser = (values, redirect) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.BUTTON_LOAD, payload: true });
      const headers = {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      };
      const options = { headers };
      await axios.put(`${USERS_URL}/${values.id}`, values, options);
      dispatch([
        {
          type: 'SNACKBAR',
          payload: {
            message: 'Operação realizada com sucesso',
            variant: 'success',
            open: true,
          },
        },
        { type: types.BUTTON_LOAD, payload: false },
      ]);
      redirect();
    } catch (error) {
      dispatch([
        {
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${
              error.response?.data?.message ||
              error.response?.data?.messages[0] ||
              error.message ||
              ''
            }`,
            variant: 'error',
            open: true,
          },
        },
        { type: types.BUTTON_LOAD, payload: false },
      ]);
    }
  };
};
