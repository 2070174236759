import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Warning from '@material-ui/icons/WarningOutlined';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import logo from 'assets/img/fan_logo.svg';
import Axios from 'axios';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import { BASE_API } from 'config/consts';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { FORM_RULES } from 'helpers/validations';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, initialize, reduxForm } from 'redux-form';

export const validateCode = (
  values,
  type,
  setLoading,
  setIsModalOpen,
  setModalData,
  setBackgroundColor,
  setMessage
) => {
  return (dispatch) => {
    setLoading(true);
    Axios.put(`${BASE_API}/validate-code-with-no-auth/${type}`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Código Validado`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        setIsModalOpen(true);
        setModalData(response.data.item);
        setBackgroundColor('#10875F');
        setMessage('Código Válido');
      })
      .catch((e) => {
        if (
          e.response.data.error === 'Item já validado anteriormente' ||
          e.response.data.error === 'Já passou da data de validação'
        ) {
          setModalData(e.response.data.item);
          setIsModalOpen(true);
          setBackgroundColor('#E34848');
          setMessage(e.response.data.error);
        } else {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.error}`,
              variant: 'error',
              open: true,
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
};

const initializeCode = (code, codeValidator = undefined) => {
  return (dispatch) => {
    if (codeValidator) {
      dispatch(initialize('qrCodeValidatorForm', { code, code_validator: codeValidator }));
    } else {
      dispatch(initialize('qrCodeValidatorForm', { code }));
    }
  };
};

const QrCodeValidator = function (props) {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [modalData, setModalData] = useState({});
  function onSubmit(values) {
    localStorage.setItem('code_validator', values.code_validator);
    props.validateCode(
      values,
      props.router.params.type,
      setLoading,
      setIsModalOpen,
      setModalData,
      setBackgroundColor,
      setMessage
    );
  }
  useEffect(() => {
    const validatorCode = localStorage.getItem('code_validator');
    if (props.router.params?.code && validatorCode) {
      props.initializeCode(props.router.params?.code, validatorCode);
    } else if (props.router.params?.code && !validatorCode) {
      props.initializeCode(props.router.params?.code);
    }
  }, [props.router.params?.code, localStorage.getItem('code_validator')]);
  return (
    <>
      <Container maxWidth="xl" style={{ paddingBottom: '4rem' }}>
        <Box mt={4}>
          <GeneralGrid container spacing={8} justify="center" style={{ background: 'white' }}>
            <GeneralGrid item xs={12}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Link to="/owner">
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 8px',
                      justifyContent: 'center',
                    }}>
                    <img src={logo} alt="Rilato Logo" style={{ width: '100%', height: 'auto' }} />
                  </div>
                </Link>
              </Box>

              <Box display="flex" justifyContent="center" mt={5}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 32,
                  }}>
                  Validador de QR Code
                </Typography>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3} justify="center">
            <GeneralGrid item xs={12}>
              <Box mt={5}>
                <form onSubmit={props.handleSubmit((v) => onSubmit(v))} noValidate>
                  <GeneralGrid container spacing={3} justify="center">
                    <GeneralGrid item xs={8} md={4} lg={3}>
                      <Field
                        component={InputLabelOutlined}
                        label="Código do cliente"
                        name="code"
                        validate={[FORM_RULES.required]}
                        required
                        margin="normal"
                        normalize={handleWhiteSpaces}
                      />
                    </GeneralGrid>
                  </GeneralGrid>
                  <GeneralGrid container spacing={3} justify="center">
                    <GeneralGrid item xs={8} md={4} lg={3}>
                      <Field
                        component={InputLabelOutlined}
                        label="Código do validador"
                        name="code_validator"
                        validate={[FORM_RULES.required]}
                        required
                        margin="normal"
                        normalize={handleWhiteSpaces}
                      />
                    </GeneralGrid>
                  </GeneralGrid>
                  <GeneralGrid container spacing={3} justify="center">
                    <GeneralGrid item xs={8} md={4} lg={3}>
                      <Box display="flex" justifyContent="center" mt={3}>
                        <PrimaryButton
                          disabled={loading}
                          fullWidth={true}
                          style={{ padding: '12px 0' }}
                          router={props.router}
                          type="submit">
                          {loading ? <CircularProgress id="register" /> : 'Validar'}
                        </PrimaryButton>
                      </Box>
                    </GeneralGrid>
                  </GeneralGrid>
                </form>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
        </Box>
        <CustomizedSnackbar />
      </Container>
      <Dialog
        PaperProps={{
          style: {
            minWidth: isMobile ? null : '40%',
          },
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        keepMounted
        fullWidth={!!isMobile}
        maxWidth={isMobile ? 'xl' : 'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent style={{ padding: 0 }}>
          <DialogContent style={{ padding: 20 }}>
            <Typography
              variant="span"
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '20px',
                lineHeight: '43px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.25px',

                color: '#A0A0A0',
              }}>
              {props.router.params.type === 'product'
                ? modalData?.action?.name
                : modalData?.presential_course?.action?.name}
            </Typography>
            {modalData.single_product_orders?.map((item, index) => (
              <Typography
                variant="span"
                key={index}
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '0.25px',
                  color: '#000',
                }}>
                {item?.product?.name}
              </Typography>
            ))}
          </DialogContent>
          <DialogContent style={{ padding: '0' }}>
            <Box
              style={{
                width: '100%',
                backgroundColor: backgroundColor,
                height: 272,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                alignContent: 'center',
              }}>
              {backgroundColor === '#E34848' ? (
                <Warning style={{ color: '#fff', fontSize: 50 }} />
              ) : (
                <CheckCircle style={{ color: '#fff', fontSize: 50 }} />
              )}
              <Typography
                variant="span"
                style={{
                  color: '#fff',
                  fontFamily: 'Lato',
                  textAlign: 'center',
                  fontSize: 20,
                }}>
                {message}
              </Typography>
            </Box>
          </DialogContent>

          <DialogContent style={{ padding: 20 }}>
            <Typography
              variant="span"
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '20px',
                lineHeight: '43px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.25px',

                color: '#A0A0A0',
              }}>
              #{modalData?.id}
            </Typography>
            <Typography
              variant="span"
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '20px',
                lineHeight: '43px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.25px',

                color: '#000',
              }}>
              {modalData?.user?.name}
            </Typography>
            {modalData.validation_date && (
              <>
                {backgroundColor === '#E34848' && (
                  <Typography
                    variant="span"
                    style={{
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      letterSpacing: '0.25px',

                      color: '#A0A0A0',
                    }}>
                    última validação
                  </Typography>
                )}
                <Typography
                  variant="span"
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    letterSpacing: '0.25px',

                    color: backgroundColor,
                  }}>
                  {moment(modalData.validation_date).format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
                <Typography
                  variant="span"
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    letterSpacing: '0.25px',

                    color: '#A0A0A0',
                  }}>
                  {modalData.code_validator?.code}
                </Typography>
              </>
            )}
          </DialogContent>
        </DialogContent>
      </Dialog>
    </>
  );
};

QrCodeValidator.propTypes = {
  handleSubmit: PropTypes.func,
  initializeCode: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.any,
      type: PropTypes.string,
    }),
  }),
  validateCode: PropTypes.func,
};

const QrCodeValidatorForm = reduxForm({ form: 'qrCodeValidatorForm' })(QrCodeValidator);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ initializeCode, validateCode }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeValidatorForm);
