import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: 'auto',
    display: 'block',
  },
  line: {
    backgroundColor: '#52C22C33',
  },
  bar: {
    backgroundColor: '#52C22C',
  },
});

export default function LinearDeterminate(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress classes={{ root: classes.line, bar: classes.bar }} />
    </div>
  );
}
