import PropTypes from 'prop-types';
import React from 'react';

export default function MoneyPig(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '34'}
      height={props.height || '34'}
      viewBox="0 0 34 34"
      // fill={props.color || '#000'}
    >
      <g id="Group_77" data-name="Group 77" transform="translate(-307 -1105)">
        <circle
          id="Ellipse_115"
          data-name="Ellipse 115"
          cx="3.556"
          cy="3.556"
          r="3.556"
          transform="translate(320.444 1106)"
          strokeWidth="2"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <circle
          id="Ellipse_116"
          data-name="Ellipse 116"
          cx="0.593"
          cy="0.593"
          r="0.593"
          transform="translate(332.889 1121.407)"
          strokeWidth="2"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Path_1046"
          data-name="Path 1046"
          d="M181.3,369.7a12.45,12.45,0,0,0-4.148-.7,12.642,12.642,0,0,0-4.148.692"
          transform="translate(146.852 746.482)"
          fill="none"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1047"
          data-name="Path 1047"
          d="M155.37,382.37A2.37,2.37,0,0,1,153,380"
          transform="translate(155 742)"
          fill="none"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1049"
          data-name="Path 1049"
          d="M186.037,375.413h-.748a1.19,1.19,0,0,1-1.166-1,10.416,10.416,0,0,0-3.114-5.687.591.591,0,0,1-.165-.572l.792-3.03a.593.593,0,0,0-.718-.725l-4.655,1.164a1.173,1.173,0,0,1-.65-.026,16.079,16.079,0,0,0-4.984-.788c-7.528,0-13.63,5.041-13.63,11.259a9.9,9.9,0,0,0,2.427,6.4,4.765,4.765,0,0,1,1.129,3.064v3.573a.593.593,0,0,0,.593.593H164.7a.593.593,0,0,0,.593-.593v-1.86a.589.589,0,0,1,.747-.571,16.434,16.434,0,0,0,8.028.29.589.589,0,0,1,.706.581v1.561a.593.593,0,0,0,.593.593h3.556a.593.593,0,0,0,.593-.593v-2.372a3.555,3.555,0,0,1,1.883-3.138l4.6-2.452a1.185,1.185,0,0,0,.628-1.046v-4.03A.593.593,0,0,0,186.037,375.413Z"
          transform="translate(153.37 748.364)"
          fill="none"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1050"
          data-name="Path 1050"
          d="M164.3,390.283A8.054,8.054,0,0,1,161,384"
          transform="translate(151.741 740.37)"
          fill="none"
          stroke={props.color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

MoneyPig.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
