import React from 'react';
import { Route, IndexRoute } from 'react-router';
import News from './news';
import NewsForm from './newsForm';

export default (
  <Route path="noticias">
    <IndexRoute component={(props) => <News {...props} />} />
    <Route path="novo" component={(props) => <NewsForm {...props} />} />
    <Route path=":id/:action" component={(props) => <NewsForm {...props} />} />
  </Route>
);
