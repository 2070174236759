import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { BASE_S3 } from 'config/consts';
import { uploadImageToS3 } from 'modules/owner/pages/actions/actionsFile';
import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import AlertDialogSlide from '../../dialog/dialog';
import { getCroppedImg } from './canvasUtils';
import styles from './styles';

const InputImage = ({
  classes,
  meta: { touched, error },
  input: { onChange, value },
  label,
  meta,
  aspect,
  aspectLabel,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setImageLoading(true);
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      console.log('donee', { croppedImage });
      setCroppedImage(croppedImage);
      setOpen(false);
      const url = await uploadImageToS3(croppedImage);
      onChange(url);

      setImageLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, onChange]);

  const onFileChange = async (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      const imageDataUrl = await readFile(file);
      setOpen(true);
      setImageSrc(imageDataUrl);
    }
  };
  function dropzoneProps() {
    return {
      accept: 'image/jpeg, image/png, image/gif',
      multiple: false,
      onDropAccepted: onFileChange,
      maxSize: 500000,
      onDropRejected: () =>
        meta.dispatch({
          type: 'SNACKBAR',
          payload: {
            message: 'Erro: Imagem maior que 500kB',
            variant: 'error',
            open: true,
          },
        }),
    };
  }

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={imageLoading}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Fazendo upload da imagem...</span>
      </Backdrop>
      <div className={`form-group ${touched && error && 'has-error'}`}>
        <Box display="flex">
          <Dropzone {...dropzoneProps()}>
            {({ getRootProps, getInputProps }) => (
              <Box
                display="flex"
                flexGrow={1}
                {...getRootProps()}
                style={{
                  height: 'auto',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: touched && error ? 'red' : 'gray',
                  borderStyle: 'dashed',
                  borderRadius: 5,
                  cursor: 'pointer',
                  backgroundColor: 'white',
                }}>
                {croppedImage ? (
                  <>
                    <input {...getInputProps()} />

                    <img src={croppedImage} className="img-fluid" alt="Ícone do Troféu" />
                  </>
                ) : value ? (
                  <>
                    <input {...getInputProps()} />
                    <img src={`${BASE_S3}/${value}`} className="img-fluid" alt="Ícone do Troféu" />
                  </>
                ) : (
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={3}>
                    <input {...getInputProps()} />
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: `white`,
                        height: 70,
                        width: 70,
                      }}>
                      <CloudUploadIcon
                        style={{
                          fontSize: 16,
                          color: touched && error ? 'red' : 'rgb(102, 102, 102)',
                        }}
                        className=""
                      />
                    </Box>

                    <Typography
                      variant="caption"
                      align="left"
                      style={{
                        textAlign: 'left',
                        color: touched && error ? 'red' : 'rgb(102, 102, 102)',
                      }}>
                      {label}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Dropzone>
        </Box>
        {imageSrc ? (
          <AlertDialogSlide
            visible={open}
            onClose={() => setOpen(false)}
            title={`${'Edite e corte da forma que preferir, na proporção '}${aspectLabel}`}
            maxWidth="sm">
            <>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography variant="span">Ajustar Zoom</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    classes={{ container: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>

                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}>
                  Finalizar Edição
                </Button>
              </div>
            </>
          </AlertDialogSlide>
        ) : null}
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    </>
  );
};

InputImage.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  aspect: PropTypes.any,
  aspectLabel: PropTypes.any,
  classes: PropTypes.shape({
    controls: PropTypes.any,
    cropButton: PropTypes.any,
    cropContainer: PropTypes.any,
    slider: PropTypes.any,
    sliderContainer: PropTypes.any,
  }),
  label: PropTypes.any,
  meta: PropTypes.shape({
    dispatch: PropTypes.func,
    touched: PropTypes.func,
    error: PropTypes.func,
  }),
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const Styled = withStyles(styles)(InputImage);

export default Styled;
