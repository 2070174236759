import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { appColors } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  input: {
    display: 'none',
  },
  primary: {
    color: appColors.primary,
    borderColor: appColors.primary,
    backgroundColor: appColors.light.level1,
    '&:hover': {
      borderColor: appColors.dark.level2,
      backgroundColor: appColors.light.level2,
    },
  },
}));

export default function SecondaryButton(props) {
  const classes = useStyles();

  return (
    <Button variant="outlined" className={clsx(classes.button, classes.primary)} {...props}>
      {props.children}
    </Button>
  );
}

SecondaryButton.propTypes = {
  children: PropTypes.any,
};
