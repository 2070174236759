import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Field, getFormValues, reduxForm, formValueSelector } from 'redux-form';
import { isMobile } from 'react-device-detect';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { dateMask, cpfMask, phoneMask } from 'helpers/masks';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import EmptyScreen from './components/EmptyScreen';
import Loading from './components/Loading';
import { getUser, updateUser } from './participantsActions';
import statuses from './status';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const EditParticipant = (props) => {
  const {
    detail: { stationHasIntegration },
    status,
    buttonLoading,
  } = props.participants;
  const { id: participantId } = props.params;

  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    props.getUser(participantId);
  }, []);

  useEffect(() => {
    setData(props.participants.detail);
  }, [props.participants.detail]);

  const redirectBack = () => {
    props.router.push('/owner/participantes');
  };

  const onSubmit = () => {
    props.updateUser(props.formValues, redirectBack);
  };

  if (status === statuses.PENDING) return <Loading />;
  if (status === statuses.RESOLVED && !data.id) return <EmptyScreen page="editParticipant" />;
  if (status === statuses.REJECTED) return <EmptyScreen page="error" />;

  return (
    <GeneralGrid container spacing={3}>
      <GeneralGrid item container xs={12} justifyContent="center">
        <GeneralGrid item xs={isMobile ? 12 : 6}>
          <Paper elevation={3} className={classes.paper}>
            <Form onSubmit={props.handleSubmit(onSubmit)}>
              <Box mt={2} mb={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome"
                  type="text"
                  name="name"
                  disabled={stationHasIntegration}
                />
              </Box>
              <Box mb={4}>
                <Field
                  component={InputLabelOutlined}
                  label="CPF"
                  type="text"
                  name="cpf"
                  disabled={stationHasIntegration}
                  validate={[FORM_RULES.cpf]}
                  {...cpfMask}
                />
              </Box>
              <Box mb={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Telefone"
                  type="text"
                  name="tel"
                  disabled={
                    stationHasIntegration &&
                    !_.isEmpty(props.telValue) &&
                    props.telValue.length >= 11
                  }
                  {...phoneMask}
                />
              </Box>
              <Box mb={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Data de Nascimento"
                  type="text"
                  name="birthday"
                  {...dateMask}
                />
              </Box>
              <Box mb={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Email"
                  type="text"
                  name="email"
                  disabled
                />
              </Box>
              <Box className={classes.buttonsBox}>
                <SecondaryButton type="button" onClick={redirectBack}>
                  Cancelar
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  loading={buttonLoading}
                  disabled={buttonLoading}
                  loadingSize={25}>
                  {stationHasIntegration ? 'Buscar dados e Enviar' : 'Enviar'}
                </PrimaryButton>
              </Box>
            </Form>
          </Paper>
        </GeneralGrid>
      </GeneralGrid>
    </GeneralGrid>
  );
};

const selector = formValueSelector('editParticipantForm');

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    participants: state.participants,
    general: state.general,
    formValues: getFormValues('editParticipantForm')(state),
    telValue: selector(state, 'tel'),
    birthdayValue: selector(state, 'birthday'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUser,
      updateUser,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'editParticipantForm', destroyOnUnmount: true })(EditParticipant));
