import _ from 'lodash';
import React from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

export class ColorPickerInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
      color: '#fff',
    };

    this.toggleColor = this.toggleColor.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!_.isUndefined(this.props.input.value)) {
      this.setState({ color: this.props.input.value });
    }
  }

  handleChangeComplete(color, event) {
    const { onColorChange } = this.props;
    this.setState({ color: color.hex });
    onColorChange(color.hex);
  }

  toggleColor(e) {
    e.preventDefault();
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;

    return (
      <div
        className={`form-group ${touched && error && 'has-error'}`}
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}>
        {this.props.labelDisabled ? (
          <></>
        ) : (
          <>
            {touched && error ? (
              <label className="control-label">{this.props.label}</label>
            ) : (
              <label htmlFor={this.props.name}>{this.props.label}</label>
            )}
          </>
        )}
        <center>
          <div>
            <SketchPicker
              color={this.state.color}
              onChangeComplete={this.handleChangeComplete}
              disableAlpha
              width={250}
            />
          </div>
        </center>
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

ColorPickerInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }),
  label: PropTypes.any,
  labelDisabled: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.any,
  }),
  name: PropTypes.any,
  onColorChange: PropTypes.func,
};
