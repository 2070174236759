import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import { USER_TOKEN, BASE_API } from 'config/consts';

import _ from 'lodash';

import moment from 'moment';
import types from './types';

const URL = `${BASE_API}/schedules/`;
const URL_DAYS = `${BASE_API}/days/`;

export const getList = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: true,
    });

    axios
      .get(`${URL}?where[station_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const remove = (id, stationId) => {
  return (dispatch) => {
    axios
      .delete(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        toastr.success('Sucesso', 'Operação realizada com sucesso.');

        dispatch([
          {
            type: types.LIST_LOAD,
            payload: true,
          },
          getList(stationId),
        ]);
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error(i, e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((scheduleResponse) => {
        const initialValues = {
          ...scheduleResponse.data[0],
        };

        axios
          .get(`${URL_DAYS}${scheduleResponse.data[0].day_id}`, {
            headers: {
              Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
            },
          })
          .then((dayResponse) => {
            const temporaryString = `${dayResponse.data[0].description.replace('/', '-')}`;
            initialValues.start = `${moment(
              `${temporaryString.replace('/', '-')}T${scheduleResponse.data[0].start}`
            ).format('YYYY-MM-DDTHH:mm')}`;
            initialValues.end = `${moment(
              `${temporaryString.replace('/', '-')}T${scheduleResponse.data[0].end}`
            ).format('YYYY-MM-DDTHH:mm')}`;

            dispatch([
              {
                type: types.ITEM_DETAIL,
                payload: { data: initialValues },
              },
              initializeForm(initialValues),
            ]);
          });
      });
  };
};

export const setDetail = (values) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };

    axios
      .get(`${URL_DAYS}${initialValues.day_id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((dayResponse) => {
        const temporaryString = `${dayResponse.data[0].description.replace('/', '-')}`;
        initialValues.start = `${moment(
          `${temporaryString.replace('/', '-')}T${initialValues.start}`
        ).format('YYYY-MM-DDTHH:mm')}`;
        initialValues.end = `${moment(
          `${temporaryString.replace('/', '-')}T${initialValues.end}`
        ).format('YYYY-MM-DDTHH:mm')}`;

        dispatch([
          {
            type: types.ITEM_DETAIL,
            payload: { data: initialValues },
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const initializeForm = (values) => {
  return initialize('schedulesForm', values);
};

export const reset = () => {
  return resetForm('schedulesForm');
};

export const create = (values, router) => {
  return submit(values, 'post', router);
};

export const update = (values, router) => {
  const newValues = { ...values };
  newValues.start = moment(newValues.start).format('HH:mm');
  newValues.end = moment(newValues.end).format('HH:mm');

  return submit(newValues, 'put', router);
};

function submit(values, method, router = undefined) {
  values.id = values.id ? parseInt(values.id) : null;
  const id = values.id ? `${values.id}/` : '';
  return (dispatch) => {
    dispatch([
      {
        type: types.BUTTON_LOAD,
        payload: true,
      },
    ]);
    axios[method](`${URL}${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    })
      .then((response) => {
        dispatch([
          {
            type: types.BUTTON_LOAD,
            payload: false,
          },
        ]);
        toastr.success('Sucesso', 'Operação realizada com sucesso.');
        if (!_.isUndefined(router)) {
          router.push('/owner/content/calendar');
        }
      })
      .catch((e) => {
        dispatch([
          {
            type: types.BUTTON_LOAD,
            payload: false,
          },
        ]);
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error(i, e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
}

export const setLoading = (bool) => {
  return (dispatch) => {
    dispatch({
      type: types.LIST_LOAD,
      payload: false,
    });
  };
};
