import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Support from './support';
import SupportForm from './supportForm';

export default (
  <Route path="suporte">
    <IndexRoute component={(props) => <Support {...props} />} />
    <Route path=":id/:action" component={(props) => <SupportForm {...props} />} />
  </Route>
);
