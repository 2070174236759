import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import { getActionShareHistory } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export function StatementBuyPoints(props) {
  const { id } = props.action;
  const { router } = props;

  const [columns] = useState([
    { field: 'type.name', title: 'Tipo' },
    {
      title: 'Usuário',
      render: (rowData) =>
        rowData.user_received
          ? rowData.user_received.name
            ? rowData.user_received.name
            : 'N/A'
          : 'N/A',
    },
    {
      title: 'E-mail',
      render: (rowData) => (rowData.single_email ? rowData.single_email : 'N/A'),
    },
    {
      title: 'E-mail Aberto',
      render: (rowData) => (rowData.opened ? 'Sim' : 'Não'),
    },
    { field: 'createdAt', title: 'Data', type: 'date' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getActionShareHistory(id);
  }, []);

  useEffect(() => {
    setData(props.activeActions.historyShare);
  }, [props.activeActions.historyShare]);

  function handleChange(e) {
    setData(tableSearch(e, props.activeActions.historyShare));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Essa atividade ainda não foi divulgada</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.activeActions.loadingHistoryShare) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (props.activeActions.historyShare.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3} justify="space-between" alignContent="space-between">
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}>
                Histórico de divulgações
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.activeActions.historyShare.length}{' '}
                {props.activeActions.historyShare.length > 1 ? 'Envios' : 'Envio'}
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6 title="Extrato" columns={columns} data={data} router={router} />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

StatementBuyPoints.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.any,
  }),
  activeActions: PropTypes.shape({
    historyShare: PropTypes.shape({
      length: PropTypes.number,
    }),
    loadingHistoryShare: PropTypes.any,
  }),
  getActionShareHistory: PropTypes.func,
  router: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionShareHistory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementBuyPoints);
