import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Categories from './categories';
import CategoriesForm from './categoriesForm';

export default (
  <Route path="minhas-categorias">
    <IndexRoute component={(props) => <Categories {...props} />} />
    <Route path="novo" component={(props) => <CategoriesForm {...props} />} />
    <Route path=":id/:action" component={(props) => <CategoriesForm {...props} />} />
  </Route>
);
