import axios from 'axios';
import { reset as resetForm, initialize, destroy } from 'redux-form';

import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import types from './types';

const URL_RESEARCH = `${BASE_API}/research`;
const URL_ACTION = `${BASE_API}/actions`;

export const submitAction = (
  actionValues,
  router = undefined,
  create_or_update,
  id = undefined,
  publishAction
) => {
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    if (create_or_update === 'create') {
      axios
        .post(`${URL_ACTION}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
              },
            ]),
        })
        .then((actionResponse) => {
          // console.log(actionResponse)
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
            {
              type: 'SNACKBAR',
              payload: {
                message: 'Operação realizada com sucesso',
                variant: 'success',
                open: true,
              },
            },
            destroyForm(),
          ]);
          router.push('/owner');
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else if (create_or_update === 'update') {
      axios
        .put(`${URL_ACTION}/${id}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((actionResponse) => {
          if (publishAction) {
            dispatch([publishAction()]); // dispatch só pra retornar na função
          } else {
            dispatch([
              { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
              destroyForm(),
            ]);
            if (router) {
              router.push('/owner/acoes/minhas-atividades');
            }
          }
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    }
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.CONTACTS_SURVEY_LOAD,
      payload: 'pending',
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const { target } = response.data;
        const categories = response.data.category_actions?.map((item) => ({
          id: item.category?.id,
          title: item.category?.name,
        }));
        const groupings = response.data.action_grouping?.map((item) => ({
          id: item.grouping?.id,
          title: item.grouping?.name,
        }));
        const obj = response.data;

        const newQuestionsList = obj.researches[0]?.questions?.map((question, index) => ({
          ...question,
          options: question.options.map((item) => ({
            label: { text: item.label, id: item.id },
            correct: item.correct,
          })),
          question: { id: question.id, text: question.question },
          type: question.type_id,
        }));
        const error_count_as_participation = obj.researches[0]?.error_count_as_participation;

        const initialValues = {
          ...obj,
          isElection: obj.researches[0]?.is_election,
          categories_field: categories,
          groupings_field: groupings,
          from: target.age ? target.age.from : null,
          to: target.age ? target.age.to : null,
          error_count_as_participation,
          states: target.states,
          entities: target.entities,
          videoIdField:
            response.data.action_videos.length > 0 ? response.data.action_videos[0].video_id : null,
          associated: target.associated,
          internal: target.internal,
          segmentation_type: target.segment_users ? 'users' : 'public',
          segment_users: target.segment_users || [],
          questions: newQuestionsList,
          has_giveaway: response.data.giveaway_number_rules.length > 0,
          giveaway_quantity:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].quantity
              : null,
          giveaway_id:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].giveaway_id
              : null,
          isThereTrophy: !!response.data.trophy_description,
        };
        if (!initialValues.points || parseFloat(initialValues.points) === 0) {
          initialValues.isPaid = false;
        } else {
          initialValues.isPaid = true;
        }
        dispatch([
          initializeForm(initialValues),
          {
            type: types.CONTACTS_SURVEY_DETAIL,
            payload: obj,
          },
        ]);
      })
      .catch((e) => {
        dispatch({
          type: types.CONTACTS_SURVEY_LOAD,
          payload: 'rejected',
        });
      });
  };
};

export const initializeForm = (values) => {
  return initialize('contactsSurveysForm', values);
};

export const submitResearch = (researchValues, router = undefined, typeAction) => {
  return (dispatch) => {
    dispatch({ type: types.CONTACTS_SURVEY_LOAD, payload: true });
    axios
      .post(`${URL_RESEARCH}`, researchValues, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((researchResponse) => {
        if (!_.isUndefined(router)) {
          router.push({
            pathname: `/owner/acoes/aquisicao-clientes/pesquisa/form/acao/${researchResponse.data.ok[0].research_id}`,
            search: `?type=${typeAction}`,
          });
        }
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getResearchemplates = () => {
  return (dispatch) => {
    dispatch({ type: types.RESEARCH_TEMPLATES_LOAD, payload: true });
    axios
      .get(`${BASE_API}/action-templates/?where[type_action_id]=7&&where[type_action_id]=8`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: types.RESEARCH_TEMPLATES_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const reset = () => {
  return resetForm('contactsSurveysForm');
};

export const dispatchFormReset = () => {
  return (dispatch) => {
    dispatch(destroy('contactsSurveysForm'));
  };
};

export const destroyForm = () => {
  return destroy('contactsSurveysForm');
};

export const savePartialy = (values, router, setValue, value) => {
  const { id } = router.params;
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ACTION}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round((progressEvent.loaded * 100) / progressEvent.total)} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          setValue(value + 1),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
