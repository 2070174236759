import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ButtonPrimary from 'common/components/buttons/primaryButton';
import ButtonSecondary from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import Table from 'common/components/table/table-material';
import { NameElement } from 'common/components/table/tableComponents';
import { BASE_S3 } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  deactivateClassifiedAds,
  getClassifiedAdDetail,
  getStationClassifiedAds,
} from '../moderatorActions';
import Container from './styles';

function ClassifiedAds(props) {
  const [deleteInfo, setDeleteInfo] = useState({ visible: false });
  const [detailInfo, setDetailInfo] = useState({ images: [], visible: false });

  useEffect(() => {
    props.getStationClassifiedAds(props.auth.user.establishments[0].stations[0].id);
  }, []);

  return (
    <>
      <Container>
        <Table
          columns={[
            {
              field: 'title',
              title: 'Título',
              render: (rowData) => <NameElement name={rowData.title ? rowData.title : ''} />,
            },
            {
              field: 'description',
              title: 'Descrição',
              render: (rowData) => (
                <NameElement name={rowData.description ? rowData.description : ''} />
              ),
            },
            { field: 'user.name', title: 'Usuário' },
            {
              title: 'Detalhe',
              render: (rowData) => {
                return (
                  <div>
                    <IconButton
                      onClick={() => {
                        setDetailInfo({ ...rowData, visible: true });
                      }}
                      style={{ color: '#2d95ef' }}
                      aria-label="Rejeitar">
                      <SearchIcon />
                    </IconButton>
                  </div>
                );
              },
            },
            {
              title: 'Ativar/Desativar',
              render: (rowData) => {
                return (
                  <div>
                    <span
                      onClick={() => {
                        setDeleteInfo({
                          id: rowData.id,
                          station_id: rowData.station_id,
                          active: rowData.active,
                          user_name: rowData.user.name,
                          visible: true,
                        });
                      }}
                      style={{ color: '#2d95ef', cursor: 'pointer' }}
                      aria-label="Rejeitar">
                      {rowData.active ? 'Desativar' : 'Ativar'}
                    </span>
                  </div>
                );
              },
            },
          ]}
          rows={props.moderator.classifiedList}
        />
      </Container>
      {/* Container de detalhe da do anuncio */}
      <AlertDialogSlide
        visible={detailInfo.visible}
        onClose={() => setDetailInfo((state) => ({ ...state, visible: false }))}
        title="Anúncio">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold', marginBottom: 2 }}>Status:</Typography>
            <Typography style={{ marginBottom: 4 }}>
              {detailInfo.active ? 'Online' : 'Offline'}
            </Typography>
            <Typography style={{ fontWeight: 'bold', marginBottom: 2 }}>Titulo:</Typography>
            <Typography style={{ marginBottom: 4 }}>{detailInfo.title}</Typography>
            <Typography style={{ fontWeight: 'bold', marginBottom: 2 }}>Descrição:</Typography>
            <Typography style={{ marginBottom: 4 }}>{detailInfo.description}</Typography>
            <Typography style={{ fontWeight: 'bold', marginBottom: 4 }}>Imagens:</Typography>
            {detailInfo.images.length > 0 ? (
              detailInfo.images.map((item, index) => (
                <img
                  key={index}
                  style={{ height: 90, width: 160, marginRight: 20 }}
                  src={`${BASE_S3}/${item.image}`}
                  alt=""
                />
              ))
            ) : (
              <Typography>Anúncio sem imagens</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold', marginBottom: 4 }}>Comentários:</Typography>
            {detailInfo.comments ? (
              <Table
                columns={[
                  { field: 'description', title: 'Comentário' },
                  { field: 'user.name', title: 'Usuário' },
                ]}
                rows={detailInfo.comments}
              />
            ) : null}
          </Grid>
        </Grid>
      </AlertDialogSlide>

      {/* Container de delete */}
      <AlertDialogSlide
        visible={deleteInfo.visible}
        onClose={() => setDeleteInfo((state) => ({ ...state, visible: false }))}
        maxWidth="sm"
        hiddenTitle>
        <Typography style={{ padding: 16 }}>
          {`Você ira ${deleteInfo.active ? 'desativar' : 'ativar'} o anúncio de ${
            deleteInfo.user_name
          } tem certeza que deseja
          prosseguir?`}
        </Typography>
        <ButtonSecondary onClick={() => setDeleteInfo((state) => ({ ...state, visible: false }))}>
          Cancelar
        </ButtonSecondary>
        <ButtonPrimary
          onClick={() => {
            props.deactivateClassifiedAds(deleteInfo.id, !deleteInfo.active, deleteInfo.station_id);
            setDeleteInfo((state) => ({ ...state, visible: false }));
          }}>
          {deleteInfo.active ? 'Desativar' : 'Ativar'}
        </ButtonPrimary>
      </AlertDialogSlide>
    </>
  );
}

ClassifiedAds.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  deactivateClassifiedAds: PropTypes.func,
  getStationClassifiedAds: PropTypes.func,
  moderator: PropTypes.shape({
    classifiedList: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    moderator: state.moderator,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getStationClassifiedAds, getClassifiedAdDetail, deactivateClassifiedAds },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedAds);
