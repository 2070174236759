import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { initialize, reset as resetForm } from 'redux-form';

const URL_ACTION_PAGE = `${BASE_API}/offer-to-station`;
const URL_ACTION_ARCHIVE = `${BASE_API}/negar-action-offer`;

export const getStationActionOffers = (id) => {
  return (dispatch) => {
    dispatch({ type: 'ACTION_OFFERS_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (const i in response.data) {
          response.data[i].createdAtDate = moment(response.data[i].createdAt).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i].participationStartDate = moment(
            response.data[i].action.participationStart
          ).format('DD/MM/YYYY HH:mm');
          response.data[i].participationEndDate = moment(
            response.data[i].action.participationEnd
          ).format('DD/MM/YYYY HH:mm');
        }
        dispatch([
          {
            type: 'ACTION_OFFERS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const onClickAction = (rowData, router = undefined) => {
  return (dispatch) => {
    const { action } = rowData;
    if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/${action.id}/proposta`);
    } else if (action.type_id === 6 || action.type_id === 8) {
      router.push(`/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/proposta`);
    } else if (action.type_id === 4) {
      router.push(`/owner/acoes/curso-presencial/${action.id}/proposta`);
    } else if (action.type_id === 14) {
      router.push(`/owner/acoes/entregar-produto/${action.id}/proposta`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/${action.id}/proposta`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/${action.id}/proposta`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/${action.id}/proposta`);
    }
  };
};

export const reset = (form) => {
  return resetForm(form);
};

export const initializeForm = (form, values) => {
  return initialize(form, values);
};

export const remove = (id, station_id, values) => {
  return (dispatch) => {
    axios
      .put(`${URL_ACTION_ARCHIVE}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        try {
          if (error.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${error.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do servidor`,
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};
