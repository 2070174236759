import { getListTransform, getDetailTransform } from 'helpers/transformResponse';
import types from './types';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  listTemplates: {
    content: [],
  },
  detail: {},
  loading: false,
  loadingTemplates: false,
  objTemplate: {
    questions: [],
  },
  buttonLoading: false,
  status: 'idle',
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RIGHT_ANSWER_SURVEYS_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case types.RIGHT_ANSWER_SURVEY_DETAIL:
      return {
        ...state,
        detail: getDetailTransform(action.payload) || INITIAL_STATE.detail,
        loading: false,
        status: 'resolved',
      };
    case types.RIGHT_ANSWER_SURVEY_LOAD:
      return {
        ...state,
        loading: action.payload,
        status: action.payload,
      };
    case types.RESEARCH_TEMPLATES_FETCHED:
      return {
        ...state,
        listTemplates: getListTransform(action.payload.data) || INITIAL_STATE.listTemplates,
        loadingTemplates: false,
      };
    case types.RESEARCH_TEMPLATES_LOAD:
      return {
        ...state,
        loadingTemplates: action.payload,
      };

    case 'TEMPLATE_RESEARCH_PARSED':
      return {
        ...state,
        objTemplate: action.payload,
      };
    case types.RIGHT_ANSWER_SURVEY_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
