import PropTypes from 'prop-types';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, InfoContainer, ItemImage, Prices, RadioSelect, SeeMore, Title } from './styles';

const ItemCard = function ({ data, colors }) {
  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);
  if (!colors) {
    return null;
  }
  return (
    <>
      <Container>
        <ItemImage image={data.image} />
        <InfoContainer>
          <Title>{data.name}</Title>
          <SeeMore colors={colors}>Ver mais</SeeMore>
          <section>
            <Prices colors={colors}>
              <span>{discountPrice}</span>
              {data.discount_value > 0 && <span>{price}</span>}
            </Prices>
            <RadioSelect colors={colors}>
              <label>
                <input type="radio" checked={false} name="product" value={data.id} />
                <span>
                  <section />
                </span>
              </label>
            </RadioSelect>
          </section>
        </InfoContainer>
      </Container>
    </>
  );
};

ItemCard.propTypes = {
  colors: PropTypes.any,
  data: PropTypes.shape({
    discount_value: PropTypes.number,
    id: PropTypes.any,
    image: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    colors: JSON.parse(state.auth.user.establishments[0].stations[0].jsonData).objectNewItemsBody,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
