import styled from 'styled-components';

const GeneralTitle = styled.h1`
  font-family: 'Lato';
  font-size: ${(props) => (props.xs || props.sm ? '1.5rem' : '2rem')};
  font-weight: 700;
  line-height: ${(props) =>
    props.isPreview ? '2.5rem' : props.xs || props.sm ? '1.7rem' : '2.5rem'};
  /* margin: 0 0 .5rem 0; */
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '.5rem')};
  color: #525252;
`;

export default GeneralTitle;
