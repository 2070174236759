import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './categoriesActions';

export function Categories(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const { router } = props;
  const { list, loading } = props.categories;

  const [columns] = useState([
    { field: 'name', title: 'Nome' },
    {
      title: 'Tipo',
      render: (rowData) => (rowData.station_id ? 'Personalizada' : 'Padrão do sistema'),
    },
    {
      title: 'Editar',
      render: (rowData) =>
        rowData.station_id ? (
          <Typography
            onClick={() => router.push(`/owner/minhas-categorias/${rowData.id}/editar`)}
            color="primary"
            style={{ cursor: 'pointer' }}>
            Editar
          </Typography>
        ) : (
          'Você não pode editar as categorias padrão do sistema'
        ),
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].stations[0].id);
  }, []);

  useEffect(() => {
    setData(props.categories.list.content);
  }, [props.categories.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não fez nenhuma categoria</h2>
                <PrimaryButton onClick={() => props.router.push('/owner/minhas-categorias/novo')}>
                  Criar Categoria
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="categories">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} style={{ width: isMobile ? '90vw' : undefined }}>
          <Table
            hasToolbar={true}
            toolbarSearch
            defaultSelected={{}}
            title="Categorias"
            columns={columns}
            rows={data}
            compressedTable
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <Fab
            variant="extended"
            style={{ background: '#fff' }}
            onClick={() => props.router.push('/owner/minhas-categorias/novo')}>
            <Add className={classes.extendedIcon} />
            Nova Categoria
          </Fab>
        </Box>
      </GeneralGrid>
    </section>
  );
}

Categories.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  categories: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
  getList: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    categories: state.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
