import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { WelcomeMailTemplate } from 'common/components/email';
import { InputFileWithUpload } from 'common/components/fields/InputFileWithUpload';
import { InputLabelOutlined } from 'common/components/fields/InputLabelOutlined';
import Grid from 'common/components/layout/grid/grid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { isMobile } from 'react-device-detect';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './welcomeMailActions';

let WelcomeMailForm = function (props) {
  const { welcomeMail, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(welcomeMail, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      station: props.auth.user.establishments[0].stations[0].id,
    };

    let firstTime = false;

    if (props.location.query.first_action) {
      firstTime = true;
    }

    create_or_update(router, newValues, create, update, firstTime);
  }

  function onDropRejected(files, event) {
    props.fileRejected();
  }

  return props.welcomeMail.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="welcomeMail-form">
      <MaterialGrid container spacing={8}>
        <MaterialGrid
          item
          xs={isMobile ? 12 : 6}
          style={{
            borderRight: '1px solid lightgray',
            marginBottom: isMobile ? 20 : 0,
            marginTop: 20,
          }}>
          <div className="card">
            <div className="card-body">
              <a
                style={{ cursor: 'pointer', color: '#2D95EF' }}
                onClick={() => props.router.push('/owner/automatizacoes')}>
                Voltar
              </a>
              <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Título"
                    name="title"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Assunto do e-mail"
                    name="subject"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>

                <MaterialGrid item xs={12} md={6}>
                  <Field
                    component={InputFileWithUpload}
                    label={'Imagem de destaque .png, .jpg ou .gif'}
                    name="featured_image"
                    validate={[FORM_RULES.required]}
                    onDropRejected={onDropRejected}
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Corpo"
                    name="body"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
                <Grid cols="12 12 12 12" style={{ textAlign: 'right' }}>
                  <PrimaryButton
                    type="submit"
                    disabled={props.welcomeMail.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}>
                    {props.welcomeMail.buttonLoading ? 'Enviando' : 'Enviar'}
                  </PrimaryButton>
                </Grid>
              </Form>
            </div>
          </div>
        </MaterialGrid>
        {isMobile ? null : (
          <MaterialGrid item xs={6}>
            <Typography style={{ textAlign: 'left', fontSize: 12, color: 'gray' }}>
              Pré-visualização
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: WelcomeMailTemplate(
                  props.auth.user,
                  props.auth.user.establishments[0].stations[0],
                  props.formValues
                    ? props.formValues.featured_image
                    : 'files/actions/stations/1/IMG_0.9006205497615707',
                  props.formValues ? props.formValues.title : 'Título',
                  props.formValues ? props.formValues.body : 'Corpo',
                  props.auth.user.establishments[0]
                ),
              }}></div>
          </MaterialGrid>
        )}
      </MaterialGrid>
    </section>
  );
};

WelcomeMailForm.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  create: PropTypes.any,
  fileRejected: PropTypes.func,
  formValues: PropTypes.shape({
    body: PropTypes.any,
    featured_image: PropTypes.any,
    title: PropTypes.any,
  }),
  general: PropTypes.shape({
    submitProgress: PropTypes.any,
  }),
  getDetail: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      first_action: PropTypes.any,
    }),
  }),
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
    push: PropTypes.func,
  }),
  setDetail: PropTypes.any,
  setLoading: PropTypes.func,
  update: PropTypes.any,
  welcomeMail: PropTypes.shape({
    buttonLoading: PropTypes.any,
    loading: PropTypes.any,
  }),
};

WelcomeMailForm = reduxForm({ form: 'welcomeMailForm' })(WelcomeMailForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    welcomeMail: state.welcomeMail,
    formValues: getFormValues('welcomeMailForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMailForm);
