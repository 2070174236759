import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Schedules from './schedules';
import SchedulesForm from './schedulesForm';

export default (
  <Route path="calendar">
    <IndexRoute component={(props) => <Schedules {...props} />} />
    <Route path="novo" component={(props) => <SchedulesForm {...props} />} />
    <Route path=":id/:action" component={(props) => <SchedulesForm {...props} />} />
  </Route>
);
