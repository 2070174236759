import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { change } from 'redux-form';

const URL_USER_STEPS = `${BASE_API}/user-steps`;
const URL_ADDITIONAL_DATA = `${BASE_API}/gateway-additional-data`;

export const getUserSteps = (id) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'USER_STEPS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USER_STEPS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'USER_STEPS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};
export const getAdditionalData = () => {
  return (dispatch) => {
    dispatch([
      {
        type: 'ADDITIONAL_DATA_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ADDITIONAL_DATA}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          {
            type: 'ADDITIONAL_DATA_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const changeField = (form, field, value) => {
  return change(form, field, value);
};
