import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import GenericConfirmationCard from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { ColumnsBelowSearch } from 'common/components/table/tableComponents';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { getList, removeCode } from './subscriptionsActions';
import moment from 'moment';

const useStyles = makeStyles(styles);

function Subscriptions(props) {
  const classes = useStyles();
  const { auth } = props;
  const { content } = props.subscriptions.list;
  const { router } = props;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const defaultColumnsMob = [{ title: 'Nome', field: 'user.name' }];

  const defaultColumns = [
    {
      title: 'Nome',
      field: 'user.name',
      render: (rowData) => rowData.user?.name,
    },
    {
      title: 'CPF',
      field: 'user.cpf',
      render: (rowData) => rowData.user?.cpf,
    },
    {
      title: 'Atividade',
      field: 'presential_course.action.name',
      render: (rowData) => rowData.presential_course?.action?.name,
    },
    {
      title: 'Data de emissão',
      field: 'createdAt',
      render: (rowData) => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Codigo',
      field: 'code',
      render: (rowData) => rowData?.code,
    },
    {
      title: 'Validado',
      field: 'validated',
      render: (rowData) => (parseInt(rowData.validated) ? 'Sim' : 'Não'),
    },
    {
      title: 'Data de Validação',
      field: 'validation_date      ',
      render: (rowData) =>
        rowData?.validation_date
          ? moment(rowData.validation_date).format('DD/MM/YYYY HH:mm:ss')
          : '----',
    },
    {
      title: 'Validador',
      field: 'code_validator.identification',
      render: (rowData) => rowData.code_validator?.identification,
    },
    {
      field: 'actions',
      title: '',

      hasOnClick: true,
      render: (rowData) => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.code);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  function handleDelete(code) {
    props.removeCode(code, auth.user.establishments[0].stations[0].id);
    setOpenConfirmation(false);
  }

  const columns = isMobile ? defaultColumnsMob : defaultColumns;

  const [data, setData] = useState([]);
  useEffect(() => {
    props.getList(auth.user.establishments[0].stations[0].id);
  }, []);
  useEffect(() => {
    setData(props.subscriptions.list.content);
  }, [props.subscriptions.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="subscriptions">
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">Você ainda não possui inscrições</h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.subscriptions.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section>
      {' '}
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.code}
        general={props.general}
        title="Deletar Voucher"
        confirmButtonColor={'primary'}
        text="Confirma exclusão do Voucher?"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">accessibility</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Resgates</p>
              <h3 className={classes.cardTitle}>{props.subscriptions.list.content.length}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">dashboard</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Vouchers validados</p>
              <h3 className={classes.cardTitle}>
                {
                  props.subscriptions.list.content.filter((item) => {
                    // console.log(item);
                    return item.validated === 'Sim';
                  }).length
                }
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/owner/validacao">
            <Card
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="active-actions">
              <Typography color="primary" component="h4" className={classes.cardTitle}>
                Validar QR CODE
              </Typography>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/owner/inscricoes/validados">
            <Card
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="active-actions">
              <Typography color="primary" component="h4" className={classes.cardTitle}>
                Premiar participante
              </Typography>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table
                hasToolbar
                toolbarSearch
                title="Lista de vouchers resgatados"
                columns={columns}
                rows={data}
                dataType={'subscriptions'}
                router={router}
                mobileTable={isMobile}
                hasBorder={!isMobile}
              />
              {/* <Table6
                title="Lista de inscrições"
                columns={columns}
                data={data}
                router={router}
              /> */}
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

Subscriptions.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      establishments: PropTypes.any,
    }),
  }),
  getList: PropTypes.func,
  router: PropTypes.any,
  removeCode: PropTypes.func,
  subscriptions: PropTypes.shape({
    list: PropTypes.shape({
      content: PropTypes.shape({
        filter: PropTypes.func,
        length: PropTypes.number,
      }),
    }),
    loading: PropTypes.any,
  }),
};

const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
